<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">
      Сменить тип подключения
    </div>
    <div class="inner">
      <div class="input-container mb-3">
        <select class="input select" v-model="type_id" :class="{'empty': !type_id}" ref="type_id">
          <option
            v-for="option in connectionTypes"
            :key="option.key"
            :value="option.key"
          >
            {{ option.type }}
          </option>
        </select>
        <label>Тип подключения</label>
      </div>
      <button type="button" class="btn" @click="submitForm">Сменить</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ChangeConnectionTypeModal',
  data() {
    return {
      type_id: null,
    }
  },
  computed: {
    ...mapGetters({
      connectionTypes: 'connectionTypes',
    }),
  },
  methods: {
    ...mapActions({
      error: 'errorAlert'
    }),
    validate() {
      let isError = false
      if (!this.type_id) {
        isError = true
        this.error('Выберите тип подключения')
        this.$refs.type_id.classList.add('error')
        setTimeout(() => {
          this.$refs.type_id.classList.remove('error')
        }, 3000)
      }
      return isError
    },
    submitForm() {
      if (!this.validate()) {
        console.log('here')
        let payload = {
          type_key: this.type_id
        }
        this.$emit('changeType', payload)
      }
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.change-date {
  width: 500px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 400px;
  }
}

</style>
