import {api} from "@/service/axios";

export const accessDataModule = {
    state: () => ({
       accessList: [],
       subtaskProblems: [],
       accessTypes: []
    }),
    getters: {
        accessList: state => state.accessList,
        subtaskProblems: state => state.subtaskProblems,
        accessTypes: state => state.accessTypes,
    },
    mutations: {
        setAccessList(state, payload) {
            state.accessList = payload
        },
        setSubtaskProblems(state, payload) {
            state.subtaskProblems = payload
        },
        setAccessTypes(state, payload) {
            state.accessTypes = payload
        }
    },
    actions:{
        async fetchAccessDataList({commit}, payload) {
            const res = await api.get('access-info/access-info', {params:payload})
            commit('setAccessList', res.data)
            return res
        },
        async createAccessData({commit}, payload) {
            const res = await api.post('access-info/save-access-info', payload)
            return res
        },
        async createAccessDataTicket({commit}, payload) {
            const res = await api.post('access-info/access-ticket-store', payload)
            return res
        },
        async fetchAccessDataExcel({commit}, payload) {
            const res = await api.post('access-info/save-access-info-from-excel',payload, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            return res
        },
        async updateAccessData({commit}, payload) {
            const res = await api.put('access-info/update-access-info', payload)
            return res
        },
        async downloadExcelAccessData({commit}, payload) {
            const res = await api.get('access-info/access-info', {params: payload, responseType: 'blob'});
            return res
        },
        async increaseAccessDataTicketEndDate({commit}, payload) {
            const res = await api.put(`access-info/increase-ticket-end-date/${payload}`)
            return res
        },
        async createAccessDataRelated({commit}, payload){
            const res = await api.post('access-info/related-access-ticket-store', payload)
            return res
        },
        async createConnectionAccessRelatedTicket({commit}, payload){
            const res = await api.post(`connection-tickets/create-related-ticket/${payload.id}`, payload)
            return res
        },
        async checkAccessInfoAddress({commit}, payload){
            const res = await api.post('access-info/check-addresses', payload)
            return res
        },
        async calculateClientsByNode({commit}, payload){
            const res = await api.post('tickets/calculate-client-count', payload)
            return res
        },
        async getAccessSubtaskProblems({commit}, payload){
            const res = await api.get('access-info/access-subtask-problems')
            commit('setSubtaskProblems', res.data)
            return res
        },
        async createSubtask({commit}, payload) {
            const res = await api.post('access-info/create-subtask', payload)
            return res
        },
        async getAccessAddressType({commit}, payload){
            const res = await api.get('access-info/get-ticket-address-access-type')
            commit('setAccessTypes', res.data)
            return res
        },
        async storeTicketAddress({commit}, payload){
            const res = await api.post('access-info/store-ticket-address', payload)
            return res
        }
    }
}