<template>
  <div class="p-20">
    <div class="p-20" v-if="Object.keys(permission).length === 0">
      <label for="" class="text-20 mb-1">Роль</label>
      <select class="input-form select mt-2 mb-3" v-model="role">
        <option v-for="option in allRoles" :key="option.id" :value="option.key">
          {{ option.title }}
        </option>
      </select>
      <label for="" class="text-20">Разрешения</label>
      <select class="input-form select mt-2 mb-3" v-model="newPermission" @change="resetValues">
        <option v-for="option in allSections" :key="option.id" :value="option.key">
          {{ option.title }}
        </option>
      </select>
      <div class="flex justify-center mb-4" v-if="newPermission">
        <div>
          <div class="flex justify-between">
            <label for="" class="block">Возможность редактировать</label>
            <input type="checkbox" v-model="isEdit">
          </div>

          <div class="flex justify-between">
            <label for="" class="block">Возможность просматривать</label>
            <input type="checkbox" v-model="isShow">
          </div>
          <div class="flex justify-between">
            <label for="" class="block">Возможность создавать</label>
            <input type="checkbox" v-model="isCreate">
          </div>
          <div class="flex justify-between">
            <label for="" class="block">Возможность удалять</label>
            <input type="checkbox" v-model="isDelete">
          </div>
        </div>
      </div>
      <button class="btn" @click="createPermissions">Создать</button>
    </div>
    <div class="p-20" v-else>
      <p class="text-20 mb-1 text-center">Роль</p>
      <input type="text" disabled class="input-form mb-2" :value="permission.role_title"/>
      <p class="text-20 mb-1 text-center">Разрешения</p>
      <div style="height: 200px; overflow: scroll;">
        <div v-for="el in existingPermissions" :key="el">
          <div class="flex justify-between" style="border: 1px solid black; border-radius: 10px; padding: 10px;">
            <div>{{ el.section_title }}</div>
            <div><img src="/assets/TrashBr.svg" class="cursor-pointer" alt="delete" width="20" height="20"
                      @click.prevent="deleteCurrentPermission(el.id)"/></div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <p class="text-center">Добавить разрешение</p>
        <select class="input-form select mt-2 mb-3" v-model="newPermission">
          <option v-for="option in allSections" :key="option.id" :value="option.key">
            {{ option.title }}
          </option>
        </select>
        <div class="flex justify-center mb-4" v-if="newPermission">
          <div>
            <div class="flex justify-between">
              <label for="" class="block">Возможность редактировать</label>
              <input type="checkbox" v-model="isEdit">
            </div>

            <div class="flex justify-between">
              <label for="" class="block">Возможность просматривать</label>
              <input type="checkbox" v-model="isShow">
            </div>
            <div class="flex justify-between">
              <label for="" class="block">Возможность создавать</label>
              <input type="checkbox" v-model="isCreate">
            </div>
            <div class="flex justify-between">
              <label for="" class="block">Возможность удалять</label>
              <input type="checkbox" v-model="isDelete">
            </div>
          </div>
        </div>
        <button class="btn" @click="createPermissions">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "PermissionModal",
  props: {
    permission: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      role: '',
      newPermission: '',
      isEdit: false,
      isCreate: false,
      isShow: false,
      isDelete: false,
      existingPermissions: [],
    }
  },
  async created() {
    this.setLoading(true)
    await this.fetchAllSections()
    await this.fetchAllRoles()
    if (Object.keys(this.permission).length > 0) {
      this.role = this.permission.role
      this.existingPermissions = this.permission.permissions
    }
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      allSections: 'allSections',
      allRoles: 'allRoles'
    })
  },
  methods: {
    ...mapActions({
      fetchAllSections: 'fetchAllSections',
      createPermission: 'createRolePermissions',
      fetchAllRoles: 'fetchAllRoles',
      deleteRolePermission: 'deleteRolePermission',
      fetchRolePermissions: 'fetchRolePermissions',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async createPermissions() {
      const obj = {
        role_permissions: [{
          role: this.role,
          permissions: [{
            section_key: this.newPermission,
            is_show: this.isShow,
            is_edit: this.isEdit,
            is_create: this.isCreate,
            is_delete: this.isDelete
          }]
        }]
      }
      await this.createPermission(obj).then((res) => {
        if (res.success) {
          this.$toast.success('Разрешение успешно добавлено')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось изменить')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async deleteCurrentPermission(id) {
      await this.deleteRolePermission({id: id}).then((res) => {
        if (res.success) {
          const array = this.existingPermissions.filter(item => item.id !== id)
          this.existingPermissions = array
          this.fetchRolePermissions()
          this.$toast.success('Разрешение успешно удалено')
        } else {
          this.$toast.error('Не удалось изменить')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    resetValues(){
      this.isEdit = false
      this.isCreate = false
      this.isShow = false
      this.isDelete = false
    }
  },
}
</script>


<style scoped lang="scss">
</style>