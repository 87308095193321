<template>
	<div class="relative">
		<div ref="stickyTrigger" style="top: 0"></div>
		<div
      style="transition: all 300ms"
      class="chat-mobile"
			ref="chatContainer"
		>
			<div
				@click="openChatMain"
        style="cursor: pointer; position: relative;"
			>
				<img
					src="/assets/chat.svg"
					alt=""
					width="52"
					height="52"
				/>
				<div
            class="unread-count"
					v-if="totalUnreadCount"
				>
					{{ totalUnreadCount }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		isMobile: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isOpen: false,
			isSticky: false,
			observer: null
		}
	},
	computed: {
		...mapGetters([
			'getRocketChatRooms',
			'getUnreadCounts',
			'getRocketChatCred'
		]),
		totalUnreadCount() {
			return Object.values(this.getUnreadCounts).reduce((sum, count) => sum + (count || 0), 0)
		},
	},
	methods: {
		openChatMain() {
			this.$emit('open-chat-test')
		},
		setupIntersectionObserver() {
			this.observer = new IntersectionObserver(
				([entry]) => {
					this.isSticky = !entry.isIntersecting
				},
				{ threshold: 0, rootMargin: '-1px 0px 0px 0px' }
			)
			this.observer.observe(this.$refs.stickyTrigger)
		}
	},
	mounted() {
		this.setupIntersectionObserver()
	},
  beforeUnmount() {
		if (this.observer) this.observer.disconnect()
	}
}
</script>

<style scoped lang="scss">
.chat-mobile {
		.unread-count {
      position: absolute;
      top: -15px;
      right: -19px;
			font-size: 12px;
			width: 18px;
			height: 18px;
      background-color: #FF4053;
      border-radius: 48px;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
		}
}

.unread-badge {
  top: 5px;
  right: -5px;
  font-size: 12px;
  width: 18px;
  height: 18px;
}

.chat-scroll {
	height: 100%;
	overflow-y: auto;
	
	&::-webkit-scrollbar {
		width: 0;
		display: none;
	}
	
	scrollbar-width: none;
	
	-ms-overflow-style: none;
}

.chat-list-move {
	overflow: visible;
}

.chat-list-enter-active,
.chat-list-leave-active {
	transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);
	position: absolute;
	width: 100%;
}

.chat-list-enter-from,
.chat-list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}

.unread-badge {
	animation: bounce 0.5s ease;
}

@keyframes bounce {
	0%, 100% { transform: scale(1); }
	50% { transform: scale(1.2); }
}

:deep(.chat-list) {
	overflow: visible;
}
</style>
