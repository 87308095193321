import {api} from '@/service/axios'
import Cookies from 'js-cookie'
import router from '@/router'
import store from "@/store";
import {useStore} from "vuex";
export const userModule = {
  state: () => ({
    user: {
      active: null,
      admin: false,
      bitrix_id: null,
      block_date: null,
      created_at: '',
      department_id: null,
      email: '',
      erp_id: null,
      firstname: '',
      full_name: '',
      id: null,
      iin: '',
      lanbilling_id: null,
      lastname: '',
      password: '',
      patronymicname: '',
      phone: '',
      position: '',
      role_id: null,
      sms_status_key: null,
      updated_at: '',
      username: ''
    },
    managerInfo: null,
    isAuth: false,
    isFirstLoad: false,
  }),
  getters: {
    isAuth(state) {
      return state.isAuth
    },
    user(state) {
      return state.user
    },
    managerInfo(state) {
      return state.managerInfo
    },
    isFirstLoad(state){
      return state.isFirstLoad
    }
  },
  mutations: {
    setIsAuth(state, payload) {
      state.isAuth = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setManagerInfo(state, payload) {
      state.managerInfo = payload
    },
    setIsFirstLoad(state, payload) {
      state.isFirstLoad = payload
    }
  },
  actions: {
    async login({commit, dispatch}, payload) {
      const res = await api.post('auth/login', payload)
      if (res.success) {
        // Cookies.set('token', res.data.token)
        localStorage.setItem("token", res.data.token);

        commit('setIsAuth', true)
        commit('setUser', res.data)
        await router.push('/')
      }
      else {
        dispatch('errorAlert', res.message)
      }
      return res
    },
    async sendSMS({commit}, payload) {
      const res = await api.post('auth/forgot-password-erp', payload)
      return res
    },
    async checkSMS({commit}, payload) {
      const res = await api.post('auth/confirm-sms-code', payload)
      return res
    },
    async changePass({commit}, payload) {
      const res = await api.post('auth/change-password', payload)
      return res
    },
    async checkLogin({commit, dispatch}) {
      const res = await api.get('auth/get-user-data')
      if (res.success) {
        commit('setIsAuth', true)
        commit('setUser', res.data)
        dispatch('fetchManagerInfo', res.data.id)
      }
      else {
        dispatch('errorAlert', res.message)
      }
      return res
    },
    logOut({commit, dispatch}) {
      const store = useStore();
      // Cookies.remove('token')
      localStorage.removeItem("token");
      dispatch('clearTasksCred')

      commit('setIsAuth', false)
      router.push('/login')
    },
    async fetchManagerInfo({commit}, payload){
      const res = await api.get(`managers/get-manager-data?manager_id=${payload}`)
      commit('setManagerInfo', res.data.data)
    },
  }
}
