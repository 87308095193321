<template>
  <div class="wrapper modified flex column bg">
    <div class="bg-white p-20">
      <DepartureServiceFilters
        v-model:search="search"
        v-model:dateRange="dateRange"
        :date_from="date_from"
        :date_to="date_to"
        v-model:area_id="area_id"
        v-model:status_ids="status_ids"
        v-model:category_id="category_id"
        v-model:district_id="district_id"
        v-model:responsible_id="responsible_id"
        v-model:brigade_id="brigade_id"
        :connection_department="connection_department"
        :responsible_dep="params.responsible_dep"
        :areas="areas"
        :statuses="statuses"
        :categories="categories"
        :districts="districts"
        :allResponsibles="allResponsibles"
        :brigades="brigades"
        @setDate="setDate"
        @clearBtn="clearBtn"
        @departmentFilter="departmentFilter"
        @saveFilters="saveFilters"
        @resetFilters="resetFilters"
        @update:search="handleSearchUpdate"
      />

      <div class="flex mt-2 gap-2">
        <div class="flex justify-between input-form">
          <div>Закрытые за данный период</div>
          <div style="color: #FFB020">{{ finishedServiceTickets.period }}</div>
        </div>
        <div class="flex justify-between input-form">
          <div>Закрытые за сегодня</div>
          <div style="color: #1ED3FF">{{ finishedServiceTickets.today }}</div>
        </div>
        <div class="flex justify-between input-form">
          <div>Закрытые за вчера</div>
          <div style="color: #9F53FF">{{ finishedServiceTickets.yesterday }}</div>
        </div>
      </div>

      <div class="w-100 flex justify-center rounded mt-2 p-20" style="background: #00bfff;font-weight: 500">
        <p class="">План сервисов по задачам {{ showData }}, записей
          {{ serviceTickets?.meta?.total }}</p>
      </div>

      <div class="flex justify-between items-center mt-2">
        <div class="w-100 flex" style="align-items: end">
          <div
              class="ticket-menu flex justify-center align-center cursor-pointer mr-2 navs"
              v-for="menu in menuMainArray"
              :key="menu.key"
              :class="{'active': currentTab === menu.key}"
              @click="changeTab(menu)"
          >
            {{ menu.title }}
          </div>
          <div class="mt flex flex-wrap" style="margin-left:auto;justify-content: flex-end;align-items: end">
            <div>
              <button class="ticket-menu flex justify-center gap-1 align-center cursor-pointer mr-2 navs"
                      style="background: #2ab161;color: white;" @click="downloadExcel">
                <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
                <div>Выгрузить</div>
              </button>
            </div>
            <div style="width: 200px;">
              <div class="mb-1">Показывать по</div>
              <AppSelect
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  @change="onPerPageChange"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="content w-100 mt-4 flex column">
        <div class="grid gridText" style="">
          <div class="grid-item grid-num">
            <p>№</p>
          </div>
          <div class="grid-item grid-id flex align-center justify-center" @click="sortedData('id')">
            <div class="flex justify-center w-100">
              <p class="text-center">ID</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-name flex align-center justify-center">
            <p class="text-center">Наименование</p>
          </div>
          <div class="grid-item grid-address flex align-center justify-center" @click="sortedData('address')">
            <div class="flex justify-center w-100">
              <p class="text-center">Адрес</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-district flex align-center justify-center" @click="sortedData('city')">
            <div class="flex justify-center w-100">
              <p class="text-center">Район</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-category flex align-center justify-center">
            <p class="text-center">Категория</p>
          </div>
          <div class="grid-item grid-type cursor-pointer flex align-center justify-center">
            <p class="text-center">Тип проблемы</p>
          </div>
          <div class="grid-item grid-responsible flex align-center justify-center" @click="sortedData('responsible')">
            <div class="flex justify-center w-100">
              <p class="text-center">Ответственный</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-status flex align-center justify-center">
            <p class="text-center">Статус</p>
          </div>
          <div class="grid-item grid-created flex align-center justify-center" @click="sortedData('created_at')">
            <div class="flex justify-center w-100">
              <p class="text-center">Дата создания</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-finished flex align-center justify-center" @click="sortedData('plan_end')">
            <p class="text-center">Дата завершения</p>
            <img src="/assets/comments/arrow-down-up.svg" alt="sort" class="sort-icon">
          </div>
          <div class="grid-item grid-planned flex align-center justify-center">
            <p class="text-center">План. дата выполнения</p>
          </div>
          <div class="grid-item grid-Options flex align-center justify-center">
            <p class="text-center">Управление</p>
          </div>
        </div>
        <div class="departure-table" v-if="serviceTickets">
          <div class="grid gridText rounded" :id="list.id" @click="onTableClick(list.id)"
               v-for="(list, index) in sortedTickets" :key="list.id">
            <div class="grid-item grid-num"> {{ index + 1 }}</div>
            <div class="grid-item grid-id flex justify-center items-center">
              <div style="padding: 2px 4px" @click="openTicket(list)" @click.middle="openMiddleMouse(list)">
                <div class="text-center cursor-pointer">
                  <div>{{ list.ticket_number }}</div>
                </div>
              </div>
            </div>
            <div class="grid-item grid-name flex gap-1 align-center" @click="openClientInfo(list)">
              <p class="">{{ list.contact_info || 'Не указано' }}</p>
              <div v-if="!list.client.is_active" ><img src="/assets/warning-sign-svgrepo-com.svg" width="16" height="16" alt=""></div>
            </div>
            <div class="grid-item grid-address flex align-center">
              <div>{{ list.address || 'Не указано' }}</div>
            </div>
            <div class="grid-item grid-district flex align-center">
              <div>{{ list.district?.title || 'Не указано' }}</div>
            </div>
            <div class="grid-item grid-category flex align-center">
              <div>{{ list.category.title || 'Не указано' }}</div>
            </div>
            <div class="grid-item grid-type flex align-center">
              <div>{{ list.problem_type?.title || 'Не указано' }}</div>
            </div>
            <div
                :class="list.status?.id === 26 ? 'grid-item grid-responsible grid-closed gap-1 flex justify-between align-center' : 'grid-item grid-responsible cursor-pointer gap-1 flex justify-between align-center'"
                @click="openBrigade(list)">
              <div v-if="list.responsible.title">
                <div :class="list.status?.id === 26 ? '' : 'grid-blue'">
                  <div>{{ list.responsible_brigade.title ? list.responsible_brigade.title : list.responsible.title }}</div>
                </div>
              </div>
              <div v-else :class="list.status?.id === 26 ? '' : 'grid-blue'">
                Не указано
              </div>
              <div v-if="list.status?.id !== 26">
                <img src="/assets/edit-button-blue.svg"/>
              </div>
            </div>
            <div class="grid-item grid-status flex align-center">
              <div>{{ list.status.title }}</div>
            </div>
            <div class="grid-item grid-created flex align-center text-center">{{ list.created_at }}</div>
            <div class="grid-item grid-finished flex align-center text-center">{{ list.closed_at || 'Не указано' }}</div>
            <div class="grid-item grid-planned flex align-center text-center cursor-pointer grid-blue" @click="openDate(list)">{{ list.planned_at_begin || 'Не указано' }}
            </div>
            <div class="grid-item brigade-options" style="padding: 0 2px;">
              <img src="/assets/logs-icon.svg" alt="" width="22" height="22" title="История" @click="openLogs(list.id)">
              <img src="/assets/list.svg" alt="edit" width="20" height="20" title="Информация"
                   @click="openTicketInfo(list)">
              <img src="/assets/comment.svg" alt="comment" width="24" height="24" title="Комментарии"
                   @click="openComments(list.id)"/>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent :total-pages="totalPages" :current-page="page" @page-change="onPageChange"></PaginationComponent>
    </div>
  </div>
  <custom-modal custom-class="ticket-detail" v-if="isTicketDetailModal" @close="isTicketDetailModal = !isTicketDetailModal">
    <ticket-detail-view :is-modal="true">
    </ticket-detail-view>
  </custom-modal>
  <custom-modal v-if="isServiceTicketLogsModal" @close="isServiceTicketLogsModal = !isServiceTicketLogsModal">
    <departure-service-tickets-logs-modal :id="currentId">
    </departure-service-tickets-logs-modal>
  </custom-modal>
  <custom-modal custom-class="departure-comment" v-if="isServiceTicketCommentsModal" @close="isServiceTicketCommentsModal = !isServiceTicketCommentsModal">
    <departure-service-tickets-comments-modal :id="currentId">
    </departure-service-tickets-comments-modal>
  </custom-modal>
  <custom-modal v-if="isBrigadeAssignModal" @close="isBrigadeAssignModal = !isBrigadeAssignModal">
    <departure-service-all-responsibles-modal @submit="assignBrigade" :department-id="currentDepartmentId">
    </departure-service-all-responsibles-modal>
  </custom-modal>
  <custom-modal v-if="isPlannedDateModal" @close="isPlannedDateModal = !isPlannedDateModal">
    <departure-service-planned-date-modal :id="currentTicketId" @closeDate="closeDate">
    </departure-service-planned-date-modal>
  </custom-modal>
</template>

<script>
import AppSelect from "@/components/ui/AppSelect.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TicketDetailView from "@/views/serviceTickets/TicketDetailView.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import DepartureServiceTicketsLogsModal from "@/components/modals/departureService/DepartureServiceTicketsLogsModal.vue";
import DepartureServiceTicketsCommentsModal from "@/components/modals/departureService/DepartureServiceTicketsCommentsModal.vue";
import DepartureServicePlannedDateModal from "@/components/modals/departureService/DepartureServicePlannedDateModal.vue";
import DepartureServiceAllResponsiblesModal from "@/components/modals/departureService/DepartureServiceAllResponsiblesModal.vue";
import {TicketRouterNavigationMixin} from "@/mixins/TicketRouterNavigationMixinModule";
import DepartureServiceFilters from "@/components/filters/DepartureServiceFilters.vue";
import departureServiceViewMixin from "@/mixins/departureServiceViewMixin";

export default {
  name: "DepartureServiceTicketsB2BView",
  components: { DepartureServiceFilters, DepartureServiceAllResponsiblesModal, DepartureServicePlannedDateModal, DepartureServiceTicketsCommentsModal,
    DepartureServiceTicketsLogsModal, TicketDetailView, PaginationComponent, AppSelect, CustomModal
  },
  mixins: [TicketRouterNavigationMixin, departureServiceViewMixin],
  computed: {
    ...mapGetters({
      user: 'user',
      serviceTickets: 'departureServiceTickets',
      statuses: 'statuses',
      finishedServiceTickets: 'finishedServiceTickets',
      allResponsibles: 'allResponsibles',
      brigades: 'brigadeByDepartment',
      categories: 'categories',
      districts: 'districts',
      areas: 'areas',
      departureManagers: 'departureManagers',
      departureServiceTicketsFilters: 'departureServiceTicketsFilters',
      departureServiceTicketResponsibles: 'departureServiceTicketResponsibles',
      serviceDepartments: 'serviceDepartments'
    }),
    clientType() {
      return 'b2b';
    }
  },
  methods: {
    ...mapActions({
      fetchDepartureTickets: 'fetchDepartureServiceTickets',
      fetchDepartureManagers: 'fetchDepartureManagers',
      fetchStatuses: 'fetchStatuses',
      fetchCategories: 'fetchCategories',
      fetchDistricts: 'fetchDistricts',
      fetchTicketDetail: 'ticketDetail',
      saveRequestFilter: 'saveRequestFilter',
      deleteRequestFilter: 'deleteRequestFilter',
      fetchClientData: 'fetchClientData',
      fetchDepartureServiceClosedInfo: 'fetchDepartureServiceClosedInfo',
      makeExcelList: 'makeExcelList',
      fetchDepartureServiceTicketResponsibles: 'fetchDepartureServiceTicketResponsibles',
      fetchServiceDepartments: 'fetchServiceDepartments',
      setDepartment: 'setDepartment',
      fetchAllResponsibles: 'fetchAllResponsibles',
      getAreas: 'getAreas',
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      fetchFinishedServiceTickets: 'fetchFinishedServiceTickets',
      assignBrigadeToTicket: 'assignBrigadeToServiceTicket',
      fetchExcelList: 'fetchDepartureServiceExcelList'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentClientInfo: 'setCurrentClientInfo',
      setIsDepartureClientInfoModal: 'setIsDepartureClientInfoModal',
      sortTickets: 'sortTickets',
      setCurrentTicketId: 'setCurrentTicketId'
    }),
    openTicketInfo(ticket) {
      this.setCurrentTicketId(ticket.id)
      this.isTicketDetailModal = true
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';

.selected {
  border: 4px solid black;
}

.filters-section {
  margin-bottom: 1rem;
}

.filters-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-bottom: 16px;
  align-items: start;
}

.filter-item {
  min-width: 0;
  width: 100%;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;

  .save-btn {
    min-width: 140px;
    height: 44px;
    padding: 0 12px;
  }

  .reset-btn {
    min-width: 100px;
    height: 44px;
    padding: 0 12px;
  }
}

.filter-container {
  display: none;
}

.grid {
  display: grid;
  gap: 1px;
  align-items: center;
  padding: 1px 0;
  grid-template-columns: 
    minmax(0, 40px)      /* №  */
    minmax(0, 1.5fr)     /* ID */
    minmax(0, 3fr)       /* Наименование */
    minmax(0, 3fr)       /* Адрес */
    minmax(0, 2fr)       /* Район */
    minmax(0, 1.5fr)     /* Категория */
    minmax(0, 1.5fr)     /* Тип проблемы */
    minmax(0, 2fr)       /* Ответственный */
    minmax(0, 1.5fr)     /* Статус */
    minmax(0, 1.5fr)     /* Дата создания */
    minmax(0, 1.3fr)     /* Дата завершения */
    minmax(0, 1.3fr)     /* План. дата выполнения */
    minmax(0, 1.2fr);    /* Управление */
  grid-template-areas: 'Num Id Name Address District Category Type Responsible Status Created Finished Planned Options';
}

.grid-item {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sort-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
}

.grid-num {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: Num;
}

.grid-id {
  align-items: center;
  grid-area: Id;
  padding: 4px 10px;
}

.grid-name {
  grid-area: Name;
  padding: 4px 10px;
}

.grid-address {
  grid-area: Address;
  padding: 4px 10px;
}

.grid-district {
  grid-area: District;
  padding: 4px 10px;
}

.grid-category {
  grid-area: Category;
  padding: 4px 10px;
}

.grid-type {
  grid-area: Type;
  padding: 4px 10px;
}

.grid-responsible {
  grid-area: Responsible;
  padding: 4px 10px;
}

.grid-status {
  grid-area: Status;
  padding: 4px 10px;
}

.grid-created {
  grid-area: Created;
  padding: 0 4px;
}

.grid-finished {
  grid-area: Finished;
  padding: 0 2px;
}

.grid-planned {
  grid-area: Planned;
  padding: 0 2px;
}

.grid-Options {
  grid-area: Options;
}

.brigade-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2px;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.departure-table {
  :hover {
    background-color: #F5F5FA !important;
  }

  .grid {
    &-Creator:hover {
      text-decoration: underline;
      transition: 200ms;
      font-weight: bold;
    }

    &-Plan {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-id {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-responsible {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-planned {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-name {
      &:hover {
        cursor: pointer;
        transition: 200ms;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &-closed {
      &:hover {
        cursor: default;
        font-weight: normal;
        text-decoration: none;
      }
    }

    &-blue {
      color: #2A5298
    }
  }
}

.ticket-menu {
  padding: 9px 13px 11px 12px;
  border-radius: 12px;
  background: #FFF;
  color: #888B94;
  border: 1px #F2F1F0 solid;
  font-size: 16px;
  font-style: normal;
  width: 166px;
  font-weight: 600;
  height: 40px;


  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.navs:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: 200ms;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.filter {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 25px !important;
  border: 1px solid #ADADAD !important;
  height: 48px !important;
  margin-top: 8px;
  cursor: pointer;
  font-size: 16px !important;
  color: #ADADAD;
}

.statuses-list {
  position: absolute;
  background-color: white;
  z-index: 10;
  padding: 10px;
  box-shadow: 0 4px 30px 0 #0000001A;
  margin-top: 5px;
  width: 250px;
}

@media only screen and (max-width: 1660px) {
  .gridText {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .gridText {
    font-size: 8px;
  }
}
</style>