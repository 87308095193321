<template>
  <div class="w-100" style="height: 90vh;">
    <iframe :src="url" allow="microphone *; clipboard-write *" style="width: 100%; height: 100%"></iframe>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ChatComponent",
  mounted() {
    if (!this.isClientCard){
      const data = {
        user: {
          id: `${this.user.id}`,
          name: this.user.full_name
        },
        scope: 'global'
      }
      this.fetchWazzupIframeUrl(data).catch((err)=>{
        if (err.response.status === 400) {
          const users = [{
            id: `${this.user.id}`,
            name: this.user.full_name,
            phone: this.user.phone
          }]
          this.createUser(users)
          location.reload()
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      url: 'url',
      user: 'user',
      isClientCard: 'isClientCard'
    })
  },
  methods: {
    ...mapActions({
      fetchWazzupIframeUrl: "fetchWazzupIframeUrl",
      createUser: 'createWazzupUser'
    }),
    ...mapMutations({
      setIsClientCard: 'setIsClientCard'
    })
  },
  unmounted() {
    this.setIsClientCard(false)
  }
}
</script>

<style scoped lang="scss">

</style>