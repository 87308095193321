<template>
  <div class="flex justify-center">
    <div class="bg-white mt-1 rounded">
      <div class="mb-1">
        <h1 class="text-center text-24">Создать связанную заявку доступа</h1>
      </div>
      <div class="flex justify-center p-20" style="width: auto">
        <form @submit.prevent="submitAccessData">
          <div class="input-container input-container__form mb-2" v-if="name">
            <input class="input input-form" v-model="name" placeholder="ФИО клиента" type="text" disabled />
            <label class="label-form">ФИО клиента</label>
          </div>
          <div class="input-container input-container__form mb-2" v-if="phone">
            <input class="input input-form" v-model="phone" placeholder="Номер клиента" type="text" disabled />
            <label class="label-form">Номер клиента</label>
          </div>
          <div class="input-container input-container__form mb-2" v-if="initiator">
            <input class="input input-form" v-model="initiator" placeholder="ФИО постановщика" type="text" disabled />
            <label class="label-form">ФИО постановщика</label>
          </div>
          <div class="input-container input-container__form mb-2" v-if="initiator_phone">
            <input class="input input-form" v-model="initiator_phone" placeholder="Номер постановщика" type="text"
              disabled />
            <label class="label-form">Номер постановщика</label>
          </div>
          <div class="input-container input-container__form mb-2" v-if="ticket?.nodes?.length > 0">
            <div v-for="node in nodes" :key="node.id">
              <input class="input input-form" v-model="node.node_name" placeholder="Узлы" type="text" disabled />
              <label class="label-form">Узлы</label>
            </div>
          </div>

          <div v-for="(el, index) in addressesArr" :key="el">
            <div class="flex justify-between gap-2 mt-1 mb-2 input">
              <p class="text-center text-20">Выбрать координаты:</p>
              <div class="flex items-center justify-center gap-1" style="align-items: center">
                <img src="/assets/access-data/2gis-svgrepo-com.svg" class="img-btn" @click="open2GisModal(index)"
                  title="2GIS" height="24" width="24" alt="">
                <img src="/assets/access-data/yandex-svgrepo-com.svg" class="img-btn" @click="openYandexModal(index)"
                  title="Yandex" height="20" width="22" alt="">
                <button type="button" @click="removeAddress(index)" class="delete-btn">✕</button>
              </div>
            </div>
            <div class="input-container input-container__form mb-2 mt-1">
              <input class="input input-form" :value="el.latitude && el.longitude ? [el.latitude, el.longitude] : null"
                placeholder="Координаты" type="text" readonly />
              <label class="label-form">Координаты</label>
            </div>
            <div class="input-container input-container__form mb-1">
              <input class="input input-form" v-model="el.address" placeholder="Адрес" type="text" />
              <label class="label-form">Адрес</label>
            </div>
            <p class="text-center text-green" v-if="el.has_access && el.latitude && el.longitude">Есть доступ</p>
            <p class="text-center text-red" v-if="!el.has_access && el.latitude && el.longitude">Нет доступа</p>
          </div>
          <div class="mb-2 flex justify-center mt-1">
            <button class="btn btn-green" @click.prevent="addAddressToArray">Добавить адрес</button>
          </div>
          <div class="input-container input-container__form mb-1">
            <app-textarea required class="input input-form" v-model="description" placeholder="Описание"
              maxlength="600" />
            <label class="label-form">Описание</label>
          </div>

          <div class="flex justify-center gap-2 mt-2">
            <button class="btn form-btn btn-green" type="submit" @click.prevent="submitAccessData">Cоздать</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <custom-modal v-if="isAddressModal" @close="isAddressModal = !isAddressModal">
    <service-ticket-form-address-modal @getStreetName="getStreetName"
      @close="pickedCoordinates"></service-ticket-form-address-modal>
  </custom-modal>
  <custom-modal v-if="is2gisAddressModal" @close="is2gisAddressModal = !is2gisAddressModal">
    <map-gis-modal @close="pickGisCoordinates"></map-gis-modal>
  </custom-modal>
  <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
    <success-modal @close="toggleSuccessModal">
      ЗАЯВКА <span v-if="createdTicketId"><b>#{{ createdTicketId }}</b></span> НА ДОСТУП УСПЕШНО СОЗДАНА
    </success-modal>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ServiceTicketFormAddressModal from "@/components/modals/tickets/ServiceTicketFormAddressModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import MapGisModal from "@/components/modals/access-data/MapGisModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import router from "@/router";
import AppTextarea from "@/components/ui/AppTextarea.vue";

export default {
  name: "QueriesTCAccessForm",
  components: { AppTextarea, SuccessModal, MapGisModal, ServiceTicketFormAddressModal, CustomModal },
  props: {
    parentTicketId: {
      type: Number,
      default: 0
    },
    ticket: {
      type: Object,
      default: () => {
      }
    },
  },
  created() {
    this.name = this.ticket.contact_info || this.ticket?.connection_client?.name
    this.phone = this.ticket.contact_number || this.ticket?.connection_client?.phone_number
    this.initiator = this.ticket?.author?.full_name
    this.initiator_phone = this.ticket?.author?.phone
    this.nodes = this.ticket?.nodes
  },
  data() {
    return {
      name: null,
      address: null,
      supervisor: null,
      phone: null,
      agreement: null,
      latitude: null,
      longitude: null,
      isAddressModal: false,
      is2gisAddressModal: false,
      district_id: null,
      isSuccessModal: false,
      createdTicketId: "",
      initiator: null,
      initiator_phone: null,
      description: null,
      nodes: null,
      addressesArr: [],
      selectedAddressIndex: null
    }
  },
  computed: {
    ...mapGetters({
      districts: 'districts',
    })
  },
  methods: {
    ...mapActions({
      createAccessDataRelated: 'createAccessDataRelated',
      checkAccessInfoAddress: 'checkAccessInfoAddress',
      createConnectionAccessRelatedTicket: 'createConnectionAccessRelatedTicket'
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async submitAccessData() {
      const obj = {
        description: this.description,
        parent_ticket_id: +this.parentTicketId,
        addresses: this.addressesArr
      }
      await this.createAccessDataRelated(obj).then(response => {
        if (response.success) {
          this.createdTicketId = response.data.ticket.ticket_number
          this.$toast.success('Заявка успешно создана')
          this.toggleSuccessModal()
          this.clearData()
          this.$emit('created', this.createdTicketId)
        } else {
          this.$toast.error('Не удалось создать адрес')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })

    },
    clearData() {
      this.name = null
      this.address = null
      this.supervisor = null
      this.phone = null
      this.agreement = null
      this.latitude = null
      this.longitude = null
      this.district_id = null
      this.description = null
    },
    async pickedCoordinates(coords) {
      this.isAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].latitude = coords[0];
        this.addressesArr[this.selectedAddressIndex].longitude = coords[1];
        const addresses = {
          addresses: [
            {
              longitude: this.addressesArr[this.selectedAddressIndex].longitude,
              latitude: this.addressesArr[this.selectedAddressIndex].latitude
            }
          ]
        }
        if (this.type !== 'connection') {
          await this.checkAccessInfoAddress(addresses).then((res) => {
            if (res.success) {
              this.addressesArr[this.selectedAddressIndex].has_access = res.data[0].has_access
            }
          })
        }
      }
    },
    async pickGisCoordinates({ coordinates, street }) {
      this.is2gisAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].latitude = coordinates[0];
        this.addressesArr[this.selectedAddressIndex].longitude = coordinates[1];
        this.addressesArr[this.selectedAddressIndex].address = street;
        const addresses = {
          addresses: [
            {
              longitude: this.addressesArr[this.selectedAddressIndex].longitude,
              latitude: this.addressesArr[this.selectedAddressIndex].latitude
            }
          ]
        }
        if (this.type !== 'connection') {
          await this.checkAccessInfoAddress(addresses).then((res) => {
            if (res.success) {
              this.addressesArr[this.selectedAddressIndex].has_access = res.data[0].has_access
            }
          })
        }
      }
    },
    getStreetName(street) {
      this.isAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].address = street
      }
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
    },
    addAddressToArray() {
      this.addressesArr.push({ latitude: null, longitude: null, address: '' });
    },
    removeAddress(index) {
      this.addressesArr.splice(index, 1);
    },
    openYandexModal(index) {
      this.selectedAddressIndex = index;
      this.isAddressModal = true;
    },
    open2GisModal(index) {
      this.selectedAddressIndex = index;
      this.is2gisAddressModal = true;
    },
  },
  unmounted() {
    this.clearData()
  }
}
</script>

<style scoped lang="scss">
.img-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 200ms;
}
</style>