<template>
  <div class="wrapper justify-center">
    <div class="form flex justify-center">

      <div class="form-inner">
        <h1 class="text-black2 text-center w-100 mb-2">
          {{ $route.query.edit === 'true' ? 'РЕДАКТИРОВАТЬ ЗАЯВКУ “АВАРИЯ”' : 'НОВАЯ ЗАЯВКА “АВАРИЯ”' }}</h1>
        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.city_id" :class="{'empty': !newTicket.city_id}"
                  ref="city_id">
            <option
                v-for="option in cities"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Город</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.priority_id"
                  :class="{'empty': !newTicket.priority_id}" ref="priority_id">
            <option
                v-for="option in worktime"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Приоритет</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.node_type_id"
                  :class="{'empty': !newTicket.node_type_id}" ref="node_type_id">
            <option
                v-for="option in nodeTypes"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип узла</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.executor" ref="executor" placeholder="Ответственный"
                 type="text"/>
          <label class="label-form">Ответственный</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" disabled v-model="tempNodes" ref="tempNodes" placeholder="Затронутые узлы"
                 type="text"/>
          <label class="label-form">Затронутые узлы</label>
          <div class="flex mt-1 cursor-pointer" @click="setNodesListModal(true)">
            <p style="color: #0095FF; font-size: 16px">Список узлов</p>
            <img src="/assets/up_right.svg" alt="">
          </div>
        </div>

        <!--        <div class="input-container input-container__form  mb-2">-->
        <!--          <input class="input input-form" type="text" v-model="searchNode" ref="searchNodes" placeholder="Узел подключения" @keyup="searchNodes" />-->
        <!--          <label class="label-form">Узел подключения</label>-->
        <!--          <div class="field__search-results" v-if="filteredNodes.length > 0">-->
        <!--            <div class="field__search-results_item flex justify-between" v-for="node in filteredNodes" :key="node.id" @click="selectNode(node)">-->
        <!--              <p>{{node.name}}</p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="input-btn input-btn__form">-->
        <!--            <img src="/assets/search-form.svg">-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.type_id" :class="{'empty': !newTicket.type_id}"
                  ref="type_id">
            <option
                v-for="option in types"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Категория</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.emergency_ticket_problem_type_id"
                  :class="{'empty': !newTicket.emergency_ticket_problem_type_id}"
                  ref="emergency_ticket_problem_type_id">
            <option
                v-for="option in emergencyTypeProblems"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип проблемы</label>
        </div>

        <Datepicker class="datepicker mb-2" v-model="newTicket.emergency_started_at" :clearable="false"
                    select-text="ПРИМЕНИТЬ" cancel-text="СБРОСИТЬ" :enable-time-picker="true" :format="formatDate"
                    locale="ru-RU" placeholder="Выберите дату">
          <template #dp-input="{ value }">
            <div class="input-container input-container__form mb-2">
              <input ref="emergency_started_at" class="input input-form datepicker" :class="{'filled': value}" readonly
                     type="text" placeholder="Время начала аварии" :value="value"/>
              <label class="label-form">Время начала аварии</label>
              <div class="input-btn input-btn__form">
                <img src="/assets/calendar-form.svg" alt="">
              </div>
            </div>
          </template>
        </Datepicker>

        <Datepicker class="datepicker mb-2" v-model="newTicket.planned_recovered_at" :clearable="false"
                    select-text="ПРИМЕНИТЬ" cancel-text="СБРОСИТЬ" @open="onTimePickerOpen('planned_recovered_at')"
                    :enable-time-picker="true" :format="formatDate" locale="ru-RU" placeholder="Выберите дату">
          <template #dp-input="{ value }">
            <div class="input-container input-container__form mb-2">
              <input ref="emergency_started_at" class="input input-form datepicker" :class="{'filled': value}" readonly
                     type="text" placeholder="Плановое время восстановления" :value="value"/>
              <label class="label-form">Плановое время восстановления</label>
              <div class="input-btn input-btn__form">
                <img src="/assets/calendar-form.svg" alt="">
              </div>
            </div>
          </template>
        </Datepicker>

        <Datepicker class="datepicker mb-2" v-model="newTicket.recovered_at" :clearable="false" select-text="ПРИМЕНИТЬ"
                    cancel-text="СБРОСИТЬ" :enable-time-picker="true" :format="formatDate" locale="ru-RU"
                    placeholder="Выберите дату">
          <template #dp-input="{ value }">
            <div class="input-container input-container__form mb-2">
              <input ref="emergency_started_at" class="input input-form datepicker" :class="{'filled': value}" readonly
                     type="text" placeholder="Время восстановления" :value="value"/>
              <label class="label-form">Время восстановления</label>
              <div class="input-btn input-btn__form">
                <img src="/assets/calendar-form.svg" alt="">
              </div>
            </div>
          </template>
        </Datepicker>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.abonent_cnt" ref="abonent_cnt"
                 placeholder="Кол-во абонентов" type="number"/>
          <label class="label-form">Кол-во абонентов</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.count_fiz_l" ref="count_fiz_l"
                 placeholder="Кол-во абонентов ФЛ" type="number"/>
          <label class="label-form">Кол-во абонентов ФЛ</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.count_jur_l" ref="count_jur_l"
                 placeholder="Кол-во абонентов ЮЛ" type="number"/>
          <label class="label-form">Кол-во абонентов ЮЛ</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <textarea class="input-form textarea" v-model="newTicket.decision" ref="message_for_brigade"
                    placeholder="Решение" type="text"/>
          <label class="label-form" for="body">Решение</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <textarea class="input-form textarea" v-model="newTicket.message" ref="message" placeholder="Описание"
                    type="text"/>
          <label class="label-form" for="body">Описание</label>
        </div>

        <div class="w-100 mb-2">
          <DropZone
              class="!cursor-pointer w-full h-full !border-none"
              :uploadOnDrop="false"
              ref="files"
              :multipleUpload="true"
              :parallelUpload="3">
            <template v-slot:message>
              <DropZoneInner/>
            </template>
          </DropZone>
        </div>

        <button type="button" class="btn form-btn mb-4" @click="submitForm">
          {{ $route.query.edit === 'true' ? 'изменить' : 'создать' }}
        </button>
      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        <div v-if="$route.query.edit === 'true'">
          <p>АВАРИЯ <b>#{{ createdTicketId }}</b> УСПЕШНО ИЗМЕНЕНА</p>
        </div>
        <div v-else>
          <p>АВАРИЯ <b>#{{ createdTicketId }}</b> УСПЕШНО СОЗДАНА</p>
        </div>
      </success-modal>
    </custom-modal>
    <side-modal v-if="isNodesListModal" @close="setNodesListModal(false)">
      <nodes-list-modal @addNodes="addNodes" :commutators="newTicket.commutators"></nodes-list-modal>
    </side-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {formatDate, getBase64, getFileFormat, formatDateReverse} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import {DropZone} from 'dropzone-vue';
import DropZoneInner from "@/components/static/DropZoneInner.vue";
import router from "@/router";
import Datepicker from '@vuepic/vue-datepicker';
import SideModal from "@/components/modules/SideModal.vue";
import NodesListModal from "@/components/modals/emergency/NodesListModal.vue";

export default {
  name: 'EmergencyTicketForm',
  components: {
    NodesListModal, SideModal,
    SuccessModal,
    CustomModal,
    DropZone,
    Datepicker,
    DropZoneInner,
  },
  data() {
    return {
      newTicket: {
        type_id: null,
        abonent_cnt: null,
        count_fiz_l: null,
        count_jur_l: null,
        city_id: null,
        node_type_id: null,
        emergency_ticket_problem_type_id: null,
        emergency_started_at: new Date(),
        planned_recovered_at: null,
        recovered_at: null,
        executor: '',
        decision: '',
        priority_id: null,
        message: '',
        message_for_brigade: '',
        details: [],
        nodes: [],
        attached_files: [],
        commutators: []
      },
      chosenNode: [],
      filteredNodes: [],
      searchNode: '',
      tempNodes: '',
      isSuccessModal: false,
      createdTicketId: '',
    }
  },
  created() {
    this.setLoading(true)
    let promises = [this.fetchCategories(5), this.fetchServiceTypes(5), this.fetchWorktime({ticket_kind_id: 5}), this.getTicketTypeProblems()]
    if (!this.cities || !this.cities.length) promises.push(this.fetchCities())
    if (!this.nodes || !this.nodes.length) promises.push(this.fetchNodes())
    if (!this.nodeTypes || !this.nodeTypes.length) promises.push(this.fetchNodeTypes())
    Promise.all(promises).then(() => {
      this.fetchTypes({id: this.categories[0].id})
      this.setLoading(false)
    })
    if (this.$route.query.edit === 'true' && this.chosenTicket.ticket) {
      this.newTicket = {
        ticket_id: this.chosenTicket.ticket?.id,
        abonent_cnt: this.chosenTicket.ticket?.abonent_cnt,
        count_fiz_l: this.chosenTicket.ticket?.count_fiz_l,
        count_jur_l: this.chosenTicket.ticket?.count_jur_l,
        city_id: this.chosenTicket.ticket?.city?.id,
        type_id: this.chosenTicket.ticket?.type?.id,
        node_type_id: this.chosenTicket.ticket?.node_type?.id,
        emergency_started_at: this.stringToDate(this.chosenTicket.ticket?.emergency_started_at),
        emergency_ticket_problem_type_id: this.chosenTicket.ticket?.emergency_ticket_problem_type?.id,
        planned_recovered_at: this.chosenTicket.ticket?.planned_recovered_at ? this.stringToDate(this.chosenTicket.ticket?.planned_recovered_at) : null,
        recovered_at: this.chosenTicket.ticket?.recovered_at ? this.stringToDate(this.chosenTicket.ticket?.recovered_at) : null,
        executor: this.chosenTicket.ticket?.executor,
        decision: this.chosenTicket.ticket?.decision,
        priority_id: this.chosenTicket.ticket?.priority_id,
        message: this.chosenTicket.ticket?.message,
        message_for_brigade: this.chosenTicket.ticket?.message_for_brigade,
        details: [],
        nodes: [],
        attached_files: []
      }
      if (this.chosenTicket.ticket?.nodes?.length) {
        this.chosenTicket.ticket.nodes.forEach((node, index) => {
          this.tempNodes += node.node_name
          if (index < this.chosenTicket.ticket?.nodes?.length - 1) {
            this.tempNodes += ', '
          }
        })
      }
      if (this.chosenTicket.ticket?.commutators?.length) {
        this.newTicket.commutators = this.chosenTicket.ticket?.commutators
        this.chosenTicket.ticket.commutators.forEach((commutator, index) => {
          if (commutator.port_count === commutator.ports.length) {
            this.tempNodes += commutator.commutator_name
          } else {
            this.tempNodes += `${commutator.commutator_name} [${[...commutator.ports]}]`
          }
          if (index < this.chosenTicket.ticket?.commutators?.length - 1) {
            this.tempNodes += `,`
          }
          commutator.name = commutator.commutator_name
          commutator.port_names = commutator.ports
        })
      }
    }

  },
  computed: {
    ...mapGetters({
      serviceTypes: 'serviceTypes',
      categories: 'categories',
      cities: 'cities',
      types: 'types',
      addresses: 'addresses',
      nodes: 'nodes',
      worktime: 'worktime',
      nodeTypes: 'nodeTypes',
      chosenTicket: "chosenTicket",
      emergencyTypeProblems: 'getEmergencyTypeProblems',
      isNodesListModal: 'isNodesListModal'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
      setNodesListModal: 'setNodesListModal'
    }),
    ...mapActions({
      fetchAddresses: 'fetchAddresses',
      fetchCities: 'fetchCities',
      fetchNodes: 'fetchNodes',
      fetchTypes: 'fetchTypes',
      fetchCategories: 'fetchCategories',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchNodeClients: 'fetchNodeClients',
      fetchWorktime: 'fetchWorktime',
      createEmergencyTicket: 'createEmergencyTicket',
      error: 'errorAlert',
      fetchNodeTypes: 'fetchNodeTypes',
      getTicketTypeProblems: 'getTicketTypeProblems',
      calculateClientsByNode: 'calculateClientsByNode'
    }),
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push('/emergency-tickets')
    },
    onSelectServiceType(service_type) {
      this.newTicket.service_type_id = service_type
    },
    selectNode(node) {
      this.setLoading(true)
      this.newTicket.node_id = node.id
      this.newTicket.address = node.name
      this.searchNode = node.name
      this.filteredNodes = []
      this.fetchNodeClients(node.id).then(res => {
        this.newTicket.abonent_cnt = res.data.total_clients_quantity
        this.setLoading(false)
      })
    },
    stringToDate(str) {
      let strArray = str.split(' ')
      const year = Number(strArray[0].split('.')[2])
      const month = Number(strArray[0].split('.')[1]) - 1
      const day = Number(strArray[0].split('.')[0])
      const hour = Number(strArray[1].split(':')[0])
      const minute = Number(strArray[1].split(':')[1])
      const date = new Date(year, month, day, hour, minute)
      return date
    },
    searchNodes() {
      if (this.searchNode.length === 0) {
        this.newTicket.node_id = 0
        this.newTicket.address = ''
        this.filteredNodes = []
      } else {
        this.filteredNodes = this.nodes.filter((item) => {
          if (item.name.indexOf(this.searchNode) > -1) return item
        })
      }
    },
    formatDate(date) {
      return formatDate(date, '.', true)
    },
    onTimePickerOpen(key) {
      console.log(key)
      this.newTicket[key] = new Date()
    },
    validateForm() {
      let isError = false
      for (let property in this.newTicket) {
        // if (property === 'address' || property === 'node_id') {
        //   if(!this.newTicket['address'] || !this.newTicket['node_id']) {
        //     isError = true
        //     this.$refs['chosenNode'].classList.add('error')
        //     setTimeout(() => {
        //       this.$refs['chosenNode'].classList.remove('error')
        //     }, 3000)
        //   }
        // }
        if (!this.newTicket[property] &&
            property !== 'ticket_id' &&
            property !== 'attached_files' &&
            property !== 'details' &&
            property !== 'address' &&
            property !== 'node_id' &&
            property !== 'count_fiz_l' &&
            property !== 'count_jur_l' &&
            property !== 'message_for_brigade' &&
            property !== 'planned_recovered_at' &&
            property !== 'recovered_at' &&
            property !== 'executor' &&
            property !== 'decision') {
          isError = true
          this.$refs[property].classList.add('error')
          setTimeout(() => {
            this.$refs[property].classList.remove('error')
          }, 3000)
        }
      }
      if (!this.tempNodes) {
        isError = true
        this.$refs['tempNodes'].classList.add('error')
        setTimeout(() => {
          this.$refs['tempNodes'].classList.remove('error')
        }, 3000)
      }
      return isError
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.setLoading(true)
        let file = {}
        for (const prop in this.$refs.files.all) {

          await getBase64(this.$refs.files.all[prop].file).then(data => {
            file.format = getFileFormat(this.$refs.files.all[prop].file)
            file.base64String = data.split('base64,')[1]
            this.newTicket.attached_files.push(file)
          })
          // = getBase64(this.$refs.files.all[prop].file)

        }
        if (Object.keys(this.chosenNode).length === 0) {
          this.tempNodes.split(',').forEach(str => {
            this.newTicket.nodes.push({
              name: str
            })
          })
        }

        this.newTicket.emergency_started_at = formatDateReverse(this.newTicket.emergency_started_at, '-', true)
        if (this.newTicket.planned_recovered_at) this.newTicket.planned_recovered_at = formatDateReverse(this.newTicket.planned_recovered_at, '-', true)
        if (this.newTicket.recovered_at) this.newTicket.recovered_at = formatDateReverse(this.newTicket.recovered_at, '-', true)
        // if (this.$props.scoreId) this.newTicket.score_id = this.$props.scoreId
        await this.createEmergencyTicket(this.newTicket).then(res => {
          if (res.success) {
            this.createdTicketId = res.data.ticket_number
            this.toggleSuccessModal()
          } else this.error(res.message)

          this.setLoading(false)
        })
      } else this.error('Заполните все поля')
    },
    async addNodes(nodes) {
      this.newTicket.commutators = [];
      this.chosenNode = []
      this.tempNodes = ''
      this.chosenNode.push(...nodes)


      nodes.forEach((el) => {
        if (el.iface_count === el.chosenPort.length) {
          this.tempNodes += el.device_hostname ? `${el.device_hostname}, ` : `${el.node_name}, `
        } else {
          this.tempNodes += el.device_hostname ? `${el.device_hostname} [${[...el.chosenPort]}], ` : `${el.node_name}, `
        }
      })


      this.chosenNode.forEach(el => {
        this.newTicket.commutators.push({
          id: el.device_id,
          name: el?.device_hostname || el?.device_name,
          port_names: [...el.chosenPort],
          port_count: el.iface_count
        })
      })

      const obj = this.chosenNode.map(el => ({
        id: el.device_id,
        name: el.device_hostname || el.device_name,
        port_names: [...el.chosenPort],
      }));

      this.setLoading(true)
      await this.calculateClientsByNode({commutators: obj}).then((res) => {
        if (res.success) {
          this.newTicket.abonent_cnt = res.data.total
          this.newTicket.count_fiz_l = res.data.fiz
          this.newTicket.count_jur_l = res.data.jur
        } else {
          this.$toast.error('Не удалось получить количество абонентов')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
      this.setLoading(false)
    }
  },
  unmounted() {
    this.newTicket = {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
