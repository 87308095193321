<template>
  <div class="card-grid">
    <div class="card-grid__item card-blue" v-if="cntBlock.ticket_cnt">
      <p>Общее кол-во заявок</p>
      <h3>{{cntBlock.ticket_cnt}}</h3>

      <img src="/assets/dashboard/users-card.svg" alt="">
      <div class="card-grid__gradient" />
    </div>

    <div class="card-grid__item card-orange" v-if="cntBlock.emergency_active_cnt">
      <p>Кол-во заявок типа <b>"Авария"</b></p>
      <div class="flex">
        <div class="card-grid__value" :class="{'modified': cntBlock.emergency_deadline_cnt}">
          <h3 class="cursor-pointer card-effect" @click="$router.push('/emergency-tickets')">{{cntBlock.emergency_active_cnt}}</h3>
          <p>Активные</p>
        </div>
        <div v-if="cntBlock.emergency_deadline_cnt" class="card-grid__value-divider" />
        <div class="card-grid__value" v-if="cntBlock.emergency_deadline_cnt">
          <h3>{{cntBlock.emergency_deadline_cnt}}</h3>
          <p>Просрочено</p>
        </div>
      </div>

      <img src="/assets/dashboard/warning-card.svg" alt="">
      <div class="card-grid__gradient" />
    </div>

    <div class="card-grid__item card-green" v-if="cntBlock.service_active_cnt">
      <p>Кол-во заявок типа <b>"Сервис"</b></p>
      <div class="flex">
        <div class="card-grid__value" :class="{'modified': cntBlock.service_deadline_cnt}">
          <h3 class="cursor-pointer card-effect" @click="$router.push('/tickets')">{{cntBlock.service_active_cnt}}</h3>
          <p>Активные</p>
        </div>
        <div v-if="cntBlock.emergency_deadline_cnt" class="card-grid__value-divider" />
        <div class="card-grid__value" v-if="cntBlock.service_deadline_cnt">
          <h3>{{cntBlock.service_deadline_cnt}}</h3>
          <p>Просрочено</p>
        </div>
      </div>

      <img src="/assets/dashboard/gears-card.svg" alt="">
      <div class="card-grid__gradient" />
    </div>

<!--    <div class="card-grid__item card-red" v-if="cntBlock.deadline_cnt">-->
<!--      <p>Кол-во заявок <b>Просрочено</b></p>-->
<!--      <h3>{{cntBlock.deadline_cnt}}</h3>-->

<!--      <img src="/assets/dashboard/arrow-right-down-card.svg" alt="">-->
<!--      <div class="card-grid__gradient" />-->
<!--    </div>-->

<!--    <div class="card-grid__item card-light-blue" v-if="cntBlock.service_departure_cnt">-->
<!--      <p>Кол-во заявок <b>"С Выездом"</b></p>-->
<!--      <h3>{{cntBlock.service_departure_cnt}}</h3>-->

<!--      <img src="/assets/dashboard/truck-card.svg" alt="">-->
<!--      <div class="card-grid__gradient" />-->
<!--    </div>-->

<!--    <div class="card-grid__item card-orange" v-if="cntBlock.service_non_departure_cnt">-->
<!--      <p>Кол-во заявок <b>"Без выезда"</b></p>-->
<!--      <h3>{{cntBlock.service_non_departure_cnt}}</h3>-->

<!--      <img src="/assets/dashboard/location-card.svg" alt="">-->
<!--      <div class="card-grid__gradient" />-->
<!--    </div>-->
  </div>

</template>

<script>
export default {
  name: 'CardGrid',
  props: ['cntBlock', 'timeBlock'],
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  width: 100%;
  position: relative;

  @media (max-width: 1919px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: 1279px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.card-grid__item {
  border-radius: 13px;
  height: 138px;
  padding: 28px 20px;
  background: #fff;
  position: relative;

  > p {
    margin-bottom: 14px;

    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;

    > b {
      font-weight: 600;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .card-grid__value {
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }

  img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.card-grid__value-divider {
  width: 1px;
  height: 23px;
  margin: 3px 16px;
  background: #8B88AB;
}
.card-grid__value:not(.modified) {
  > h3 {
    color: #EC3751;
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #EC3751;
  }
}

.card-blue {
  h3 {
    color: #0095FF;
  }
  .card-grid__gradient {
    width: 100%;
    height: 36px;
    border-radius: 420px;
    background: linear-gradient(88deg, rgba(0, 149, 255, 0.44) 4.26%, rgba(0, 149, 255, 0.00) 40.99%);
    filter: blur(24px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.card-orange {
  h3 {
    color: #FF6700;
  }
  .card-grid__value.modified {
    > p {
      color: #FF6700;
    }
  }
  .card-grid__gradient {
    width: 100%;
    height: 36px;
    border-radius: 420px;
    background: linear-gradient(88deg, rgba(255, 103, 0, 0.44) 4.26%, rgba(255, 103, 0, 0.00) 40.99%);
    filter: blur(24px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.card-green {
  h3 {
    color: #159C33;
  }
  .card-grid__value.modified {
    > p {
      color: #159C33;
    }
  }  
  .card-grid__gradient {
    width: 100%;
    height: 36px;
    border-radius: 420px;
    background: linear-gradient(88deg, rgba(21, 166, 54, 0.44) 4.26%, rgba(21, 169, 54, 0.00) 40.99%);
    filter: blur(24px);
    filter: blur(24px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.card-red {
  h3 {
    color: #EC3751;
  }
  .card-grid__gradient {
    width: 100%;
    height: 36px;
    border-radius: 420px;
    background: linear-gradient(88deg, rgba(236, 55, 81, 0.45) 4.26%, rgba(236, 55, 81, 0.00) 40.99%);
    filter: blur(24px);
    filter: blur(24px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.card-light-blue {
  h3 {
    color: #2C9DB9;
  }
  .card-grid__gradient {
    width: 100%;
    height: 36px;
    border-radius: 420px;
    background: linear-gradient(90deg, rgba(95, 201, 226, 0.44) 2.83%, rgba(99, 204, 229, 0.00) 33.94%);
    filter: blur(24px);
    filter: blur(24px);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.card-gray {
  background: linear-gradient(134deg, #304352 0%, #A9A9A9 100%);
  box-shadow: 0px 20px 50px 0px rgba(145, 149, 151, 0.35);
}

.card-light-green {
  background: linear-gradient(134deg, #5BE371 0%, #45B649 100%);
  box-shadow: 0px 20px 50px 0px rgba(43, 209, 131, 0.35);
}

.card-effect:hover {
  text-decoration: underline;
  transition: 200ms;
}
</style>
