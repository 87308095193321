import {api} from '@/service/axios'

export const csModule = {
    state:() => ({
        csData: [],
    }),
    getters: {
        getCsData(state) {
            return state.csData
        }
    },
    mutations: {
        setCsData(state, payload) {
            state.csData = payload
        }
    },
    actions: {
        async fetchCsData({ commit }, payload) {
            const res = await api.get('tickets/get-department-current-situation', {
                params: payload,
            })
            commit('setCsData', res.data)
        }
    }
}
