<template>
  <div class="details" v-if="isBlock == false">
    <h1 class="details-heading">Просмотр карточки пользователя</h1>
    <div class="details-column flex column">
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Фамилия</div>
          <div class="form__row_field">
            <input
              class="input-form"
              :class="{ error: errors.has('lastname') }"
              type="text"
              v-model="payload.lastname"
              ref="lastname"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Имя</div>
          <div class="form__row_field">
            <input
              class="input-form"
              :class="{ error: errors.has('firstname') }"
              type="text"
              v-model="payload.firstname"
              ref="firstname"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Отчество</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              v-model="payload.patronymicname"
              ref="patronymicname"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Login</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              v-model="payload.username"
              ref="username"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">ИИН</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="number"
              v-model="payload.iin"
              ref="iin"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Номер телефона</div>
          <div class="form__row_field">
            <input
              class="input-form"
              :class="{ error: errors.has('phone') }"
              type="text"
              v-model="payload.phone"
              v-mask="phoneMask"
              ref="phone" 
              disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Должность</div>
          <div class="form__row_field">
            <input
              class="input-form"
              :class="{ error: errors.has('position') }"
              type="text"
              v-model="payload.position"
              ref="position"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="details-column flex column">
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Роль пользователя</div>
          <div class="form__row_field">
            <select
              class="input-form select"
              :class="{ error: errors.has('role_id') }"
              v-model="payload.role_id"
              ref="role_id"
              disabled
            >
              <option disabled :value="null"></option>
              <option v-for="item in groups" :key="item.id" :value="item.id">
                {{ item.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Отдел</div>
          <div class="form__row_field">
            <select
              class="input-form select"
              :class="{ error: errors.has('department_id') }"
              v-model="payload.department_id"
              ref="department_id"
              disabled
            >
              <option disabled :value="null"></option>
              <option
                v-for="item in departments"
                :key="item.id"
                :value="item.id"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row flex">
          <div class="form__row_label">Руководитель</div>
          <div class="form__row_field">
            <input
              type="checkbox"
              v-model="payload.is_head"
            />
          </div>
          <div class="form__row_label ml-4">Dispatcher</div>
          <div class="form__row_field">
            <input
                type="checkbox"
                disabled
                v-model="payload.is_dispatcher"
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">ERP ID</div>
          <div class="form__row_field">
            <input
                class="input-form"
                type="text"
                v-model="payload.erp_id"
                ref="erpId"
                disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Битрикс ID</div>
          <div class="form__row_field">
            <input
                class="input-form"
                type="text"
                v-model="payload.bitrix_id"
                ref="bitrix"
                disabled
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Дата создания пользователя</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              disabled
              v-model="userInfo.created_date"
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Дата изменения пользователя</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              disabled
              v-model="userInfo.updated_at"
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Дата деактивации пользователя</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              disabled
              v-model="userInfo.block_date"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="details-buttons w-100 flex justify-between align-center">
      <!-- <button class="form__body_cancel" @click="isBlock = !isBlock">
        Заблокировать
      </button> -->
      <button class="form__body_submit" @click="updateUser">Сохранить</button>
    </div>
  </div>
  <div
    v-else
    class="form__body flex justify-between align-center column h-250 w-100"
  >
    <div>
      Вы действительно хотите заблокировать пользователя: <br />
      {{ userInfo.lastname }} {{ userInfo.name }} {{ userInfo.patronymicname }}?
    </div>
    <div class="flex justify-between w-100">
      <button class="form__body_cancel" @click="isBlock = !isBlock">Нет</button>
      <button class="form__body_submit" @click="blockUser">Да</button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      phoneMask: "+7 (###)-###-##-##",
      payload: {},
      originalPayload: {},
      isBlock: false,
      errors: new Set()
    };
  },
  mounted() {
    const formattedPhone = this.userInfo?.phone ? 
      this.formatPhoneNumber(this.userInfo.phone) : '';

    const initialPayload = {
      manager_id: this.userInfo?.id,
      username: this.userInfo.username,
      login: this.userInfo.username,
      lastname: this.userInfo.lastname,
      firstname: this.userInfo.name,
      patronymicname: this.userInfo.patronymicname,
      department_id: this.userInfo?.department_id,
      role_id: this.userInfo?.role?.id,
      phone: formattedPhone,
      position: this.userInfo?.position,
      block_date: this.userInfo?.block_date,
      iin: this.userInfo?.iin,
      is_head: this.userInfo?.is_head === 1,
      bitrix_id: this.userInfo?.bitrix_id,
      erp_id: this.userInfo?.erp_id,
      is_dispatcher: this.userInfo?.is_dispatcher,
    };

    this.payload = { ...initialPayload };
    this.originalPayload = { ...initialPayload };
  },
  computed: {
    ...mapGetters({
      groups: "groups",
      departments: "departments",
      userInfo: "userInfo",
    }),
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    validate() {
      const requiredFields = ['lastname', 'firstname', 'phone', 'position', 'role_id', 'department_id'];
      this.errors.clear();
      
      requiredFields.forEach(field => {
        if (!this.payload[field]) {
          this.errors.add(field);
        }
      });

      return this.errors.size === 0;
    },
    formatPhoneNumber(phone) {
      const cleaned = phone.replace(/\D/g, '');
      
      return `+7 (${cleaned.slice(1, 4)})-${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
    },
    getChangedFields() {
      const changedFields = {};
      Object.keys(this.payload).forEach(key => {
        if (this.payload[key] !== this.originalPayload[key]) {
          changedFields[key] = this.payload[key];
        }
      });
      return changedFields;
    },
    async updateUser() {
      if (this.validate()) {
        const changedFields = this.getChangedFields();
        
        if (Object.keys(changedFields).length === 0) {
          return;
        }

        const phone = this.unMask(this.payload.phone);
        const updatedPayload = {
          manager_id: this.payload.manager_id,
          ...changedFields,
          ...(changedFields.is_head !== undefined && { 
            is_head: changedFields.is_head ? 1 : 0 
          }),
          ...(changedFields.phone !== undefined && { 
            phone: '8' + this.unMask(changedFields.phone).substring(1) 
          }),
          ...(changedFields.username !== undefined && { 
            login: changedFields.username 
          })
        };

        delete updatedPayload.is_dispatcher;
        this.$emit("onSubmit", updatedPayload);
      }
    },
    async blockUser() {
      this.$emit("onBlock", { 
        manager_id: this.payload.manager_id,
        active: false 
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.details {
  display: grid;
  min-width: 40vw;
  max-height: 80vh;
  overflow-y: scroll;
  grid-template-areas:
    "a a"
    "b c"
    "d d";
  gap: 20px 60px;
  &-heading {
    grid-area: a;
    font-size: 32px;
    font-weight: 600;
  }
  &-column {
    grid-area: c;
    &:nth-child(2) {
      grid-area: b;
    }
  }
  &-buttons {
    grid-area: d;
  }
  @media screen and (max-height:900px) {
    gap: 20px 60px;
    &-heading {
      font-size: 22px;
      font-weight: 600;
    }
  }
}

.form__body_submit {
  margin: 0 auto;
  background: linear-gradient(92.74deg, #149d8f 1.61%, #35d784 101.49%);
  font-size: 22px;
  border: none;
  outline: none;
  color: white;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;
}
.form__body_cancel {
  margin: 0 auto;
  font-size: 22px;
  border: 1px solid #DA1414;
  color: #DA1414;
  background: none;
  outline: none;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;
}

.input-form.error {
  border-color: #ff4444 !important;
  background-color: rgba(255, 68, 68, 0.05);
}
</style>
