<template>
  <div class="wrapper no-menu">
    <div class="wrapper-content">
      <div class="block">
        <div class="bg-white p-20 rounded">
          <div class="w-100 p-20 border-bottom" v-if="brigades.length && connectionStatuses.length">
            <filter-bar
              :is-create="false"
              :is-search="true"
              :is-sort="false"
              :is-filter="true"
              :is-date="true"
              :search="search"
              :filters="filters"
              :date-range="dateRange"
              @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
              }"
              @on-search="searchTickets"
              @on-filter="filterModalSubmit"
            />
          </div>
          <div class="w-100 p-20" v-if="tickets.length">
            <custom-table
              :columns="tableColumns"
              :data="tickets"
              :total-pages="ticketsTotalPages"
              :current-page="currentPage"
              :is-pagination="true"
              :active-id="chosenConnectionTicket.connection_ticket?.id"
              @get-detail="getTicketDetail"
              @page-change="fetchFilteredTickets"
            />
          </div>
        </div>
      </div>
      <div class="block">
        <connection-ticket-details-component />
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import {formatDate} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import ConnectionTicketDetailsComponent from "@/components/cards/ConnectionTicketDetailsComponent.vue";
import {ConnectionTicketsTableColumns} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import router from "@/router";


export default {
  name: 'ConnectionTicketsView',
  components: {
    FilterBar,
    CustomTable,
    ConnectionTicketDetailsComponent,
    // CustomModal,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      dateRange: null,
      status_id: null,
      brigade_id: null,
      search: '',
      searchTimeout: null,
      currentPage: 1,
      perPage: 8,
    }
  },
  created() {
    if (window.screen.width < 1025) {
      this.perPage = 5
    }
    this.setLoading(true)
    let promises = [this.fetchFilteredTickets()]
    if (!this.brigades || !this.brigades.length) promises.push(this.fetchBrigades())
    if (!this.connectionStatuses || !this.connectionStatuses.length) promises.push(this.fetchConnectionStatuses())
    if (!this.connectionTypes || !this.connectionTypes.length) promises.push(this.fetchConnectionTypes())
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      tickets: 'connectionTickets',
      ticketsTotalPages: 'totalConnectionTicketsPages',
      brigades: 'brigades',
      connectionStatuses: 'connectionStatuses',
      chosenConnectionTicket: 'chosenConnectionTicket',
    }),
    tableColumns() {
      return ConnectionTicketsTableColumns
    },
    filters() {
      return [
        {
          title: 'Бригада',
          array: this.brigades,
          value: this.brigade_id,
          key: 'brigade_id',
          option_title: 'name_ru'
        },
        {
          title: 'Статус',
          array: this.connectionStatuses,
          value: this.status_id,
          key: 'status_id',
          option_title: 'title'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      createServiceTicket: 'createServiceTicket',
      fetchBrigades: 'fetchBrigades',
      fetchConnectionTickets: 'fetchConnectionTickets',
      fetchConnectionStatuses: 'fetchConnectionStatuses',
      fetchConnectionTypes: 'fetchConnectionTypes',
      fetchTicketDetail: 'fetchConnectionTicketDetail'
    }),
    ...mapMutations({
      setChosenTicket: 'setChosenTicket',
      setLoading: 'setLoading'
    }),
    searchTickets() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredTickets()
      }, 1500)
    },
    async getTicketDetail(ticket) {
      this.setLoading(true)
      await this.fetchTicketDetail(ticket.id)
      this.setLoading(false)
    },
    filterModalSubmit(filters) {
      this.brigade_id = filters.brigade_id
      this.status_id = filters.status_id
      this.fetchFilteredTickets()
    },
    async fetchFilteredTickets(page = 1) {
      this.setLoading(true)
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      this.currentPage = page
      if (this.dateFrom) filters.created_at_from = formatDate(this.dateFrom, '-')
      if (this.dateTo) filters.created_at_to = formatDate(this.dateTo, '-')
      if (this.search) filters.search = this.search



      if (this.brigade_id) filters.brigade_id = this.brigade_id
      if (this.status_id) filters.status_id = this.status_id

      await this.fetchConnectionTickets(filters)
      if (this.tickets[0]) await this.fetchTicketDetail(this.tickets[0].id)
      this.setLoading(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
