<template>
  <div class="p-20">
    <form action="" class="p-20">
      <h1 class="text-24 text-center">Создать роль</h1>
      <div>
        <div class="p-20">
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="title"
                   placeholder="Роль"/>
            <label class="label-form">Роль</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="key"
                   placeholder="Роль"/>
            <label class="label-form">Ключ</label>
          </div>
          <div v-if="roleKey" class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="role_key"
                   placeholder="Копируемая роль" disabled/>
            <label class="label-form">Копируемая роль</label>
          </div>
        </div>
      </div>
      <button class="btn" @click.prevent="createNewRole">Создать</button>

    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'CreateRoleModal',
  props: {
    roleKey: {
      type: String,
      default: ''
    },
  },
  created() {
    if (this.roleKey){
      this.role_key = this.roleKey;
    }
  },
  data() {
    return {
      title: '',
      key: '',
      role_key: null,
    }
  },
  methods: {
    ...mapActions({
      createRole: 'createRole',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async createNewRole() {
      await this.createRole({title: this.title, key: this.key, role_key: this.role_key ? this.role_key : null}).then((res) => {
        if (res.success) {
          this.$toast.success('Роль успешно создана')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось создать роль')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
  },
  unmounted() {
    this.role_key = null
  }
}
</script>