<template>
  <div>
    <div class="text-center p-20 text-20">
      <h1 class="mb-1">{{currentDutySchedule.department}}</h1>
      <p class="mb-1">{{currentDutySchedule.manager}}</p>
      <p class="text-gray">Дата начала: <span class="text-black">{{currentDutySchedule.started_at}}</span></p>
      <p class="text-gray mb-3">Дата окончания: <span class="text-black">{{currentDutySchedule.ended_at}}</span></p>
      <div class="flex gap-2">
      <button class="btn" style="background: darkred" @click="deleteCurrentSchedule">удалить</button>
        <button class="btn" @click="closeModal">отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DutyScheduleDetailModal",
  props: {
    currentDutySchedule: {
      type: {},
      default: ()=> {}
    },
  },
  methods: {
    ...mapActions({
      deleteDutySchedule: 'deleteSchedule',
      getScheduleAll: 'fetchDutyScheduleAll',
      errorAlert: 'errorAlert',
    }),
    closeModal() {
      this.$emit('close')
    },
    async deleteCurrentSchedule(){
      const obj = {
        id: this.currentDutySchedule.id
      }
      await this.deleteDutySchedule(obj).then((res)=>{
        if (res.success){
          this.getScheduleAll()
          this.$emit('close')
          this.$emit('successModal')
        } else {
          this.errorAlert(`${res.message}`)
        }
      }).catch(err=>{
        this.errorAlert(`${err.message}`)
      })
    }
  },
}
</script>



<style scoped lang="scss">

</style>