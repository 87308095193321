<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">Добавление техника</div>
    <div class="inner flex column align-center">
      <form @submit.prevent="submitData">
        <div class="input-container mb-1">
          <input class="input input-form" v-model="formData.lastname"
                 placeholder="Фамилия" type="text"/>
          <label class="label-form">Фамилия</label>
        </div>
        <div class="input-container mb-1">
          <input class="input input-form" v-model="formData.firstname"
                 placeholder="Имя" type="text"/>
          <label class="label-form">Имя</label>
        </div>
        <div class="input-container mb-1">
          <input class="input input-form" v-model="formData.patronymicname"
                 placeholder="Отчество" type="text"/>
          <label class="label-form">Отчество</label>
        </div>
        <div class="input-container mb-1">
          <input class="input input-form" v-model="formData.iin"
                 placeholder="ИИН" type="text" v-mask="'############'"/>
          <label class="label-form">ИИН</label>
        </div>
        <div class="input-container mb-1">
          <input class="input input-form" v-model="formData.phone"
                 placeholder="Номер телефона" type="text" v-mask="'+7 (###)-###-##-##'"/>
          <label class="label-form">Номер телефона</label>
        </div>
        <div class="mb-1">
          <label style="color: #ADADAD">Бригада</label>
          <multiselect
              v-model="formData.brigade_id"
              :options="parsedBrigades"
              label="name"
              track-by="id"
              placeholder="Выберите бригаду"
              class="mt-1"
          ></multiselect>
        </div>
        <div class="flex justify-center">
          <button class="btn form-btn mt-4" type="button" @click="submitData">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {validateIin} from "@/modules/validations";

export default defineComponent({
  name: 'CreateTechnicModal',
  components: {Multiselect},
  data() {
    return {
      formData: {
        lastname: '',
        firstname: '',
        patronymicname: '',
        iin: '',
        phone: '',
        brigade_id: 0
      }
    }
  },
  created() {
    this.setLoading(true)
    this.fetchBrigadeByDepartment()
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      brigadeByDepartment: 'brigadeByDepartment',
    }),
    parsedBrigades() {
      if (!this.brigadeByDepartment.length) {
        return []
      }
      return this.brigadeByDepartment.map((item) => ({
        name: item.title,
        id: item?.crm_meganet_id,
      }))
    },
  },
  methods: {
    ...mapActions({
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      createTechnic: 'createTechnic'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async submitData() {
      if (!validateIin(this.formData.iin)) {
        this.$toast.error('ИИН не верный')
      } else {
        const submissionData = {
          ...this.formData,
          brigade_id: this.formData.brigade_id.id,
          iin: this.formData.iin
        };
        await this.createTechnic({installers: [submissionData]}).then((res) => {
          if (res.success) {
            this.$toast.success('Техник успешно создан')
            this.$emit('close')
            this.$emit('refresh')
          } else {
            this.$toast.error('Не удалось создать тухника')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        })
      }
    },
  },
  unmounted() {
    this.formData.lastname = ''
    this.formData.firstname = ''
    this.formData.patronymic = ''
    this.formData.iin = ''
    this.formData.phone = ''
    this.formData.brigade_id = 0
  },
})
</script>

<style lang="scss" scoped>
.change-date {
  width: 400px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 350px;
  }
}
</style>