<template>
  <div class="wrapper justify-center">
    <div class="form flex justify-center">

      <div class="form-inner">
        <h1 class="text-black2 text-center w-100 mb-2">СОЗДАНИЕ УВЕДОМЛЕНИЯ</h1>
        <p class="text-gray2 uppercase bold text-center w-100 mb-3">выберите личный кабинет</p>

        <div class="w-100 mb-3">
          <btn-select ref="app_name" :items="appOptions" :title="'title'" :selected="newNotification.app_name" @itemClick="onSelectApp" />
        </div>

        <Datepicker class="datepicker mb-3" v-model="sendDate" :clearable="false" select-text="ПРИМЕНИТЬ" cancel-text="СБРОСИТЬ" :enable-time-picker="true" :format="formatDate" locale="ru-RU" placeholder="Выберите дату">
          <template #dp-input="{ value }">
            <div class="input-container mb-3">
              <input ref="send_at" class="input datepicker" :class="{'filled': value}" readonly type="text" placeholder="Заголовок" :value="value" />
              <label for="title">Дата и время отправки</label>
              <div class="input-btn">
                <img src="/assets/calendar-form.svg" alt="">
              </div>
            </div>
          </template>
        </Datepicker>

        <div class="input-container mb-3">
          <input ref="title" class="input" type="text" placeholder="Заголовок" v-model="newNotification.title" />
          <label for="title">Заголовок</label>
        </div>

        <div class="input-container mb-3">
          <textarea ref="body" class="input textarea" type="text" placeholder="Содержание" v-model="newNotification.body" />
          <label for="body">Содержание</label>
        </div>
        <button type="button" class="btn" @click="submitForm">создать</button>
      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        Вы успешно создали уведомление!
      </success-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {formatDate} from "@/helpers/utils";
import BtnSelect from "@/components/modules/BtnSelect.vue";
import Datepicker from '@vuepic/vue-datepicker';
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import router from "@/router";

export default {
  name: 'NotificationFormView',
  components: {
    SuccessModal,
    CustomModal,
    BtnSelect,
    Datepicker
  },
  data() {
    return {
      newNotification: {
        app_name: '',
        title: '',
        body: '',
        send_at: ''
      },
      sendDate: null,
      isSuccessModal: false,
      appOptions: [
        {
          title: 'Meganet',
          id: 'meganet'
        },
        {
          title: 'NLS',
          id: 'nls'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      createNotification: 'createNotification',
      error: 'errorAlert'
    }),
    ...mapMutations({
      setChosenTicket: 'setChosenTicket',
      setLoading: 'setLoading'
    }),
    async submitForm() {
      if (!this.validateForm()) {
        this.newNotification.send_at = formatDate(this.sendDate, '.', true)
        await this.createNotification(this.newNotification).then(res => {
          if(res.status) {
            this.toggleSuccessModal()
          }
          else this.error(res.message)
        })
      } else this.error('Заполните все поля')
    },
    formatDate(date) {
      return formatDate(date, '.', true)
    },
    onSelectApp(app) {
      this.newNotification.app_name = app
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push('/push-notifications')
    },
    validateForm() {
      let isError = false
      for(let property in this.newNotification) {
        if(property === 'send_at') {
          if (this.formatDate(this.sendDate).length === 0) {
            isError = true
            this.$refs[property].classList.add('error')
            setTimeout(() => {
              this.$refs[property].classList.remove('error')
            }, 3000)
          }
        }
        else if(!this.newNotification[property]) {
          isError = true
          this.$refs[property].classList.add('error')
          setTimeout(() => {
            this.$refs[property].classList.remove('error')
          }, 3000)
        }
      }
      return isError
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
