import { api } from "@/service/axios";
import { formatDateReverse } from "@/helpers/utils";
import QRCode from "qrcode";
export const dashboardModule = {
  state: () => ({
    dashboard: {},
    isDashboardFiltersModal: false,
    dashboardFilters: {
      date_from: null,
      date_to: null,
      status: null,
      qr: ''
    }
  }),
  getters: {
    dashboard(state) {
      return state.dashboard
    },
    isDashboardFiltersModal(state) {
      return state.isDashboardFiltersModal
    },
    dashboardFilters(state) {
      return state.dashboardFilters
    },
    qr(state) {
      return state.qr
    },
  },
  mutations: {
    setDashboard(state, payload) {
      state.dashboard = payload
    },
    setIsDashboardFiltersModal(state, payload) {
      state.isDashboardFiltersModal = payload
    },
    setDashboardFilters(state, payload) {
      state.dashboardFilters = payload
    },
    setQR(state, payload) {
      state.qr = payload
    }
  },
  actions: {
    async fetchDashboardInfo({ state, commit }) {
      commit('setLoading', true);
      const res = await api.get(`dashboard/get-info`, {
        params: {
          date_from: state.dashboardFilters.date_from
            ? formatDateReverse(state.dashboardFilters.date_from, '-') : undefined,
          date_to: state.dashboardFilters.date_to
            ? formatDateReverse(state.dashboardFilters.date_to, '-') : undefined,
          status: state.dashboardFilters.status ?? undefined,
        }
      });
      commit('setDashboard', res.data);
      commit('setLoading', false);
    },
    openDashboardFiltersModal({ commit }) {
      commit('setIsDashboardFiltersModal', true);
    },
    closeDashboardFiltersModal({ commit }) {
      commit('setIsDashboardFiltersModal', false);
    },
    async setDashboardFilters({ dispatch, commit }, payload) {
      commit('setDashboardFilters', payload);
      await dispatch('fetchDashboardInfo');
    },
    async getQRData({commit}, payload) {
      const res = await api.get(`get-qr-data?iin=${payload}`)
      const qrUrl = await QRCode.toDataURL(res.data.qr_data)
      commit('setQR', qrUrl)
    }
  }
}
