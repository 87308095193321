<template>
  <div class="wrapper modified flex column">
    <div class="mb-3">
      <div class="bg-white rounded" style="padding: 12px 16px;">
        <div>
          <div>
            <div class="p-20 rounded">
              <div>
                <filter-bar
                    :is-filter="true" :is-date="true"
                    :isStatuses="true"
                    @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
              }"
                    @on-create="toggleCreateModal"
                    @on-search="searchTickets"
                    @on-filter="filterModalSubmit"
                />
              </div>
            </div>
          </div>
          <custom-table
              :columns="planTableColumns"
              :data="planTickets"
              :total-pages="planTicketsTotalPages"
              :current-page="planCurrentPage"
              @get-detail="openPlanTicket"
              @page-change="fetchFilteredPlanTickets"
              @getMiddleDetail="openPlanTicketMiddle"
          />
        </div>
      </div>

    </div>


    <custom-modal v-if="isCreateTicketModal" @close="toggleCreateModal">
      <choose-ticket-type @choose-ticket-kind="chooseTicketKind"/>
    </custom-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import CustomModal from "@/components/modules/CustomModal.vue";
import ChooseTicketType from "@/components/modals/tickets/ChooseTicketType.vue";
import {EmergencyTicketsTableColumns} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import router from "@/router";
import CurrentSituationComment from "@/components/modals/CurrentSituationComment.vue";
import {TicketRouterNavigationMixin} from "@/mixins/TicketRouterNavigationMixinModule";
import {formatDate} from "@/helpers/utils";


export default {
  name: 'PlanTicketView',
  components: {
    FilterBar,
    CustomTable,
    ChooseTicketType,
    CustomModal,
  },
  mixins: [TicketRouterNavigationMixin],
  data() {
    return {
      planTableColumns: [],
      isCreateTicketModal: false,
      emergencyCurrentPage: 1,
      emergencyPerPage: 5,
      isSuccessModal: false,
      isCurrentSituationComment: false,
      currentComment: [],
      status_ids: [],
      planCurrentPage: 1,
      dateRange: null,
    }
  },
  created() {
    this.fetchFilteredPlanTickets()
  },
  computed: {
    ...mapGetters({
      planTickets: 'planTickets',
      user: 'user',
      ticketKinds: 'ticketKinds',
      serviceTypes: 'serviceTypes',
      managers: 'managers',
      chosenTicket: 'chosenTicket',
      ticketCounts: 'ticketCounts',
      planTicketsTotalPages: 'planTicketsTotalPages',
      planTotalTickets: 'planTotalTickets',
    }),
  },
  methods: {
    ...mapActions({
      fetchTickets: 'fetchTickets',
      fetchTicketKinds: 'fetchTicketKinds',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchManagers: 'fetchManagers',
      fetchTicketDetail: 'ticketDetail',
      fetchPlanTickets: 'fetchPlanTickets',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async chooseTicketKind(id) {
      this.toggleCreateModal()
      if (id === 2) {
        await router.push('/service-ticket-form')
      } else if (id === 8) {
        await router.push('/plan-ticket-form')
      } else if (id === 10) {
        await router.push('/consultation-ticket-form')
      } else if (id === 12) {
        await router.push('/internal-ticket-form')
      } else if (id === 16) {
        await router.push('/access-form-view')
      } else await router.push('/emergency-ticket-form')
    },
    toggleCreateModal() {
      this.isCreateTicketModal = !this.isCreateTicketModal
    },
    openPlanTicket(ticket) {
      this.ticketRouterNavigationFunc(ticket)
    },
    openPlanTicketMiddle(ticket) {
      this.ticketRouterNavigationFunc(ticket, true)
    },
    searchTickets(searchText) {
      this.search = searchText
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredEmergencyTickets()
      }, 1500)
    },
    filterModalSubmit(filters, statuses) {
      if (Array.isArray(statuses) && statuses.length > 0) {
        this.status_ids = statuses
      } else {
        this.status_ids = []
      }
      this.fetchFilteredPlanTickets()
    },
    async fetchFilteredPlanTickets(page = 1) {
      this.setLoading(true)
      let filters = {}
      if (this.dateRange && Array.isArray(this.dateRange)) {
        if (this.dateRange[0]) filters.date_from = formatDate(this.dateRange[0], '-')
        if (this.dateRange[1]) filters.date_to = formatDate(this.dateRange[1], '-')
      }
      if (this.$route.query.is_expired) filters.is_expired = this.$route.query.is_expired
      if (this.$route.query.is_only_open) filters.is_only_open = this.$route.query.is_only_open
      if (this.status_ids.length > 0) filters.status_ids = this.status_ids
      await this.fetchPlanTickets(filters)
      this.setLoading(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.table-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 43.2px;
  margin: px 0;
}
</style>
