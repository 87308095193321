<template>
  <div class="flex column">
    <div class="table">
      <div class="table-header">
        <div class="table-row">
          <draggable :list="newTableColumns" tag="template" class="table-cell" :item-key="key => key.value"
                     @end="checkMove(newTableColumns)">
            <template #item="{ element: column }">
              <div class="table-cell" :class="{'long': column.isLong}" @click="columnSort(column)">
                <template v-if="column.title === 'checkbox'">
                  <input class="table-checkbox" type="checkbox" id="ticket-table-chekbox-head"
                         :checked="isChecked(data)" @change="changeTicket(data)">
                  <label class="table-checkbox__label" for="ticket-table-chekbox-head">
                    <span>&#10004;</span>
                  </label>
                </template>
                <template v-else>{{ column.title }}</template>
                <template v-if="sortParams && column.sort">
                  <div class="table-sort" v-if="sortParams.value === column.value">
                    <img class="table-sort__arrow" :class="{'up': sortParams.order === 'asc'}"
                         src="/assets/arrow-down.svg" alt="">
                  </div>
                </template>
                <template v-if="column.title === 'Регистратор' && sortRegistratorParams?.order">
                  <div>
                    <img class="table-sort__arrow" :class="{'up': sortRegistratorParams.order === 'asc'}"
                         src="/assets/arrow-down.svg" alt="">
                  </div>
                </template>
              </div>
            </template>
          </draggable>
          <!--          <div class="table-cell" v-for="column in columns" :key="column.value" :class="{'long': column.isLong}" @click="columnSort(column)">-->
          <!--            {{column.title}}-->
          <!--            <template v-if="sortParams && column.sort">-->
          <!--              <div class="table-sort" v-if="sortParams.value === column.value">-->
          <!--                <img class="table-sort__arrow" :class="{'up': sortParams.order === 'asc'}" src="/assets/arrow-down.svg" alt="">-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="table-body" v-if="data.length">
        <div class="table-row" v-for="item in data" :key="item.id" @click.middle.stop="middleDetail(item)" @click.stop="detail(item)"  @click.prevent="openModal(item)"
             :class="[{'active': activeId === item.id}, {'bg-red': isMoreThan24Hours(item)}]">
          <div class="table-cell" v-for="column in newTableColumns" :key="column.value"
               :class="{'column justify-center': column.second, 'align-center': !column.second, 'long': column.isLong, 'block': column.value === 'commutators' || column.value === 'addresses'}">
            <!--          <template v-if="column.value === 'work_time'">{{minutesToTime(item[column.value])}}</template>-->
            <template v-if="column.title === 'checkbox'">
              <div class="table-checkbox__body">
                <input class="table-checkbox" type="checkbox" :id="`ticket-table-chekbox-${item.id}`"
                       :checked="isChecked(item)">
                <label class="table-checkbox__label table-checkbox__label-in"
                       :for="`ticket-table-chekbox-${item.id} table-checkbox__label`" @click.stop="changeTicket(item)">
                  <span>&#10004;</span>
                </label>
              </div>
            </template>
            <template v-if="column.title === 'Копировать'">
              <img src="/assets/list.svg" @click.stop="copyValue(item)"/>
            </template>

            <template v-if="column.value === 'status'">
              <div class="status-badge flex align-center">
                <img
                    :src="item[column.value].key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
                    alt="">
                {{ item[column.value][column.inner] }}
              </div>
            </template>
            <template v-if="column.value === 'client'">
              {{item[column.value]?.full_name || (item?.connection_clients && item?.connection_clients[0]?.name)}}
            </template>
            <template v-else-if="column.value === 'nodes'">
              <p v-for="(node, index) in item[column.value]" :key="node.id">{{ node.node_name }}
                {{ index < item[column.value].length - 1 ? ',' : '' }}</p>
            </template>

            <template v-else-if="column.value === 'commutators' && column.another_value === 'nodes'">
              <div v-if="item[column.value].length">
                <div style="width: 100%" v-for="(commutator, index) in item[column.value]" :key="commutator">
                  <p>{{index+1}}) {{ commutator.commutator_name}}</p>
                </div>
              </div>
              <div v-if="item[column.another_value].length && !item[column.value].length">
                <div v-for="node in item[column.another_value]" :key="node">
                  <p>{{node.node_name}}</p>
                </div>
              </div>
            </template>
            <template v-else-if="column.value === 'photo_uri'">
              <img :src="item[column.value]"
                   v-if="item[column.value]"
                   @click="toggleImageModal(item[column.value])"
                   alt="Image"
                   style="width: 50px"
              />
            </template>
            <template v-else-if="column.value === 'active_status'">
              <div v-if="item[column.value]" style="color: #159C33">Активный</div>
              <div v-else class="text-red">Деактивироанный</div>
            </template>
            <template v-else-if="column.value === 'action'">
              <div class="flex gap-2">
                <img src="/assets/pen-edit.svg" @click="openEditModal(item?.id)"/>
                <img src="/assets/map.svg" @click="openCurrentMap(item?.polygons)"/>
                <img v-if="user?.role?.key === 'dev_super_admin'" src="/assets/delete-icon-grey.svg" @click.stop="deleteArea(item)" alt="Delete"/>
              </div>
            </template>
            <template v-else-if="column.value === 'coordinates'">
              <div @click="openMap([[item.start_latitude, item.start_longitude], [item.end_latitude, item.end_longitude]])" class="flex column text-16 link cursor-pointer">
                <div>1 точка: {{item.start_latitude + ', ' + item.start_longitude}}</div>
                <div>2 точка: {{item.end_latitude + ', ' + item.end_longitude}}</div>
              </div>
            </template>
            <template v-else-if="column.value === 'agreement'">
              <p v-for="(agrm, index) in item[column.value]" :key="agrm.number">{{ index + 1 }}. agrm.number</p>
            </template>
            <template v-else-if="column.value === 'addresses'">
              <div v-for="address in item[column.value]" :key="address">
                <p>{{address.address}}</p>
              </div>
            </template>
            <template v-else-if="column.value === 'message_current_situation'">
              <div v-if="!item[column.value]" @click.stop="updateComment(item)" class="comment_edit">
                <p style="color: #0095FF">Добавить комментарий </p>
              </div>
              <div v-else @click.stop="updateComment(item)" class="comment_edit" style="word-break: break-all">{{ item[column.value] }}</div>
            </template>
            <template v-else-if="item[column.value] && column.value !== 'status'">
              <p>{{ column.inner ? item[column.value]?.[column.inner] : item[column.value] }}</p>
              <span v-if="column.second">{{
                  column.secondInner ? item[column.second] ? item[column.second]?.[column.secondInner] : '' : item[column.second]
                }}</span>
            </template>
            <div v-else-if="column.combined">
              <p v-for="combinedItem in column.combined" :key="combinedItem.path">
                {{
                  combinedItem.wrapAroundWith ? combinedItem.wrapAroundWith(stringToObject(item, combinedItem.path)) : stringToObject(item, combinedItem.path)
                }}
              </p>
            </div>
            <div v-else-if="column.path">
              <p>
                {{ stringToObject(item, column.path) }}
              </p>
            </div>

            <img class="ml-2" v-if="column.icon" :src="column.icon" alt="">
          </div>
        </div>
      </div>
      <div v-if="!data.length" class="w-100 text-center text-gray mt-4">Данных нет</div>


    </div>

    <div class="table-pagination mt-2" v-if="data.length && isPagination && totalPages">
      <div class="table-pagination__item" @click="prevPage">
        <img class="table-pagination__arrow" src="/assets/chevron-left.svg" alt="">
        <img class="table-pagination__arrow-hover" src="/assets/chevron-left-w.svg" alt="">
      </div>
      <div class="table-pagination__item" v-for="page in pagination(currentPage, totalPages)" :key="page"
           @click="changePage(page)" :class="{'active': page === currentPage}">
        {{ page }}
      </div>
      <div class="table-pagination__item" @click="nextPage">
        <img class="table-pagination__arrow" src="/assets/chevron-right.svg" alt="">
        <img class="table-pagination__arrow-hover" src="/assets/chevron-right-w.svg" alt="">
      </div>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
    <custom-modal v-if="isOpenMap" @close="toggleOpenModal">
      <points-info-map :coordinates="lineCoordinates" :key="mapRefreshKey"/>
    </custom-modal>
    <custom-modal v-if="isOpenCurrentMap" @close="toggleOpenModalWithCurrentMap">
      <current-areas-map-component  :currentPolygons="currentPolygons" :key="mapRefreshKey" />
    </custom-modal>
    <custom-modal v-if="isOpenEditModal" @close="isOpenEditModal = !isOpenEditModal">
      <create-area-form @close="isOpenEditModal = !isOpenEditModal" :type="'edit'" :area-id="selectedAreaId"/>
    </custom-modal>
    <delete-confirmation-modal
      v-if="showDeleteConfirmation"
      :area-name="selectedAreaName"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {maskPhone} from "@/helpers/utils";
import draggable from "vuedraggable";
import ImageModal from "@/components/modals/ImageModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import PointsInfoMap from "@/components/cards/ticketsLCS/PointsInfoMap.vue";
import CurrentAreasMapComponent from "@/components/cards/areas/CurrentAreasMapComponent.vue";
import CreateAreaForm from "@/components/modals/areas/CreateAreaForm.vue";
import DeleteConfirmationModal from "@/components/modals/DeleteConfirmationModal.vue";

export default {
  name: 'CustomTable',
  components: {
    CreateAreaForm,
    CurrentAreasMapComponent,
    PointsInfoMap,
    CustomModal,
    ImageModal,
    draggable,
    DeleteConfirmationModal
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    totalPages: Number,
    currentPage: Number,
    isPagination: Boolean,
    isActions: Boolean,
    isHighlight: Boolean,
    sortParams: Object,
    sortRegistratorParams: Object,
    perPage: Number,
    headerStyle: String,
    activeId: Number,
  },
  data() {
    return {
      newTableColumns: [],
      image: '',
      isOpenMap: false,
      lineCoordinates: [],
      mapRefreshKey: 1,
      isOpenCurrentMap: false,
      currentPolygons: [],
      isOpenEditModal: false,
      selectedAreaId: 0,
      showDeleteConfirmation: false,
      areaToDelete: null,
      selectedAreaName: '',
      selectedAreaForDelete: null
    }
  },
  mounted() {
    this.newTableColumns = this.columns
    const res = this.columns.some((column)=> 'isChecked' in column)
    if (res){
      this.updateNewTableColumns(this.columns)
    }
  },
  watch: {
    columns: {
      handler(newValue, oldValue) {
        this.updateNewTableColumns(newValue);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    isChecked(item) {
      if (Array.isArray(item)) {
        return item.every(subItem => this.getChangedTicketIds.some(ticket => ticket.id === subItem.id));
      }
      return this.getChangedTicketIds.some(ticket => ticket.id === item.id);
    },
    changeTicket(id) {
      this.$emit('changedTicket', id)
    },
    detail(id) {
      this.$emit('getDetail', id)
    },
    openModal(id) {
      this.$emit('openEditModal', id)
    },
    middleDetail(id){
      this.$emit('getMiddleDetail', id)
    },
    editTicket(id) {
      this.$emit('editTicket', id)
    },
    sendTicket(id) {
      this.$emit('sendTicket', id)
    },
    createTicket(id) {
      this.$emit('createTicket', id)
    },
    feedBack(id) {
      this.$emit('feedBack', id)
    },
    changeStatus(id, status_id) {
      this.$emit('changeStatus', {
        id,
        status_id
      })
    },
    stringToObject(object, pathStr) {
      return pathStr.split('.').reduce((obj, key) => {
        return obj && obj[key] !== 'undefined' ? obj[key] : undefined;
      }, object)
    },
    checkMove(e) {
      this.$emit('moveColumn', e)
    },
    changePage(page) {
      if (page !== '...' && page !== this.currentPage) this.$emit('pageChange', page)
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.$emit('pageChange', this.currentPage + 1)
    },
    prevPage() {
      if (this.currentPage > 1) this.$emit('pageChange', this.currentPage - 1)
    },
    columnSort(column) {
      console.log(column)
      if (column.sort) this.$emit('sortColumn', column.value)
      if (column.title === 'Регистратор'){
        this.$emit('sortRegistrator')
      }
    },
    phone(phone) {
      return maskPhone(phone)
    },
    changePerPage(event) {
      this.$emit('changePerPage', event.target.value)
    },
    minutesToTime(minutes) {
      const hours = Math.floor(minutes / 60)
      return `${hours}:${minutes - (hours * 60)}:00`
    },
    copyValue(id) {
      this.$emit('copyValue', id)
    },
    updateComment(item) {
      this.$emit('updateComment', item)
    },
    pagination(current, total) {
      let delta = 1,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

      for (let i = 1; i <= total; i++) {
        if (i == 1 || i == total || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    isMoreThan24Hours(item) {
      if (item.category){
        if (item.category.key === 'emergency'){
          const [datePart, timePart] = item.emergency_started_at.split(' ');
          const [day, month, year] = datePart.split('.');
          const formattedDate = `${year}-${month}-${day}T${timePart}:00`;
          const createdAt = new Date(formattedDate);
          const now = new Date();
          const diffInHours = (now - createdAt) / (1000 * 60 * 60);
          return diffInHours > 24;
        }
      }
    },
    updateNewTableColumns(columns) {
      this.newTableColumns = columns.filter(column => column.isChecked);
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    openMap(coordinates) {
      ++this.mapRefreshKey
      this.lineCoordinates = coordinates
      this.isOpenMap = true
    },
    openCurrentMap(polygons) {
      ++this.mapRefreshKey
      this.currentPolygons = polygons
      this.isOpenCurrentMap = true
    },
    openEditModal(id) {
      ++this.mapRefreshKey
      this.selectedAreaId = id
      this.isOpenEditModal = true
    },
    toggleOpenModal() {
      this.isOpenMap = !this.isOpenMap
      this.lineCoordinates = []
    },
    toggleOpenModalWithCurrentMap() {
      this.isOpenCurrentMap = !this.isOpenCurrentMap
      this.lineCoordinates = []
    },
    deleteArea(item) {
      this.selectedAreaName = item.area_name;
      this.selectedAreaForDelete = item;
      this.showDeleteConfirmation = true;
    },
    confirmDelete() {
      this.$emit('deleteArea', this.selectedAreaForDelete.id);
      this.showDeleteConfirmation = false;
      this.selectedAreaName = '';
      this.selectedAreaForDelete = null;
    },
    cancelDelete() {
      this.showDeleteConfirmation = false;
      this.selectedAreaName = '';
      this.selectedAreaForDelete = null;
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      chosenTicket: 'chosenTicket',
      getChangedTicketIds: 'getChangedTicketIds'
      // taskScoreStatuses: 'taskScoreStatuses'
    }),

  },
}
</script>

<style lang="scss" scoped>
.comment_edit:hover {
  text-decoration: underline;
  transition: 200ms;
}
.bg-red {
  background: #f38a8a !important;
}
</style>
