<template>
  <div class="wrapper wrapper-content modified flex column bg">
    <div class="bg-white p-20">

      <div class="flex items-center gap-2">
        <div class="w-30" @click="showDropdownCalendar = !showDropdownCalendar">
          <calendar-departure :show-dropdown-calendar="showDropdownCalendar" :date-from="date_from" :date-to="date_to"
                              @setDate="setDate" :type="'departments-tickets'"></calendar-departure>
        </div>

        <div class="w-30 relative">
          <label>Отдел</label>
          <button class="clear-btn cursor-pointer" @click="clearBtn('department')">
            <img v-if="connection_department" src="/assets/close.svg" height="16" width="16"/></button>
          <select class="input-form select mt-1" v-model="connection_department"
                  style="height: 48px !important; border-radius: 25px !important;" @change="departmentFilter">
            <option :value=null>Все</option>
            <option
                v-for="option in serviceDepartments"
                :key="option.id"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-100 flex justify-center rounded mt-2 p-20" style="background: #00bfff;font-weight: 500">
        <p class="">Открытых заявок на данный момент {{ serviceTickets?.tickets?.length }}</p>
      </div>
      <div class="flex justify-between items-center mt-2">
        <div class="w-100 flex" style="align-items: end">


          <div class="mt flex flex-wrap" style="margin-left:auto;justify-content: flex-end;align-items: end">
            <div>
              <button class="ticket-menu flex justify-center gap-1 align-center cursor-pointer mr-2 navs"
                      style="background: #2ab161;color: white;" @click="downloadExcel">
                <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
                <div>Выгрузить</div>
              </button>
            </div>
            <div style="width: 200px;">
              <div class="mb-1">Показывать по</div>
              <AppSelect
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  @change="onPerPageChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content w-100 mt-4 flex  column">
        <div class="grid gridText" style="">
          <div class="grid-id flex align-center justify-center" @click="sortedData('id')">
            <p class="text-center">Номер заявки</p>
          </div>
          <div class="grid-name flex align-center justify-center">
            <p class="text-center">Клиент</p>
          </div>
          <div class="grid-category flex align-center justify-center" @click="sortedData('address')">
            <p class="text-center">Категория</p>
          </div>
          <div class="grid-created flex align-center justify-center" @click="sortedData('city')">
            <p class="text-center">Дата и время создания</p>
          </div>
          <div class="grid-status flex align-center justify-center">
            <p class="text-center">Статус</p>
          </div>
          <div class="grid-author cursor-pointer flex align-center justify-center">
            <p class="text-center">Регистратор</p>
          </div>
          <div class="grid-responsible flex align-center justify-center" @click="sortedData('responsible')">
            <p class="text-center">Ответственный</p>
          </div>
          <div class="grid-life-time flex align-center justify-center">
            <p class="text-center">Время жизни</p>
          </div>
        </div>
        <div class="departure-table" v-if="serviceTickets">
          <div class="grid gridText rounded cursor-pointer" :class="{'bg-red': isMoreThan24Hours(list)}" :id="list.id"
               @click="getTicketDetail(list)"
               v-for="list in serviceTickets.tickets" :key="list.id">
            <div class="grid-id flex justify-center items-center">
              <div style="padding: 2px 4px" @click="openTicket(list)" @click.middle="openTicket(list)">
                <div class="text-center cursor-pointer">
                  <div>{{ list.ticket_number }}</div>
                </div>
              </div>
            </div>
            <div class="grid-name flex gap-1 align-center" @click="openClientInfo(list)">
              <p class="">{{ list?.client?.full_name || 'Не указано' }}</p>
              <div v-if="!list.client?.is_active"><img src="/assets/warning-sign-svgrepo-com.svg" width="16" height="16"
                                                      alt=""></div>
            </div>
            <div class="grid-category flex align-center">
              <div>{{ list?.category?.title || 'Не указано' }}</div>
            </div>
            <div class="grid-created flex align-center">
              <div>{{ list?.created_at || 'Не указано' }}</div>
            </div>
            <div class="grid-status flex align-center">
              <div class="text-center">{{ list?.status?.title || 'Не указано' }}</div>
            </div>
            <div class="grid-author flex align-center link" @click="openManagerCard(list?.author?.id)">
              <div>{{ list?.author?.full_name || 'Не указано' }}</div>
            </div>
            <div class="grid-responsible flex align-center link" @click="openManagerCard( list?.responsible?.id)">
              <div>{{ list?.responsible?.title || 'Не указано' }}</div>
            </div>
            <div class="grid-life-time flex align-center">
              <div>{{ list?.life_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent :total-pages="totalPages" :current-page="page"
                           @page-change="onPageChange"></PaginationComponent>
    </div>
  </div>
  <custom-modal v-if="isManagerInfoModal" @close="isManagerInfoModal = !isManagerInfoModal">
    <manager-info-modal></manager-info-modal>
  </custom-modal>
</template>


<script>
import CalendarDeparture from "@/components/calendar/calendar-departure.vue";
import AppSelect from "@/components/ui/AppSelect.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import router from "@/router";
import ManagerInfoModal from "@/components/modals/ManagerInfoModal.vue";

export default {
  name: "DepartmentTicketsView",
  components: {
    ManagerInfoModal, PaginationComponent, AppSelect, CalendarDeparture, CustomModal
  },
  async created() {
    this.setLoading(true)
    await this.fetchServiceDepartments()
    this.setFirstLoad()
    this.setLoading(false)

  },
  data() {
    return {
      perPage: 100,
      page: 1,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 500],
      date_from: null,
      date_to: null,
      showDropdownCalendar: false,
      connection_department: null,
      params: {
        page: this.page ? this.page : 1,
        tickets_per_page: this.perPage ? this.perPage : 100,
        client_name: null,
        use_saved_filters: 1,
        date_from: null,
        date_to: null,
        responsible_manager_id: null,
        responsible_department_id: null,
        responsible_brigade_id: null,
        on_departure: 1,
        taken_tasks: 1,
        without_brigade_history: 1,
        is_only_open: 1,
      },
      isManagerInfoModal: false,
    }
  },
  computed: {
    ...mapGetters({
      serviceTickets: 'ticketsByDepartment',
      ticketsByDepartmentFilters: 'ticketsByDepartmentFilters',
      serviceDepartments: 'serviceDepartments',
    }),
    totalPages() {
      return Math.ceil((this.serviceTickets?.meta?.total || 0) / this.perPage);
    },
  },
  watch: {
    params: {
      async handler(newValue, oldValue) {
        await this.watcherFunction()
      },
      deep: true
    },
  },
  methods: {
    ...mapMutations({
      sortTickets: 'sortTickets',
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchDepartureTickets: 'fetchTicketsByDepartment',
      fetchClientData: 'fetchClientData',
      fetchDepartureServiceClosedInfo: 'fetchDepartureServiceClosedInfo',
      makeExcelList: 'makeExcelList',
      makeDepartmentExcelList: 'makeDepartmentExcelList',
      fetchServiceDepartments: 'fetchServiceDepartments',
      setDepartment: 'setDepartment',
      fetchManagerPhones: 'fetchManagerPhoneNumbers',
    }),
    async openClientInfo(list) {
      this.setLoading(true)
      await this.fetchClientData(list.client?.lanbilling_id)
      this.setLoading(false)
    },
    sortedData(sortedValue) {
      this.sortTickets(sortedValue)
    },
    onPageChange(page = 1) {
      this.page = page
      this.params.page = page
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.params.tickets_per_page = perPage
    },
    async watcherFunction() {
      this.setLoading(true)
      await this.fetchDepartureTickets(this.params)
      await this.fetchDepartureServiceClosedInfo(this.params)
      this.setLoading(false)
    },
    setDate(data) {
      if (data) {
        this.date_from = data?.[0]
        this.date_to = data?.[1]
        this.params.date_from = this.date_from
        this.params.date_to = this.date_to
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
        delete this.params.date_from
        delete this.params.date_to
      }
    },
    departmentFilter() {
      this.params.responsible_department_id = this.connection_department
      this.params.taken_tasks = 1
    },
    openTicket(ticket) {
      this.setLoading(true)
      router.push(`/ticket/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    async downloadExcel() {
      this.setLoading(true)

      const res = await this.makeDepartmentExcelList({
        ...this.params,
        download: 1,
        is_only_open: 1
      })
      const url = window.URL.createObjectURL(res);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `отчеты.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    clearBtn(value) {
      if (value === 'department') {
        this.connection_department = null
        this.params.responsible_department_id = null
      }
    },
    setFirstLoad() {
      if (this.ticketsByDepartmentFilters) {
        //department
        if (this.ticketsByDepartmentFilters.responsible_department_id) {
          // this.connection_department = this.ticketsByDepartmentFilters.responsible_department_id
          // this.params.responsible_department_id = this.ticketsByDepartmentFilters.responsible_department_id
        }
        //per page
        if (this.ticketsByDepartmentFilters.tickets_per_page) {
          this.perPage = this.ticketsByDepartmentFilters.tickets_per_page
          this.params.tickets_per_page = this.ticketsByDepartmentFilters.tickets_per_page
        }
        //date
        if (this.ticketsByDepartmentFilters.date_from && this.ticketsByDepartmentFilters.date_to) {
          this.date_from = this.ticketsByDepartmentFilters.date_from
          this.date_to = this.ticketsByDepartmentFilters.date_to
          this.params.date_from = this.ticketsByDepartmentFilters.date_from
          this.params.date_to = this.ticketsByDepartmentFilters.date_to
        }
      }
    },
    async openManagerCard(id) {
      this.setLoading(true)
      await this.fetchManagerPhones(id).then(() => {
        this.isManagerInfoModal = true
        this.setLoading(false)
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    isMoreThan24Hours(item) {
      const [datePart, timePart] = item.created_at.split(' ');
      const [day, month, year] = datePart.split('.');
      const formattedDate = `${year}-${month}-${day}T${timePart}:00`;
      const createdAt = new Date(formattedDate);
      const now = new Date();
      const diffInHours = (now - createdAt) / (1000 * 60 * 60);
      return diffInHours > 24;
    },
    async getTicketDetail(ticket) {
      this.setLoading(true)
      router.push(`/ticket/${ticket.ticket_number}`)
      this.setLoading(false)
    },
  },
}
</script>


<style scoped lang="scss">
@import '../../theme/variables';

.selected {
  border: 4px solid black;
}

.grid {
  display: grid;
  gap: 1px;
  align-items: center;
  padding: 1px 0;
  grid-template-columns: minmax(0, 1.5fr) minmax(0, 2fr) minmax(0, 1fr) minmax(0, 2fr) minmax(0, 3fr) minmax(0, 1.5fr) minmax(0, 2fr) minmax(0, 2fr);
  grid-template-areas: 'Id Name Category Created Status Author Responsible LifeTime';
}

.grid-num {
  border: 1px solid black;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-area: Num;
}


.grid-id {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  align-items: center;
  grid-area: Id;
}

.grid-name {
  border: 1px solid black;
  border-radius: 10px;
  grid-area: Name;
  padding: 4px 10px;
  height: 100%;
}

.grid-category {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Category;
}

.grid-created {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Created;
}

.grid-status {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Status;
}

.grid-author {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 4px;
  grid-area: Author;
}

.grid-responsible {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Responsible;
}

.grid-life-time {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 2px;
  grid-area: LifeTime;
}

.brigade-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.departure-table {
  :hover {
    background-color: #f1f1f1 !important;
  }

  .grid {
    &-Creator:hover {
      text-decoration: underline;
      transition: 200ms;
      font-weight: bold;
    }

    &-Plan {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-id {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-responsible {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-planned {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-name {
      &:hover {
        cursor: pointer;
        transition: 200ms;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &-closed {
      &:hover {
        cursor: default;
        font-weight: normal;
        text-decoration: none;
      }
    }

    &-blue {
      color: #2A5298
    }
  }
}

.ticket-menu {
  padding: 9px 13px 11px 12px;
  border-radius: 12px;
  background: #FFF;
  color: #888B94;
  border: 1px #F2F1F0 solid;
  font-size: 16px;
  font-style: normal;
  width: 166px;
  font-weight: 600;
  height: 40px;


  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.navs:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: 200ms;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.clear-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 40px;
  bottom: 12px;
  z-index: 1
}

.bg-red {
  background: #f38a8a !important;
}

@media only screen and (max-width: 1660px) {
  .gridText {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .gridText {
    font-size: 8px;
  }
}
</style>