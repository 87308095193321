export const TicketRouterNavigationMixin = {
    methods: {
        ticketRouterNavigationFunc(ticket, isNewTab){
            const  { ticket_kind, author, ticket_number, ticket_id } = ticket
            if (ticket_kind?.key === 'connection' || ticket_kind?.is_connection) {
                if (isNewTab){
                    window.open(`/connection/${ticket_id}`, '_blank');
                    return
                } else {
                    this.$router.push(`/connection/${ticket_id}`)
                    return
                }
            }
            if (ticket_kind?.key === 'service' && !author){
                if (isNewTab){
                    window.open(`/cabinet-tickets/${ticket_number}`, '_blank');
                    return
                } else {
                    this.$router.push(`/cabinet-tickets/${ticket_number}`)
                    return
                }
            }
            const routeMap = {
                'access': `/access/ticket/${ticket_number}`,
                'emergency': `/emergency/ticket/${ticket_number}`,
                'subtask': `/subtask/ticket/${ticket_number}`,
                'lks': `/ticket-lcs/${ticket_number}`,
                'commutation': `/commutation-tickets/${ticket_number}`,
                'preparation': `/preparing-connections-tickets/${ticket_number}`,
                'rework': `/connection-improvements-tickets/${ticket_number}`,
                'add-sale': `/upsells/${ticket_number}`,
                'dis-assembly': `/disassembly/${ticket_number}`,
                'transposition': `/services-transfer/${ticket_number}`,
                'tech_capabilities': `/queries-technical-capability/${ticket_number}`
            }

            const route = routeMap[ticket_kind?.key] || `/ticket/${ticket_number}`
            if (isNewTab){
                window.open(route, '_blank');
            } else {
                this.$router.push(route)
            }
        },
    }
}