<template>
  <div class="table-pagination mt-2" v-if="totalPages">
    <div class="table-pagination__item" @click="prevPage">
      <img class="table-pagination__arrow" src="/assets/chevron-left.svg" alt="">
      <img class="table-pagination__arrow-hover" src="/assets/chevron-left-w.svg" alt="">
    </div>
    <div class="table-pagination__item" v-for="page in pagination(currentPage, totalPages)" :key="page" @click="changePage(page)" :class="{'active': page === currentPage}">
      {{page}}
    </div>
    <div class="table-pagination__item" @click="nextPage">
      <img class="table-pagination__arrow" src="/assets/chevron-right.svg" alt="">
      <img class="table-pagination__arrow-hover" src="/assets/chevron-right-w.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationComponent',
  props: {
    totalPages: Number,
    currentPage: Number,
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) this.$emit('pageChange', this.currentPage - 1)
    },
    pagination(current, total) {
      let delta = 1,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

      for (let i = 1; i <= total; i++) {
        if (i == 1 || i == total || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    changePage(page) {
      if (page !== '...' && page !== this.currentPage) this.$emit('pageChange', page)
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.$emit('pageChange', this.currentPage + 1)
    },
  },
}
</script>

<style>

</style>