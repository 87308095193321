<template>
  <div class="wrapper modified flex column">
    <div class="bg-white rounded p-20">
      <div class="flex justify-between">
        <div>
          <button class="btn btn-green" @click="$router.push('/roles')">Назад</button>
        </div>
        <div>
          <button class="btn btn-create" @click="isAddPagesModal = true">
            <img src="/assets/plus-create.svg" alt=""/>Добавить страницу
          </button>
        </div>
      </div>
      <div>
        <div class="flex column gap-2 mb-4 pt-20">
          <div class="flex align-center gap-2">
            <p class="text-center">Роль:</p>
            <p class="text-18 font-bold">{{ currentRole?.title || '' }}</p>
          </div>
          <div class="flex align-center gap-2">
            <p class="text-gray">Ключ:</p>
            <p class="text-18">{{ currentRole?.key || '' }}</p>
          </div>
        </div>
        <div class="mt-4">
          <h1 class="text-center text-20 mb-3">Доступ к страницам</h1>
          <div class="table-container">
            <div class="grid-pages table-header" style="background-color: #F5F5FA;">
              <div class="grid-num">
                <p class="header-text">№</p>
              </div>
              <div class="grid-page-name">
                <p class="header-text">Страница</p>
              </div>
              <div class="grid-page-path">
                <p class="header-text">Путь</p>
              </div>
              <div class="grid-page-desc">
                <p class="header-text">Описание</p>
              </div>
              <div class="grid-access">
                <p class="header-text">Доступ</p>
              </div>
              <div class="grid-actions">
                <p class="header-text">Действия</p>
              </div>
            </div>
            <div class="roles-table">
              <div class="grid-pages" v-for="(page, index) in pages" :key="page.key">
                <div class="flex align-center">{{ index + 1 }}</div>
                <div class="flex align-center">{{ page?.title || '' }}</div>
                <div class="flex align-center">{{ page?.key || '' }}</div>
                <div class="flex align-center">{{ page?.meta?.description || '' }}</div>
                <div class="flex align-center">
                  <input 
                    type="checkbox" 
                    :checked="selectedPages.includes(page.key)"
                    @change="togglePageAccess(page.key)"
                  >
                </div>
                <div class="flex align-center justify-center">
                  <img 
                    src="/assets/delete.svg" 
                    alt="Delete"
                    class="action-icon"
                    @click="openDeleteModal(page)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-4">
          <button class="btn form-btn text-center" @click.prevent="savePageAccess">Cохранить</button>
        </div>
      </div>
    </div>
    <custom-modal v-if="isAddPagesModal" @close="closeAddPagesModal">
      <add-pages-modal @close="closeAddPagesModal"></add-pages-modal>
    </custom-modal>
    <custom-modal v-if="isDeleteModal" @close="closeDeleteModal">
      <delete-page-modal 
        :page="selectedPage" 
        @close="closeDeleteModal"
        @deleted="handlePageDeleted"
      ></delete-page-modal>
    </custom-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import AddPagesModal from "@/components/modals/pages/AddPagesModal.vue";
import DeletePageModal from "@/components/modals/pages/DeletePageModal.vue";

export default {
  name: 'RolePagesAccess',
  components: {
    CustomModal,
    AddPagesModal,
    DeletePageModal
  },
  data() {
    return {
      selectedPages: [],
      isAddPagesModal: false,
      isDeleteModal: false,
      selectedPage: null,
    }
  },
  computed: {
    ...mapGetters({
      currentRole: 'pagesModule/currentRole',
      currentRolePages: 'pagesModule/currentRolePages',
      pages: 'pagesModule/pages',
    }),
  },
  methods: {
    ...mapActions({
      updateRolePages: 'pagesModule/updateRolePages',
      fetchPages: 'pagesModule/fetchPages',
      fetchRoleById: 'pagesModule/fetchRoleById',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    togglePageAccess(key) {
      const index = this.selectedPages.indexOf(key);
      if (index === -1) {
        this.selectedPages.push(key);
      } else {
        this.selectedPages.splice(index, 1);
      }
    },
    async savePageAccess() {
      console.log('savePageAccess', this.selectedPages)
      const result = await this.updateRolePages({
        roleId: this.$route.params.id,
        pages: this.selectedPages.map(key => {
          const page = this.pages.find(p => p.key === key)
          return {
            key,
            title: page?.title || '',
            meta: {
              description: page?.meta?.description || '',
              keywords: page?.meta?.keywords || []
            }
          }
        })
      })

      if (result.success) {
        this.$toast.success('Доступ к страницам успешно обновлен')
        this.$router.push('/roles')
      } else {
        this.$toast.error('Не удалось обновить доступ к страницам')
      }
    },
    async closeAddPagesModal() {
      this.isAddPagesModal = false
    },
    openDeleteModal(page) {
      this.selectedPage = page;
      this.isDeleteModal = true;
    },
    closeDeleteModal() {
      this.isDeleteModal = false;
      this.selectedPage = null;
    },
    async handlePageDeleted() {
      const result = await this.fetchPages()
      if (!result.success) {
        this.$toast.error('Не удалось обновить список страниц')
      }
    },
  },
  async created() {
    console.log('Created hook called, roleId:', this.$route.params.id)
    this.setLoading(true)
    try {
      // Load role data
      const roleResult = await this.fetchRoleById(this.$route.params.id)
      console.log('Role fetch result:', roleResult)
      if (!roleResult.success) {
        this.$toast.error('Не удалось загрузить данные роли')
        return
      }

      // Load pages
      const pagesResult = await this.fetchPages()
      console.log('Pages fetch result:', pagesResult)
      if (!pagesResult.success) {
        this.$toast.error('Не удалось загрузить список страниц')
        return
      }

      // Set selected pages from current role
      if (this.currentRolePages?.length) {
        console.log('currentRolePages', this.currentRolePages)
        this.selectedPages = this.currentRolePages.map(page => page.key)
      }
    } catch (error) {
      console.error('Error in created hook:', error)
      this.$toast.error('Произошла ошибка при загрузке данных')
    } finally {
      this.setLoading(false)
    }
  }
}
</script>

<style scoped lang="scss">
.header-text {
  color: black;
  font-weight: normal;
}

.table-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  .grid-pages {
    padding: 14px 10px;
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  border-radius: 8px;
  background: white;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

.grid-pages {
  display: grid;
  min-width: 1000px;
  width: 100%;
  padding: 16px 10px;
  grid-template-columns: 0.5fr 2fr 2fr 2fr 1fr 0.5fr;
  grid-template-areas: 'Num PageName PagePath PageDesc Access Actions';
}

.grid-num {
  grid-area: Num;
}

.grid-page-name {
  grid-area: PageName;
}

.grid-page-path {
  grid-area: PagePath;
}

.grid-page-desc {
  grid-area: PageDesc;
}

.grid-access {
  grid-area: Access;
}

.grid-actions {
  grid-area: Actions;
}

.roles-table {
  :hover {
    background-color: #F5F5FA;
  }
}

.action-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}
</style> 