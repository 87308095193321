<template>
  <div>
    <div class="wrapper flex column ">
      <form @submit.prevent="createWiki" class="flex justify-around gap-2 align-center  bg-white rounded p-20">
        <div>
        <div>
          <label>Название<span class="text-red">*</span></label>
        <input class="input-form mt-1" type="text" v-model="title">
        </div>
        <div class="mt-2">
          <label for="">Описание</label>
          <input class="input-form mt-1" type="text" v-model="description">
        </div>
          <div class="flex gap-2 mt-4" v-if="blocks.length === 0">
            <button class="btn btn-green" @click.prevent="createWiki">Создать</button>
            <button class="btn btn-secondary" @click.prevent="$router.push('/instructions')">Отмена</button>
          </div>
        </div>
        <div>
          <button type="button" class="btn-emergency" @click="addBlock">Добавить блок</button>
        </div>
      </form>

      <div class="mt-4" v-for="el in blocks" :key="el.id">
        <WikiBlock :id="el.id" @deleteBlock="deleteBlock" @saveBlock="saveBlock" :saveBlockText="'Создать'"></WikiBlock>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import WikiBlock from "@/components/wiki/WikiBlock.vue";

export default {
  name: "InstructionsCreate",
  components: {WikiBlock},
  created() {
    this.fetchWikiBlockTypes();
  },
  data() {
    return {
      title: '',
      description: '',
      blocks: [],
      blocksValue: []
    }
  },
  computed: {
   ...mapGetters({
     blockTypes: 'wikiBlockTypes'
   })
  },
  methods: {
    ...mapActions({
      createWikiPage: 'createWikiPage',
      fetchWikiBlockTypes: 'fetchWikiBlockTypes',
      createWikiBlock: 'createWikiBlock'
    }),
   async createWiki(){
      const obj = {
        title: this.title,
        description: this.description
      }
      await this.createWikiPage(obj).then(res=>{
        if(res.success){
          const formData = new FormData();
          this.blocksValue.forEach((block, index)=> {
            block.wiki_page_id = res.data.id;
            block.wiki_block_type_id = this.blockTypes[0].id;
            
            formData.append(`blocks[${index}][title]`, block.title);
            formData.append(`blocks[${index}][description]`, block.description);
            formData.append(`blocks[${index}][wiki_block_type_id]`, block.wiki_block_type_id);
            formData.append(`blocks[${index}][wiki_page_id]`, block.wiki_page_id);
            if (block.attached_files) {
              block.attached_files.forEach((file, fileIndex) => {
                formData.append(`blocks[${index}][files][${fileIndex}]`, file);
              });
            }
          });

          this.createWikiBlock(formData).then(blockRes => {
            if(blockRes.success) {
              this.$toast.success('Инструкция успешно создана')
              this.$router.push('/instructions')
            } else {
              this.$toast.error('Не удалось создать блоки инструкции')
            }
          }).catch(err => {
            this.$toast.error(`Ошибка при создании блоков: ${err.message}`)
          })
        } else {
          this.$toast.error('Не удалось создать инструкцию')
        }
      }).catch((err)=>{
        this.$toast.error(`${err.message}`)
      })
    },
    addBlock(){
      const uniqueId = Date.now().toString();
      this.blocks.push({ id: uniqueId });
    },
    deleteBlock(blockId){
      this.blocks = this.blocks.filter(block => block.id !== blockId);
    },
    async saveBlock(blockValue){
      this.blocksValue.push(blockValue)
      await this.createWiki()
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';
</style>