<template>
  <div class="p-20">
    <div class="">
      <div class="">
        <div
            class="client-dashboard__card--gradient flex--space"
        >
          <div>
            <h1 class="text-24 mb-4">Информация о клиенте</h1>
           <div class="bg-gray rounded p-20 mt-4" v-if="clientInfo">
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded">
               <div>Имя</div>
               <div>{{clientInfo.name ? clientInfo.name : clientInfo.full_name}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded" v-if="clientInfo.contact_person">
               <div>Контактное лицо</div>
               <div>{{clientInfo?.contact_person}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded">
               <div>Телефон</div>
               <div>{{clientInfo?.phone_number ? clientInfo.phone_number : clientInfo.phone}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded" v-if="clientInfo.source">
               <div>Источник</div>
               <div>{{clientInfo?.source}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded">
               <div>ИИН</div>
               <div>{{clientInfo?.iin}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded">
               <div>ID</div>
               <div>{{clientInfo?.id}}</div>
             </div>
             <div class="flex mb-2 w-100 justify-between bg-white p-20 rounded" v-if="clientInfo.lanbilling_id">
               <div>Lanbilling ID</div>
               <div>{{clientInfo?.lanbilling_id}}</div>
             </div>

           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DepartureClientInfo",
  computed: {
    ...mapGetters({
      clientInfo: 'currentClientInfo'
    })
  },
}
</script>

<style scoped lang="scss">

</style>