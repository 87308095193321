<template>
  <div class="p-20">
    <div>
      <h1 class="text-center bold text-24 mb-3">История изменений</h1>
      <div class="gridLogs bg-gray">
        <div class="bold">Дата и время изменения</div>
        <div class="bold">Автор</div>
        <div class="bold">Действие</div>
      </div>
      <div v-if="brigadeLogs.length > 0">
        <div class="gridLogs" v-for="log in brigadeLogs" :key="log.id">
          <div class="border">{{new Date(log.created_at).toISOString().slice(0,19).replace("T"," ")}}</div>
          <div class="border">{{log.user.full_name}}</div>
          <div>{{log.action}}</div>
        </div>
      </div>
      <div v-else class="text-center text-24 mt-3">
        История пуста
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "BrigadeLogs",
  created() {
    this.setLoading(true)
    this.fetchLogs({id: this.id}).then(data => {
      this.setLoading(false)
    });
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      brigadeLogs: 'brigadeLogs'
    })
  },
  methods: {
    ...mapActions({
      fetchLogs: 'fetchBrigadeLogs'
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    })
  },
}
</script>

<style scoped lang="scss">
.gridLogs {
  display: grid;
  padding: 10px;
  gap: 10px;
  width: 1024px;
  border: 1px solid gray;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-areas: 'Date Author Action'
}
.gridLogs-author {
  grid-area: Author;
}
.border {
  border-right: 1px solid gray;
}
</style>