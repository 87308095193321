import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask';
// import components from '@/components';
import DropZone from 'dropzone-vue';
import YmapPlugin from 'vue-yandex-maps'
import './theme/index.scss'
import '@vuepic/vue-datepicker/dist/main.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueClickAway from "vue3-click-away";

const settings = {
  apiKey: process.env.VUE_APP_YANDEX_API_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  debug: false,
  enterprise: false,
  version: '2.1'
}

const app = createApp(App)

// components.forEach(component => {
//   app.component(component.name || component.displayName, component)
// })


app
  .use(router)
  .use(store)
  .use(VueTheMask)
  .use(DropZone)
  .use(YmapPlugin, settings)
  .use(ToastPlugin).use(QuillEditor)
  .use(VueClickAway)
  .mount('#app');
