
export const alertModule = {
  state: () => ({
    isAlert: false,
    alertText: '',
    alertType: '',
    alertQuery: [],
    lastAlertAt: null
  }),
  getters: {
    isAlert(state) {
      return state.isAlert
    },
    alertText(state) {
      return state.alertText
    },
    alertType(state) {
      return state.alertType
    }
  },
  mutations: {
    setIsAlert(state, payload) {
      state.isAlert = payload
    },
    setAlertText(state, payload) {
      state.alertText = payload
    },
    setAlertType(state, payload) {
      state.alertType = payload
    },

    // FIFO
    pushInAlertQuery(state, payload) {
      state.alertQuery = [...state.alertQuery, payload]
    },
    filterFirstOutAlertQuery(state) {
      state.alertQuery = state.alertQuery.filter((_, idx) => idx != 0)
    },
    setLastAlertAt(state, payload) {
      state.lastAlertAt = payload
    }
  },
  actions: {
    errorAlert({commit, state}, payload) {
      const currentTime = new Date().getTime();
      const timeGapMs = 500;

      const commitAlert = (message, time) => {
        commit('setIsAlert', true)
        commit('setAlertText', message)
        commit('setAlertType', 'error')
        commit('setLastAlertAt', time)
      }
      const commitAlertQuery = (message) => {
        commit('pushInAlertQuery', message)
        state.alertQuery.forEach((message) => {
          setTimeout(() => commitAlert(message, new Date().getTime()))
          commit('filterFirstOutAlertQuery')
        })
      }

      if (state.lastAlertAt && currentTime - state.lastAlertAt < timeGapMs)
        commitAlertQuery(payload)
      else
        commitAlert(payload, currentTime)

      setTimeout(() => {
        commit('setIsAlert', false)
        commit('setAlertText', '')
        commit('setAlertType', '')
      }, 3000)
    },
    successAlert({commit}, payload) {
      commit('setIsAlert', true)
      commit('setAlertText', payload)
      commit('setAlertType', 'success')
      setTimeout(() => {
        commit('setIsAlert', false)
        commit('setAlertText', '')
        commit('setAlertType', '')
      }, 3000)
    }
  }
}
