<template>
  <div class="info-block p-20" style="width: 600px">
    <div>
      <div class="tech-tabs flex">
        <div class="tech-tabs_item cursor-pointer" v-for="tab in tabs" :key="tab.key" :class="{'active': currentTab === tab.key}" @click="changeTab(tab)">
          <h1 class="text-20 text-center p-20">{{tab.title}}</h1>
        </div>
      </div>

      <template v-if="currentTab === 'tech'">
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo?.ip_info?.login">
          <p class="text-black bold text-14">Логин PPOE:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo?.ip_info.login || "Не указано"}}</p>
            <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionInfo?.ip_info.login)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo?.ip_info?.password">
          <p class="text-black bold text-14">Пароль PPOE:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo?.ip_info.password || "Не указано"}}}</p>
            <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionInfo?.ip_info.password)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo?.ip_info?.ip?.ip">
          <p class="text-black bold text-14">IP адрес:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo?.ip_info?.ip?.ip || 'Не указано'}}</p>
            <img class="ml-1 cursor-pointer" v-if="connectionInfo?.ip_info?.ip?.ip" @click="copyToClipboard(connectionInfo?.ip_info.ip.ip)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative" v-if="connectionInfo.task?.fiber_type">
          <p class="text-black bold text-14">Тип подключения:</p>
          <p class="link relative" @click="isChangeConnectionType = !isChangeConnectionType">{{renameConnectionType}}</p>
          <div v-if="isChangeConnectionType" class="fiber_window">
            <p class="cursor-pointer fiber_type" @click="onChangeConnectionType('optic')">Оптика</p>
            <p class="cursor-pointer fiber_type" @click="onChangeConnectionType('copper')">Медь</p>
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative">
          <p class="text-black bold text-14">Сооружение связи:</p>
          <div class="text-14 w-50 text-right">
            <p v-if="connectionInfo.ork?.name">ОРК {{connectionInfo.ork?.name|| "Не указано"}}</p>
            <p v-if="connectionInfo.ork?.shrpo">ШРПО {{connectionInfo.ork?.shrpo|| "Не указано"}}</p>
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative" v-if="connectionInfo.node">
          <p class="text-black bold text-14">Узел:</p>
          <p class="text-14  w-50 text-right" style="word-break: break-all">{{connectionInfo.node?.name|| "Не указано"}}</p>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative" v-if="connectionInfo.host">
          <p class="text-black bold text-14">Хост:</p>
          <p class="text-14 w-50 text-right relative link"  @click="getHosts(connectionInfo.node.id)">{{connectionInfo.host.location || "Не указано"}}</p>
          <div v-if="isChangeHosts && departureInfoHosts.length > 0" class="fiber_window" style="height: 120px; overflow: scroll; bottom: -120px;">
            <div class="cursor-pointer fiber_type" style="width: 400px" v-for="host in departureInfoHosts" :key="host.id">
              <p @click="changeHosts(host.id)">{{host.location}}</p>
            </div>
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative">
          <p class="text-black bold text-14">Комментарии:</p>
          <p class="text-14 w-50" style="word-break: break-all">{{connectionInfo.task.comment.comment|| "Не указано"}}</p>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative">
          <p class="text-black bold text-14">Cтатус:</p>
          <p class="text-14 text-right">{{connectionInfo.task.status|| "Не указано"}}</p>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between">
          <p class="text-black bold text-14">Агент:</p>
          <div class="flex align-center">
            <p class="text-gray5 text-14 text-right">{{connectionInfo.task.agent_key|| "Нет агента"}}</p>
            <img class="ml-1 cursor-pointer" v-if="connectionInfo.task.agent_key" @click="copyToClipboard(connectionInfo.task.agent_key)" src="/assets/copy.svg" alt="">
          </div>
        </div>
      </template>
      <template v-if="currentTab === 'client'">
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo.task?.point?.agreement?.client?.full_name">
          <p class="text-black bold text-14">Клиент:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo.task?.point?.agreement?.client?.full_name || "Не указано"}}</p>
            <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionInfo.task?.point?.agreement?.client?.full_name)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo.task?.point?.agreement?.client?.iin">
          <p class="text-black bold text-14">ИИН:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo.task?.point?.agreement?.client?.iin || "Не указано"}}</p>
            <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionInfo.task?.point?.agreement?.client?.iin)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between" v-if="connectionInfo.task?.point?.agreement?.lanbilling_agreement">
          <p class="text-black bold text-14">Номер договора:</p>
          <div class="flex align-center">
            <p class="text-gray5  text-14">{{connectionInfo.task?.point?.agreement?.lanbilling_agreement || "Не указано"}}</p>
            <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionInfo.task?.point?.agreement?.lanbilling_agreement)" src="/assets/copy.svg" alt="">
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative">
          <p class="text-black bold text-14">Услуги:</p>
          <div class="text-14 text-right">
            <p v-for="(service,index) in productServices.services" :key="service.id">
              {{index + 1}}) {{service.service_title}}
            </p>
          </div>
        </div>
        <div class="p-4 bg-white rounded flex align-center justify-between relative">
          <p class="text-black bold text-14">Оборудование:</p>
          <div class="text-14 text-right">
            <p v-for="(product,index) in connectionInfo.products" :key="product.id">
              {{index + 1}}) {{product.product_title}}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CustomModal from "@/components/modules/CustomModal.vue";
import ChangeConnectionTypeModal from "@/components/modals/changeConnection/ChangeConnectionTypeModal.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'DepartureConnectionInfoModal',
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isChangeConnectionType: false,
      isChangeHosts: false,
      currentTab: 'tech',
      tabs: [
        {
          key: 'tech',
          title: 'Техническая информация'
        },
        {
          key: 'client',
          title: 'Информация о подключении'
        }
      ]
    }
  },
  computed: {
   ...mapGetters({
     connectionInfo: 'connectionInfo',
     departureInfoHosts: 'departureInfoHosts',
     productServices: 'productServices'
   }),
    renameConnectionType(){
     if (this.connectionInfo.task.fiber_type === 'optic'){
       return 'Оптика'
     } else {
       return 'Медь'
     }
    }
  },
  methods: {
    ...mapActions({
      changeConnectionType: 'changeConnectionType',
      fetchConnectionInfo: 'fetchConnectionInfo',
      fetchDepartureInfoHosts: 'fetchDepartureInfoHosts',
      changeDepartureInfoHost: 'changeDepartureInfoHost'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
    }),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    toggleChangeConnectionType() {
      this.isChangeConnectionType = !this.isChangeConnectionType
    },
    changeTab(tab) {
      this.currentTab = tab.key
    },
    async onChangeConnectionType(type) {
      const obj = {
        id: this.id,
        type_key: type
      }
      this.toggleChangeConnectionType()
      this.setLoading(true)
      await this.changeConnectionType(obj)
      await this.fetchConnectionInfo({id: this.id})
      this.setLoading(false)
    },
    async getHosts(id){
      this.isChangeHosts = !this.isChangeHosts
      if (this.isChangeHosts) {
        await this.fetchDepartureInfoHosts({id:id}).then(response => {
          this.isChangeHosts = true
        })
      }
    },
    async changeHosts(hostId){
      await this.changeDepartureInfoHost({id: this.id, host_id: hostId}).then(response => {
        if (response.success) {
          this.$toast.success('Хост был успешно изменен')
          this.isChangeHosts = false
        } else {
          this.$toast.error('Не удалось сменить хост')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../theme/variables';
.p-4 {
  padding: 4px;
}

.fiber_window {
  position: absolute; right: 0;z-index: 2; bottom: -80px;  border: 1px solid gray;
  border-radius: 4px;filter: drop-shadow(2px 2px 2px #000000);
  background: white;
}

.fiber_type {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid gray;
  width: 200px;
  &:hover {
    background: gray;
    transition: 200ms;
  }
}

.tech-tabs_item {
  &.active {
    border-bottom: 2px solid $primary;
    color: $primary;
  }
}
</style>