<template>
  <div class="wrapper modified flex column">
    <div class="mb-2" @click="showDropdownCalendar = !showDropdownCalendar" style="width: 300px">
      <calendar-departure :show-dropdown-calendar="showDropdownCalendar" @setDate="setDate"></calendar-departure>
    </div>
    <div class="bg-white p-20 rounded flex column gap-2">
      <div class="mt-3" style="background: #F5F5FA; border-radius: 10px; padding-left: 20px; padding-right: 20px;">
        <div class="grid">
          <div class="grid-name">
            <p class="title-text">Название участка</p>
          </div>
          <div class="grid-active">
            <p class="title-text">Активных заявок</p>
          </div>
          <div class="grid-expired">
            <p class="title-text">Просроченных заявок</p>
          </div>
          <div class="grid-completed">
            <p class="title-text">Выполненных заявок</p>
          </div>
          <div class="grid-brigades">
            <p class="title-text">Бригад на участке</p>
          </div>
          <div class="grid-rating">
            <p class="title-text">Рейтинг участка</p>
          </div>
        </div>
        <div class="areaAnalytics-table">
          <div class="grid" v-for="(el) in ticketCountsByArea" :key="el"
               style="background: white; margin-bottom: 6px; border-radius: 10px;">
            <div class="flex align-center">{{ el.area_name }}</div>
            <div>
              <div v-for="active in el.active_tickets" :key="active">
                <div class="cursor-pointer areaAnalytics-link">
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;" @click="goToTicket(active, 'b2c', 'active')">
                    <div>{{ active.kind }} B2C</div>
                    <div class="ml-1"><b>{{ active.count_b2c }}</b></div>
                  </div>
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;" @click="goToTicket(active, 'b2b', 'active')">
                    <div>{{ active.kind }} B2B</div>
                    <div class="ml-1"><b>{{ active.count_b2b }}</b></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-for="expired in el.expired_tickets" :key="expired">
                <div class="cursor-pointer areaAnalytics-link">
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;"  @click="goToTicket(expired, 'b2c', 'expired')">
                    <div>{{ expired.kind }} B2C</div>
                    <div :class="expired.count_b2c > 0 ? 'text-red ml-1': 'ml-1'"><b>{{ expired.count_b2c }}</b></div>
                  </div>
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;"  @click="goToTicket(expired, 'b2b', 'expired')">
                    <div>{{ expired.kind }} B2B</div>
                    <div :class="expired.count_b2b > 0 ? 'text-red ml-1': 'ml-1'"><b>{{ expired.count_b2b }}</b></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-for="completed in el.completed_tickets" :key="completed">
                <div class="cursor-pointer areaAnalytics-link">
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;"
                       @click="goToTicket(completed, 'b2c', 'completed')">
                    <div>{{ completed.kind }} B2C</div>
                    <div class="ml-1"><b>{{ completed.count_b2c }}</b></div>
                  </div>
                  <div class="flex align-center justify-between" style="padding-bottom: 2px;"
                       @click="goToTicket(completed, 'b2b', 'completed')">
                    <div>{{ completed.kind }} B2B</div>
                    <div class="ml-1"><b>{{ completed.count_b2b }}</b></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex align-center ml-4 text-24"><b>{{ el.brigade_count }}</b></div>
            <div class="flex align-center areaAnalytics-placement"><b class="mr-2">{{ el.total_completed_tickets }}</b>
              <img
                  src="/assets/area-analytics/icons8-first-place-64.png" width="30" height="30"
                  v-if="ticketCountsByArea[0] === el"/>
              <img
                  src="/assets/area-analytics/icons8-second-place-ribbon-64.png" width="30" height="30"
                  v-if="ticketCountsByArea[1] === el"/>
              <img
                  src="/assets/area-analytics/icons8-third-place-ribbon-64.png" width="30" height="30"
                  v-if="ticketCountsByArea[2] === el"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CalendarDeparture from "@/components/calendar/calendar-departure.vue";

export default {
  name: "AreasAnalyticsView",
  components: {CalendarDeparture},
  created() {
    this.getTicketCountsByArea()
  },
  data() {
    return {
      showDropdownCalendar: false,
      date_from: null,
      date_to: null,
    }
  },
  computed: {
    ...mapGetters({
      ticketCountsByArea: 'ticketCountsByArea'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      getTicketCountsByArea: 'getTicketCountsByArea'
    }),
    async setDate(data) {
      this.setLoading(true)
      if (data) {
        this.date_from = data?.[0]
        this.date_to = data?.[1]
        await this.getTicketCountsByArea({
          date_from: this.date_from.split("-").reverse().join("-"),
          date_to: this.date_to.split("-").reverse().join("-")
        })
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
      }
      this.setLoading(false)
    },
    goToTicket(ticket, type, status) {
      if (status === 'completed'){
       this.toCompletedStatusTickets(ticket, type)
      } else if (status === 'expired'){
        this.toExpiredStatusTickets(ticket, type)
      } else {
        this.toActiveStatusTickets(ticket, type)
      }

    },
    toCompletedStatusTickets(ticket,type){
      const routes = {
        'Сервисная заявка': {
          b2c: `departure/service-b2c?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `departure/service-b2b?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Подключение': {
          b2c: `connection-b2c?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `connection-b2b?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Демонтажи': {
          b2b: `disassembly?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Допродажи': {
          b2b: `upsells-b2b?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `upsells-b2c?status_id=6&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Доработка демонтажа': {
          b2b: `disassembly-improvements-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly-improvements-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'LKS': {
          b2b: `tickets-lcs?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `tickets-lcs?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доступ': {
          b2b: `access-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `access-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Коммутация': {
          b2b: `commutation-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `commutation-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Подготовка': {
          b2b: `preparing-connections-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `preparing-connections-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доработка': {
          b2b: `connection-improvements-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `connection-improvements-tickets?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Аварийная заявка': {
          b2b: `emergency-tickets-info?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `emergency-tickets-info?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Плановая работа': {
          b2b: `plan-tickets-view?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `plan-tickets-view?status_id=20&date_from=${this.date_from}&date_to=${this.date_to}`
        },

      };

      if (routes[ticket.kind] && routes[ticket.kind][type]) {
        this.$router.push(routes[ticket.kind][type]);
      }
    },
    toExpiredStatusTickets(ticket,type){
      const routes = {
        'Сервисная заявка': {
          b2c: `departure/service-b2c?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `departure/service-b2b?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Подключение': {
          b2c: `connection-b2c?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `connection-b2b?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Демонтажи': {
          b2b: `disassembly?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Допродажи': {
          b2b: `upsells-b2b?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `upsells-b2c?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Доработка демонтажа': {
          b2b: `disassembly-improvements-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly-improvements-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'LKS': {
          b2b: `tickets-lcs?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `tickets-lcs?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доступ': {
          b2b: `access-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `access-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Коммутация': {
          b2b: `commutation-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `commutation-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Подготовка': {
          b2b: `preparing-connections-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `preparing-connections-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доработка': {
          b2b: `connection-improvements-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `connection-improvements-tickets?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Аварийная заявка': {
          b2b: `emergency-tickets-info?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `emergency-tickets-info?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Плановая работа': {
          b2b: `plan-tickets-view?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `plan-tickets-view?is_expired=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },

      };

      //open_tickets: 1  connection_ticket
      //tickets is_only_open: 1
      //is_expired: 1

      if (routes[ticket.kind] && routes[ticket.kind][type]) {
        this.$router.push(routes[ticket.kind][type]);
      }
    },
    toActiveStatusTickets(ticket,type){
      const routes = {
        'Сервисная заявка': {
          b2c: `departure/service-b2c?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `departure/service-b2b?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Подключение': {
          b2c: `connection-b2c?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2b: `connection-b2b?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Демонтажи': {
          b2b: `disassembly?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Допродажи': {
          b2b: `upsells-b2b?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `upsells-b2c?open_tickets=1&date_from=${this.date_from}&date_to=${this.date_to}`,
        },
        'Доработка демонтажа': {
          b2b: `disassembly-improvements-tickets?is_only_open=1=20&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `disassembly-improvements-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'LKS': {
          b2b: `tickets-lcs?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `tickets-lcs?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доступ': {
          b2b: `access-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `access-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Коммутация': {
          b2b: `commutation-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `commutation-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Подготовка': {
          b2b: `preparing-connections-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `preparing-connections-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Доработка': {
          b2b: `connection-improvements-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `connection-improvements-tickets?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Аварийная заявка': {
          b2b: `emergency-tickets-info?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `emergency-tickets-info?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },
        'Плановая работа': {
          b2b: `plan-tickets-view?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`,
          b2c: `plan-tickets-view?is_only_open=1&date_from=${this.date_from}&date_to=${this.date_to}`
        },

      };

      if (routes[ticket.kind] && routes[ticket.kind][type]) {
        this.$router.push(routes[ticket.kind][type]);
      }
    },

  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  gap: 20px;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
  grid-template-areas: 'Name Active Expired Completed Brigades Rating';
}

.title-text {
  color: #888888;
}

.grid-name {
  grid-area: Name;

}

.grid-active {
  grid-area: Active;

}

.grid-expired {
  grid-area: Expired;

}

.grid-completed {
  grid-area: Completed;

}

.grid-brigades {
  grid-area: Brigades;

}

.grid-rating {
  grid-area: Rating;

}

.areaAnalytics-table {
}

.areaAnalytics-placement {
  &:first-child {

  }
}

.areaAnalytics-link {
  :hover {
    text-decoration: underline;
    color: #3939d0;
    transform: scale(1.01);
    transition: 200ms;
  }
}
</style>