<template>
  <div class="p-20">
    <div class="p-20">
      <h1 class="text-24 text-center mb-3">Отправка оповещения</h1>
      <div class="flex gap-2 justify-center">
        <label for="" class="block" style="color: #7b7b7b; width: 100px">Только Push</label>
        <input type="checkbox" v-model="onlyPush" @change="clearOtherOptions('push')">
      </div>
      <div class="flex gap-2 justify-center">
        <label for="" class="block" style="color: #7b7b7b; width: 100px">Только SMS</label>
        <input type="checkbox" v-model="onlySms" @change="clearOtherOptions('sms')">
      </div>
      <div class="flex gap-2 justify-center">
        <label for="" class="block" style="color: #7b7b7b; width: 100px">SMS + Push</label>
        <input type="checkbox" v-model="pushAndSms" @change="clearOtherOptions('both')">
      </div>
      <div class="flex justify-center mt-2">
        <div>
          <p class="text-20 mb-2">Заголовок</p>
          <input type="text" class="input-form mb-3" placeholder="Введите заголовок..." v-model="title">
          <p class="text-20 mb-2">Текст оповещения</p>
          <textarea class="input-form" style="min-height: 200px;" placeholder="Введите текст..." v-model="body"/>
          <button class="btn mt-4" @click="sendMessage">Отправить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SendNotificationModal",
  data() {
    return {
      title: '',
      body: '',
      onlyPush: true,
      onlySms: false,
      pushAndSms: false,
    }
  },
  methods: {
    clearOtherOptions(type) {
      if (type === 'push') {
        this.onlySms = false;
        this.pushAndSms = false;
      } else if (type === 'sms') {
        this.onlyPush = false;
        this.pushAndSms = false;
      } else if (type === 'both') {
        this.onlyPush = false;
        this.onlySms = false;
      }
    },
    sendMessage() {
      this.$emit('sendMessage', {
        title: this.title,
        body: this.body,
        ...(this.onlyPush && !this.onlySms ? {notification_types: ['push']} : {}),
        ...(!this.onlyPush && this.onlySms ? {notification_types: ['sms']} : {}),
        ...(this.onlyPush && this.onlySms ? {notification_types: ['push', 'sms']} : {}),
        ...(this.pushAndSms && !this.onlyPush && !this.onlySms ? {notification_types: ['push', 'sms']} : {})
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>