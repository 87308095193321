<template>
  <div class="p-20">
    <div class="choose-modal" >
      <div class="choose-modal__title w-100 mb-4">
        Выберите отдел
      </div>
      <select class="input-form select" v-model="departmentId">
        <option disabled value=""></option>
        <option
            v-for="option in bitrixDepartments"
            :key="option.id"
            :value="option.id"
        >
          {{ option.title }}
        </option>
      </select>
      <button type="button" class="btn mb-2 mt-4" @click="sendBitrix">Отправить</button>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DepartureBrigadeModal",
  props: {
    ticketId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      departmentId: 0,
    }
  },
  created() {
    this.fetchBitrixDepartments()
  },
  computed: {
    ...mapGetters({
      bitrixDepartments: 'bitrixDepartments'
    }),
  },
  methods: {
    ...mapActions({
      sendToBitrix: 'sendToBitrix',
      fetchBitrixDepartments: 'fetchBitrixDepartments'
    }),
    async sendBitrix() {
      await this.sendToBitrix({id: this.ticketId, department_id: this.departmentId}).then(res => {
        if (res.success) {
          this.$toast.success('Заявка успешно отправлена в Битрикс')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось отправить заявку в Битрикс')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
  },
}
</script>


<style scoped lang="scss">

</style>