<template>
    <div class="p-20 text-20 text-center">
    <h1 class="mb-3">Создать дежурство</h1>
    <form @submit.prevent="createDutyScheduleBtn">
      <div class="mb-2">
        <div class="mt-1"><strong>{{ currentDutySchedule.manager }}</strong></div>
      </div>
      <div class="mb-2">
        <label class="block">Дата начала {{ currentDutySchedule.started_at?.slice(10, 16) }}</label>
        <input class="block input-form mt-1" type="date" :value="currentDutySchedule.started_at.slice(0,10)" required @change="changeTimeStarted($event)" max="2999-12-31"/>
      </div>
      <div class="">
        <label class="block">Дата окончания {{ currentDutySchedule.ended_at?.slice(10, 16) }}</label>
        <input class="block input-form mt-1" type="date" :value="currentDutySchedule.ended_at.slice(0,10)" required @change="changeTimeEnded($event)" max="2999-12-31"/>
      </div>
      <div class="mt-3 flex gap-2">
        <button type="submit" class="btn" @click.prevent="createDutyScheduleBtn">Создать</button>
        <button type="button" class="btn" style="background: gray" @click.prevent="closeModal">Отмена</button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
    name: 'DutyScheduleCreateFromCopy',
    props: {
        currentDutySchedule: {
            type: Object,
            default: ()=> {}
        },
    },
    data() {
        return {
            end_date: '',
            start_date: ''
        }
    },
    methods: {
        ...mapActions({
      createDutySchedule: 'createDutySchedule',
      fetchSchedule: 'fetchDutyScheduleAll',
      errorAlert: 'errorAlert',
    }),
        changeTimeStarted(event) {
            this.start_date = event.target.value + this.currentDutySchedule.started_at.slice(10,16)
        },
        changeTimeEnded(event){
            this.end_date = event.target.value + this.currentDutySchedule.ended_at.slice(10,16)
        },
        closeModal(){
        this.$emit('close')
        },    
    async createDutyScheduleBtn() {
      const obj = {
        schedule: [
          {
            manager_id: this.currentDutySchedule.manager_id,
            started_at: this.start_date,
            ended_at: this.end_date,
          }
        ]
      }
      await this.createDutySchedule(obj).then((res)=> {
        if (res.success){
          this.fetchSchedule()
          this.$emit('close')
          this.$emit('successModal')
        } else {
          this.errorAlert(`${res.message}`)
        }
      }).catch((err)=>{
        this.errorAlert(`${err.message}`)
      })
    }
    },
   
}
</script>