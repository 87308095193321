<template>
  <div class="bg-white rounded p-20">
    <div class="flex justify-between items-center">
      <h1 class="text-24">Блок</h1>
      <button class="flex align-center" :disabled="disabled" @click="deleteBlock">
        <img src="/assets/TrashBr.svg" />
      </button>
    </div>

    <div class="mt-2 flex justify-between items-center">
      <div>
        <label class="block">Название</label>
        <input class="input-form mt-1 mb-1" :disabled="disabled" type="text" v-model="title">
      </div>
      <div>
        <label class="block" for="">Импортировать из MS Word</label>
        <input type="file" accept=".docx" :disabled="disabled" @change="importDocx" class="mt-1 mb-1">
      </div>

    </div>

    <div class="mt-2">
      <div class="mb-2">Описание</div>
      <QuillEditor theme="snow" toolbar="full" :read-only="disabled" v-model:content="description" content-type="html"></QuillEditor>
    </div>

    <div class="mt-3">
      <div class="mb-2">Видео</div>
      <input multiple type="file" accept="video/mp4,video/x-m4v,video/*" :disabled="disabled" @change="getVideoFiles"/>
      <div class="mt-3" v-for="(video, index) in videos" :key="video.file_name">
        <h1 class="mb-2">{{ video.file_name }}</h1>
        <div class="flex gap-2">
          <video :src="video.file_url" controls width="600"></video>
          <div>
            <button v-if="!disabled" class="btn error-btn" @click="deleteVideo(video.id, index)">Удалить видео</button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <button v-if="!disabled" class="btn-green btn" style="width: auto" @click.prevent="saveBlock" :disabled="disabled">{{ saveBlockText }}</button>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import { mapActions } from "vuex";

export default {
  name: "WikiBlock",
  components: { QuillEditor },
  props: {
    id: [String, Number],
    blocks: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    saveBlockText: { type: String, default: "Сохранить" }
  },
  created() {
    if (this.blocks) {
      this.title = this.blocks.title;
      this.description = this.blocks.description;
      this.videos = this.blocks.files ? this.blocks.files : [];
    }
  },
  data() {
    return {
      title: "",
      description: null,
      attached_files: [],
      videos: []
    };
  },
  methods: {
    ...mapActions({
      deleteMedia: "deleteWikiBlockMedia"
    }),
    deleteBlock() {
      this.$emit("deleteBlock", this.id);
    },
    saveBlock() {
      if (typeof this.blocks?.id === "number") {
        this.$emit("editBlock", {
          title: this.title,
          description: this.description,
          id: this.blocks.id,
          attached_files: this.attached_files
        });
      } else {
        this.$emit("saveBlock", {
          title: this.title,
          description: this.description,
          attached_files: this.attached_files
        });
      }
    },
    getVideoFiles(evt) {
      const files = evt.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.videos.push({ 
            file_name: file.name, 
            file_url: URL.createObjectURL(file)
          });
          this.attached_files.push(file);
        };
        reader.readAsArrayBuffer(file);
      }
    },
    async deleteVideo(id, index) {
      if (id) {
        await this.deleteMedia({ id: id })
            .then((res) => {
              if (res.success) {
                this.$toast.success("Данные успешно изменены");
                this.videos = this.videos.filter((item) => item.id !== id);
              } else {
                this.$toast.error("Не удалось изменить данные");
              }
            })
            .catch((err) => {
              this.$toast.error(`${err.message}`);
            });
      } else {
        this.videos.splice(index, 1);
      }
    },
   async importDocx(event) {
     const Mammoth = await import('mammoth')
      const file = event.target.files[0];
      if (!file || !file.name.endsWith(".docx")) {
        this.$toast.error("Пожалуйста, загрузите файл формата .docx");
        return;
      }

     const reader = new FileReader();
     reader.onload = async (e) => {
       const arrayBuffer = e.target.result;
       try {
         const result = await Mammoth.convertToHtml({ arrayBuffer });
         this.description = result.value;
       } catch (error) {
         this.$toast.error("Ошибка обработки файла");
       }
     };
      reader.readAsArrayBuffer(file);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
