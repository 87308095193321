<template>
  <div class="p-20">
    <div class="choose-modal">
      <div class="choose-modal__title w-100 mb-4 text-center text-24">
        Выберите бригадира
      </div>
      <div class="dropdown w-100">
        <input
            type="text"
            class="input-form"
            v-model="search"
            @click="toggleDropdown"
            @input="showDropdown = true"
            placeholder="Выберите бригадира"
        />
        <div class="dropdown-content" :class="{ show: showDropdown }">
          <div
              v-for="option in filteredInstallers"
              :key="option.id"
              @click="selectOption(option)"
          >
            {{ option.full_name }}
          </div>
        </div>
      </div>

      <button type="button" class="btn mb-2 mt-4" @click="setOwner">Назначить</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "BrigadeAddOwnerModal",
  data() {
    return {
      search: '',
      owner_id: 0,
      showDropdown: false,
      filteredInstallers: this.installers,
      currentOwner: {},
    }
  },
  computed: {
    ...mapGetters({
      installers: 'installers',
      currentBrigade: 'currentBrigadeOptions',
    })
  },
  watch: {
    search(value) {
      this.filteredInstallers = this.installers.filter(option =>
          option.full_name.toLowerCase().includes(value.toLowerCase())
      );
    },
    installers(newOptions) {
      this.filteredInstallers = newOptions;
    }
  },
  methods: {
    ...mapActions({
      changeOwner: 'changeBrigadeOwner'
    }),
    ...mapMutations({
      setCurrentBrigadeOwner: 'setCurrentBrigadeOwner'
    }),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.owner_id = option.id
      this.search = option.full_name;
      this.currentOwner = option
      this.showDropdown = false;
    },
   async setOwner(){
       await this.changeOwner({brigade_id: this.currentBrigade.brigade.id, owner_id: this.owner_id}).then((res)=>{
         if (res.success){
           this.$toast.success('Бригадир успешно назначен')
           this.setCurrentBrigadeOwner(this.currentOwner)
           this.$emit('close')
         } else {
           this.$toast.error('Не удалось назначить бригадира')
         }
       }).catch((err)=> {
         this.$toast.error(`${err.message}`)
       });
    }
  },
  unmounted() {
    this.currentOwner = {}
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
</style>