<template>
  <main class="-mt-32" style="overflow-x: auto">
    <div class="mt-4 mb-2" :class="{ 'mb-9': currentTab === 'all' }">
      <div class="flex justify-end w-100">
        <div class="form__row_field mr-2 search">
          <img src="/assets/search.svg" alt="" />
          <input
            class="input-form"
            type="text"
            v-model="search"
            ref="search"
            placeholder="Поиск..."
            @keyup="searchUsers"
          />
        </div>
        <!-- <button
          v-if="isDevSuperAdmin"
          class="btn btn-create flex align-center justify-center w-max-content mr-2"
          type="button"
          @click="toggleDepartmentModal"
        >
          <img src="/assets/plus.svg" alt="" />Создать отдел
        </button> -->
        <!-- <button
          v-if="isDevSuperAdmin"
          class="btn btn-create flex align-center justify-center w-max-content"
          type="button"
          @click="toggleCreateModal"
        >
          <img src="/assets/plus.svg" alt="" />Создать пользователя
        </button> -->
      </div>
    </div>
    <custom-table
      :columns="tableColumns"
      :data="paginatedUsers"
      header-style="blue-gradient"
      :is-pagination="false"
      @get-detail="getUserDetail"
      @page-change="fetchFilteredUsers"
    />

    <div class="pagination-wrapper">
      <pagination-component 
        v-if="users.length"
        :total-pages="totalPages"
        :current-page="currentPage"
        @page-change="currentPage = $event"
      />
    </div>

    <CustomModal v-if="isCreateModal" @close="toggleCreateModal">
      <new-user @close="toggleCreateModal" @on-submit="onCreateFormSubmit" />
    </CustomModal>

    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <SuccessModal @close="toggleSuccessModal">
        {{ successMessage }}
      </SuccessModal>
    </custom-modal>

    <custom-modal v-if="isUserDetailModal" @close="toggleUserDetailModal">
      <user-details
        :details="userInfo"
        @on-submit="onUpdateFormSubmit"
        @on-block="onBlockSubmit"
      />
    </custom-modal>

    <CustomModal v-if="isDepartmentModal" @close="toggleDepartmentModal">
      <new-department 
        @close="toggleDepartmentModal"
        @on-submit="onCreateDepartmentSubmit"
      />
    </CustomModal>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CustomTable from "@/components/modules/CustomTable.vue";
import { formatDate } from "@/helpers/utils";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import UserDetails from "@/components/modals/user/UserDetails.vue";
import NewUser from "@/components/modals/user/NewUser.vue";
import NewDepartment from '@/components/modals/department/NewDepartment.vue'
import PaginationComponent from '@/components/ui/PaginationComponent.vue'

import { UserTableColumns } from "@/modules/table-columns";

export default {
  name: "UsersView",
  components: {
    CustomTable,
    NewUser,
    CustomModal,
    UserDetails,
    SuccessModal,
    NewDepartment,
    PaginationComponent,
  },
  data() {
    return {
      isSideModalOpen: false,
      statusesValue: "",
      isUserDetailModal: false,
      isSuccessModal: false,
      isCreateModal: false,
      search: "",
      searchTimeout: null,
      successMessage: "",
      isDepartmentModal: false,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  async mounted() {
    await this.fetchFilteredUsers();
    if (!this.departments || !this.departments.length) await this.fetchDepartments()
    if (!this.groups || !this.groups.length) await this.fetchGroups()
  },
  computed: {
    ...mapGetters({
      user: "user",
      users: "users",
      departments: "departments",
      userInfo: "userInfo",
      groups: 'groups',
      managerInfo: 'managerInfo'
    }),
    totalPages() {
      return Math.ceil(this.users.length / this.itemsPerPage);
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      
      const sortedUsers = [...this.users].sort((a, b) => {
        const roleA = a.role?.title || '';
        const roleB = b.role?.title || '';
        return roleA.localeCompare(roleB);
      });
      return sortedUsers.slice(start, end);
    },
    isDevSuperAdmin() {
      return this.user?.role?.key === 'dev_super_admin';
    },
    tableColumns() {
      return UserTableColumns;
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers",
      createUser: "createUser",
      updateUser: "updateUser",
      blockUser: "blockUser",
      fetchUserDetails: "fetchUserDetails",
      fetchDepartments: 'fetchDepartments',
      fetchGroups: 'fetchGroups',
      storeDepartment: 'storeDepartment',
    }),
    ...mapMutations({
      setChosenUser: "setChosenTicket",
      setLoading: "setLoading",
    }),
    toggleCreateModal() {
      this.isCreateModal = !this.isCreateModal;
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal;
    },
    toggleUserDetailModal() {
      this.isUserDetailModal = !this.isUserDetailModal;
    },
    toggleDepartmentModal() {
      this.isDepartmentModal = !this.isDepartmentModal
    },
    async getUserDetail(user) {
      this.setLoading(true);
      await this.fetchUserDetails(user.id);
      this.toggleUserDetailModal();
      this.setLoading(false);
    },
    async onCreateFormSubmit(user) {
      await this.createUser(user).then((res) => {
          this.toggleCreateModal();
          this.successMessage = "Пользователь успешно создан!"
          this.fetchFilteredUsers();
          this.toggleSuccessModal();
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async onUpdateFormSubmit(user) {
      await this.updateUser(user).then((res)=> {
        if (res.success) {
          this.toggleUserDetailModal()
          this.successMessage = "Пользователь успешно изменен!"
          this.toggleSuccessModal();
          this.fetchFilteredUsers();
        } else {
          this.$toast.error('Не удалось изменить данные пользователя')
        }
      }).catch((err)=> {
        this.$toast.error(`${err.message}`)
      });
    },
    async onBlockSubmit(id) {
      await this.blockUser(id);
      this.toggleUserDetailModal()
      this.successMessage = "Пользователь успешно заблокирован!"
      await this.fetchFilteredUsers();
      this.toggleSuccessModal();
    },
    searchUsers() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredUsers();
      }, 1500);
    },
    async fetchFilteredUsers() {
      this.setLoading(true);
      let filter = {};
      if (this.search.length > 0) filter.full_name = this.search;
      else delete filter.full_name;
      await this.fetchUsers(filter);
      this.setLoading(false);
    },
    async onCreateDepartmentSubmit(department) {
      const res = await this.storeDepartment(department)
      if (res.success) {
        this.toggleDepartmentModal()
        this.successMessage = "Отдел успешно создан!"
        this.toggleSuccessModal()
      }
    },
  },
  watch: {
    isUserDetailModal() {
      if (!this.isUserDetailModal) this.setChosenUser({});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../theme/variables";
.btn {
  width: max-content;
}
.tab {
  width: 251px;
  height: 39px;
  border-radius: 8px;
  background: #fff;
  color: #8d8d8d;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.07px;
  margin-right: 12px;
  cursor: pointer;
  padding: 10px 0;

  &.active,
  &:hover {
    background: #00557b;
    color: $white;
  }

  &.service.active,
  &.service:hover {
    background: linear-gradient(135deg, #149d8f 0%, #35d784 100%);
  }

  &.emergency.active,
  &.emergency:hover {
    background: linear-gradient(135deg, #ffb06d 0%, #f67785 100%);
  }

  &.plan.active,
  &.plan:hover {
    background: linear-gradient(132deg, #ff830a 79.69%, #ffc535 100%);
  }
}

.form__row_field {
  width: 338px;
}

.btn-create {
  margin-left: 10px;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 40px;
}
</style>
