<template>
  <textarea
      :class="['app-textarea', className]"
      :minlength="minLength"
      :maxlength="maxLength"
      :name="name"
      :placeholder="placeholder"
      :id="id"
      v-model="model"
      ref="appTextareaElementRef"
      @input="onInputHandler"
      @change="onChangeHandler"
      style="height: 140px"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    minLength: {
      type: Number,
      required: false,
      default: 0,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 999,
    },
    maxResizeHeight: {
      type: Number,
      required: false,
      default: 700,
    },
    className: {
      type: String,
      required: false,
      default: '',
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      model: this.modelValue,
      defaultTextareaHeight: 0,
    };
  },
  methods: {
    onInputHandler(evt) {
      this.$emit('update:modelValue', evt.target.value);

      this.handleResize();
    },
    onChangeHandler(evt) {
      this.$emit('change', evt.target.value);
    },
    handleResize() {
      const elem = this.$refs.appTextareaElementRef;

      if (!elem) {
        return;
      }

      if (!this.defaultTextareaHeight) {
        this.defaultTextareaHeight = elem.offsetHeight
      }

      if (elem.scrollHeight < this.defaultTextareaHeight) {
        elem.style.height = `${this.defaultTextareaHeight}px`;
      } else {
        elem.style.height = 'auto';
        elem.style.height = `${Math.min(Math.max(elem.scrollHeight, this.defaultTextareaHeight), this.maxResizeHeight)}px`;
      }
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.model = this.modelValue;
      },
      immediate: true,
    }
  }
}
</script>

<style scoped lang="scss">
.app-textarea {
  width: 100%;
  height: 100px;

  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid #ADADAD;
  background: #F2F1F0;

  color: #333;
  font-size: 16px;
  font-weight: 400;

  resize: none;
  box-sizing: border-box;
}
</style>
