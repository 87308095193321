<template>
  <div class="wrapper modified flex column">
    <div class="mb-3">
      <div class="bg-white p-20 rounded">
        <div class="w-100 p-20 border-bottom">
          <filter-bar
              :is-create="true"
              :create-text="'Создать заявку'"
              :is-search="true"
              :is-sort="false"
              :is-filter="false"
              :is-date="true"
              :search="search"
              :date-range="dateRange"
              @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
                fetchFilteredTickets();
              }"
              @on-search="searchTickets"
              @on-create="toggleCreateModal"
          />
        </div>
        <div class="w-100 mt-2">
          <custom-table
              :columns="tableColumns"
              :data="tickets"
              :total-pages="ticketsTotalPages"
              :current-page="currentPage"
              :is-pagination="true"
              :active-id="chosenTicket?.ticket?.id"
              @get-detail="getTicketDetail"
              @page-change="fetchFilteredTickets"
              @move-column="moveTableColumn"
          />
        </div>
      </div>
    </div>
  </div>
  <custom-modal v-if="isCreateTicketModal" @close="toggleCreateModal">
    <choose-ticket-type @choose-ticket-kind="chooseTicketKind"/>
  </custom-modal>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import {formatDate} from "@/helpers/utils";

import {TicketsTableColumns, CabinetTicketsTableColumns} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import TicketDetailsComponent from "@/components/cards/TicketDetailsComponent.vue";
import router from "@/router";
import ChooseTicketType from "@/components/modals/tickets/ChooseTicketType.vue";
import CustomModal from "@/components/modules/CustomModal.vue";


export default {
  name: 'CabinetTicketsView',
  components: {
    CustomModal, ChooseTicketType,
    FilterBar,
    CustomTable,
  },
  data() {
    return {
      tableColumns: [],
      dateFrom: null,
      dateRange: null,
      dateTo: null,
      search: '',
      searchTimeout: null,
      currentPage: 1,
      isOnlyOpen: false,
      isLC: true,
      perPage: 8,
      isCreateTicketModal: false,
    }
  },
  created() {
    if (window.screen.width < 1025) {
      this.perPage = 5
    }
    if (window.localStorage.getItem('CabinetTicketsTableColumns')) this.tableColumns = JSON.parse(window.localStorage.getItem('CabinetTicketsTableColumns'))
    else this.tableColumns = CabinetTicketsTableColumns

    this.setLoading(true)
    let promises = [this.fetchFilteredTickets(), this.fetchTicketKinds()]
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      tickets: 'tickets',
      ticketsTotalPages: 'ticketsTotalPages',
      totalTickets: 'totalTickets',
      chosenTicket: 'chosenTicket',
    }),
  },
  methods: {
    ...mapActions({
      fetchTickets: 'fetchTickets',
      fetchTicketDetail: 'ticketDetail',
      fetchTicketKinds: 'fetchTicketKinds',
    }),
    ...mapMutations({
      setChosenTicket: 'setChosenTicket',
      setLoading: 'setLoading'
    }),
    getTitleByKey(array, key) {
      if (array.find(item => item.key === key)) return array.find(item => item.key === key).count
    },
    // async getTicketDetail(ticket) {
    //   this.setLoading(true)
    //   await this.fetchTicketDetail(ticket.id)
    //   this.setLoading(false)
    // },
    async getTicketDetail(ticket) {
      this.setLoading(true)
      router.push(`/cabinet-tickets/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    moveTableColumn(columns) {
      this.tableColumns = columns
      window.localStorage.setItem('TicketsTableColumns', JSON.stringify(columns))
    },
    resetFilters() {
      this.selectedStatuses = []
      this.dateFrom = null
      this.dateTo = null
      this.author = null
      this.responsible = null
      this.serviceType = null
      this.currentColumnSortParams = {
        value: '',
        order: ''
      }
      this.search = ''
    },
    searchTickets(searchText) {
      this.search = searchText
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredTickets()
      }, 1500)
    },
    async fetchFilteredTickets(page = 1) {
      this.setLoading(true)
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      filters.is_only_open = this.isOnlyOpen ? '1' : '0'
      filters.is_lc = this.isLC ? '1' : '0'
      filters.responsible_department_id = 100
      this.currentPage = page
      if (this.dateFrom) filters.date_from = formatDate(this.dateFrom, '-')
      if (this.dateTo) filters.date_to = formatDate(this.dateTo, '-')
      if (this.search) filters.client_name = this.search

      await this.fetchTickets(filters)

      // if (this.tickets[0]?.id)
      //   await this.fetchTicketDetail(this.tickets[0].id);

      this.setLoading(false)
    },
    toggleCreateModal() {
      this.isCreateTicketModal = !this.isCreateTicketModal
    },
    async chooseTicketKind(id) {
      this.toggleCreateModal()
      if (id === 2) {
        await router.push('/service-ticket-form')
      } else if (id === 8) {
        await router.push('/plan-ticket-form')
      } else if (id === 10) {
        await router.push('/consultation-ticket-form')
      } else if (id === 12) {
        await router.push('/internal-ticket-form')
      } else if (id === 16) {
        await router.push('/access-form-view')
      } else await router.push('/emergency-ticket-form')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}
</style>
