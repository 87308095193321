import {api} from '@/service/axios'

export const resourcesModule = {
  state: () => ({
    categories: [],
    types: [],
    statuses: [],
    companies: [],
    districts: [],
    detailTypes: [],
    clients: [],
    clients_simple: [],
    taskScoreStatuses: [],
    addresses: [],
    ticketKinds: [],
    ticketKindsWithTypes: [],
    problemTypes: [],
    serviceTypes: [],
    cities: [],
    clientTypes: [],
    ticketResults: [],
    nodes: [],
    managers: [],
    departments: [],
    groups: [],
    brigades: [],
    connectionStatuses: [],
    connectionTypes: [],
    nodeTypes: [],
    brigadeByDepartment: [],
  }),
  getters: {
    categories(state) {
      return state.categories
    },
    types(state) {
      return state.types
    },
    statuses(state) {
      return state.statuses
    },
    companies(state) {
      return state.companies
    },
    districts (state) {
      return state.districts
    },
    detailTypes(state) {
      return state.detailTypes
    },
    clients(state) {
      return state.clients
    },
    clientsSimple(state) {
      return state.clients_simple
    },
    taskScoreStatuses(state) {
      return state.taskScoreStatuses
    },
    addresses(state) {
      return state.addresses
    },
    ticketKinds(state) {
      return state.ticketKinds
    },
    ticketKindsWithTypes(state) {
      return state.ticketKindsWithTypes
    },
    problemTypes(state) {
      return state.problemTypes
    },
    serviceTypes(state) {
      return state.serviceTypes
    },
    cities(state) {
      return state.cities
    },
    clientTypes(state) {
      return state.clientTypes
    },
    ticketResults(state) {
      return state.ticketResults
    },
    nodes(state) {
      return state.nodes
    },
    managers(state) {
      return state.managers
    },
    departments(state) {
      return state.departments
    },
    groups(state) {
      return state.groups
    },
    brigades(state) {
      return state.brigades
    },
    connectionStatuses(state) {
      return state.connectionStatuses
    },
    connectionTypes(state) {
      return state.connectionTypes
    },
    nodeTypes(state) {
      return state.nodeTypes
    },
    brigadeByDepartment(state){
      return state.brigadeByDepartment
    }
  },
  mutations: {
    setCategories(state, payload) {
      state.categories = payload
    },
    setTypes(state, payload) {
      state.types = payload
    },
    setStatuses(state, payload) {
      state.statuses = payload
    },
    setCompanies(state, payload) {
      state.companies = payload
    },
    setDistricts(state, payload) {
      state.districts = payload
    },
    setDetailTypes(state, payload) {
      state.detailTypes = payload
    },
    setClients(state, payload) {
      state.clients = payload
    },
    setClientsSimple(state, payload) {
      state.clients_simple = payload
    },
    setTaskScoreStatuses(state, payload) {
      state.taskScoreStatuses = payload
    },
    setAddresses(state, payload) {
      state.addresses = payload
    },
    setTicketKinds(state, payload) {
      state.ticketKinds = payload
    },
    setTicketKindsWithTypes(state, payload) {
      state.ticketKindsWithTypes = payload
    },
    setProblemTypes(state, payload) {
      state.problemTypes = payload
    },
    setServiceTypes(state, payload) {
      state.serviceTypes = payload
    },
    setCities(state, payload) {
      state.cities = payload
    },
    setClientType(state, payload) {
      state.clientTypes = payload
    },
    setTicketResults(state, payload) {
      state.ticketResults = payload
    },
    setNodes(state, payload) {
      state.nodes = payload
    },
    setManagers(state, payload) {
      state.managers = payload
    },
    setDepartments(state, payload) {
      state.departments = payload
    },
    setGroups(state, payload) {
      state.groups = payload
    },
    setBrigades(state, payload) {
      state.brigades = payload
    },
    setConnectionStatuses(state, payload) {
      state.connectionStatuses = payload
    },
    setConnectionTypes(state, payload) {
      state.connectionTypes = payload
    },
    setNodeTypes(state, payload) {
      state.nodeTypes = payload
    },
    setBrigadeByDepartment(state, payload){
      state.brigadeByDepartment = payload
    },
    setDids(state, payload){
      state.clients_simple = [...state.clients_simple, payload]
    }
  },
  actions: {
    async fetchCategories({commit}, payload) {
      const res = await api.get(`dict/get-ticket-category?ticket_kind_id=${payload}`)
      commit('setCategories', res.data)
    },
    async fetchTypes({commit}, payload) {
      let url = `dict/get-ticket-type/${payload.id}`
      if (payload.problem) url += `?problem_type_id=${payload.problem}`
      const res = await api.get(url)
      commit('setTypes', res.data)
    },
    async fetchStatuses({commit}, payload) {
      const res = await api.get('dict/get-ticket-statuses', {params: payload})
      commit('setStatuses', res.data)
    },
    async fetchCompanies({commit}) {
      const res = await api.get('dict/get-companies')
      commit('setCompanies', res.data)
    },
    async fetchDistricts({commit}) {
      const res = await api.get('dict/get-districts')
      commit('setDistricts', res.data)
    },
    async fetchDetailTypes({commit}, payload) {
      try {
        const res = await api.get(`dict/get-detail-types/${payload.category_id}/${payload.vg_id}/${payload.problem_type_id}`)
        commit('setDetailTypes', res.data)
      }
      catch (e) {
        console.log(e)
      }
    },
    async fetchClients({commit}, payload) {
      const res = await api.get(`clients/client-list?search=${payload}`)
      commit('setClients', res.data)
    },
    async clientsSearch({commit}, payload) {
      const res = await api.get(`clients/client-search?search=${payload}`)
      if (res.success) {
        let results = []
        
        // Добавляем обычных клиентов, если они есть
        if (res.data?.length) {
          results = [...res.data]
        }
        
        // Добавляем обычные тикеты, если они есть
        if (res.data_tickets?.tickets?.length) {
          results = [...results, ...res.data_tickets.tickets]
        }
        
        // Добавляем тикеты соединений, если они есть
        if (res.data_connection_tickets?.length) {
          const connectionTickets = res.data_connection_tickets.map(ticket => ({
            ticket_number: ticket.ticket_number,
            ticket_id: ticket.id,
            name: ticket.connection_client?.name,
            iin: ticket.connection_client?.iin,
            contact_info: ticket.connection_client?.phone_number,
            status: ticket.status?.title,
            brigade: ticket.brigade_title,
            type: ticket.type?.type,
            address: ticket.address,
            ticket_kind: {
              key: 'connection',
              is_connection: true
            }
          }))
          results = [...results, ...connectionTickets]
        }

        commit('setClientsSimple', results)
      }
    },
    async fetchTaskScoreStatuses({commit}) {
      const res = await api.get(`score/status-list`)
      commit('setTaskScoreStatuses', res.data)
    },
    async fetchAddresses({commit}, payload) {
      const res = await api.get(`dict/get-address?address_string=${payload}`)
      if(res.data.addresses) commit('setAddresses', res.data.addresses)
      return res.data
    },
    async fetchTicketKinds({commit}) {
      const res = await api.get(`dict/get-ticket-kinds`)
      commit('setTicketKinds', res.data)
    },
    async fetchTicketKindsWithTypes({commit}) {
      const res = await api.get(`dict/get-ticket-kinds-with-types`)
      commit('setTicketKindsWithTypes', res.data)
    },
    async fetchProblemTypes({commit}, payload) {
      const res = await api.get(`dict/get-problem-types?ticket_category_id=${payload}`)
      commit('setProblemTypes', res.data)
    },
    async fetchServiceTypes({commit}, payload) {
      const res = await api.get(`dict/get-service-types?ticket_kind_id=${payload}`)
      commit('setServiceTypes', res.data)
    },
    async fetchCities({commit}) {
      const res = await api.get(`dict/get-cities`)
      commit('setCities', res.data)
    },
    async fetchClientTypes({commit}) {
      const res = await api.get(`dict/get-client-types`)
      commit('setClientType', res.data)
    },
    async fetchTicketResults({commit}, payload) {
      const res = await api.get(`dict/get-ticket-results?ticket_kind_id=${payload}`)
      commit('setTicketResults', res.data)
    },
    async fetchNodes({commit}) {
      const res = await api.get(`tickets/get-nodes`)
      commit('setNodes', res.data)
    },
    async fetchManagers({commit}, payload) {
      const res = await api.get(`managers/author-list`, { params: payload})
      commit('setManagers', res.data)
      return res.data
    },
    async fetchDepartments({commit}, payload) {
      const res = await api.get(`dict/get-departments`, {
        params: payload
      })
      commit('setDepartments', res.data)
    },
    async fetchGroups({ commit }) {
      const res = await api.get(`dict/get-groups`);
      commit("setGroups", res.data);
    },
    async fetchBrigades({ commit }) {
      const res = await api.get('dict/get-brigades')
      commit('setBrigades', res.data)
    },
    async fetchConnectionStatuses({commit}) {
      const res = await api.get('dict/get-connection-ticket-statuses-list')
      commit('setConnectionStatuses', res.data)
    },
    async fetchConnectionTypes({commit}) {
      const res = await api.get('dict/get-connection-types')
      commit('setConnectionTypes', res.data)
    },
    async fetchNodeTypes({commit}) {
      const res = await api.get('dict/get-node-types')
      commit('setNodeTypes', res.data)
    },
    async fetchBrigadeByDepartment({commit}, payload){
      const res = await api.get(`/brigade/by-department`, {params: payload})
      commit('setBrigadeByDepartment', res.data)
      return res
    },
    async fetchDids({commit}, payload){
      const res = await api.post('billing/get-dids', payload)
      if (res.data.dids){
        commit('setDids',...res.data.dids.did)
      }
      return res
    }
  }
}
