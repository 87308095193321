<template>
  <div class="flex align-center">
    <div class="avatar mr-2">
      <img v-if="image" :src="image" alt="" />
    </div>
    <div class="flex column justify-between">
      <p class="text-14 text-black2">{{fio}}</p>
      <p class="text-14 uppercase bold text-gray2">{{role}}</p>
    </div>
    <img v-if="isArrow" class="ml-1" src="/assets/arrow-down.svg" alt="">
  </div>

</template>

<script>


export default {
  name: 'UserInfo',
  props: ['fio', 'role', 'image', 'isArrow'],
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 32px;
  height: 32px;
  background: $gray;

  img {
    width: 32px;
    height: 32px;
  }

}

</style>
