<template>
  <div class="wrapper modified flex column">
    <div class="flex mb-4">
      <div
          class="ticket-menu flex justify-center align-center cursor-pointer mr-4"
          v-for="menu in menuMainArray"
          :key="menu.key"
          :class="{'active': currentTab === menu.key}"
          @click="changeTab(menu)"
      >
        {{ menu.title }}
      </div>
    </div>
    <div class="bg-white p-20 rounded flex column gap-2">
      <div class="filter gap-2">
        <div class="flex column gap-1">
          <label class="label-text">Технология</label>
          <AppSelect v-model="filterForm.technology" @change="fetchItems(filterForm)" :placeholder="'Все'"
            :options="connectionTechnologies" labelKey="name_ru" />
        </div>
        <div class="flex column gap-1">
          <label class="label-text">Менеджера</label>
          <AppSelect v-model="filterForm.manager" :placeholder="'Все'" @change="fetchItems(filterForm)" :options="managers" labelKey="full_name" />
        </div>
        <div @click="showDropdownCalendar = !showDropdownCalendar">
          <calendar-departure :show-dropdown-calendar="showDropdownCalendar" @setDate="setDate"></calendar-departure>
        </div>
        <div class="flex column gap-1">
          <label class="label-text">Статус</label>
          <AppSelect v-model="filterForm.status" @change="fetchItems(filterForm)" :placeholder="'Все'" :options="statuses" labelKey="title" />
        </div>
      </div>
      <div class="">
        <custom-table :columns="QueriesTCShortColumns" :data="items" :total-pages="meta.last_page"
          :current-page="currentPage" :is-pagination="true" @get-detail="getQueriesTCDetail"   @page-change="fetchFilteredItems" />
      </div>
    </div>
  </div>
</template>


<script>
import { QueriesTCShortColumns } from "@/modules/table-columns";
import { mapActions, mapGetters, mapMutations } from "vuex";
import router from "@/router";
import CustomTable from '@/components/modules/CustomTable.vue'
import AppSelect from "@/components/ui/AppSelect.vue";
import CalendarDeparture from "@/components/calendar/calendar-departure.vue";
import { formatDate } from "@/helpers/utils";

export default {
  name: "QueriesTCView",
  components: { CalendarDeparture, CustomTable, AppSelect },
  data() {
    return {
      QueriesTCShortColumns,
      filterForm: {
        manager: null,
        technology: null,
        status: null,
        dateFrom: null,
        dateTo: null,
        ticketStatus: null
      },
      currentPage: 1,
      showDropdownCalendar: false,
      menuMainArray: [
        {
          key: 'department',
          title: 'Открытые  заявки'
        },
        {
          key: 'all',
          title: 'Все заявки'
        },
        {
          key: 'closed',
          title: 'Закрытые заявки'
        }
      ],
      currentTab: 'department',
    }
  },
  computed: {
    ...mapGetters('queriesTCModule', {
      connectionTechnologies: 'connectionTechnologies',
      managers: 'managers',
      statuses: 'statuses',
      items: 'items',
      meta: 'meta',
    }),
    ...mapGetters({
      statuses: 'statuses',
      user: 'user',
    })
  },
  methods: {
    ...mapActions('queriesTCModule', {
      fetchItems: 'fetchItems',
      fetchConnectionTechnologies: 'fetchConnectionTechnologies',
      fetchManagers: 'fetchManagers',
    }),
    ...mapActions({
      fetchStatuses: 'fetchStatuses'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      clearTicketData: 'queriesTCModule/clearTicketData',
    }),
    async getQueriesTCDetail(ticket) {
      this.clearTicketData()
      this.$store.commit('setTicketComments', [])
      this.$store.commit('setCurrentCommentPage', 1)
      this.setLoading(true)
      try {
        await router.push(`/queries-technical-capability/${ticket.ticket_number}`)
      } finally {
        this.setLoading(false)
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return formatDate(date, '-', false)
    },
    setDate(data) {
      this.filterForm.dateFrom = data?.[0]
      this.filterForm.dateTo = data?.[1]
      this.showDropdownCalendar = false
      this.fetchFilteredItems()
    },
    fetchFilteredItems(page=1){
      if (this.currentTab === 'department') {
        this.filterForm.ticketStatus = 'open'
      } else if (this.currentTab === 'closed') {
        this.filterForm.ticketStatus = 'closed'
      } else {
        delete this.filterForm.ticketStatus
      }
      this.fetchItems({...this.filterForm,page: page})
      this.currentPage = page
      this.filterForm.page = page
    },
    changeTab(item) {
      this.currentTab = item.key
      this.fetchFilteredItems()
    },
  },
  created() {
    this.fetchStatuses()
    this.fetchConnectionTechnologies()
    this.fetchManagers()
  }
}

</script>


<style scoped lang="scss">
@import '../../theme/variables';

.filter {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
}

.label-text {
  font-size: 13px;
  color: #8b8b8b
}

::v-deep .choose-modal__title {
  font-size: 13px;
  color: #8b8b8b
}

::v-deep .vs__dropdown-toggle {
  height: 48px;
  border: 1px solid #ADADAD;
}

::v-deep .vs__search {
  margin-top: 0;
}

.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}
</style>