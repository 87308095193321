import {api} from "@/service/axios";
export const reportsModule = {
    state: ()=> ({
        reports: []
    }),
    getters: {
        reports: state => {
            return state.reports;
        }
    },
    mutations: {
        setReports(state, payload) {
            state.reports = payload;
        }
    },
    actions: {
       async fetchReports({commit}, payload) {
            const res = await api.get('ticket-reports/ticket-counts-by-department')
            commit('setReports', res);
            return res
        }
    }
}