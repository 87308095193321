<template>
  <div class="">
    <div class="p-20">
      <div v-if="type !== 'connection' && connectionLogs">
        <h1 class="text-center text-24 mb-4">История</h1>
        <div class="grid" style="background-color: lightgray;">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-date">
            <p>Дата и время</p>
          </div>
          <div class="grid-author">
            <p>Автор</p>
          </div>
          <div class="grid-action">
            <p>Действие</p>
          </div>
          <div class="grid-change">
            <p>Изменение</p>
          </div>
          <div class="grid-comment">
            <p>Комментарий</p>
          </div>
        </div>
        <div class="brigade-table">
          <div class="grid" v-for="(log,index ) in connectionLogs" :key="log">
            <div> {{ index + 1 }}</div>
            <div>{{ new Date(log.date).toISOString().slice(0, 19).replace("T", " ") }}</div>
            <div>
              {{ log.author ? log.author : 'System' }}
            </div>
            <div>
              {{ log.action }}
            </div>
            <div>{{ log.old_responsible_full_name ? log.old_responsible_full_name : 'Не указано' }} >
              {{ log.new_responsible_full_name }}
            </div>
            <div>{{ log.comment }}</div>
          </div>
        </div>
      </div>
      <div v-if="type === 'connection' && chosenConnectionTicket?.logs?.length > 0">
        <history-tab :history="chosenConnectionTicket.logs"></history-tab>
      </div>
      <div v-else class="p-20">
        Данных нет
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import HistoryTab from "@/components/tabs/HistoryTab.vue";

export default {
  name: "DepartureLogsModal",
  components: {HistoryTab},
  props: {
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    ticket: {
      type: Object,
      default: () => {
      }
    }
  },
  async created() {
    this.setLoading(true)
    if (this.type === 'connection') {
     await this.fetchConnectionTicketDetail(this.id)
    } else {
      await this.fetchConnectionDepartureLogs({id: this.id});
    }
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      connectionLogs: 'connectionLogs',
      chosenConnectionTicket: 'chosenConnectionTicket'
    }),
  },
  methods: {
    ...mapActions({
      fetchConnectionDepartureLogs: 'fetchConnectionDepartureLogs',
      fetchConnectionTicketDetail: 'fetchConnectionTicketDetail',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    })
  },
}
</script>


<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  width: 1024px;
  grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr;
  grid-template-areas: 'Num Date Author Action Change Comment';
}

.grid-num {
  grid-area: Num;
}

.grid-date {
  grid-area: Date;
}

.grid-author {
  grid-area: Author;
}

.grid-action {
  grid-area: Action;
}

.grid-change {
  grid-area: Change;
}

.grid-comment {
  grid-area: Comment;
}
</style>