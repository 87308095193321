<template>
  <div class="min-h-full main">
    <chat-component-test-rocket
        v-if="isRocketChatAvailable"
        :is-open="isOpenTest"
        @closeChat="closeChatRocket"
        :chat-path="currentChatPath"
    ></chat-component-test-rocket>
    <side-nav-bar v-if="!isAuth" />
    <div :class="{'container' : !isAuth, 'opened-bar': sideBarOpened}">
      <header-component v-if="!isAuth" @toggleProfile="toggleProfile" @toggleNotifications="toggleNotifications">
        <template #chat-icon>
          <div v-if="isRocketChatAvailable">
            <div class="chat">
              <chat-small
                  @open-chat="isOpenTest = true"
                  @open-chat-test="handleChatOpen"
                  :is-mobile="true"
              />
            </div>
          </div>
        </template>
      </header-component>
      <router-view />
    </div>

    <profile-modal v-if="isProfile" @close="toggleProfile" />

    <side-modal v-if="client.uid" @close="closeClientCard">
      <client-modal @close="closeClientCard" />
    </side-modal>

    <side-modal
      v-if="isClientDashboardModal"
      wide
      modal-style="padding: 0; background: #F5F5FA;"
      @close="closeClientDashboard"
    >
      <client-dashboard-modal @close="closeClientDashboardWithClient" />
    </side-modal>
    
    <user-notifications-modal v-if="isNotificationsModal" @close="toggleNotifications" />

    <side-modal
      v-if="isDashboardFiltersModal"
      small
      modal-style="padding: 38.5px;"
      @close="closeDashboardFiltersModal"
    >
      <dashboard-filters-modal />
    </side-modal>

    <side-modal v-if="isBrigadeAddInstallersModal" @close="setisBrigadeAddInstallersModal(false)">
      <brigade-add-installers-modal></brigade-add-installers-modal>
    </side-modal>

    <side-modal v-if="isDepartureClientInfoModal" @close="setIsDepartureClientInfoModal(false)">
      <departure-client-info></departure-client-info>
    </side-modal>


    <custom-loader v-if="loading" />
  </div>
</template>

<script>
import SideNavBar from "@/components/navigation/SideNavBar.vue";
import HeaderComponent from "@/components/navigation/Header.vue";
import SideModal from "@/components/modules/SideModal.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import ClientModal from "@/components/modals/client/ClientModal.vue";
import ClientDashboardModal from "@/components/modals/client/ClientDashboardModal.vue";
import ProfileModal from "@/components/modals/ProfileModal.vue";
import UserNotificationsModal from "@/components/modals/user/UserNotificationsModal.vue";
import DashboardFiltersModal from "@/components/modals/DashboardFiltersModal.vue";
import CustomLoader from "@/components/modules/CustomLoader.vue";
import BrigadeAddInstallersModal from "@/components/modals/brigade/BrigadeAddInstallersModal.vue"
import io from "socket.io-client";
import Echo from "laravel-echo";
import DepartureClientInfo from "@/components/modals/departureB2c/DepartureClientInfo.vue";
import ChatComponentTestRocket from "@/components/chat/chat-component-test-rocket.vue";
import ChatSmall from "@/components/chat/chat-small.vue";

export default {
  components: {
    DepartureClientInfo,
    ProfileModal,
    SideNavBar,
    HeaderComponent,
    SideModal,
    ClientModal,
    ClientDashboardModal,
    UserNotificationsModal,
    DashboardFiltersModal,
    CustomLoader,
    BrigadeAddInstallersModal,
    ChatComponentTestRocket,
    ChatSmall
  },
  data() {
    return {
      isClientCard: false,
      isProfile: false,
      isNotificationsModal: false,
      isOpenTest: false,
      currentChatPath: '',
    }
  },
  methods: {
    ...mapMutations({
      setClient: 'setClient',
      setClientAgreements: 'setClientAgreements',
      setClientDocuments: 'setClientDocuments',
      setClientEquipments: 'setClientEquipments',
      setIsClientDashboardModal: 'setIsClientDashboardModal',
      setClientDashboard: 'setClientDashboard',
      setIsDashboardFiltersModal: 'setIsDashboardFiltersModal',
      setisBrigadeAddInstallersModal: 'setisBrigadeAddInstallersModal',
      setIsDepartureClientInfoModal: 'setIsDepartureClientInfoModal',
      setUnreadCount: 'setUnreadCount'
    }),
    ...mapActions({
      closeDashboardFiltersModal: 'closeDashboardFiltersModal',
      checkLogin: 'checkLogin',
      fetchUnreadNotificationsCount: 'fetchUnreadNotificationsCount',
      initializeFromStorage: "initializeFromStorage",
    }),
    closeClientCard() {
      this.setClient({})
      this.setClientAgreements([])
      this.setClientDocuments([])
      this.setClientEquipments([])
    },
    toggleProfile() {
      this.isProfile = !this.isProfile
    },
    toggleNotifications() {
      this.isNotificationsModal = !this.isNotificationsModal
    },
    closeClientDashboard() {
      this.setIsClientDashboardModal(false)
    },
    closeClientDashboardWithClient() {
      this.closeClientDashboard()
      this.closeClientCard()
    },
    setupEchoWS(){
      const url = process.env.VUE_APP_NOTIFICATION_API_URL;
      window.io = io;
      window.Echo = new Echo({
        broadcaster: "socket.io",
        host: url,
        path: '/notification/socket.io',
        namespace: '',
        transports: ['websocket', 'polling']
      });
      const userId = this.user?.id;
      if (!userId) {
        console.error("User ID is not defined.");
        return;
      }

      window.Echo.channel(
          `notify_duty_manager_id_${userId}`
      ).listen(`Ticket\\TicketEnrollToEmployeeEvent`, (e) => {
        this.setUnreadCount(e.data?.cnt)
      });
    },
    handleChatOpen(path) {
      this.currentChatPath = path
      this.isOpenTest = true
    },
    async closeChatRocket(event) {
      this.isOpenTest = event
    },
  },
  computed: {
    ...mapGetters({
      client: 'client',
      isClientDashboardModal: 'isClientDashboardModal',
      loading: 'loading',
      sideBarOpened: 'sideBarOpened',
      isDashboardFiltersModal: 'isDashboardFiltersModal',
      isAlert: 'isAlert',
      alertText: 'alertText',
      alertType: 'alertType',
      isBrigadeAddInstallersModal: 'isBrigadeAddInstallersModal',
      user: 'user',
      isDepartureClientInfoModal: 'isDepartureClientInfoModal',
      isRocketChatAvailable: "isRocketChatAvailable"
    }),
    isAuth() {
      if (this.$route.name) {
        if (this.$route.name.indexOf('auth') > -1) {
          return true
        }
        else return false
      }
      else return false
    },
  },
  watch: {
    alertText() {
      if (!this.alertText) return;
      this.$toast.open({ message: this.alertText, type: this.alertType })
    },
    user(newValue) {
      if (newValue) {
        this.setupEchoWS();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('initializeFromStorage')
  },
  unmounted() {
    if (window.Echo) {
      window.Echo.disconnect();
    }
  }
}
</script>

<style lang="scss">
.main {
  position: relative;
}

.container {
  margin-left: 100px;
  padding-top: 90px;
  position: relative;
  transition: all .3s ease-out;
  background: url("~/public/assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  &.opened-bar {
    margin-left: 350px;
  }
}

.chat {
  position: relative;
  border-radius: 12px;
  border: 1px solid #E1E0E0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &__unread {
    border-radius: 50%;
    background: red;
    left: 32px;
    top: -4px;
    width: 14px;
    height: 14px;
    position: absolute;
    flex-shrink: 0;
  }
}
</style>
