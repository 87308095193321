<template>
  <div class="p-20">
    <h1 class="text-center mt-3" style="font-size: 29px;">ДОБАВЛЕНИЕ БРИГАДЫ</h1>
    <div class="mt-3">
      <form action="">

        <div style="background: #F5F5FA;border-radius: 10px;">
          <div class="mt-3" style="padding: 20px;">
            <p class="text-20 mb-1">Название</p>
            <input class="brigade-installer input-form" type="text" v-model="brigadeName"/>
            <p class="text-20 mb-1 mt-2">Bitrix ID</p>
            <input class="brigade-installer input-form" type="text" v-model="bitrix_id"/>
            <div class="mt-2">
              <div>Отдел</div>
              <div class="mt-2 mb-2">
                <select class="input-form select" v-model="department_id">
                  <option v-for="option in departmentList" :key="option.key" :value="option.id">
                    {{ option.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <div style="padding: 20px;">
            <p class="text-24 mb-2">Цвет бригады</p>
            <div class="w-100">
              <color-picker z-index="999" v-model:pure-color="color" is-widget="true"
                            format="hex" picker-type="chrome"></color-picker>

            </div>
          </div>

        </div>
        <button class="btn mt-3" @click.prevent="submitNewBrigade">Cоздать</button>
      </form>
    </div>
  </div>
</template>

<script>
import {ColorPicker} from "vue3-colorpicker";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'BrigadeCreateModal',
  components: {
    ColorPicker,
  },
  created() {
    this.fetchDepartmentList();
  },
  data() {
    return {
      brigadeName: '',
      bitrix_id: null,
      color: '',
      department_id: null
    }
  },
  computed: {
    ...mapGetters({
      departmentList: 'departmentList'
    })
  },
  methods: {
    ...mapActions({
      createBrigade: 'createBrigade',
      fetchBrigades: 'fetchBrigadesRelations',
      fetchDepartmentList: 'fetchDepartmentList'
    }),
    async submitNewBrigade() {
      const obj = {
        title: this.brigadeName,
        key: this.brigadeName.trim(),
        color: this.color,
        bitrix_id: this.bitrix_id,
        department_id: this.department_id,
      }
      await this.createBrigade(obj).then((res) => {
        this.$emit('close')
        this.$toast.success('Бригада успешно создана')
        this.fetchBrigades()
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    }
  },
}
</script>