<template>
  <div class="change-date flex column align-center">
    <div class="inner flex column align-center">
      <h1 class="text-center text-24 mb-2">Отображение участков</h1>
      <yandex-map
          :coordinates="[ defaultCenter.latitude, defaultCenter.longitude ]"
          :zoom="13"
          :controls="['rulerControl', 'zoomControl']"
          @created="mapInit"
          :width="200"
      >
        <div v-if="selectedPolygons?.length">
          <div v-for="(item, idx) in selectedPolygons"
               :key="'segmentIdx' + idx">
            <yandex-marker
                :marker-id="`polygon`"
                type="Polygon"
                :coordinates="[item.coordinates.map(([latitude, longitude]) => [longitude, latitude])]"
                :options="{
                    iconColor: item?.color,
                    preset: 'islands#circleIcon',
                    strokeColor: item?.color,
                    strokeWidth: 1,
                    strokeOpacity: 0.6,
                    fillColor: item?.color,
                    opacity: .8
                }"
            />
          </div>
        </div>
      </yandex-map>
    </div>
  </div>
</template>

<script>
import {YandexMap, YandexMarker, loadYmap} from "vue-yandex-maps";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'CurrentAreasMapComponent',
  components: {
    YandexMap,
    YandexMarker
  },
  props: {
    currentPolygons: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    defaultCenter: {
      latitude: '',
      longitude: '',
    },
    yandexMap: null,
    selectedPolygons: []
  }),
  computed: {
    ...mapGetters({
      polygons: 'polygons',
    }),
  },
  methods: {
    ...mapActions({
      checkPolygon: 'checkPolygon',
      getPolygons: 'getPolygons',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async mapInit(mapInstance) {
      if (mapInstance) {
        this.yandexMap = mapInstance
        const settings = {lang: "en_US"};
        await loadYmap(settings);
      }
    },
    initializeSelectedPolygons() {
      this.selectedPolygons = this.polygons.filter((polygon) =>
          this.currentPolygons.some(
              (current) => current.polygon_id === polygon.id
          )
      );
      this.defaultCenter.latitude = this.selectedPolygons[0].coordinates[0][1]
      this.defaultCenter.longitude = this.selectedPolygons[0].coordinates[0][0]
    },
  },
   mounted() {
     this.initializeSelectedPolygons();
   }
}
</script>

<style lang="scss" scoped>
.change-date {

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}
</style>
