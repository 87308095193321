<template>
  <div class="wrapper justify-center">
    <div class="form flex justify-center">
      <div class="form-inner">
        <h1 class="text-black2 text-center w-100 mb-2">
          {{ $route.query.edit === 'true' ? 'РЕДАКТИРОВАТЬ ЗАЯВКУ ЛКС' : 'НОВАЯ ЗАЯВКА ЛКС' }}</h1>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.client_full_name" ref="client_full_name"
                 placeholder="ФИО клиента"
                 type="text" disabled/>
          <label class="label-form">ФИО клиента</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.client_phone" ref="client_phone"
                 placeholder="Номер клиента"
                 type="text" disabled/>
          <label class="label-form">Номер клиента</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" disabled v-model="tempNodes" ref="tempNodes" placeholder="Узлы"
                 type="text"/>
          <label class="label-form">Узлы</label>
          <div class="flex mt-1 cursor-pointer" @click="setNodesListModal(true)">
            <p style="color: #0095FF; font-size: 16px">Список узлов</p>
            <img src="/assets/up_right.svg" alt="">
          </div>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.author_full_name" ref="author_full_name"
                 placeholder="ФИО постановщика"
                 type="text" disabled/>
          <label class="label-form">ФИО постановщика</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.author_phone" ref="author_phone"
                 placeholder="Номер постановщика"
                 type="text" disabled/>
          <label class="label-form">Номер постановщика</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.brigadier_full_name" ref="brigadier_full_name"
                 placeholder="ФИО бригадира"
                 type="text" disabled/>
          <label class="label-form">ФИО бригадира</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.cable_length" ref="cable_length"
                 placeholder="Количество метров кабеля" type="number"/>
          <label class="label-form">Количество метров кабеля</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.cable_type_id"
                  :class="{'empty': !newTicket.cable_type_id}" ref="cable_type_id">
            <option
                v-for="option in cableTypes"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип кабеля</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.suspension_count" ref="suspension_count"
                 placeholder="Количество подвесов" type="number"/>
          <label class="label-form">Количество подвесов</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" v-model="newTicket.suspension_type_id"
                  :class="{'empty': !newTicket.suspension_type_id}" ref="suspension_type_id">
            <option
                v-for="option in suspensionTypes"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип подвеса</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <textarea class="input-form textarea" v-model="newTicket.description" ref="description" placeholder="Описание"
                    type="text"/>
          <label class="label-form" for="body">Описание</label>
        </div>
        <button type="button" class="btn form-btn mb-4" @click="submitForm">
          {{ $route.query.edit === 'true' ? 'изменить' : 'создать' }}
        </button>
      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        <div v-if="$route.query.edit === 'true'">
          <p>ЗАЯВКА НА ЛКС <b>#{{ createdTicketId }}</b> УСПЕШНО ИЗМЕНЕНА</p>
        </div>
        <div v-else>
          <p>ЗАЯВКА НА ЛКС <b>#{{ createdTicketId }}</b> УСПЕШНО СОЗДАНА</p>
        </div>
      </success-modal>
    </custom-modal>
    <side-modal v-if="isNodesListModal" @close="setNodesListModal(false)">
      <nodes-list-modal @addNodes="addNodes" :commutators="newTicket.commutators"></nodes-list-modal>
    </side-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import router from "@/router";
import SideModal from "@/components/modules/SideModal.vue";
import NodesListModal from "@/components/modals/emergency/NodesListModal.vue";

export default {
  name: 'UpdateTicketLCSFormView',
  components: {
    NodesListModal, SideModal,
    SuccessModal,
    CustomModal,
  },
  data() {
    return {
      newTicket: {
        ticket_id: 0,
        type_id: null,
        client_full_name: '',
        client_phone: '',
        author_full_name: '',
        author_phone: '',
        brigadier_full_name: '',
        cable_length: 0,
        cable_type_id: null,
        suspension_count: 0,
        suspension_type_id: null,
        description: '',
        nodes: [],
        commutators: []
      },
      chosenNode: [],
      tempNodes: '',
      isSuccessModal: false,
      createdTicketId: '',
    }
  },
  created() {
    this.setLoading(true)
    let promises = [this.fetchCategories(5), this.fetchServiceTypes(5), this.fetchWorktime({ticket_kind_id: 5}), this.getTicketTypeProblems()]
    if (!this.nodes || !this.nodes.length) promises.push(this.fetchNodes())
    if (!this.cableTypes || !this.cableTypes.length) promises.push(this.fetchCableTypes())
    if (!this.suspensionTypes || !this.suspensionTypes.length) promises.push(this.fetchSuspensionTypes())
    Promise.all(promises).then(() => {
      this.fetchTypes({id: this.categories[0].id})
      this.setLoading(false)
    })
    if (this.$route.query.edit === 'true' && this.chosenTicket.ticket) {
      this.newTicket = {
        ticket_id: this.chosenTicket.ticket?.id,
        type_id: this.chosenTicket.ticket?.type?.id,
        client_full_name: this.chosenTicket.ticket?.client?.full_name,
        client_phone: this.chosenTicket.ticket?.client?.phone,
        author_full_name: this.chosenTicket.ticket?.author?.full_name,
        author_phone: this.chosenTicket.ticket?.author?.phone,
        brigadier_full_name: this.chosenTicket.ticket?.brigade_owner?.full_name,
        cable_length: this.chosenTicket.ticket?.cable_length || 0,
        cable_type_id: this.chosenTicket.ticket?.cable_type?.id,
        suspension_count: this.chosenTicket.ticket?.suspension_count || 0,
        suspension_type_id: this.chosenTicket.ticket?.suspension_type?.id,
        description: this.chosenTicket.ticket?.message,
        nodes: [],
      }
      if (this.chosenTicket.ticket?.nodes?.length) {
        this.chosenTicket.ticket.nodes.forEach((node, index) => {
          this.tempNodes += node.node_name
          if (index < this.chosenTicket.ticket?.nodes?.length - 1) {
            this.tempNodes += ', '
          }
        })
      }
      if (this.chosenTicket.ticket?.commutators?.length) {
        this.newTicket.commutators = this.chosenTicket.ticket?.commutators
        this.chosenTicket.ticket.commutators.forEach((commutator, index) => {
          if (commutator.port_count === commutator.ports.length) {
            this.tempNodes += commutator.commutator_name
          } else {
            this.tempNodes += `${commutator.commutator_name} [${[...commutator.ports]}]`
          }
          if (index < this.chosenTicket.ticket?.commutators?.length - 1) {
            this.tempNodes += `,`
          }
          commutator.name = commutator.commutator_name
          commutator.port_names = commutator.ports
        })
      }
    }

  },
  computed: {
    ...mapGetters({
      serviceTypes: 'serviceTypes',
      categories: 'categories',
      types: 'types',
      nodes: 'nodes',
      worktime: 'worktime',
      chosenTicket: "chosenTicket",
      isNodesListModal: 'isNodesListModal',
      cableTypes: 'cableTypes',
      suspensionTypes: 'suspensionTypes'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
      setNodesListModal: 'setNodesListModal'
    }),
    ...mapActions({
      fetchNodes: 'fetchNodes',
      fetchTypes: 'fetchTypes',
      fetchCategories: 'fetchCategories',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchNodeClients: 'fetchNodeClients',
      fetchWorktime: 'fetchWorktime',
      createTicketLCS: 'createTicketLCS',
      error: 'errorAlert',
      getTicketTypeProblems: 'getTicketTypeProblems',
      calculateClientsByNode: 'calculateClientsByNode',
      fetchCableTypes: 'fetchCableTypes',
      fetchSuspensionTypes: 'fetchSuspensionTypes',
    }),
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push(`/ticket-lcs/${this.chosenTicket.ticket?.ticket_number}`)
    },
    validateForm() {
      let isError = false
      for (let property in this.newTicket) {
        if (!this.newTicket[property] &&
            property !== 'ticket_id' &&
            property !== 'type_id' &&
            property !== 'client_full_name' &&
            property !== 'client_phone' &&
            property !== 'author_full_name' &&
            property !== 'author_phone' &&
            property !== 'brigadier_full_name' &&
            property !== 'cable_length' &&
            property !== 'cable_type_id' &&
            property !== 'suspension_count' &&
            property !== 'suspension_type_id' &&
            property !== 'description') {
          isError = true

          this.$refs[property].classList.add('error')
          setTimeout(() => {
            this.$refs[property].classList.remove('error')
          }, 3000)
        }
      }
      if (!this.tempNodes) {
        isError = true
        this.$refs['tempNodes'].classList.add('error')
        setTimeout(() => {
          this.$refs['tempNodes'].classList.remove('error')
        }, 3000)
      }
      return isError
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.setLoading(true)
        if (Object.keys(this.chosenNode).length === 0) {
          this.tempNodes.split(',').forEach(str => {
            this.newTicket.nodes.push({
              name: str
            })
          })
        }

        await this.createTicketLCS(this.newTicket).then(res => {
          if (res.success) {
            this.createdTicketId = res.data.ticket_number
            this.toggleSuccessModal()
          } else this.error(res.message)

          this.setLoading(false)
        })
      } else this.error('Заполните все поля')
    },
    async addNodes(nodes) {
      this.newTicket.commutators = [];
      this.chosenNode = []
      this.tempNodes = ''
      this.chosenNode.push(...nodes)


      nodes.forEach((el) => {
        if (el.iface_count === el.chosenPort.length) {
          this.tempNodes += el.device_hostname ? `${el.device_hostname}, ` : `${el.node_name}, `
        } else {
          this.tempNodes += el.device_hostname ? `${el.device_hostname} [${[...el.chosenPort]}], ` : `${el.node_name}, `
        }
      })


      this.chosenNode.forEach(el => {
        this.newTicket.commutators.push({
          id: el.device_id,
          name: el.device_hostname || el.device_name,
          port_names: [...el.chosenPort],
          port_count: el.iface_count
        })
      })
    }
  },
  unmounted() {
    this.newTicket = {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
