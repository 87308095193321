<template>
  <div class="flex column gap-2">
    <div ref="imageContainer" class="image-container">
      <img :src="image" alt="image" />
    </div>
    <button class="btn btn-primary" @click="openViewer">Открыть изображение</button>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: ['image'],
  data() {
    return {
      viewer: null,
    };
  },
  methods: {
    async openViewer() {
      const { default: Viewer } = await import("viewerjs");

      if (!this.viewer) {
        this.viewer = new Viewer(this.$refs.imageContainer, {
          toolbar: {
            zoomIn: true,
            zoomOut: true,
            rotateLeft: true,
            rotateRight: true,
            flipHorizontal: true,
            flipVertical: true,
          },
        });
      }
      this.viewer.show();
    },
    destroyViewer() {
      if (this.viewer) {
        this.viewer.destroy();
        this.viewer = null;
      }
    },
  },
  beforeUnmount() {
    this.destroyViewer();
  },
};
</script>

<style scoped>
@import 'viewerjs/dist/viewer.css';

.image-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container img {
  width: 150px;
  height: auto;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
