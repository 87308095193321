<template>
  <div class="p-20" v-if="departments.length > 0">
    <h2 class="text-24 mt-4">Cоздание подзадачи для <b>#{{details.ticket.ticket_number}}</b></h2>
    <div class="mt-4">
      <form action="" @submit.prevent="createSubTicketFunc">
        <div>
          <select class="input-form select" v-model="responsible_id">
            <option disabled value=""></option>
            <option
                v-for="option in departments"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
        </div>
        <div class="mt-2">
          <label for="" class="block text-20 label-form mb-1">Описание</label>
          <textarea  class="input-form textarea" v-model="message"/>
        </div>
        <button class="btn w-100 mt-4">создать</button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'TicketDetailsComponentModal',
  data() {
    return {
      responsible_id: 0,
      message: '',
    }
  },
  props: {
    departments: {
      type: Array,
      default: ()=> []
    },
  },
  computed: {
    ...mapGetters({
      details: "chosenTicket",
    })
  },
  methods: {
    ...mapActions({
      createSubTicket: 'createSubTicket',
      fetchTicketDetail: 'ticketDetail',
    }),
   async createSubTicketFunc() {
     const obj = {
       id: this.$route.params.id,
       message: this.message,
       department: this.responsible_id,
     }
     await this.createSubTicket(obj).then((res)=> {
       if (res.errors || !res.success){
         this.$toast.error(res.message)
       } else {
         this.$toast.success('Подзадача успешна создана')
         this.fetchTicketDetail(this.$route.params.id)
         this.$emit('close')
       }
     }).catch((err) => {
       this.$toast.error(`${err.message}`)
     });

    }
  },
}
</script>