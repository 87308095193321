import {api} from '@/service/axios'
export const notificationModule = {
  state: () => ({
    notificationList: [],
    notificationTotalPages: 1,
    userNotifications: [],
    unreadCount: 0
  }),
  getters: {
    notificationList(state) {
      return state.notificationList
    },
    notificationTotalPages(state) {
      return state.notificationTotalPages
    },
    userNotifications(state) {
      return state.userNotifications
    },
    unreadCount(state) {
      return state.unreadCount
    }
  },
  mutations: {
    setNotificationList(state, payload) {
      state.notificationList = payload
    },
    setNotificationTotalPages(state, payload) {
      state.notificationTotalPages = payload
    },
    setUserNotifications(state, payload) {
      state.userNotifications = payload
    },
    setUnreadCount(state, payload) {
      state.unreadCount = payload
    }
  },
  actions: {
    async fetchNotificationList ({commit}, payload) {
      const res = await api.get(`notifications/notification-list`, {
        params: payload
      })
      commit('setNotificationList', res.data.notifications)
      commit('setNotificationTotalPages', res.data.meta.last_page)
    },
    async createNotification ({dispatch}, payload) {
      return await api.post('notifications/notification-store', payload)
      // if(res.status) dispatch('successAlert', 'Уведомление успешно создана')
      // else dispatch('errorAlert', res.message)
      // commit('setTickets', [...state.tickets, res.data])
    },
    async fetchUserNotifications ({commit}) {
      const res = await api.get(`notifications/my-notifications`)
      commit('setUserNotifications', res.data.notifications)
      // commit('setNotificationList', res.data.notifications)
      // commit('setNotificationTotalPages', res.data.meta.last_page)
    },
    async fetchUnreadNotificationsCount({commit}) {
      const res = await api.get(`notifications/unread-notification-cnt`)
      commit('setUnreadCount', res.data.cnt)
    },
    async readNotification ({dispatch}, payload) {
      const res = await api.post('notifications/read-notification', {notification_id: payload})
      dispatch('fetchUserNotifications');
      dispatch('fetchUnreadNotificationsCount');
      // commit('setTickets', [...state.tickets, res.data])
    },
    async readAllNotification ({dispatch}, payload) {
      const res = await api.get('notifications/read-notification-all')
      dispatch('fetchUnreadNotificationsCount');
    },
  }
}
