import {api} from "@/service/axios";

export const polygonsModule = {
    state: ()=>({
        polygons: [],
        managerPolygon: null
    }),
    getters: {
        polygons: state => state.polygons,
        managerPolygon: state => state.managerPolygon,
    },
    mutations: {
        setPolygons (state, payload) {
            return state.polygons = payload
        },
        setManagerPolygon (state, payload) {
            return state.managerPolygon = payload
        }
    },
    actions: {
        async getPolygons ({commit}, payload) {
            const res = await api.get('/polygons/userside-polygons')
            commit('setPolygons', res.data)
        },
        async createManagerPolygon({commit}, payload) {
            const res = await api.post('/access-info-polygon/manager-polygon-create', payload)
            return res
        },
        async deleteManagerPolygon({commit,dispatch}, id, payload) {
            const res = await api.delete(`/access-info-polygon/manager-polygon/${id}`, payload)
            dispatch('getManagerPolygon')
            dispatch('getPolygons')
            return res
        },
        async getManagerPolygon ({commit}, payload) {
            const res = await api.get('/access-info-polygon/manager-polygon-get')
            commit('setManagerPolygon', res.data)
        },
    }
}