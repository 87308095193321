<template>
    <template v-if="clientEquipmentsList.length > 0">
      <div class="w-100 p-20 bg-gray rounded mb-2" v-for="(equip, index) in clientEquipmentsList" :key="index">
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Название:</p>
          <p class="text-14 text-black text-right">{{ equip.eq_name }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Серийный номер:</p>
          <p class="text-14 text-black text-right">{{ equip.eq_serial }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Стоимость:</p>
          <p class="text-14 text-black text-right">{{ equip.eq_price }}</p>
        </div>
      </div>
    </template>
    <div v-else class="w-100 empty-text text-center text-black uppercase">
      Нет оборудования
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'ClientEquipment',
  computed: {
    ...mapGetters({
      clientEquipmentsList: 'clientEquipmentList',
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.card {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

</style>
