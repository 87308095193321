<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">
      Координаты точек
    </div>
    <div class="inner flex column align-center">
      <yandex-map
          :coordinates="[ defaultCenter.latitude, defaultCenter.longitude ]"
          :zoom="17"
          :controls="['rulerControl', 'zoomControl', 'searchControl']"
          @map-was-initialized="mapInit"
      >
        <div v-if="coordinates">
          <yandex-marker
              v-for="(item, index) in coordinates"
              :key="'reserveId' + index"
              marker-type="placemark"
              :marker-id="index"
              :coordinates="item"
              :options="{iconColor: 'blue', preset: 'islands#circleIcon'}"
          />
        </div>
      </yandex-map>
    </div>
  </div>
</template>

<script>
import {YandexMap, YandexMarker} from 'vue-yandex-maps'
import {mapMutations} from "vuex";

export default {
  name: 'PointsInfoMap',
  components: {
    YandexMap,
    YandexMarker
  },
  props: {
    coordinates: {
      type: Object,
      default: () => ({
        latitude: '',
        longitude: '',
      }),
    },
  },
  data() {
    return {
      defaultCenter: {
        latitude: '',
        longitude: '',
      },
      yandexMap: undefined,
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async mapInit(map) {
      this.yandexMap = map;
    },
  },
  mounted() {
    this.defaultCenter.latitude = this.coordinates[0][0]
    this.defaultCenter.longitude = this.coordinates[0][1]
  }
}
</script>


<style lang="scss" scoped>
.change-date {
  width: 750px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}
</style>
