import {api} from "@/service/axios";
export const loadChartReportModule = {
    state: ()=> ({
        loadChartReports: []
    }),
    getters: {
        loadChartReports: state => {
            return state.loadChartReports;
        }
    },
    mutations: {
        setLoadChartReports(state, payload) {
            state.loadChartReports = payload;
        }
    },
    actions: {
        async fetchLoadChartReports({commit}, payload) {
            const res = await api.get('ticket-reports/load-schedule-service-ticket', {params: payload});
            commit('setLoadChartReports', res.data);
            return res
        }
    }
}