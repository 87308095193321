import { api } from "@/service/axios";

export const worktimeModule = {
  state: () => ({
    worktime: [],
  }),
  getters: {
    worktime(state) {
      return state.worktime;
    },
  },
  mutations: {
    setWorktime(state, payload) {
      state.worktime = payload;
    },
  },
  actions: {
    async fetchWorktime({ commit }, payload) {
      const res = await api.get("work-time/list", {
        params: payload
      });
      commit("setWorktime", res.data);
    },
    async editPriority(_commit, payload) {
      const res = await api.post("work-time/update", payload);
      return res.data;
    },
  },
};
