<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">
      Сменить дату подключения
    </div>
    <div class="inner">
      <date-picker class="datepicker mb-3" v-model="connection_date" :clearable="false" select-text="ПРИМЕНИТЬ" cancel-text="СБРОСИТЬ" :enable-time-picker="false" :format="formatDate" locale="ru-RU" placeholder="Выберите дату">
        <template #dp-input="{ value }">
          <div class="input-container mb-3">
            <input ref="connection_date" class="input datepicker" :class="{'filled': value}" readonly type="text" placeholder="Заголовок" :value="value" />
            <label for="connection_date">Дата подключения</label>
            <div class="input-btn">
              <img src="/assets/calendar-form.svg" alt="">
            </div>
          </div>
        </template>
        <template #action-row="{ closePicker, selectDate }">
          <div class="dp-action-row">
            <button class="clear-button" @click="() => {connection_date = null; closePicker();}">Сбросить</button>
            <button class="select-button" @click="selectDate">Применить</button>
          </div>
        </template>
      </date-picker>
      <div class="input-container mb-3">
        <textarea ref="reason" class="input textarea" type="text" placeholder="Причина" v-model="reason" />
        <label for="reason">Причина</label>
      </div>
      <button type="button" class="btn" @click="submitForm">Сменить</button>
    </div>
  </div>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker';
import {formatDate, formatDateReverse} from "@/helpers/utils";
import {mapActions} from "vuex";

export default {
  name: 'ChangeConnectionDateModal',
  components: {DatePicker},
  data() {
    return {
      connection_date: null,
      reason: '',
    }
  },
  methods: {
    ...mapActions({
      error: 'errorAlert'
    }),
    formatDate(date) {
      return formatDate(date, '.')
    },
    validate() {
      let isError = false
      if (!this.connection_date) {
        isError = true
        this.error('Дата подлючения обязательна для заполнения')
        this.$refs.connection_date.classList.add('error')
        setTimeout(() => {
          this.$refs.connection_date.classList.remove('error')
        }, 3000)
      }
      if (!this.reason) {
        isError = true
        this.error('Причина обязательна для заполнения')
        this.$refs.reason.classList.add('error')
        setTimeout(() => {
          this.$refs.reason.classList.remove('error')
        }, 3000)
      }
      if (this.reason.length > 100) {
        isError = true
        this.error('Маскимум 100 символов')
        this.$refs.reason.classList.add('error')
        setTimeout(() => {
          this.$refs.reason.classList.remove('error')
        }, 3000)
      }
      return isError
    },
    submitForm() {
      if (!this.validate()) {
        let payload = {
          planned_date: formatDateReverse(this.connection_date, '-'),
          reason: this.reason
        }
        this.$emit('changeDate', payload)
      }
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.change-date {
  width: 500px;

  .title {
    margin: 40px 0;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 400px;
  }
}

</style>
