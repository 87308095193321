<template>
  <div class="grid">
    <div class="grid-num flex justify-center align-center">{{ index + 1 }}</div>
    <div @click="openMap(polygon)" class="grid-name text-center flex justify-center align-center link cursor-pointer">
      {{ polygon.name || 'Не указано' }}
    </div>
    <div class="grid-color text-center flex justify-center align-center">
      <div
          :style="{background: polygon.color, height: '24px', width: '80px', margin: 'auto', borderRadius: '6px'}"
          class="flex justify-center"></div>
    </div>
    <div class="grid-manager flex gap-2" style="align-items: center">
      <AppSelect
          v-model="selectedManager"
          :placeholder="''"
          :options="managers"
          :clearable="false"
          @change="addPolygonToManager(polygon.id, selectedManager.value)"
          style="width: 300px"
      />
      <img v-if="selectedManager"
           src="/assets/delete.svg"
           alt="delete"
           width="24"
           height="24"
           title="Удаление полигона у менеджера"
           @click="deletePolygonToManager(polygon.id, selectedManager.value)" class="cursor-pointer"/>
    </div>
    <custom-modal v-if="isOpenMap" @close="toggleOpenModal">
      <polygons-map :polygons="polygonCoordinates" :key="mapRefreshKey"/>
    </custom-modal>
  </div>
</template>

<script>
import AppSelect from "@/components/ui/AppSelect.vue";
import {mapActions, mapMutations} from "vuex";
import PolygonsMap from "@/components/cards/polygons/PolygonsMap.vue";
import CustomModal from "@/components/modules/CustomModal.vue";

export default {
  name: "PolygonsTableDataComponent",
  components: {CustomModal, PolygonsMap, AppSelect},
  props: {
    polygon: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    managers: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      isOpenMap: false,
      polygonCoordinates: null,
      mapRefreshKey: 1,
      selectedManager: null,
      selectedRelationId: null
    }
  },

  mounted() {
    let currentManager = null
    currentManager = this.managers.find(manager =>
        manager?.polygons.some(p => p.polygon_id === this.polygon.id)
    )
    let currentRelationId = null
    const polygon = this.managers
        .flatMap(manager => manager?.polygons || [])
        .find(p => p.polygon_id === this.polygon.id);

    currentRelationId = polygon ? polygon.id : null;

    this.selectedManager = currentManager
    this.selectedRelationId = currentRelationId
  },
  methods: {
    ...mapActions({
      createManagerPolygon: 'createManagerPolygon',
      deleteManagerPolygon: 'deleteManagerPolygon',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    toggleOpenModal() {
      this.isOpenMap = !this.isOpenMap
      this.polygonCoordinates = {}
    },
    openMap(polygon) {
      ++this.mapRefreshKey
      this.polygonCoordinates = polygon
      this.isOpenMap = true
    },
    addPolygonToManager(polygonId, managerId) {
      this.setLoading(true)
        this.createManagerPolygon({
          manager_id: managerId,
          polygon_id: polygonId
        }).then((e) => {
          if(e.success) {
            this.$toast.success('Полигон успешно добавлен менеджеру')
          } else {
            this.$toast.error(e.message)
          }
        }).finally(() => {
          this.setLoading(false)
        })
    },
    deletePolygonToManager(polygonId, managerId) {
      this.setLoading(true)
      this.deleteManagerPolygon(this.selectedRelationId, {
        manager_id: managerId,
        polygon_id: polygonId,
      }).then((e) => {
        if(e.success) {
          this.$toast.success('Полигон успешно удален у менеджера')
        } else {
          this.$toast.error(e.message)
        }
      }).finally(() => {
        this.setLoading(false)
      })
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 6fr 3fr 4fr;
  grid-template-areas: 'Num Name Color Manager';
}

.title-text {
  color: #888888;
}

.grid-num {
  grid-area: Num;
  text-align: center;
}

.grid-name {
  grid-area: Name;
  text-align: center;
}

.grid-color {
  grid-area: Color;
  text-align: center;
}

.grid-manager {
  grid-area: Manager;
  text-align: center;
}

.grid-options {
  grid-area: Options;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.area-table {
}
</style>