<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100 mb-4">
      Выберите заявку
    </div>
    <select class="input-form select" v-model="ticket_id">
      <option disabled value=""></option>
      <option
          v-for="option in currentEmergencyTickets"
          :key="option.key"
          :value="option.id"
      >
        {{option.ticket_number}}: <p v-for="(node, index) in option.nodes" :key="node.id">{{node.node_name}} {{index < option.nodes.length - 1 ? ',' : ''}}</p>
      </option>
    </select>
    <button type="button" class="btn mb-2 mt-4" @click="relate">Связать</button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'RelateToEmergencyModal',
  props: ['ticketId'],
  emits: ['submit'],
  data() {
    return {
      ticket_id: 0
    }
  },
  mounted() {
    if (this.$props.ticketId) this.ticket_id = this.$props.ticketId
  },
  methods: {
    relate() {
      if (this.ticket_id) this.$emit('submit', this.ticket_id)
    }
  },
  computed: {
    ...mapGetters({
      currentEmergencyTickets: 'currentEmergencyTickets',
    })
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

</style>
