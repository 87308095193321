<template>
  <div class="flex column mt-4 top-managers">
    <h4 class="top-managers__title">ТОП 5 сотрудники по принятым заявкам</h4>
    <div class="top-managers__item flex justify-between align-end" v-for="(item, index) in topManagers" :key="item.id">
      <p class="bold pr-2">{{index + 1}}. {{item.full_name}}</p>
      <div class="top-managers__progress">
        <div class="flex justify-end w-100">
          <p class="bold text-right">{{item.ticket_cnt }}</p>
        </div>
        <k-progress class="progress-bar" :percent="item.ticket_cnt / topManagers[0].ticket_cnt * 100" :show-text="false" :color="getColour(index)" :line-height="8"></k-progress>
      </div>
    </div>
  </div>

</template>

<script>

import KProgress from 'k-progress-v3';
export default {
  name: 'TopManagers',
  props: ['topManagers'],
  components: {KProgress},
  data() {
    return {
    }
  },
  methods: {
    getColour(type) {
      switch(type) {
        case 0:
          return '#FF850B'

        case 1:
          return '#57C2DB'

        case 2:
          return '#1CA588'

        default:
          return '#727B82'
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.top-managers {
  width: 426px;
  border-radius: 29px;
  background: #FFF;
  box-shadow: 0px 32px 44px 0px rgba(0, 0, 0, 0.10);
  padding: 30px 40px;
  @media (min-width: 1440px) {
    width: 50%;
    gap: 16px;
  }

}

.top-managers__title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-bottom: 32px;
}

.top-managers__progress {
  width: 60%;
}

.top-managers__item {
  margin-bottom: 24px;

  p {
    width: 40%;
    color: #000;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}

</style>
