<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100 mb-4">
      Выберите департамент
    </div>

    <div class="dropdown w-100">
      <input
          type="text"
          class="input-form"
          v-model="search"
          @click="toggleDropdown"
          @input="showDropdown = true"
          placeholder="Выберите бригаду"
      />
      <div class="dropdown-content" :class="{ show: showDropdown }">
        <div
            v-for="option in filteredOptions"
            :key="option.id"
            @click="selectOption(option)"
        >
          {{ option.title }}
        </div>
      </div>
    </div>
    <button type="button" class="btn mb-2 mt-4" @click="setDepartment">Назначить</button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'SetDepartmentModal',
  data() {
    return {
      responsible_id: 0,
      search: '',
      showDropdown: false,
      filteredOptions: this.departments
    }
  },
  watch: {
    search(value) {
      this.filteredOptions = this.departments.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
    },
    departments(newOptions) {
      this.filteredOptions = newOptions;
    }
  },
  methods: {
    setDepartment() {
      if (this.responsible_id) this.$emit('submit', this.responsible_id)
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.responsible_id = option.id
      this.search = option.title;
      this.showDropdown = false;
    },
  },
  computed: {
    ...mapGetters({
      departments: 'departments',
    })
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
</style>
