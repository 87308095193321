<template>
  <div class="search-wrapper">
    <div v-if="label" class="search-label">{{ label }}</div>
    <div class="search-container">
      <input 
        type="search" 
        class="input-form w-100 mt-1"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :disabled="disabled"
      >
      <img 
        src="/assets/equipment-search-icon.svg" 
        alt="search" 
        class="search-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSearch',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Поиск по...'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped lang="scss">
.search-wrapper {
  width: 100%;
}

.search-label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.search-container {
  position: relative;
  width: 100%;

  .search-icon {
    position: absolute;
    left: 12px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    pointer-events: none;
  }

  .input-form {
    background: #F2F1F0 !important;
    border: none !important;
    border-radius: 12px !important;
    height: 44px !important;
    width: 100%;
    padding: 0 12px 0 36px;
    
    &::placeholder {
      color: #757575;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.w-100 {
  width: 100%;
}
</style> 