<template>
  <div class="wrapper modified flex column bg">
    <div>
      <button class="btn mb-2 w-30" @click="getReports">Сформировать отчет</button>
      <div v-if="reports.length > 0" class="bg-white w-100 p-20">
        <div v-for="report in reports" :key="report">
     <br>
          <h1 class="mb-1">{{report.title}}</h1>
          <pre>{{report.value}}</pre>

        </div>
      </div>
      <div v-else class="bg-white p-20 text-center w-30">
        <h1>Отчеты пусты</h1>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReportView",
  computed: {
    ...mapGetters({
      reports: 'reports'
    })
  },
  methods: {
    ...mapActions({
      fetchReports: 'fetchReports'
    }),
   async getReports() {
      await this.fetchReports()
    },
  },
}
</script>


<style scoped lang="scss">

</style>