<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="details.ticket">
      <div class="flex justify-between mb-4">
        <div class="flex align-center gap-2">
          <h2 class="text-black2">{{details.ticket?.ticket_kind?.title}} <b>#{{ details.ticket.ticket_number }}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="details.ticket.status_internal">
            <img
                :src="details.ticket.status_internal.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
                alt="">
            {{ details.ticket.status_internal.title }}
          </div>
          <label v-if="!details.ticket.author" class="text-red">ЗАЯВКА ИЗ ЛК</label>
        </div>
        <div>
          <img height="24" width="24" v-if="!isModal" class="block cursor-pointer" @click="$router.go(-1)" src="/assets/close.svg" alt=""/>
        </div>
      </div>

      <div class="flex flex-wrap justify-between">
        <div class="w-45 flex column mb-3" v-if="details.ticket.client">
          <p class="text-20 text-gray8 text-margin">Клиент</p>
          <p class="text-20 link cursor-pointer" @click="openClientCard(details.ticket.client.lanbilling_id)">
            {{ details.ticket.client.full_name }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.address">
          <p class="text-20 text-gray8 text-margin">Адрес</p>
          <p class="text-20 text-black">{{ details.ticket.address }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.addresses">
          <div v-if="details.ticket.addresses.length > 0">
            <p class="text-20 text-gray8 text-margin">Адреса</p>
            <div v-for="address in details.ticket.addresses" :key="address">
              <p class="text-20 text-black">{{ address.address }} <span style="color: #1fd345">{{address.has_access ? 'Есть в базе' : ''}}</span></p>
            </div>
            <p class="text-20 text-black">{{ details.ticket.address }}</p>
          </div>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.client">
          <p class="text-20 text-gray8 text-margin">ИИН</p>
          <p class="text-20 text-black">{{ details.ticket.client.iin }}</p>
        </div>
        <!--        <div class="w-45 flex column mb-3" v-if="details.logs.length">-->
        <!--          <p class="text-20 text-gray8 text-margin">Создана</p>-->
        <!--          <p class="text-20 text-black">{{ details.logs[details.logs.length - 1].created_at }}</p>-->
        <!--        </div>-->
        <div class="w-45 flex column mb-3" v-if="details.ticket.created_at">
          <p class="text-20 text-gray8 text-margin">Создана</p>
          <p class="text-20 text-black">{{ details.ticket.created_at }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.plan_end">
          <p class="text-20 text-gray8 text-margin">Крайний срок</p>
          <p class="text-20 text-black">{{ details.ticket.plan_end }}</p>
        </div>
        <div class="w-45 flex column mb-3"
             v-if="details.ticket.plan_end  && details.ticket.ticket_kind.id === 16 && details.ticket.status_internal.id === 17">
          <p class="text-20 text-gray8 text-margin">Ориентировочное время выполнения заявки</p>
          <div class="text-20 text-black" v-if="timeRemaining.hours >= 0">
            <span>{{ timeRemaining.hours }}ч : </span>
            <span>{{ timeRemaining.minutes }}м : </span>
            <span>{{ timeRemaining.seconds }}с</span>
          </div>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.author">
          <p class="text-20 text-gray8 text-margin">Инициатор</p>
          <p class="text-20 text-black link cursor-pointer" @click="openManagerCard(details.ticket.author.id)">
            {{ details.ticket.author.full_name }} <span
              v-if="details.ticket.author.department && details.ticket.ticket_kind.id === 16">({{
              details.ticket.author.department.title
            }})</span></p>

        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.reassignment_count">
          <p class="text-20 text-gray8 text-margin">Переназначении</p>
          <p class="text-20 text-black">{{ details.ticket.reassignment_count }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.responsible">
          <p class="text-20 text-gray8 text-margin">Исполнитель</p>
          <p class="text-20 text-black" v-if="details.ticket.responsible.type === 'department' || details.ticket.responsible.type === 'brigade'">
            {{ details.ticket.responsible.title }}</p>
          <p class="text-20 text-black link cursor-pointer" v-else
             @click="openManagerCard(details.ticket.responsible.id)">{{ details.ticket.responsible.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.contact_number">
          <p class="text-20 text-gray8 text-margin">Контактный номер</p>
          <p class="text-20 text-black">{{ maskPhone(details.ticket.contact_number) }}</p>
          <p class="link cursor-pointer mt-1" style="color: green"
             @click.prevent="openWhatsApp(details.ticket.contact_number)">Написать в WhatsApp</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.contact_info">
          <p class="text-20 text-gray8 text-margin">ФИО контакта</p>
          <p class="text-20 text-black">{{ details.ticket.contact_info }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.additional_contact_number">
          <p class="text-20 text-gray8 text-margin">Доп. контактный номер</p>
          <p class="text-20 text-black">{{ maskPhone(details.ticket.additional_contact_number) }}</p>
          <p class="link cursor-pointer mt-1" style="color: green"
             @click.prevent="openWhatsApp(details.ticket.additional_contact_number)">Написать в WhatsApp</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.additional_contact_fio">
          <p class="text-20 text-gray8 text-margin">Доп. ФИО контакта</p>
          <p class="text-20 text-black">{{ details.ticket.additional_contact_fio }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.author">
          <p class="text-20 text-gray8 text-margin">ФИО постановщика</p>
          <p class="text-20 text-black link cursor-pointer" @click="openManagerCard(details.ticket?.author?.id)">
            <span>{{ details.ticket?.author?.full_name || ' - ' }} {{ details.ticket?.author?.department.title ? `(${details.ticket?.author?.department.title})` : '' }}</span>
          </p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.author?.phone">
          <p class="text-20 text-gray8 text-margin">Номер постановщика</p>
          <p class="text-20 text-black">{{details.ticket?.author?.phone ? maskPhone(details.ticket?.author?.phone) : ' - '}}</p>
          <p class="link cursor-pointer mt-1" style="color: green"
             @click.prevent="openWhatsApp(details.ticket?.author?.phone)">Написать в WhatsApp</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.lanbilling_vg_id">
          <p class="text-20 text-gray8 text-margin">№ Договора</p>
          <p class="text-20 text-black">{{ details.ticket.lanbilling_vg_id }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.category">
          <p class="text-20 text-gray8 text-margin">Категория</p>
          <p class="text-20 text-black">{{ details.ticket.category.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.type">
          <p class="text-20 text-gray8 text-margin">Подкатегория</p>
          <p class="text-20 text-black">{{ details.ticket.type.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.service_type">
          <p class="text-20 text-gray8 text-margin">Тип</p>
          <p class="text-20 text-black">{{ details.ticket.service_type.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.emergency_ticket_problem_type">
          <p class="text-20 text-gray8 text-margin">Тип проблемы</p>
          <p class="text-20 text-black">{{ details.ticket.emergency_ticket_problem_type.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.abonent_cnt">
          <p class="text-20 text-gray8 text-margin">Кол-во абонентов</p>
          <p class="text-20 text-black">{{ details.ticket.abonent_cnt }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.emergency_started_at">
          <p class="text-20 text-gray8 text-margin">Время начала аварии</p>
          <p class="text-20 text-black">{{ details.ticket.emergency_started_at }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.city">
          <p class="text-20 text-gray8 text-margin">Город</p>
          <p class="text-20 text-black">
            {{ details.ticket.city.name ? details.ticket.city.name : details.ticket.city.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.life_time">
          <p class="text-20 text-gray8 text-margin">Время жизни</p>
          <p class="text-20 text-black">{{ details.ticket.life_time }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.language">
          <p class="text-20 text-gray8 text-margin">Язык</p>
          <p class="text-20 text-black">{{ details.ticket.language === 'ru' ? 'русский' : 'Казахский' }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.priority">
          <p class="text-20 text-gray8 text-margin">Приоритет</p>
          <p class="text-20 text-black">{{ details.ticket.priority?.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.planned_at_begin">
          <p class="text-20 text-gray8 text-margin">Дата начала</p>
          <p class="text-20 text-black">{{ details.ticket?.planned_at_begin }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.planned_at_end">
          <p class="text-20 text-gray8 text-margin">Дата окончания</p>
          <p class="text-20 text-black">{{ details.ticket?.planned_at_end }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.district_id && districts.length">
          <p class="text-20 text-gray8 text-margin">Район</p>
          <p class="text-20 text-black">{{ getTitle(districts, details.ticket.district_id) }}</p>
        </div>
        <div class="w-45 flex column mb-3"
             v-if="details.ticket.client && details.ticket.client?.company_id && companies.length">
          <p class="text-20 text-gray8 text-margin">Компания</p>
          <p class="text-20 text-black">{{ getCompanyName(details.ticket.client.company_id) }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.status_external">
          <p class="text-20 text-gray8 text-margin">Внешний статус</p>
          <p class="text-20 text-black">{{ details.ticket.status_external.title }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.message_for_brigade">
          <p class="text-20 text-gray8 text-margin">Сообщение аварийной бригаде</p>
          <p class="text-20 text-black">{{ details.ticket.message_for_brigade }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.planned_recovered_at">
          <p class="text-20 text-gray8 text-margin">Плановое время восстановления</p>
          <p class="text-20 text-black">{{ details.ticket.planned_recovered_at }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.recovered_at">
          <p class="text-20 text-gray8 text-margin">Время восстановления</p>
          <p class="text-20 text-black">{{ details.ticket.recovered_at }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.executor">
          <p class="text-20 text-gray8 text-margin">Ответственный</p>
          <p class="text-20 text-black">{{ details.ticket.executor }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.count_fiz_l">
          <p class="text-20 text-gray8 text-margin">Кол-во абонентов ФЛ</p>
          <p class="text-20 text-black">{{ details.ticket.count_fiz_l }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.count_jur_l">
          <p class="text-20 text-gray8 text-margin">Кол-во абонентов ЮЛ</p>
          <p class="text-20 text-black">{{ details.ticket.count_jur_l }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.message">
          <p class="text-20 text-gray8 text-margin">Описание</p>
          <p v-html="cleanHtml(makeClickable(details.ticket.message))" class="text-20 text-black"></p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.work_goal">
          <p class="text-20 text-gray8 text-margin">Цель работы</p>
          <p class="text-20 text-black">{{ details.ticket.work_goal }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.work_plan">
          <p class="text-20 text-gray8 text-margin">План работы</p>
          <p class="text-20 text-black">{{ details.ticket.work_plan }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.external_type">
          <p class="text-20 text-gray8 text-margin">Подключение от узла</p>
          <p class="text-20 text-black">{{ details.ticket.external_type }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.desired_date">
          <p class="text-20 text-gray8 text-margin">Желаемая дата выезда</p>
          <p class="text-20 text-black">{{ details.ticket.desired_date }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.emergency_ticket_detail?.ticket?.ticket_number">
          <p class="text-20 text-gray8 text-margin">Относится к аварии</p>
          <p class="link cursor-pointer mt-1 text-20"
             @click.prevent="openEmergencyTicket(details.ticket.emergency_ticket_detail.ticket)">
            №{{ details.ticket.emergency_ticket_detail.ticket.ticket_number }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.nodes.length > 0 && !details.ticket?.commutators.length">
          <p class="text-20 text-gray8 text-margin">Затронутые узлы</p>
          <p class="text-20 text-black mb-1" v-for="node in details.ticket.nodes" :key="node.id">
            {{ node.node_name }}</p>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.commutators.length > 0">
          <p class="text-20 text-gray8 text-margin">Затронутые узлы</p>
          <div class="text-20 text-black mb-1" v-for="commutator in details.ticket.commutators" :key="commutator.id">
            <p>{{ commutator.commutator_name }} <span
                class="text-gray5">{{ commutator.ports.length === commutator.port_count ? '' : commutator.ports }}</span>
            </p>
          </div>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.parent_tickets.length > 0 && details.ticket?.ticket_kind.id === 16">
          <p class="text-20 text-gray8 text-margin">Связанные заявки</p>
          <div v-for="related in details.ticket.parent_tickets" :key="related.id">
            <p class="text-20 text-black "  @click="onParentTickets(related)">
              <span v-if="related.ticket_kind_id === 2">Сервисная</span>
              <span v-if="related.ticket_kind_id === 5">Аварийная</span>
              <span v-if="related.ticket_kind_id === 16">Доступ</span>
              <span v-if="related.ticket_kind_id === 14">Подзадача</span>
              <span class="text-20 link cursor-pointer">#{{related.ticket_number}}</span></p>
          </div>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket?.related_tickets.length > 0">
          <p class="text-20 text-gray8 text-margin">Дочерние заявки</p>
          <div v-for="related in details.ticket.related_tickets" :key="related.id">
            <p class="text-20 text-black "   @click="onRelatedTickets(related)">
              <span v-if="related.ticket_kind_id === 2">Сервисная</span>
              <span v-if="related.ticket_kind_id === 5">Аварийная</span>
              <span v-if="related.ticket_kind_id === 16">Доступ</span>
              <span v-if="related.ticket_kind_id === 14">Подзадача</span>
              <span class="text-20 link cursor-pointer"> #{{related.ticket_number}}</span></p>
          </div>
        </div>
        <div class="w-45 flex column mb-3" v-if="details.ticket.ticket_result">
          <p class="text-20 text-gray8 text-margin">Итог</p>
          <p class="text-20 text-black mb-1">{{ details.ticket.ticket_result.title }}</p>
        </div>
        <template v-if="details.details.length">
          <template v-for="detail in details.details" :key="detail.key">
            <div class="w-45 flex column mb-3" v-if="detail.key === 'recent_calls'">
              <p class="text-20 text-gray8 text-margin">{{ detail.title }}</p>
              <p class="text-20 text-black" style="word-break: break-word;" v-html="detail.value"></p>
            </div>
            <div class="w-45 flex column mb-3" v-else-if="detail.key === 'agreement_status'">
              <p class="text-20 text-gray8 text-margin">{{ detail.title }}</p>
              <p :class="details.ticket.client.is_active ? 'text-20 text-black' : 'text-20 text-red'"
                 v-html="detail.value.replaceAll('\n', '<br>')"></p>
            </div>
            <div class="w-45 flex column mb-3" v-else-if="detail.key === 'ip_address'">
              <p class="text-20 text-gray8 text-margin">{{ detail.title }}</p>
              <p class="text-20 text-black" v-html="detail.value.replace(/,/g, '<br>')"></p>
            </div>
            <div class="w-45 flex column mb-3" v-if="detail.key === 'checker_info'">
              <p class="text-20 text-gray8 text-margin">{{ detail.title }}</p>
              <p class="text-20 text-black" v-html="detail.value.replaceAll('\n', '<br>')"></p>
            </div>
            <div class="w-45 flex column mb-3" v-else>
              <p class="text-20 text-gray8 text-margin">{{ detail.title }}</p>
              <p class="text-20 text-black" v-html="detail.value"></p>
            </div>
          </template>
        </template>
        <div class="w-45 flex column mb-3" v-if="details.files.length">
          <p class="text-20 text-gray8 text-margin">Вложения</p>
          <div class="flex gap-2 flex-wrap">
            <div class="images" v-for="file in details.files" :key="file">
              <template
                  v-if="file.file_name.match(/\.(jpg|jpeg|png|gif|jfif)$/i) || file.file_url.match(/\.(jpg|jpeg|png|gif|jfif)$/i)">
                <img :src="file.file_url" @click="toggleImageModal(file.file_url)" alt="Image"/>
              </template>
              <template
                  v-else-if="file.file_name.match(/\.(mp4|avi|mov|webm)$/i) || file.file_url.match(/\.(mp4|avi|mov|webm)$/i)">
                <video class="attached-files__video" controls :src="file.file_url" width="200" height="200"></video>
              </template>
              <template
                  v-else-if="file.file_name.match(/\.(xlsx|xls|doc|docx|pdf)$/i) || file.file_url.match(/\.(xlsx|xls|doc|docx|pdf)$/i)">
                <div class="cursor-pointer" @click="downloadFile(file)">
                  <div class="mr-2 mt-1 link">{{ file.file_name }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <template v-if="details.ticket_clients.length">
          <div class="w-100 flex column mb-3">
            <p class="text-20 text-gray8 text-margin">Клиентов: {{ details.ticket_clients.length }}</p>
          </div>
          <div class="w-30 flex column mb-2" v-for="client in details.ticket_clients" :key="client.id">
            <p class="flex align-center">
              <span
                  class="text-20 link cursor-pointer" @click="openClientCard(client.lanbilling_id)">{{
                  client.full_name
                }} </span><span
                class="text-20 ml-1 mr-1">
                  {{ client.phone }}
              </span>
              <img v-if="client.is_called" class="cursor-pointer" @click="callClientChange(client.id)"
                   src="/assets/phone-call-svgrepo-com.svg" height="26" width="26" alt="">
              <img v-if="!client.is_called" class="cursor-pointer" @click="callClientChange(client.id)"
                   src="/assets/phone-call-svgrepo-com-gray.svg" height="26" width="26" alt="">
              <span class="client-emergency__remove" title="Удалить клиента из Аварии"
                    @click="showRemoveClientModal(client)">&#10006;</span>
            </p>

          </div>
        </template>

        <div class="w-100 flex column client-accident" v-if="details.ticket.ticket_kind.id === 5">
          <p class="client-accident__label">Добавить клиента к аварии</p>
          <ClientSearchBar
              :result="clients"
              v-model="searchClient"
              class="client-accident__search-bar"
              @select="selectAccidentClient"
              @input="onClientSearchInput"
          />
          <button
              type="button"
              class="btn client-accident__btn"
              :disabled="!selectedClient"
              @click="relateTicketAndClient"
          >
            Добавить клиента
          </button>
        </div>
        <div class="w-100 flex column align-end">
          <div class="input-container w-100 mb-2" v-if="details.ticket.ticket_kind.id === 5">
            <textarea class="input-form textarea" v-model="decision" ref="decision" placeholder="Решение"
                      type="text"/>
            <label class="label-form" for="body">Решение</label>
          </div>

          <div class="flex gap-2" v-if="details.ticket.ticket_kind.id === 5">
            <button type="button" class="btn" @click="openSubTicketModal">
              создать подзадачу
            </button>
            <button type="button" class="btn w-200" @click="saveDecision">сохранить</button>
          </div>
        </div>
      </div>

      <div v-if="details.ticket.ticket_kind.id === 5">
        <div class="mt-2 mb-2 text-20 text-black" v-if="details.ticket.emergency_child_tickets">
          <h3>Cозданные подзадачи</h3>
          <div v-for="el in details.ticket.emergency_child_tickets" :key="el.id">
            <div style="border: 1px solid black; padding: 12px" class="mt-2 mb-2  rounded">
              <p class="text-gray">ID: <span class="text-black">{{ el.id }}</span></p>
              <p class="text-gray"> Сообщение: <span class="text-black">{{ el.message }}</span></p>
              <p class="text-gray">Дата создания <span
                  class="text-black">{{ new Date(el.created_at).toLocaleString('ru-Ru', options) }}</span></p>
            </div>
          </div>
        </div>

      </div>


      <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" :is-modified="true" @tab-click="changeTab"/>

      <div>
        <comments-tab
            v-if="activeTab === 'comment'"
            :comments="ticketComments"
            :totalComments="totalComments"
            @addComment="addComment"
            @loadComments="loadComments"
            @openImage="toggleImageModal"
            @newComment="handleNewComment"
        />
        <history-tab
            v-if="activeTab === 'history'"
            :history="details.logs"
        />
        <custom-table
            v-if="activeTab === 'node_clients'"
            :columns="tableColumns"
            :data="details.node_clients.clients"
            @get-detail="selectClient"
        />
      </div>
    </div>
    <div class="actions flex flex-wrap" v-if="details.ticket">
      <router-link v-if="details.ticket.ticket_kind.id === 5"
                   class="actions-buttons actions-buttons_info mr-2 text-decoration-none"
                   to="/emergency-ticket-form?edit=true">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Редактировать
      </router-link>
      <router-link
          v-if="details.ticket.ticket_kind.id === 2 && user.department_id === details.ticket.author?.department_id"
          class="actions-buttons actions-buttons_info mr-2 text-decoration-none"
          to="/service-ticket-form?edit=true">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Редактировать
      </router-link>
      <router-link v-if="details.ticket.ticket_kind.id === 8"
                   class="actions-buttons actions-buttons_info mr-2 text-decoration-none"
                   to="/plan-ticket-form?edit=true">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Редактировать
      </router-link>
      <router-link v-if="details.ticket.ticket_kind.id === 16"
                   class="actions-buttons actions-buttons_info mr-2 text-decoration-none"
                   to="/access-form-view?edit=true">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Редактировать
      </router-link>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.status_internal.id !== 26"
              @click="toggleDepartmentModal()">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Переназначить
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.status_internal.id !== 26"
              @click="isBrigadeAssignModal = !isBrigadeAssignModal"><span
          v-if="user.department_id === 108">На выезд</span><span v-else>Назначить на бригаду</span>
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info"
              v-if="details.ticket.ticket_kind.id === 2 || details.ticket.ticket_kind.id === 16"
              @click="isEmployeAssignModal = !isEmployeAssignModal">Назначить на сотрудника
      </button>
      <button class="actions-buttons mr-2 actions-buttons_emergency"
              v-if="details.ticket.ticket_kind.id === 2 && !details.ticket.emergency_ticket_id"
              @click="toggleRelateModal()">
        <img src="/assets/statuses/emergency.svg" class="mr-1" alt="emergency"/>
        Связать с аварией
      </button>
      <button class="actions-buttons mr-2 actions-buttons_emergency" v-if="details.ticket.ticket_kind.id === 5 || details.ticket.ticket_kind.id === 8"
              @click="isSendNotificationModal = !isSendNotificationModal">Отправить оповещение
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.ticket_kind.id === 16"
              @click="increaseEndDate(details.ticket.id)">Продлить
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.ticket_kind.id === 16"
              @click="isCreateSubtaskModal = !isCreateSubtaskModal">Создать подзадачу
      </button>

      <div v-for="item in details.buttons" :key="item.id">
        <button v-if="item.key !== 'problem' && item.title" class="actions-buttons mr-2" :class="'actions-buttons_'+item.key"
                @click="changeStatus(item)">
          <img
              :src="`/assets/statuses/${item.key === 'waiting' || item.key === 'complete' || item.key === 'emergency' || item.key === 'departure' ? item.key : 'info'}.svg`"
              class="mr-1" :alt="item.key"/>
          {{ item.title }}
        </button>
      </div>
      <button class="actions-buttons mr-2 actions-buttons_complete" v-if="details.ticket.ticket_kind.id === 2"
              @click="isOpenSendBitrixModal = true">Отправить в Битрикс
      </button>
      <router-link v-if="!details.ticket.author" class="actions-buttons actions-buttons_info mr-2 text-decoration-none"
                   to="/service-ticket-form?edit=true">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Создать заявку
      </router-link>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.ticket_kind.id !== 16"
              @click="isAccessFormModal = true">Создать связанную заявку на доступ
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.ticket_kind.id !== 17"
              @click="isLCSFormModal = true">Создать связанную заявку на ЛКС
      </button>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
    <custom-modal v-if="isSetDepartmentModal" @close="toggleDepartmentModal">
      <set-department-modal @submit="onChangeDepartment"/>
    </custom-modal>
    <custom-modal v-if="isTicketResultModal" @close="toggleTicketResultModal">
      <ticket-result-modal @submit="ticketResult"/>
    </custom-modal>
    <custom-modal v-if="isRelateModal" @close="toggleRelateModal">
      <relate-to-emergency-modal @submit="relateToEmergency"/>
    </custom-modal>
    <custom-modal v-if="isManagerInfoModal" @close="isManagerInfoModal = !isManagerInfoModal">
      <manager-info-modal></manager-info-modal>
    </custom-modal>
    <custom-modal v-if="isCommentModal" @close="isCommentModal = !isCommentModal">
      <ticket-add-comment-modal @addComment="addCommentAsResult"/>
    </custom-modal>
    <custom-modal v-if="isSubTicketModal" @close="isSubTicketModal = !isSubTicketModal">
      <ticket-details-component-modal :departments="departments"
                                      @close="isSubTicketModal = !isSubTicketModal"></ticket-details-component-modal>
    </custom-modal>
    <custom-modal v-if="isBrigadeAssignModal" @close="isBrigadeAssignModal = !isBrigadeAssignModal">
      <BrigadeAssignModal @submit="assignBrigade"></BrigadeAssignModal>
    </custom-modal>
    <custom-modal v-if="isEmployeAssignModal" @close="isEmployeAssignModal = !isEmployeAssignModal">
      <EmployeAssignModal @submit="assignEmploye"></EmployeAssignModal>
    </custom-modal>
    <custom-modal v-if="isSendNotificationModal" @close="isSendNotificationModal = !isSendNotificationModal">
      <send-notification-modal @sendMessage="sendMessage"></send-notification-modal>
    </custom-modal>
    <custom-modal v-if="removeClientModal" @close="removeClientModal = !removeClientModal">
      <emergency-remove-user-modal :client="removeClient" @remove="removeUserEmergency"></emergency-remove-user-modal>
    </custom-modal>
    <custom-modal v-if="isAccessFormModal" @close="isAccessFormModal = !isAccessFormModal">
      <access-form-view :parent-ticket-id="+details.ticket.id" :ticket="details.ticket"></access-form-view>
    </custom-modal>
    <custom-modal v-if="isLCSFormModal" @close="isLCSFormModal = !isLCSFormModal">
      <CreateTicketLCSFormView :parent-ticket-id="+details.ticket.id" :ticket="details.ticket"></CreateTicketLCSFormView>
    </custom-modal>
    <custom-modal v-if="isCreateSubtaskModal" @close="isCreateSubtaskModal = !isCreateSubtaskModal">
      <create-subtask-modal @close="isCreateSubtaskModal = !isCreateSubtaskModal"></create-subtask-modal>
    </custom-modal>
    <custom-modal v-if="isOpenSendBitrixModal" @close="toggleSendBitrixModal">
      <send-to-bitrix-modal @close="isOpenSendBitrixModal = !isOpenSendBitrixModal" :ticket-id="details.ticket.id"/>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ClientsTableColumns} from "@/modules/table-columns";
import TicketResultModal from "@/components/modals/tickets/TicketResultModal.vue";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import HistoryTab from "@/components/tabs/HistoryTab.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import CustomTable from "@/components/modules/CustomTable.vue";
import {findById, maskPhone} from "@/helpers/utils"
import SetDepartmentModal from "@/components/modals/SetDepartmentModal.vue";
import RelateToEmergencyModal from "@/components/modals/tickets/RelateToEmergencyModal.vue";
import ClientSearchBar from "@/components/navigation/ClientSearchBar.vue";
import router from "@/router";
import {api} from "@/service/axios";
import ManagerInfoModal from "@/components/modals/ManagerInfoModal.vue";
import TicketDetailsComponentModal from "@/components/modals/tickets/TicketDetailsCreateSubticketModal.vue";
import BrigadeAssignModal from "@/components/modals/brigade/BrigadeAssignModal.vue";
import EmployeAssignModal from "@/components/modals/EmployeAssignModal.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import TicketAddCommentModal from "@/components/modals/tickets/TicketAddCommentModal.vue";
import SendNotificationModal from "@/components/modals/SendNotificationModal.vue";
import EmergencyRemoveUserModal from '@/components/modals/emergency/EmergencyRemoveUserModal.vue';
import AccessFormView from "@/views/accessData/AccessFormView.vue";
import CreateSubtaskModal from "@/components/modals/subtasks/CreateSubtaskModal.vue";
import CreateTicketLCSFormView from "@/views/ticketsLCS/CreateTicketLCSFormView.vue";
import SendToBitrixModal from "@/components/modals/SendToBitrixModal.vue";

export default {
  name: 'TicketDetailsComponent',
  components: {
    CreateSubtaskModal,
    AccessFormView,
    SendNotificationModal,
    BrigadeAssignModal,
    TicketDetailsComponentModal,
    ManagerInfoModal,
    TabsComponent,
    HistoryTab,
    CommentsTab,
    CustomModal,
    TicketResultModal,
    CustomTable,
    SetDepartmentModal,
    RelateToEmergencyModal,
    ClientSearchBar,
    ImageModal,
    TicketAddCommentModal,
    EmergencyRemoveUserModal,
    EmployeAssignModal,
    CreateTicketLCSFormView,
    SendToBitrixModal,
  },
  props: ['isModal'],
  data() {
    return {
      activeTab: 'comment',
      image: '',
      isTicketResultModal: false,
      isSetDepartmentModal: false,
      isRelateModal: false,
      decision: '',
      isSubTicketModal: false,
      options: {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'},
      searchClient: '',
      clients: [],
      selectedClient: null,
      isManagerInfoModal: false,
      isBrigadeAssignModal: false,
      isEmployeAssignModal: false,
      isCommentModal: false,
      selectedStatus: null,
      isSendNotificationModal: false,
      removeClientModal: false,
      removeClient: {},
      isAccessFormModal: false,
      isLCSFormModal: false,
      timeRemaining: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      timer: null,
      isCreateSubtaskModal: false,
      socketChannel: null,
      isOpenSendBitrixModal: false
    }
  },
  created() {
    this.setLoading(true)
    let promises = []
    if (!this.districts || !this.districts.length) promises.push(this.fetchDistricts())
    if (!this.companies || !this.companies.length) promises.push(this.fetchCompanies())
    this.updateCountdown();
    this.timer = setInterval(this.updateCountdown, 1000);
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
    if (this.details.ticket && this.details.ticket.ticket_kind.id === 5) this.decision = this.details.ticket.decision
  },
  // mounted() {
  //   if (this.details.ticket && this.details.ticket.ticket_kind.id === 5) {
  //     this.$refs.decision.addEventListener('keypress', (event) => {
  //       if (event.key === "Enter" && this.decision !== this.details.ticket.decision) {
  //         this.saveDecision()
  //       }
  //     })
  //
  //   }
  // },
  computed: {
    ...mapGetters({
      details: "chosenTicket",
      currentCommentPage: 'currentCommentPage',
      ticketComments: 'ticketComments',
      totalComments: 'totalComments',
      districts: 'districts',
      companies: 'companies',
      departments: "departments",
      user: 'user',
    }),
    tableColumns() {
      return ClientsTableColumns
    },
    tabs() {
      if (this.details.node_clients) {
        return [
          {
            title: 'Клиенты',
            icon: '/assets/tabs/agreements-gray.svg',
            icon_active: '/assets/tabs/agreements-white.svg',
            key: 'node_clients'
          },
          {
            title: 'Комментарии',
            icon: '/assets/tabs/comment-gray.svg',
            icon_active: '/assets/tabs/comment-white.svg',
            key: 'comment'
          },
          {
            title: 'ИСТОРИЯ',
            icon: '/assets/tabs/clock-gray.svg',
            icon_active: '/assets/tabs/clock-white.svg',
            key: 'history'
          },
        ]
      } else return [
        {
          title: 'Комментарии',
          icon: '/assets/tabs/comment-gray.svg',
          icon_active: '/assets/tabs/comment-white.svg',
          key: 'comment'
        },
        {
          title: 'ИСТОРИЯ',
          icon: '/assets/tabs/clock-gray.svg',
          icon_active: '/assets/tabs/clock-white.svg',
          key: 'history'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      changeTicketStatus: 'changeTicketStatus',
      fetchTicketResults: 'fetchTicketResults',
      fetchTicketDetail: 'ticketDetail',
      addTicketResult: 'addTicketResult',
      fetchTicketComments: 'fetchTicketComments',
      addTicketComments: 'addTicketComments',
      fetchClientData: 'fetchClientData',
      fetchManagerPhones: 'fetchManagerPhoneNumbers',
      fetchDistricts: 'fetchDistricts',
      fetchCompanies: 'fetchCompanies',
      fetchDepartments: 'fetchDepartments',
      setDepartment: 'setDepartment',
      error: 'errorAlert',
      success: 'successAlert',
      fetchCurrentEmergencyTicketList: 'fetchCurrentEmergencyTicketList',
      relateToEmergencyTicket: 'relateToEmergencyTicket',
      updateDecision: 'updateDecision',
      successAlert: 'successAlert',
      errorAlert: 'errorAlert',
      fetchWazzup: 'fetchWazzupIframeUrl',
      createUser: 'createWazzupUser',
      sendNotificationMessage: 'sendNotificationMessage',
      changeCallClient: 'changeCallClient',
      sendToBitrix: 'sendToBitrix',
      removeUserInEmergencyTask: 'removeUserInEmergencyTask',
      increaseAccessDataTicketEndDate: 'increaseAccessDataTicketEndDate',

    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
      setIsClientCard: 'setIsClientCard',
      setTicketClientsIsCalled: 'setTicketClientsIsCalled',
      setClearDeletedUser: 'setClearDeletedUser',
      addNewComment: 'addTicketComment',
    }),
    async fetchClients() {
      if (this.searchClient.length > 3) {
        this.setLoading(true);
        const res = await api.get(`clients/client-search?search=${this.searchClient}`)
        if (res.success) {
          const arr = res.data.filter(client =>
              client.is_active > 0
          )
          this.clients = arr
        }
        this.setLoading(false);
      }
    },
    showRemoveClientModal(client) {
      this.removeClientModal = true
      this.removeClient = client
    },
    removeUserEmergency() {
      this.setLoading(true)
      this.removeUserInEmergencyTask({
        ticket_id: this.details.ticket.id,
        client_id: this.removeClient.id
      }).then(res => {
        if (res.success) {
          this.success(res.message)
          this.setClearDeletedUser(this.removeClient.id)
        } else this.error(res.message)
      }).finally(() => {
        this.setLoading(false)
        this.removeClientModal = false
      })
    },
    selectAccidentClient(client) {
      this.selectedClient = client;
      this.searchClient = client.name;
      this.clients = [];
    },
    async onClientSearchInput() {
      this.selectedClient = null;
      await this.fetchClients();
    },
    async relateTicketAndClient() {
      if (!this.selectedClient) {
        return;
      }

      try {
        this.setLoading(true);
        const res = await api.post('tickets/relate-ticket-and-client', {
          ticket_id: this.details.ticket.id,
          client_lanbilling_id: this.selectedClient.uid
        })

        if (!res.success) {
          this.errorAlert(res?.message || 'Ошибка при добавлении клиента к заявке');
        } else this.successAlert('Клиент успешно добавлен к заявке');
        this.searchClient = ''
        await this.fetchTicketDetail(this.details.ticket.id)
      } catch (err) {
        this.errorAlert(err?.message);
      } finally {
        this.setLoading(false);
      }
    },
    changeTab(tab) {
      this.activeTab = tab
    },
    async selectClient(client) {
      this.setLoading(true)
      await this.fetchClientData(client.billing_id)
      this.setLoading(false)
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({ticket_id: this.details.ticket.id, page: this.currentCommentPage})
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    toggleDepartmentModal() {
      if (!this.isSetDepartmentModal) {
        this.fetchDepartments({category_key: this.details.ticket.category.key})
      }
      this.isSetDepartmentModal = !this.isSetDepartmentModal
    },
    async toggleRelateModal() {
      if (!this.isRelateModal) {
        this.setLoading(true)
        await this.fetchCurrentEmergencyTicketList()
        this.setLoading(false)
      }
      this.isRelateModal = !this.isRelateModal
    },
    saveDecision() {
      this.setLoading(true)
      this.updateDecision({
        id: this.details.ticket.id,
        decision: this.decision
      })
      this.setLoading(false)
    },
    relateToEmergency(id) {
      this.setLoading(true)
      this.relateToEmergencyTicket({
        emergency_ticket_id: id,
        ticket_id: this.details.ticket.id
      }).then(res => {
        if (res.status) {
          this.success('Успешно связано с аварийной заявкой')
          this.fetchTicketDetail(this.$route.params.id);
        } else this.error(res.message)
      }).finally(() => {
        this.setLoading(false)
        this.toggleRelateModal()
      })
    },
    onChangeDepartment(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'department',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) this.error(res.message)
        this.toggleDepartmentModal()
        this.fetchTicketDetail(this.$route.params.id);
        this.setLoading(false)
      })
    },
    async addComment(comment) {
      comment.ticket_id = this.details.ticket.id
      await this.addTicketComments(comment)
    },
    async addCommentAsResult(comment) {
      this.setLoading(true)
      await this.addComment(comment)
      const payload = {
        ticket_id: this.details.ticket.id,
        status_id: this.selectedStatus.id,
        responsible_type: this.selectedStatus.responsible_type,
        responsible_id: this.selectedStatus.responsible_id
      }
      await this.changeTicketStatus(payload)
      await this.fetchTicketDetail(this.details.ticket.id)
      this.isCommentModal = false
      this.setLoading(false)
    },
    toggleTicketResultModal() {
      this.isTicketResultModal = !this.isTicketResultModal
    },
    toggleSendBitrixModal() {
      this.isOpenSendBitrixModal = !this.isOpenSendBitrixModal
    },
    maskPhone(phone) {
      return maskPhone(phone)
    },
    getTitle(array, id) {
      const result = findById(array, id)
      return result.title
    },
    getCompanyName(lanbilling_id) {
      let result
      this.companies.forEach(item => {
        if (item.lanbilling_id === lanbilling_id) result = item
      })
      return result.title
    },
    async openClientCard(id) {
      this.setLoading(true)
      await this.fetchClientData(id)
      this.setLoading(false)
    },
    async openManagerCard(id) {
      this.setLoading(true)
      await this.fetchManagerPhones(id).then(() => {
        this.isManagerInfoModal = true
        this.setLoading(false)
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    async ticketResult(result_id) {
      this.setLoading(true)
      await this.addTicketResult({
        ticket_id: this.details.ticket.id,
        ticket_result_id: result_id
      })
      this.toggleTicketResultModal()
      await this.fetchTicketDetail(this.details.ticket.id)
      this.setLoading(false)
    },
    async changeStatus(status) {
      const validIds = [16, 17]
      if (status.key === 'done' || status.key === 'unsuccessful') {
        this.selectedStatus = status
        this.isCommentModal = true
      } else if (status.key === 'closed' && !validIds.includes(this.details.ticket.ticket_kind.id)) {
        this.setLoading(true)
        await this.fetchTicketResults(this.details.ticket.ticket_kind.id)
        this.toggleTicketResultModal()
        this.setLoading(false)
      } else {
        this.setLoading(true)
        const payload = {
          ticket_id: this.details.ticket.id,
          status_id: status.id,
          responsible_type: status.responsible_type,
          responsible_id: status.responsible_id
        }
        await this.changeTicketStatus(payload)
        await this.fetchTicketDetail(this.details.ticket.id)
        this.setLoading(false)
      }
    },
    async openSubTicketModal() {
      this.isSubTicketModal = !this.isSubTicketModal
      this.setLoading(true)
      if (this.isSubTicketModal) {
        await this.fetchDepartments().then(() => {
          this.setLoading(false)
        })
      }
    },
    async assignBrigade(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'brigade',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) {
          this.error(res.message)
        } else {
          this.success(res.message)
          this.isBrigadeAssignModal = !this.isBrigadeAssignModal
          this.fetchTicketDetail(this.$route.params.id);
          this.setLoading(false)
        }
      })
    },
    async assignEmploye(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'manager',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) {
          this.error(res.message)
        } else {
          this.success(res.message)
          this.isEmployeAssignModal = !this.isEmployeAssignModal
          this.fetchTicketDetail(this.$route.params.id);
          this.setLoading(false)
        }
      })
    },
    async openWhatsApp(number) {
      const data = {
        user: {
          id: `${this.user.id}`,
          name: this.user.full_name
        },
        scope: 'card',
        filter: [{
          chatType: 'whatsapp',
          chatId: number
        }]
      }
      await this.fetchWazzup(data).then(() => {
        this.setIsClientCard(true)
        this.$emit("close");
        this.$router.push('/chat')
      }).catch((err) => {
        if (err.response.status === 400) {
          const users = [{
            id: `${this.user.id}`,
            name: this.user.full_name,
            phone: this.user.phone
          }]
          this.createUser(users)
          location.reload()
        }
      })
    },
    openEmergencyTicket(ticket) {
      this.setLoading(true)
      router.push(`/ticket/${ticket.id}`)
      this.setLoading(false)
    },
    async sendMessage({title, body, notification_types}) {
      const obj = {
        id: this.details.ticket.id,
        title: title,
        body: body,
        notification_types: notification_types
      }
      await this.sendNotificationMessage(obj).then((res) => {
        console.log('dd', title, body)
        if (res.success) {
          this.isSendNotificationModal = !this.isSendNotificationModal
          this.$toast.success('Уведомления успешно отправлены')
        } else {
          this.$toast.error('Не удалось отправить уведомления')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async callClientChange(client_id) {
      const obj = {
        id: this.details.ticket.id,
        client_id: client_id,
      }
      await this.changeCallClient(obj).then((res) => {
        if (res.success) {
          this.setTicketClientsIsCalled(obj.client_id)
          this.fetchTicketDetail(this.$route.params.id);
          this.$toast.success('Cтатус клиента успешно изменен')
        } else {
          this.$toast.error('Не удалось изменить статус клиента')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    downloadFile(file) {
      const url = file.file_url;
      const a = document.createElement('a');
      a.href = url;
      a.download = file.file_name || 'downloaded-file';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async increaseEndDate(id) {
      await this.increaseAccessDataTicketEndDate(id).then((res) => {
        if (res.success) {
          this.$toast.success('Дата завершения задачи удачно продлена')
          this.fetchTicketDetail(this.$route.params.id)
        } else {
          this.$toast.error('Не удалось продлить дату завершения')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    updateCountdown() {
      if (!this.details || !this.details.ticket || !this.details.ticket.plan_end) {
        console.error('Данные о плане недоступны');
        return;
      }

      // Преобразуем строку в корректный формат
      const planEndRaw = this.details.ticket.plan_end; // "03.10.2024 15:46"

      // Разделяем строку на дату и время
      const [date, time] = planEndRaw.split(' ');

      // Разделяем дату на день, месяц и год
      const [day, month, year] = date.split('.');

      // Формируем строку в формате ISO 8601: "YYYY-MM-DDTHH:MM:SS"
      const planEndISO = `${year}-${month}-${day}T${time}:00`;

      // Создаём объект даты
      const planEndDate = new Date(planEndISO);
      const now = new Date();

      if (isNaN(planEndDate.getTime())) {
        console.error('Неверный формат даты после преобразования:', planEndISO);
        return;
      }

      const difference = planEndDate - now;

      if (difference > 0) {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        this.timeRemaining = {hours, minutes, seconds};
      } else {
        clearInterval(this.timer);
        this.timeRemaining = {hours: 0, minutes: 0, seconds: 0};
      }
    },
    onRelatedTickets(ticket) {
      if (ticket.ticket_kind_id === 16) {
        this.$router.push(`/access/ticket/${ticket.ticket_number}`);
      } else if (ticket.ticket_kind_id === 5) {
        this.$router.push(`/emergency/ticket/${ticket.ticket_number}`)
      } else if (ticket.ticket_kind_id === 14){
        this.$router.push(`/subtask/ticket/${ticket.ticket_number}`)
      } else if (ticket.ticket_kind_id === 17){
        this.$router.push(`/ticket-lcs/${ticket.ticket_number}`)
      } else {
        this.$router.push(`/ticket/${ticket.ticket_number}`)
      }
    },
    onParentTickets(ticket) {
      if (ticket.ticket_kind_id === 16) {
        this.$router.push(`/access/ticket/${ticket.ticket_number}`);
      } else if (ticket.ticket_kind_id === 5) {
        this.$router.push(`/emergency/ticket/${ticket.ticket_number}`)
      } else if (ticket.ticket_kind_id === 14){
        this.$router.push(`/subtask/ticket/${ticket.ticket_number}`)
      } else if (ticket.ticket_kind_id === 17){
        this.$router.push(`/ticket-lcs/${ticket.ticket_number}`)
      } else {
        this.$router.push(`/ticket/${ticket.ticket_number}`)
      }
    },
    handleNewComment(comment) {
      console.log('Handling new comment:', comment); // Debug log
      this.addNewComment(comment);
    },
    setupSocketConnection() {
      const ticketId = this.details.ticket.id;
      if (!ticketId) return;

      console.log('Setting up socket connection for ticket:', ticketId);

      if (this.socketChannel) {
        this.socketChannel.unsubscribe();
      }

      this.socketChannel = window.Echo.channel(`notify_ticket_${ticketId}_reply`);

      this.socketChannel.listen('App\\Events\\Ticket\\ReplyEvent', (data) => {
        console.log('Received socket event:', data);

        const comment = {
          ...data,
          created_at: new Date().toLocaleString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        };

        console.log('Adding comment to store:', comment);
        this.addNewComment(comment);
      });
    },
    cleanupSocket() {
      if (this.socketChannel) {
        console.log('Cleaning up socket connection');
        this.socketChannel.unsubscribe();
        this.socketChannel = null;
      }
    },
    decodeEntities(encodedString) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = encodedString;
      return textarea.value;
    },
    cleanHtml(content) {
      if (!content) return content;
      return content.replace(/<(?!\/?(a|p|img|br)\b)([^>]+)>|<\/(?!\/?(a|br)\b)[^>]+>/g, '');
    },
    makeClickable(comment) {
      if (!comment) return comment;
      comment = this.decodeEntities(comment);
      let regex = /(https?:\/\/[^\s]+)/g;
      return comment.replace(regex, (match) => {
        return `<a style="color: #6788FF" href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });
    },
  },
  watch: {
    'details.ticket.id': {
      handler(newId) {
        if (newId) {
          this.setupSocketConnection();
        }
      },
      immediate: true
    }
  },
  beforeUnmount() {
    this.cleanupSocket();
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.client-emergency {
  &__remove {
    font-size: 24px;
    color: red;
    margin-left: 10px;
  }
}

.client-accident {
  margin: 30px 0 60px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  &__search-bar {
    width: 450px;
  }

  &:deep(input) {
    border-radius: 5px;
    border: solid 1px #000;
    outline: none;
  }

  &:deep(.search-client__result-dropdown) {
    background: #fff;
    border-radius: 5px;
  }

  &:deep(.search-client__result-item) {
    color: #000;
  }

  &__label {
    font-size: 24px;
  }

  &__btn {
    height: unset;
    width: unset;
    padding: 15px 25px;

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
}

.w-200 {
  width: 200px;
}

.text-margin {
  margin-bottom: 6px;
}

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  max-height: 340px;
  border-radius: 15px;
  background: $gray4;
  padding: 20px;


  & > div {
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;


    &:last-child {
      margin-bottom: 0;
    }

    & > p:first-child {
      width: 35%;
    }
  }
}

.images {
  img {
    width: 50px;
    height: 50px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.actions {
  margin-top: 28px;

  &-buttons {
    background: #04567B;
    color: white;
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 25px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_info {
      background: #04567B;
    }

    &_departure {
      background: #57C2DC;
    }

    &_waiting {
      background: #0095FF;
    }

    &_emergency {
      background: #FF6700;
    }

    &_complete {
      background: #15AD37;
    }
  }
}
</style>
