<template>
  <div class="base-input">
    <div v-if="label" class="input-label">{{ label }}</div>
    <div class="input-wrapper">
      <input
        :type="type"
        class="input-form"
        :value="value !== null ? value : modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :disabled="disabled"
      >
      <div v-if="modelValue && !disabled" class="input-controls">
        <img 
          src="/assets/clear-input.svg" 
          class="clear-icon"
          alt="clear"
          @click="clearInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    clearInput() {
      this.$emit('update:modelValue', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .input-label {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .input-wrapper {
    position: relative;
    width: 100%;

    .input-form {
      background: #F2F1F0 !important;
      border: none !important;
      border-radius: 12px !important;
      height: 44px !important;
      width: 100%;
      padding: 0 56px 0 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 16px;
      
      &::placeholder {
        color: #757575;
      }
      
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:disabled {
        background: #F2F1F0 !important;
        opacity: 0.7;
        pointer-events: none;
        -webkit-text-fill-color: #333333;
        color: #333333;
      }
    }

    .input-controls {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      .clear-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.2s ease;
        
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style> 