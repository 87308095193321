<template>
  <div class="auth-container min-h-full flex align-center justify-end">
    <div class="auth-form flex column align-center">
      <div class="flex w-100 align-center justify-center">
        <img class="mr-2" src="/assets/nls-logo.svg" alt="">
        <h3 class="auth-title">NLS.SUPPORT ЦЕНТР</h3>
      </div>
      <div class="auth-inner">
        <h4 :class="{'mb-5': errorText.length === 0, 'mb-2': errorText.length}">АВТОРИЗАЦИЯ</h4>
        <p v-if="errorText.length" class="error-text mb-2 uppercase">{{errorText}}</p>
        <div class="input-container mb-2">
          <input ref="loginInput" id="loginInput" placeholder="Логин" v-mask="phoneMask" class="input auth" type="text" v-model="login" @paste="onPasteLogin" />
          <label for="loginInput">Логин</label>
        </div>

        <div class="input-container" v-if="!showPass">
          <input ref="passInput" class="input auth" type="password" placeholder="Пароль" v-model="password" />
          <label for="passInput">Пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-closed.svg" @click="toggleShowPass">
          </div>
        </div>

        <div class="input-container" v-if="showPass">
          <input ref="passInput" class="input auth" type="text" placeholder="Пароль" v-model="password" />
          <label for="passInput">Пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-open.svg" @click="toggleShowPass">
          </div>
        </div>

        <div class="auth-actions">
          <button type="button" class="btn mb-2 mt-2" @click="signIn">Войти</button>
          <button type="button" class="btn mb-2 transparent" @click="forgotPass">Забыли пароль?</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import {mapGetters, mapMutations} from "vuex";
import Cookies from 'js-cookie'
export default {
  name: 'LoginView',
  data() {
    return {
      login: '',
      password: '',
      showPass: false,
      errorText: '',
      phoneMask: '+7 (###)-###-##-##',
    }
  },
  async mounted() {
    // const token = Cookies.get('token')
    const token = localStorage.getItem("token");

    if (token) return this.$router.push("/")

    this.$refs.loginInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.signIn()
      }
    })

    this.$refs.passInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.signIn()
      }
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    onPasteLogin(e) {
      if (e.clipboardData.getData('text').length === 11 && e.clipboardData.getData('text')[0] === '8') {
        this.login = '7' + this.unMask(e.clipboardData.getData('text')).slice(-10)
      }
    },
    async getRocketChatUser() {
      try {
        const user = await store.dispatch('getRocketChatUser')
        return user
      } catch (error) {
        this.$toast.open({
          message: 'NLS Чат недоступен',
          type: 'error'
        })
        return null
      }
    },
    async signIn() {
      if (this.unMask(this.login).length < 11) {
        this.errorText = 'Введите логин'
        setTimeout(() => {
          this.errorText = ''
        }, 5000)
      }
      else if (this.password.length === 0) {
        this.errorText = 'Заполните пароль'
        setTimeout(() => {
          this.errorText = ''
        }, 5000)
      }
      else {
        this.setLoading(true)
        await store.dispatch('login', {
          login: this.unMask(this.login),
          password: this.password
        }).then(async res => {
          if (!res.status) {
            this.errorText = res.message
            setTimeout(() => {
              this.errorText = ''
            }, 5000)
          }

          const rocketChatUser = await this.getRocketChatUser()
          if (rocketChatUser) {
              store.dispatch('loginToRocketChat')
          }
          if(this.user.iin) {
            store.dispatch('loginToTasks', {
              iin: this.user.iin,
              password: this.password,
            })
          }
        })
        this.setLoading(false)
      }
    },
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    forgotPass() {
      this.$router.push(`/forgot${this.login ? `?login=${this.unMask(this.login)}` : ''}`)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  }
}
</script>
