<template>
  <div class="ticket-comments w-100 flex column justify-end" v-if="!nonModalComments">
    <div class="ticket-comments__list flex">
      <div v-if="comments.length">
        <div class="flex justify-end cursor-pointer mb-1" @click="sortCommentsOrder">
          <img src="/assets/comments/arrow-down-up.svg" height="20" width="20"/>
        </div>
        <div class="ticket-comments__item card bg-white rounded mb-2" v-for="comment in getFirstComments"
             :key="comment.id">
          <div>
            <div v-if="comment.author" style="display: flex; justify-content: space-between">
              <h4 v-if="comment.author.full_name">
                {{ comment.author?.full_name }}
                <template v-if="comment.author.department">({{ comment.author.department.title }})</template>
              </h4>
              <h4 v-if="!comment.author.full_name">{{ comment.author.title }}</h4>
              <div
                  class="ticket-comments__edit"
                  v-if="user && comment && (user.id === comment.author.id || user.id === comment.manager_id)"
              >
                <img @click="editComment(comment)" src="/assets/edit-button-svgrepo-com.svg" alt=""/>
              </div>
            </div>
            <div v-else-if="comment.manager" style="display: flex; justify-content: space-between">
              <h4>
                {{ (comment?.manager?.full_name || comment?.installer_name) || '' }}
                <template v-if="comment.manager.department">({{ comment.manager.department?.title }})</template>
              </h4>

              <div
                  class="ticket-comments__edit"
                  v-if="user && comment && (user.id === comment.manager.id || user.id === comment.manager_id)"
              >
                <div v-if="comment.type === 'connection_ticket_reply'">
                  <img @click="editCommentDeparture(comment)" src="/assets/edit-button-svgrepo-com.svg" alt=""/>234
                </div>
                <div v-else>
                  <img @click="editComment(comment)" src="/assets/edit-button-svgrepo-com.svg" alt=""/>123
                </div>

              </div>
            </div>
            <div v-else style="display: flex; justify-content: space-between">
              <div></div>
              <div class="ticket-comments__edit"
                   v-if="user.id === comment.manager_id || user.id === comment.manager?.id">
                <img @click="editCommentDeparture(comment)" src="/assets/edit-button-svgrepo-com.svg" alt=""/>234
              </div>
            </div>
          </div>
          <pre v-html="cleanHtml(makeClickable(comment.message))" class="comment-pre"
               @click="toggleImageFromHtml"></pre>
          <div class="ticket-comments__files">
            <div v-for="file in comment.files" :key="file.id">
              <template
                  v-if="
									file.file_name.match(/\.(jpg|jpeg|png|gif|jfif)$/i) ||
									file.file_url.match(/\.(jpg|jpeg|png|gif|jfif)$/i)
								"
              >
                <img :src="file.file_url" @click="openViewer($event)" alt="Image" ref="imageContainer"/>
              </template>
              <template
                  v-else-if="file.file_name.match(/\.(mp4|avi|mov|webm)$/i) || file.file_url.match(/\.(mp4|avi|mov|webm)$/i)"
              >
                <video class="attached-files__video" controls :src="file.file_url" width="65" height="65"></video>
              </template>
              <template
                  v-else-if="
									file.file_name.match(/\.(xlsx|xls|doc|docx|pdf)$/i) ||
									file.file_url.match(/\.(xlsx|xls|doc|docx|pdf)$/i)
								"
              >
                <div class="cursor-pointer" @click="downloadFile(file)">
                  <div class="mr-2 mt-1 link">{{ file.file_name }}</div>
                </div>
              </template>
            </div>
          </div>

          <span>{{ comment.created_at }}</span>
        </div>
      </div>
      <p class="w-100 text-gray2 text-center" v-else>Комментариев нет</p>
    </div>
    <div
        :style="{ display: isShowAllComments ? 'none' : '' }"
        class="w-full mb-1 text-center more-comments"
        v-if="comments.length > 3"
        @click="showAllComments"
    >
      Показать все комментарии
    </div>
    <div class="w-full text-center more-comments" v-if="totalComments > comments.length" @click="loadComments">
      Загрузить еще {{ totalComments - comments.length > 10 ? '10' : totalComments - comments.length }} коментариев
    </div>
    <div class="ticket-comments__input w-100 flex column">
      <img class="ticket-comments__file" @click="onPickFile" src="/assets/paper-clip.svg" alt=""/>
      <img class="ticket-comments__send" @click="addComment" src="/assets/send.svg" alt=""/>
      <input type="file" style="display: none" ref="fileInput" id="fileInput" multiple @change="onFilePicked"/>
      <!--      <textarea class="input textarea" v-model="comment" placeholder="Добавить комментарий" ref="message" type="text"-->
      <!--                @paste="pasteImage" @drop.prevent="pasteImage"/>-->
      <QuillEditor
          ref="quillEditor"
          style="height: 160px !important; margin-bottom: 12px; !important;"
          class="input textarea"
          theme="snow"
          toolbar="essential"
          v-model:content="comment"
          content-type="html"
          @drop.prevent="pasteImage"
          @keydown="handleKeyPress"
      ></QuillEditor>
      <div class="attached-files flex flex-wrap w-100" v-if="attached_files.length > 0">
        <div class="attached-files__item" v-for="(file, index) in attached_files" :key="index">
          <img class="attached-files__delete" @click="removeFile(index)" src="/assets/close-red.svg" alt="Delete file"/>

          <!-- Image Handling -->
          <template
              v-if="
							file.format === 'jpg' ||
							file.format === 'jpeg' ||
							file.format === 'png' ||
							file.format === 'gif' ||
							file.format === 'jfif' ||
							file.format === 'bmp' ||
							file.format === 'svg' ||
							file.format.includes('image')
						"
          >
            <img
                class="attached-files__file"
                @click="toggleImageModal('data:image/' + file.format + ';base64,' + file.base64String)"
                :src="'data:image/' + file.format + ';base64,' + file.base64String"
                alt="Attached image"
            />
          </template>

          <!-- Video Handling -->
          <template
              v-else-if="
							file.format === 'mp4' ||
							file.format === 'avi' ||
							file.format === 'mov' ||
							file.format === 'webm' ||
							file.format.includes('video')
						"
          >
            <video
                class="attached-files__file"
                controls
                :src="'data:video/' + file.format + ';base64,' + file.base64String"
                width="200"
                height="200"
            >
              Your browser does not support the video tag.
            </video>
          </template>

          <!-- Excel/Word File Handling -->
          <template
              v-else-if="
							file.format === 'xls' ||
							file.format === 'xlsx' ||
							file.format === 'doc' ||
							file.format === 'docx' ||
							file.format === 'pdf'
						"
          >
            <div class="attached-files__file">
              <div style="background: gray; word-break: break-all">{{ file.fileName }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <button @click="nonModalComments = !nonModalComments" class="expand-btn">
      <img src="/assets/fullscreen-svgrepo-com.svg" height="16" width="16"/>
    </button>
    <custom-modal v-if="isEditCommentModal" @close="isEditCommentModal = !isEditCommentModal">
      <EditCommentModal :comment="editedComment" @submitEdittingComment="submitEdittingComment"></EditCommentModal>
    </custom-modal>
  </div>

  <!--  FULL SIZE COMMENTS-->
  <custom-modal v-if="nonModalComments" @close="nonModalComments = !nonModalComments" customClass="comments-full">
    <div class="ticket-comments-full w-100 flex column justify-end">
      <div class="ticket-comments-full__list flex">
        <div v-if="comments.length">
          <div class="flex justify-end cursor-pointer mb-1" @click="sortCommentsOrder">
            <img src="/assets/comments/arrow-down-up.svg" height="20" width="20"/>
          </div>
          <div class="ticket-comments-full__item card bg-white rounded mb-2" v-for="comment in comments"
               :key="comment.id">
            <div>
              <div v-if="comment.author" style="display: flex; justify-content: space-between">
                <h4 v-if="comment.author.full_name">
                  {{ comment.author.full_name }}
                  <template v-if="comment.author.department">({{ comment.author.department.title }})</template>
                </h4>
                <h4 v-if="!comment.author.full_name">{{ comment.author.title }}</h4>
                <div
                    class="ticket-comments-full__edit"
                    v-if="user && comment && (user.id === comment.author.id || user.id === comment.manager_id)"
                >
                  <img @click="editComment(comment)" src="/assets/edit-button-svgrepo-com.svg" alt=""/>
                </div>
              </div>
            </div>
            <pre v-html="comment.message" @click="toggleImageFromHtml"></pre>
            <div class="ticket-comments-full__files">
              <div class="ticket-comments-full__files" v-for="file in comment.files" :key="file.id">
                <template
                    v-if="
										file.file_name.match(/\.(jpg|jpeg|png|gif|jfif)$/i) ||
										file.file_url.match(/\.(jpg|jpeg|png|gif|jfif)$/i)
									"
                >
                  <img :src="file.file_url" @click="openViewer($event)" alt="Image" ref="imageContainer"/>
                </template>
                <template
                    v-else-if="
										file.file_name.match(/\.(mp4|avi|mov|webm)$/i) || file.file_url.match(/\.(mp4|avi|mov|webm)$/i)
									"
                >
                  <video class="attached-files__video" controls :src="file.file_url" width="65" height="65"></video>
                </template>
                <template
                    v-else-if="
										file.file_name.match(/\.(xlsx|xls|doc|docx|pdf)$/i) ||
										file.file_url.match(/\.(xlsx|xls|doc|docx|pdf)$/i)
									"
                >
                  <div class="cursor-pointer" @click="downloadFile(file)">
                    <div class="mr-2 mt-1 link">{{ file.file_name }}</div>
                  </div>
                </template>
              </div>
            </div>
            <span>{{ comment.created_at }}</span>
          </div>
        </div>
        <p class="w-100 text-gray2 text-center" v-else>Комментариев нет</p>
      </div>
      <div class="w-full text-center more-comments" v-if="totalComments > comments.length" @click="loadComments">
        Загрузить еще {{ totalComments - comments.length > 10 ? '10' : totalComments - comments.length }} коментариев
      </div>
      <div class="ticket-comments-full__input w-100 flex column">
        <img class="ticket-comments-full__file" @click="onPickFile" src="/assets/paper-clip.svg" alt=""/>
        <img class="ticket-comments-full__send" @click="addComment" src="/assets/send.svg" alt=""/>
        <input type="file" style="display: none" ref="fileInput" id="fileInput" multiple @change="onFilePicked"/>
        <!--        <textarea class="input textarea" v-model="comment" placeholder="Добавить комментарий" ref="message" type="text"-->
        <!--                  @paste="pasteImage" @drop.prevent="pasteImage"/>-->
        <QuillEditor
            ref="quillEditor"
            style="height: 160px !important; margin-bottom: 12px !important"
            class="input textarea"
            theme="snow"
            toolbar="essential"
            v-model:content="comment"
            content-type="html"
            @drop.prevent="pasteImage"
            @keydown="handleKeyPress"
        ></QuillEditor>
        <div class="attached-files flex flex-wrap w-100" v-if="attached_files.length > 0">
          <div class="attached-files__item" v-for="(file, index) in attached_files" :key="index">
            <img class="attached-files__delete" @click="removeFile(index)" src="/assets/close-red.svg"
                 alt="Delete file"/>

            <!-- Image Handling -->
            <template
                v-if="
								file.format === 'jpg' ||
								file.format === 'jpeg' ||
								file.format === 'png' ||
								file.format === 'gif' ||
								file.format === 'jfif' ||
								file.format === 'bmp' ||
								file.format === 'svg' ||
								file.format.includes('image')
							"
            >
              <img
                  class="attached-files__file"
                  @click="toggleImageModal('data:image/' + file.format + ';base64,' + file.base64String)"
                  :src="'data:image/' + file.format + ';base64,' + file.base64String"
                  alt="Attached image"
              />
            </template>

            <!-- Video Handling -->
            <template
                v-else-if="
								file.format === 'mp4' ||
								file.format === 'avi' ||
								file.format === 'mov' ||
								file.format === 'webm' ||
								file.format.includes('video')
							"
            >
              <video
                  class="attached-files__file"
                  controls
                  :src="'data:video/' + file.format + ';base64,' + file.base64String"
                  width="200"
                  height="200"
              >
                Your browser does not support the video tag.
              </video>
            </template>
            <!-- Excel File Handling -->
            <template
                v-else-if="
								file.format === 'xls' ||
								file.format === 'xlsx' ||
								file.format === 'doc' ||
								file.format === 'docx' ||
								file.format === 'pdf'
							"
            >
              <div class="attached-files__file">
                <div style="background: gray; word-break: break-all">{{ file.fileName }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <button class="expand-btn" @click="nonModalComments = !nonModalComments">
        <img src="/assets/normal-screen.svg" width="16" height="16"/>
      </button>
      <custom-modal v-if="isEditCommentModal" @close="isEditCommentModal = !isEditCommentModal">
        <EditCommentModal :comment="editedComment" @submitEdittingComment="submitEdittingComment"></EditCommentModal>
      </custom-modal>
    </div>
  </custom-modal>
  <custom-modal v-if="image" @close="toggleImageModal">
    <image-modal :image="image"/>
  </custom-modal>
</template>

<script>
import {getBase64, getFileFormat} from '@/helpers/utils';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CustomModal from '@/components/modules/CustomModal.vue';
import EditCommentModal from '@/components/modals/EditCommentModal.vue';
import ImageModal from '@/components/modals/ImageModal.vue';
import {QuillEditor} from '@vueup/vue-quill';
import {parse, format, parseISO, isValid} from 'date-fns';
import {DateTime} from 'luxon';

export default {
  name: 'CommentsTab',
  props: ['comments', 'totalComments'],
  components: {
    ImageModal,
    CustomModal,
    EditCommentModal,
    QuillEditor,
  },
  data() {
    return {
      comment: '',
      attached_files: [],
      isEditCommentModal: false,
      editedComment: [],
      nonModalComments: false,
      image: '',
      isDeparture: false,
      isShowAllComments: false,
      isSorted: false,
      format,
      parseISO,
      viewer: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    getFirstComments() {
      if (this.isShowAllComments) {
        return this.getSortedComments();
      } else {
        return this.getSortedComments().slice(-3);
      }
    },
  },
  methods: {
    ...mapActions({
      editTicketComments: 'editTicketComments',
      departureCommentEdit: 'departureCommentEdit',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async openViewer(event) {
      const {default: Viewer} = await import('viewerjs');

      const imageElement = event.target;
      if (imageElement) {
        new Viewer(imageElement, {
          inline: false,
          toolbar: true,
          navbar: false,
          title: false,
        }).show();
      }
    },
    destroyViewer() {
      if (this.viewer) {
        this.viewer.destroy();
        this.viewer = null;
      }
    },
    addComment() {
      if (this.comment.length > 0 || this.attached_files.length > 0) {
        this.$emit('addComment', {
          comment: this.comment,
          attached_files: this.attached_files,
        });
        this.clearComment();
      }
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = Array.from(event.target.files);
      files.forEach(async (item) => {
        let file = {};
        try {
          const data = await getBase64(item);
          file.format = getFileFormat(item);
          if (file.format.includes('video')) {
            file.base64String = data.split(',')[1];
            file.videoURL = URL.createObjectURL(item);
          } // Handle Excel or Word files
          else if (
              file.format.includes('xlsx') ||
              file.format.includes('doc') ||
              file.format.includes('xls') ||
              file.format.includes('docx') ||
              file.format.includes('pdf')
          ) {
            file.base64String = data.split(',')[1]; // Base64 conversion for Excel or Word
            file.fileName = item.name;
          } else {
            file.base64String = data.split(',')[1];
          }

          this.attached_files.push(file);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      });
    },
    removeFile(index) {
      this.attached_files.splice(index, 1);
    },
    clearComment() {
      this.comment = '';
      this.attached_files = [];
      this.$refs.quillEditor.setHTML('');
    },
    loadComments() {
      this.$emit('loadComments');
    },
    showAllComments() {
      this.isShowAllComments = true;
    },
    editComment(msg) {
      this.editedComment = msg;
      this.isEditCommentModal = true;
      this.isDeparture = false;
    },
    editCommentDeparture(msg) {
      this.editedComment = msg;
      this.isDeparture = true;
      this.isEditCommentModal = true;
    },
    pasteImage(pasteEvent) {
      let items = pasteEvent.clipboardData ? pasteEvent.clipboardData.items : pasteEvent.dataTransfer.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') == -1) continue;
        let blob = items[i].getAsFile();
        const temp = {
          target: {
            files: [blob],
          },
        };
        this.onFilePicked(temp);
      }
    },
    async submitEdittingComment(comment) {
      if (this.isDeparture) {
        this.setLoading(true);
        await this.departureCommentEdit(comment)
            .then((res) => {
              if (res.success) {
                this.isEditCommentModal = !this.isEditCommentModal;
                this.setLoading(false);
                this.$toast.success('Комментарий изменен!');
              } else {
                this.$toast.error('Не удалось изменить комментарий');
                this.setLoading(false);
              }
            })
            .catch((err) => {
              this.$toast.error('Не удалось изменить комментарий');
              this.setLoading(false);
            });
      } else {
        this.setLoading(true);
        await this.editTicketComments(comment)
            .then((res) => {
              if (res.success) {
                this.isEditCommentModal = !this.isEditCommentModal;
                this.setLoading(false);
                this.$toast.success('Комментарий изменен!');
              } else {
                this.$toast.error('Не удалось изменить комментарий');
              }
            })
            .catch((err) => {
              this.$toast.error('Не удалось изменить комментарий');
            });
      }
    },
    toggleImageModal(src = '') {
      this.image = src;
    },
    toggleImageFromHtml(event) {
      if (event.target.tagName === 'IMG') {
        this.image = event.target.src;
      }
    },
    downloadFile(file) {
      const url = file.file_url;
      const a = document.createElement('a');
      a.href = url;
      a.download = file.file_name || 'downloaded-file';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    decodeEntities(encodedString) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = encodedString;
      return textarea.value;
    },
    cleanHtml(content) {
      if (!content) return content;
      return content.replace(/<(?!\/?(a|p|img|br)\b)([^>]+)>|<\/(?!\/?(a|br)\b)[^>]+>/g, '');
    },
    makeClickable(comment) {
      if (!comment) return comment;
      comment = this.decodeEntities(comment);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = comment;

      // Replace <p> tags with single line break and preserve other HTML
      let content = comment.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
      content = content.replace(/<br\s*\/?>/g, '\n');

      // Then process URLs in the text
      let regex = /(https?:\/\/[^\s<>]+)/g;
      const processedText = content.replace(regex, (match) => {
        return `<a style="color: #6788FF" href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });

      return processedText;
    },
    handleKeyPress(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        event.preventDefault();
        this.addComment();
      }
    },
    sortCommentsOrder() {
      this.isSorted = !this.isSorted;
    },
    getSortedComments() {
      const clonedComments = [...this.comments];

      return clonedComments
          .map((comment) => {
            const parseDate = (dateString) => {
              const isoDate = parseISO(dateString);
              const dateTime = DateTime.fromISO(dateString, {zone: 'utc'});
              const convertedDate = dateTime.minus({hours: 5}); // Subtract 5 hours from UTC time
              if (isValid(isoDate)) return convertedDate;
              const customDate = parse(dateString, 'dd.MM.yyyy HH:mm', new Date());
              if (isValid(customDate)) return customDate;
              return null;
            };

            const date = parseDate(comment.created_at);

            if (date) {
              // Convert the Luxon DateTime object to a native JavaScript Date object for sorting
              const nativeDate = date instanceof Date ? date : date.toJSDate();
              comment.created_at = format(nativeDate, 'dd.MM.yyyy HH:mm');
              return {...comment, date: nativeDate}; // Use the native JS date object
            }

            return {...comment, date: null};
          })
          .sort((a, b) => {
            if (!a.date || !b.date) return 0;

            // Compare the native JavaScript Date objects (or timestamps)
            return this.isSorted ? b.date - a.date : a.date - b.date;
          });
    },
  },
  created() {
    const ticketId = window.location.pathname.split('/').pop();

    if (ticketId) {
      console.log('Setting up socket for ticket:', ticketId);

      window.Echo.channel(`notify_ticket_${ticketId}_reply`).listen('.Ticket\\ReplyEvent', (data) => {
        console.log('Received socket event:', data);

        const comment = {
          ...data,
          author: {
            id: data.author_id,
            type: data.author_type,
            full_name: data.full_name,
            department: {
              title: data.department,
            },
          },
          created_at: new Date().toLocaleString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
        };

        this.$store.commit('addTicketComment', comment);
      });
    }
  },
  beforeUnmount() {
    const ticketId = window.location.pathname.split('/').pop();
    if (ticketId) {
      console.log('Leaving socket channel for ticket:', ticketId);
      window.Echo.leave(`notify_ticket_${ticketId}_reply`);
    }
    this.destroyViewer();
  },
};
</script>

<style lang="scss" scoped>
@import '../../theme/variables';
@import 'viewerjs/dist/viewer.css';

.ticket-comments {
  position: relative;

  &__list {
    flex-direction: column;
    min-height: 240px;
    padding: 20px;
    max-height: 420px;

    background: $gray4;
    border-radius: 15px;
    margin-bottom: 12px;

    & > div {
      overflow-y: auto;
      max-height: 420px;
      overflow-x: hidden;
    }
  }

  &__input {
    position: relative;

    .textarea {
      border-radius: 25px;
      border: 2px solid $gray;
      background: $white;
      height: 200px !important;
      margin-bottom: 12px;

      &:focus,
      &:active,
      &:focus-visible {
        border: 1px solid $gray;
        outline: none !important;
      }
    }
  }

  &__file {
    position: absolute;
    top: 160px;
    right: 48px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  video {
    width: 570px;
    height: 300px;
  }

  &__edit {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
  }

  &__send {
    position: absolute;
    top: 160px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  &__item {
    padding: 16px 20px;
    position: relative;

    h4 {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    p {
      color: #8d8d8d;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    span {
      position: absolute;
      right: 16px;
      bottom: 16px;
      color: #8d8d8d;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 65px;
      height: 65px;
      margin-right: 12px;
      cursor: pointer;
      margin-top: 12px;
    }
  }
}

.attached-files {
  margin-bottom: 12px;

  &__item {
    margin-right: 8px;
    position: relative;
  }

  &__delete {
    position: absolute;
    z-index: 2;
    top: -4px;
    right: -4px;
    cursor: pointer;
  }

  &__file {
    width: 65px;
    height: 65px;
  }

  &__video {
    width: 65px;
    height: 65px;
    margin-right: 12px;
    cursor: pointer;
    margin-top: 12px;
  }
}

.ticket-comments-full {
  position: relative;

  &__list {
    flex-direction: column;
    min-height: 600px;
    max-height: 600px;
    padding: 20px;

    background: $gray4;
    border-radius: 15px;
    margin-bottom: 12px;

    & > div {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &__input {
    position: relative;

    .textarea {
      border-radius: 25px;
      border: 2px solid $gray;
      background: $white;
      height: 160px !important;
      margin-bottom: 20px !important;

      &:focus,
      &:active,
      &:focus-visible {
        border: 1px solid $gray;
        outline: none !important;
      }
    }
  }

  &__file {
    position: absolute;
    bottom: 30px;
    right: 48px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  &__edit {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
  }

  &__send {
    position: absolute;
    bottom: 30px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  &__item {
    padding: 16px 20px;
    position: relative;

    h4 {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    p {
      color: #8d8d8d;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }

    span {
      position: absolute;
      right: 16px;
      bottom: 16px;
      color: #8d8d8d;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 65px;
      height: 65px;
      margin-right: 12px;
      cursor: pointer;
      margin-top: 12px;
    }
  }
}

.more-comments {
  background: #dadada;
  padding: 12px 0;
  cursor: pointer;
  border-radius: 6px;
}

.expand-btn {
  display: flex;
  background: transparent;
  border: none;
  justify-content: end;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
    transition: 100ms;
  }
}

.comment-pre {
  word-break: break-word;
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
  font-family: inherit;
  line-height: 1.4;
}

.ticket-comments__input {
  position: relative;

  &::after {
    content: 'Ctrl + Enter для отправки';
    position: absolute;
    top: 100%;
    right: 40px;
    font-size: 12px;
    color: #718096;
    opacity: 0.7;
  }
}
</style>
