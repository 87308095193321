<template>
	<label :class="['app-checkbox', 'filled', className]">
		<input type="checkbox" v-bind="$attrs" v-model="checked" />
		<span :class="['checkmark']">
			<img src="/assets/tick.svg" class="tick-icon" alt="tick" />
		</span>
	</label>
</template>

<script>
	export default {
		props: {
			className: {
				type: String,
				default: '',
			},
			modelValue: {
				type: [Boolean, String, Number],
				default: false,
			},
		},
		emits: ['update:modelValue'],
		data() {
			return {
				checked: this.modelValue ?? false,
			};
		},
		watch: {
			modelValue(newValue) {
				this.checked = newValue ?? false;
			},
			checked(newValue) {
				this.$emit('update:modelValue', newValue);
			},
		},
	};
</script>

<style scoped lang="scss">
	.app-checkbox {
		cursor: pointer;
		display: inline-block;
		width: 20px;
		height: 20px;
		position: relative;
		border: none;

		&.filled {
			input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				width: 0;
				height: 0;

				&:checked ~ .checkmark {
					.tick-icon {
						opacity: 1;
					}
					background-color: #FE7300;
				}
			}

			.checkmark {
				border: none;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: #E3E2E1;
				border-radius: 5px;
			}
		}

		.tick-icon {
			opacity: 0;
		}
	}
</style>
