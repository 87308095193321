<template>
  <div v-if="wikiPage" class="wrapper flex justify-center " style="overflow-y: scroll; height: 80vh">
    <div >
      <div v-for="block in wikiPage.blocks" :key="block.id" >
        <div class="mb-4">{{block.title}}</div>
        <div v-html="block.description"></div>
        <div v-for="video in block.files" :key="video.file_name">
          <h1 class="mb-2 mt-4">{{ video.file_name }}</h1>
          <video :src="video.file_url" controls width="600">
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "InstructionModal",
  // props: {
  //   wikiPage: {
  //     type: Object,
  //     default: ()=>{}
  //   },
  // },
  created() {
    this.setLoading(true)
    this.fetchWikiDetail(this.$route.params.id);
    this.setLoading(false)
  },
  computed: {
  ...mapGetters({
    wikiPage: 'wikiPage'
  })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchWikiDetail: 'fetchWikiPage',
    })
  },
}
</script>



<style scoped lang="scss">

</style>