import {api} from '@/service/axios'
import {getIndexById} from '@/helpers/utils'

export const ticketsModule = {
  state: () => ({
    tickets: [],
    ticketsTotalPages: 0,
    totalTickets: 0,
    emergencyTickets: [],
    emergencyTicketsTotalPages: 0,
    emergencyTotalTickets: 0,
    emergencyTypeProblems: [],
    planTickets: [],
    planTicketsTotalPages: 0,
    planTotalTickets: 0,
    chosenTicket: {},
    ticketComments: [],
    totalComments: 0,
    currentCommentPage: 1,
    clientsTickets: [],
    ticketCounts: [],
    currentEmergencyTickets: [],
    changedTicketIds: [],
    ticketsCategories: [],
    bitrixDepartments: [],
    currentTicketId: null
  }),
  getters: {
    tickets(state) {
      return state.tickets
    },
    ticketsTotalPages(state) {
      return state.ticketsTotalPages
    },
    totalTickets(state) {
      return state.totalTickets
    },
    emergencyTickets(state) {
      return state.emergencyTickets
    },
    emergencyTicketsTotalPages(state) {
      return state.emergencyTicketsTotalPages
    },
    emergencyTotalTickets(state) {
      return state.emergencyTotalTickets
    },
    planTickets(state) {
      return state.planTickets
    },
    planTicketsTotalPages(state) {
      return state.planTicketsTotalPages
    },
    planTotalTickets(state) {
      return state.planTotalTickets
    },
    chosenTicket(state) {
      return state.chosenTicket
    },
    ticketComments(state) {
      return state.ticketComments
    },
    totalComments(state) {
      return state.totalComments
    },
    currentCommentPage(state) {
      return state.currentCommentPage
    },
    clientsTickets(state) {
      return state.clientsTickets
    },
    ticketCounts(state) {
      return state.ticketCounts
    },
    currentEmergencyTickets(state) {
      return state.currentEmergencyTickets
    },
    getChangedTicketIds(state) {
      return state.changedTicketIds
    },
    getEmergencyTypeProblems(state){
      return state.emergencyTypeProblems
    },
    ticketsCategories(state){
      return state.ticketsCategories
    },
    bitrixDepartments(state){
      return state.bitrixDepartments
    },
    currentTicketId(state) {
      return state.currentTicketId
    }
  },
  mutations: {
    setTickets(state, payload) {
      state.tickets = payload
    },
    setTicketsTotalPages(state, payload) {
      state.ticketsTotalPages = payload
    },
    setTotalTickets(state, payload) {
      state.totalTickets = payload
    },
    setEmergencyTickets(state, payload) {
      state.emergencyTickets = payload
    },
    setEmergencyTicketsTotalPages(state, payload) {
      state.emergencyTicketsTotalPages = payload
    },
    setEmergencyTotalTickets(state, payload) {
      state.emergencyTotalTickets = payload
    },
    setPlanTickets(state, payload) {
      state.planTickets = payload
    },
    setPlanTicketsTotalPages(state, payload) {
      state.planTicketsTotalPages = payload
    },
    setPlanTotalTickets(state, payload) {
      state.planTotalTickets = payload
    },
    setChosenTicket(state, payload) {
      state.chosenTicket = payload
    },
    setTicketComments(state, payload) {
      state.ticketComments = Array.isArray(payload) ? [...payload] : []
    },
    setTotalComments(state, payload) {
      state.totalComments = payload
    },
    setCurrentCommentPage(state, payload) {
      state.currentCommentPage = payload
    },
    setClientsTickets(state, payload) {
      state.clientsTickets = payload
    },
    setTicketCounts(state, payload) {
      state.ticketCounts = payload
    },
    setCurrentEmergencyTickets(state, payload) {
      state.currentEmergencyTickets = payload
    },
    setChangedTicketIds(state, payload) {
      state.changedTicketIds = payload
    },
    setEmergencyTypeProblems(state, payload){
      state.emergencyTypeProblems = payload
    },
    setTicketClientsIsCalled(state,payload){
      state.chosenTicket.ticket_clients.forEach((el)=> {
        if (el.id === payload){
          el.is_called = true
        }
      })
    },
    setClearDeletedUser(state, payload){
      state.chosenTicket.ticket_clients = state.chosenTicket.ticket_clients.filter((el)=> el.id != payload)
    },
    addTicketComment(state, comment) {
      const exists = state.ticketComments.some(c => c.id === comment.id)
      if (!exists) {
        state.ticketComments = [comment, ...state.ticketComments]
        state.totalComments++
      }
    },
    setTicketsCategories(state, payload) {
      state.ticketsCategories = payload
    },
    setBitrixDepartments(state, payload) {
      state.bitrixDepartments = payload
    },
    setCurrentTicketId(state, payload) {
      state.currentTicketId = payload
    },
  },
  actions: {
    async fetchTickets ({commit}, payload) {
      const res = await api.get(`tickets/ticket-list`, {
        params: payload,
        responseType: payload.download ? 'blob' : 'json'
      })
      
      if (payload.download) {
        return res.data
      }
      
      commit('setTickets', res.data.tickets)
      commit('setTicketsTotalPages', res.data.meta.last_page)
      commit('setTotalTickets', res.data.meta.total)
      return res
    },
    async fetchEmergencyTickets ({commit}, payload) {
      const res = await api.get(`tickets/ticket-list`, {
        params: {
          ...payload,
          ticket_kind_id: 5
        }
      })
      commit('setEmergencyTickets', res.data.tickets)
      commit('setEmergencyTicketsTotalPages', res.data.meta.last_page)
      commit('setEmergencyTotalTickets', res.data.meta.total)
    },
    async fetchPlanTickets ({commit}, payload) {
      const res = await api.get(`tickets/ticket-list`, {
        params: {
          ...payload,
          ticket_kind_id: 8
        }

      })
      commit('setPlanTickets', res.data.tickets)
      commit('setPlanTicketsTotalPages', res.data.meta.last_page)
      commit('setPlanTotalTickets', res.data.meta.total)
    },
    async ticketDetail ({state, commit, dispatch}, payload) {
      const res = await api.get(`tickets/ticket/${payload}`)
      if (res.data){
        commit('setChosenTicket', res.data)
        commit('setCurrentCommentPage', 1)
        commit('setTicketComments', [])
        dispatch('fetchTicketComments', {ticket_id: res.data.ticket.id, page: state.currentCommentPage})
      }
      return res
    },
    async createServiceTicket ({dispatch}, payload) {

      payload.details.forEach(item => {
          if(item.key === 'error_number' || item.key === 'registration_number') {
            item.value = item.value.replace(/[() +-]/g, "").trim()
          }
      })
      return await api.post('tickets/service-ticket-store', payload)
    },
    async createEmergencyTicket ({dispatch}, payload) {
      return await api.post('tickets/emergency-ticket-store', payload)
    },
    async createPlanTicket ({dispatch}, payload) {
      return await api.post('tickets/plan-ticket-store', payload)
    },
    async createConsultationTicket ({dispatch}, payload) {
      return await api.post('tickets/consultation-ticket-store', payload)
    },
    async createInternalTicket ({dispatch}, payload) {
      return await api.post('tickets/internal-ticket-store', payload)
    },
    async fetchClientsTickets ({commit}, payload) {
      const res = await api.get(`clients/ticket-list?lanbilling_uid=${payload}`)
      commit('setClientsTickets', res.data.tickets)
    },
    // async fetchTicketStatusButtons ({commit}, payload) {
    //   const res = await api.get(`tickets/get-statuses?ticket_id=${payload}`)
    //   commit('setTicketStatusButtons', res.data)
    // },
    async changeTicketStatus ({dispatch}, payload) {
      const res = await api.post('tickets/change-status', payload)
      // if(res.success) dispatch('successAlert', 'Тикет успешно отправлен')
      // else dispatch('errorAlert', 'Ошибка сервера')
    },
    async addTicketResult ({dispatch}, payload) {
      const res = await api.post('tickets/ticket-result-add', payload)
      console.log(res)
      // if(res.success) dispatch('successAlert', 'Тикет успешно отправлен')
      // else dispatch('errorAlert', 'Ошибка сервера')
    },
    async fetchTicketComments ({state, commit}, payload) {
      try {
        const res = await api.get(`tickets/ticket-comments`, {
          params: payload
        })
        
        if (payload.page === 1) {
          commit('setTicketComments', res.data.replies.reverse())
        } else {
          commit('setTicketComments', [...state.ticketComments, ...res.data.replies.reverse()].reverse())
        }
        
        commit('setTotalComments', res.data.meta.total)
      } catch (error) {
        console.error('Error fetching comments:', error)
        commit('setTicketComments', [])
        commit('setTotalComments', 0)
      }
    },
    async addTicketComments ({state, commit}, payload) {
      const res = await api.post(`tickets/ticket-comment-add`, payload)
      commit('setTicketComments', [...state.ticketComments, res.data])
    },
    async editTicketComments({state,commit,},payload){
      const res = await api.post(`tickets/ticket-comment-update`, payload)
      commit('setTicketComments', [...state.ticketComments.slice(0,getIndexById(state.ticketComments, res.data.id)),
         res.data,...state.ticketComments.slice(getIndexById(state.ticketComments,res.data.id)+1)])
      return res
    },
    async fetchNodeClients ({state}, payload) {
      return await api.get(`tickets/get-node-clients?node_id=${payload}`)
    },
    async fetchTicketCountList ({commit}, payload) {
      const res = await api.get(`tickets/ticket_count_list`)
      commit('setTicketCounts', res.data)
    },
    async setDepartment({state}, payload) {
      return await api.post(`tickets/assign`, payload)
    },
    async fetchCurrentEmergencyTicketList({commit}) {
      const res = await api.get(`tickets/current-emergency-ticket-list`)
      commit('setCurrentEmergencyTickets', res.tickets)
    },
    async relateToEmergencyTicket({state}, payload) {
      return await api.post(`tickets/relate-to-emergency`, payload)
    },
    async updateDecision({state}, payload) {
      return await api.post(`tickets/update-decision`, payload)
    },
    async createTaskOnTicketIds({state}, payload) {
      return await api.post(`tickets/take-in-work`, payload)

    },
    async createSubTicket({state}, payload){
      return await api.post(`tickets/emergency-ticket-store-child/${payload.id}`, {
        message: payload.message,
        department: payload.department,
      })
    },
    async getDistrictByAddress({state}, payload) {
      return await api.get(`tickets/get-district-by-address`, {
        params: {
          address: payload
        }
      })
    },
    async getTicketTypeProblems({commit}, payload){
      const res = await api.get('dict/get-emergency-ticket-type-problems')
      commit('setEmergencyTypeProblems', res.data)
    },
    async updateCommentCurrentSituation({commit}, payload){
      const res = await api.put('tickets/update-message-current-situation', payload)
      return res
    },
    // async sendNotificationMessage({commit}, payload) {
    //   const res = await api.get(`tickets/${payload.id}/ticket-send-notification-to-client?title=${payload.title}&body=${payload.body}&notification_types[]=${payload.notification_types}`)
    //   return res
    // },
    async sendNotificationMessage({ commit }, payload) {
      const notificationTypesQuery = payload.notification_types
          .map(type => `notification_types[]=${type}`)
          .join('&');

      const url = `tickets/${payload.id}/ticket-send-notification-to-client?title=${encodeURIComponent(payload.title)}&body=${encodeURIComponent(payload.body)}&${notificationTypesQuery}`;

      const res = await api.get(url);
      return res;
    },
    async changeCallClient({commit}, payload){
      const res = await api.post(`tickets/${payload.id}/is-client-called-change`, {client_id: payload.client_id})
      return res
    },
    async fetchFirstTicketComments({state,commit}, payload){
      const res = await api.get(`tickets/ticket-comments`, {
        params: payload
      })
      commit('setTicketComments', [...res.data.replies.reverse()])
      commit('setTotalComments', res.data.meta.total)
    },
    async sendToBitrix({commit}, payload){
      const res = await api.post(`tickets/${payload.id}/send-to-bitrix`, payload)
      return res
    },
    async removeUserInEmergencyTask(_, payload) {
      const res = await api.delete(`tickets/${payload.ticket_id}/delete-client?client_id=${payload.client_id}`)
      return res
    },
    async downloadServiceTickets({commit}, payload){
      const res = await api.get('tickets/ticket-list', {params: payload, responseType: 'blob'});
      return res
    },
    async fetchTicketsCategories({commit}, payload){
      const res = await api.get('dict/get-ticket-category', {params: payload});
      commit('setTicketsCategories', res.data)
      return res
    },
    async fetchBitrixDepartments({commit}){
      const res = await api.get(`dict/get-bitrix-departments`)
      commit('setBitrixDepartments', res.data)
    },
    async downloadExcelTickets(_, params) {
      const response = await api.get(`tickets/ticket-list`, {
        params,
        responseType: 'blob'
      });
      return response;
    },
    async enrollTicketsToManager({commit}, payload){
      const res = await api.post(`tickets/enroll-tickets-to-manager`, payload)
      return res
    }
  }
}
