<template>
  <div class="modal flex justify-center align-center">
    <div class="modal-overlay" @click="close"></div>
    <div class="modal-container" :class="customClass">
      <img class="modal-close" @click="close" src="/assets/close.svg" alt="" />
      <slot></slot>
    </div>
  </div>
</template>

<script>


export default {
  name: 'CustomModal',
  props: ['customClass'],
  data() {
    return {

    }
  },
  methods: {
    close() {
        this.$emit('close')
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';
.comments-full {
  height: 90%;
  width: 90%;
  padding: 70px;
}
.departure-comment {
  padding: 70px;
  width: 800px;
}
.ticket-detail {
  height: 90%;
  width: 90%;
  overflow: scroll;
  padding: 70px;
}
.modal-container {
  max-height: 90vh;
  position: relative;
  background: white;
  border-radius: 20px;
  z-index: 1001;
}
</style>
