<template>
  <div class="widgets">
    <div class="widgets-item" :class="tab">
      <p class="widgets-item_heading mb-2">{{ tabStyle.title }}</p>
      <custom-table
        :columns="tableColumns"
        :data="tableData"
        :header-style="tabStyle.header"
        :is-pagination="false"
        @get-detail="emitDetail"
      />
    </div>
  </div>
</template>
<script>
import CustomTable from '@/components/modules/CustomTable.vue'
import {WorkTimeColumns} from "@/modules/table-columns";
export default {
  props: {
    tab: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tabStyle: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CustomTable
  },
  computed: {
    tableColumns() {
      return WorkTimeColumns
    },
  },
  methods: {
    emitDetail(detail) {
      this.$emit("get-detail", detail.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.widgets {
  &-item {
    &.service {
      color: #159E8E;
    }
    &.emergency {
      color: #F77D82;
    }
    &.plan {
      color: #04567B;
    }
    &_heading {
      font-size: 30px;
      line-height: 40px;
    }
  }
}
</style>
