<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="details.ticket">
      <div class="flex justify-between mb-2">
        <div class="flex align-center">
          <h2 class="text-black2 mr-2"><b>{{ details.ticket.title }} #{{details?.ticket?.ticket_number}}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="details.ticket?.status_internal"
               @click="toggleAddressModal">
            <img
                :src="details.ticket?.status_internal.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
                alt="">
            {{ details.ticket?.status_internal.title }}
          </div>
        </div>
        <div>
          <img class="block cursor-pointer" @click="$router.go(-1)" src="/assets/close.svg" alt=""/>
        </div>
      </div>
      <div class="w-100 flex justify-between">
        <div class="flex column mb-4">
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Создана: </p>
            <p class="text-14 text-black2" v-if="details.ticket.created_at">
              {{ details.ticket.created_at }}</p>
          </div>
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Родительская заявка: </p>
            <div v-for="related in details.ticket?.related_connection_tickets" :key="related.id">
              <p class="text-20 text-black" @click="onParentTickets(related)">
                <span class="text-20 link cursor-pointer">#{{related.ticket_number}}</span></p>
            </div>
          </div>
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Адрес: </p>
            <p class="text-14 text-black2" @click="toggleAddressModal">
              {{ details.ticket.address || ' - ' }}</p>
          </div>
        </div>
      </div>
      <div class="w-100 ticket-kind text-center text-black2 mb-2">
        Заявка на доработку подключения
      </div>
      <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" @tab-click="changeTab"/>
      <div>
        <div class="info-block" v-if="activeTab === 'info'">
          <div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Причина:</p>
              <pre v-html="details?.ticket?.message" class="text-gray5 text-right text-14"></pre>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Наименование компании:</p>
              <p class="text-gray5 text-right text-14">
                {{ connectionDetails?.connection_ticket?.connection_client?.name || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">БИН:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">
                  {{ connectionDetails?.connection_ticket?.connection_client?.iin || ' - ' }}</p>
                <img class="ml-1 cursor-pointer"
                     @click="copyToClipboard(connectionDetails?.connection_ticket?.connection_client?.iin)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">№ Договора:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.contract_number || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Контактное лицо:</p>
              <p class="text-gray5 text-right text-14">
                {{ connectionDetails?.connection_ticket?.connection_client?.contact_person || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Контактный номер:</p>
              <p class="text-gray5 text-right text-14">
                {{ connectionDetails?.connection_ticket?.connection_client?.phone_number || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Старый адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{ connectionDetails?.connection_ticket?.old_address || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" src="/assets/MapPinLine.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Новый адрес подключения:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{ connectionDetails?.connection_ticket?.address.address || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="toggleAddressModal" src="/assets/MapPinLine.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Координаты подключения:</p>
              <div class="text-gray5 text-right text-14">
                <div>{{ connectionDetails?.connection_ticket?.address?.latitude || ' - ' }}</div>
                <div>{{ connectionDetails?.connection_ticket?.address?.longitude || ' - ' }}</div>
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Источник:</p>
              <p class="text-gray5 text-right text-14">
                {{ connectionDetails?.connection_ticket?.connection_source?.title || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Id процесса:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.bp_id || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">ID сделки:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.deal_id || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Подключение от компании:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.contractor || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Менеджер, ответственный за процесс:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.manager?.full_name || ' - ' }}</p>
            </div>
            <div class="mb-2">Сведения об услугах</div>
            <div class="card bg-white rounded"
                 v-if="details?.connection_ticket?.services && details?.connection_ticket?.services.length">
              <div v-for="(service, index) in details?.connection_ticket?.services" :key="service?.id"
                   class="flex" style="gap: 40px;padding: 4px;">
                <div class="flex w-50">
                  <p class="text-gray5 mr-1">{{ index + 1 }})</p>
                  <div class="flex gap-1">
                    <p class="text-black bold">Название услуги:</p>
                    <p class="text-gray5 ">{{ service?.title || ' - ' }}</p>
                  </div>
                </div>
                <div class="flex gap-1 w-30">
                  <p class="text-black bold">Тип услуги:</p>
                  <p class="text-gray5 ">{{ service?.type || ' - ' }}</p>
                </div>
                <div class="flex gap-1 w-30">
                  <p class="text-black bold">Стоимость:</p>
                  <p class="text-gray5 ">{{ service?.price || ' - ' }}</p>
                </div>
                <div class="flex gap-1">
                  <p class="text-black bold">Количество:</p>
                  <p class="text-gray5">{{ service?.pivot.quantity || ' - ' }}</p>
                </div>
              </div>
            </div>
            <div v-else class="card bg-white rounded flex align-center text-gray5 text-right text-14">Отсутствует</div>
          </div>
        </div>
        <div class="info-block" v-if="activeTab === 'tech'">
          <div>
            <div class="card bg-white rounded flex align-center justify-between"
                 v-if="connectionDetails.connection_ticket?.ip_address">
              <p class="text-black bold text-14">IP адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails.connection_ticket?.ip_address }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails.connection_ticket.ip_address)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.connection_type?.type">
              <p class="text-black bold text-14">Метод подключения:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.connection_type?.type }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.gateway">
              <p class="text-black bold text-14">Gateway:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.gateway}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.dns1">
              <p class="text-black bold text-14">DNS1:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.dns1}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.dns2">
              <p class="text-black bold text-14">DNS2:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.dns2 }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.mask">
              <p class="text-black bold text-14">Mask:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.mask }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.node_name">
              <p class="text-black bold text-14">Узел подключения:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.node_name }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.qinq_vlan">
              <p class="text-black bold text-14">QINQ VLAN:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.qinq_vlan }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.commutator">
              <p class="text-black bold text-14">Коммутатор:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.commutator }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.port">
              <p class="text-black bold text-14">Порт:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.port }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.equipment">
              <p class="text-black bold text-14">Необходимое оборудование:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.equipment }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.login_ppoe">
              <p class="text-black bold text-14">Логин PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.login_ppoe }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails?.connection_ticket?.login_ppoe)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.password_ppoe">
              <p class="text-black bold text-14">Пароль PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.password_ppoe }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails?.connection_ticket?.password_ppoe)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.sip_number">
              <p class="text-black bold text-14">SIP номер:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.sip_number }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails?.connection_ticket?.sip_number)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.login_sip">
              <p class="text-black bold text-14">Login SIP:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.login_sip }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails?.connection_ticket?.login_sip)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.password_sip">
              <p class="text-black bold text-14">Password SIP:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.password_sip }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(connectionDetails?.connection_ticket?.password_sip)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.deal_id">
              <p class="text-black bold text-14">Запрос ТВ ссылка:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.deal_id }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="connectionDetails?.connection_ticket?.router_sks">
              <p class="text-black bold text-14">Роутер:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.router_sks }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if=" connectionDetails?.connection_ticket?.sks">
              <p class="text-black bold text-14">СКС:</p>
              <p class="text-gray5 text-right text-14">{{ connectionDetails?.connection_ticket?.sks }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details?.connection_ticket?.tv_ticket?.id">
              <p class="text-black bold text-14">Запрос на ТВ</p>
              <p class="text-gray5 text-right text-14 link" @click="goToTV(details.connection_ticket.tv_ticket.id)">{{details?.connection_ticket?.tv_ticket?.id}}</p>
            </div>
          </div>
        </div>
        <comments-tab
            v-if="activeTab === 'comment'"
            :comments="ticketComments"
            :totalComments="totalComments"
            @addComment="addComment"
            @loadComments="loadComments"
            @openImage="toggleImageModal"
        />
        <history-tab
            v-if="activeTab === 'history'"
            :history="details.logs"
        />
      </div>
    </div>
    <div class="actions flex flex-wrap">
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.status_internal.id !== 26"
              @click="toggleDepartmentModal()">
        <img src="/assets/statuses/info.svg" class="mr-1" alt="info"/>
        Переназначить
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.ticket.status_internal.id !== 26"
              @click="isBrigadeAssignModal = !isBrigadeAssignModal"><span
          v-if="user.department_id === 108">На выезд</span><span v-else>Назначить на бригаду</span>
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info"
              @click="isEmployeAssignModal = !isEmployeAssignModal">Назначить на сотрудника
      </button>
      <div v-for="item in details.buttons" :key="item.id">
        <button v-if="item.key !== 'problem'" class="actions-buttons mr-2" :class="'actions-buttons_'+item.key"
                @click="changeStatus(item)">
          <img
              :src="`/assets/statuses/${item.key === 'waiting' || item.key === 'complete' || item.key === 'emergency' || item.key === 'departure' ? item.key : 'info'}.svg`"
              class="mr-1" :alt="item.key"/>
          {{ item.title }}
        </button>
      </div>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
    <custom-modal v-if="isAddressModal" @close="toggleAddressModal">
      <map-modal title="АДРЕС ПОДКЛЮЧЕНИЯ" :latitude="details.ticket.latitude"
                 :longitude="details.ticket.longitude" @close="toggleAddressModal"/>
    </custom-modal>
    <custom-modal v-if="isSetDepartmentModal" @close="toggleDepartmentModal">
      <set-department-modal @submit="onChangeDepartment"/>
    </custom-modal>
    <custom-modal v-if="isEmployeAssignModal" @close="isEmployeAssignModal = !isEmployeAssignModal">
      <EmployeAssignModal @submit="assignEmploye"></EmployeAssignModal>
    </custom-modal>
    <custom-modal v-if="isBrigadeAssignModal" @close="isBrigadeAssignModal = !isBrigadeAssignModal">
      <BrigadeAssignModal @submit="assignBrigade"></BrigadeAssignModal>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import HistoryTab from "@/components/tabs/HistoryTab.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import MapModal from "@/components/modals/MapModal.vue";
import SetDepartmentModal from "@/components/modals/SetDepartmentModal.vue";
import EmployeAssignModal from "@/components/modals/EmployeAssignModal.vue";
import BrigadeAssignModal from "@/components/modals/brigade/BrigadeAssignModal.vue";

export default {
  name: 'ConnectionImprovementsTicketDetailsComponent',
  components: {
    BrigadeAssignModal,
    EmployeAssignModal,
    SetDepartmentModal,
    MapModal,
    TabsComponent,
    HistoryTab,
    CommentsTab,
    ImageModal,
    CustomModal,
  },
  props: [],
  data() {
    return {
      isSetDepartmentModal: false,
      isEmployeAssignModal: false,
      isBrigadeAssignModal: false,
      tabs: [
        {
          title: 'Инфо',
          icon: '/assets/tabs/info-gray.svg',
          icon_active: '/assets/tabs/info-white.svg',
          key: 'info'
        },
        {
          title: 'тех.данные',
          icon: '/assets/tabs/wrench-gray.svg',
          icon_active: '/assets/tabs/wrench-white.svg',
          key: 'tech'
        },
        {
          title: 'Комментарии',
          icon: '/assets/tabs/comment-gray.svg',
          icon_active: '/assets/tabs/comment-white.svg',
          key: 'comment'
        },
        {
          title: 'ИСТОРИЯ',
          icon: '/assets/tabs/clock-gray.svg',
          icon_active: '/assets/tabs/clock-white.svg',
          key: 'history'
        },
      ],
      activeTab: 'info',
      image: '',
      isAddressModal: false,
    }
  },
  computed: {
    ...mapGetters({
      details: "chosenTicket",
      currentCommentPage: 'currentCommentPage',
      ticketComments: 'ticketComments',
      totalComments: 'totalComments',
      user: 'user',
      connectionDetails: "chosenConnectionTicket",
    }),
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'ticketDetail',
      fetchTicketComments: 'fetchTicketComments',
      addTicketComments: 'addTicketComments',
      error: 'errorAlert',
      success: 'successAlert',
      fetchDepartments: 'fetchDepartments',
      setDepartment: 'setDepartment',
      changeTicketStatus: 'changeTicketStatus',
      fetchConnectionTicketDetail: 'fetchConnectionTicketDetail',
      getRelatedTickets: 'getRelatedTickets',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
    }),
    changeTab(tab) {
      this.activeTab = tab
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({ticket_id: this.details.ticket.id, page: this.currentCommentPage})
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    toggleAddressModal() {
      this.isAddressModal = !this.isAddressModal
    },
    addComment(comment) {
      comment.ticket_id = this.details.ticket.id
      this.addTicketComments(comment)
    },
    toggleDepartmentModal() {
      if (!this.isSetDepartmentModal) {
        this.fetchDepartments({category_key: this.details.ticket.category.key})
      }
      this.isSetDepartmentModal = !this.isSetDepartmentModal
    },
    onChangeDepartment(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'department',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) this.error(res.message)
        this.toggleDepartmentModal()
        this.setLoading(false)
      })
    },
    async assignEmploye(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'manager',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) {
          this.error(res.message)
        } else {
          this.success(res.message)
          this.isEmployeAssignModal = !this.isEmployeAssignModal
          this.setLoading(false)
        }
      })
    },
    async changeStatus(status) {
      this.setLoading(true)
      const payload = {
        ticket_id: this.details.ticket.id,
        status_id: status.id,
        responsible_type: status.responsible_type,
        responsible_id: status.responsible_id
      }
      await this.changeTicketStatus(payload)
      await this.fetchTicketDetail(this.details.ticket.id)
      this.setLoading(false)
    },
    onParentTickets(ticket) {
      this.getRelatedTickets(ticket.id)

      if (ticket.type.key === 'connection') {
        this.$router.push(`/connection/${ticket.id}`);
      } else if (ticket.type.key === 'add-sale') {
        this.$router.push(`/upsells/${ticket.id}`)
      } else if (ticket.type.key === 'dis-assembly'){
        this.$router.push(`/disassembly/${ticket.id}`)
      } else if (ticket.type.key === 'transposition'){
        this.$router.push(`/services-transfer/${ticket.id}`)
      } else {
        this.$router.push(`/connection/${ticket.id}`)
      }
    },
    async assignBrigade(id) {
      const payload = {
        ticket_id: this.details.ticket.id,
        responsible_type: 'brigade',
        responsible_id: id
      }
      this.setLoading(true)
      this.setDepartment(payload).then(res => {
        if (!res.status) {
          this.error(res.message)
        } else {
          this.success(res.message)
          this.isBrigadeAssignModal = !this.isBrigadeAssignModal
          this.setLoading(false)
        }
      })
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    goToTV(id){
      this.$router.push(`/queries-technical-capability/${id}`)
    }
  },
  async mounted() {
    await this.fetchConnectionTicketDetail(this.details.ticket?.related_connection_tickets[0].id)
  }
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  border-radius: 15px;
  background: $gray4;
  padding: 20px;


  & > div {
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;

    .images {
      img {
        width: 50px;
        height: 50px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > p:first-child {
      width: 35%;
    }
  }
}

.option {
  color: $black2;
  height: 58px;

  &.active,
  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}

.actions {
  margin-top: 28px;

  &-buttons {
    background: #04567B;
    color: white;
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 25px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_info {
      background: #04567B;
    }

    &_departure {
      background: #57C2DC;
    }

    &_waiting {
      background: #0095FF;
    }

    &_emergency {
      background: #FF6700;
    }

    &_complete {
      background: #15AD37;
    }
  }
}

.fiber_window {
  position: absolute;
  right: 0;
  z-index: 2;
  bottom: -80px;
  border: 1px solid gray;
  border-radius: 4px;
  filter: drop-shadow(2px 2px 2px #000000);
  background: white;
}

.fiber_type {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid gray;
  width: 200px;

  &:hover {
    background: gray;
    transition: 200ms;
  }
}
</style>
