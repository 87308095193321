<template>
  <div v-if="department">
    <label class="department-title">{{ department.department_name }} - {{ department.ticket_count }}</label>
    <div class="progress-container">
      <label class="progress-percentage">
        {{ Math.round((department.ticket_count / totalCount) * 100) }}%
      </label>
      <div class="progress-bar">
        <div class="progress-fill"
             :style="{ width: Math.round(department.ticket_count / totalCount) * 100 + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClosedTicketsByDepartmentComponent",
  props: {
    totalCount: {
      type: Number,
      default: 0
    },
    department: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped lang="scss">
.department-title {
  color: #888B94;
  font-size: 16px;
}

.progress-container {
  margin-bottom: 1rem;
  margin-top: 5px;

  .progress-percentage {
    font-weight: bold;
    font-size: 16px;
  }

  .progress-bar {
    width: 100%;
    background-color: #EAEAEA;
    border-radius: 100px;
    height: 5px;

    .progress-fill {
      background: linear-gradient(to right, #E04C5A, #FF4053);
      height: 5px;
      border-radius: 9999px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 0.5rem;
      padding-top: 3px;
    }
  }
}
</style>