import {api} from '@/service/axios'
import axios from "axios";

export const clientModule = {
    state: () => ({
        client: {},
        connectionTickets: [],
        isClientModal: false,
        isClientDashboardModal: false,
        clientDashboardFilters: {
            vgroup_id: null,
            date_from: null,
            date_to: null
        },
        clientAgreements: [],
        clientDocuments: [],
        clientEquipments: [],
        clientEquipmentList: [],
        clientVGroups: [],
        managerPhones: null,
    }),
    getters: {
        client(state) {
            return state.client
        },
        clientAgreements(state) {
            return state.clientAgreements
        },
        clientDocuments(state) {
            return state.clientDocuments
        },
        clientEquipments(state) {
            return state.clientEquipments
        },
        clientEquipmentList(state) {
            return state.clientEquipmentList
        },
        isClientModal(state) {
            return state.isClientModal
        },
        isClientDashboardModal(state) {
            return state.isClientDashboardModal
        },
        clientVGroups(state) {
            return state.clientVGroups
        },
        clientDashboardFilters(state) {
            return state.clientDashboardFilters
        },
        managerPhones(state) {
            return state.managerPhones
        },
        connectionTickets(state){
            return state.connectionTickets
        }
    },
    mutations: {
        setClient(state, payload) {
            state.client = payload
        },
        setClientAgreements(state, payload) {
            state.clientAgreements = payload
        },
        setClientDocuments(state, payload) {
            state.clientDocuments = payload
        },
        setClientEquipments(state, payload) {
            state.clientEquipments = payload
        },
        setClientEquipmentList(state, payload) {
            state.clientEquipmentList = payload
        },
        setIsClientModal(state, payload) {
            state.isClientModal = payload
        },
        setIsClientDashboardModal(state, payload) {
            state.isClientDashboardModal = payload
        },
        setClientVGroups(state, payload) {
            state.clientVGroups = payload
        },
        setClientDashboard(state, payload) {
            state.client.dashboard = payload
        },
        setClientDashboardFilters(state, payload) {
            state.clientDashboardFilters = payload
        },
        setManagerPhoneNumbers(state, payload) {
            state.managerPhones = payload
        },
        setConnectionTickets(state, payload){
            state.connectionTickets = payload
        }
    },
    actions: {
        async fetchClientData({commit}, payload) {
            const res = await api.get(`clients/client-data?lanbilling_id=${payload}`)
            commit('setClient', res.data.client)
            commit('setConnectionTickets', res.data.connection_tickets)
        },
        async fetchClientSingle({commit}, payload) {
            return await api.get(`clients/client-single?vg_id=${payload}`)
        },
        async fetchClientAgreements({commit}, payload) {
            const res = await api.get(`clients/client-agreement?lanbilling_id=${payload}`)
            commit('setClientAgreements', res.data)
        },
        async fetchClientDocuments({commit}, payload) {
            const res = await api.get(`clients/client-document?lanbilling_id=${payload}`)
            commit('setClientDocuments', res.data)
        },
        async fetchClientEquipments({commit}, payload) {
            const res = await api.get(`clients/client-equipment?lanbilling_id=${payload}`)
            commit('setClientEquipments', res.data)
        },
        async fetchClientEquipmentList({commit}, payload) {
            const res = await api.get(`connection-tickets/${payload}/equipment-list`)
            commit('setClientEquipmentList', res.data)
        },
        async fetchClientFullData({commit}, payload) {
            const res = await api.get(`clients/client-full-data?lanbilling_id=${payload}`)
            // commit('setClientDocuments', res.data)
        },
        async fetchClientDashboard({commit}, payload) {
            const res = await api.get(`clients/dashboard`, {params: payload})
            commit('setClientDashboard', res.data)
        },
        async fetchClientVGroups({commit}, payload) {
            const res = await api.get(`clients/vgroup-list`, {params: payload})
            commit('setClientVGroups', res.data)
        },
        async fetchManagerPhoneNumbers({commit}, payload) {
            const res = await api.get(`managers/get-manager-data?manager_id=${payload}`)
            commit('setManagerPhoneNumbers', res.data.data)
        },
        async fetchWazzup({commit}, payload) {
            const data = {
                user: {
                    id: '45999842',
                    name: 'Test NLS'
                },
                scope: 'global'
            }
            const users = [{
                id: '45999842',
                name: 'Test NLS',
                phone: '77079117021'
            }]
            const channel = {
                channelId: '7055e6b2-bd30-4e42-a1a1-59f62b8574e4',
                name: "77086416754",
                plainId: "77086416754",
                state: "active",
                transport: "whatsapp",
            }
            const res = await axios.post('https://api.wazzup24.com/v3/iframe',data, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer 427b9873f1c8416c80887bcbaada3c18'}})
            // const res = await axios.post('https://api.wazzup24.com/v3/users',users, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer 427b9873f1c8416c80887bcbaada3c18'}})
            // const res = await axios.get('https://api.wazzup24.com/v3/channels', {
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer d463340621af494f9b30709f24bf088e'
            //     }
            // })
            // const res = await axios.get('https://api.wazzup24.com/v3/users', {
            //         headers: {
            //             'Content-Type': 'application/json',
            //             'Authorization': 'Bearer 427b9873f1c8416c80887bcbaada3c18'
            //         }
            //     })
            return res
        }
    }
}
