<template>
  <div class="wrapper modified flex column bg">
    <div class="bg-white p-20 border-radius-20">
      <ConnectionFilters
        v-model:search="search"
        v-model:dateRange="dateRange"
        :date_from="date_from"
        :date_to="date_to"
        v-model:area_id="area_id"
        v-model:status_ids="status_ids"
        v-model:responsible_id="responsible_id"
        v-model:brigade_id="brigade_id"
        :connection_department="connection_department"
        :responsible_dep="params.responsible_dep"
        :areas="areas"
        :departureStatuses="departureStatuses"
        :departureManagers="departureManagers"
        :brigades="brigades"
        @setDate="setDate"
        @clearBtn="clearBtn"
        @departmentFilter="departmentFilter"
        @saveFilters="saveFilters"
        @resetFilters="resetFilters"
        @update:search="handleSearchUpdate"
      />

      <div class="flex mt-2 gap-2">
        <div class="relative flex justify-between input-form">
          <div>Закрытые за данный период</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.period.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('period', $event)"
                 @mouseleave="hideTooltip('period')"
            >
            <div style="color: #FFB020">{{ finishedCount.period }}</div>
          </div>
          <div v-if="isTooltipWithPeriod" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.period" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
        <div class="relative flex justify-between input-form">
          <div>Закрытые за сегодня</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.today.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('today', $event)"
                 @mouseleave="hideTooltip('today')"
            >
            <div style="color: #1ED3FF">{{ finishedCount.today }}</div>
          </div>
          <div v-if="isTooltipWithToday" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.today" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
        <div class="relative flex justify-between input-form">
          <div>Закрытые за вчера</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.yesterday.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('yesterday', $event)"
                 @mouseleave="hideTooltip('yesterday')"
            >
            <div style="color: #9F53FF">{{ finishedCount.yesterday }}</div>
          </div>
          <div v-if="isTooltipWithYesterday" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.yesterday" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 flex justify-center rounded mt-2 p-20" style="background: #00bfff;font-weight: 500">
        <p class="">План подключений по задачам {{ showData }}, записей
          {{ connectionList?.meta?.total }}</p>
      </div>


      <div class="flex justify-between items-center mt-2">
        <div class="w-100 flex" style="align-items: end">
          <div
              class="ticket-menu flex justify-center align-center cursor-pointer mr-2 navs"
              v-for="menu in menuMainArray"
              :key="menu.key"
              :class="{'active': currentTab === menu.key}"
              @click="changeTab(menu)"
          >
            {{ menu.title }}
          </div>


          <div class="mt flex flex-wrap" style="margin-left:auto;justify-content: flex-end;align-items: end">
            <div>
              <button class="ticket-menu flex justify-center gap-1 align-center cursor-pointer mr-2 navs"
                      style="background: #2ab161;color: white;" @click="downloadExcel">
                <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
                <div>Выгрузить</div>
              </button>
            </div>
            <div style="width: 200px;">
              <div class="mb-1">Показывать по</div>
              <AppSelect
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  @change="onPerPageChange"
              />
            </div>
          </div>
        </div>


      </div>


      <div class="content w-100 mt-4 flex  column">
        <div class="grid gridText" style="">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-id" style="padding:4px 10px">
            <p class="text-center">ID</p>
            <p class="text-center" style="color: #3a94af">bitrix_task_id</p>
          </div>
          <div class="grid-Name flex align-center justify-center">
            <p class="text-center">Наименование</p>
          </div>
          <div class="grid-Address flex align-center cursor-pointer" @click="onSortData('address')">
            <div class="flex justify-center w-100">
              <p class="text-center">Адрес</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
          </div>
          <div class="grid-Creator flex align-center justify-center">
            <p class="text-center">Создал</p>
          </div>
          <div class="grid-Responsible flex align-center justify-center">
            <p class="text-center">Ответственный</p>
          </div>
          <div class="grid-CreatedData flex align-center cursor-pointer" @click="onSortData('created_at')">
            <div class="flex justify-center w-100">
              <p class="text-center">Дата создания</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
          </div>
          <div class="grid-Brigade cursor-pointer flex align-center justify-center" @click="sortByBrigade">
            <p class="text-center">Бригада</p>
          </div>
          <div class="grid-Status flex align-center cursor-pointer" @click="onSortData('status_id')">
            <div class="flex justify-center w-100">
            <p class="text-center">Статус</p>
            </div>
            <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
          </div>
          <div class="grid-Plan flex align-center justify-center">
            <p class="text-center">План дата подключения</p>
          </div>
          <div class="grid-EndDate flex align-center justify-center">
            <p class="text-center">Дата завершения</p>
          </div>
          <div class="grid-Options flex align-center justify-center">
            <p class="text-center">Управление</p>
          </div>
        </div>
        <div class="departure-table" v-if="sortedData">
          <div class="grid gridText rounded" :id="list.id" @click="onTableClick(list.id)"
               v-for="(list, index) in sortedData" :key="list.id"
               :style="{ background: list.brigade_color ? list.brigade_color : '' }">
            <div class="grid-num"> {{ index + 1 }}</div>
            <div class="grid-id flex justify-center items-center">
              <div style="padding: 2px 4px">
                <p @click="getConnectionInfo(list.id)" class="text-center" style="cursor: pointer">{{
                    list.ticket_number
                  }}</p>
                <a :href="list.bitrix_task_link" class="text-center link" style="color: #3a94af;cursor: pointer"
                   target="_blank">{{ list.bitrix_task_id }}</a>
              </div>
            </div>
            <div class="grid-Name flex align-center" @click="openClientInfo(list)"><p class="">
              {{ list.connection_client.name }}</p></div>
            <div class="grid-Address flex align-center">
              <div>{{ list.address }}</div>
            </div>
            <div class="grid-Creator flex align-center justify-center">
              <div v-if="list.manager" class="cursor-pointer" @click="openManagerCard(list.manager.id)">
                {{ list.manager?.full_name ? list.manager.full_name : 'Не указано' }}
                {{ list.manager.department?.title ? `(${list.manager.department.title})` : '(Отдел не указан)' }}
              </div>
              <div v-else>
                Не указано
              </div>
            </div>
            <div
                :class="list.status?.id === 17 ? 'grid-Responsible grid-closed gap-1 flex justify-between align-center' : 'grid-Responsible cursor-pointer gap-1 flex justify-between align-center'"
                @click="openChangeResponsible(list)">
              <div v-if="list.responsible.title">
                <div :class="list.status?.id === 17 ? '' : 'grid-blue'">
                  <div>{{ list.responsible?.title }}</div>
                  <div v-if="list.responsible.department">({{ list.responsible?.department?.title }})</div>
                </div>
              </div>
              <div v-else :class="list.status?.id === 17 ? '' : 'grid-blue'">
                Не указано
              </div>
              <div v-if="list.status?.id !== 17">
                <img src="/assets/edit-button-blue.svg"/>
              </div>
            </div>
            <div class="grid-Brigade flex align-center">
              <span class="mr-1">{{
                  list.brigade_title ? list.brigade_title : 'Не указано'
                }}</span>
              <!--              <img src="/assets/edit-button-blue.svg"/>-->
            </div>
            <div class="grid-Status flex justify-center text-center align-center">
              <p>{{ list.status?.title }}</p>
            </div>
            <div class="grid-CreatedData flex align-center">{{ list?.connection_status_date || ' - ' }}</div>

            <div class="grid-Plan flex align-center" @click="openChangeDate(list.id)">
              <span class="mr-1" style="color: #2A5298">{{ list.planned_date }}</span>
              <img src="/assets/edit-button-blue.svg"/>
            </div>
            <div class="grid-EndDate flex align-center text-center">{{ list.finished_at || "Не указано" }}</div>
            <div class="brigade-options relative" style="padding: 0 2px;">
              <img src="/assets/logs-icon.svg" alt="" width="22" height="22" title="История" @click="openLogs(list.id)">
              <img src="/assets/list.svg" alt="edit" width="20" height="20" title="Информация"
                   @click="openConnectionInfo(list.id)">
              <img src="/assets/locationpin.svg" alt="location" width="24" height="24" title="Смена сооружения"
                   @click="openOrk(list.id)"/>
              <img src="/assets/comment.svg" alt="comment" width="24" height="24" title="Комментарии"
                   @click="openComments(list.id)"/>
              <img src="/assets/plus-gray.svg" alt="create" width="24" height="24" title="Создание связанной задачи"
                   @click="openToggle(list.id, list)"/>
              <ToggleDropdown class="toggle-dropdown" v-if="selectedId === list.id"
                              @close="selectedId = null"
                              :rowItem="list"
                              @create-lcs="(data) => openCreateLCSTicket(data)"
                              @create-access="(data) => openCreateAccessTicket(data)"/>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent :total-pages="totalPages" :current-page="page" @page-change="onPageChange"></PaginationComponent>
    </div>
  </div>
  <custom-modal v-if="isManagerInfoModal" @close="isManagerInfoModal = !isManagerInfoModal">
    <manager-info-modal></manager-info-modal>
  </custom-modal>
  <custom-modal v-if="isPlanDate" @close="isPlanDate = !isPlanDate">
    <departure-connection-plan-date @closeDate="isPlanDate = false" :id="currentId" :type="'connection'" :params="params">
    </departure-connection-plan-date>
  </custom-modal>
  <custom-modal v-if="isBrigadeModal" @close="isBrigadeModal = !isBrigadeModal">
    <departure-brigade-modal :brigade-id="currentBrigadeId" :ticket_id="currentId" @close="isBrigadeModal = !isBrigadeModal">
    </departure-brigade-modal>
  </custom-modal>
  <custom-modal v-if="isConnectionInfoModal" @close="isConnectionInfoModal = !isConnectionInfoModal">
    <departure-connection-info-modal :id="currentId">
    </departure-connection-info-modal>
  </custom-modal>
  <custom-modal v-if="isChangeResponsibleModal" @close="isChangeResponsibleModal = !isChangeResponsibleModal">
    <departure-change-responsible :id="currentId" @close="isChangeResponsibleModal = !isChangeResponsibleModal" :params="params" :type="'connection'">
    </departure-change-responsible>
  </custom-modal>
  <custom-modal v-if="isOrkModal" @close="isOrkModal = !isOrkModal">
    <departure-opk-modal
        :coordinates="{latitude: parseFloat(connectionDepartureTicket.connection_ticket.address.latitude), longitude: parseFloat(connectionDepartureTicket.connection_ticket.address.longitude), ork_id: connectionDepartureTicket.connection_ticket.ork_id}"
        :ticket-id="connectionDepartureTicket.connection_ticket.id" @close="isOrkModal = !isOrkModal"
        @success="orkChanged" :type="'b2b'"></departure-opk-modal>
  </custom-modal>
  <custom-modal custom-class="departure-comment" v-if="isCommentsModal" @close="isCommentsModal = !isCommentsModal">
    <comments-tab :comments="ticketComments" :totalComments="totalComments" @addComment="addComment" @loadComments="loadComments">
    </comments-tab>
  </custom-modal>
  <custom-modal v-if="isDepartureLogsModal" @close="isDepartureLogsModal = !isDepartureLogsModal">
    <departure-logs-modal :id="currentId" :type="'connection'" :ticket="connectionDepartureTicket?.logs">
    </departure-logs-modal>
  </custom-modal>
  <custom-modal v-if="isShowCreateLCSTicket" @close="isShowCreateLCSTicket = !isShowCreateLCSTicket">
    <CreateTicketLCSFormView :parent-ticket-id="+selectedId" :ticket="selectedTicket" :type="'connection'">
    </CreateTicketLCSFormView>
  </custom-modal>
  <custom-modal v-if="isShowCreateAccessTicket" @close="isShowCreateAccessTicket = !isShowCreateAccessTicket">
    <access-form-view :parent-ticket-id="+selectedId" :ticket="selectedTicket" :type="'connection'"></access-form-view>
  </custom-modal>
  <custom-modal v-if="isShowCreateSubtask" @close="isShowCreateSubtask = !isShowCreateSubtask">
    <create-subtask-modal @close="isShowCreateSubtask = !isShowCreateSubtask"></create-subtask-modal>
  </custom-modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppSelect from "@/components/ui/AppSelect.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import ManagerInfoModal from "@/components/modals/ManagerInfoModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import DepartureConnectionPlanDate from "@/components/modals/departureB2c/DepartureConnectionPlanDate.vue";
import DepartureBrigadeModal from "@/components/modals/departureB2c/DepartureBrigadeModal.vue";
import DepartureConnectionInfoModal from "@/components/modals/departureB2c/DepartureConnectionInfoModal.vue";
import DepartureChangeResponsible from "@/components/modals/departureB2c/DepartureChangeResponsible.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import DepartureLogsModal from "@/components/modals/departureB2c/DepartureLogsModal.vue";
import DepartureOpkModal from "@/components/modals/departureB2c/DepartureOpkModal.vue";
import ClosedTicketsByDepartmentComponent from "@/components/cards/departure/ClosedTicketsByDepartmentComponent.vue";
import CreateTicketLCSFormView from "@/views/ticketsLCS/CreateTicketLCSFormView.vue";
import AccessFormView from "@/views/accessData/AccessFormView.vue";
import CreateSubtaskModal from "@/components/modals/subtasks/CreateSubtaskModal.vue";
import ToggleDropdown from "@/components/ui/ToggleDropdown.vue";
import ConnectionFilters from "@/components/filters/ConnectionFilters.vue";
import connectionViewMixin from "@/mixins/connectionViewMixin";

export default {
  name: "ServiceTransfersB2B",
  mixins: [connectionViewMixin],
  components: {
    ConnectionFilters,
    AppSelect,
    PaginationComponent,
    ManagerInfoModal,
    CustomModal,
    DepartureConnectionPlanDate,
    DepartureBrigadeModal,
    DepartureConnectionInfoModal,
    DepartureChangeResponsible,
    CommentsTab,
    DepartureLogsModal,
    DepartureOpkModal,
    ClosedTicketsByDepartmentComponent,
    CreateTicketLCSFormView,
    AccessFormView,
    CreateSubtaskModal,
    ToggleDropdown
  },
  computed: {
    ...mapGetters({
      connectionList: 'connectionList',
      connectionStatuses: 'connectionStatuses',
      finishedCount: 'finishedCount',
      user: 'user',
      managers: 'managers',
      connectionDepartureTicket: 'connectionDepartureTicket',
      currentCommentPage: 'currentConnectionTicketCommentsPage',
      ticketComments: 'connectionTicketComments',
      totalComments: 'totalConnectionTicketComments',
      departureFilters: 'departureFilters',
      departureManagers: 'departureManagers',
      brigades: 'brigadeByDepartment',
      departureStatuses: 'departureStatuses',
      departureRoles: 'departureRoles',
      connectionInfo: 'connectionInfo',
      closedConnectionTickets: 'closedConnectionTickets',
      areas: 'areas',
    }),
    totalPages() {
      return Math.ceil((this.connectionList?.meta?.total || 0) / this.perPage);
    },
    sortedData() {
      if (this.sortedValue) {
      return this.sortCalc(this.sortedValue);
      } else {
        return this.connectionList.data;
      }
    },
    showData() {
      if (this.date_from && this.date_to) {
        return `${this.date_from} - ${this.date_to}`;
      } else {
        return 'за Все время'
      }
    },
    allChecked() {
      return this.departureStatuses.every(option => option.checked);
    },
    storageKey() {
      return 'filtersServicesTransferB2B';
    },
    clientType() {
      return 'b2b';
    },
    connectionTicketType() {
      return 'transposition';
    }
  },
  methods: {
    ...mapActions({
      fetchConnectionList: "fetchConnectionList",
      fetchManagerPhones: 'fetchManagerPhoneNumbers',
      fetchConnectionStatuses: 'fetchConnectionStatuses',
      fetchFinishedCount: 'fetchFinishedCount',
      fetchClosedConnectionTickets: 'fetchClosedConnectionTickets',
      fetchConnectionInfo: 'fetchConnectionInfo',
      fetchProductServices: 'fetchProductServices',
      fetchHost: 'fetchHostByNode',
      changeResponsible: 'changeResponsibleDeparture',
      fetchManagers: 'fetchManagers',
      fetchTicket: 'fetchConnectionDepartureTicket',
      addTicketComments: 'addConnectionTicketComments',
      fetchTicketComments: 'fetchConnectionTicketComments',
      fetchConnectionTicketDetail: 'fetchConnectionTicketDetail',
      fetchExcelList: 'fetchExcelList',
      saveRequestFilter: 'saveRequestFilter',
      deleteRequestFilter: 'deleteRequestFilter',
      fetchDepartureManagers: 'fetchDepartureManagers',
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      fetchDepartureStatuses: 'fetchDepartureStatuses',
      fetchDepartureRoles: 'fetchDepartureRoles',
      getAreas: 'getAreas',
      fetchConnectionTypes: 'fetchConnectionTypes',
      getRelatedTickets: 'getRelatedTickets',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
      setIsDepartureClientInfoModal: 'setIsDepartureClientInfoModal',
      setCurrentClientInfo: 'setCurrentClientInfo',
      setDepartureFilters: 'setDepartureFilters',
    })
  }
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';

.selected {
  border: 4px solid black;
}

.grid {
  display: grid;
  gap: 1px;
  align-items: center;
  padding: 1px 0;
  grid-template-columns: minmax(0, 40px) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 3fr) repeat(3, minmax(0, 2.2fr)) repeat(4, minmax(0, 1.5fr)) minmax(0, 1.2fr);
  grid-template-areas: 'Num Id Name Address Creator Responsible Brigade Status CreatedData  Plan EndDate Options';
}

.grid-num {
  border: 1px solid black;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-area: Num;
}

.grid-id {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  align-items: center;
  grid-area: Id;
}

.grid-Name {
  border: 1px solid black;
  border-radius: 10px;
  grid-area: Name;
  padding: 4px 10px;
  height: 100%;
}

.grid-Address {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Address;
}

.grid-Creator {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Creator;
}

.grid-Responsible {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Responsible;
}

.grid-Status {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 4px;
  grid-area: Status;
}

.grid-CreatedData {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 4px;
  grid-area: CreatedData;
}

.grid-Brigade {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Brigade;
}

.grid-Plan {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 2px;
  grid-area: Plan;
}

.grid-EndDate {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 2px;
  grid-area: EndDate;
}

.grid-Options {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  grid-area: Options;
}

.brigade-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.departure-table {
  :hover {
    background-color: #F5F5FA !important;
  }

  .grid {
    &-Creator:hover {
      text-decoration: underline;
      transition: 200ms;
      font-weight: bold;
    }

    &-Plan {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-Responsible {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-Name {
      &:hover {
        cursor: pointer;
        transition: 200ms;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &-id {
      a:hover {
        transition: 200ms;
        font-weight: bold;
      }
    }

    &-closed {
      &:hover {
        cursor: default;
        font-weight: normal;
        text-decoration: none;
      }
    }

    &-blue {
      color: #2A5298
    }
  }
}

.ticket-menu {
  padding: 9px 13px 11px 12px;
  border-radius: 12px;
  background: #FFF;
  color: #888B94;
  border: 1px #F2F1F0 solid;
  font-size: 16px;
  font-style: normal;
  width: 166px;
  font-weight: 600;
  height: 40px;


  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.navs:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: 200ms;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.tooltip {
  position: fixed;
  transform: translateX(-80%);
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: white;
  box-shadow: 0 4px 30px 0 #0000001A;
  color: black;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
  width: 300px;
  white-space: wrap;
}

.toggle-dropdown {
  position: absolute;
  right: 100px;
  top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:hover {
    transform: none;
  }
}

.filter {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 25px !important;
  border: 1px solid #ADADAD !important;
  height: 48px !important;
  margin-top: 8px;
  cursor: pointer;
  font-size: 16px !important;
  color: #ADADAD;
}

.statuses-list {
  position: absolute;
  background-color: white;
  z-index: 10;
  padding: 10px;
  box-shadow: 0 4px 30px 0 #0000001A;
  margin-top: 5px;
  width: 250px;
}

@media only screen and (max-width: 1660px) {
  .gridText {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .gridText {
    font-size: 8px;
  }
}
</style>