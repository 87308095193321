<template>
  <div class="flex justify-between flex-wrap">
    <div
      v-for="item in items"
      :key="item.id"
      class="select-btn flex justify-center align-center uppercase text-center cursor-pointer"
      :class="{ 'active': item.id === selected }"
      @click="itemClickHandle(item.id)"
    >
      {{ item[title] }}
    </div>
  </div>
</template>

<script>


export default {
  name: 'BtnSelect',
  props: ['items', 'selected', 'title'],
  methods: {
    itemClickHandle(id) {
      this.$emit('itemClick', id)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.select-btn {
  width: 193px;
  height: 48px;
  border-radius: 5px;
  background: $gray3;
  font-size: 18px;
  color: $gray2;

  &:hover, &.active {
    color: $white;
    background: $secondary;
  }
}

</style>
