<template>
  <div class="cs-page">
    <div class="cs-page__content">
      <header class="cs-page__header">
        <AppSelect
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            :searchable="false"
            class="cs-page__per-page-select"
            @change="onPerPageChange"
        />
      </header>
      <CustomTable
          :columns="tableColumns"
          :data="csData?.data || []"
          :is-pagination="true"
          :current-page="page"
          :total-pages="totalPages"
          @page-change="onPageChange"
          @sort-column="sortColumn"
          @move-column="moveTableColumn"
      />
    </div>
  </div>
</template>

<script>
import CustomTable from "@/components/modules/CustomTable.vue";
import {CurrentSitTableColumns, TicketsTableColumns} from "@/modules/table-columns";
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppSelect from "@/components/ui/AppSelect.vue";

export default {
  components: {
    CustomTable,
    AppSelect,
  },
  data() {
    return {
      page: 1,
      perPage: 20,
      perPageOptions: [10, 20, 50, 100],
      currentColumnSortParams: {
        value: '',
        order: ''
      },
      tableColumns: [],
    };
  },
  methods: {
    ...mapActions({
      fetchCsData: 'fetchCsData',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    onPageChange(page = 1) {
      this.page = page;
      this.fetchCsDataWrapper();
    },
    onPerPageChange(perPage) {
      this.perPage = perPage;
      this.fetchCsDataWrapper();
    },
    async fetchCsDataWrapper() {
      this.setLoading(true);
      await this.fetchCsData({ page: this.page, limit: this.perPage });
      this.setLoading(false);
    },
    moveTableColumn(columns) {
      this.tableColumns = columns
      window.localStorage.setItem('CurrentSitTableColumns', JSON.stringify(columns))
    },
    sortColumn(column) {
      if (this.currentColumnSortParams.value === column) {
        if (this.currentColumnSortParams.order === 'asc') this.currentColumnSortParams.order = 'desc'
        else if (this.currentColumnSortParams.order === 'desc') {
          this.currentColumnSortParams.order = ''
          this.currentColumnSortParams.value = ''
        }
      }
      else {
        this.currentColumnSortParams.value = column
        this.currentColumnSortParams.order = 'asc'
      }
      this.fetchFilteredTickets()
    },
  },
  computed: {
    ...mapGetters({
      csData: 'getCsData',
    }),
    totalPages() {
      return Math.ceil((this.csData?.total || 0) / this.perPage);
    }
  },
  async mounted() {
    void this.fetchCsDataWrapper();

    if (window.localStorage.getItem('CurrentSitTableColumns')) {
      if (CurrentSitTableColumns.length !== JSON.parse(window.localStorage.getItem('CurrentSitTableColumns')).length) {
        window.localStorage.setItem('CurrentSitTableColumns', JSON.stringify(CurrentSitTableColumns))
        this.tableColumns = CurrentSitTableColumns
      } else {
        this.tableColumns = JSON.parse(window.localStorage.getItem('CurrentSitTableColumns'))
      }
    }
    else this.tableColumns = CurrentSitTableColumns
  },
}
</script>

<style scoped lang="scss">
.cs-page {
  padding: 40px 20px;

  &__header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__per-page-select {
    width: 300px;
  }

  &__content {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
  }
}
</style>
