<template>
  <div class="items-per-page">
    <div class="dropdown-label">Показывать по</div>
    <div class="dropdown">
      <input
        type="text"
        class="input-form select"
        :value="selectedValue"
        @click="toggleDropdown"
        readonly
      />
      <div class="dropdown-controls">
        <img 
          src="/assets/dropdown-toggle.svg" 
          class="dropdown-toggle"
          :class="{ open: isOpen }"
          alt="toggle"
          @click.stop="toggleDropdown"
        />
      </div>
      <div 
        class="dropdown-content" 
        :class="{ show: isOpen }" 
        v-show="isOpen"
      >
        <div
          v-for="option in options"
          :key="option"
          @click="selectOption(option)"
          class="dropdown-item"
          :class="{ active: option === selectedValue }"
        >
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseItemsPerPage',
  props: {
    modelValue: {
      type: Number,
      default: 10
    },
    options: {
      type: Array,
      default: () => [10, 20, 30]
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      selectedValue: this.modelValue
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedValue = newVal
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },
    selectOption(value) {
      this.selectedValue = value
      this.$emit('update:modelValue', value)
      this.isOpen = false
    },
    handleClickOutside(event) {
      const dropdown = this.$el
      if (!dropdown.contains(event.target)) {
        this.isOpen = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped lang="scss">
.items-per-page {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 174px;
}

.dropdown-label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
}

.dropdown {
  position: relative;
  width: 100%;

  .input-form {
    background: #F2F1F0 !important;
    border: none !important;
    border-radius: 12px !important;
    height: 44px !important;
    width: 100%;
    padding: 0 32px 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
  }

  .dropdown-controls {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .dropdown-toggle {
    transition: transform 0.2s ease;
    width: 12px;
    height: 12px;
    opacity: 0.8;
    cursor: pointer;
    
    &.open {
      transform: rotate(180deg);
    }
  }
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #F2F1F0;
  border-radius: 12px;
  margin-top: 4px;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
  overflow: hidden;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover,
  &.active {
    background-color: darken(#F2F1F0, 5%);
  }
}

.select {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style> 