<template>
  <div v-if="managerInfo">
    <div>
      <h1 class="text-center text-24 text-black mb-2">Cотрудник</h1>
      <div class="text-center text-24 mb-2">
        {{managerInfo.full_name ? managerInfo.full_name : ''}}
      </div>
      <div class="text-center text-20 mb-2">
        {{managerInfo.department.name_ru ? managerInfo.department.name_ru : ''}}
      </div>
      <div>
        <p class="text-20 text-gray mb-1">Телефон: <span class="text-black">{{managerInfo.phone ? managerInfo.phone : 'Нет'}}</span></p>
        <p class="text-20 text-gray mb-1">Внутренний номер: <span class="text-black">{{managerInfo.extension_number ? managerInfo.extension_number : 'Нет'}}</span></p>
        <p class="text-20 text-gray mb-1">Телеграм: <span class="text-black">{{managerInfo.telegram_username ? managerInfo.telegram_username : 'Нет'}}</span></p>
      </div>
    </div>


  </div>
  <div class="p-20" v-else>
    <h1 class="text-24 text-center p-20">Данных нет</h1>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ManagerInfoModal',
  computed: {
    ...mapGetters({
      managerInfo: 'managerPhones'
    })
  },
}
</script>