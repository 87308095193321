<template>
  <div class="wrapper modified flex column ">
    <div class="bg-white rounded p-20">
      <div class="flex gap-2">
        <filter-bar is-create="true" create-text="Добавить бригаду"
                    @on-create="toggleCreateBrigadeModal"></filter-bar>
        <button @click="openCreateTechnicModal" type="button" class="btn btn-create" style="width: 300px">
          <img src="/assets/plus-create.svg" alt=""/>
          Добавить техника
        </button>
      </div>
      <div class="flex justify-between align-center">
        <div class="mt-3">
          <label for="" class="block mb-1 bold">Поиск по ID или имени</label>
          <input class="input-form" type="search" v-model="search" @input="searchForBrigade">
        </div>
        <universal-departments-dropdown 
          @clearBtn="clearBtn('department')" 
          @departmentFilter="departmentFilter" 
          :current-value="department"
          style="width: 340px;"
        ></universal-departments-dropdown>
      </div>
      <div class="mt-3">
        <div class="grid">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-brigade">
            <p>Бригада</p>
          </div>
          <div class="grid-employees">
            <p>Cотрудники</p>
          </div>
          <div class="grid-owner">
            <p>Бригадир</p>
          </div>
          <div class="grid-bitrixId">
            <p>Bitrix ID</p>
          </div>
          <div class="grid-department">
            <p>Отдел</p>
          </div>
          <div class="grid-color text-center">
            <p>Цвет бригады</p>
          </div>
          <div class="grid-status text-center">
            <p>Статус</p>
          </div>
          <div class="grid-options" style="text-align: center">
            <p>Управление</p>
          </div>
        </div>
        <div class="brigade-table">
          <div class="grid" v-for="(brigade, index) in filteredBrigades" :key="brigade"
               style="border-bottom: 1px solid #EBEBEB;">
            <div class="border text-center" style="margin: auto"> {{ index + 1 }}</div>
            <div class="border text-center" style="margin: auto">{{ brigade.brigade.title }}</div>
            <div class="border">
              <p v-for="installer in brigade.installers" :key="installer.id" class="link mb-1  text-center">
                {{ installer.full_name }}</p>
            </div>
            <div class="border text-center brigade-center">{{ brigade.brigade?.owner?.full_name }}</div>
            <div class="border text-center brigade-center">{{ brigade.brigade.bitrix_id || '-' }}</div>
            <div class="border text-center brigade-center">{{ brigade.brigade?.department?.title }}</div>

            <div class="border text-center flex justify-center items-center">
              <div
                  :style="{background: brigade.brigade.color, height: '30px', width: '40px', margin: 'auto', borderRadius: '6px'}"
                  class="flex justify-center"></div>
            </div>
            <div class="border text-center flex justify-center" style="margin: auto">
              <button class="brigade-btn cursor-pointer" v-if="brigade.brigade.active"
                      @click="changeStatusBtn(brigade.brigade.id)">Активный
              </button>
              <button class="brigade-unactive cursor-pointer" v-if="!brigade.brigade.active"
                      @click="changeStatusBtn(brigade.brigade.id)">Неактивный
              </button>
            </div>
            <div class="brigade-options">
              <img src="/assets/logs-icon.svg" alt="" width="18" height="18" @click="openLogs(brigade.brigade.id)">
              <img src="/assets/list.svg" alt="edit" width="16" height="16" @click="editBrigade(brigade)">
              <img v-if="hasDeletePermission" src="/assets/TrashBr.svg" alt="delete" width="20" height="20"
              @click="openDeleteModal(brigade)"/>
            </div>
          </div>
        </div>
      </div>

      <custom-modal v-if="isCreateBrigadeModal" @close="isCreateBrigadeModal = !isCreateBrigadeModal">
        <BrigadeCreateModal @close="isCreateBrigadeModal = !isCreateBrigadeModal"></BrigadeCreateModal>
      </custom-modal>
      <custom-modal v-if="isBrigadeLogsModal" @close="isBrigadeLogsModal = !isBrigadeLogsModal">
        <brigade-logs-modal :id="currentId"></brigade-logs-modal>
      </custom-modal>
      <custom-modal v-if="isOpenCreateTechnicModal" @close="isOpenCreateTechnicModal = !isOpenCreateTechnicModal">
        <create-technic-modal @close="isOpenCreateTechnicModal = !isOpenCreateTechnicModal" @refresh="refreshList"/>
      </custom-modal>
      <delete-brigade-modal 
        v-if="isDeleteBrigadeModal" 
        :brigade-name="selectedBrigade?.brigade?.title || ''" 
        @cancel="isDeleteBrigadeModal = false" 
        @confirm="confirmDeleteBrigade"
      />
    </div>
  </div>
</template>

<script>
import CustomModal from "@/components/modules/CustomModal.vue";
import FilterBar from "@/components/modules/FilterBar.vue";
import BrigadeCreateModal from "@/components/modals/brigade/BrigadeCreateModal.vue"
import {mapActions, mapGetters, mapMutations} from "vuex";
import BrigadeLogsModal from "@/components/modals/brigade/BrigadeLogsModal.vue";
import CreateTechnicModal from "@/components/modals/brigade/CreateTechnicModal.vue";
import UniversalDepartmentsDropdown from "@/components/ui/UniversalDepartmentsDropdown.vue";
import DeleteBrigadeModal from "@/components/modals/brigade/DeleteBrigadeModal.vue";

export default {
  name: "BrigadeOptions",
  components: {
    UniversalDepartmentsDropdown,
    CreateTechnicModal,
    BrigadeLogsModal,
    FilterBar,
    CustomModal,
    BrigadeCreateModal,
    DeleteBrigadeModal
  },
  created() {
    this.fetchBrigades();
  },
  data() {
    return {
      isCreateBrigadeModal: false,
      currentId: 0,
      isBrigadeLogsModal: false,
      search: '',
      timeout: null,
      isOpenCreateTechnicModal: false,
      department: null,
      isDeleteBrigadeModal: false,
      selectedBrigade: null
    }
  },
  computed: {
    ...mapGetters({
      brigadesOptions: 'brigadesOptions',
      user: 'user'
    }),
    filteredBrigades() {
      if (!this.department) {
        return this.brigadesOptions;
      }
      return this.brigadesOptions.filter(brigade => 
        brigade.brigade?.department?.key === this.department
      );
    },
    hasDeletePermission() {
      const allowedRoles = ['dev_super_admin', 'it_support'];
      return allowedRoles.includes(this.user?.role?.key);
    }
  },
  methods: {
    ...mapActions({
      fetchBrigades: 'fetchBrigadesRelations',
      fetchBrigadesInstallers: 'fetchBrigadesInstallers',
      changeStatusBrigade: 'changeStatusBrigade',
      deleteBrigade: 'deleteBrigade'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setisBrigadeAddInstallersModal: 'setisBrigadeAddInstallersModal',
      setCurrentBrigadeOptions: 'setCurrentBrigadeOptions',
      setBrigadeStatus: 'setBrigadeStatus'
    }),
    toggleCreateBrigadeModal() {
      this.isCreateBrigadeModal = !this.isCreateBrigadeModal
    },
    async editBrigade(brigade) {
      this.setLoading(true)
      await this.fetchBrigadesInstallers()
      this.setCurrentBrigadeOptions(brigade)
      this.setisBrigadeAddInstallersModal(true)
      this.setLoading(false)
    },
    async changeStatusBtn(id) {
      await this.changeStatusBrigade({id: id}).then((res) => {
        if (res.success) {
          this.$toast.success('Статус успешно изменен')
          this.setBrigadeStatus(+id)
        } else {
          this.$toast.error('Не удалось изменить статус')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async openLogs(id) {
      this.currentId = id
      this.isBrigadeLogsModal = true
    },
    async searchForBrigade() {
      if (this.search.length >= 1) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          this.setLoading(true)
          await this.fetchBrigades({
            search: this.search
          })
          this.setLoading(false)
        }, 1500)
      } else if (this.search === '') {
        await this.fetchBrigades()
      }
    },
    openCreateTechnicModal() {
      this.isOpenCreateTechnicModal = true
    },
    async refreshList() {
      await this.fetchBrigades()
    },
    clearBtn(value) {
      if (value === 'department') {
        this.department = null
      }
    },
    departmentFilter(value) {
      this.department = value
    },
    openDeleteModal(brigade) {
      this.selectedBrigade = brigade;
      this.isDeleteBrigadeModal = true;
    },
    async confirmDeleteBrigade() {
      if (!this.selectedBrigade) return;
      
      this.setLoading(true);
      try {
        const response = await this.deleteBrigade(this.selectedBrigade.brigade.id);
        if (response.success) {
          this.$toast.success('Бригада успешно удалена');
          await this.fetchBrigades();
        } else {
          this.$toast.error((response.data && response.data.message) || 'Не удалось удалить бригаду');
        }
      } catch (error) {
        this.$toast.error(error.message || 'Произошла ошибка при удалении бригады');
      } finally {
        this.setLoading(false);
        this.isDeleteBrigadeModal = false;
        this.selectedBrigade = null;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 230px;
  font-weight: 500;
  height: 40px;
  margin-top: 40px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.brigade-table {
  background-color: #F5F5F5;
  border-radius: 20px;

  :hover {
    background-color: #F5F5FA;
  }
}

.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr 3fr 2fr 2fr 2fr 1fr 2fr  2fr;
  grid-template-areas: 'Num Brigade Employees Owner BitrixId Department Color Status Options';

  .border {
    border-right: 1px solid #EBEBEB;
    padding-left: 10px;
  }
}

.grid-num {
  grid-area: Num;
  text-align: center;
}

.grid-brigade {
  grid-area: Brigade;
  text-align: center;
}

.grid-employees {
  grid-area: Employees;
  text-align: center;
}

.grid-owner {
  grid-area: Owner;
  text-align: center;
}

.grid-department {
  grid-area: Department;
  text-align: center;
}

.grid-bitrixId {
  grid-area: BitrixId;
  text-align: center;
}

.grid-status {
  grid-area: Status;
}

.grid-Options {
  grid-area: Options;
}

.grid-color {
  grid-area: Color;
}

.brigade-options {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding-left: 10px;
  justify-content: center;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.brigade-btn {
  background: #2ab161;
  padding: 10px;
  border-radius: 16px;
  border: none;
  color: white;
  font-weight: bold;
  width: 100px;

  &:hover {
    background: #2ab161;
    filter: drop-shadow(2px 2px 4px #000000);
    transition: 200ms;
  }
}

.brigade-unactive {
  width: 100px;
  background: gray;
  padding: 10px;
  border-radius: 16px;
  border: none;
  color: white;
  font-weight: bold;

  &:hover {
    background: gray;
    filter: drop-shadow(2px 2px 4px #000000);
    transition: 200ms;
  }
}

.brigade-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>