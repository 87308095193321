import {api} from '@/service/axios'

export const dutyScheduleModule = {
    state: () => ({
        scheduleAll: [],
        scheduleDepartment: [],
        departmentList: []
    }),
    getters: {
        getScheduleAll: (state) => {
            return state.scheduleAll
        },
        getScheduleDepartment: (state) => {
            return state.scheduleDepartment
        },
        departmentList: (state) => {
            return state.departmentList
        }
    },
    mutations: {
        setScheduleAll (state, payload) {
            state.scheduleAll = payload
        },
        setScheduleDepartment (state, payload) {
            state.scheduleDepartment = payload
        },
        setDepartmentList(state, payload){
            state.departmentList = payload
        }
    },
    actions: {
        async fetchDutyScheduleAll({commit}, payload){
            const res = await api.get(`duty-manager-schedule/get-schedule-all`, {
                params: payload
            })
            commit('setScheduleAll', res.data)
            return res
        },
        async fetchDutyScheduleDepartment({commit}, payload){
            const res = await api.get(`duty-manager-schedule/get-schedule-by-department/${payload.id}`)
            commit('setScheduleDepartment', res.data)
        },
        async deleteSchedule({commit},payload){
             return await api.delete(`duty-manager-schedule/delete/${payload.id}`)
        },
        async createDutySchedule({commit},payload){
           return await api.post(`duty-manager-schedule/create-schedule`, payload)
        },
        async fetchDepartmentList({commit}, payload){
            const res = await api.get('dict/get-departments')
            commit('setDepartmentList', res.data)
        }
    },
}