<template>
  <base-modal @close="$emit('cancel')" color="#dc3545">
    <template #header>
      <div class="modal_title">
        <div class="modal_title__image">
          <img src="/assets/delete-icon-red.svg" alt="delete" />
        </div>
        <h3>{{ title }}</h3>
      </div>
    </template>
    
    <div class="confirmation-content">
      <div class="confirmation-body">
        <p>Вы точно хотите удалить участок "{{ areaName }}"?</p>
      </div>
      <div class="confirmation-footer">
        <button class="btn btn-cancel" @click="$emit('cancel')">Отмена</button>
        <button class="btn btn-confirm" @click="$emit('confirm')">{{ confirmText }}</button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'

export default {
  name: 'DeleteConfirmationModal',
  components: {
    BaseModal
  },
  props: {
    title: {
      type: String,
      default: 'Удаление участка'
    },
    areaName: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      default: 'Удалить'
    }
  }
}
</script>

<style lang="scss" scoped>
.modal_title {
  display: flex;
  align-items: center;
  gap: 12px;
  
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  &__image {
    width: 48px;
    height: 48px;
    min-width: 48px;
    flex-shrink: 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.confirmation-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.confirmation-body {
  padding: 20px 0;
  
  p {
    margin: 0;
    color: #1C1E23;
    font-size: 15px;
    line-height: 1.5;
  }
}

.confirmation-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.btn {
  padding: 10px 24px;
  height: 44px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s;

  &-cancel {
    background: #ffffff;
    color: #1C1E23;

    &:hover {
      background: darken(#F2F1F0, 5%);
    }
  }

  &-confirm {
    background: #dc3545;
    color: white;

    &:hover {
      background: darken(#dc3545, 5%);
    }
  }
}
</style> 