<template>
  <div class="">
    <iframe
        :src="`${tasksUrl}?resumeToken=${tasksCred?.token}`"
        frameborder="0"
        style="height: calc(100vh - 93px); width: 100%;"
    />
  </div>
</template>

<script>
import { tasksUrl } from '@/service/tasksServer'
import {mapGetters} from "vuex";

export default {
  name: 'TasksView',
  data () {
    return {
      tasksUrl
    }
  },
  computed: {
    ...mapGetters({
      tasksCred: 'tasksCred',
    })
  },
}
</script>
