<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">
       {{type === 'b2b' ? 'Сменить координаты' : 'Сменить сооружение связи'}}
    </div>
    <div class="inner flex column align-center">
      <yandex-map
          :coordinates="[coordinates.latitude, coordinates.longitude]"
          :zoom="17"
          :controls="[
							'rulerControl',
							'zoomControl',
							'searchControl',
							'geolocationControl'
						]"
          :options="{
            suppressMapOpenBlock: true,
            yandexMapDisablePoiInteractivity: true
          }"
          @map-was-initialized="mapInit"
          @click="selectNewCoordinates"
      >
        <yandex-marker
            marker-id="current-placemark"
            :coordinates="[coordinates.latitude, coordinates.longitude]"
        />
        <yandex-marker
            v-if="defaultCenter && type === 'b2b'"
            marker-id="current-placemark"
            :coordinates="[defaultCenter.latitude, defaultCenter.longitude]"
        />
        <yandex-marker
            v-for="(ork, index) in mapData.data"
            :key="index"
            marker-id="Placemark"
            marker-type="Placemark"
            :coordinates="[ork.lat, ork.lon]"
            :options="placeMarkORK(ork, ork.iconId)"
            :properties="{
              iconContent: ork.port_count ? ork.port_count.toString() : '',
            }"
            :ref="'marker' + ork.id"
            @click="(event) => selectORK(event, ork)"
            :balloon="{
								header: 'ОРК: ' + ork.name,
								body:
									ork.name +
									'<br>' +
									'Дистанция от адреса - ' +
									ork.distance +
									'м',
							}"
        >
        </yandex-marker>
        <yandex-marker
            v-if="selectedORK.coords"
            marker-id="Polyline"
            marker-type="Polyline"
            :coordinates="orkForConnection"
            :marker-stroke="{
								color: '#2AB161',
								width: 2,
								stroke: 'dash',
							}"
        />
      </yandex-map>
      <div class="flex justify-between action-bar mt-3">
        <button type="button" class="btn" @click="submit">сменить</button>
        <button type="button" class="btn error-btn" @click="() => $emit('close')">ОТмена</button>
      </div>
    </div>
  </div>
</template>

<script>

import {YandexMap, YandexMarker} from 'vue-yandex-maps'
import {mapActions, mapMutations} from "vuex";

export default {
  name: 'DepartureOpkModal',
  components: {
    YandexMap,
    YandexMarker,
  },
  props: ['coordinates', 'ticketId', 'type'],
  data() {
    return {
      yandexMap: undefined,
      mapData: {
        currentORK: {
          rotated: false,
        },
        data: [],
      },
      selectedORK: {
        id: undefined,
        coords: undefined,
        name: "Изменение ОРК",
        portCount: undefined,
        hasError: false,
      },
      defaultCenter: {
        latitude: '',
        longitude: '',
      },
    }
  },
  mounted() {
    this.getNearORK();
  },
  computed: {
    orkForConnection() {
      return [
        [this.coordinates.latitude, this.coordinates.longitude],
        this.selectedORK.coords,
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchNearOpk: 'fetchNearOpk',
      error: 'errorAlert',
      changeOrkId: 'changeOrkId',
      fetchNearOrkDeparture: 'fetchNearOrkDeparture',
      changeConnectionType: 'changeConnectionType'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async mapInit(map) {
      this.yandexMap = map;
      try {
        await window.ymaps.ready();
        console.log('Yandex Maps API ready');
      } catch (error) {
        console.error('Failed to initialize Yandex Maps:', error);
      }
    },
    selectORK(event, ork) {
      console.log(event)
      let lastTarget = this.selectedORK.id
      if (this.selectedORK.id === ork.id) {
        return;
      }
      if (this.selectedORK.id)
        this.selectedORK.id = ork.id;
      this.selectedORK.name = ork.name;
      this.selectedORK.portCount = ork.port_count;
      this.selectedORK.coords = event
          .get("target")
          .geometry.getCoordinates();
      event
          .get("target")
          .options.set("iconImageHref", '/assets/location-pin.svg');
      if (lastTarget) {
        try {
          this.$refs['marker' + lastTarget][0].options.set("iconImageHref", '/assets/location-pin-gray.svg');
        } catch (e) {
          console.log(e)
        }
      }
    },
    async getNearORK() {
      if (
          !this.coordinates.latitude ||
          !this.coordinates.longitude ||
          this.coordinates.latitude === "-" ||
          this.coordinates.longitude === "-"
      ) {
        this.selectedORK.name = "—";
        this.selectedORK.hasError = true;
        return;
      }
      try {
        this.setLoading(true)
        // const res = await this.fetchNearOpk({
        //   latitude: this.coordinates.latitude,
        //   longitude: this.coordinates.longitude,
        // });
        const res = await this.fetchNearOrkDeparture({id: this.ticketId});
        if (!res.success) {
          return;
        }
        this.mapData.data = await this.reformatNearORK(res.data);
        if (this.coordinates.ork_id) {
          const ork = this.mapData.data.find(
              (item) => item.id === this.coordinates.ork_id
          );
          this.selectedORK.id = ork?.id;
          this.selectedORK.name = 'ОРК' + ork.id;
          this.selectedORK.portCount = ork.port_count;
          this.selectedORK.coords = [ork.lat, ork.lon];
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.setLoading(false)
      }
    },
    placeMarkORK(ork) {
      return {
        iconLayout: "default#imageWithContent", // 'default#imageWithContent' для использования с контентом
        iconImageHref: this.selectedORK.id === ork.id ? '/assets/location-pin.svg' : '/assets/location-pin-gray.svg', // адрес изображения или data:image/svg+xml;base64
        iconImageSize: [43, 55], // размер иконки в px
        iconImageOffset: [-22, -55], // смещение иконки в px,, // строковый HTML шаблон для контента
        balloonContentHeader: 'ОРК: ' + ork.name,
        balloonContent: ork.name + '<br>' + 'Дистанция от адреса - ' + ork.distance + 'м',
        iconContentOffset: [ork.port_count >= 10 ? 14 : 16, 15],
      };
    },
    async reformatNearORK(data) {
      if (data.some((item) => item.distance <= 20)) {
        this.mapData.currentORK.rotated = true;
      }
      return data.map((item) => {
        return {
          lat: item.lat,
          lon: item.lon,
          distance: item.distance,
          id: item.id,
          port_count: item.info.port_count_out,
          location: item.info.location,
          name: item.info.name,
          iconId: 0,
        };
      });
    },
    async selectNewCoordinates(e) {
      const coords = e.get("coords");
      this.defaultCenter = {
        latitude: coords[0],
        longitude: coords[1],
      };
      
      try {
        const ymaps = await window.ymaps;
        const response = await ymaps.geocode([coords[0], coords[1]], { results: 1 });
        const address = response.geoObjects.get(0);
        if (address) {
          this.coordinates.address = address.getAddressLine();
          console.log('Found address:', this.coordinates.address);
        }
      } catch (error) {
        console.error('Failed to get address:', error);
      }
    },
    async submit() {
      if (this.type === 'b2b') {
        try {
          this.setLoading(true)
          await this.changeConnectionType({
            id: this.ticketId,
            longitude: this.defaultCenter.longitude.toString(),
            latitude: this.defaultCenter.latitude.toString(),
            address: this.coordinates.address || ''
          });
        } catch (e) {
          console.log(e);
          this.error('Не удалось изменить координаты');
        } finally {
          this.setLoading(false)
        }
      } else {
        if (
            !this.selectedORK.id ||
            this.selectedORK.id === this.coordinates.ork_id
        ) {
          this.error('Вы не выбрали ОРК');
          return;
        }
        try {
          this.setLoading(true)
          await this.changeOrkId({
            connection_ticket_id: this.ticketId,
            ork_id: this.selectedORK.id,
          });
        } catch (e) {
          console.log(e);
          this.error('Не удалось назначить ОРК');
        } finally {
          this.setLoading(false)
        }
      }
      this.$emit("success");
    },

  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.change-date {
  width: 750px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}

.action-bar {
  width: 60%;

  .btn {
    width: 163px;
  }
}

</style>
