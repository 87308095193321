<template>
  <div v-if="wikiPage">
    <div class="wrapper flex column ">
      <div class="flex justify-around gap-2 align-center  bg-white rounded p-20">
        <div>
          <div>
            <label>Название<span class="text-red">*</span></label>
            <input class="input-form mt-1" type="text" disabled v-model="wikiPage.title">
          </div>
          <div class="mt-2">
            <label for="">Описание<span class="text-red">*</span></label>
            <input class="input-form mt-1 " type="text" disabled v-model="wikiPage.description">
          </div>

        </div>
        <div class="flex gap-2 mt-4">
          <button type="button" class="btn" @click="isPreview = !isPreview">Preview</button>
          <button class="btn btn-green" @click.prevent="$router.push(`/instructions/${$route.params.id}/edit`)">Изменить</button>
          <button class="btn btn-secondary" @click.prevent="$router.push('/instructions')">Отмена</button>
        </div>
      </div>

      <div class="mt-4" v-for="el in wikiPage.blocks" :key="el.id">
        <WikiBlock :id="el.id" :blocks="el" :disabled="disabled"></WikiBlock>
      </div>
    </div>
    <custom-modal :custom-class="'comments-full'" v-if="isPreview" @close="isPreview = !isPreview">
      <instruction-modal></instruction-modal>
    </custom-modal>
  </div>
</template>

<script>
import WikiBlock from "@/components/wiki/WikiBlock.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import InstructionModal from "@/components/modals/instructions/InstructionModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";

export default {
  name: "InstructionView",
  components: {CustomModal, InstructionModal, WikiBlock},
  created() {
    this.setLoading(true)
    this.fetchWikiDetail(this.$route.params.id);
    this.setLoading(false)
  },
  data() {
    return {
      disabled: true,
      isPreview: false,
    }
  },
  computed: {
   ...mapGetters({
     wikiPage: 'wikiPage'
   })
  },
  methods: {
    ...mapActions({
      fetchWikiDetail: 'fetchWikiPage'
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    })
  },
}
</script>
<style scoped lang="scss">

</style>