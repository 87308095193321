import { api } from '@/service/axios'
import { getIndexById } from '@/helpers/utils'
import { managers } from 'socket.io-client'

export const queriesTCModule = {
    namespaced: true,
    state: () => ({
        items: [],
        item: {},
        meta: {},
        managers: [],
        connectionTechnologies: [],
        statuses: [],
        necessaryEquipments: [],
        connectionMethods: [],
        additionalGoods: [],
    }),
    getters: {
        items(state) {
            return state.items
        },
        item(state) {
            return state.item
        },
        meta(state) {
            return state.meta
        },
        managers(state) {
            return state.managers
        },
        connectionTechnologies(state) {
            return state.connectionTechnologies
        },
        necessaryEquipments(state) {
            return state.necessaryEquipments
        },
        connectionMethods(state) {
            return state.connectionMethods
        },
        additionalGoods(state) {
            return state.additionalGoods
        },
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload
        },
        setItem(state, payload) {
            state.item = payload
        },
        setMeta(state, payload) {
            state.meta = payload
        },
        setManagers(state, payload) {
            state.managers = payload
        },
        setConnectionTechnologies(state, payload) {
            state.connectionTechnologies = payload
        },
        setNecessaryEquipments(state, payload) {
            state.necessaryEquipments = payload
        },
        setConnectionMethods(state, payload) {
            state.connectionMethods = payload
        },
        setAdditionalGoods(state, payload) {
            state.additionalGoods = payload
        },
        clearTicketData(state) {
            state.item = null
        }
    },
    actions: {
        async fetchItems({ commit }, payload) {
            let params = {}

            payload.manager?.id && (params.manager_id = payload.manager.id)
            payload.technology?.id && (params.connection_technology_id = payload.technology.id)
            payload.status?.id && (params.status_id = payload.status.id)
            payload.dateFrom && (params.date_from = payload.dateFrom)
            payload.dateTo && (params.date_to = payload.dateTo)
            payload.page && (params.page = payload.page)
            payload.ticketStatus && (params.status = payload.ticketStatus)


            const res = await api.get(`tech-cap/ticket-list`, {
                params
            })

            if (res.success) {
                commit('setItems', res.data.tickets)
                commit('setMeta', res.data.meta)
            }
        },
        async fetchItem({ commit }, id, payload) {
            const res = await api.get(`tech-cap/ticket-detail/${id}`, {
                payload
            })

            if (res.success) {
                commit('setItem', res.data)
            }
        },
        async fetchManagers({ commit }, payload) {
            const res = await api.get(`tech-cap/get-managers`, {
                params: payload
            })

            if (res.success) {
                commit('setManagers', res.data)
            }
        },
        async fetchConnectionTechnologies({ commit }, payload) {
            const res = await api.get(`dict/get-connection-technologies`, {
                params: payload
            })

            if (res.success) {
                commit('setConnectionTechnologies', res.data)
            }
        },
        async updateItem({ commit }, { id, payload }) {
            const res = await api.put(`tech-cap/ticket-update/${id}`, payload)
            if (res.success) {
                commit('setItem', res.data)
            } else {
                throw new Error(res.message)
            }
        },
        async fetchNecessaryEquipments({ commit }) {
            const res = await api.get(`dict/get-necessary-equipment`)
            if (res.success) {
                commit('setNecessaryEquipments', res.data)
            }
        },
        async fetchConnectionMethods({ commit }) {
            const res = await api.get(`dict/get-connection-methods`)
            if (res.success) {
                commit('setConnectionMethods', res.data)
            }
        },
        async fetchAdditionalGoods({ commit }) {
            const res = await api.get(`dict/get-additional-goods`)
            if (res.success) {
                commit('setAdditionalGoods', res.data)
            }
        },
        async changeActionStatus({ commit, dispatch }, { id, payload }) {
            const res = await api.post(`tech-cap/send-action-status/${id}`, payload)
            if (res.success) {
                dispatch('fetchItem', id)
            } else {
                throw new Error(res.message)
            }
        },
        async sendCommentToErp({commit}, {id, payload}){
            const res = await api.post(`tech-cap/create-erp-comment/${id}`, payload)
            return res
        }
    }
}
