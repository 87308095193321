<template>
  <div class="modal-content">
    <div class="filter-buttons">
      <button class="filter-btn"
              style="background: #2ab161;color: white;" @click="downloadExcel">
        <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
        <div>Выгрузить</div>
      </button>
    </div>
    <div class="flex justify-between" style="align-items: center">
      <div>{{ data.author_name }}</div>
      <div class="flex gap-2" style="align-items: center">
        <div class="filter-buttons">
          <button @click="toggleSortOrder" class="filter-btn">
            Сортировать по дате
            <img :src="'/assets/comments/arrow-down-up.svg'"/>
          </button>
        </div>
        <div class="toggle-switch" :class="{ 'toggle-active': isShowAll }" @click="toggleAll">
          <div :class="['toggle-circle', { 'toggle-on': isShowAll }]"></div>
        </div>
      </div>
    </div>
    <div style="overflow-y: auto; max-height: 600px">
      <div v-for="(task, index) in sortedTasks"
           :key="'taskIndex' + index" class="tasks">
        <div @click="toggleTaskInfo(index)" class="task-info">
          <div class="flex gap-2">
            <div class="number">{{ task?.task_id || task?.bitrix_task_id }}:</div>
            <div>Заработанная сумма: <span class="sum">{{ task?.wallet.amount }}тг.</span></div>
            <div>{{ format(new Date(task.created_at), 'yyyy-MM-dd HH:ss', {locale: ru}) }}</div>
          </div>
          <img src="/assets/chevron-right-black.svg" :class="{'rotated': openDropdowns.includes(index)}"/>
        </div>
        <div
            v-if="openDropdowns.includes(index)"
            class="task-dropdown mb-2"
        >
          <div>Тип задачи: <span style="font-weight: 600">{{ task.type }}</span></div>
          <div>ID задачи: <a :href="task?.url" target="_blank"
                             style="font-weight: 600">{{ task?.task_id || task?.bitrix_task_id }}</a></div>
          <div v-if="task?.additional_installers.length">
            <div v-for="user in task?.additional_installers" :key="user.id">
              <div>Сотрудники выполнявшие заявку: <span style="font-weight: 600"> {{ user?.name }}</span></div>
            </div>
          </div>
          <div v-else>Сотрудник выполнил задание один</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {format} from 'date-fns'
import {ru} from 'date-fns/locale';

export default {
  name: "DetalizationModal",
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },

  },
  async created() {

  },
  data() {
    return {
      format,
      ru,
      isShowAll: false,
      activeIndex: null,
      openDropdowns: [],
      sortOrder: 'desc'
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
    sortedTasks() {
      if (!this.data?.rewards.length) return [];

      return [...this.data.rewards].sort((a, b) => {
        const from = new Date(a.created_at);
        const to = new Date(b.created_at);
        return this.sortOrder === 'asc' ? from - to : to - from;
      });
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async downloadExcel() {
      const {utils, writeFile} = await import("xlsx");

      const formattedData = this.data?.rewards.map((reward) => ({
        ФИО: this.data.author_name,
        ID: reward?.task_id || reward?.bitrix_task_id,
        Отдел: this.data.department,
        Дата: new Date(reward.created_at).toLocaleString(),
        Сумма: reward.wallet.amount,
        Описание: reward.description,
        'Доп. сотрудники': reward?.additional_installers?.length
            ? reward.additional_installers.map(item => item.name).join(', ')
            : '-'
      }));

      const worksheet = utils.json_to_sheet(formattedData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");

      writeFile(workbook, `Отчет ТО, ${this.data.author_name}.xlsx`);
    },
    toggleTaskInfo(index) {
      if (this.openDropdowns.includes(index)) {
        this.openDropdowns = this.openDropdowns.filter((i) => i !== index);
      } else {
        this.openDropdowns.push(index);
      }
    },
    toggleAll() {
      this.isShowAll = !this.isShowAll;

      if (this.isShowAll) {
        this.openDropdowns = this.data?.rewards.map((_, index) => index);
      } else {
        this.openDropdowns = [];
      }
    },
    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>


<style scoped lang="scss">
.modal-content {
  min-width: 700px;

  .filter-buttons {
    grid-column: 3 / span 2;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: flex-end;
    padding-top: 8px;

    .filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 0 14px;
      border-radius: 12px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      transition: all 0.3s ease;
      line-height: 1;

      .filter-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        margin-right: 8px;
      }

      &.save-btn {
        width: 205px;
        background: linear-gradient(92.65deg, #00C82C 0%, #5AC371 100.1%);
        color: white;
      }

      &.reset-btn {
        width: 198px;
        background: #888B94;
        color: white;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .tasks {
    border-bottom: 1px solid #c7c7c7;

    .task-info {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 16px;
      align-items: center;

      .number {
        color: #444444;
        font-size: 15px;
        font-weight: bold;
      }

      .sum {
        color: #2ab161;
        font-size: 15px;
      }

      .rotated {
        transform: rotate(90deg);
      }
    }
  }
}

.dropdown {
  position: relative;

  .dropdown-toggle {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s;

    &.open {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  align-items: center;

  button {
    width: 100%;
    max-width: 350px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-save {
    background: linear-gradient(268.85deg, #04567B 1.72%, #0095FF 100%);
    color: white;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #333;
    border: none;

    &:hover {
      background: #F5F5FA;
    }
  }
}

.toggle-switch {
  width: 56px;
  height: 32px;
  background-color: #ccc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-active {
  background-color: #4DCE4D;
}

.toggle-circle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.toggle-on {
  transform: translateX(24px);
}
</style>