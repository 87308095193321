

<template>
  <div class="p-20">
    <div class="p-20">
      <h1 class="text-24 mb-4">Назначение даты выполнения</h1>
      <form action="">
        <label class="block mb-1" for="">Дата выполнения</label>
        <input class="input-form" type="date" max="2999-12-31" v-model="planned_date">
        <div class="flex gap-2 mt-4">
          <button type="button" class="input-btn cursor-pointer w-100" style="border: none" @click="$emit('closeDate')">Отменить</button>
          <button type="button" class="btn btn-green w-100 text-center text-14" style="padding: 0 12px" @click.prevent="submitChangeDate">Назначить дату</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DepartureServicePlannedDateModal",
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      planned_date: ''
    }
  },
  methods: {
    ...mapActions({
      changePlannedDate: 'changePlannedDate',
    }),
    async submitChangeDate(){
      await this.changePlannedDate({id: this.id,planned_date: this.planned_date}).then((res)=> {
        if (res.success){
          this.$toast.success('Дата успешно изменена')
          this.$emit('closeDate')
        } else {
          this.$toast.error('Не удалось изменить дату')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>