<template>
  <div class="wrapper justify-center">
    <div class="form modified flex justify-center">

      <div class="form-inner flex flex-wrap justify-between">
        <h1 class="text-black2 text-center w-100 mb-2">
          {{ $route.query.edit === 'true' ? 'РЕДАКТИРОВАТЬ ЗАЯВКУ "СЕРВИС"' : 'НОВАЯ ЗАЯВКА "СЕРВИС"' }}</h1>


        <div class="input-container input-container__form mb-2">
          <input class="input input-form" type="text" v-model="searchClient" ref="client" placeholder="Клиент"
                 @keyup="searchClients"/>
          <label class="label-form">Клиент</label>
          <div class="field__search-results" v-if="clients && clients.length > 0">
            <div class="field__search-results_item flex justify-between" v-for="client in clients" :key="client.uid"
                 @click="selectClient(client)">
              <p :class="client.blocked > 0 ? 'text-red' : ''">{{ client.name }}</p>
              <p :class="client.blocked > 0 ? 'text-red' : ''">{{ client.address }}</p>
            </div>
          </div>
          <div class="input-btn input-btn__form">
            <img src="/assets/search-form.svg" alt="">
          </div>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" type="text" v-model="newTicket.address" ref="address"
                 placeholder="Адрес"/>
          <label class="label-form">Адрес</label>
          <!--          <div class="field__search-results" v-if="addresses.length > 0">-->
          <!--            <div class="field__search-results_item flex justify-between" v-for="address in addresses" :key="address.id"-->
          <!--                 @click="selectAddress(address)">-->
          <!--              <p>{{ address.metaDataProperty.GeocoderMetaData.text }}</p>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="input-btn input-btn__form">
            <img src="/assets/search-form.svg" alt="">
            <!--            <button v-if="addresses.length === 0 && !searchAddress" class="cursor-pointer map-button"-->
            <!--                    @click="isAddressModal = true">-->
            <!--              <img style="margin-top: 2px;" src="/assets/reshot-icon-maps-VT593REALY.svg" width="24" height="20"/>-->
            <!--            </button>-->
          </div>
          <!--          <span v-if="isAddressFoundText" class="input-subtext"-->
          <!--                :class="{'error': !isAddressFound}">{{ isAddressFound ? 'Адрес найден' : 'Адрес не найден' }}</span>-->
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form"
                 :value="newTicket.longitude ? [newTicket.latitude, newTicket.longitude] : null" ref="coords"
                 placeholder="Координаты" type="text"/>
          <label class="label-form">Координаты</label>
          <div class="input-btn input-btn__form">
            <button class="cursor-pointer map-button"
                    @click="isAddressModal = true">
              <img style="margin-top: 2px;" src="/assets/reshot-icon-maps-VT593REALY.svg" width="24" height="20"/>
            </button>
          </div>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.district_id}"
                  v-model="newTicket.district_id" ref="district_id">
            <option v-for="option in districts" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Район</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.language}" v-model="newTicket.language"
                  ref="language">
            <option v-for="option in langOptions" :key="option.id" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Язык</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.contact_fio" ref="contact_fio"
                 placeholder="Контактное лицо" type="text"/>
          <label class="label-form">Контактное лицо</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form"
                 v-model="newTicket.contact_number"
                 ref="contact_number"
                 placeholder="Контактный номер"
                 type="text"
                 @input="handlePhoneInput($event, 'contact_number')"
                 @paste="onPastePhoneNumber('contact_number', $event)"/>
          <label class="label-form">Контактный номер</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="newTicket.additional_contact_fio" ref="additional_contact_fio"
                 placeholder="Доп. контактное лицо" type="text"/>
          <label class="label-form">Доп. контактное лицо</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input class="input input-form"
                 v-model="newTicket.additional_contact_number"
                 ref="additional_contact_number"
                 placeholder="Доп. контактный номер"
                 type="text"
                 @input="handlePhoneInput($event, 'additional_contact_number')"
                 @paste="onPastePhoneNumber('additional_contact_number', $event)"/>
          <label class="label-form">Доп. контактный номер</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <input ref="desired_date" class="input input-form" type="date" v-model="newTicket.desired_date"/>
          <label class="label-form">Желаемая дата выезда</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.category_id}"
                  v-model="newTicket.category_id" ref="category_id" @change="categorySelected">
            <option v-for="option in categories" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Категория</label>
        </div>

        <div class="input-container input-container__form mb-2" v-if="problemTypes.length">
          <select class="input input-form select" :class="{'empty': !newTicket.problem_type_id}"
                  v-model="newTicket.problem_type_id" ref="problem_type_id" @change="problemTypeSelected">
            <option v-for="option in problemTypes" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип проблемы</label>
        </div>

        <div class="input-container input-container__form mb-2" v-if="types.length">
          <select class="input input-form select" :class="{'empty': !newTicket.type_id}" v-model="newTicket.type_id"
                  ref="type_id">
            <option v-for="option in types" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Подкатегория</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.lanbilling_oper_id}"
                  v-model="newTicket.lanbilling_oper_id" ref="lanbilling_oper_id">
            <option v-for="option in companies" :key="option.key" :value="option.lanbilling_id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Компания</label>
        </div>
        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.client_type_id}"
                  v-model="newTicket.client_type_id" ref="client_type_id">
            <option v-for="option in clientTypes" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Тип клиента</label>
        </div>

        <template v-if="newTicket.details.length > 0">

          <template v-for="(detail, index) in newTicket.details" :key="detail.id">

            <div class="input-container input-container__form mb-2" v-if="detail.key === 'lack_of_service'">
              <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                      :ref="detail.key">
                <option value="Полное">Полное</option>
                <option value="Частичное">Частичное</option>
              </select>
              <label class="label-form">{{ detail.title }}</label>
            </div>

            <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'pbx_in_service'">
              <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                      :ref="detail.key">
                <option value="Да">Да</option>
                <option value="Нет">Нет</option>
              </select>
              <label class="label-form">{{ detail.title }}</label>
            </div>

            <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'connection_by'">
              <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                      :ref="detail.key">
                <option value="Приставка">Приставка</option>
                <option value="Wi-Fi">Wi-Fi</option>
              </select>
              <label class="label-form">{{ detail.title }}</label>
            </div>

            <template v-else-if="detail.key === 'point_A' && newTicket.category_id !== CHANNEL_PD_CATEGORY_ID">
              <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                      :ref="detail.key" @change="pointCheck(index, detail.key)">
                <option v-for="option in clientVGroups" :key="option.vg_id" :value="option.address">
                  {{ option.address }}
                </option>
              </select>
              <label class="label-form">{{ detail.title }}</label>
            </template>
            <template v-else-if="detail.key === 'point_B' && newTicket.category_id !== CHANNEL_PD_CATEGORY_ID">
              <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                      :ref="detail.key" @change="pointCheck(index, detail.key)">
                <option v-for="option in clientVGroups" :key="option.vg_id" :value="option.address">
                  {{ option.address }}
                </option>
              </select>
              <div class="text-center mb-1 mt-1">или выберите на карте</div>
              <div class="flex gap-2 items-center" style="align-items: center">
                <input class="input input-form" v-model="detail.value" type="text"/>
                <img src="/assets/access-data/yandex-svgrepo-com.svg" class="img-btn" @click="openMapForPointB(index)"
                     title="Yandex"
                     height="20" width="22" alt="">
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>
            <template v-else-if="detail.key === 'sip_data'">
              <div class="w-100 mb-2" :ref="detail.key">
                <label class="label-form" for="body">{{ detail.title }}</label>
                <div class="telephone__sip">
                  <div v-for="value in detail.value" :key="value">
                    <div v-for="(item, key) in value" :key="item">
                      <div>{{key}}:{{ item }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="detail.key === 'registration_number'">
              <input class="input input-form" v-model="detail.value" v-mask="phoneMask" :ref="detail.key"
                     :placeholder="detail.title" type="text"/>
              <label class="label-form">{{ detail.title }}</label>
            </template>
            <template v-else-if="detail.key === 'recent_calls'">
<!--              <div class="w-100 mb-2">-->
<!--                <label class="label-form" for="body">{{ detail.title }}</label>-->
<!--                <AppTextarea v-model="detail.value" max-length="1000" class-name="input-form input mt-1"-->
<!--                             :placeholder="detail.title"/>-->
<!--              </div>-->
              <div class="w-100 mb-2" :ref="detail.key">
                <label class="label-form" for="body">{{ detail.title }}</label>
                <div class="telephone__sip">
                  <div v-for="value in detail.value" :key="value">
                    <div v-for="(item,key) in value" :key="item">
                      <div>{{ key }}: {{ item }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'error_number'">
              <input class="input input-form" v-model="detail.value" :ref="detail.key"
                     :placeholder="detail.title" type="text"/>
              <label class="label-form">{{ detail.title }}</label>
            </div>

            <template v-else-if="detail.key === 'ip_address'">
              <template v-if="isClientIps">
                <div class="input-container input-container__form mb-2" v-for="(ip, index) in detail.value.split(',')"
                     :key="ip" @click="fetchConnectionType(ip)">
                  <input class="input input-form cursor-pointer link" :value="ip" :placeholder="detail.title"
                         type="text" readonly/>
                  <label class="label-form">{{ detail.title }} {{ index + 1 }}</label>
                </div>
              </template>

              <div class="input-container input-container__form mb-2" v-else>
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                       type="text"/>
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>

            <template v-else-if="detail.key === 'no_resource'">
              <div class="input-container input-container__form mb-2"
                   v-if="newTicket.type_id === NOTABLE_TYPE_ID.no_resource">
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                       type="text"/>
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>

            <template v-else-if="detail.key === 'connection_method' && $route.query.edit === 'true'">
              <div class="input-container input-container__form mb-2">
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                       type="text"/>
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>

            <template v-else-if="detail.key === 'connection_method' && $route.query.edit !== 'true'">
              <div class="input-container input-container__form mb-2" style="display: none;">
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                       type="text" readonly/>
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>

            <template v-else-if="detail.key === 'tariff'">
              <div class="input-container input-container__form mb-2">
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                       type="text" readonly/>
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>

            <!--              <div v-else-if="detail.key === 'node'" class="input-container input-container__form mb-2">-->
            <!--                <input class="input input-form" type="text" v-model="searchNode" :ref="detail.key" placeholder="Узел" @keyup="searchNodes" />-->
            <!--                <label class="label-form">Узел</label>-->
            <!--                <div class="field__search-results" v-if="filteredNodes.length > 0">-->
            <!--                  <div class="field__search-results_item flex justify-between" v-for="node in filteredNodes" :key="node.id" @click="selectNode(node)">-->
            <!--                    <p>{{ node.name }}</p>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="input-btn input-btn__form">-->
            <!--                  <img src="/assets/search-form.svg">-->
            <!--                </div>-->
            <!--              </div>-->

            <template v-else-if="detail.key === 'appeal_reason'">
              <div class="input-container input-container__form mb-2">
                <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value"
                        @change="appealReasonChanged(detail.value)" ref="appeal_reason">
                  <option v-for="(option, index) in appealReasonOptions" :key="index" :value="option">
                    {{ option }}
                  </option>
                </select>
                <label class="label-form">{{ detail.title }}</label>
              </div>

            </template>

            <date-picker
                v-else-if="detail.key === 'cod_date'"
                class="datepicker mb-2"
                v-model="detail.value"
                :clearable="false"
                select-text="ПРИМЕНИТЬ"
                cancel-text="СБРОСИТЬ"
                :enable-time-picker="false"
                :format="formatDate"
                locale="ru-RU"
                :placeholder="detail.title"
            >
              <template #dp-input="{ value }">
                <div class="input-container input-container__form mb-2">
                  <input
                      :ref="detail.key"
                      class="input datepicker"
                      :class="{ 'filled': value }"
                      readonly
                      type="text"
                      :placeholder="detail.title"
                      :value="value"
                  />
                  <label class="label-form" :for="detail.key">{{ detail.title }}</label>
                  <div class="input-btn input-btn__form">
                    <img src="/assets/calendar-form.svg" alt="">
                  </div>
                </div>
              </template>
              <template #action-row="{ closePicker, selectDate }">
                <div class="dp-action-row">
                  <button class="clear-button" @click="() => { detail.value = null; closePicker() }">
                    Сбросить
                  </button>
                  <button class="select-button" @click="selectDate">
                    Применить
                  </button>
                </div>
              </template>
            </date-picker>

            <date-picker
                v-else-if="detail.key === 'time'"
                class="datepicker mb-2"
                v-model="detail.value"
                :clearable="false"
                select-text="ПРИМЕНИТЬ"
                cancel-text="СБРОСИТЬ"
                time-picker
                :placeholder="detail.title"
            >
              <template #dp-input="{ value }">
                <div class="input-container input-container__form mb-2">
                  <input
                      :ref="detail.key"
                      class="input datepicker"
                      :class="{ 'filled': value }"
                      readonly
                      type="text"
                      :placeholder="detail.title"
                      :value="value"
                  />
                  <label class="label-form" :for="detail.key">{{ detail.title }}</label>
                  <div class="input-btn input-btn__form">
                    <img src="/assets/calendar-form.svg" alt="">
                  </div>
                </div>
              </template>
              <template #action-row="{ closePicker, selectDate }">
                <div class="dp-action-row">
                  <button class="clear-button" @click="() => { detail.value = null; closePicker() }">
                    Сбросить
                  </button>
                  <button class="select-button" @click="selectDate">
                    Применить
                  </button>
                </div>
              </template>
            </date-picker>

            <template
                v-else-if="detail.key === 'checker_info' || (detail.key === 'point_A_info' && newTicket.category_id !== CHANNEL_PD_CATEGORY_ID) || (detail.key === 'point_B_info' && newTicket.category_id !== CHANNEL_PD_CATEGORY_ID)">
              <div class="input-container w-100 mb-2" v-if="detail.key === 'checker_info'">
                <textarea class="input-form textarea modified" v-model="detail.value" :ref="detail.key"
                          :placeholder="detail.title" type="text" disabled/>
                <label class="label-form" for="body">{{ detail.title }}</label>
              </div>
            </template>

            <div class="input-container input-container__form mb-2" v-else>
              <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title"
                     type="text"/>
              <label class="label-form">{{ detail.title }}</label>
            </div>

          </template>
        </template>

        <div class="input-container w-100 mb-2">
          <!--          <textarea class="input-form textarea" v-model="newTicket.message" ref="message" placeholder="Описание"-->
          <!--                    type="text"/>-->
          <AppTextarea v-model="newTicket.message" max-length="3000" class-name="input-form input"
                       placeholder="Описание"/>
          <label class="label-form" for="body">Описание</label>
        </div>

        <div class="w-100 flex justify-center">
          <button type="button" class="btn form-btn mb-4" @click="toggleRelateModal">связать с аварией</button>
        </div>

        <div class="w-100 mb-2">
          <DropZone
              class="!cursor-pointer w-full h-full !border-none"
              :uploadOnDrop="false"
              ref="files"
              :multipleUpload="true"
              :acceptedFiles="['image', 'pdf', 'docx', 'xls', 'xlsx', 'doc']"
              @added-file="handleFiles"
              :parallelUpload="3">
            <template v-slot:message>
              <DropZoneInner/>
            </template>
          </DropZone>
          <div class="mt-2 flex justify-center">
            <button @click="onPickFile" type="button" class="btn btn-gray">или выберите видео</button>
          </div>
        </div>
        <input type="file"
               style="display: none"
               ref="fileInput"
               id="fileInput"
               multiple
               accept="video/*"
               @change="onFilePicked"/>
        <div class="attached-files flex flex-wrap" v-if="newTicket.attached_files.length > 0">
          <div class="attached-files__item" v-for="(file, index) in newTicket.attached_files" :key="index">
            <img class="attached-files__delete cursor-pointer" @click="removeFile(index)" src="/assets/close-red.svg"
                 alt="">
            <div v-if="file.format === 'mp4'">
              <video class="attached-files__file mr-2" :src="'data:video/mp4;base64,' + file.base64String" controls
                     style="width: 100px; height: 70px"></video>
            </div>
            <div v-else>
              <img class="attached-files__file mr-2" :src="'data:image/png;base64,' + file.base64String" alt="">
            </div>
          </div>
        </div>

        <div class="w-100 flex justify-center mt-1">
          <button type="button" class="btn form-btn mb-4" @click="submitForm">
            {{ $route.query.edit === 'true' ? 'Сохранить' : 'Создать' }}
          </button>
        </div>

      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        ЗАЯВКА <span v-if="createdTicketId"><b>#{{ createdTicketId }}</b></span> НА СЕРВИС УСПЕШНО
        {{ $route.query.edit === 'true' ? 'ИЗМЕНЕНА' : 'СОЗДАНА' }}
      </success-modal>
    </custom-modal>
    <custom-modal v-if="isIpCheckResultModal" @close="toggleIpCheckResult" :custom-class="'bg-gray'">
      <ip-check-result-modal/>
    </custom-modal>

    <custom-modal v-if="isRelateModal" @close="toggleRelateModal">
      <relate-to-emergency-modal :ticket-id="newTicket.emergency_ticket_id" @submit="relateEmergencyTicket"/>
    </custom-modal>
    <custom-modal v-if="isAddressModal" @close="isAddressModal = !isAddressModal">
      <service-ticket-form-address-modal @close="pickedCoordinates"
                                         @getStreetName="getStreetName"
                                         @getDistrictName="handleDistrictName"></service-ticket-form-address-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {formatDate, formatDateReverse, getBase64, getFileFormat, findById, maskPhone} from "@/helpers/utils";
import IpCheckResultModal from "@/components/modals/IpCheckResultModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import {DropZone} from 'dropzone-vue';
import DropZoneInner from "@/components/static/DropZoneInner.vue";
import router from "@/router";
import DatePicker from '@vuepic/vue-datepicker';
import RelateToEmergencyModal from "@/components/modals/tickets/RelateToEmergencyModal.vue";
import ServiceTicketFormAddressModal from "@/components/modals/tickets/ServiceTicketFormAddressModal.vue";
import AppTextarea from '@/components/ui/AppTextarea.vue';
import { parse, format } from 'date-fns';

// подкатегория
const NOTABLE_TYPE_ID = {
  no_resource: 41
}

export default {
  name: 'ServiceTicketFormView',
  components: {
    ServiceTicketFormAddressModal,
    SuccessModal,
    CustomModal,
    DropZone,
    DropZoneInner,
    IpCheckResultModal,
    RelateToEmergencyModal,
    DatePicker,
    AppTextarea,
  },
  data() {
    return {
      newTicket: {
        lanbilling_oper_id: 0,
        lanbilling_id: 0,
        lanbilling_vg_id: 0,
        type_id: null,
        category_id: 0,
        district_id: 0,
        problem_type_id: 0,
        client_type_id: 0,
        contact_number: '',
        language: '',
        message: '',
        address: '',
        longitude: '',
        latitude: '',
        details: [],
        contact_fio: '',
        additional_contact_fio: '',
        additional_contact_number: '',
        emergency_ticket_id: null,
        attached_files: [],
        desired_date: '',
      },
      phoneMask: '+7 (###)-###-##-##',
      searchClient: '',
      clientTimeOut: null,
      searchAddress: '',
      addressTimeOut: null,
      isAddressFoundText: false,
      selectedClient: {
        name: ''
      },
      createdTicketId: '',
      langOptions: [
        {
          title: 'Казахский',
          id: 'kz'
        },
        {
          title: 'Русский',
          id: 'ru'
        },
      ],
      appealReasonOptions: [
        'Не работает услуга на всех устройствах, пользуются по WiFi и по кабелю, перезагрузка не помогла, прошу проверить с нашей стороны',
        'LOS, по списку клиента не нашел, прошу проверить относится ли к общей аварии',
        'Иное'
      ],
      appealReason: '',
      filteredNodes: [],
      searchNode: '',
      isSuccessModal: false,
      isClientIps: false,
      isRelateModal: false,
      isAddressModal: false,
      NOTABLE_TYPE_ID,
      selectedIndex: 0,
      CHANNEL_PD_CATEGORY_ID: 2,
    }
  },
  async created() {
    this.setLoading(true)
    let promises = [this.fetchCategories(2), this.fetchServiceTypes(2)]
    if (!this.clientTypes || !this.clientTypes.length) promises.push(this.fetchClientTypes())
    if (!this.districts || !this.districts.length) promises.push(this.fetchDistricts())
    if (!this.companies || !this.companies.length) promises.push(this.fetchCompanies())
    if (!this.nodes || !this.nodes.length) promises.push(this.fetchNodes())
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
    if (this.$route.query.edit === 'true' && this.chosenTicket.ticket) {
      this.newTicket = {
        ticket_id: this.chosenTicket.ticket.id,
        lanbilling_oper_id: this.chosenTicket.ticket.client.company_id,
        lanbilling_id: this.chosenTicket.ticket.client?.lanbilling_id,
        lanbilling_vg_id: this.chosenTicket.ticket.lanbilling_vg_id,
        type_id: this.chosenTicket.ticket.type ? this.chosenTicket.ticket.type.id : null,
        category_id: this.chosenTicket.ticket.category ? this.chosenTicket.ticket.category.id : 0,
        district_id: this.chosenTicket.ticket.district_id,
        problem_type_id: this.chosenTicket.ticket.problem_type ? this.chosenTicket.ticket.problem_type.id : 0,
        client_type_id: this.chosenTicket.ticket.client_type ? this.chosenTicket.ticket.client_type.id : 0,
        contact_number: this.chosenTicket.ticket.contact_number,
        language: this.chosenTicket.ticket.language,
        message: this.chosenTicket.ticket.message,
        address: this.chosenTicket.ticket.address,
        details: this.chosenTicket.details,
        contact_fio: this.chosenTicket.ticket.contact_info,
        additional_contact_fio: this.chosenTicket.ticket.additional_contact_fio,
        additional_contact_number: this.chosenTicket.ticket.additional_contact_number,
        attached_files: [],
        desired_date: this.chosenTicket.ticket.desired_date ? this.transformDate(this.chosenTicket.ticket.desired_date) : '',
        latitude: this.chosenTicket.ticket.latitude,
        longitude: this.chosenTicket.ticket.longitude,
      }
      this.searchClient = this.chosenTicket.ticket.client?.full_name
      this.searchAddress = this.chosenTicket.ticket.address
      this.setLoading(true)
      if (this.newTicket.problem_type_id && this.newTicket.category_id && this.newTicket.lanbilling_vg_id && !this.chosenTicket) {

        await this.fetchDetailTypes({
          category_id: this.newTicket.category_id,
          vg_id: this.newTicket.lanbilling_vg_id,
          problem_type_id: this.newTicket.problem_type_id
        })

        this.newTicket.details = this.detailTypes
        // this.newTicket.details.forEach(item => {
        //   if (item.key === 'ip_address') {
        //     this.isClientIps = true
        //     console.log(this.selectedClient, item)
        //     item.value = this.selectedClient.ip_address
        //
        //   }
        //   if (item.key === 'connection_method') item.value = this.selectedClient.connection_method
        //   if (item.key === 'lb_status_is_active') item.value = this.selectedClient.status
        // })
      }

      if (this.newTicket.category_id && this.newTicket.problem_type_id) {
        await this.fetchTypes(
            {
              id: this.newTicket.category_id,
              problem: this.newTicket.problem_type_id
            }
        )
      }

      this.setLoading(false)
    }
    this.fetchClientSingleWrapper();
  },
  computed: {
    ...mapGetters({
      serviceTypes: 'serviceTypes',
      categories: 'categories',
      clientTypes: 'clientTypes',
      types: 'types',
      clients: 'clients',
      addresses: 'addresses',
      districts: 'districts',
      companies: 'companies',
      detailTypes: 'detailTypes',
      problemTypes: 'problemTypes',
      nodes: 'nodes',
      isIpCheckResultModal: 'isIpCheckResultModal',
      chosenTicket: "chosenTicket",
      clientVGroups: 'clientVGroups'
    }),
  },
  methods: {
    ...mapMutations({
      setClients: 'setClients',
      setAddresses: 'setAddresses',
      setLoading: 'setLoading',
      setIsIpCheckResultModal: 'setIsIpCheckResultModal'
    }),
    ...mapActions({
      fetchClientTypes: 'fetchClientTypes',
      fetchCategories: 'fetchCategories',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchTypes: 'fetchTypes',
      fetchClients: 'fetchClients',
      fetchAddresses: 'fetchAddresses',
      fetchDistricts: 'fetchDistricts',
      fetchCompanies: 'fetchCompanies',
      fetchDetailTypes: 'fetchDetailTypes',
      fetchProblemTypes: 'fetchProblemTypes',
      fetchNodes: 'fetchNodes',
      createServiceTicket: 'createServiceTicket',
      errorAlert: 'errorAlert',
      checkIpAddress: 'checkIpAddress',
      checkIpExtended: 'checkIpExtended',
      fetchClientSingle: 'fetchClientSingle',
      fetchCurrentEmergencyTicketList: 'fetchCurrentEmergencyTicketList',
      fetchClientVGroups: 'fetchClientVGroups',
      getDistrictByAddress: 'getDistrictByAddress',
    }),
    handlePhoneInput(event, field) {
      let value = event.target.value.replace(/\D/g, '');
      if (value[0] === '8') value = '7' + value.slice(1);
      this.newTicket[field] = value ? this.formatPhoneNumber(value) : '';
    },
    formatPhoneNumber(value) {
      if (!value || value.length === 0) return '';
      value = value.replace(/\D/g, '').slice(0, 11);
      if (value[0] === '8') value = '7' + value.slice(1);

      const parts = [
        '+7',
        value.length > 1 ? ` (${value.slice(1, 4)}` : '',
        value.length > 4 ? `)-${value.slice(4, 7)}` : '',
        value.length > 7 ? `-${value.slice(7, 9)}` : '',
        value.length > 9 ? `-${value.slice(9)}` : ''
      ];

      return parts.join('');
    },
    onPastePhoneNumber(key, e) {
      const value = e.clipboardData.getData('text').replace(/\D/g, '');
      if (value.length >= 10) {
        this.$nextTick(() => {
          this.newTicket[key] = this.formatPhoneNumber(value);
        });
      }
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push('/tickets')
    },
    async toggleRelateModal() {
      if (!this.isRelateModal) {
        this.setLoading(true)
        await this.fetchCurrentEmergencyTicketList()
        this.setLoading(false)
      }
      this.isRelateModal = !this.isRelateModal
    },
    relateEmergencyTicket(id) {
      this.newTicket.emergency_ticket_id = id
      this.toggleRelateModal()
    },
    formatDate(date) {
      return formatDate(date, '.')
    },
    searchClients() {
      if (this.searchClient.length >= 3) {
        clearTimeout(this.clientTimeOut)
        this.clientTimeOut = setTimeout(async () => {
          this.setLoading(true)
          await this.fetchClients(this.searchClient)
          this.setLoading(false)
        }, 1500)
      } else this.setClients([])
    },
    async pointCheck(index, key) {
      this.setLoading(true)
      const vgroup = this.clientVGroups.find(item => item.address === this.newTicket.details[index].value)
      if (vgroup.ip_address) {
        await this.checkIpExtended(vgroup.ip_address).then(res => {
          if (res.status) {
            this.newTicket.details.forEach(detail => {
              if (detail.key === `${key}_info`) detail.value = res.data.message
            })

          } else this.errorAlert(res.message)
        })
      } else {
        this.newTicket.details.forEach(detail => {
          if (detail.key === `${key}_info`) detail.value = 'Информация отсутствует'
        })
      }
      this.setLoading(false)
    },
    async fetchConnectionType(ip) {
      // this.checkIpAddress(ip)
      this.setLoading(true)
      await this.checkIpExtended(ip).then(res => {
        if (res.status) {
          this.newTicket.details.forEach(detail => {
            if (detail.key === 'checker_info') detail.value += res.data.message
            if (detail.key === 'node' && res.data.info.location) detail.value = res.data.info.location
            if (detail.key === 'vlan' && res.data.info?.ip_info?.clvlan) detail.value = res.data.info.ip_info.clvlan
            if (detail.key === 'channel_speed' && res.data.info?.check_block?.rate) detail.value = res.data.info.check_block.rate
          })

        } else this.errorAlert(res.message)
      })
      this.setLoading(false)
    },
    toggleIpCheckResult() {
      this.setIsIpCheckResultModal(!this.isIpCheckResultModal)
    },
    appealReasonChanged(reason) {
      if (reason !== 'Иное') {
        this.newTicket.message = reason
      }
    },
    async selectClient(client, fromClientCard) {
      this.selectedClient = client
      console.log(client)
      if (client.client_type_id !== 2) {
        this.newTicket.contact_number = this.toMask(client.mobile)
      }
      this.newTicket.lanbilling_oper_id = client.lanbilling_oper_id
      this.newTicket.lanbilling_id = client.uid
      this.newTicket.lanbilling_vg_id = client.vg_id
      this.newTicket.client_type_id = client.client_type_id

      // this.fetchAddresses(client.address).then(res => {
      //   if (res.addresses.length) {
      //     this.selectAddress(res.addresses[0])
      //   }
      //   if (fromClientCard) {
      //     this.isAddressFoundText = true
      //     if (!client.address || !res.addresses.length) {
      //       this.isAddressFound = false
      //     } else this.isAddressFound = true
      //   }
      //
      // })

      if (client.type === 2) {
        this.newTicket.contact_fio = client.name
      }
      this.searchClient = client.name
      this.setClients([])
      if (this.newTicket.problem_type_id && this.newTicket.category_id) {
        await this.getDetailTypes()
      }
    },
    // searchAddresses() {
    //   if (this.searchAddress.length >= 3) {
    //     clearTimeout(this.addressTimeOut)
    //     this.addressTimeOut = setTimeout(async () => {
    //       this.setLoading(true)
    //       await this.fetchAddresses(this.searchAddress)
    //       this.setLoading(false)
    //     }, 1500)
    //   } else this.setAddresses([])
    // },
    async selectAddress(address) {
      this.newTicket.address = address.metaDataProperty.GeocoderMetaData.text
      this.searchAddress = address.metaDataProperty.GeocoderMetaData.text
      if (address.Point.pos) {
        this.newTicket.latitude = address.Point.pos.split(' ')[1]
        this.newTicket.longitude = address.Point.pos.split(' ')[0]
      }
      this.setAddresses([])
      await this.getDistrictByAddress(address.metaDataProperty.GeocoderMetaData.text).then(res => {
        if (res.success && res.data.id) this.newTicket.district_id = res.data.id
      })

    },
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    toMask(value) {
      return '7' + this.unMask(value).slice(-10)
    },
    chooseLang(lang) {
      this.newTicket.language = lang
    },
    async categorySelected() {
      await this.fetchProblemTypes(this.newTicket.category_id)
      if (this.newTicket.problem_type_id) {
        await this.fetchTypes(
            {
              id: this.newTicket.category_id,
              problem: this.newTicket.problem_type_id
            }
        )
      }
      if (this.newTicket.problem_type_id && this.newTicket.lanbilling_vg_id) {
        await this.getDetailTypes()
      }

    },
    async problemTypeSelected() {
      if (this.newTicket.category_id) {
        await this.fetchTypes(
            {
              id: this.newTicket.category_id,
              problem: this.newTicket.problem_type_id
            }
        )
      }
      if (this.newTicket.category_id && this.newTicket.lanbilling_vg_id) {
        await this.getDetailTypes()
      }
    },
    async getDetailTypes() {
      this.setLoading(true)
      await this.fetchDetailTypes({
        category_id: this.newTicket.category_id,
        vg_id: this.newTicket.lanbilling_vg_id,
        problem_type_id: this.newTicket.problem_type_id
      })
      this.setLoading(false)

      // Фильтруем все поля, связанные с точками А и Б
      this.newTicket.details = this.detailTypes.filter(detail =>
          !['point_A', 'point_B', 'point_A_info', 'point_B_info'].includes(detail.key)
      );

      this.newTicket.details.forEach(item => {
        if (item.key === 'ip_address' && item.value.length) {
          this.isClientIps = true
          item.value = this.selectedClient.ip_address
        }
        if (item.key === 'connection_method') item.value = this.selectedClient.connection_method
        if (item.key === 'lb_status_is_active') item.value = this.selectedClient.status
        if (item.key === 'registration_number') item.value = this.toMask(item.value)
      })
    },
    async fetchVGroups() {
      this.setLoading(true)
      await this.fetchClientVGroups({client_lb_id: this.selectedClient.uid})
      this.setLoading(false)
    },
    selectNode(node) {
      this.newTicket.details = this.newTicket.details.map(item => {
        if (item.key === "node")
          return {
            ...item,
            value: node.name || node.id // TODO: keep one
          }

        return item
      })
      this.searchNode = node.name
      this.filteredNodes = []
    },
    searchNodes() {
      if (this.searchNode.length === 0) {
        this.newTicket.details = this.newTicket.details.map(item => {
          if (item.key === "node")
            return {
              ...item,
              value: ""
            }

          return item
        })
        this.filteredNodes = []
      } else {
        this.filteredNodes = this.nodes.filter((item) => {
          if (item.name.indexOf(this.searchNode) > -1) return item
        })
      }
    },
    validateForm() {
      let isError = false
      for (let property in this.newTicket) {
        if (property === 'details') {
          for (let i = 0; i < this.newTicket.details.length; i++) {
            const item = this.newTicket.details[i]
            if (
                (item.key === 'no_resource' || item.key === 'address') &&
                item.value.length < 5
            ) {
              if (item.key !== 'no_resource' || this.newTicket.type_id === NOTABLE_TYPE_ID.no_resource) {
                this.errorAlert(`${item.title} минимум 5 символов`)

                isError = true
                this.$refs[item.key][0].classList.add('error')
                setTimeout(() => {
                  this.$refs[item.key][0].classList.remove('error')
                }, 3000)
              }
            } else if (item.key === 'recent_calls') {
              // this.errorAlert(`${item.title} максимум 1000 символов`)
              //
              // isError = true
              // this.$refs[item.key][0].classList.add('error')
              // setTimeout(() => {
              //   this.$refs[item.key][0].classList.remove('error')
              // }, 3000)
              isError = false
            } else if (item.key === 'sip_data') {
              isError = false
            }

            else if ((item.key === 'camera_name') && item.value.length < 4) {
              this.errorAlert(`${item.title} минимум 4 символа`)

              isError = true
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            } else if (item.key === 'error_number' && item.value.length > 4 && item.value.length < 3) {
              this.errorAlert(`${item.title} от 3 до 4 символов`)

              isError = true
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            } else if (item.key === 'registration_number' && this.unMask(item.value).length != 11) {
              this.errorAlert(`${item.title} не полностью указан`)

              isError = true
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            } else if (!item.value && item.is_required) {
              isError = true
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            }
          }
        } else if (property === 'lanbilling_id' || property === 'lanbilling_vg_id') {
          if (!this.newTicket['lanbilling_id'] || !this.newTicket['lanbilling_vg_id']) {
            isError = true
            this.$refs['client'].classList.add('error')
            setTimeout(() => {
              this.$refs['client'].classList.remove('error')
            }, 3000)
          }
        } else if (property === 'address') {
          if (!this.newTicket['address']) {
            isError = true
            this.$refs['address'].classList.add('error')
            setTimeout(() => {
              this.$refs['address'].classList.remove('error')
            }, 3000)
          }
        } else if (property === 'language') {
          if (!this.newTicket['language']) {
            isError = true
            this.errorAlert('Выберите язык комуникации')
          }
        } else if (property === 'desired_date') {
          // if (!this.newTicket['desired_date']) {
          //   isError = false
          // }
        } else if (property === 'additional_contact_number') {
          if (!this.newTicket['additional_contact_number']) {
            isError = true
            this.$refs['additional_contact_number'].classList.add('error')
            setTimeout(() => {
              this.$refs['additional_contact_number'].classList.remove('error')
            }, 3000)
          }
        } else if (property === 'longitude' || property === 'latitude') {
          if (!this.newTicket.longitude || !this.newTicket.latitude) {
            isError = true
            this.$refs['coords'].classList.add('error')
            this.errorAlert('Укажите координаты')
            setTimeout(() => {
              this.$refs['coords'].classList.remove('error')
            }, 3000)
          }
        } else if (!this.newTicket[property] &&
            property !== 'type_id' &&
            property !== 'ticket_id' &&
            property !== 'attached_files' &&
            property !== 'additional_contact_fio' &&
            property !== 'additional_contact_number' &&
            property !== 'emergency_ticket_id') {
          isError = true
          if (this.$refs[property]) {
            this.$refs[property].classList.add('error')
            setTimeout(() => {
              this.$refs[property].classList.remove('error')
            }, 3000)
          }
        }
      }
      return isError
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.setLoading(true)
        // for (const prop in this.$refs.files.all) {
        //   const fileData = this.$refs.files.all[prop].file;
        //   await getBase64(fileData).then(data => {
        //     const file = {
        //       format: getFileFormat(fileData),
        //       base64String: data.split('base64,')[1]
        //     };
        //     this.newTicket.attached_files.push(file);
        //   });
        // }

        // await this.newTicket.details.forEach((item, index) => {
        //   if(item.key === 'error_number' || item.key === 'registration_number' || item.key === 'sip_data') {
        //     item.value = this.unMask(item.value)
        //     console.log(this.newTicket.details[index].value)
        //   }
        // })

        // if (this.$props.scoreId) this.newTicket.score_id = this.$props.scoreId

        const newTicketData = {...this.newTicket};

        newTicketData.contact_number = this.unMask(newTicketData.contact_number)
        if (newTicketData.additional_contact_number)
          newTicketData.additional_contact_number = this.unMask(newTicketData.additional_contact_number)

        newTicketData.details = newTicketData.details.map((item) => {
          let newItem = {...item}

          if (item.key == "registration_number")
            newItem.value = this.unMask(item.value)
          else if (item.key == "cod_date")
            newItem.value = formatDateReverse(item.value)
          else if (item.key == "time")
            newItem.value = `${item.value.hours}:${item.value.minutes}`
          else if (item.key == "sip_data")
            newItem.value = JSON.stringify(newItem.value)
          else if (item.key == "recent_calls")
            newItem.value = JSON.stringify(newItem.value)

          return newItem
        })

        await this.createServiceTicket(newTicketData).then(res => {
          if (res.success) {
            this.createdTicketId = res.data.ticket_number
            this.toggleSuccessModal()
          } else {
            this.errorAlert(res.message)
            res.data?.value?.forEach(msg => this.errorAlert(msg))
          }
          this.setLoading(false)
        }).catch((res)=> {
          if (res.message === 'В поле desired date должна быть дата больше или равняться today.'){
            const desiredDateError = 'В поле желаемая дата выезда должна быть дата сегодня или позже.'
            this.$toast.error(desiredDateError)
          } else {
            this.$toast.error(res.message)
          }
        })
      } else {
        this.errorAlert('Заполните все поля')
      }
    },
    async fetchClientSingleWrapper() {
      if (this.$route.query.vg_id) {
        this.setLoading(true)
        await this.fetchClientSingle(this.$route.query.vg_id).then(res => {
          if (res.success) {
            this.selectClient(res.data, true)
          }
        })
        this.setLoading(false)
      }
    },
    async pickedCoordinates(event) {
      this.newTicket.latitude = event[0]
      this.newTicket.longitude = event[1]
      this.isAddressModal = false
    },
    async getStreetName(street) {
      this.selectedIndex = null;
      this.newTicket.address = street;
    },
    handleDistrictName(district) {
      if (district) {
        const matchingDistrict = this.districts.find(d => 
          d.title === district.replace(' район', '')
        );

        if (matchingDistrict) {
          this.newTicket.district_id = matchingDistrict.id;
          this.$toast.success(`Найден район: ${matchingDistrict.title}`);
        } else {
          this.$toast.error('Не удалось определить район');
        }
      }
    },
    openMapForPointB(index) {
      this.isAddressModal = true
      this.selectedIndex = index
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const files = event.target.files
      files.forEach(async (item) => {
        let file = {}
        await getBase64(item).then(data => {
          file.format = getFileFormat(item)
          file.base64String = data.split(',')[1]
          this.newTicket.attached_files.push(file)
        })
      })
    },
    removeFile(index) {
      this.newTicket.attached_files.splice(index, 1)
    },
    transformDate(inputDate) {
      try {
        const parsedDate = parse(inputDate, 'dd.MM.yyyy HH:mm', new Date());
        if (isNaN(parsedDate)) {
          throw new Error('Invalid date');
        }
        return format(parsedDate, 'yyyy-MM-dd');
      } catch (error) {
        return 'Invalid Date';
      }
    },
    async handleFiles(files) {
      const fileArray = Array.isArray(files) ? files : [files];
      for (const fileWrapper of fileArray) {
        const file = fileWrapper.file || fileWrapper;
        try {
          const base64Data = await getBase64(file);
          const formattedFile = {
            format: getFileFormat(file),
            base64String: base64Data.split('base64,')[1],
          };
          this.newTicket.attached_files.push(formattedFile);
          console.log('Файл добавлен:', formattedFile);
        } catch (error) {
          console.error('Ошибка обработки файла:', error);
        }
      }
    },
  },
  watch: {
    '$route': 'fetchClientSingleWrapper',
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.text-red {
  color: red;
}

.map-button {
  border: none;
  background: transparent
}

.attached-files {
  margin: 0 auto;

  &__item {
    margin-right: 8px;
    position: relative;
  }

  &__delete {
    position: absolute;
    z-index: 2;
    top: -4px;
    right: -4px;
    cursor: pointer;
  }

  &__file {
    width: 65px;
    height: 65px;
  }
}

.telephone__sip {
  min-height: 140px;
  max-height: 140px;
  overflow-y: scroll;
  padding: 12px 16px;
  margin-top: 10px;
  border: 1px solid #ADADAD;
  border-radius: 5px;
}
</style>
