<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100 mb-4">
      Комментарий:
    </div>
    <div class="ticket-comments__input w-100 flex column">
      <img class="ticket-comments__file" @click="onPickFile" src="/assets/paper-clip.svg" alt="">
      <img class="ticket-comments__send" @click="addComment" src="/assets/send.svg" alt="">
      <input type="file"
             style="display: none"
             ref="fileInput"
             id="fileInput"
             multiple
             accept="image/*"
             @change="onFilePicked" />
      <textarea class="input textarea" v-model="comment" placeholder="Добавить комментарий" ref="message" type="text" @paste="pasteImage" @drop.prevent="pasteImage"/>
      <div class="attached-files flex flex-wrap w-100" v-if="attached_files.length > 0">
        <div class="attached-files__item" v-for="(file, index) in attached_files" :key="index">
          <img class="attached-files__delete" @click="removeFile(index)" src="/assets/close-red.svg" alt="">
          <img class="attached-files__file" @click="toggleImageModal('data:image/png;base64,' + file.base64String)" :src="'data:image/png;base64,' + file.base64String" alt="">
        </div>
      </div>
    </div>
    <button type="button" class="btn mb-2 mt-4" @click="addComment">Завершить</button>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image" />
    </custom-modal>
  </div>
</template>

<script>
import {getBase64, getFileFormat} from "@/helpers/utils";

export default {
  name: 'TicketAddCommentModal',
  data() {
    return {
      comment: '',
      attached_files: [],
      image: '',
    }
  },
  methods: {
    onFilePicked (event) {
      const files = event.target.files
      files.forEach(async (item) => {
        let file = {}
        await getBase64(item).then(data => {
          file.format = getFileFormat(item)
          file.base64String = data.split(',')[1]
          this.attached_files.push(file)
        })
      })
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    removeFile(index) {
      this.attached_files.splice(index, 1)
    },
    clearComment() {
      this.comment = ''
      this.attached_files = []
    },
    pasteImage(pasteEvent){
      let items = pasteEvent.clipboardData? pasteEvent.clipboardData.items : pasteEvent.dataTransfer.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        let blob = items[i].getAsFile();
        const temp = {
          target: {
            files: [blob]
          }
        }
        this.onFilePicked(temp)
      }
    },
    addComment() {
      if (this.comment.length > 0 || this.attached_files.length > 0) {
        this.$emit('addComment',{
          comment: this.comment,
          attached_files: this.attached_files
        })
        this.clearComment()
      }
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

.ticket-comments {
  &__input {
    position: relative;

    .textarea {
      border-radius: 25px;
      border: 2px solid $gray;
      background: $white;
      height: 77px !important;
      margin-bottom: 12px;

      &:focus, &:active, &:focus-visible {
        border: 1px solid $gray;
        outline: none !important;
      }
    }
  }


  &__file {
    position: absolute;
    top: 44px;
    right: 48px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  &__edit {
    cursor: pointer;
    &:hover{
      box-shadow: 0 0 11px rgba(33,33,33,.2);
    }
  }

  &__send {
    position: absolute;
    top: 44px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }
}

</style>
