<template>
  <div class="wrapper modified">
    <div class="monetization">
      <h1 class="page-title">Управление стоимостью за завершенную задачу</h1>
      <div class="tabs-container">
        <div class="tabs">
          <div class="tab"  :class="{ active: activeTab === 'connection' }" @click="switchTab('connection')">
            Задачи
          </div>
          <!-- <div class="tab"  :class="{ active: activeTab === 'add-sale' }" @click="switchTab('add-sale')">
            Допродажа
          </div> -->
        </div>
        <BaseButton class="history-btn" disabled @click="downloadHistory" icon="excel-logo.svg">
          Выгрузить историю изменений
        </BaseButton>
      </div>

      <div class="monetization-header">
        <div class="filters-section">
          <div class="filter-group">
            <BaseCalendar ref="calendar" placeholder="Выберите период" @setDate="handleDateChange" :disabled="loading" label="" />
            <div class="w-100">
                <BaseDropdown 
                  v-model:selectedValue="selectedCompany" 
                  :items="companyItems" 
                  placeholder="Выберите компанию" 
                />
            </div>
            <div class="w-100">
                <BaseDropdown 
                  v-model:selectedValue="selectedType" 
                  :items="typeItems" 
                  placeholder="Выберите тип" 
                />
            </div>
            <BaseButton class="recalculate-btn" disabled @click="recalculate">
              Перерасчет
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="monetization-content">
        <div v-for="company in ['meganet', 'nls']" :key="company" class="company-section">
          <div class="company-header">
            <h3 class="company-title">{{ company.toUpperCase() }}</h3>
            <div class="company-controls">
              <button class="add-task-btn" @click="openAddTaskModal(company)">
                <img src="/assets/plus-icon.svg" alt="+" />
                <span>Добавить</span>
              </button>
            </div>
          </div>
          <div class="table-container">
            <div class="grid gridText">
              <div class="grid-num">
                <p>№</p>
              </div>
              <div class="grid-description flex align-center justify-center">
                <p class="text-center">Описание</p>
              </div>
              <div class="grid-cost flex align-center justify-center">
                <p class="text-center">Стоимость</p>
              </div>
              <div class="grid-type flex align-center justify-center">
                <p class="text-center">Тип начисления</p>
              </div>
              <div class="grid-weekend flex align-center justify-center">
                <p class="text-center">Выходной</p>
              </div>
              <div class="grid-actions flex align-center justify-center">
                <!-- <p class="text-center"></p> -->
              </div>
            </div>
            <div class="departure-table">
              <div class="grid cursor-pointer"
                v-for="task in getCompanyTasks(company)" 
                :key="task.id"
              >
                <div class="grid-num">{{ task.index }}</div>
                <div class="grid-description">
                  {{ task.description || '-' }}
                </div>
                <div class="grid-cost">
                  {{ task.costType === 'percent' ? task.percent + '%' : task.amount + ' тг' }}
                </div>
                <div class="grid-type">
                  {{ task.serviceType === 'product' ? 'Товар' : 'Услуга' }}
                </div>
                <div class="grid-weekend">
                  {{ task.weekend ? 'Да' : 'Нет' }}
                </div>
                <div class="grid-actions">
                  <img 
                    src="/assets/edit-icon.svg" 
                    @click.stop="openEditTaskModal(task)"
                    title="Редактировать"
                  />
                  <img 
                    src="/assets/delete-icon-red.svg" 
                    @click.stop="showDeleteTaskModal(task)"
                    title="Удалить"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="table-pagination mt-24" v-if="getCompanyTasks(company).length && getTotalPages(company) > 1">
            <div class="table-pagination__item" @click="paginationState[company].currentPage > 1 && paginationState[company].currentPage--">
              <img class="table-pagination__arrow" src="/assets/chevron-left.svg" alt="">
              <img class="table-pagination__arrow-hover" src="/assets/chevron-left-w.svg" alt="">
            </div>
            <div 
              class="table-pagination__item" 
              v-for="page in getPaginationRange(company)" 
              :key="page"
              @click="page !== '...' && (paginationState[company].currentPage = page)" 
              :class="{'active': page === paginationState[company].currentPage}"
            >
              {{ page }}
            </div>
            <div class="table-pagination__item" @click="paginationState[company].currentPage < getTotalPages(company) && paginationState[company].currentPage++">
              <img class="table-pagination__arrow" src="/assets/chevron-right.svg" alt="">
              <img class="table-pagination__arrow-hover" src="/assets/chevron-right-w.svg" alt="">
            </div>
          </div>
        </div>
      </div>

      <CreateTaskModal
        v-if="showAddTaskModal || showEditModal"
        :initial-task="newTask"
        :company-items="companyItems"
        :dropdown-options="dropdownOptions"
        :is-edit="showEditModal"
        :task-id="editingTaskId"
        @close="closeTaskModal"
        @submit="handleTaskSubmit"
      />

      <DeleteTaskModal
        v-if="showDeleteModal"
        :loading="loading"
        @close="closeDeleteModal"
        @confirm="confirmDelete"
      />
    </div>
  </div>
</template>

<script>
import BaseDropdown from '@/components/ui/BaseDropdown.vue'
import BaseCalendar from '@/components/ui/BaseCalendar.vue'
import CreateTaskModal from '@/components/modals/monetization/CreateTaskModal.vue'
import DeleteTaskModal from '@/components/modals/monetization/DeleteTaskModal.vue'
import BaseButton from '@/components/ui/BaseButton.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'MonetizationView',
  components: { 
    BaseDropdown, 
    CreateTaskModal, 
    BaseButton, 
    BaseCalendar, 
    DeleteTaskModal 
  },
  data() {
    return {
      activeTab: 'connection',
      selectedCompany: '',
      selectedType: '',
      params: {
        start_date: null,
        end_date: null,
      },
      showAddTaskModal: false,
      showDeleteModal: false,
      taskToDelete: null,
      currentCompany: null,
      newTask: {
        company: '',
        reason: '',
        costType: '',
        value: null,
        service_type: '',
        description: '',
        weekend: false
      },
      paginationState: {
        meganet: {
          currentPage: 1,
          itemsPerPage: 10
        },
        nls: {
          currentPage: 1,
          itemsPerPage: 10
        }
      },
      showEditModal: false,
      editingTaskId: null
    }
  },
  computed: {
    ...mapGetters('monetization', {
      companies: 'companiesList',
      taskTypes: 'rewardTypesList',
      serviceTypes: 'serviceTypesList'
    }),
    ...mapGetters({
      loading: 'loading'
    }),
    tasks() {
      return this.$store.state.monetization.tasks
    },
    companyItems() {
      const companiesData = this.companies || {}
      return Object.entries(companiesData).map(([key, title]) => ({
        key,
        title
      }))
    },
    typeItems() {
      const serviceTypesData = this.serviceTypes || {}
      return Object.entries(serviceTypesData).map(([key, title]) => ({
        key,
        title
      }))
    },
    dropdownOptions() {
      return {
        costTypes: [
          { value: 'rate', label: 'Процент' },
          { value: 'fixed', label: 'Фиксированная сумма' }
        ],
        serviceTypes: Object.entries(this.serviceTypes || {}).map(([value, label]) => ({
          value,
          label
        })),
        taskTypes: this.typeItems.map(item => ({
          value: item.key,
          label: item.title
        }))
      }
    }
  },
  methods: {
    ...mapActions('monetization', [ 
      'fetchCompanies', 
      'fetchRewardTypes', 
      'fetchServiceTypes', 
      'fetchTasks', 
      'createTask',
      'deleteTask',
      'updateTask',
      'fetchCostTypes'
    ]),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    getTotalPages(company) {
      const filteredTasks = this.tasks[company] || []
      return Math.ceil(filteredTasks.length / this.paginationState[company].itemsPerPage)
    },
    getPaginationRange(company) {
      const currentPage = this.paginationState[company].currentPage
      const totalPages = this.getTotalPages(company)
      
      let delta = 1
      let left = currentPage - delta
      let right = currentPage + delta + 1
      let range = []
      let rangeWithDots = []
      let l

      for (let i = 1; i <= totalPages; i++) {
        if (i == 1 || i == totalPages || (i >= left && i < right)) {
          range.push(i)
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1)
          } else if (i - l !== 1) {
            rangeWithDots.push('...')
          }
        }
        rangeWithDots.push(i)
        l = i
      }

      return rangeWithDots
    },
    getCompanyTasks(company) {
      const filteredTasks = this.tasks[company] || []
      const { currentPage, itemsPerPage } = this.paginationState[company]
      const start = (currentPage - 1) * itemsPerPage
      const end = start + itemsPerPage

      const mappedTasks = filteredTasks.map((task, index) => ({
        id: task.id,
        company: task.company,
        reason: task.reason,
        type: task.reason_type,
        costType: task.type === 'rate' ? 'percent' : 'fixed',
        amount: task.value,
        percent: task.value,
        postfix: task.postfix,
        serviceType: task.service_type,
        created_at: task.created_at,
        updated_at: task.updated_at,
        description: task.description || '-',
        weekend: task.weekend
      }))

      return mappedTasks.slice(start, end).map((task, index) => ({
        ...task,
        index: start + index + 1
      }))
    },
    openAddTaskModal(company) {
      this.currentCompany = company
      this.newTask = {
        company: company,
        reason: this.activeTab,
        costType: '',
        value: null,
        service_type: '',
        description: '',
        weekend: false
      }
      this.showAddTaskModal = true
    },
    recalculate() {
    },
    downloadHistory() {
    },
    closeTaskModal() {
      this.showAddTaskModal = false;
      this.showEditModal = false;
      this.editingTaskId = null;
      this.newTask = {
        company: '',
        reason: '',
        costType: '',
        value: null,
        service_type: '',
        description: '',
        weekend: false
      };
    },
    openEditTaskModal(task) {
      this.editingTaskId = task.id;
      this.showEditModal = true;
      this.newTask = {
        company: task.company,
        reason: task.reason || this.activeTab,
        costType: task.costType === 'percent' ? 'rate' : 'fixed',
        value: task.costType === 'percent' ? task.percent : task.amount,
        service_type: task.serviceType === 'all' ? null : task.serviceType,
        description: task.description === '-' ? '' : task.description,
        weekend: task.weekend === 1
      };
    },
    async handleTaskSubmit(taskData) {
      try {
        this.setLoading(true);
        if (this.showEditModal) {
          await this.updateTask({
            id: this.editingTaskId,
            ...taskData
          });
          this.$toast.success('Задача успешно обновлена');
        } else {
          await this.createTask(taskData);
          this.$toast.success('Задача успешно создана');
        }
        
        this.closeTaskModal();

        await this.fetchTasks({
          reason: this.activeTab,
          company: this.currentCompany
        });
      } catch (error) {
        console.error('Failed to handle task:', error);
        this.$toast.error(error.message || 'Произошла ошибка');
      } finally {
        this.setLoading(false);
      }
    },
    async showDeleteTaskModal(task) {
      this.taskToDelete = task;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.taskToDelete = null;
    },
    async confirmDelete() {
      try {
        this.setLoading(true);
        await this.deleteTask({
          id: this.taskToDelete.id
        });
        
        this.$toast.success('Задача успешно удалена');
        
        await this.fetchTasks({
          reason: this.activeTab,
          company: this.taskToDelete.company
        });
        
        this.closeDeleteModal();
      } catch (error) {
        this.$toast.error(error.message || 'Ошибка при удалении задачи');
      } finally {
        this.setLoading(false);
      }
    },
    async switchTab(tab) {
      this.setLoading(true);
      try {
        this.activeTab = tab;
        await Promise.all([
          this.fetchTasks({
            reason: tab,
            company: 'meganet'
          }),
          this.fetchTasks({
            reason: tab,
            company: 'nls'
          })
        ]);
      } finally {
        this.setLoading(false);
      }
    },
    handleDateChange([startDate, endDate]) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        start_date: Array.isArray(startDate) ? startDate[0] : startDate,
        end_date: Array.isArray(endDate) ? endDate[0] : endDate
      }
    }
  },
  watch: {
    selectedCompany() {
    },
    selectedType() {
    }
  },
  async created() {
    try {
      this.setLoading(true);
      await Promise.all([
        this.fetchCompanies(),
        this.fetchRewardTypes(),
        this.fetchServiceTypes(),
        this.fetchCostTypes(),
        this.fetchTasks({
          reason: this.activeTab,
          company: 'meganet'
        }),
        this.fetchTasks({
          reason: this.activeTab,
          company: 'nls'
        })
      ]);

    } catch (error) {
      console.error('Failed to load initial data:', error);
    } finally {
      this.setLoading(false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables';

.page-title {
  font-size: 1.7rem;
  font-weight: 600;
  margin: 0 0 22px 0;
}

.monetization {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding: 30px;

  &-header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
  }

  .filters-section {
    // max-width: 60%;
    .filter-group {
      display: flex;
      gap: 20px;
      align-items: flex-end;
      
      :deep(.base-dropdown),
      :deep(.base-calendar) {
        width: 300px;
        flex-shrink: 0;
      }

      .recalculate-btn {
        background: #02D15C;
        color: white;
        min-height: 44px;
        padding: 0 24px;
        border-radius: 12px;
        font-weight: 500;
        white-space: nowrap;
        border: none;
        margin-top: 4px;
        
        &:hover {
          background: darken(#02D15C, 5%);
        }
      }
    }
  }

  .buttons-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 50%;
    
    .btn {
      height: 42px;
      padding: 0 16px;
      font-weight: 500;
    }

    .btn-primary {
      width: 100%;
    }

    .btn-yellow {
      width: 100%;
      min-width: unset;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    gap: 24px;
    min-width: 0;
  }
}

.company-section {
  flex: 1;
  margin-bottom: 0;
  min-width: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .company-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .company-title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 0;
    
    &::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 18px;
      background: $primary;
      margin-right: 12px;
      border-radius: 2px;
    }
  }

  .company-controls {
    display: flex;
    align-items: center;
    gap: 16px;

    .add-task-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 36px;
      padding: 0 16px;
      background: white;
      color: #333;
      border: 1px solid #C6C6C6;
      border-radius: 8px;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.2s ease;

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background: #f8f9fa;
      }
    }
  }
}

.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  border-radius: 12px;
  background: white;
  z-index: 1;
  max-width: 100%;
  border: 1px solid #E1E0E0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }

  .gridText {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #F5F5FA;
    font-weight: 500;
    height: 48px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    [class^="grid-"] {
      padding: 0 8px;
      min-height: 48px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F9F9F9 !important;
      border-bottom: 1px solid #E1E0E0;
    }

    > div {
      border-bottom: none;
      background: #F5F5FA;
    }

    p {
      color: #333333;
    }
  }
}

.grid {
  display: grid;
  min-width: 400px;
  width: 100%;
  gap: 0;
  grid-template-columns: 
    minmax(40px, 40px)
    minmax(80px, 1fr)
    minmax(60px, 80px)
    minmax(80px, 1fr)
    minmax(60px, 80px)
    minmax(60px, 60px);
  grid-template-areas: 'Num Description Cost Type Weekend Actions';

  > div {
    padding: 8px;
    font-size: 15px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 54px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.grid-description {
      justify-content: flex-start;
      text-align: left;
      padding: 12px;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;
      line-height: 20px;
      min-height: fit-content;
    }

    &.grid-cost {
      justify-content: center;
      padding: 0 8px;
    }

    &.grid-type {
      justify-content: flex-center;
      padding-right: 12px;
    }

    &.grid-weekend {
      justify-content: flex-center;
      padding-right: 12px;
    }
  }
}

.grid-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  img {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.departure-table {
  .grid {
    border-top: 1px solid #E1E0E0;
    &:first-child { border-top: none; }
    &:last-child { border-bottom: none; }
  }

  :hover {
    background-color: #F5F5FA !important;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

@media (max-width: 1400px) {
  .monetization {
    &-content {
      flex-direction: column;
    }
  }

  .company-section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .monetization {
    padding: 24px;

    &-header {
      flex-direction: column;
      align-items: stretch;
    }

    &-filters {
      flex-direction: column;
      
      .filter-group {
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .table-container {
    overflow-x: auto;
  }
}

.mt-24 {
  margin-top: 24px;
}

.table-pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__item {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(42, 82, 152, 0.1);
    }

    &.active {
      background-color: rgba(42, 82, 152, 0.1);
    }
  }

  &__arrow {
    width: 16px;
    height: 16px;
    display: block;
  }

  &__arrow-hover {
    display: none;
    width: 16px;
    height: 16px;
  }

  &__item:hover {
    .table-pagination__arrow {
      display: none;
    }
    .table-pagination__arrow-hover {
      display: block;
    }
  }
}

.table-controls {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 16px;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  
  .history-btn {
    background: transparent;
    color: #02D15C;
    height: 44px;
    padding: 0 20px;
    border-radius: 12px;
    font-weight: 500;
    white-space: nowrap;
    border: 1px solid #02D15C;
    margin-bottom: 4px;
    
    &:hover {
      background: rgba(2, 209, 92, 0.1);
    }
  }
}

.tabs {
  display: flex;
  gap: 32px;
  position: relative;
  padding-bottom: 8px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 2px;
    background: #E5E7EB;
    z-index: 1;
  }

  .tab {
    position: relative;
    padding: 0 0 4px 0;
    color: #6B7280;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.3s ease;
    font-weight: 600;

    &:hover {
      color: #FE7300;
    }

    &.active {
      color: #FE7300;

      &:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #FE7300;
        border-radius: 2px;
        z-index: 2;
      }
    }
  }
}
</style> 