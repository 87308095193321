<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100 mb-4">
      Выберите итог заявки:
    </div>
    <select class="input-form select" v-model="ticket_result_id">
      <option disabled value=""></option>
      <option
        v-for="option in ticketResults"
        :key="option.key"
        :value="option.id"
      >
        {{ option.title }}
      </option>
    </select>
    <button type="button" class="btn mb-2 mt-4" @click="addTicketResult">Завершить</button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TicketResultModal',
  data() {
    return {
      ticket_result_id: 0
    }
  },
  methods: {
    addTicketResult() {
      this.$emit('submit', this.ticket_result_id)
    }
  },
  computed: {
    ...mapGetters({
      ticketResults: 'ticketResults',
    })
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

</style>
