import { api } from "@/service/axios";
import { rocketChatProvider } from "@/service/rocketChatServer";

function loginToRocketChat(credentials) {
    return rocketChatProvider.post('login', credentials);
}

function getRocketChatRooms() {
    return rocketChatProvider.get('v1/rooms.get', {
        params: {
            type: 'd' //d - direct messages
        }
    });
}

function getRocketChatUnreadCount(roomId) {
    return rocketChatProvider.get('v1/subscriptions.get', {
        params: {
            roomId
        }
    });
}

function getRocketChatUser(token) {
    return api.get('rocketchat/get-user')
}

export {
    loginToRocketChat,
    getRocketChatRooms,
    getRocketChatUnreadCount,
    getRocketChatUser
};
