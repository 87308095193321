<template>
  <div class="wrapper modified flex column">
    <div class="flex p-20">
      <div
          class="ticket-menu-blue flex justify-center align-center cursor-pointer mr-4"
          v-for="menu in menuMainArray"
          :key="menu.key"
          :class="{'active': currentTabMain === menu.key}"
          @click="changeTab(menu)"
      >
        {{ menu.title }}
      </div>
    </div>
    <div class="bg-white p-20 rounded flex column gap-2">
      <div class="w-100 p-20 border-bottom flex">
        <div
            class="ticket-menu flex justify-center align-center cursor-pointer mr-2"
            v-for="tab in tabs"
            :key="tab.label"
            :class="{'active': currentTab === tab.query.tab}"
            @click="switchTab(tab.query.tab)"
        >
          {{ tab.label }}
        </div>
      </div>
      <filter-bar     :is-filter="true"
                      :is-date="true"
                      :isStatuses="true"
                      @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
              }"
                      @on-filter="filterModalSubmit"></filter-bar>
      <custom-table
          :key="currentTab"
          :columns="tableColumns"
          :data="ticketsLCS"
          :total-pages="ticketsLCSTotalPages"
          :current-page="currentPage"
          :is-pagination="false"
          @get-detail="getTicketLCSDetail"
      />
      <PaginationComponent :total-pages="ticketsLCSTotalPages"
                           :current-page="currentPage"
                           @page-change="onPageChange"
      />
    </div>
  </div>
</template>


<script>
import {EmergencyTicketsLCSShortColumns, ServicesTicketsLCSShortColumns} from "@/modules/table-columns";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomTable from '@/components/modules/CustomTable.vue'
import router from "@/router";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import FilterBar from "@/components/modules/FilterBar.vue";
import {formatDate} from "@/helpers/utils";

export default {
  name: "TicketsLCSView",
  components: {FilterBar, PaginationComponent, CustomTable},
  data() {
    return {
      EmergencyTicketsLCSShortColumns,
      ServicesTicketsLCSShortColumns,
      currentPage: 1,
      tabs: [
        {
          label: 'Аварийные',
          query: {tab: 'emergency'}
        },
        {
          label: 'Сервисные',
          query: {tab: 'services'}
        },
        {
          label: 'Подключения',
          query: {tab: 'connections'}
        }
      ],
      menuMainArray: [
        {
          key: 'department',
          title: 'Открытые  заявки'
        },
        {
          key: 'all',
          title: 'Все заявки'
        },
        {
          key: 'closed',
          title: 'Закрытые заявки'
        }
      ],
      currentTab: '',
      currentTabMain: 'department',
      status_ids: [],
    }
  },
  async created() {
    this.currentTab = this.$route.query.tab || 'emergency';
    await this.fetchTicketKinds()
    await this.fetchData(this.currentTab);
  },
  watch: {
    '$route.query.tab'(newTab) {
      this.currentTab = newTab
      this.currentPage = 1
      this.fetchData(newTab)
    },
  },
  computed: {
    ...mapGetters({
      ticketsLCS: 'ticketsLCS',
      ticketsLCSTotalPages: 'ticketsLCSTotalPages',
      totalTicketsLCS: 'totalTicketsLCS',
      ticketKinds: 'ticketKinds',
    }),
    filter() {
      return {
        page: this.currentPage,
        tickets_per_page: 8,
        is_only_open: 1,
        ...(this.ticketKindId ? {ticket_kind_id: this.ticketKindId} : {}),
        ...(this.status_ids.length > 0 ? {status_ids: this.status_ids} : {}),
        ...(this.$route.query.is_expired  ? {is_expired: this.$route.query.is_expired} : {}),
        ...(this.$route.query.is_only_open  ? {is_only_open: this.$route.query.is_only_open} : {}),
        ...(this.dateRange && Array.isArray(this.dateRange) ? {date_from: formatDate(this.dateRange[0], '-'), date_to: formatDate(this.dateRange[1], '-') } : {}),
        ...(this.currentTab === 'emergency' ? {related_type: 'emergency'} : {}),
        ...(this.currentTab === 'services' ? {related_type: 'client'} : {}),
        ...(this.currentTab === 'connections' ? {related_type: 'connection'} : {}),
        ...(this.currentTabMain === 'closed' ? {is_closed: 1, is_only_open: null} : {}),
        ...(this.currentTabMain === 'all' ? {is_only_open: null} : {}),
      }
    },
    ticketKindId() {
      return this.ticketKinds.find(item => item.key === 'lks').id
    },
    tableColumns() {
      if (this.currentTab === 'emergency') {
        return EmergencyTicketsLCSShortColumns
      } else if (this.currentTab === 'services') {
        return ServicesTicketsLCSShortColumns
      } else if (this.currentTab === 'connections') {
        return ServicesTicketsLCSShortColumns
      } else return false
    }
  },
  methods: {
    ...mapActions({
      fetchTicketsLCS: 'fetchTicketsLCS',
      fetchTicketKinds: 'fetchTicketKinds',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async getTicketLCSDetail(ticket) {
      this.setLoading(true)
      router.push(`/ticket-lcs/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    switchTab(tab) {
      this.$router.push({name: 'tickets-lcs-list', query: {tab}});
    },
    async fetchData() {
      this.setLoading(true)
      await this.fetchTicketsLCS(this.filter)
      this.setLoading(false)
    },
    onPageChange(page = 1) {
      this.currentPage = page
      this.fetchData()
    },
    async changeTab(menu){
      this.currentTabMain = menu.key
      await this.fetchData()
    },
    filterModalSubmit(filters, statuses) {
      if (Array.isArray(statuses) && statuses.length > 0) {
        this.status_ids = statuses
      } else {
        this.status_ids = []
      }
      this.fetchData()
    },
  },
}
</script>


<style scoped lang="scss">
@import '../../theme/variables';

.label-text {
  font-size: 13px;
  color: #8b8b8b
}

::v-deep .choose-modal__title {
  font-size: 13px;
  color: #8b8b8b
}

::v-deep .vs__dropdown-toggle {
  height: 48px;
  border: 1px solid #ADADAD;
}

::v-deep .vs__search {
  margin-top: 0;
}

.ticket-menu {
  font-size: 18px;
  height: 48px;
  padding: 8px 16px 8px 12px;
  border-radius: 32px;
  text-transform: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  border: 1px solid #ADADAD;

  &.active {
    color: $white;
    background: linear-gradient(180deg, #FF6700 0%, #FFB800 100%);
    border: none;
  }
}
.ticket-menu-blue {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}
</style>