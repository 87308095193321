<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">Полигон {{ polygons?.name }}</div>
    <div class="inner flex column align-center">
      <yandex-map
        :coordinates="[defaultCenter.latitude, defaultCenter.longitude]"
        :zoom="15"
        :controls="['rulerControl', 'zoomControl', 'searchControl']"
        @created="mapInit"
        :width="200"
        v-if="polygons"
      >
        <yandex-marker
          :marker-id="`polygon`"
          type="Polygon"
          :coordinates="[swappedCoordinates]"
          :options="{
            iconColor: polygons?.color,
            preset: 'islands#circleIcon',
            strokeColor: polygons?.color,
            strokeWidth: 1,
            strokeOpacity: 0.6,
            fillColor: polygons?.color,
            opacity: .6
          }"
        />
      </yandex-map>
    </div>
  </div>
</template>

<script>
import { YandexMap, YandexMarker } from "vue-yandex-maps";
import { mapMutations } from "vuex";

export default {
  name: "PolygonsMap",
  components: {
    YandexMap,
    YandexMarker,
  },
  props: {
    polygons: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    defaultCenter: {
      latitude: "",
      longitude: "",
    },
    yandexMap: null,
    polygonFill: {
      color: "#ff0000",
      opacity: 0.1,
    },
    polygonStroke: {
      color: "#ff0000",
      width: 1,
    },
    polygonCoordinates: [],
  }),
  computed: {
    swappedCoordinates() {
      return this.polygons?.coordinates.map(([latitude, longitude]) => [
        longitude,
        latitude,
      ]);
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    async mapInit(mapInstance) {
      if (mapInstance) {
        this.yandexMap = mapInstance;
      }
    },
  },
  async mounted() {
    this.defaultCenter.latitude = this.swappedCoordinates[0][0];
    this.defaultCenter.longitude = this.swappedCoordinates[0][1];
  },
};
</script>

<style lang="scss" scoped>
.change-date {
  width: 750px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}
</style>
