<script setup>
import { computed, onMounted, ref, defineEmits, defineProps } from "vue"
import { useStore } from "vuex"
import RangeDatePicker from "@/components/modules/RangeDatePicker.vue"
import { formatDateReverse } from "@/helpers/utils"
import CustomModal from "@/components/modules/CustomModal.vue"
import router from "@/router";
import IpCheckResultModal from "@/components/modals/IpCheckResultModal.vue"
import AppSelect from "@/components/ui/AppSelect.vue";
import RelateToEmergencyModal from "@/components/modals/tickets/RelateToEmergencyModal.vue";
import {api} from "@/service/axios";

const store = useStore()
const client = computed(() => store.getters.client)
const addressOptions = computed(() => {
  if (!client.value) {
    return [];
  }

  return client.value.vgroups.map((item) => ({
    value: item.vg_id,
    label: item.address,
    blocked: item.blocked
  }));
})
const active_tickets_count = computed(() => client.value.dashboard.active_tickets.length)
const all_tickets_count = computed(() => client.value.dashboard.ticket_cnt)
const emit = defineEmits(['close'])

const isRelateModal = ref(false);
const emergencyTicketId = ref(null);

const selected_vgroup = ref(undefined)
async function select_vgroup(option) {
  console.log(option)
  selected_vgroup.value = client.value.vgroups.find(item => item.vg_id == option.value)

  store.commit("setLoading", true)
  await store.dispatch("fetchClientDashboard", {
    client_lb_id: client.value.uid,
    vgroup_id: option.value,
    date_from: date_range.value?.[0] ? formatDateReverse(date_range.value[0], '-') : undefined,
    date_to: date_range.value?.[1] ? formatDateReverse(date_range.value[1], '-') : undefined,
  });
  store.commit("setLoading", false)
}

const is_ip_modal = computed(() => store.getters.isIpCheckResultModal)
const check_ip_address = (ip) => store.dispatch("checkIpExtendedRework", ip)
const close_ip_modal = () => store.commit("setIsIpCheckResultModal", false)

const date_range = ref(null)
async function select_date_range(new_date_range) {
  date_range.value = new_date_range

  store.commit("setLoading", true)
  await store.dispatch("fetchClientDashboard", {
    client_lb_id: client.value.uid,
    vgroup_id: selected_vgroup.value?.vg_id,
    date_from: new_date_range?.[0] ? formatDateReverse(new_date_range[0], '-') : undefined,
    date_to: new_date_range?.[1] ? formatDateReverse(new_date_range[1], '-') : undefined,
  });
  store.commit("setLoading", false)
}

function createServiceTicket(vg_id) {
  router.push(`/service-ticket-form?vg_id=${vg_id}`)
  emit('close')
}

function goToTicket(id) {
  router.push(`/ticket/${id}`)
  emit('close')
}

async function toggleRelateModal() {
  if(!isRelateModal.value) {
    store.commit("setLoading", true)
    await store.dispatch('fetchCurrentEmergencyTicketList');
    store.commit("setLoading", false)
  }
  isRelateModal.value = !isRelateModal.value
}

async function relateEmergencyTicket(id) {
  if(!client.value) {
    return;
  }

  try {
    store.commit("setLoading", true)
    const res = await api.post('tickets/relate-ticket-and-client', {
      ticket_id: id,
      client_lanbilling_id: client.value.uid
    })

    console.log(res);

    if (!res.success) {
      throw new Error(res?.message || 'Ошибка при добавлении клиента к заявке');
    }

    store.dispatch('successAlert', 'Клиент успешно связан с аварией')
  } catch (err) {
    store.dispatch('errorAlert', err?.message)
  } finally {
    store.commit("setLoading", false)
    void toggleRelateModal()
  }
}
</script>

<template>
  <custom-modal v-if="isRelateModal" @close="toggleRelateModal">
    <relate-to-emergency-modal :ticket-id="emergencyTicketId" @submit="relateEmergencyTicket" />
  </custom-modal>

  <div class="client-dashboard flex--column gap-24">
    <div class="flex gap-24">
      <div class="flex--column gap-12">
        <div
          class="client-dashboard__card--gradient flex--space"
          :class="client.dashboard.loyalty.code"
        >
          <span>
            <p class="client-dashboard__title">{{ client.name }}</p>
            <div class="client-dashboard__tickets">
              <span class="client-dashboard__tickets__item--blue">
                <p class="client-dashboard__tickets__item__value">{{ all_tickets_count }}</p>
                <p class="client-dashboard__tickets__item__label">Заявок</p>
              </span>
              <p class="text-20 text-gray2">|</p>
              <span class="client-dashboard__tickets__item--green">
                <p class="client-dashboard__tickets__item__value">{{ active_tickets_count }}</p>
                <p class="client-dashboard__tickets__item__label">Активные</p>
              </span>
            </div>
          </span>

          <span>
            <img
              class="client-dashboard__card__icon"
              :src="`/assets/client-dashboard/${client.dashboard.loyalty.code}.svg`"
              alt="loyalty icon"
            />
          </span>
        </div>

        <div class="client-dashboard__card flex--column gap-12">
          <range-date-picker
            :date-range="date_range"
            @set-date-range="select_date_range"
          />

          <div class="client-dashboard__card__content" style="height: 240px;">
            <table>
              <thead>
                <tr>
                  <th>КАТЕГОРИЯ</th>
                  <th>ТИП ПРОБЛЕМЫ</th>
                  <th>ИТОГО ЗАЯВОК</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="categoryTicket, idx in client.dashboard.category_tickets" :key="idx">
                  <td>{{ categoryTicket.ticket_category_title }}</td>
                  <td>{{ categoryTicket.problem_type_title }}</td>
                  <td>{{ categoryTicket.ticket_count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="client-dashboard__card flex--column gap-24">
        <div style="margin-right: 40px;">

          <AppSelect :options="addressOptions" placeholder="Выберите адрес" @change="select_vgroup" />

        </div>
        <div v-if="selected_vgroup" class="client-dashboard__card__content" style="height: 350px;">
          <template v-if="selected_vgroup?.ip_address">
            <div
                v-for="(ip, idx) in selected_vgroup?.ip_address.split(',')" :key="idx"
                class="client-dashboard__card__content__item"
            >
              <b>IP Адрес {{ ++idx }}</b>
              <a @click="check_ip_address(ip)">{{ ip }}</a>
            </div>
          </template>
          <div class="client-dashboard__card__content__item">
            <b>Узел подключения</b>
            <span>{{ selected_vgroup?.vgroup_name }}</span>
          </div>
          <div class="client-dashboard__card__content__item">
            <b>Метод подключения</b>
            <span>{{ selected_vgroup?.connection_method }}</span>
          </div>
          <div class="client-dashboard__card__content__item">
            <b>Логин</b>
            <span>{{ selected_vgroup?.login }}</span>
          </div>
          <div v-if="selected_vgroup?.descr" class="client-dashboard__card__content__item">
            <b>Описание</b>
            <span>{{ selected_vgroup.descr }}</span>
          </div>
        </div>
        <button
          v-if="selected_vgroup"
          class="dashboard-button"
          @click="createServiceTicket(selected_vgroup.vg_id)"
        >
          Создать заявку
        </button>
        <div class="flex justify-center" v-if="selected_vgroup">
        <button
            v-if="selected_vgroup.blocked === 0"
            type="button"
            class="btn form-btn mb-4 relate-btn"
            @click="toggleRelateModal"
        >
          связать с аварией
        </button>
        </div>
      </div>
    </div>

    <div class="client-dashboard__card">
      <div class="client-dashboard__card__content" style="height: 348px;">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>СТАТУС</th>
              <th>АДРЕС</th>
              <th>КАТЕГОРИЯ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="activeTicket, idx in client.dashboard.period_tickets.tickets" :key="idx" @click="goToTicket(activeTicket.id)" class="cursor-pointer">
              <td>{{ activeTicket.ticket_number }}</td>
              <td>{{ activeTicket.status.title }}</td>
              <td>{{ activeTicket.address }}</td>
              <td>{{ activeTicket.category.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <custom-modal v-if="is_ip_modal" @close="close_ip_modal" :custom-class="'bg-gray'">
      <ip-check-result-modal />
    </custom-modal>
  </div>
</template>

<style scoped lang="scss">
@import '../../../theme/variables';

.flex, .flex--column, .flex--space {
  display: flex;
}
.flex--column {
  flex-direction: column;
}
.flex--space {
  align-items: center;
  justify-content: space-between;
}
.gap-12 {
  gap: 12px;
}
.gap-24 {
  gap: 24px;
}

.clear-button {
  margin-left: 5px;
  padding: 0;
  font-weight: 700;
  color: #EE3D56;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.relate-btn {
  margin: 0 auto;
}

.client-dashboard {
  height: 100%;
  padding: 30px;
  background: url("~/public/assets/client-dashboard/client-dashboard-bg.png");

  &__card, &__card--gradient {
    width: 100%;
    height: 100%;
    padding: 22px;
    background: #fff;
    border-radius: 15px;

    &__buttons {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 8px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 0;
        border: 0;
        outline: 0;
        background: #F3F4F9;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
        &:active {
          opacity: .7;
        }
      }
    }
    &__content {
      height: 100%;
      padding: 16px 30px 8px;
      background: #F5F5FA;
      border-radius: 15px;
      overflow: auto;

      &__item {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background: #fff;
        border-radius: 15px;

        &:not(:first-child) {
          margin-top: 8px;
        }

        b {
          color: #000;
          font-size: 14px;
          font-weight: 700;
        }
        span, a {
          color: #606060;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
        }
        a {
          color: #156BA8;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        overflow: auto;

        thead {
          font-size: 14px;
          font-weight: 700;
          color: #8B88AB;
          th {
            padding: 0 20px 10px;
            text-align: start;
          }
        }
        tbody {
          font-size: 14px;
          font-weight: 400;
          color: #333;
          tr {
            background: #FFF;
          }
          td {
            padding: 20px;
            &:first-child {
              border-radius: 15px 0 0 15px;
            }
            &:last-child {
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }
    }
  }
  &__card--gradient {
    width: 664px;
    height: 138px;

    &.promoter {
      background: linear-gradient(
        0deg,
        rgba(22, 230, 67, 0.22) 4%,
        #fff 40%
      );
    }
    &.detractor {
      background: linear-gradient(
        0deg,
        rgba(241, 69, 94, 0.44) 4%,
        #fff 40%
      );
    }
    &.neutral {
      background: linear-gradient(
        0deg,
        rgba(255, 169, 3, 0.44) 4%,
        #fff 40%
      );
    }
  }

  &__title {
    font-size: 24px;
  }
  &__tickets {
    display: flex;
    gap: 16px;
    margin-top: 16px;

    &__item, &__item--blue, &__item--green {
      &__value {
        font-size: 24px;
        font-weight: 700;
        color: inherit;
      }
      &__label {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: inherit;
      }
    }
    &__item--blue {
      color: #0095FF;
    }
    &__item--green {
      color: #159C33;
    }
    &__divider {
      width: 1px;
      height: 23px;
      border-radius: 1px;
      background: #8B88AB;
    }
  }
}
</style>
