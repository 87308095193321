import {api} from '@/service/axios'

export const ticketsOTPModule = {
    state: () => ({
        OTPCategory: [],
        OTPDepartments: [],
        OTPTicketsTotal: [],
        OTPManagersTickets: [],
        OTPManagerTicketInfo: []
    }),
    getters: {
        OTPCategory(state) {
            return state.OTPCategory
        },
        OTPDepartments(state){
            return state.OTPDepartments
        },
        OTPTicketsTotal(state) {
            return state.OTPTicketsTotal
        },
        OTPManagersTickets(state){
            return state.OTPManagersTickets
        },
        OTPManagerTicketInfo(state){
            return state.OTPManagerTicketInfo
        }
    },
    mutations: {
        setOTPCategory(state, payload) {
            state.OTPCategory = payload
        },
        setOTPDepartments(state, payload) {
            state.OTPDepartments = payload
        },
        setOTPTicketsTotal(state, payload) {
            state.OTPTicketsTotal = payload
        },
        setOTPManagersTickets(state, payload) {
            state.OTPManagersTickets = payload
        },
        setOTPManagerTicketInfo(state, payload) {
            state.OTPManagerTicketInfo = payload
        }
    },
    actions: {
        async fetchOTPCategories({commit}, payload) {
            const res = await api.get(`dict/get-otp-category`)
            commit('setOTPCategory', res.data)
            return res
        },
        async fetchOTPDepartments({commit}, payload){
            const res = await api.get(`dict/get-otp-departments`)
            commit('setOTPDepartments', res.data)
            return res
        },
        async fetchOTPTicketsTotal({commit}, payload){
            const res = await api.get('ticket-reports/otp-tickets-total', {
                params: payload
            })
            commit('setOTPTicketsTotal', res.data)
            return res
        },
        async fetchOTPManagersTickets({commit}, payload){
            const res = await api.get('ticket-reports/managers-ticket-count', {
                params: payload
            })
            commit('setOTPManagersTickets', res.data)
            return res
        },
        async fetchOTPManagerTicketInfo({commit}, payload){
            const res = await api.get('ticket-reports/manager-responsible-tickets', {params: payload})
            commit('setOTPManagerTicketInfo', res.data)
            return res
        }
    }
}