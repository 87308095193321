<template>
  <div class="wrapper modified flex column">
    <div class="block">
      <emergency-ticket-details-component v-if="chosenTicket?.ticket?.id" />
    </div>
  </div>
</template>

<script>
import TicketDetailsComponent from "@/components/cards/TicketDetailsComponent.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import EmergencyTicketDetailsComponent from "@/components/cards/emergencyTickets/EmergencyTicketDetailsComponent.vue";
export default {
  name: 'EmergencyTicketDetailView',
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EmergencyTicketDetailsComponent,
  },
 async created() {
   this.setLoading(true);
   try {
     if (this.isModal === false) {
       await this.fetchTicketDetail(this.$route.params.id);
     }
   } catch (error) {
     console.error("Ошибка при загрузке деталей заявки:", error);
   } finally {
     this.setLoading(false);
   }
  },
  watch: {
    '$route.params.id'(newId) {
    this.getEmergencyTicketDetailInfo(newId)
    }
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
    })
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'ticketDetail',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setChosenTicket: 'setChosenTicket'
    }),
   async getEmergencyTicketDetailInfo(id) {
      this.setLoading(true)
     await this.fetchTicketDetail(id).then(response => {
        this.setLoading(false)
      });
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

</style>
