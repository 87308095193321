const discharge = {
    0: {
        gen: 'man',
        century: 1000
    },
    1: {
        gen: "man",
        century: 1800,
    },
    2: {
        gen: "woman",
        century: 1800,
    },
    3: {
        gen: "man",
        century: 1900,
    },
    4: {
        gen: "woman",
        century: 1900,
    },
    5: {
        gen: "man",
        century: 2000,
    },
    6: {
        gen: "woman",
        century: 2000,
    },
};

function validateIin(i) {
    if (i.length === 0) {
        return false;
    }
    if (i.match(/\D/)) {
        return false;
    }
    let iin = i;
    if (iin.length !== 12) {
        return false;
    }
    if (!Object.prototype.hasOwnProperty.call(discharge, iin[6])) {
        return false;
    }
    let year = discharge[iin[6]].century + parseInt(iin.slice(0, 2));
    let month = parseInt(iin.slice(2, 4));
    let day = parseInt(iin.slice(4, 6));
    if (day === 0 || day > 31) {
        return false;
    }
    if (!month || month > 12) {
        return false;
    }
    if (new Date().getFullYear() < year) {
        return false;
    }

    let b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    let b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    let a = [];
    let control = 0;
    for (let i = 0; i < 12; i++) {
        a[i] = parseInt(iin.substring(i, i + 1));
        if (i < 11) control += a[i] * b1[i];
    }
    control = control % 11;
    if (control === 10) {
        control = 0;
        for (let i = 0; i < 11; i++) control += a[i] * b2[i];
        control = control % 11;
    }
    return control === a[11];
}

export { validateIin }