import {api} from "@/service/axios";
import {request} from "axios";

export const departureServiceModule = {
    state: () => ({
        departureServiceTickets: [],
        departureServiceTicketsFilters: [],
        finishedServiceTickets: [],
        departureServiceTicketResponsibles: [],
        serviceDepartments: [],
        allResponsibles: [],
        ticketsByDepartment: [],
        ticketsByDepartmentFilters: [],
    }),
    getters: {
        departureServiceTickets: state => {
            return state.departureServiceTickets;
        },
        departureServiceTicketsFilters: state => {
            return state.departureServiceTicketsFilters;
        },
        finishedServiceTickets: state => {
            return state.finishedServiceTickets;
        },
        departureServiceTicketResponsibles: state => {
            return state.departureServiceTicketResponsibles;
        },
        serviceDepartments: state => {
            return state.serviceDepartments;
        },
        allResponsibles: state => {
            return state.allResponsibles;
        },
        ticketsByDepartment: state => {
            return state.ticketsByDepartment;
        },
        ticketsByDepartmentFilters: state => {
            return state.ticketsByDepartmentFilters;
        },
    },
    mutations: {
        setDepartureServiceTickets(state, payload) {
            state.departureServiceTickets = payload;
        },
        sortTickets(state, sortedValue) {
            state.departureServiceTickets.tickets = [...state.departureServiceTickets.tickets].sort((a, b) => {
                const titleA = String(a[sortedValue] || '');
                const titleB = String(b[sortedValue] || '');
                return titleA.localeCompare(titleB);
            });
        },
        setDepartureServiceTicketsFilters(state, payload) {
            state.departureServiceTicketsFilters = payload;
        },
        setFinishedServiceTickets(state, payload) {
            state.finishedServiceTickets = payload;
        },
        setDepartureServiceTicketResponsibles(state, payload) {
            state.departureServiceTicketResponsibles = payload;
        },
        setServiceDepartments(state,payload){
            state.serviceDepartments = payload;
        },
        setAllResponsibles(state,payload){
            state.allResponsibles = payload;
        },
        setTicketsByDepartment(state, payload) {
            state.ticketsByDepartment = payload;
        },
        setTicketsByDepartmentFilters(state, payload) {
            state.ticketsByDepartmentFilters = payload;
        },
    },
    actions: {
        async fetchDepartureServiceTickets({commit}, payload) {
            const res = await api.get(`tickets/ticket-list`, {
                params: payload
            })
            commit('setDepartureServiceTickets', res.data)
            // commit('setDepartureServiceTicketsFilters', res.filters)
            return res
        },
        async fetchTicketsByDepartment({commit}, payload) {
            const res = await api.get(`tickets/ticket-list-by-department`, {
                params: {
                    ...payload,
                    is_only_open: 1
                }
            })
            commit('setTicketsByDepartment', res.data)
            commit('setTicketsByDepartmentFilters', res.filters)
            return res
        },
        async fetchDepartureServiceClosedInfo({commit}, payload) {
            const res = await api.get('/tickets/closed-counts', {
                params: payload
            })
            commit('setFinishedServiceTickets', res)
            return res
        },
        async makeExcelList({commit}, payload){
            const res = await api.get(`tickets/ticket-list`, {
                params: payload,
                responseType: 'blob'
            })
            return res
        },
        async makeDepartmentExcelList({commit}, payload){
            const res = await api.get(`tickets/ticket-list-by-department`, {
                params: payload,
                responseType: 'blob'
            })
            return res
        },
        async fetchDepartureServiceTicketResponsibles({commit}, payload){
            const resBrigades = await api.get('dict/get-ticket-responsibles')
            resBrigades.data.forEach((item) => {
                item.full_name = item.title
                item.id = item.responsible_id
            })
            commit('setDepartureServiceTicketResponsibles', resBrigades.data)
        },
        async fetchServiceDepartments({commit}, payload){
            const res = await api.get('dict/get-service-departments')
            commit('setServiceDepartments', res.data)
            return res
        },
        async changePlannedDate({commit}, payload){
            const res = await api.put(`tickets/${payload.id}/planned-date`, {planned_date: payload.planned_date} )
            return res
        },
        async fetchAllResponsibles({commit}, payload){
            const res = await api.get(`dict/get-ticket-responsible-all`, {params: payload})
            commit('setAllResponsibles', res.data)
            return res
        }
    }
}