import axios from 'axios';
import Cookies from 'js-cookie';
import router from '@/router';
import store from "@/store";
import { useToast } from "vue-toast-notification";

const baseUrl = process.env.VUE_APP_API_URL;
const toast = useToast();

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 100000,
})

api.interceptors.request.use((config) => {
  // const token = Cookies.get('token');
  // if (token) {
  //   config.headers['Authorization'] = `Bearer ${token}`;
  // }

  const token = localStorage.getItem("token");
	config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
    (response) => {
      // if (response.data.error === 401) {
      //   Cookies.remove('token');
      //   toast.error('Требуется повторная авторизация.');
      //   router.push('/login');
      // }
      // return response.data;

      return Promise.resolve(response.data);
    },
    (error) => {
      store.commit('setLoading', false);
      if (error.response) {
        const status = error.response.status;
        if (!status) {
          toast.error('Произошла неизвестная ошибка')
        }

        if (status === 401) {
          // Cookies.remove('token');
          localStorage.removeItem("token");

          toast.error('Некорректно указан логин или пароль');
          router.push('/login');
        }

        if (status === 403) {
          toast.error('Нет доступа.', { timeout: 5000 });
        }

        if (status >= 500) {
          toast.error('Ошибка сервера. Попробуйте позже.');
        }
      } else if (error.code === "ERR_NETWORK") {
        toast.error('Ошибка подключения. Проверьте интернет.');
      }

      return Promise.reject(error.response?.data || error);
    }
);

export { api };
