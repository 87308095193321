<template>
  <div class="modal-content">
    <h2 class="modal-title">ПРОСМОТР ДУБЛЕЙ</h2>
    
    <div class="results-container">
      <!-- Table Header -->
      <div class="table-header">
        <div class="th company">Компания</div>
        <div class="th serial">Серийный номер</div>
        <div class="th name">Наименование оборудования</div>
      </div>

      <!-- Table Body -->
      <div class="table-body">
        <!-- NLS Equipment -->
        <template v-if="nlsData?.data?.length">
          <div v-for="(item, index) in nlsData.data" :key="'nls-'+index" class="table-row">
            <div class="td company">
              <input 
                type="checkbox" 
                class="checkbox"
                v-model="selectedCompanies"
                :value="'nls-'+index"
              />
              NLS
            </div>
            <div class="td serial">{{ item.eq_serial }}</div>
            <div class="td name">{{ item.eq_name }}</div>
          </div>
        </template>

        <!-- Meganet Equipment -->
        <template v-if="meganetData?.data?.length">
          <div v-for="(item, index) in meganetData.data" :key="'meganet-'+index" class="table-row">
            <div class="td company">
              <input 
                type="checkbox" 
                class="checkbox"
                v-model="selectedCompanies"
                :value="'meganet-'+index"
              />
              Meganet
            </div>
            <div class="td serial">{{ item.eq_serial }}</div>
            <div class="td name">{{ item.eq_name }}</div>
          </div>
        </template>
      </div>
    </div>

    <div class="modal-actions">
      <button class="btn-save" @click="save">Сохранить</button>
      <button class="btn-cancel" @click="$emit('close')">Отмена</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DuplicateCheckModal',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedCompanies: []
    }
  },
  computed: {
    meganetData() {
      return this.data?.meganet || {}
    },
    nlsData() {
      return this.data?.nls || {}
    }
  },
  methods: {
    save() {
      // Handle save action
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modal-content {
  width: 100%;
  max-width: 754px;
  // padding: 24px;
}

.modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
}

.results-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 24px;
  background: white;

  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
    
    &:hover {
      background: #a1a1a1;
    }
  }
}

.table-header {
  display: grid;
  grid-template-columns: 140px 140px 1fr;
  gap: 12px;
  padding: 16px;
  background: #F5F5FA;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.table-body {
  .table-row {
    display: grid;
    grid-template-columns: 140px 140px 1fr;
    gap: 12px;
    padding: 16px;
    background: white;

    &:last-child {
      border-bottom: none;
    }
  }
}

.th {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.td {
  font-size: 14px;
  color: #333;

  &.company {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #D1D5DB;
  cursor: pointer;
  margin: 0;

  &:checked {
    background-color: #0085FF;
    border-color: #0085FF;
  }
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  align-items: center;

  button {
    width: 100%;
    max-width: 350px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-save {
    background: linear-gradient(268.85deg, #04567B 1.72%, #0095FF 100%);
    color: white;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #333;
    border: none;

    &:hover {
      background: #F5F5FA;
    }
  }
}
</style> 