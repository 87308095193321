<template>
  <CustomModal @close="$emit('close')" :title="isEdit ? 'Редактировать задачу' : 'Добавить задачу'">
    <div class="add-task-form">
      <h2 class="modal-title">{{ isEdit ? 'Редактировать задачу' : 'Добавить задачу' }}</h2>
      <BaseInput
        v-if="task.company"
        v-model="task.company"
        label="Компания"
        :disabled="true"
        :value="task.company === 'meganet' ? 'MEGANET' : 'NLS'"
      />
      <BaseDropdown
        v-else
        v-model:selectedValue="task.company"
        :items="companyItems"
        label="Компания"
        placeholder="Выберите компанию"
      />
      <BaseInput
        v-if="task.reason"
        v-model="task.reason"
        label="Тип задачи"
        :disabled="true"
        :value="
          task.reason === 'connection'
            ? 'Привлечение'
            : task.reason === 'add-sale'
            ? 'Допродажа'
            : ''
        "
      />
      <BaseDropdown
        v-else
        v-model:selectedValue="task.reason"
        :items="
          dropdownOptions.taskTypes.map((opt) => ({
            key: opt.value,
            title: opt.label,
          }))
        "
        label="Тип задачи"
        placeholder="Выберите тип задачи"
      />
      <div class="form-group">
        <BaseDropdown
          v-model:selectedValue="task.costType"
          :items="
            dropdownOptions.costTypes.map((opt) => ({
              key: opt.value,
              title: opt.label,
            }))
          "
          label="Тип стоимости"
          placeholder="Выберите тип стоимости"
        />
        <BaseInput
          v-if="task.costType === 'rate'"
          v-model="task.value"
          type="number"
          label="Процент"
          placeholder="Введите процент"
        />
        <BaseInput
          v-else-if="task.costType === 'fixed'"
          v-model="task.value"
          type="number"
          label="Сумма"
          placeholder="Введите сумму"
        />
      </div>
      <BaseDropdown
        v-model:selectedValue="task.service_type"
        :items="
          dropdownOptions.serviceTypes.map((opt) => ({
            key: opt.value,
            title: opt.label,
          }))
        "
        label="Тип начисления"
        placeholder="Выберите тип начисления"
      />
      <BaseInput
        v-model="task.description"
        label="Описание"
        placeholder="Введите описание"
      />
      <div class="checkbox-container">
        <label class="checkbox-label">
          <input
            type="checkbox"
            v-model="task.weekend"
            class="checkbox-input"
          />
          <span class="checkbox-text">Выходной день</span>
        </label>
      </div>
      <div class="modal-actions">
        <button class="btn btn-primary" @click="handleSubmit">{{ isEdit ? 'Сохранить' : 'Добавить' }}</button>
        <button class="btn btn-secondary" @click="$emit('close')">
          Отмена
        </button>
      </div>
    </div>
  </CustomModal>
</template>

<script>
import CustomModal from "@/components/modules/CustomModal.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import BaseInput from "@/components/ui/BaseInput.vue";

export default {
  name: "CreateTaskModal",
  components: { CustomModal, BaseDropdown, BaseInput },
  props: {
    initialTask: {
      type: Object,
      default: () => ({
        company: "",
        reason: "",
        costType: "",
        value: null,
        service_type: "",
        description: "",
        weekend: false,
      }),
    },
    companyItems: {
      type: Array,
      required: true,
    },
    dropdownOptions: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: [Number, String],
      default: null
    }
  },
  emits: ["close", "submit"],
  data() {
    return {
      task: { ...this.initialTask },
    };
  },
  methods: {
    handleSubmit() {
      const submitData = {
        company: this.task.company,
        reason: this.task.reason,
        type: this.task.costType,
        postfix: this.task.costType === "rate" ? "%" : "₸",
        value: this.task.value,
        service_type: this.task.service_type,
        description: this.task.description,
        weekend: this.task.weekend ? 1 : 0,
      };

      if (this.isEdit && this.taskId) {
        submitData.id = this.taskId;
      }

      this.$emit("submit", submitData);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-task-form {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
    padding-bottom: 8px;
  }

  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: end;
  }

  .modal-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid #eef2f6;
    padding-top: 16px;

    .btn {
      width: 100%;
      height: 44px;
      font-weight: 500;
      border-radius: 12px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s ease;
      text-transform: none;
      letter-spacing: 0.5px;
    }

    .btn-primary {
      background: #FE7300;
      color: white;
      font-weight: 500;

      &:hover {
        background: darken(#FE7300, 5%);
      }
    }

    .btn-secondary {
      background: white;
      color: black;
      border: none;
      font-weight: 400;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.checkbox-container {
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .checkbox-input {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    cursor: pointer;
  }

  .checkbox-text {
    font-size: 14px;
    color: #333;
  }
}

@media (max-width: 768px) {
  .add-task-form {
    .form-group {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
</style>
