<template>
  <div class="form w-100 flex column">
    <div class="form__title w-100">Изменить</div>
    <div class="form__body w-100">
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Приоритет</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              v-model="payload.title"
              disabled
              ref="priority"
            />
          </div>
        </div>
      </div>
      <div class="form__body_item">
        <div class="form__row">
          <div class="form__row_label">Крайние сроки выполнения</div>
          <div class="form__row_field">
            <input
              class="input-form"
              type="text"
              :value="payload[`${tab}_work_time`]"
              v-mask="
                isMask
                  ? `+${payload[`${tab}_work_time`]} суточных часа от даты создания заявки`
                  : '##'
              "
              ref="priority"
              @focusin="isMask = false"
              @focusout="isMask = true"
              @change="payload[`${tab}_work_time`] = $event.target.value"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form__body w-100 mt-3 flex justify-between align-center">
      <button class="form__body_cancel" @click="$emit('close')">Отмена</button>
      <button
        class="form__body_submit"
        :class="tabStyle.header"
        @click="$emit('submit', payload)"
      >
        Создать
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabStyle: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      payload: {
        key: this.detail?.key,
        title: this.detail?.title,
        service_work_time: this.detail?.service_work_time,
        emergency_work_time: this.detail?.emergency_work_time,
        plan_work_time: this.detail?.plan_work_time,
        id: this.detail?.id,
      },
      mask: "+# суточных часа от даты создания заявки",
      isMask: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.form {
  &__title {
    font-size: 22px;
    color: black;
    font-weight: 400;
    margin-top: 20px;
    text-transform: none;
  }
  &__body {
    padding: 0 20px;
  }
  width: 50vw;
}
.input-form:disabled {
  background: #f9fafb;
}
.form__body_submit {
  font-size: 22px;
  border: none;
  outline: none;
  color: white;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;
}
.form__body_cancel {
  font-size: 22px;
  border: 1px solid gray;
  background: none;
  outline: none;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;
}
</style>
