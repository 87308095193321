import {api} from '@/service/axios'

export const scheduleModule = {
  state: () => ({

  }),
  getters: {

  },
  mutations: {

  },
  actions: {
    async fetchBrigadeSchedule ({commit}, payload) {
      return await api.get(`brigade-work/get-brigade-schedule`, {
          params: payload
        }
      )
    },
    async changeBrigadeSchedule ({commit}, payload) {
      return await api.post(`brigade-work/change-brigade-schedule`, payload)
    },
  }
}
