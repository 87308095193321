<template>
  <div class="wrapper modified flex column">
    <div class="bg-white p-20 rounded">
      <div class="filters-container">
        <!-- Фильтры -->
        <div class="w-100">
          <BaseSearch
            label="Поиск"
            v-model="search"
            @update:modelValue="debouncedSearch"
            :disabled="loading"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
            label="Статус записи"
            placeholder="Выберите статус"
            :items="statuses"
            v-model:selectedValue="status_key"
            @change="handleStatusChange"
            :disabled="loading"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
            label="Бригады"
            placeholder="Выберите бригаду"
            :items="brigades"
            v-model:selectedValue="brigade_id"
            @change="selectBrigade"
            :disabled="loading"
            keyProp="id"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
            label="Склад"
            placeholder="Выберите склад"
            :items="warehouses"
            v-model:selectedValue="storage_key"
            @change="selectStorage"
            :disabled="loading"
          />
        </div>

        <!-- Фильтры -->
        <div class="w-100">
          <BaseCalendar
            label="Период"
            @setDate="handleDateChange"
            :disabled="loading"
            ref="calendar"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
            label="Компания"
            placeholder="Выберите компанию"
            :items="companies"
            v-model:selectedValue="company_key"
            @change="selectCompany"
            :disabled="loading"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
              label="Cтатус оборудования"
              placeholder="Выберите статус оборудования"
              :items="equipmentStatuses"
              v-model:selectedValue="equipment_status_key"
              @change="selectEquipmentStatus"
              :disabled="loading"
          />
        </div>

        <div class="w-100">
          <BaseDropdown
              label="Местоположение"
              placeholder="Выберите местоположение"
              :items="equipmentLocations"
              v-model:selectedValue="location_key"
              @change="selectLocation"
              :disabled="loading"
          />
        </div>

        <!-- Кнопки фильтров -->
        <div class="filter-buttons">
          <button class="filter-btn"
                  style="background: #2ab161;color: white;" @click="downloadExcel">
            <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
            <div>Выгрузить</div>
          </button>
          <button 
            class="filter-btn save-btn"
            @click="saveFilters"
            :disabled="loading"
          >
            <img src="/assets/save-filters-icon.svg" alt="save" class="filter-icon mr-1"/>
            Сохранить фильтры
          </button>
          <button 
            class="filter-btn reset-btn"
            @click="resetFilters"
            :disabled="loading"
          >
            <img src="/assets/reset-filters-icon.svg" alt="reset" class="filter-icon mr-1"/>
            Сбросить фильтры
          </button>
        </div>

        <div class="table-controls">
<!--          <button class="excel-download-btn">-->
<!--            <img src="/assets/excel-download.svg" alt="excel" class="excel-icon"/>-->
<!--            Выгрузить в Excel-->
<!--          </button>-->
          <BaseItemsPerPage
            v-model="itemsPerPage"
            :options="[10, 20, 30]"
          />
        </div>
      </div>

      <!-- Table section -->
      <div class="table-container mt-30">
        <template v-if="virtualWarehouses.length">
          <div class="grid gridText">
            <div class="grid-num">
              <p>№</p>
            </div>
            <div class="grid-ticket flex align-center justify-center">
              <p class="text-center">Номер заявки</p>
            </div>
            <div class="grid-company flex align-center justify-center">
              <p class="text-center">Компания</p>
            </div>
            <div class="grid-serial flex align-center justify-center">
              <p class="text-center">Серийный номер</p>
            </div>
            <div class="grid-brigade flex align-center justify-center">
              <p class="text-center">Бригада</p>
            </div>
            <div class="grid-iin flex align-center justify-center">
              <p class="text-center">БИН/ИИН</p>
            </div>
            <div class="grid-location flex align-center justify-center">
              <p class="text-center">Адрес</p>
            </div>
            <div class="grid-device flex align-center justify-center">
              <p class="text-center">Наименование оборудования</p>
            </div>
            <div class="grid-equipment-location flex align-center justify-center">
              <p class="text-center">Местоположение</p>
            </div>
            <div class="grid-equipment-status flex align-center justify-center">
              <p class="text-center">Статус оборудования</p>
            </div>
            <div class="grid-status flex align-center justify-center">
              <p class="text-center">Статус записи</p>
            </div>
            <div class="grid-recommendation flex align-center justify-center">
              <p class="text-center">Рекомендованное действие</p>
            </div>
            <div class="grid-actions flex align-center justify-center">
              <p class="text-center">Действия</p>
            </div>
            <div class="grid-actions flex align-center justify-center">
              <p class="text-center">Дата создания</p>
            </div>
          </div>
          <div class="departure-table">
            <div class="grid cursor-pointer"
              :id="list.id" 
              @click="onTableClick(list.id)"
              v-for="(list, index) in paginatedWarehouses" 
              :key="list.id"
            >
              <div class="grid-num">{{ ((currentPage - 1) * itemsPerPage) + index + 1 }}</div>
              <div class="grid-ticket clickable" @click.stop="openTicketInfo(list.ticket_id, list.ticket_type)">
                {{ list.ticket_number || 'Не указано' }}
              </div>
              <div class="grid-company">
                {{ list?.company?.title || 'Не указано' }}
              </div>
              <div class="grid-serial">
                {{ list.serial_number || 'Не указано' }}
              </div>
              <div class="grid-brigade">
                {{ list?.brigade?.title || 'Не указано' }}
              </div>
              <div class="grid-iin">
                {{ list.iin || 'Не указано' }}
              </div>
              <div class="grid-location">
                {{ list.address || 'Не указано' }}
              </div>
              <div class="grid-device">
                {{ list.device_name || 'Не указано' }}
              </div>
              <div class="grid-equipment-location">
                {{ list?.equipment_location?.title || 'Не указано' }}
              </div>
              <div class="grid-equipment-status">
                {{ list?.equipment_status?.title || 'Не указано' }}
              </div>
              <div class="grid-status" 
                  :class="{'status-new': list?.status?.key === 'new', 'status-moved': list?.status?.key === 'delivered_to_client'}"
              >
                {{ list?.status?.title || 'Не указано' }}
                <!-- <img src="/assets/status.svg" @click.stop="openChangeStatus(list)"/> -->
              </div>
              <div class="grid-recommendation">
                {{ list.recommendation || 'Не указано' }}
              </div>
              <div class="grid-actions">
                <img 
                  src="/assets/status.svg" 
                  @click.stop="openChangeStatus(list)"
                  title="Изменить статус"
                />
                <!-- Изменение склада -->
                <!-- <img 
                  src="/assets/warehouse-1.svg" 
                  @click.stop="openChangeWarehouse(list)" 
                  title="Изменить склад"
                /> -->
                <img 
                  src="/assets/warehouse.svg" 
                  @click.stop="checkDuplicate(list.id)"
                  title="Проверить дубликаты в 1С"
                />
              </div>
              <div>
                {{ formatDate(list.created_at) }}
              </div>
            </div>
          </div>
        </template>
        <div v-else class="no-data">
          <img src="/assets/warning-icon.svg" alt="warning" class="warning-icon"/>
          <p>По вашему запросу ничего не найдено</p>
        </div>
      </div>

      <!-- Update pagination markup to match table.scss styles -->
      <div class="table-pagination mt-24" v-if="virtualWarehouses.length && totalPages > 1">
        <div class="table-pagination__item" @click="currentPage > 1 && currentPage--">
          <img class="table-pagination__arrow" src="/assets/chevron-left.svg" alt="">
          <img class="table-pagination__arrow-hover" src="/assets/chevron-left-w.svg" alt="">
        </div>
        <div 
          class="table-pagination__item" 
          v-for="page in paginationRange" 
          :key="page"
          @click="page !== '...' && (currentPage = page)" 
          :class="{'active': page === currentPage}"
        >
          {{ page }}
        </div>
        <div class="table-pagination__item" @click="currentPage < totalPages && currentPage++">
          <img class="table-pagination__arrow" src="/assets/chevron-right.svg" alt="">
          <img class="table-pagination__arrow-hover" src="/assets/chevron-right-w.svg" alt="">
        </div>
      </div>
    </div>

    <custom-modal v-if="isChangeWarehouseModal" @close="isChangeWarehouseModal = !isChangeWarehouseModal">
      <change-warehouse-modal :id="currentId" @close="isChangeWarehouseModal = !isChangeWarehouseModal"/>
    </custom-modal>
    <custom-modal v-if="isChangeStatusModal" @close="isChangeStatusModal = !isChangeStatusModal">
      <change-status-modal 
        :id="currentId" 
        :current-status="currentStatus"
        :current-ticket="currentTicket"
        @close="isChangeStatusModal = !isChangeStatusModal" 
      />
    </custom-modal>
    <custom-modal v-if="isDuplicateCheckModal" @close="isDuplicateCheckModal = false">
      <duplicate-check-modal 
        :data="duplicateCheckData" 
        @close="isDuplicateCheckModal = false"
      />
    </custom-modal>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import router from "@/router";
import ChangeWarehouseModal from "@/components/modals/virtualWarehouse/ChangeWarehouseModal.vue";
import ChangeStatusModal from "@/components/modals/virtualWarehouse/ChangeStatusModal.vue";
import DuplicateCheckModal from "@/components/modals/virtualWarehouse/DuplicateCheckModal.vue";
import BaseCalendar from '@/components/ui/BaseCalendar.vue'
import BaseDropdown from '@/components/ui/BaseDropdown.vue'
import BaseSearch from '@/components/ui/BaseSearch.vue'
import {debounce} from "@/helpers/utils";
import BaseItemsPerPage from '@/components/ui/BaseItemsPerPage.vue'
import { format, parseISO, isValid } from 'date-fns'
import { ru } from 'date-fns/locale';

export default {
  name: "VirtualWarehouse",
  components: { 
    CustomModal, 
    ChangeWarehouseModal, 
    ChangeStatusModal, 
    DuplicateCheckModal,
    BaseCalendar,
    BaseDropdown,
    BaseSearch,
    BaseItemsPerPage
  },
  async created() {
    // Create debounced search method
    this.debouncedSearch = debounce((value) => {
      this.search = value;
      this.params = {
        ...this.params,
        search: value || null,
        start_date: this.params.start_date,
        end_date: this.params.end_date
      };
    }, 500);

    this.setLoading(true)
    try {
      await this.getStatuses()
      await this.getWarehouses()
      await this.getVirtualWarehouses()
      await this.getEquipmentStatuses()
      await this.fetchBrigades()
      await this.getEquipmentLocations()
    } catch (error) {
      console.error('Error in created:', error)
    } finally {
      this.setLoading(false)
    }
  },
  data() {
    return {
      isChangeWarehouseModal: false,
      isChangeStatusModal: false,
      isDuplicateCheckModal: false,
      duplicateCheckData: null,
      currentId: null,
      currentStatus: null,
      currentTicket: null,
      search: '',
      storage_key: null,
      company_key: null,
      status_key: null,
      brigade_id: null,
      equipment_status_key: null,
      location_key: null,
      params: {
        search: null,
        brigade_id: null,
        storage_key: null,
        company_key: null,
        status_key: null,
        start_date: null,
        end_date: null,
        equipment_status_key: null,
        location_key: null,
      },
      companies: [
        { key: 'meganet', title: 'Meganet' },
        { key: 'nls', title: 'NLS' }
      ],
      dateRange: [],
      // Add pagination data
      currentPage: 1,
      itemsPerPage: 10
    }
  },
  computed: {
    ...mapGetters({
      virtualWarehouses: 'virtualWarehouses',
      statuses: 'statuses',
      warehouses: 'warehouses',
      brigades: 'virtualWarehouseBrigades',
      loading: 'loading',
      equipmentStatuses: 'equipmentStatuses',
      equipmentLocations: 'equipmentLocations'
    }),
    totalPages() {
      return Math.ceil(this.virtualWarehouses.length / this.itemsPerPage);
    },
    paginatedWarehouses() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.virtualWarehouses.slice(start, end);
    },
    paginationRange() {
      let delta = 1,
          left = this.currentPage - delta,
          right = this.currentPage + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

      for (let i = 1; i <= this.totalPages; i++) {
        if (i == 1 || i == this.totalPages || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    filteredBrigades() {
      if (!this.brigades) return []
      
      // Filter brigades based on search input
      return this.brigades.filter(brigade => {
        const searchTerm = (this.searchBrigade || '').toLowerCase()
        const brigadeTitle = (brigade?.title || '').toLowerCase()
        return brigadeTitle.includes(searchTerm)
      })
    }
  },
  watch: {
    params: {
      async handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setLoading(true)
          try {
            await this.getVirtualWarehouses(newValue)
          } finally {
            this.setLoading(false)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      getVirtualWarehouses: 'getVirtualWarehouses',
      getStatuses: 'getStatuses',
      getWarehouses: 'getWarehouses',
      checkDuplicateOneC: 'checkDuplicateOneC',
      getEquipmentStatuses: 'getEquipmentStatuses',
      fetchBrigades: 'fetchBrigades',
      getEquipmentLocations: 'getEquipmentLocations',
      downloadExelList: 'downloadExelList'
    }),
    async downloadExcel() {
      if (!this.params.search) {
        delete this.params.search
      }
      this.setLoading(true)

      const res = await this.downloadExelList({...this.params, download: 1})
      const url = window.URL.createObjectURL(res);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `отчеты виртуальный склад.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    openTicketInfo(ticketId, ticketType) {
      this.setLoading(true)
      if(ticketType === 'ticket') {
        router.push(`/ticket/${ticketId}`)
      }
      if(ticketType === 'connection_ticket') {
        router.push(`/connection/${ticketId}`)
      }
      this.setLoading(false)
    },
    openChangeWarehouse(list) {
        this.isChangeWarehouseModal = true
        this.currentId = list.id
    },
    openChangeStatus(list) {
      this.isChangeStatusModal = true
      this.currentId = list.id
      this.currentStatus = list?.status?.key || null
      this.currentTicket = list
    },
    onTableClick(id) {
      const previouslySelected = document.querySelector('.selected');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected');
      }
      const el = document.getElementById(id);
      if (el) {
        el.classList.add('selected');
      }
    },
    async checkDuplicate(id) {
      try {
        this.setLoading(true)
        const response = await this.checkDuplicateOneC(id)
        this.duplicateCheckData = response?.data || response
        this.isDuplicateCheckModal = true
      } catch (error) {
        console.error('Error checking duplicates:', error)
      } finally {
        this.setLoading(false)
      }
    },
    handleSearch() {
      if (this.search === "") {
        this.search = null
      }
      this.params.search = this.search
    },
    selectBrigade(brigade) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        brigade_id: brigade?.id || null
      }
    },
    handleStorageChange() {
      this.params.storage_key = this.storage_key
    },
    handleCompanyChange() {
      this.params.company_key = this.company_key
    },
    handleStatusChange(item) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        status_key: this.status_key
      }
    },
    clearFilter(type) {
      switch(type) {
        case 'storage':
          this.storage_key = null
          this.params.storage_key = null
          break
        case 'company':
          this.company_key = null
          this.params.company_key = null
          break
        case 'status':
          this.status_key = null
          this.params.status_key = null
          break
      }
    },
    async saveFilters() {
      try {
        // Логика сохранения фильтров
        this.$toast.success('Фильтры успешно сохранены')
      } catch (error) {
        this.$toast.error('Не удалось сохранить фильтры')
      }
    },
    resetFilters() {
      if (this.loading) return
      
      this.search = ''
      this.storage_key = null
      this.company_key = null
      this.status_key = null
      this.brigade_id = null
      
      this.params = {
        search: null,
        brigade_id: null,
        storage_key: null,
        company_key: null,
        status_key: null,
        start_date: null,
        end_date: null
      }
      
      this.$refs.calendar.$emit('setDate', [null, null])
      
      this.$toast.success('Фильтры сброшены')
    },
    selectStorage(storage) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        storage_key: storage?.key || null
      }
    },
    selectCompany(company) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        company_key: company?.key || null
      }
    },
    selectEquipmentStatus(equipmentStatus) {
      if (this.loading) return

      this.params = {
        ...this.params,
        equipment_status_key: equipmentStatus?.key || null
      }
    },
    selectLocation(location) {
      if (this.loading) return

      this.params = {
        ...this.params,
        location_key: location?.key || null
      }
    },
    selectStatus(status) {
      this.status_key = status?.key || null
      this.selectedStatusTitle = status?.title || ''
      this.params.status_key = status?.key || null
      this.showStatusDropdown = false
    },
    handleDateChange([startDate, endDate]) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        start_date: Array.isArray(startDate) ? startDate[0] : startDate,
        end_date: Array.isArray(endDate) ? endDate[0] : endDate
      }
    },
    formatDate(dateString) {
      const parsedDate = parseISO(dateString);

      if (isValid(parsedDate)) {
        return format(parsedDate, 'dd.MM.yy, HH:mm', { locale: ru });
      }

      return dateString;
    },
    // Обновление поиска с задержкой
    debouncedSearch: debounce(function(value) {
      if (this.loading) return
      
      this.params = {
        ...this.params,
        search: value || null
      }
    }, 500),
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>


<style lang="scss" scoped>
@import '@/theme/variables';
@import '@/theme/table';

.mt-30 {
  margin-top: 30px;
}

.mt-24 {
  margin-top: 24px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  border-radius: 8px;
  background: white;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

//.selected {
//  background-color: rgba(42, 82, 152, 0.1);
//}

.grid {
  display: grid;
  min-width: 1400px;
  width: 100%;
  gap: 0;
  grid-template-columns: minmax(0, 40px) minmax(0, 120px) minmax(0, 120px) minmax(0, 120px) minmax(0, 150px) minmax(0, 120px) minmax(0, 150px) minmax(150px, 1fr) minmax(0, 120px) minmax(0, 150px) minmax(0, 120px) minmax(0, 200px) minmax(0, 120px) minmax(0, 135px);
  grid-template-areas: 'Num Ticket Company Serial Brigade Iin Location Device Equipment-location Equipment-status Status Recommendation Actions';

  > div {
    padding: 16px;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
    text-align: center;
  }
}

.gridText {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #F5F5FA;
  font-weight: 500;
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  [class^="grid-"] {
    padding: 0 16px;
    min-height: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5FA !important;
  }

  > div {
    border-bottom: none;
    background: #F5F5FA;
  }

  p {
    color: #333333;
  }
}

.grid-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  img {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.grid-status {
  display: flex !important;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 16px 24px !important;
  color: #333333;

  &.status-new {
    color: #FF8A00;
    &::before {
      content: '';
      display: block;
      min-width: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #FF8A00;
    }
  }

  &.status-moved {
    color: #00C82C;
    &::before {
      content: '';
      display: block;
      min-width: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #00C82C;
    }
  }
}

.filters-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto auto;
  gap: 16px;
  width: 100%;
  position: relative;
}

.filters-container > div {
  width: 100%;
  min-width: 0;
}

.filter-buttons {
  grid-column: 3 / span 2;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: flex-end;
  padding-top: 8px;
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 14px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease;
  line-height: 1;

  .filter-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &.save-btn {
    width: 205px;
    background: linear-gradient(92.65deg, #00C82C 0%, #5AC371 100.1%);
    color: white;
  }

  &.reset-btn {
    width: 198px;
    background: #888B94;
    color: white;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.excel-download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #00C82C;
  border-radius: 12px;
  background: transparent;
  color: #00C82C;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 44px;
  min-width: 200px;
  white-space: nowrap;

  .excel-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:hover {
    background: rgba(0, 200, 44, 0.1);
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background: #F5F5FA;
  border-radius: 8px;
  
  .warning-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    opacity: 0.7;
  }
  
  p {
    color: #6B7280;
    font-size: 16px;
  }
}

.table-controls {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  justify-content: flex-end;
  grid-column: 4;
  margin-top: 8px;
}

.table-pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.clickable {
  cursor: pointer;
  color: #2A5298;
  text-decoration: underline;

  &:hover {
    color: #1a3b7a;
  }
}

.departure-table {
  :hover {
    background-color: #F5F5FA !important;
  }
}

.selected {
  border: 4px solid black;
}
</style>