<template>
  <div class="p-20" style="width: 600px">
    <div class="">
      <div class="mb-1">
        <h1 class="text-center text-24">Создать заявку доступа</h1>
      </div>
      <div class="flex justify-center p-20" style="width: auto">
        <form @submit.prevent="submitAccessData">
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="name"
                   placeholder="Название" type="text"/>
            <label class="label-form">Название</label>
          </div>
          <div>
            <p class="text-center">Выбрать координаты</p>
            <div class="flex justify-between gap-2 mt-1 mb-1">
              <button type="button" class="input-btn btn btn-green" @click="isAddressModal = !isAddressModal">Yandex
              </button>
              <button type="button" class="input-btn btn btn-green" @click="is2gisAddressModal = !is2gisAddressModal">
                2GIS
              </button>
            </div>
            <div class="input-container input-container__form mb-2 mt-2">
              <input class="input input-form" :value="longitude && latitude ? [latitude,longitude] : null"
                     placeholder="Координаты" type="text"/>
              <label class="label-form">Координаты</label>
            </div>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="address"
                   placeholder="Адрес" type="text"/>
            <label class="label-form">Адрес</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <select class="input input-form select" :class="{'empty': !district_id}"
                    v-model="district_id" ref="district_id">
              <option v-for="option in districts" :key="option.key" :value="option.id">
                {{ option.title }}
              </option>
            </select>
            <label class="label-form">Район</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="supervisor"
                   placeholder="ФИО контактного лица" type="text"/>
            <label class="label-form">ФИО контактного лица</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="phone"
                   placeholder="Телефон" type="text"/>
            <label class="label-form">Телефон</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="agreement"
                   placeholder="№ Договора" type="text"/>
            <label class="label-form">№ Договора</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="notes"
                   placeholder="Примечание" type="text"/>
            <label class="label-form">Примечание</label>
          </div>
          <div class="flex justify-between gap-2 mt-4">
            <button class="btn w-full btn-gray" type="button" style="height: 48px; width: 100%" @click="$emit('close')">Отмена</button>
            <button class="btn w-full" type="submit" @click.prevent="submitAccessData">Добавить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <custom-modal v-if="isAddressModal" @close="isAddressModal = !isAddressModal">
    <service-ticket-form-address-modal @getStreetName="getStreetName"
                                       @close="pickedCoordinates"></service-ticket-form-address-modal>
  </custom-modal>
  <custom-modal v-if="is2gisAddressModal" @close="is2gisAddressModal = !is2gisAddressModal">
    <map-gis-modal @close="pickGisCoordinates"></map-gis-modal>
  </custom-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ServiceTicketFormAddressModal from "@/components/modals/tickets/ServiceTicketFormAddressModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import MapGisModal from "@/components/modals/access-data/MapGisModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import router from "@/router";

export default {
  name: "AccessCreateModal",
  components: {MapGisModal, ServiceTicketFormAddressModal, CustomModal},
  created() {
    this.fetchDistricts();
  },
  data() {
    return {
      name: null,
      address: null,
      supervisor: null,
      phone: null,
      agreement: null,
      notes: null,
      latitude: null,
      longitude: null,
      isAddressModal: false,
      is2gisAddressModal: false,
      district_id: null,
      isSuccessModal: false,
      createdTicketId: "",
    }
  },
  computed: {
    ...mapGetters({
      districts: 'districts',
    })
  },
  methods: {
    ...mapActions({
      createAccessData: 'createAccessData',
      fetchDistricts: 'fetchDistricts',
    }),
    async submitAccessData() {
      const obj = {
        name: this.name,
        address: this.address,
        supervisor: this.supervisor,
        phone: this.phone,
        agreement: this.agreement,
        notes: this.notes,
        latitude: this.latitude,
        longitude: this.longitude,
        district_id: this.district_id
      }
      await this.createAccessData(obj).then(response => {
        if (response.status) {
          this.$toast.success('Адрес был успешно создан')
          this.$emit('close')
          this.clearData()
        } else {
          this.$toast.error('Не удалось создать адрес')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    clearData() {
      this.name = null
      this.address = null
      this.supervisor = null
      this.phone = null
      this.agreement = null
      this.notes = null
      this.latitude = null
      this.longitude = null
      this.district_id = null
    },
    pickedCoordinates(coords) {
      this.isAddressModal = !this.isAddressModal
      this.latitude = coords[0]
      this.longitude = coords[1]
    },
    pickGisCoordinates({coordinates, street}) {
      this.is2gisAddressModal = !this.is2gisAddressModal
      this.latitude = coordinates[0]
      this.longitude = coordinates[1]
      this.address = street
    },
    getStreetName(street) {
      this.address = street
    },
  },
  unmounted() {
    this.clearData()
  }
}
</script>

<style scoped lang="scss">

</style>