<template>
  <div class="auth-container min-h-full flex align-center justify-end">
    <div class="auth-form flex column align-center">
      <div class="flex w-100 align-center justify-center">
        <img class="mr-2" src="/assets/nls-logo.svg" alt="">
        <h3 class="auth-title">NLS.SUPPORT ЦЕНТР</h3>
      </div>
      <div class="auth-inner">
        <h4 :class="{'mb-5': errorText.length === 0, 'mb-2': errorText.length}">СМЕНА ПАРОЛЯ</h4>
        <p v-if="errorText.length" class="error-text mb-2 uppercase">{{errorText}}</p>
        <div class="input-container mb-2">
          <input ref="loginInput" id="loginInput" placeholder="Логин" v-mask="phoneMask" class="input" type="text" v-model="login" @paste="onPasteLogin" />
          <label for="loginInput">Логин</label>
        </div>

        <div class="input-container mb-2" v-if="!showPass">
          <input ref="passInput" id="passInput" class="input" type="password" placeholder="Новый пароль" v-model="new_password" />
          <label for="passInput">Новый пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-closed.svg" @click="toggleShowPass">
          </div>
        </div>

        <div class="input-container mb-2" v-if="showPass">
          <input ref="passInput" id="passInput" class="input" type="text" placeholder="Новый пароль" v-model="new_password" />
          <label for="passInput">Новый пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-open.svg" @click="toggleShowPass">
          </div>
        </div>

        <div class="input-container" v-if="!showPassConf">
          <input ref="passConfInput" id="passConfInput" class="input" type="password" placeholder="Повторить новый пароль" v-model="new_password_confirmation" />
          <label for="passConfInput">Повторить новый пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-closed.svg" @click="showPassConf = !showPassConf">
          </div>
        </div>

        <div class="input-container" v-if="showPassConf">
          <input ref="passConfInput" id="passConfInput" class="input" type="text" placeholder="Повторить новый пароль" v-model="new_password_confirmation" />
          <label for="passConfInput">Повторить новый пароль</label>
          <div class="input-btn">
            <img src="/assets/eye-open.svg" @click="showPassConf = !showPassConf">
          </div>
        </div>

        <div class="auth-actions">
          <button type="button" class="btn mb-2 mt-2" @click="changePass">ИЗМЕНИТЬ</button>
          <button type="button" class="btn mb-2 transparent" @click="toLogin">ОТМЕНИТЬ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import {mapMutations} from "vuex";
import Cookies from 'js-cookie'
export default {
  name: 'ResetView',
  data() {
    return {
      login: '',
      new_password: '',
      new_password_confirmation: '',
      showPass: false,
      showPassConf: false,
      errorText: '',
      phoneMask: '+7 (###)-###-##-##',
    }
  },
  async mounted() {
    if (this.$route.query.login) this.login = this.$route.query.login
    this.$refs.loginInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.changePass()
      }
    })
    this.$refs.passInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.changePass()
      }
    })
    this.$refs.passConfInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.changePass()
      }
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    onPasteLogin(e) {
      if (e.clipboardData.getData('text').length === 11 && e.clipboardData.getData('text')[0] === '8') {
        this.login = '7' + this.unMask(e.clipboardData.getData('text')).slice(-10)
      }
    },
    async changePass() {
      this.setLoading(true)
      await store.dispatch('changePass', {
        login: this.unMask(this.login),
        new_password: this.new_password,
        new_password_confirmation: this.new_password_confirmation,
        code: this.$route.query.code
      }).then(res => {
        if (!res.status) {
          this.errorText = res.message
          setTimeout(() => {
            this.errorText = ''
          }, 5000)
        }
        else this.toLogin()
      })
      this.setLoading(false)
    },
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    toLogin() {
      this.$router.push("/login")
    }
  }
}
</script>
