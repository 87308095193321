<template>
  <div class="w-100 p-20 bg-gray rounded">
    <template v-if="clientDocuments.length > 0">
      <div class="mb-2" v-for="doc in clientDocuments" :key="doc.id">
        <div class="card rounded bg-white flex align-center justify-between">
          <p>{{ docTitle(doc.type) }}</p>
          <a :href="doc.path" target="_blank" :download="doc.type">
            <button type="button" class="btn btn-gray">Скачать</button>
          </a>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Дата:</p>
          <p class="text-14 text-black text-right">{{ formatDate(doc.created_at) }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Обновлено:</p>
          <p class="text-14 text-black text-right">{{ formatDate(doc.updated_at) }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Подписано:</p>
          <p class="text-14 text-black text-right">{{ formatDate(doc.sms_singed_at) }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Менеджер:</p>
          <p class="text-14 text-black text-right">{{ doc.manager.full_name }}</p>
        </div>
      </div>
    </template>
    <div v-else class="w-100 empty-text text-center text-black uppercase">
      Нет документов
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {formatDate} from "@/helpers/utils";

export default {
  name: 'ClientDocuments',
  computed: {
    ...mapGetters({
      clientDocuments: 'clientDocuments',
    })
  },
  methods: {
    docTitle(type) {
      switch (type) {
        case 'completion_certificate':
          return 'Акт выполненных работ'
        case 'contract':
          return 'Контракт'
        case 'buy':
          return 'Документ продажи'
        case 'rent':
          return 'Документ АППО'
        default:
          return 'Документ'
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return formatDate(date, '.', true)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.card {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

</style>
