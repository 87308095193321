<template>
	<transition v-if="isOpen" name="chat-drawer">
		<div style="position: relative; z-index: 999">
			<div class="chat" style="background: #fbfbfb">
				<div class="chat-drawer">
					<div style="display: flex; align-items: center">
						<div>
							<div @click="handleClose" class="close-button">
								<img src="/assets/close-chat.svg" />
							</div>
						</div>
						<div class="chat__header">
							<div style="width: 306px">
								<div style="font-size: 28px">
									{{ currentTime }}
								</div>
								<div style="font-size: 14px; color: #888b94">
									{{ weekName }}, {{ dayDate }}
									{{ monthName }}
								</div>
							</div>
						</div>
					</div>
					<iframe
						:src="`${rocketChatBaseUrl}${chatPath}?resumeToken=${getRocketChatCred?.authToken}&userId=${getRocketChatCred?.userId}`"
						frameborder="0"
						style="height: calc(100% - 90px); width: 100%; margin-bottom: 20px; margin-top: 20px; border-radius: 20px;"
						allow="camera;microphone"
					/>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
	todayDate,
	currentTime,
	dayDate,
	weekName,
	monthName,
} from "@/helpers/todayDate";
import { rocketChatBaseUrl } from '@/service/rocketChatServer'

export default {
	name: "chat-component-test-rocket",
	props: {
		isOpen: Boolean,
		chatPath: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			showProfile: false,
			profile: {},
			currentTime: "",
			monthName: "",
			weekName: "",
			dayDate: "",
      rocketChatBaseUrl
		}
	},
	computed: {
		...mapGetters({
			getRocketChatCred: 'getRocketChatCred',
		})
	},
	methods: {
		...mapActions({
			getRooms: 'getRocketChatRooms'
		}),
		handleClose() {
			this.getRooms()
			this.$emit("closeChat", false)
		}
	},
	created() {
		setInterval(() => {
			todayDate()
			this.monthName = monthName
			this.currentTime = currentTime
			this.weekName = weekName
			this.dayDate = dayDate
		}, 1000)
	}
}
</script>

<style scoped lang="scss">
.chat {
  border-radius: 0;
}
.chat-drawer {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.start-message {
  display: flex;
  gap: 14px;
  align-items: center;
}

.chat {
	position: fixed;
	width: 100%;
	height: 100%;
	background: white;
	display: flex;
	justify-content: center;
	&__header {
		width: 100%;
		background: #f2f1f0;
		border-radius: 20px;
		display: flex;
		align-items: center;
		padding: 13px 16px;
		margin-left: 10px;
	}
}

.chat-drawer-enter,
.chat-drawer-leave-to {
	opacity: 1;
	transform: translateX(20%);
}

.chat-drawer-enter-active,
.chat-drawer-leave-active {
	transition: 0.2s ease-in-out;
}

.close-button {
  cursor: pointer;

  img {
    width: 46px;
    height: 46px;
  }
}
</style>
