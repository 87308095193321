import {rocketChatProvider} from '@/service/rocketChatServer'

export function initializeWebSocket(store) {
	const wsProtocol = process.env.VUE_APP_ROCKET_CHAT_URL.startsWith('https') ? 'wss' : 'ws'
	const wsUrl = process.env.VUE_APP_ROCKET_CHAT_URL.replace(/^https?/, wsProtocol)
	const ws = new WebSocket(`${wsUrl}/websocket`)

	setupWebSocketHandlers(ws, store)
	return ws
}

function setupWebSocketHandlers(ws, store) {
	ws.onopen = () => handleOpen(ws)
	ws.onmessage = (event) => handleMessage(event, ws, store)
}

function handleOpen(ws) {
	ws.send(JSON.stringify({
		msg: 'connect',
		version: '1',
		support: ['1']
	}))
}

function handleMessage(event, ws, store) {
	const data = JSON.parse(event.data)

	const handlers = {
		connected: () => handleConnected(ws, store),
		result: () => handleResult(data, ws, store),
		ping: () => handlePing(ws),
		changed: () => handleChanged(data, store)
	}

	const handler = handlers[data.msg]
	if (handler) handler()
}

function handleConnected(ws, store) {
	const rocketChatCred = store.state.rocketChatCred
	ws.send(JSON.stringify({
		msg: 'method',
		method: 'login',
		id: 'login-' + Date.now(),
		params: [{ resume: rocketChatCred?.authToken }]
	}))
}

function handleResult(data, ws, store) {
	if (data.id.startsWith('login-') && !data.error && data.result?.id) {
		subscribeToEvents(ws, store)
	}
}

function handlePing(ws) {
	ws.send(JSON.stringify({ msg: 'pong' }))
}

async function handleChanged(data, store) {
	if (data.collection !== 'stream-notify-user') return

	const [, type] = data.fields.eventName.split('/')
	
	if (type === 'notification') {
		await handleNotification(data.fields.args?.[0], store)
	} else if (type === 'rooms-changed') {
		handleRoomChanged(data.fields.args, store)
	}
}

async function handleNotification(notification, store) {
	if (!notification?.payload) return

	const { rid: roomId, sender, u, _id: messageId } = notification.payload
	const senderId = sender?._id || u?._id
	const rocketChatCred = store.state.rocketChatCred

	if (!senderId || senderId === rocketChatCred?.userId) return

	const existingRoom = store.state.rocketChatRooms.find(r => r._id === roomId)
	if (!existingRoom) {
		await createNewRoom(roomId, store)
	}

	store.commit('incrementUnreadCount', { roomId, messageId })
}

async function createNewRoom(roomId, store) {
	try {
		const roomResponse = await rocketChatProvider.get('rooms.info', {
			params: { roomId }
		})
		
		if (roomResponse.data.success) {
			store.commit('chat/addNewDirectMessage', {
				room: {
					...roomResponse.data.room,
					unread: 1,
					lm: new Date().toISOString()
				}
			})
		}
	} catch (error) {
		console.error('Failed to fetch room info:', error)
	}
}

function handleRoomChanged([action, room], store) {
	if (action === 'inserted' && room?.t === 'd') {
		store.commit('chat/addNewDirectMessage', {
			room: {
				...room,
				unread: room.msgs > 0 ? 1 : 0,
				lm: room.lm || new Date().toISOString()
			}
		})
	}
}

function subscribeToEvents(ws, store) {
	if (ws.readyState !== WebSocket.OPEN) return

	const rocketChatCred = store.state.rocketChatCred
	const userId = rocketChatCred?.userId

	const subscriptions = [
		{
			id: 'stream-notify-user-notification',
			name: 'stream-notify-user',
			params: [`${userId}/notification`, false]
		},
		{
			id: 'stream-notify-user-rooms',
			name: 'stream-notify-user',
			params: [`${userId}/rooms-changed`, false]
		}
	]

	subscriptions.forEach(sub => {
		ws.send(JSON.stringify({
			msg: 'sub',
			...sub
		}))
	})
} 