import { api } from "@/service/axios";

export const rolesModule = {
    state: () => ({
        allRoles: [],
        allSections: [],
        permissions: [],
        currentRole: [],
    }),
    getters: {
        allRoles(state){
            return state.allRoles;
        },
        allSections(state){
            return state.allSections;
        },
        permissions(state){
            return state.permissions;
        },
        currentRole(state){
            return state.currentRole;
        }
    },
    mutations: {
        setAllRoles(state, payload) {
            state.allRoles = payload;
        },
        setAllSections(state, payload) {
            state.allSections = payload;
        },
        setPermissions(state, payload) {
            state.permissions = payload;
        },
        setCurrentRole(state, payload) {
            state.currentRole = payload;
        }
    },
    actions: {
        async deleteRole({commit}, payload) {
            const res = await api.delete(`role/role-delete/${payload.id}`)
            return res
        },
        async updateRole({commit}, payload) {
            const res = await api.put(`role/role-update/${payload.id}`, payload)
            return res
        },
        async createRole({commit}, payload) {
            const res = await api.post(`role/role-create`, payload)
            return res
        },
        async createRolePermissions({commit}, payload) {
            const res = await api.post('role/permissions', payload)
            return res
        },
        async fetchRolePermissions({commit}, payload) {
            const res = await api.get('role/permissions')
            commit('setPermissions', res.data.role_permissions)
            return res
        },
        async deleteRolePermission({commit}, payload) {
            const res = await api.delete(`role/permission/${payload.id}`)
            return res
        },
        async fetchAllSections({commit}, payload) {
            const res = await api.get('role/all-sections')
            commit('setAllSections', res.data)
            return res
        },
        async fetchAllRoles({commit, getters}, payload) {
            const res = await api.get('role/all-roles', {params: payload})
            commit('setAllRoles', res.data)
            res.data.forEach((item) => {
                if (item.id === getters['currentRole'].id) {
                   getters['currentRole'].permissions = item.permissions
                }
            })
            return res
        }
    }
}