import {loginToTasks} from "@/service/tasks";

export const tasksModule = {
  state: () => ({
    tasksCred: null
  }),
  getters: {
    tasksCred: state => state.tasksCred,
  },
  mutations: {
    setTasksCred(state, cred) {
      state.tasksCred = cred
      localStorage.setItem('tasksCred', JSON.stringify(state.tasksCred))
    },
    clearTasksCred(state, cred) {
      localStorage.removeItem('tasksCred')
    },
  },
  actions: {
    async loginToTasks({ commit, state }, params) {
      try {
        const response = await loginToTasks(params)
        const { token } = response.data.data

        const tasksCred = {
          token
        }
        commit('setTasksCred', tasksCred)

        return response
      } catch (error) {
        console.warn('Tasks login failed:', error)
      }
    },
    clearTasksCred({commit, state}) {
      commit('clearTasksCred')
    }
  }
}
