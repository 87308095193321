<template>
  <div class="modal flex justify-center align-center" >
    <div class="modal-overlay" @click="close"></div>
    <div class="modal-container profile">
      <div class="flex profile__inner mb-2 w-100">
        <div class="user-img">
          <img v-if="user.photo" :src="'data:image/png;base64,' + user.photo" alt="" />
        </div>

        <div class="flex column">
          <p class="text-black">{{user.full_name}}</p>
          <p class="text-gray">{{user.position}}</p>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex align-center">
        <img class="mr-1" src="/assets/phone-blue.svg" alt="" />
        <p class="text-gray">{{toMask(user.phone)}}</p>
        </div>
        <p v-if="managerInfo.extension_number" class="mt-1">ВН: <span class="text-gray">{{managerInfo.extension_number}}</span></p>
      </div>

      <div class="logout flex" @click="close(); logOut()">
        <img class="mr-1" src="/assets/logout.svg" alt="">
        <p>Выйти</p>
      </div>
    </div>
  </div>
</template>

<script>


import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ProfileModal',
  data() {
    return {

    }
  },
  methods: {
    ...mapActions({
      logOut: 'logOut',
    }),
    close() {
      this.$emit('close')
    },
    toMask(value) {
      return '+7' + this.unMask(value).slice(-10)
    },
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      managerInfo: 'managerInfo'
    })
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.profile {
  border-radius: 19px;
  box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.10);
  padding: 24px 16px;
  width: 292px;
  position: fixed;
  top: 99px;
  right: 0;

  &__inner {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.05px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E6E6E6;

    & > img {
      margin-right: 12px;
      width: 32px;
      height: 32px;

    }
  }
}

.logout {
  position: absolute;
  left: 12px;
  bottom: 16px;
  color: #A1A1A1;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.modal-overlay {
  background-color: transparent;
}
.user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #E0E2E7;
  margin-right: 12px;
  overflow: hidden;

  img {
    width: 32px;
    height: 32px;
  }
}

.user-data {
  font-size: 14px;
  color: $black;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}

</style>
