<template>
  <div class="modal">
    <div class="modal-overlay" @click="handleClose"></div>
    <div class="modal-container" :style="containerStyle">
      <div class="modal-header">
        <div class="modal-header-content">
          <slot name="header"></slot>
        </div>
        <button class="modal-close-btn" @click="handleClose">
          <img src="/assets/close-rounded-light.svg" alt="close" />
        </button>
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
      <div class="modal-footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    containerStyle() {
      return this.color ? {
        '--modal-color': this.color,
        '--modal-color-rgb': this.hexToRgb(this.color)
      } : {}
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    hexToRgb(hex) {
      hex = hex.replace('#', '');
      
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      
      return `${r}, ${g}, ${b}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  &-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &-container {
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 28px;
    width: 580px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
    z-index: 1;

    :deep(.modal_title__image) {
      border: 1px solid var(--modal-color);
    }
  }

  &-header {
    position: relative;
    background: #ffffff;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;

    &-content {
      flex: 1;
      padding-right: 48px;
    }
  }

  &-close-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: all 0.2s ease;
    border-radius: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      img {
        filter: brightness(0.9);
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition: filter 0.2s ease;
    }
  }

  &-content {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      min-height: 100px;
    }
  }
  
  &-footer {
    margin-top: auto;
    padding-top: 16px;
  }
}
</style> 