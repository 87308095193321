<template>
  <div class="create-area-form">
    <div class="form-header">
      <h1 class="text-center text-24">{{ type === 'edit' ? 'Редактирование участка' : 'Создание участка' }}</h1>
      <div v-if="type === 'edit'" class="mt-2">
        <button @click="deactivate" class="btn btn-green" type="button">
          {{ areaData?.active_status ? 'Деактивировать' : 'Активировать' }}
        </button>
      </div>
    </div>
    <form class="form-body">
      <div class="input-container mb-1">
        <input class="input input-form" v-model="formData.area_name" type="text"/>
        <label class="label-form">Название участка</label>
      </div>
      <div class="mb-1" v-if="type === 'edit'">
        <label>Объединение участка</label>
        <multiselect
            v-model="formData.unite_ids"
            :options="parsedAreas"
            label="name"
            multiple
            track-by="id"
            placeholder="Выберите участок"
            class="mt-1"
        ></multiselect>
      </div>
      <div class="mb-1">
        <label>Начальник участка</label>
        <multiselect
            v-model="formData.manager_id"
            :options="parsedAuthors"
            label="name"
            track-by="id"
            placeholder="Выберите руководителя"
            class="mt-1"
        ></multiselect>
      </div>
      <div class="mb-2">
        <AreasMapComponent :polygons="polygons" v-if="polygons" :currentPolygons="areaData?.polygons" class="mt-1"
                           @selected-polygons="handleSelectedPolygons"/>
      </div>
      <div class="mb-1">
        <label>Тип заявки и ответственные</label>
        <div class="ticket-types-container">
          <div v-for="type in parsedTicketKinds" :key="type.id" class="ticket-type-item">
            <div class="ticket-type-header">
              <label class="checkbox-container">
                <input 
                  type="checkbox" 
                  :checked="isTicketTypeSelected(type)"
                  @change="toggleTicketType(type)"
                />
                <span class="checkmark"></span>
                {{ type.name }}
              </label>
            </div>
            <div v-if="isTicketTypeSelected(type)" class="ticket-type-manager">
              <multiselect
                v-model="ticketTypeManagers[`${type.id}-${type.type}`]"
                :options="parsedAuthors"
                label="name"
                track-by="id"
                :custom-label="({ name }) => `${name}`"
                placeholder="Выберите ответственного"
                class="mt-1"
                @input="handleManagerChange(type)"
              ></multiselect>
              <div v-if="$props.type === 'edit' && (shouldShowReasonInput(type) || hasExistingReason(type))" class="reason-input mt-2">
                <label class="mb-1">Причина изменения ответственного*</label>
                <textarea
                  v-model="ticketTypeReasons[`${type.id}-${type.type}`]"
                  class="input-form"
                  :class="{'error': isReasonRequired(type) && !ticketTypeReasons[`${type.id}-${type.type}`]}"
                  placeholder="Укажите причину изменения"
                  rows="2"
                ></textarea>
              </div>
              <div v-if="$props.type === 'edit'" class="manager-update-info">
                <span class="text-gray">Дата и время применения правила: {{ managerUpdateDates[`${type.id}-${type.type}`] || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-1">
        <label>Бригада</label>
        <multiselect
            v-model="formData.brigade_ids"
            :options="parsedBrigades"
            multiple
            label="name"
            track-by="id"
            placeholder="Выберите бригаду"
            class="mt-1"
        ></multiselect>
      </div>
      <div class="form-footer">
        <button class="btn form-btn" type="button" @click="submitData">
          {{ type === 'edit' ? 'Сохранить' : 'Создать' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Multiselect from 'vue-multiselect';
import AreasMapComponent from "@/components/cards/areas/AreasMapComponent.vue";

export default {
  name: "CreateAreaForm",
  components: {Multiselect, AreasMapComponent},
  props: {
    type: {
      type: String,
      default: ''
    },
    areaId: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.setLoading(true)
    Promise.all([
      this.fetchTicketKindsWithTypes(),
      this.fetchBrigadeByDepartment(),
      this.getAuthorsList({is_head: 1}),
      this.getAreas({per_page: 1000}),
    ])

    if (this.areaId) {
      this.getAreaData(this.areaId).then(() => {
        this.formData.area_name = this.areaData?.area_name
        this.formData.manager_id = {id: this.areaData?.manager?.id, name: this.areaData?.manager?.full_name}
        this.formData.brigade_ids = this.areaData?.brigades.map((item) => ({
          id: item?.id,
          name: item?.title
        }))
        
        this.formData.ticket_kind_ids = this.areaData?.kinds.map((item) => ({
          name: item.title,
          id: item.id,
          type: item.type,
          key: item.key
        }))
        
        this.areaData?.kinds.forEach(kind => {
          const uniqueKey = `${kind.id}-${kind.type}`
          
          if (kind.pivot?.manager_id) {
            const manager = this.authorsList.find(author => author.id === kind.pivot.manager_id)
            if (manager) {
              this.ticketTypeManagers[uniqueKey] = {
                id: manager.id,
                name: manager.full_name
              }
            }
          }
          
          if (kind.pivot?.reason) {
            this.ticketTypeReasons[uniqueKey] = kind.pivot.reason
            this.originalReasons[uniqueKey] = kind.pivot.reason
          }
          
          this.managerUpdateDates[uniqueKey] = kind.pivot?.manager_updated_at
        })
      })
    }
    this.setLoading(false)
  },
  data() {
    return {
      formData: {
        area_name: '',
        manager_id: 0,
        polygons: [],
        ticket_kind_ids: [],
        brigade_ids: [],
        unite_ids: []
      },
      ticketTypeManagers: {},
      managerUpdateDates: {},
      ticketTypeReasons: {},
      updatedTickets: {},
      originalReasons: {}
    };
  },
  computed: {
    ...mapGetters({
      polygons: 'polygons',
      ticketKinds: 'ticketKindsWithTypes',
      brigadeByDepartment: 'brigadeByDepartment',
      authorsList: 'authorsList',
      areas: 'areas',
      areaData: 'areaData'
    }),
    parsedTicketKinds() {
      if (!this.ticketKinds.length) {
        return []
      }
      return this.ticketKinds.map((item) => ({
        name: item.title,
        id: item.id,
        type: item.type,
        key: item.key,
        uniqueId: `${item.id}-${item.type}`
      }))
    },
    parsedBrigades() {
      if (!this.brigadeByDepartment.length) {
        return []
      }
      return this.brigadeByDepartment.map((item) => ({
        name: item.title,
        id: item.id,
      }))
    },
    parsedAuthors() {
      if (!this.authorsList.length) {
        return []
      }
      return this.authorsList.map((item) => ({
        name: item.full_name,
        id: item.id,
      }))
    },
    parsedAreas() {
      if (!this.areas.length) {
        return []
      }
      return this.areas.map((item) => ({
        name: item.area_name,
        id: item.id,
      }))
    },
  },
  methods: {
    ...mapActions({
      fetchTicketKinds: 'fetchTicketKinds',
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      createArea: 'createArea',
      deactivateArea: 'deactivateArea',
      getAuthorsList: 'getAuthorsList',
      getAreas: 'getAreas',
      getAreaData: 'getAreaData',
      updateArea: 'updateArea',
      fetchTicketKindsWithTypes: 'fetchTicketKindsWithTypes'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    handleManagerChange(type) {
      const uniqueKey = `${type.id}-${type.type}`;
      const currentManager = this.ticketTypeManagers[uniqueKey]?.id;
      const originalManager = this.areaData?.kinds.find(k => k.id === type.id && k.type === type.type)?.pivot?.manager_id;
      
      if (this.type === 'edit' && currentManager !== originalManager) {
        this.updatedTickets[uniqueKey] = true;
      }
    },
    shouldShowReasonInput(type) {
      const uniqueKey = `${type.id}-${type.type}`;
      const currentManager = this.ticketTypeManagers[uniqueKey]?.id;
      const originalManager = this.areaData?.kinds.find(k => k.id === type.id && k.type === type.type)?.pivot?.manager_id;
      
      return currentManager !== originalManager;
    },
    isReasonRequired(type) {
      const uniqueKey = `${type.id}-${type.type}`;
      return this.shouldShowReasonInput(type);
    },
    validateForm() {
      let isValid = true;
      
      if (this.type === 'edit') {
        this.formData.ticket_kind_ids.forEach(type => {
          const uniqueKey = `${type.id}-${type.type}`;
          if (this.updatedTickets[uniqueKey] && !this.ticketTypeReasons[uniqueKey]) {
            isValid = false;
          }
        });
      }
      
      return isValid;
    },
    async submitData() {
      if (!this.validateForm()) {
        this.$toast.error('Пожалуйста, укажите причину изменения ответственного');
        return;
      }

      const submissionData = {
        ...this.formData,
        brigade_ids: this.formData.brigade_ids.map((item) => item.id),
        ticket_kinds: this.formData.ticket_kind_ids.map((item) => {
          const uniqueKey = `${item.id}-${item.type}`;
          const originalTicket = this.areaData?.kinds.find(k => k.id === item.id && k.type === item.type);
          const currentManager = this.ticketTypeManagers[uniqueKey]?.id;
          const currentReason = this.ticketTypeReasons[uniqueKey];
          const originalReason = this.originalReasons[uniqueKey];
          
          const managerChanged = this.type === 'edit' && 
            (!originalTicket || originalTicket.pivot?.manager_id !== currentManager);
          const reasonChanged = this.type === 'edit' && 
            currentReason !== originalReason;

          const wasChanged = managerChanged || reasonChanged;

          return {
            id: item.id,
            type: item.type,
            manager_id: this.ticketTypeManagers[uniqueKey]?.id || null,
            reason: wasChanged ? currentReason || null : null,
            ...(wasChanged ? { updated: true } : {})
          };
        }),
        manager_id: this.formData.manager_id.id,
        ...(this.areaId ? {id: this.areaId} : {}),
        unite_ids: this.formData.unite_ids.map((item) => item.id),
      };

      if (this.type === 'edit') {
        await this.updateArea(submissionData).then((res) => {
          if (res.success) {
            this.$toast.success('Участок успешно отредактирован');
            this.$emit('close');
          } else {
            this.$toast.error('Не удалось отредактировать участок');
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`);
        });
      } else {
        await this.createArea(submissionData).then((res) => {
          if (res.success) {
            this.$toast.success('Участок успешно создан');
            this.$emit('close');
          } else {
            this.$toast.error('Не удалось создать участок');
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`);
        });
      }
    },
    handleSelectedPolygons(polygons) {
      this.formData.polygons = polygons.map((item) => ({
        name: item?.name,
        id: item.id
      }))
    },
    deactivate() {
      this.deactivateArea(this.areaId).then((res) => {
        if (res.success) {
          this.$toast.success('Статус успешно изменён')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось изменить статус')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    isTicketTypeSelected(type) {
      return this.formData.ticket_kind_ids.some(t => t.id === type.id && t.type === type.type)
    },
    toggleTicketType(type) {
      const index = this.formData.ticket_kind_ids.findIndex(t => t.id === type.id && t.type === type.type)
      if (index === -1) {
        this.formData.ticket_kind_ids.push({
          name: type.name,
          id: type.id,
          type: type.type,
          key: type.key
        })
      } else {
        this.formData.ticket_kind_ids.splice(index, 1)
        delete this.ticketTypeManagers[`${type.id}-${type.type}`]
      }
    },
    hasExistingReason(type) {
      const existingKind = this.areaData?.kinds.find(k => k.id === type.id && k.type === type.type);
      return !!existingKind?.pivot?.reason;
    },
  },
  unmounted() {
    this.formData.area_name = ''
    this.formData.brigade_ids = []
    this.formData.manager_id = 0
    this.formData.polygons = []
    this.formData.unite_ids = []
    this.formData.ticket_kind_ids = []
  },
};
</script>

<style scoped lang="scss">
@import 'vue-multiselect/dist/vue-multiselect.ssr.css';

.create-area-form {
  width: 700px;
  max-height: 80vh;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.form-header {
  margin-bottom: 32px;
  text-align: center;
  padding-top: 8px;
  
  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.form-body {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  padding-top: 8px;
  
  label {
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .input-container {
    margin-bottom: 24px;
  }
  
  .multiselect {
    margin-bottom: 24px;
  }
}

.form-footer {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  
  .form-btn {
    width: 340px;
  }
}

::v-deep.input-form {
  min-height: 40px;
  display: block;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  width: 100%;
  padding: 8px 12px;
}

::v-deep.multiselect {
  &__tags {
    min-height: 40px;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
  }
  
  &__tag {
    background: #0095FF;
    color: white;
    border-radius: 4px;
  }
  
  &__option--highlight {
    background: #0095FF;
  }
}

.ticket-types-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 12px;
}

.ticket-type-item {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
  
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.ticket-type-header {
  margin-bottom: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  
  input:checked ~ .checkmark {
    background-color: #0095FF;
    border-color: #0095FF;
  }
  
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.ticket-type-manager {
  margin-top: 8px;
  margin-left: 35px;

  .multiselect {
    margin-bottom: 16px;
  }
}

.manager-update-info {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

.text-gray {
  color: #666;
}

.reason-input {
  margin-top: 12px;
  margin-bottom: 16px;
  
  label {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
  }
  
  textarea {
    width: 100%;
    min-height: 60px;
    padding: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    resize: vertical;
    
    &.error {
      border-color: #ff4444;
    }
    
    &:focus {
      outline: none;
      border-color: #0095FF;
    }
  }
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  margin-top: 4px;
}
</style>
