import { createStore } from 'vuex'
import {userModule} from "@/store/user/user";
import {resourcesModule} from '@/store/resources/resources'
import {ticketsModule} from "@/store/tickets/tickets";
import {alertModule} from "@/store/alert/alert";
import {clientModule} from "@/store/client/client";
import {themeModule} from '@/store/theme/theme';
import {dashboardModule} from '@/store/dashboard/dashboard';
import {connectionTicketsModule} from "@/store/connectionTicket/connection-tickets";
import {ipModule} from "@/store/ip/ip";
import {csModule} from "@/store/currentSituation/current-situation";
// import {taskScoreModule} from "@/store/task-score";
import {notificationModule} from "@/store/notification/notification";
import {loaderModule} from "@/store/loader/loader";
import {usersModule} from '@/store/users/users';
import {worktimeModule} from '@/store/worktime/worktime';
import {scheduleModule} from '@/store/schedule/schedule';
import {dutyScheduleModule} from "@/store/dutySchedule/duty-schedule";
import {wazzupModule} from "@/store/wazzup/wazzup";
import { brigadeOptionsModule } from '@/store/brigade/brigade-options';
import {instructionsModule} from "@/store/instructions/instructions";
import {departureModule} from "@/store/departure/departure";
import {rolesModule} from "@/store/roles/roles";
import {reportsModule} from "@/store/reports/reports";
import {departureServiceModule} from "@/store/departureService/departure-service";
import {nodesModule} from "@/store/nodes/nodes";
import {accessDataModule} from "@/store/accessData/access-data";
import {queriesTCModule} from "@/store/queriesTC";
import {chatModule} from "@/store/chat";

// import {tasksModule} from "@/store/tasks";
import {ticketsLCSModule} from "@/store/ticketsLCS";
import {areasModule} from "@/store/areas";
import {polygonsModule} from "@/store/polygons/polygons";
import {departmentsModule} from "@/store/departments/departments";
import {loadChartReportModule} from "@/store/reports/loadChartReport";
import {gisKeyModule} from "@/store/keys/gis";
import {ticketsOTPModule} from "@/store/ticketsOTP";
import {virtualWarehousesModule} from "@/store/virtualWarehouse";
import {monetizationReportModule} from "@/store/monetizationReport";
import monetization from './monetization'
import {pagesModule} from "@/store/pages/pages";
import {techniciansModule} from "@/store/technicians/technicians";
import {tasksModule} from "@/store/tasks/tasks"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userModule,
    usersModule,
    resourcesModule,
    ticketsModule,
    alertModule,
    clientModule,
    loaderModule,
    themeModule,
    // taskScoreModule,
    notificationModule,
    dashboardModule,
    worktimeModule,
    scheduleModule,
    connectionTicketsModule,
    ipModule,
    csModule,
    dutyScheduleModule,
    wazzupModule,
    brigadeOptionsModule,
    instructionsModule,
    departureModule,
    rolesModule,
    reportsModule,
    departureServiceModule,
    nodesModule,
    accessDataModule,
    queriesTCModule,
    ticketsLCSModule,
    areasModule,
    polygonsModule,
    departmentsModule,
    loadChartReportModule,
    gisKeyModule,
    ticketsOTPModule,
    virtualWarehousesModule,
    monetizationReportModule,
    monetization,
    pagesModule,
    techniciansModule,
    chatModule,
    tasksModule
    // tasksModule
  }
})
