import { api } from "@/service/axios";

export const techniciansModule = {
  state: () => ({
    techAppAccesses: [],
  }),
  getters: {
    techAppAccesses(state) {
      return state.techAppAccesses;
    },
  },
  mutations: {
    setTechAppAccesses(state, payload) {
      state.techAppAccesses = payload;
    },
  },
  actions: {
    async fetchTechAppAccesses({ commit }, payload) {
      const res = await api.get("managers/get-tech-app-accesses", {
        params: payload,
      });
      console.log("API Response:", res.data);
      commit("setTechAppAccesses", res.data);
    },
    async updateTechAppAccesses({ commit }, payload) {
      const res = await api.put("managers/update-tech-app-accesses", {
        period: payload
      });
      return res;
    },
    async deleteTechnician({ commit, dispatch }, technicianId) {
      const res = await api.delete(`brigade/delete-installers/${technicianId}`);
      return res;
    },
    async getTechAccessHistory({ commit }, userId) {
      try {
        const res = await api.get("managers/get-tech-app-access-logs", {
          params: { user_id: userId }
        });
        return res;
      } catch (error) {
        console.error("Error fetching tech access history:", error);
        throw error;
      }
    },
  },
}; 