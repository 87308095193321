import { api } from "@/service/axios";

export const ipModule = {
  state: () => ({
    ipCheckResult: {},
    isIpCheckResultModal: false,
    ipMessage: '',
  }),
  getters: {
    ipCheckResult(state) {
      return state.ipCheckResult;
    },
    isIpCheckResultModal(state) {
      return state.isIpCheckResultModal;
    },
    getIpMessage(state){
      return state.ipMessage;
    }
  },
  mutations: {
    setIpCheckResult(state, payload) {
      state.ipCheckResult = payload;
    },
    setIsIpCheckResultModal(state, payload) {
      state.isIpCheckResultModal = payload;
    },
    setIpMessage(state,payload){
      state.ipMessage = payload
    }
  },
  actions: {
    async getConnectionType({ commit }, payload) {
      return await api.get(`ip/connect-type?ip=${payload}`);
    },
    async pppoe({ commit }, payload) {
      return await api.get(
        `ip/pppoe?ip=${payload.ip}&connect_type=${payload.connection}`
      );
    },
    async blockCheck({ commit }, payload) {
      return await api.get(
        `ip/block-check?ip=${payload.ip}&connect_type=${payload.connection}`
      );
    },
    async ipConfig({ commit }, payload) {
      return await api.get(
        `ip/ip-config?ip=${payload.ip}&connect_type=${payload.connection}`
      );
    },
    async checkLoss({ commit }, payload) {
      return await api.get(
        `ip/loss?ip=${payload.ip}&connect_type=${payload.connection}`
      );
    },
    async traffic({ commit }, payload) {
      return await api.get(
        `ip/traffic?ip=${payload.ip}&connect_type=${payload.connection}`
      );
    },
    async checkIpExtended({ commit }, payload) {
      return await api.get(`ip/ip-extended-info?ip=${payload}`);
    },
    async checkIpAddress({ dispatch, commit, state }, payload) {
      commit("setIpCheckResult", {});
      commit("setLoading", true);
      dispatch("getConnectionType", payload).then(async (res) => {
        if (res.data.length) {
          if (res.data.data?.connect_type.indexOf("pppoe") > 0) {
            dispatch("pppoe", {
              ip: payload,
              connection: res.data.data.connect_type,
            }).then((pppoe) => {
              commit("setIpCheckResult", pppoe.data.data);
              commit("setLoading", false);
              commit("setIsIpCheckResultModal", true);
            });
          } else {
            let count = 0;
            await dispatch("blockCheck", {
              ip: payload,
              connection: res.data.data.connect_type,
            }).then((blockCheckRes) => {
              count++;
              commit("setIpCheckResult", {
                ...state.ipCheckResult,
                ...blockCheckRes.data.data,
              });
              if (count === 4) {
                commit("setLoading", false);
                commit("setIsIpCheckResultModal", true);
              }
            });
            await dispatch("checkLoss", {
              ip: payload,
              connection: res.data.data.connect_type,
            }).then((loss) => {
              count++;
              commit("setIpCheckResult", {
                ...state.ipCheckResult,
                ...loss.data.data,
              });
              if (count === 4) {
                commit("setLoading", false);
                commit("setIsIpCheckResultModal", true);
              }
            });
            await dispatch("ipConfig", {
              ip: payload,
              connection: res.data.data.connect_type,
            }).then((ipConfig) => {
              count++;
              commit("setIpCheckResult", {
                ...state.ipCheckResult,
                ...{ config: ipConfig.data.data },
              });
              if (count === 4) {
                commit("setLoading", false);
                commit("setIsIpCheckResultModal", true);
              }
            });
            await dispatch("traffic", {
              ip: payload,
              connection: res.data.data.connect_type,
            }).then((traffic) => {
              count++;
              commit("setIpCheckResult", {
                ...state.ipCheckResult,
                ...traffic.data.data,
              });
              if (count === 4) {
                commit("setLoading", false);
                commit("setIsIpCheckResultModal", true);
              }
            });
            // TO-DO: После того как поправять паралелные запросы вернуть таймауты
            // setTimeout(() => {
            //
            //   setTimeout(() => {
            //
            //     setTimeout(() => {
            //
            //     }, 1000)
            //   }, 1000)
            // }, 1000)
          }
        } else commit("setLoading", false);
      });
    },
    async checkIpExtendedRework({ dispatch, commit, state }, payload) {
      commit("setIpCheckResult", {});
      commit("setLoading", true);
      commit("setIsIpCheckResultModal", false);
      dispatch("checkIpExtended", payload).then(async (res) => {
        commit("setIpCheckResult", res.data.info);
        commit("setIpMessage", res.data.message)
        commit("setLoading", false);
        commit("setIsIpCheckResultModal", true);
      });
    },
  },
};
