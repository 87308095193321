<template>
  <main class="w-100 text-center caption">
    <p>В разработке</p>
  </main>
</template>

<script>

export default {
  name: 'DevView',
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';
.caption {
  margin-top: 14vh;
  font-size: 20px;
}
</style>
