<template>
  <div class="wrapper modified flex column">
    <div class="bg-white p-20 rounded flex column" style="gap: 30px">
      <div class="flex justify-between" style="align-items: center">
        <h1 class="title">Отчет по подключениям</h1>
        <div class="filter-buttons">
          <button class="filter-btn"
                  style="background: #2ab161;color: white;" @click="downloadExcelForChanges">
            <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
            <div>Изменение условий ТО</div>
          </button>
        </div>
      </div>
      <div class="filters-container">
        <div class="w-100">
          <BaseCalendar
              label=""
              @setDate="handleDateChange"
              :disabled="loading"
              ref="calendar"
              :defaultDateRange="[defaultStartDate, defaultEndDate]"
          />
        </div>
        <div class="w-100">
          <BaseDropdown
              placeholder="Компания"
              :items="parsedCompanies"
              v-model:selectedValue="companies"
              @change="selectCompany"
              :disabled="loading"
          />
        </div>
        <div class="w-100">
          <BaseDropdown
              placeholder="Тип услуги"
              :items="parsedWorkTypes"
              v-model:selectedValue="work_type"
              @change="selectWorkType"
              :disabled="loading"
          />
        </div>
        <div class="w-100">
          <BaseDropdown
              placeholder="Отдел"
              :items="parsedDepartments"
              v-model:selectedValue="selectedDepartment"
              @change="updateFilteredListByDepartment"
              :disabled="loading"
          />
        </div>
        <div class="w-100">
          <BaseDropdown
              placeholder="Сотрудники"
              :items="parsedUsers"
              v-model:selectedValue="selectedUser"
              @change="updateFilteredListByUser"
              :disabled="loading"
          />
        </div>
      </div>
      <div class="flex gap-2">
        <div class="total-count">
          <div class="block">
            <label>Закрыто задач:</label>
            <div class="count">{{ calculateTotalClosedTasks }}</div>
          </div>
        </div>
        <div class="total-count">
          <div class="block">
            <label>Всего бонусов:</label>
            <div class="count">{{ calculateTotalBonuses }}тг.</div>
          </div>
        </div>
      </div>
      <div class="flex justify-between" style="align-items: center">
        <div class="filter-buttons">
          <button class="filter-btn"
                  style="background: #2ab161;color: white;" @click="downloadExcel">
            <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
            <div>Выгрузить</div>
          </button>
        </div>
        <div class="flex gap-1">
          <div>Общее кол-во записей:</div>
          <div>{{ filteredMonetizationReport.length || 0 }}</div>
        </div>
      </div>
      <div class="table-container">
        <template v-if="filteredMonetizationReport.length">
          <div class="grid gridText">
            <div class="grid-id flex align-center justify-center gap-2 cursor-pointer" @click="onSortData('key')">
              <p class="text-center">ID</p>
              <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
            </div>
            <div class="grid-user flex align-center justify-center gap-2 cursor-pointer" @click="onSortData('author_name')">
              <p class="text-center">Сотрудник</p>
              <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
            </div>
            <div class="grid-department flex align-center justify-center gap-2 cursor-pointer" @click="onSortData('department')">
              <p class="text-center">Департамент</p>
              <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
            </div>
            <div class="grid-count flex align-center justify-center gap-2 cursor-pointer" @click="onSortData('rewards')">
              <p class="text-center">Количество закрытых задач</p>
              <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
            </div>
            <div class="grid-sum flex align-center justify-center gap-2 cursor-pointer" @click="onSortData('wallet_sum')">
              <p class="text-center">Сумма</p>
              <img src="/assets/comments/arrow-down-up.svg" height="16" width="16" alt="sort" />
            </div>
          </div>
          <div class="departure-table">
            <div class="grid cursor-pointer"
                 v-for="(task) in sortedData"
                 :key="task.key"
                 @click="onTableClick(task.id)"
            >
              <div class="grid-id">
                {{ task.key || 'Не указано' }}
              </div>
              <div class="grid-user">
                {{ task?.author_name || 'Не указано' }}
              </div>
              <div class="grid-department">
                {{ task?.department || 'Не указано' }}
              </div>
              <div class="grid-count cursor-pointer" style="color: #2ab161; font-weight: 600"
                   @click="openDetailsModal(task)">
                {{ task?.rewards?.length || 'Не указано' }}
              </div>
              <div class="grid-sum">
                {{ task?.wallet_sum + 'тг.' || 'Не указано' }}
              </div>
            </div>
          </div>
        </template>
        <div v-else class="no-data">
          <img src="/assets/warning-icon.svg" alt="warning" class="warning-icon"/>
          <p>По вашему запросу ничего не найдено</p>
        </div>
      </div>
    </div>

    <custom-modal v-if="isOpenModal" @close="isOpenModal = !isOpenModal">
      <detalization-modal @close="isOpenModal = !isOpenModal" :data="selectedUserTasks"/>
    </custom-modal>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import DetalizationModal from "@/components/modals/monetization/DetalizationModal.vue";
import BaseCalendar from '@/components/ui/BaseCalendar.vue'
import BaseDropdown from '@/components/ui/BaseDropdown.vue'
import {parse, format, startOfMonth, endOfMonth} from 'date-fns'

export default {
  name: "MonetizationReportView",
  components: {
    CustomModal,
    BaseCalendar,
    BaseDropdown,
    DetalizationModal
  },
  created() {
    this.setLoading(true)
    Promise.all([
      this.getMonetizationReport(
          {
            date_from: this.defaultStartDate,
            date_to: this.defaultEndDate,
          }
      ),
      this.getCompanies(),
      this.getWorkTypes()
    ])
        .catch(error => {
          console.error('Error in created:', error)
        })
        .finally(() => this.setLoading(false))
  },
  data() {
    return {
      selectedDepartment: null,
      selectedUser: null,
      companies: null,
      work_type: null,
      params: {
        work_type: null,
        companies: null,
        date_from: null,
        date_to: null,
      },
      isOpenModal: false,
      selectedUserTasks: [],
      filteredList: [],
      defaultStartDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      defaultEndDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
      isSorted: false,
      sortedValue: null,
    }
  },
  computed: {
    ...mapGetters({
      monetizationReport: 'monetizationReport',
      companiesList: 'companies',
      workTypesList: 'workTypes',
      loading: 'loading',
    }),
    parsedCompanies() {
      if (!this.companiesList.data) {
        return []
      }
      return Object.entries(this.companiesList.data).map(([key, value]) => ({
        key,
        title: value
      }));
    },
    parsedWorkTypes() {
      if (!this.workTypesList.data) {
        return []
      }
      return Object.entries(this.workTypesList.data).map(([key, value]) => ({
        key,
        title: value
      }));
    },
    parsedMonetizationReport() {
      if (!this.monetizationReport || this.monetizationReport.success === false) {
        return []
      }
      return Object.entries(this.monetizationReport).map(([key, value]) => ({
        key,
        ...value
      }));
    },
    filteredMonetizationReport() {
      return this.parsedMonetizationReport.filter(item => {
        const userMatch = !this.selectedUser || item.author_name === this.selectedUser;
        const departmentMatch = !this.selectedDepartment || item.department === this.selectedDepartment;
        return userMatch && departmentMatch;
      });
    },
    parsedUsers() {
      if (!this.parsedMonetizationReport.length) {
        return []
      }
      return Object.entries(this.parsedMonetizationReport).map(([key, value]) => ({
        key: value.author_name,
        title: value.author_name
      }));
    },
    parsedDepartments() {
      if (!this.parsedMonetizationReport.length) {
        return []
      }

      const uniqueDepartments = new Set(
          this.parsedMonetizationReport.map(item => item.department)
      );

      return Array.from(uniqueDepartments).map(department => ({
        key: department,
        title: department
      }));
    },
    calculateTotalClosedTasks() {
      if (!this.filteredMonetizationReport.length) {
        return 0
      }

      return this.filteredMonetizationReport.reduce((total, item) => {
        return total + (item?.rewards?.length || 0)
      }, 0)
    },
    calculateTotalBonuses() {
      if (!this.filteredMonetizationReport.length) {
        return 0
      }

      const total = this.filteredMonetizationReport.reduce((sum, item) => {
        return sum + (item.wallet_sum || 0);
      }, 0);

      return new Intl.NumberFormat('ru-RU').format(total)
    },
    sortedData() {
      if (this.sortedValue) {
        return this.sortCalc(this.sortedValue);
      } else {
        return this.sortCalc('author_name');
      }
    },
  },
  watch: {
    params: {
      async handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setLoading(true)
          try {
            await this.getMonetizationReport(newValue)
          } finally {
            this.setLoading(false)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      getMonetizationReport: 'getMonetizationReport',
      getCompanies: 'getCompanies',
      getWorkTypes: 'getWorkTypes',
      downloadExelList: 'downloadExel'
    }),
    async downloadExcel() {
      const { utils, writeFile } = await import("xlsx");

      const formattedData = this.filteredMonetizationReport.map((item) => ({
        'ID': item.key,
        'Сотрудник': item.author_name,
        'Департамент': item.departament,
        'Кол-во закрытых задач': item?.rewards.length,
        'Сумма': item.wallet_sum,
      }));
      const worksheet = utils.json_to_sheet(formattedData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");

      writeFile(workbook, `Отчет ТО.xlsx`);
    },
    async downloadExcelForChanges() {
      this.setLoading(true)

      const res = await this.downloadExelList({date_from: this.params.date_from, date_to: this.params.date_to, download: 1})
      const url = window.URL.createObjectURL(res.data);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Изменение условии ТО.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    onTableClick(id) {
      const previouslySelected = document.querySelector('.selected');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected');
      }
      const el = document.getElementById(id);
      if (el) {
        el.classList.add('selected');
      }
    },
    selectWorkType(type) {
      if (this.loading) return

      this.params = {
        ...this.params,
        work_type: type?.key || null
      }
    },
    selectCompany(company) {
      if (this.loading) return

      this.params = {
        ...this.params,
        companies: [company?.key] || null
      }
    },
    updateFilteredListByDepartment() {
      this.filteredMonetizationReport = this.parsedMonetizationReport.filter(
          item => item.department === this.selectedDepartment
      );
    },
    updateFilteredListByUser() {
      this.filteredMonetizationReport = this.parsedMonetizationReport.filter(
          item => item.author_name === this.selectedUser
      );
    },
    formatDate(dateString) {
      const parsedDate = parse(dateString, 'dd-MM-yyyy', new Date());
      const formattedDate = format(parsedDate, 'yyyy-MM-dd');

      return formattedDate
    },
    handleDateChange([startDate, endDate]) {
      if (this.loading) return;

      this.params = {
        ...this.params,
        date_from: startDate ? startDate : null,
        date_to: endDate ? endDate : null
      };
    },
    openDetailsModal(task) {
      this.isOpenModal = true
      this.selectedUserTasks = task
    },
    onSortData(sortedValue) {
      this.isSorted = !this.isSorted
      this.sortedValue = sortedValue
    },
    sortCalc(sortedValue) {
      const clonedList = [...this.filteredMonetizationReport];

      return clonedList.sort((a, b) => {
        let valueA = a[sortedValue] ?? 0;
        let valueB = b[sortedValue] ?? 0;

        if (sortedValue === "key" || typeof valueA === "number" && typeof valueB === "number") {
          return this.isSorted ? valueB - valueA : valueA - valueB;
        }

        const titleA = String(valueA);
        const titleB = String(valueB);
        return this.isSorted ? titleB.localeCompare(titleA) : titleA.localeCompare(titleB);
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>


<style lang="scss" scoped>
@import '@/theme/variables';
@import '@/theme/table';

.title {
  font-size: 1.7rem;
  font-weight: 600;
}

.total-count {
  box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
  border-radius: 20px;
  width: 100%;

  .block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 69px;

    label {
      padding: 10px;
      color: #888;
    }

    .count {
      background-color: #4DCE4D;
      border-radius: 20px;
      padding: 1rem 2rem;
      height: 100%;
      align-content: center;
      color: white;
    }
  }

}

.table-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  border-radius: 8px;
  background: white;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

.grid {
  display: grid;
  min-width: 1400px;
  width: 100%;
  gap: 0;
  grid-template-columns: minmax(0, 100px) repeat(4, minmax(0, 1fr));
  grid-template-areas: 'Id User Department Count Sum';

  > div {
    padding: 16px;
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
    text-align: center;
  }
}

.gridText {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #F5F5FA;
  font-weight: 500;
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  [class^="grid-"] {
    padding: 0 16px;
    min-height: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5FA !important;
  }

  > div {
    border-bottom: none;
    background: #F5F5FA;
  }

  p {
    color: #333333;
  }
}

.grid-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  img {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.grid-status {
  display: flex !important;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 16px 24px !important;
  color: #333333;

  &.status-new {
    color: #FF8A00;

    &::before {
      content: '';
      display: block;
      min-width: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #FF8A00;
    }
  }

  &.status-moved {
    color: #00C82C;

    &::before {
      content: '';
      display: block;
      min-width: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #00C82C;
    }
  }
}

.filters-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto auto;
  gap: 16px;
  width: 100%;
  position: relative;
}

.filters-container > div {
  width: 100%;
  min-width: 0;
}

.filter-buttons {
  grid-column: 3 / span 2;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: flex-end;
  padding-top: 8px;
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 14px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease;
  line-height: 1;

  .filter-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  &.save-btn {
    width: 205px;
    background: linear-gradient(92.65deg, #00C82C 0%, #5AC371 100.1%);
    color: white;
  }

  &.reset-btn {
    width: 198px;
    background: #888B94;
    color: white;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.excel-download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #00C82C;
  border-radius: 12px;
  background: transparent;
  color: #00C82C;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 44px;
  min-width: 200px;
  white-space: nowrap;

  .excel-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:hover {
    background: rgba(0, 200, 44, 0.1);
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background: #F5F5FA;
  border-radius: 8px;

  .warning-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    opacity: 0.7;
  }

  p {
    color: #6B7280;
    font-size: 16px;
  }
}

.table-controls {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  justify-content: flex-end;
  grid-column: 4;
  margin-top: 8px;
}

.table-pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.clickable {
  cursor: pointer;
  color: #2A5298;
  text-decoration: underline;

  &:hover {
    color: #1a3b7a;
  }
}

.departure-table {
  :hover {
    background-color: #F5F5FA !important;
  }
}

.selected {
  border: 4px solid black;
}
</style>