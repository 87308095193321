<template>
  <div class="wrapper modified">
    <div class="content w-100 flex column">
      <div class="flex justify-between mb-2">
        <div class="flex">
          <div class="input-container mb-3">
            <select
              class="input select"

              v-model="brigade_id"
              @change="searchCalendar({
							key: 'month'
						})">
              <option :value="undefined">
                Все
              </option>
              <option v-for="option in brigades" :key="option.key" :value="option.id">
                {{ option.name_ru }}
              </option>
            </select>
            <label>Бригада</label>
          </div>
        </div>
        <div></div>
      </div>

      <div class="calendar bg-gray rounded">
        <div class="flex bg-white calendar-bar rounded mb-1" v-if="currentDate">
          <div
            @click="searchCalendar({
							key: 'month',
							increment: -1
						})"
            class="calendar-bar_arrow mr-1 flex justify-center align-center cursor-pointer" >
            <img src="/assets/calendar/arrow-left.svg" alt="">
          </div>
          <div
            @click="searchCalendar({
							key: 'month',
							increment: 1
						})"
            class="calendar-bar_arrow mr-4 flex justify-center align-center cursor-pointer">
            <img src="/assets/calendar/arrow-right.svg" alt="">
          </div>
          <h3 class="uppercase">{{ currentDate.month }}</h3>
          <p> {{ currentDate.year }}</p>
        </div>

        <div class="flex w-100 mb-2">
          <div class="day flex align-center bg-white justify-center" v-for="day in daysOfWeek" :key="day">{{day}}</div>
        </div>
        <div class="calendar_item" v-if="currentDate">

          <calendar-block
            v-for="(item, index) in currentDate.data"
            :key="`day-${index}`"
            :date-info="item.dateInfo"
            :assigned-brigades="item.assignedBrigades"
            :all-brigade-departments="brigadeDepartments"
            @refresh="searchCalendar(searchFilter)"
          />
        </div>
      </div>

    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CalendarBlock from "@/components/static/CalendarBlock.vue";

const daysOfWeek = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const getDatesByRanges = {
  month(date) {
    const currentDate = new Date(date);
    // Даты для теста
    // const currentDate = new Date("2023-05-02");
    // const currentDate = new Date("2023-10-04");
    // const currentDate = new Date("2023-01-01");
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    // 10 часов было установлено чтобы при преобразовании в ISO String не выдавало некорректную дату
    const firstDay = new Date(year, month, 1, 10);
    const lastDay = new Date(year, month + 1, 0, 10);
    const prevMonthLastDay = new Date(year, month, 0, 10);

    const monthArray = [];

    // пустые ячейки до начала месяца
    const forEmptyDays = firstDay.getDay() === 0 ? 7 : firstDay.getDay();
    for (let i = 1; i < forEmptyDays; i++) {
      monthArray.push({
        day: daysOfWeek[i] || daysOfWeek[0],
        number: prevMonthLastDay.getDate() - forEmptyDays + i + 1,
        notThisMonth: true,
        dayOff: i === 6 || i === 7,
        date: new Date(
          year,
          month - 1,
          prevMonthLastDay.getDate() - forEmptyDays + i + 1,
          10
        )
          .toISOString()
          .slice(0, 10),
      });
    }

    // ячейки этого месяца
    for (let day = 1; day <= lastDay.getDate(); day++) {
      const dayOfWeek = new Date(year, month, day, 10);
      monthArray.push({
        day: daysOfWeek[dayOfWeek.getDay()],
        number: day,
        notThisMonth: false,
        dayOff: dayOfWeek.getDay() === 0 || dayOfWeek.getDay() === 6,
        date: dayOfWeek.toISOString().slice(0, 10),
      });
    }

    // пустые ячейки после последнего дня месяца
    const endDay = lastDay.getDay();
    if (endDay !== 0) {
      const forDate = new Date(lastDay);
      let nextDays = 1;
      for (let i = endDay + 1; i <= 7; i++) {
        forDate.setDate(forDate.getDate() + 1);
        monthArray.push({
          day: daysOfWeek[i] || daysOfWeek[0],
          number: nextDays++,
          notThisMonth: true,
          dayOff: i === 6 || i === 7,
          date: forDate.toISOString().slice(0, 10),
        });
      }
    }

    return {
      date_from: firstDay.toISOString().slice(0, 10),
      date_to: lastDay.toISOString().slice(0, 10),
      data: monthArray,
      month: months[month],
      year,
    };
  },
  week(date) {
    const currentDate = new Date(date);
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const weekArray = [];

    // ячейки данной недели
    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      weekArray.push({
        number: day.getDate(),
        day: daysOfWeek[day.getDay()],
        dayOff: i === 5 || i === 6,
        date: day.toISOString().slice(0, 10),
      });
    }

    return {
      date_from: startOfWeek.toISOString().slice(0, 10),
      date_to: endOfWeek.toISOString().slice(0, 10),
      data: weekArray,
      month: months[startOfWeek.getMonth()],
      year: startOfWeek.getFullYear(),
    };
  },
};

export default {
  name: 'CalendarView',
  components: {
    CalendarBlock
  },
  data() {
    return {
      dateRangeType: "",
      newDate: new Date(),
      currentDate: undefined,
      loading: false,
      searchFilter: undefined,
      mutatedBrigades: [],
      brigade_id: undefined,
    }
  },
  created() {
    this.setLoading(true)
    let promises = [this.mutateBrigades(), this.searchCalendar({key: "month"})]
    if (!this.brigades || !this.brigades.length) promises.push(this.fetchBrigades())
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      brigades: 'brigades'
    }),
    daysOfWeek() { return daysOfWeek },
    brigadeDepartments() {
      const intl = new Intl.Collator();
      return this.mutatedBrigades
        .filter(
          (obj, index) =>
            index ===
            this.mutatedBrigades.findIndex(
              (o) =>
                obj.work_department_id === o.work_department_id
            )
        )
        .map((item) => ({
          name: item.work_department,
          id: item.work_department_id,
          brigades: this.mutatedBrigades
            .filter(
              (brigade) =>
                brigade.work_department_id ===
                item.work_department_id
            )
            .map((item2) => ({
              name: item2.department_name,
              id: item2.id,
            }))
            .sort((a, b) => intl.compare(a.name, b.name)),
        }));
    },
  },
  methods: {
    ...mapActions({
      fetchBrigadeSchedule: 'fetchBrigadeSchedule',
      fetchBrigades: 'fetchBrigades',
      error: 'errorAlert',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async mutateBrigades() {
      const intl = new Intl.Collator();
      this.mutatedBrigades = this.brigades.sort((a, b) =>
        intl.compare(a.department_name, b.department_name)
      );
    },
    async getSchedule(params, data) {
      console.log(data)
      try {
        this.loading = true;
        const res = await this.fetchBrigadeSchedule(params);
        data.data = data.data.map((item) => ({
          dateInfo: item,
          assignedBrigades: res.data[item.date],
        }));
        this.currentDate = data;
        console.log(this.currentDate)
      } catch (e) {
        console.log(e);
        this.error("Не удалось загрузить расписание");
      } finally {
        this.loading = false;
      }
    },
    async searchCalendar(data) {
      let dataReset = false; // для того, чтобы данные не сбивались
      let currentDate = this.currentDate;
      if (this.dateRangeType !== data.key) {
        this.dateRangeType = data.key;
        this.newDate = new Date();
        currentDate = getDatesByRanges[this.dateRangeType](
          this.newDate
        );
        dataReset = true;
      }
      if (data.increment) {
        currentDate = await this.incrementDateRange(
          data.increment,
          currentDate
        );
        data.increment = undefined;
        dataReset = true;
      }
      const params = {
        date_from: currentDate.date_from,
        date_to: currentDate.date_to,
      };
      if (this.brigade_id) {
        params.brigade_id = data.brigade_id;
      }
      if (!dataReset) {
        currentDate = getDatesByRanges[this.dateRangeType](
          this.newDate
        );
      }
      this.searchFilter = data;
      await this.getSchedule(params, currentDate);
    },
    async incrementDateRange(val, currentDate) {
      if (this.dateRangeType === "month") {
        let currentMonth = this.newDate.getMonth() + val;
        this.newDate.setMonth(currentMonth);
        return getDatesByRanges[this.dateRangeType](this.newDate);
      }
      let needWeek;
      if (val === 1) {
        needWeek = new Date(currentDate.date_to);
      } else {
        needWeek = new Date(currentDate.date_from);
        val = -7;
      }
      needWeek.setDate(needWeek.getDate() + val);
      this.newDate = needWeek;
      return getDatesByRanges[this.dateRangeType](this.newDate);
    },
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.content {
  border-radius: 15px;
  background: $white;
  padding: 40px;
}

.calendar {

  padding: 24px 48px 36px;

  &_item {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
}

.day {
  flex: 1;
  color: $gray2;
  text-align: center;
  height: 24px;
  font-size: 12px;
}

.calendar-bar {
  padding: 10px 16px;

  &_arrow {
    width: 40px;
    height: 40px;
    background: $gray3;
    border-radius: 5px;
  }

  h3 {
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    color: #252525;
    line-height: 125%;
    margin-right: 12px;
  }

  p {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    color: #252525;
    line-height: 125%;
  }
}

</style>
