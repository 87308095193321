<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="details.ticket">
      <div class="flex justify-between mb-2">
        <div class="flex align-center">
          <h2 class="text-black2 mr-2">ЗАЯВКА <b>#{{ details.ticket.ticket_number }}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="details.ticket.status_internal">
            <img
                :src="details.ticket.status_internal.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
                alt="">
            {{ details.ticket.status_internal.title }}
          </div>
        </div>
        <div>
          <img height="24" width="24" class="block cursor-pointer" @click="$router.go(-1)" src="/assets/close.svg" alt=""/>
        </div>
      </div>
      <div class="w-100 flex justify-between">
        <div class="flex column mb-4">
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Создана: </p>
            <p class="text-14 text-black2" v-if="details.ticket.created_at">{{ details.ticket.created_at }}</p>
          </div>
          <div class="flex">
            <p class="text-14 text-gray mr-1">Срок выполнения: </p>
            <p class="text-14 text-black2" v-if="details.ticket.plan_end">{{ details.ticket.plan_end }}</p>
          </div>
        </div>
      </div>
      <div class="w-100 ticket-kind text-center text-black2 mb-2">
        ДОРАБОТКА ДЕМОНТАЖА
      </div>
      <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" @tab-click="changeTab"/>
      <div>
        <div class="info-block" v-if="activeTab === 'info'">
          <div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Название:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.title || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Статус:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.status_internal?.title || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Инициатор:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.author?.full_name || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Исполнитель:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.responsible?.title || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{ details.ticket.address || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="toggleAddressModal" src="/assets/MapPinLine.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Координаты:</p>
              <div class="text-gray5 text-right text-14">
                <div>{{ details.ticket.latitude || ' - ' }}</div>
                <div>{{ details.ticket.longitude || ' - ' }}</div>
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Категория:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.category?.title || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Время жизни:</p>
              <p class="text-gray5 text-right text-14">{{ details.ticket.life_time || ' - ' }}</p>
            </div>
            <div class="mb-2">Сообщение</div>
            <div class="card bg-white rounded p-2">
              <p class="text-gray5 text-14">{{ details.ticket.message || ' - ' }}</p>
            </div>
          </div>
        </div>
        <comments-tab
            v-if="activeTab === 'comment'"
            :comments="ticketComments"
            :totalComments="totalComments"
            @addComment="addComment"
            @loadComments="loadComments"
            @openImage="toggleImageModal"
        />
        <history-tab
            v-if="activeTab === 'history'"
            :history="details.logs"
        />
      </div>
      <div class="w-100 ticket-kind text-center text-black2 mb-2 mt-2">
        СВЯЗАННЫЕ ЗАЯВКИ
      </div>
      <div class="card bg-white rounded" v-if="details.ticket.related_connection_tickets?.length">
        <div v-for="ticket in details.ticket.related_connection_tickets" :key="ticket.id" class="flex gap-2 p-2">
          <router-link :to="ticket.type?.key === 'dis-assembly' ? `/disassembly/${ticket.id}` : `/ticket/${ticket.id}`" class="link">
            #{{ ticket.ticket_number }} - {{ ticket.type?.title || '-' }}
          </router-link>
        </div>
      </div>
      <div v-else class="card bg-white rounded flex align-center text-gray5 text-right text-14 p-2">
        Отсутствуют
      </div>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import HistoryTab from "@/components/tabs/HistoryTab.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";

export default {
  name: 'DisassemblyImprovementsTicketDetailsComponent',
  components: {
    TabsComponent,
    HistoryTab,
    CommentsTab,
    ImageModal,
    CustomModal,
  },
  data() {
    return {
      tabs: [
        {
          title: 'Инфо',
          icon: '/assets/tabs/info-gray.svg',
          icon_active: '/assets/tabs/info-white.svg',
          key: 'info'
        },
        {
          title: 'Комментарии',
          icon: '/assets/tabs/comment-gray.svg',
          icon_active: '/assets/tabs/comment-white.svg',
          key: 'comment'
        },
        {
          title: 'ИСТОРИЯ',
          icon: '/assets/tabs/clock-gray.svg',
          icon_active: '/assets/tabs/clock-white.svg',
          key: 'history'
        },
      ],
      activeTab: 'info',
      image: '',
    }
  },
  computed: {
    ...mapGetters({
      details: "chosenTicket",
      ticketComments: 'ticketComments',
      totalComments: 'totalComments',
    }),
  },
  methods: {
    ...mapActions({
      fetchTicketComments: 'fetchTicketComments',
      addTicketComments: 'addTicketComments',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    
    changeTab(tab) {
      this.activeTab = tab
      if (tab === 'comment') {
        this.loadComments()
      }
    },
    
    toggleImageModal(src = '') {
      this.image = src
    },
    
    addComment(comment) {
      comment.ticket_id = this.details.ticket.id
      this.addTicketComments(comment)
    },
    
    loadComments() {
      this.fetchTicketComments({
        ticket_id: this.details.ticket.id,
        page: 1
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../theme/variables';

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  border-radius: 15px;
  background: $gray4;
  padding: 20px;

  & > div {
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;
    padding: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    & > p:first-child {
      width: 35%;
    }
  }
}

.status-badge {
  padding: 8px 16px;
  border-radius: 20px;
  background: #f5f5f5;
  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}

.link {
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.card {
  background: white;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
}
</style> 