<template>
  <div class="wrapper modified flex column">
    <div class="mb-3">
      <div class="flex p-20">
        <div
            class="ticket-menu flex justify-center align-center cursor-pointer mr-4"
            v-for="menu in menuMainArray"
            :key="menu.key"
            :class="{'active': currentTab === menu.key}"
            @click="changeTab(menu)"
        >
          {{ menu.title }}
        </div>
      </div>
      <div class="bg-white p-20 rounded">
        <div class="w-100 p-20 border-bottom">
          <filter-bar ref="filterBar" :is-create="true" :create-text="'Создать заявку'" @on-create="toggleCreateModal"
                      :client-access="'Сервисные'"
                      :emergency-access="'Аварийные'"
                      @on-emergency-access="fetchAccessTickets(1, 'emergency')"
                      @on-client-access="fetchAccessTickets(1, 'client')" :is-search="true" :is-access-tickets="true"  @on-search="searchTickets"
                      :is-filter="true"
                      :is-date="true"
                      :isStatuses="true"
                      @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
              }"
                      @on-filter="filterModalSubmit"></filter-bar>
        </div>
        <custom-table :columns="tableColumns" :data="tickets" :total-pages="ticketsTotalPages"
                      :current-page="currentPage"
                      :is-pagination="true" @page-change="fetchFilteredTickets"
                      @get-detail="getTicketDetail"></custom-table>
      </div>

    </div>


  </div>
  <custom-modal v-if="isCreateTicketModal" @close="toggleCreateModal">
    <choose-ticket-type @choose-ticket-kind="chooseTicketKind"/>
  </custom-modal>
</template>

<script>
import CustomTable from "@/components/modules/CustomTable.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {AccessDataTable} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import router from "@/router";
import CustomModal from "@/components/modules/CustomModal.vue";
import ChooseTicketType from "@/components/modals/tickets/ChooseTicketType.vue";
import {formatDate} from "@/helpers/utils";

export default {
  name: "AccessDataTicketsView",
  components: {ChooseTicketType, CustomModal, FilterBar, CustomTable},
  created() {
    this.tableColumns = AccessDataTable
    this.fetchFilteredTickets()
    this.fetchTicketKinds()
    this.fetchAccessTickets(1, 'client')
    this.$nextTick(() => {
      const filterBar = this.$refs.filterBar
      if (filterBar) {
        filterBar.isClientAccess = true
        filterBar.isEmergencyAccess = false
      }
    })
  },
  data() {
    return {
      tableColumns: [],
      currentPage: 1,
      perPage: 15,
      isCreateTicketModal: false,
      related_type: 'client',
      search: '',
      searchTimeout: null,
      currentTab: 'department',
      status_ids: [],
      dateRange: null,
      menuMainArray: [
        {
          key: 'department',
          title: 'Открытые  заявки'
        },
        {
          key: 'all',
          title: 'Все заявки'
        },
        {
          key: 'closed',
          title: 'Закрытые заявки'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      tickets: 'tickets',
      ticketsTotalPages: 'ticketsTotalPages',
      user: 'user',
    })
  },
  methods: {
    ...mapActions({
      fetchTickets: 'fetchTickets',
      fetchTicketKinds: 'fetchTicketKinds'
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async fetchFilteredTickets(page = 1) {
      this.setLoading(true)
      let filters = {}
      filters.page = page
      filters.ticket_kind_id = 16
      filters.tickets_per_page = this.perPage
      if (this.search) filters.client_name = this.search
      if (this.status_ids.length > 0) filters.status_ids = this.status_ids
      if (this.$route.query.is_expired) filters.is_expired = this.$route.query.is_expired
      if (this.$route.query.is_only_open) filters.is_only_open = this.$route.query.is_only_open
      if (this.dateRange && Array.isArray(this.dateRange)) {
        if (this.dateRange[0]) filters.date_from = formatDate(this.dateRange[0], '-')
        if (this.dateRange[1]) filters.date_to = formatDate(this.dateRange[1], '-')
      }
      this.currentPage = page
      if (this.currentTab === 'department') {
        filters.is_only_open = 1
        delete filters.is_closed
      } else if (this.currentTab === 'closed'){
        filters.is_closed = 1
        delete filters.is_only_open
      } else {
        delete filters.is_only_open
        delete filters.is_closed
      }
      this.related_type ? filters.related_type = this.related_type : null
      await this.fetchTickets(filters)
      this.setLoading(false)
    },
    toggleCreateModal() {
      this.isCreateTicketModal = !this.isCreateTicketModal
    },
    async chooseTicketKind(id) {
      this.toggleCreateModal()
      if (id === 2) {
        await router.push('/service-ticket-form')
      } else if (id === 8) {
        await router.push('/plan-ticket-form')
      } else if (id === 10) {
        await router.push('/consultation-ticket-form')
      } else if (id === 12) {
        await router.push('/internal-ticket-form')
      } else if (id === 16) {
        await router.push('/access-form-view')
      } else await router.push('/emergency-ticket-form')
    },
    async getTicketDetail(ticket) {
      this.setLoading(true)
      router.push(`access/ticket/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    async fetchAccessTickets(page = 1, type) {
      this.setLoading(true)
      let filters = {}
      filters.page = page
      filters.ticket_kind_id = 16
      filters.tickets_per_page = this.perPage
      this.currentPage = page
      this.related_type = type
      filters.ticket_kind_id = 16
      filters.related_type = this.related_type
      this.currentTab === 'department' ? filters.is_only_open = 1 : null
      this.currentTab === 'closed' ? filters.is_closed = 1 : null

          await this.fetchTickets(filters).then(() => {
        this.setLoading(false)
      })
    },
    searchTickets(searchText) {
      this.search = searchText
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredTickets()
      }, 1500)
    },
   async changeTab(menu){
     this.currentTab = menu.key
     await this.fetchFilteredTickets()
    },
    filterModalSubmit(filters, statuses) {
      if (Array.isArray(statuses) && statuses.length > 0) {
        this.status_ids = statuses
      } else {
        this.status_ids = []
      }
      this.fetchFilteredTickets()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}
</style>