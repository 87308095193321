import { api } from '@/service/axios'

export default {
  namespaced: true,

  state: {
    serviceTypes: {},
    rewardTypes: {},
    companies: {},
    rewards: [],
    tasks: {
      meganet: [],
      nls: []
    },
    costTypes: {}
  },

  mutations: {
    SET_SERVICE_TYPES(state, types) {
      state.serviceTypes = types
    },
    SET_REWARD_TYPES(state, types) {
      state.rewardTypes = types
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_REWARDS(state, rewards) {
      state.rewards = rewards
    },
    SET_TASKS(state, { company, tasks }) {
      state.tasks[company] = tasks
    },
    SET_COST_TYPES(state, types) {
      state.costTypes = types
    }
  },

  actions: {
    async fetchServiceTypes({ commit }) {
      const res = await api.get('/installer/reward/service-types')
      if (res.data) {
        commit('SET_SERVICE_TYPES', res.data)
      }
    },

    async fetchRewardTypes({ commit }) {
      const res = await api.get('/installer/reward/types')
      if (res.data) {
        commit('SET_REWARD_TYPES', res.data)
      }
    },

    async fetchCostTypes({ commit }) {
      const res = await api.get('/installer/reward/cost-types')
      if (res.status) {
        commit('SET_COST_TYPES', res)
      }
    },

    async fetchCompanies({ commit }) {
      const res = await api.get('/installer/reward/companies')
      if (res.data) {
        commit('SET_COMPANIES', res.data)
      }
    },

    async createTask({ commit }, taskData) {
      const res = await api.post('/installer/reward', taskData)
      if (!res.success) {
        throw new Error(res.message)
      }
    },

    async fetchRewards({ commit }, { reason, company }) {
      const res = await api.get('/installer/reward', {
        params: { reason, company }
      })
      if (res.success) {
        commit('SET_REWARDS', res.data)
      }
    },

    async fetchTasks({ commit }, { reason, company }) {
      const res = await api.get('/installer/reward', {
        params: { reason, company }
      })
      if (res.success) {
        commit('SET_TASKS', { company, tasks: res.data })
      }
    },

    async deleteTask({ commit, dispatch }, { id }) {
      const res = await api.delete(`/installer/reward/${id}`)
      if (res.success) {
        return true
      }
      throw new Error(res.data?.message || 'Failed to delete task')
    },

    async updateTask({ commit }, taskData) {
      const { id, ...data } = taskData;
      const res = await api.put(`/installer/reward/${id}`, data);
      if (!res.success) {
        throw new Error(res.message || 'Failed to update task');
      }
      return res.data;
    },
  },

  getters: {
    serviceTypesList: state => state.serviceTypes,
    rewardTypesList: state => state.rewardTypes,
    companiesList: state => state.companies,
    costTypesList: state => state.costTypes
  }
} 