import axios from "axios";

const tasksBaseApiUrl = process.env.VUE_APP_TASKS_API_URL;
const tasksUrl = process.env.VUE_APP_TASKS_URL;
const tasksProvider = axios.create({
	baseURL: `${tasksBaseApiUrl}/api/`,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json"
	}
});

tasksProvider.interceptors.request.use(config => {
	const tasksCred = JSON.parse(localStorage.getItem('tasksCred'))

	if (tasksCred?.token) {
		config.headers['X-Auth-Token'] = tasksCred.token
	}

	return config
})

export { tasksProvider, tasksBaseApiUrl, tasksUrl };
