<template>
    <div class="progress-circle">
        <svg :width="size" :height="size" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" fill="none" stroke="#eee" stroke-width="8" />

            <circle cx="50" cy="50" r="45" fill="none" :stroke="color" stroke-width="8" :style="{
                strokeDasharray: circumference,
                strokeDashoffset: dashOffset
            }" transform="rotate(-90 50 50)" />

            <text x="50" y="50" text-anchor="middle" dominant-baseline="middle" font-size="22">
                {{ percentage }}%
            </text>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'CircularProgress',

    props: {
        value: {
            type: Number,
            required: true
        },
        maxValue: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            default: '#2196F3'
        },
        size: {
            type: Number,
            default: 100
        }
    },

    computed: {
        percentage() {
            return Math.round((this.value / this.maxValue) * 100)
        },

        circumference() {
            return 2 * Math.PI * 45
        },

        dashOffset() {
            const progress = this.value / this.maxValue
            return this.circumference * (1 - progress)
        }
    }
}
</script>

<style scoped>
.progress-circle {
    display: inline-block;
}

circle {
    transition: stroke-dashoffset 0.35s;
    transform-origin: 50% 50%;
}
</style>