let currentTime = "";
let weekName = "";
let monthName = "";
let dayDate = "";
function todayDate() {
	const today = new Date();
	const date =
		today.getFullYear() +
		"-" +
		(today.getMonth() + 1) +
		"-" +
		today.getDate();
	const time =
		String(today.getHours()).padStart(2, "0") +
		":" +
		String(today.getMinutes()).padStart(2, "0");
	const dayOfWeek = today.getDay();
	const month = today.getMonth();
	const weekNamesRussian = [
		"Воскресенье",
		"Понедельник",
		"Вторник",
		"Среда",
		"Четверг",
		"Пятница",
		"Суббота",
	];
	const monthNamesRussian = [
		"Января",
		"Февраля",
		"Марта",
		"Апреля",
		"Май",
		"Июня",
		"Июля",
		"Августа",
		"Сентября",
		"Октября",
		"Ноября",
		"Декабря",
	];
	weekName = weekNamesRussian[dayOfWeek];
	monthName = monthNamesRussian[month];
	dayDate = today.getDate();
	currentTime = time;
}
export { todayDate, currentTime, weekName, monthName, dayDate };
