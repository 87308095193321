<template>
  <div class="p-20">
    <div>
      <h1 class="text-24 text-center mb-4">Изменить тип доступа</h1>
    <form>
      <div class="input-container input-container__form mb-1">
        <input class="input input-form" v-model="address"
               placeholder="Адрес" type="text" disabled/>
        <label class="label-form">Адрес</label>
      </div>
      <div class="mb-2" >
        <div class="mb-1">Тип доступа</div>
        <select class="input-form select" style="max-width: 340px" v-model="access_type_id">
          <option
              v-for="option in accessTypes"
              :key="option.key"
              :value="option.id"
          >
            {{ option.title }}
          </option>
        </select>
      </div>
      <div class="input-container input-container__form mb-2">
        <input class="input input-form" v-model="owner_fullname"
               placeholder="ФИО собственника" type="text" />
        <label class="label-form">ФИО собственника</label>
      </div>
      <div class="input-container input-container__form mb-2">
        <input class="input input-form" v-model="owner_phone"
               placeholder="Номер собственника" type="text" />
        <label class="label-form">Номер собственника</label>
      </div>
      <button @click.prevent="onSubmitFunc" class="btn form-btn mt-2">Изменить</button>
    </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AccessDataAddressTicketModal",
  props: {
    currentAddress: {
      type: Object,
      default: ()=> {}
    },
  },
  data() {
    return {
      address: '',
      access_type_id: null,
      owner_fullname: '',
      owner_phone: '',
    }
  },
  created() {
    this.getAccessAddressType();
    this.address = this.currentAddress.address;
    this.access_type_id = this.currentAddress.access_type ? this.currentAddress.access_type.id : null;
    this.owner_fullname = this.currentAddress.owner_fullname;
    this.owner_phone = this.currentAddress.owner_phone;
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
      accessTypes: 'accessTypes',
    })
  },
  methods: {
    ...mapActions({
      storeTicketAddress: 'storeTicketAddress',
      getAccessAddressType: 'getAccessAddressType',
    }),
   async onSubmitFunc() {
      const obj = {
        address: this.address,
        owner_fullname: this.owner_fullname,
        owner_phone: this.owner_phone,
        ticket_address_access_type_id: this.access_type_id,
        latitude: this.currentAddress.latitude,
        longitude: this.currentAddress.longitude,
        id: this.currentAddress.id,
      }
      await this.storeTicketAddress(obj).then(response => {
        if (response.success){
          this.$toast.success('Тип доступа был успешно изменен')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось изменить тип доступа');
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    }
  },
}

</script>

<style scoped lang="scss">

</style>