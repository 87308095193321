<template>
  <div class="flex column">
    <div class="modal_title flex align-center mb-4">
      <h3 class="text-black2">{{title}}</h3>
    </div>
    <div class="w-100 p-20 bg-gray rounded">
      <div
        class="card rounded bg-white flex align-center justify-between cursor-pointer text-black2 mb-1"
        :class="{'active': newSelected.length === array.length}"
        @click="chooseAll()"
      >
        <p>Все</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between cursor-pointer text-black2 mb-1"
        v-for="item in array"
        :key="item.id"
        :class="{'active': newSelected.indexOf(item.id) > -1}"
        @click="itemClick(item.id)"
      >
        <p>{{ item[itemTitle] }}</p>
      </div>
    </div>
    <button type="button" class="btn mt-2" @click="submit">Выбрать</button>
  </div>
</template>

<script>


export default {
  name: 'MultipleOptionModal',
  props: ['array', 'selected', 'title', 'itemTitle'],
  data() {
    return {
      newSelected: this.$props.selected.length ? this.$props.selected : [],
    };
  },
  methods: {
    itemClick(id) {
      if (this.newSelected.indexOf(id) > -1) {
        this.newSelected.splice(this.newSelected.indexOf(id), 1)
      }
      else this.newSelected.push(id)
    },
    chooseAll() {
      if (this.newSelected.length === this.$props.array.length) {
        this.newSelected = []
      }
      else {
        this.newSelected = []
        this.$props.array.forEach(item => {
          this.newSelected.push(item.id)
        })
      }
    },
    submit() {
      this.$emit('submit', this.newSelected)
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.card.active,
.card:hover {
  background: linear-gradient(91deg, #0095FF 1.98%, #00557B 101.6%);
  color: $white;
}

</style>
