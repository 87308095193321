<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100 mb-4">
      Выберите тип заявки:
    </div>
    <div class="choose-modal__btn flex align-center justify-between mb-2" v-for="kind in filterTicketKinds" :key="kind.id" :class="kind.key" @click="chooseTicketKind(kind.id)">
      <p>{{kind.title}}</p>
      <img :src="`assets/ticket-kinds/${kind.key}.svg`" alt="" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ChooseTicketType',
  methods: {
    close() {
      this.$emit('close')
    },
    chooseTicketKind(id) {
      this.$emit('chooseTicketKind', id)
    }
  },
  computed: {
    ...mapGetters({
      ticketKinds: 'ticketKinds',
    }),
    filterTicketKinds() {
      return this.ticketKinds.filter((item) => item.key !== 'lks')
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: $black2;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 56px;
    cursor: pointer;
    padding: 0 16px;
    border-radius: 5px;

    img {
      width: 32px;
      height: 32px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }

    &.service {
      color: $green;
      border: 1px solid $green;
    }

    &.emergency {
      color: $orange;
      border: 1px solid $orange;
    }

    &.plan {
      color: $blue3;
      border: 1px solid $blue3;
    }

    &.consultation {
      color: $blue4;
      border: 1px solid $blue4;
    }

    &.internal {
      color: $blue5;
      border: 1px solid $blue5;
    }
    &.subtask {
      color: $blue;
      border: 1px solid $blue;
    }
    &.access {
      color: $green;
      border: 1px solid $green
    }
    &.lks {
      color: $gray9;
      border: 1px solid $gray9
    }
  }
}

</style>
