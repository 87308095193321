<template>
  <div class="p-20">
    <div class="p-20">
    <history-tab :history="chosenTicket.logs"></history-tab>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import HistoryTab from "@/components/tabs/HistoryTab.vue";

export default {
  name: "DepartureServiceTicketsLogsModal",
  components: {HistoryTab},
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  async created() {
    this.setLoading(true)
    await this.fetchTicketDetail(this.id);
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
    })
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'ticketDetail',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    })
  },
}
</script>


<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  width: 1024px;
  grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr;
  grid-template-areas: 'Num Date Author Action Change Comment';
}

.grid-num {
  grid-area: Num;
}

.grid-date {
  grid-area: Date;
}

.grid-author {
  grid-area: Author;
}

.grid-action {
  grid-area: Action;
}

.grid-change {
  grid-area: Change;
}

.grid-comment {
  grid-area: Comment;
}
</style>