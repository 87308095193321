import {api} from "@/service/axios";

export const gisKeyModule = {
    state: () => ({
        gisKey: '',
    }),
    getters: {
        gisKey(state) {
            return state.gisKey
        },
    },
    mutations: {
        setKey(state, payload) {
            state.gisKey = payload
        },
    },
    actions: {
        async get2GisKey({commit}, payload) {
            const res = await api.get('get-gis-random-key?source=2gis')
            commit('setKey', res.key)
            return res
        }
    }
}