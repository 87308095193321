<template>
  <date-picker
    range
    :model-value="dateRange"
    @update:model-value="value => submit(value)"
    format="dd MMM yyyy"
    :enable-time-picker="false"
    :multi-calendars="true"
    input-class-name="dp-custom-input"
    :class="{'filter-bar-dp': isFilterBar}"
    :format-locale="ru"
    locale="ru"
    placeholder="Дата"
    @clear="onClear"
  >
    <template #action-row="{ closePicker, selectDate }">
      <div class="dp-action-row">
        <button class="clear-button" @click="clearPicker(closePicker)">Сбросить</button>
        <button class="select-button" @click="selectDate">Применить</button>
      </div>
    </template>
  </date-picker>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ru from "date-fns/locale/ru/index.js";

const props = defineProps(['dateRange', 'isFilterBar']);
const emits = defineEmits(['set-date-range']);

function clearPicker(close) {
  emits('set-date-range', null);
  if (close) close();
}

function onClear() {
  clearPicker();
}

function submit(value) {
  if (value) {
    if (value[0] && value[1]) emits('set-date-range', value)
  } else {
    clearPicker();
  }
}
</script>

<style lang="scss">
input.dp-custom-input {
  width: 100%;
  height: 48px;
  background: #FFF;
  border: 1px solid #8B88AB !important;
  border-radius: 25px !important;

  &:hover, &:focus {
    border: 1px solid #FF6700 !important;
  }
}

.filter-bar-dp {
  .dp-custom-input {
    width: 342px;
  }
}

</style>
