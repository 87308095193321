<template>
  <div class="calendar-wrapper">
    <div v-if="label" class="calendar-label">{{ label }}</div>
    <div class="calendar-container">
      <input
        type="text"
        class="input-form select mt-1"
        :value="formatDateRange"
        @click="toggleDropdown"
        :placeholder="placeholder"
        readonly
        :disabled="disabled"
      />
      <img
        src="/assets/calend-icon.svg"
        class="calendar-icon"
        alt="calendar"
      />
      <div class="calendar-controls">
        <img
          v-if="showClearButton && (startDate || endDate)"
          src="/assets/clear-input.svg"
          class="clear-icon"
          alt="clear"
          @click.stop="clearDates"
        />
      </div>
      <div
        v-if="isOpen"
        class="calendar-dropdown"
      >
        <div class="date-inputs">
          <input
            type="date"
            class="input-form"
            v-model="startDate"
          >
          <input
            type="date"
            class="input-form"
            v-model="endDate"
          >
        </div>
        <div class="apply-button">
          <button
            type="button"
            class="btn-apply"
            @click="applyDates"
          >
            Применить
          </button>
        </div>
        <div class="quick-select">
          <p class="quick-select-title">Или вывести данные за последний(-е):</p>
          <div class="quick-select-buttons">
            <button class="calendar-btn" @click="setTodayDate">Cегодня</button>
            <button class="calendar-btn" @click="setYesterdayDate">Вчера</button>
            <button class="calendar-btn" @click="setWeekDate">Неделя</button>
            <button class="calendar-btn" @click="setMonthDate">Месяц</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { formatDate } from "@/helpers/utils"
import {parse, format, startOfMonth} from 'date-fns'

export default {
  name: 'BaseCalendar',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Период'
    },
    placeholder: {
      type: String,
      default: 'Выберите период'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultDateRange: {
      type: Array,
      default: () => [null, null]
    },
    showClearButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
      startDate: this.defaultDateRange && this.defaultDateRange.length
          ? this.defaultDateRange[0]
          : '',
      endDate: this.defaultDateRange && this.defaultDateRange.length
          ? this.defaultDateRange[1]
          : ''
    }
  },
  computed: {
    formatDateRange() {
      if (!this.startDate || !this.endDate) return ''
      return `${this.formatDate(this.startDate)} / ${this.formatDate(this.endDate)}`
    }
  },
  methods: {
    formatDate(dateString) {
      const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
      return format(parsedDate, 'yyyy-MM-dd');
    },
    toggleDropdown() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen
      }
    },
    applyDates() {
      if (this.startDate && this.endDate) {
        this.$emit('setDate', [
          this.formatDate(this.startDate),
          this.formatDate(this.endDate)
        ])
        this.isOpen = false
      }
    },
    setTodayDate() {
      const currentDate = new Date()
      this.startDate = this.formatDate(format(currentDate, 'yyyy-MM-dd'))
      this.endDate = this.formatDate(format(currentDate, 'yyyy-MM-dd'))
      this.applyDates()
    },
    setYesterdayDate() {
      const currentDate = new Date()
      const yesterdayDate = new Date(currentDate)
      yesterdayDate.setDate(currentDate.getDate() - 1)
      
      this.startDate = this.formatDate(format(yesterdayDate, 'yyyy-MM-dd'))
      this.endDate = this.formatDate(format(yesterdayDate, 'yyyy-MM-dd'))
      this.applyDates()
    },
    setWeekDate() {
      const currentDate = new Date()
      const startDateWeek = new Date(currentDate)
      startDateWeek.setDate(startDateWeek.getDate() - 7)
      
      this.startDate = this.formatDate(format(startDateWeek, 'yyyy-MM-dd'))
      this.endDate = this.formatDate(format(currentDate, 'yyyy-MM-dd'))
      this.applyDates()
    },
    setMonthDate() {
      const currentDate = new Date()
      const startDateMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      const endDateMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      
      this.startDate = this.formatDate(format(startDateMonth, 'yyyy-MM-dd'))
      this.endDate = this.formatDate(format(endDateMonth, 'yyyy-MM-dd'))
      this.applyDates()
    },
    handleClickOutside(event) {
      const calendar = this.$el
      if (!calendar.contains(event.target)) {
        this.isOpen = false
      }
    },
    clearDates() {
      this.startDate = ''
      this.endDate = ''
      this.$emit('setDate', [null, null])
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (Array.isArray(newValue) && newValue.length === 2) {
          const [start, end] = newValue
          this.startDate = start
          this.endDate = end
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.calendar-wrapper {
  width: 100%;
}

.calendar-label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.calendar-container {
  position: relative;
  width: 100%;

  .calendar-controls {
    position: absolute;
    right: 12px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .clear-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 1;
    }
  }

  .calendar-icon {
    position: absolute;
    left: 12px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    pointer-events: none;
    z-index: 1;
  }

  .input-form {
    background: #F2F1F0 !important;
    border: none !important;
    border-radius: 12px !important;
    height: 44px !important;
    width: 100%;
    padding: 0 40px 0 36px;
    
    &::placeholder {
      color: #757575;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .calendar-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    z-index: 100;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    
    .date-inputs {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 10px;
      
      .input-form {
        padding: 0 12px;
        cursor: pointer;
      }
    }
    
    .apply-button {
      padding: 10px;
      
      .btn-apply {
        background: #00C82C;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 8px 16px;
        cursor: pointer;
        width: 100%;
        
        &:hover {
          background: darken(#00C82C, 5%);
        }
      }
    }
    
    .quick-select {
      padding: 10px;
      
      &-title {
        color: #6B7280;
        margin-bottom: 10px;
        font-size: 14px;
      }
      
      &-buttons {
        display: flex;
        justify-content: space-between;
        gap: 8px;
      }
    }
  }
}

.calendar-btn {
  border: none;
  background: transparent;
  color: #6B7280;
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 8px;
  
  &:hover {
    color: #00C82C;
    text-decoration: none;
  }
}
</style> 