<template>
  <div class="modal-content">
    <h2 class="modal-title">УДАЛЕНИЕ СТРАНИЦЫ</h2>
    
    <div class="modal-body">
      <p class="confirmation-text">
        Вы уверены, что хотите удалить страницу "{{ page.title }}"?
      </p>
    </div>

    <div class="modal-actions">
      <button class="btn-cancel" @click="$emit('close')">Отмена</button>
      <button class="btn-delete" @click="handleDelete">Удалить</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DeletePageModal',
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      deletePage: 'pagesModule/deletePage',
      fetchPages: 'pagesModule/fetchPages'
    }),
    async handleDelete() {
      try {
        const result = await this.deletePage(this.page.key)
        if (result.success) {
          await this.fetchPages()
          this.$toast.success('Страница успешно удалена')
          this.$emit('deleted')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось удалить страницу')
        }
      } catch (error) {
        this.$toast.error('Произошла ошибка при удалении')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-content {
  width: 100%;
  max-width: 400px;
}

.modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
}

.modal-body {
  margin-bottom: 24px;
}

.confirmation-text {
  text-align: center;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}

.modal-actions {
  display: flex;
  gap: 12px;
  justify-content: center;

  button {
    width: 120px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-delete {
    background: #FF4D4D;
    color: white;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #333;
    border: none;

    &:hover {
      background: #F5F5FA;
    }
  }
}
</style> 