import { api } from "@/service/axios";

export const usersModule = {
  state: () => ({
    users: [],
    userInfo: {},
  }),
  getters: {
    users(state) {
      return state.users;
    },
    userInfo(state) {
      return state.userInfo;
    },
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    async fetchUsers({ commit }, payload) {
      const res = await api.get("managers/manager-list", {
        params: payload,
      });
      commit("setUsers", res.data.managers);
    },
    async createUser(_commit, payload) {
      const res = await api.post("managers/store-manager", payload);
      return res.data;
    },
    async updateUser(_commit, payload) {
      const res = await api.post("managers/manager-update", payload);
      return res;
    },
    async blockUser(_commit, payload) {
      const res = await api.post("managers/manager-block", payload);
      return res.data;
    },
    async fetchUserDetails({ commit }, payload) {
      const res = await api.get(`managers/manager-get?manager_id=${payload}`);
      console.log(res.data)
      commit("setUserInfo", res.data);
    },
  },
};
