<template>
  <div class="mt-2">
    <p class="text-center">Добавить разрешение</p>
    <select class="input-form select mt-2 mb-3" v-model="newPermission" @change="resetValues">
      <option v-for="option in allSections" :key="option.id" :value="option.key">
        {{ option.title }}
      </option>
    </select>
    <div class="flex justify-center mb-4" v-if="newPermission">
      <div>
        <div class="flex justify-between">
          <label for="" class="block">Возможность редактировать</label>
          <input type="checkbox" v-model="isEdit">
        </div>

        <div class="flex justify-between">
          <label for="" class="block">Возможность просматривать</label>
          <input type="checkbox" v-model="isShow">
        </div>
        <div class="flex justify-between">
          <label for="" class="block">Возможность создавать</label>
          <input type="checkbox" v-model="isCreate">
        </div>
        <div class="flex justify-between">
          <label for="" class="block">Возможность удалять</label>
          <input type="checkbox" v-model="isDelete">
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <button class="btn" style="width: fit-content" @click.prevent="createPermission">Добавить разрешение
      </button>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AddNewPermissionModal",
  created() {
    this.fetchAllSections();
  },
  data() {
    return {
      newPermission: '',
      isEdit: false,
      isCreate: false,
      isShow: false,
      isDelete: false,
    }
  },
  computed: {
    ...mapGetters({
      allSections: 'allSections',
    })
  },
  methods: {
    ...mapActions({
      fetchAllSections: 'fetchAllSections',
    }),
    resetValues() {
      this.isEdit = false
      this.isCreate = false
      this.isShow = false
      this.isDelete = false
    },
    createPermission(){
     const obj = {
        section_key: this.newPermission,
        is_show: this.isShow,
        is_edit: this.isEdit,
        is_create: this.isCreate,
        is_delete: this.isDelete
      }
      this.$emit('close', obj)
    }
  },
}
</script>

<style scoped lang="scss">

</style>