export const fullAccessRoles = [
  'ADMIN',
  'SUPERVISOR',
  'support_super_admin',
  'it_admin',
  'tech_admin',
  'tech_super_admin',
  'dev_super_admin',
  'maintenance_head',
  'service_department_head',
  'lks_head',
  'vols_head',
  'sks_head',
  'vols_development_head',
  'sales_head',
  'subscriptions_head',
  'tech_director',
  'net_projecting_head'
] 