<template>
  <CustomModal @close="$emit('close')" title="Удаление задачи">
    <div class="delete-task-form">
      <h2 class="modal-title">Удалить задачу?</h2>
      <p class="delete-description">
        Вы уверены, что хотите удалить эту задачу? Это действие нельзя отменить.
      </p>
      <div class="modal-actions">
        <button
          class="btn btn-danger"
          @click="$emit('confirm')"
          :disabled="loading"
        >
          {{ loading ? "Удаление..." : "Удалить" }}
        </button>
        <button class="btn btn-secondary" @click="$emit('close')">
          Отмена
        </button>
      </div>
    </div>
  </CustomModal>
</template>

<script>
import CustomModal from "@/components/modules/CustomModal.vue";

export default {
  name: "DeleteTaskModal",
  components: { CustomModal },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "confirm"],
};
</script>

<style lang="scss" scoped>
.delete-task-form {
  max-width: 400px;

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
    padding-bottom: 8px;
  }

  .delete-description {
    color: #666;
    margin: 12px 0 24px 0;
  }

  .modal-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;

    .btn {
      min-width: 100px;
      height: 40px;
      font-weight: 500;
      border-radius: 10px;
    }

    .btn-danger {
      background: #dc2626;
      color: white;
      border: none;

      &:hover {
        background: #b91c1c;
      }

      &:disabled {
        background: #f87171;
        cursor: not-allowed;
      }
    }

    .btn-secondary {
      background: #d0d0d0;
      color: white;
      border: none;
    }
  }
}
</style>
