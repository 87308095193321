<template>
  <div class="flex column mt-4 dashboard-categories">
    <h4 class="dashboard-categories__title">Категории заявок</h4>
    <div class="dashboard-categories__item" v-for="item in serviceCatBlock" :key="item.id">
      <div class="w-100 flex justify-between mb-1">
        <p>{{item.title}}</p>
        <p class="bold">{{item.percent}}%</p>
      </div>
      <k-progress class="progress-bar" :percent="Number(item.percent)" :show-text="false" :color="getColour(item.key)" :line-height="14"></k-progress>
    </div>
  </div>

</template>

<script>

import KProgress from 'k-progress-v3';
export default {
  name: 'TicketCountProgress',
  props: ['serviceCatBlock'],
  components: {KProgress},
  data() {
    return {
    }
  },
  methods: {
    getColour(type) {
      switch(type) {
        case 'internet':
          return 'linear-gradient(134deg, #00557B 0%, #84BAFF 100%)'

        case 'telephony':
          return 'linear-gradient(134deg, #11998E 0%, #38EF7D 100%)'

        case 'tv':
          return 'linear-gradient(134deg, #FF8008 0%, #FFC837 100%)'

        case 'cloud_solutions':
          return 'linear-gradient(134deg, #11998E 0%, #38EF7D 100%)'

        case 'data_link':
          return 'linear-gradient(134deg, #00557B 0%, #84BAFF 100%)'

        default:
          return 'linear-gradient(134deg, #2193B0 0%, #6DD5ED 100%)'
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.dashboard-categories {
  width: calc(100% - 884px);
  border-radius: 13px;
  background: #FFF;
  box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.10);
  padding: 24px 40px;
  @media (max-width: 1440px) {
    width: 100%;
  }
}

.dashboard-categories__title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  margin-bottom: 16px;
}

.dashboard-categories__item {
  margin-bottom: 12px;

  p {
    color: #000;
    font-size: 17px;
    line-height: normal;
    letter-spacing: 0.17px;
  }
}

</style>
