<template>
  <div class="modal-content">
    <h2 class="modal-title">ДОБАВИТЬ СТРАНИЦУ</h2>

    <div class="form-section">
      <div class="form-field">
        <BaseDropdown
          label="Выберите существующую страницу"
          placeholder="Поиск страницы..."
          :items="dropdownItems"
          v-model:selectedValue="selectedPageKey"
          keyProp="name"
          titleProp="text"
          :showAllOption="false"
          @change="handlePageSelect"
        />
      </div>
      <div class="form-field">
        <BaseInput label="Название страницы" v-model="page.title" placeholder="Введите название страницы" />
      </div>
      <div class="form-field">
        <BaseInput label="Ключ страницы" v-model="page.key" placeholder="Введите ключ страницы" />
      </div>
      <div class="form-field">
        <label>Описание</label>
        <AppTextarea v-model="page.meta.description" placeholder="Введите описание страницы" class="description-textarea" />
      </div>
      <div class="form-field">
        <BaseInput 
          label="Ключевые слова"
          v-model="keywordsInput"
          placeholder="Введите ключевые слова через запятую"
          @update:modelValue="updateKeywords"
        />
      </div>
    </div>

    <div class="modal-actions">
      <button class="btn-cancel" @click="$emit('close')">Отмена</button>
      <button class="btn-save" @click="savePage">Сохранить</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { navigation } from '@/config/navigation'
import BaseDropdown from '@/components/ui/BaseDropdown.vue'
import BaseInput from '@/components/ui/BaseInput.vue'
import AppTextarea from '@/components/ui/AppTextarea.vue'

export default {
  name: 'AddPagesModal',
  components: {
    BaseDropdown,
    BaseInput,
    AppTextarea
  },
  data() {
    return {
      keywordsInput: '',
      selectedPageKey: null,
      dropdownItems: [],
      page: {
        title: '',
        key: '',
        meta: {
          description: '',
          keywords: []
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      existingPages: 'pagesModule/pages'
    }),
  },
  async created() {
    await this.fetchPages()
    this.initializeDropdownItems()
  },
  methods: {
    ...mapActions({
      createPage: 'pagesModule/createPage',
      fetchPages: 'pagesModule/fetchPages'
    }),
    initializeDropdownItems() {
      const allPages = this.flattenNavigation(navigation)
      // Убираем страницы, которые уже существуют, сравнивая ключи
      this.dropdownItems = allPages.filter(page => {
        return !this.existingPages.some(existingPage => existingPage.key === page.name)
      })
    },
    flattenNavigation(navItems) {
      let pages = []
      navItems.forEach(item => {
        pages.push({
          text: `${item.text}\n${item.href}`,
          href: item.href,
          name: item.name
        })
        
        if (item.inner && item.inner.length) {
          item.inner.forEach(subItem => {
            pages.push({
              text: `${subItem.text}\n${subItem.href}`,
              href: subItem.href,
              name: subItem.name
            })
          })
        }
      })
      return pages
    },
    handlePageSelect(page) {
      if (!page) {
        this.clearForm()
        return
      }
      
      const [text] = page.text.split('\n')
      this.page.key = page.name
      this.page.title = text
      this.page.path = page.href
      this.page.meta.description = text
      this.keywordsInput = text.toLowerCase()
      this.updateKeywords({ target: { value: this.keywordsInput } })
    },
    clearForm() {
      this.page.key = ''
      this.page.title = ''
      this.page.path = ''
      this.page.meta.description = ''
      this.keywordsInput = ''
      this.page.meta.keywords = []
    },
    updateKeywords(e) {
      this.keywordsInput = e.target.value
      this.page.meta.keywords = e.target.value.split(',').map(k => k.trim()).filter(k => k)
    },
    async savePage() {
      try {
        const result = await this.createPage(this.page)
        if (result.success) {
          await this.fetchPages()
          this.$toast.success('Страница успешно добавлена')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось добавить страницу')
        }
      } catch (error) {
        this.$toast.error('Произошла ошибка при сохранении')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-content {
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

:deep(.dropdown) {
  .input-form {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
}

:deep(.dropdown-content) {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

:deep(.dropdown-item) {
  white-space: pre-line !important;
  line-height: 1.4;
  
  &:first-line {
    font-weight: 500;
    color: #333;
  }
}

.modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
  flex-shrink: 0;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding: 0 2px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

.form-field {
  label {
    display: block;
    color: #333333;
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.input-form {
  width: 100%;
  height: 44px;
  background: #F5F5FA;
  border: none;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  color: #333;

  &::placeholder {
    color: #888B94;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(4, 86, 123, 0.1);
  }
}

.description-textarea {
  height: 80px !important;
  min-height: 80px !important;
  border: none !important;
  font-family: inherit;
  font-size: 16px !important;
  color: #333 !important;
  padding: 16px !important;
  
  &::placeholder {
    color: #888B94;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(4, 86, 123, 0.1);
  }
}

.modal-actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 24px;
  align-items: center;
  flex-shrink: 0;

  button {
    width: 100%;
    max-width: 350px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-save {
    background: linear-gradient(268.85deg, #04567B 1.72%, #0095FF 100%);
    color: white;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #333;
    border: none;

    &:hover {
      background: #F5F5FA;
    }
  }
}
</style> 