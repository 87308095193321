<template>
  <div class="header flex justify-between align-center" :class="{'short': sideBarOpened}">
    <div class="flex header-title">
      <h1 class="text-gray" v-if="currentPage.meta?.title">{{ currentPage.meta.title }}</h1>
      <h1 class="text-gray mr-1 ml-1 header-subtitle" v-if="currentPage.meta?.subtitle"> - </h1>
      <h1 class="text-black2 header-subtitle" v-if="currentPage.meta?.subtitle">{{ currentPage.meta.subtitle }}</h1>
    </div>
    <div class="flex justify-end">
      <GlobalSearch />
      <slot name="chat-icon"></slot>
      <div class="header-notification flex justify-center align-center" @click="openNotifications">
        <div class="header-notification__unread flex justify-center align-center" v-if="unreadCount > 0"></div>
        <img src="/assets/bell.svg" alt=""/>
      </div>
      <user-info
          class="header-user"
          v-if="user.id"
          @click="toggleProfile"
          :fio="user?.firstname + ' ' + user?.lastname"
          :image="user.avatar_photo ? 'data:image/png;base64,' + user.avatar_photo : null"
          :role="user?.position"
          :is-arrow="true"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import UserInfo from "@/components/modules/UserInfo.vue";
import io from "socket.io-client";
import Echo from "laravel-echo";
import router from "@/router";
import {TicketRouterNavigationMixin} from "@/mixins/TicketRouterNavigationMixinModule";
import GlobalSearch from './GlobalSearch.vue';

export default {
  name: 'HeaderComponent',
  components: { UserInfo, GlobalSearch },
  mixins: [TicketRouterNavigationMixin],
  data() {
    return {}
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    ...mapActions({
      fetchUserNotifications: 'fetchUserNotifications',
    }),
    toggleProfile() {
      this.$emit('toggleProfile')
    },
    async openNotifications() {
      this.setLoading(true)
      await this.fetchUserNotifications()
      this.setLoading(false)
      this.$emit('toggleNotifications')
    },
  },
  computed: {
    ...mapGetters({
      isAuth: 'isAuth',
      user: 'user',
      unreadCount: 'unreadCount',
      sideBarOpened: 'sideBarOpened',
      currentPage: 'currentPage'
    }),
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.header {
  background: $white;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 32px;
  width: calc(100% - 100px);
  height: 90px;
  box-sizing: border-box;
  z-index: 4;
  transition: all .3s ease-out;

  &-title {
    h1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }


  &-user {
    padding-left: 16px;
    border-left: 1px solid #F0F1F3;
    cursor: pointer;
  }

  &-notification {
    position: relative;
    border-radius: 12px;
    border: 1px solid #E1E0E0;
    width: 48px;
    height: 48px;
    margin: 0 16px;
    cursor: pointer;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }

    &__unread {
      border-radius: 50%;
      background: $red;
      left: 32px;
      top: -4px;
      width: 14px;
      height: 14px;
      position: absolute;
      flex-shrink: 0;
    }
  }

  &-search {
    position: relative;

    input {
      width: 342px;
      height: 43px;
      border-radius: 25px;
      border: 2px solid $gray6;
      color: $black2;
      background: $white;
      padding: 12px 20px 12px 40px;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.07px;
      box-sizing: border-box;
      outline-color: $gray8;

      &::placeholder {
        color: $gray7;
      }

      &:focus, &:focus-visible {
        border: 2px solid $gray8;
      }
    }

    img {
      position: absolute;
      left: 12px;
      top: 9px;
      width: 24px;
      height: 24px;
    }
  }
}

.header.short {
  width: calc(100% - 350px);
}

.text-red {
  color: red !important;
}
.user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #E0E2E7;
  margin-right: 12px;
  overflow: hidden;

  img {
    width: 24px;
    height: 24px;
  }
}

.user-data {
  font-size: 14px;
  color: $black;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}

@media only screen and (max-width: 1600px) {
  .header-subtitle {
    display: none;
  }

  .header-search {
    input {
      width: 250px;
    }
  }
}
</style>
