export function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function maskPhone(phone) {
  if (phone[0] === '+') {
    return '+7 (' + phone.slice(2, 5) + ')-' + phone.slice(5, 8) + '-' + phone.slice(8, 10) + '-' + phone.slice(10, 12)
  }
  else return '+7 (' + phone.slice(1, 4) + ')-' + phone.slice(4, 7) + '-' + phone.slice(7, 9) + '-' + phone.slice(9, 11)
}

export function getFileFormat(file) {
  let arr = file.name.split('.')
  return arr[arr.length - 1]
}


export function formatDate(date, divider, isTime) {
  let minutes = date.getMinutes()
  let hours = date.getHours()
  let day = date.getDate()
  let month = date.getMonth() + 1
  const year = date.getFullYear()

  if (minutes < 10) minutes = '0' + minutes
  if (hours < 10) hours = '0' + hours
  if (day < 10) day = '0' + day
  if (month < 10) month = '0' + month

  let result = `${day}${divider}${month}${divider}${year}`
  if (isTime) result += ` ${hours}:${minutes}`
  return result
}

export function formatDateReverse(date, divider, isTime) {
  let minutes = date.getMinutes()
  let hours = date.getHours()
  let day = date.getDate()
  let month = date.getMonth() + 1
  const year = date.getFullYear()

  if (minutes < 10) minutes = '0' + minutes
  if (hours < 10) hours = '0' + hours
  if (day < 10) day = '0' + day
  if (month < 10) month = '0' + month

  let result = `${year}${divider}${month}${divider}${day}`
  if (isTime) result += ` ${hours}:${minutes}`
  return result
}

export function parseAndFormat(dateStr, divider) {
  const [datePart] = dateStr.split(' ');
  const [day, month, year] = datePart.split('.');

  const isoDate = `${year}-${month}-${day}`;
  const date = new Date(isoDate);

  if (isNaN(date)) return 'Invalid Date';

  const formattedYear = date.getFullYear();
  const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
  const formattedDay = String(date.getDate()).padStart(2, '0');

  return `${formattedYear}${divider}${formattedMonth}${divider}${formattedDay}`;
}


export function findById(array, id) {
  let result
  array.forEach(item => {
    if (item.id === id) result = item
  })
  return result
}

export function getIndexById(array, id) {
  const result = array.findIndex((item) => item.id === id)
  return result
}
