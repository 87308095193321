import { api } from "@/service/axios";
export const instructionsModule = {
    state: () => ({
        wikiList: null,
        wikiBlockTypes: null,
        wikiPage: null,
    }),
    getters: {
        wikiList(state){
            return state.wikiList
        },
        wikiBlockTypes(state){
            return state.wikiBlockTypes
        },
        wikiPage(state){
            return state.wikiPage
        }
    },
    mutations: {
        setWikiList(state, payload) {
            state.wikiList = payload
        },
        setWikiBlockTypes(state, payload) {
            state.wikiBlockTypes = payload
        },
        setWikiPage(state, payload) {
            state.wikiPage = payload
        },
        filterWikiList(state, payload){
            state.wikiList = state.wikiList.filter(wiki => wiki.id !== payload.id)
        }
    },
    actions: {
        async fetchWikiPages({commit}, payload){
            const res = await api.get('wiki/wiki-pages')
            commit('setWikiList', res.data)
            return res
        },
        async fetchWikiBlockTypes({commit}, payload){
            const res = await api.get('dict/get-wiki-block-types')
            commit ('setWikiBlockTypes', res.data)
        },
        async fetchWikiPage({commit}, payload){
            const res = await api.get(`wiki/get-wiki-page/${payload}`)
            commit('setWikiPage', res.data)
            return res
        },
        async createWikiPage({commit}, payload){
            const res = await api.put(`wiki/store-wiki-page`, payload)
            return res
        },
        async deleteWikiPage({commit}, payload) {
            const res = await api.delete(`wiki/delete-wiki-page`, { params: payload})
            return res
        },
        async createWikiBlock({commit}, formData){
            const res = await api.post('wiki/store-wiki-blocks?_method=PUT', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        },
        async deleteWikiBlock({commit}, payload) {
            const res = await api.delete('wiki/delete-wiki-block', {params: payload})
            return res
        },
        async editWikiPage({commit}, payload){
            const res = await api.patch('wiki/update-wiki-page', payload)
            return res
        },
        async editWikiBlocks({commit}, formData){
            const res = await api.post('wiki/update-wiki-blocks?_method=PATCH', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        },
        async deleteWikiBlockMedia({commit}, payload) {
            const res = await api.delete(`wiki/delete-wiki-block-media`, {params: payload})
            return res
        }
    }
}