<template>
  <VueSelect
      :options="options"
      v-model="model"
      :placeholder="placeholder"
      :clearable="clearable"
      :searchable="searchable"
      :class="{
        'app-select': true,
        'vs--selected': model,
      }"
      @option:selected="onChange"
      :label="labelKey"
  >
    <template v-slot:option="option">
      <div :class="option.blocked > 0 ? 'text-red': 'text-black'">
        {{option[labelKey]}}
      </div>
    </template>
    <template #no-options>
      Ничего не найдено
    </template>
  </VueSelect>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VueSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
    },
    labelKey: {
      default: 'label',
    }
  },
  emits: ['change', 'input'],
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    onChange(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.app-select {
  &.vs--open {
    .vs__dropdown-toggle {
      border-radius: 30px 30px 0 0;
      height: 50px;
    }
  }

  .vs__dropdown-toggle {
    border: 1px solid #8B88AB;
    border-radius: 100px;
    padding: 10px 15px;
  }

  &.vs--selected {
    .vs__search {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  .vs__dropdown-menu {
    border: 1px solid #8B88AB;
    border-top: none;
    border-radius: 0 0 30px 30px;
    box-shadow: none;
    padding: 0;

    .vs__dropdown-option--highlight {
      background: transparent;
      font-weight: bold;
    }
  }
}

.text-red {
  color: red;
}

.text-black {
  color: black;
}
</style>
