<template>
  <div class="wrapper modified">
    <div class="content w-100 flex column">
      <div class="mb-4">
        <filter-bar
          :is-create="true"
          :is-search="true"
          :is-sort="false"
          :is-filter="true"
          :is-date="true"
          :search="search"
          :filters="filters"
          :date-range="dateRange"
          @set-date-range="(data) => {
            dateRange = data;
                dateFrom = data?.[0];
                dateTill = data?.[1];
              }"
          @on-create="createNotification"
          @on-search="searchNotifications"
          @on-filter="filterNotifications"
        />
      </div>

      <custom-table
        :columns="tableColumns"
        :data="notificationList"
        :total-pages="totalPages"
        :current-page="currentPage"
        :is-pagination="true"
        @page-change="fetchFilteredNotifications"
      />
    </div>


  </div>

</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import {formatDate} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import FilterBar from "@/components/modules/FilterBar.vue";

import {NotificationColumns} from "@/modules/table-columns";
import router from "@/router";


export default {
  name: 'NotificationView',
  components: {
    CustomTable,
    FilterBar
    },
  data() {
    return {
      currentPage: 1,
      selectedApp: null,
      dateFrom: null,
      dateTill: null,
      search: '',
      searchTimeout: null,
      dateRange: null,
      appOptions: [
        {
          title: 'Все',
          id: 'all',
          key: 'all'
        },
        {
          title: 'Meganet',
          id: 'meganet',
          key: 'meganet'
        },
        {
          title: 'NLS',
          id: 'nls',
          key: 'meganet'
        },
      ],
    }
  },
  async created() {
    this.setLoading(true)
    await this.fetchFilteredNotifications()
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      notificationList: 'notificationList',
      totalPages: 'notificationTotalPages'
    }),
    tableColumns() {
      return NotificationColumns
    },
    filters() {
      return [
        {
          title: 'Приложения',
          array: this.appOptions,
          value: this.selectedApp,
          key: 'selectedApp',
          option_title: 'title'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchNotificationList: 'fetchNotificationList',
      createNotification: 'createNotification'
    }),
    ...mapMutations({
      setChosenTicket: 'setChosenTicket',
      setLoading: 'setLoading'
    }),
    filterNotifications(filters) {
      this.selectedApp = filters.selectedApp
      this.fetchFilteredNotifications()
    },
    createNotification() {
      router.push('/push-notifications-form')
    },
    searchNotifications(text) {
      this.search = text
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredNotifications()
      }, 1500)
    },
    formatDate(date) {
      let day = date.getDate()
      let month = date.getMonth() + 1
      const year = date.getFullYear()
      if (day < 10 && month < 10) return `0${day}-0${month}-${year}`
      else if (month < 10) return `${day}-0${month}-${year}`
      else if (day < 10) return `0${day}-${month}-${year}`
      return `${day}-${month}-${year}`
    },
    async fetchFilteredNotifications(page = 1) {
      this.loading = true
      let filters = {}
      filters.page = page
      this.currentPage = page
      if (this.selectedApp) filters.app_name = this.selectedApp
      if (this.dateFrom) filters.date_from = this.formatDate(this.dateFrom)
      if (this.dateTill) filters.date_to = this.formatDate(this.dateTill)
      if (this.search) filters.author_name = this.search
      await this.fetchNotificationList(filters)
      this.loading = false
      console.log(this.notificationList)
    },
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.content {
  border-radius: 15px;
  background: $white;
  padding: 40px;
}

</style>
