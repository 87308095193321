import axios from "axios";

const rocketChatBaseUrl = process.env.VUE_APP_ROCKET_CHAT_URL;
const rocketChatProvider = axios.create({
	baseURL: `${rocketChatBaseUrl}/api/`,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json"
	}
});

rocketChatProvider.interceptors.request.use(config => {
	const rocketChatCred = JSON.parse(localStorage.getItem('rocketChatCred'))

	if (rocketChatCred?.authToken && rocketChatCred?.userId) {
		config.headers['X-Auth-Token'] = rocketChatCred.authToken
		config.headers['X-User-Id'] = rocketChatCred.userId
	}

	return config
})

export { rocketChatProvider, rocketChatBaseUrl };
