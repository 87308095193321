<template>
  <div class="wrapper modified flex column">
    <div class="">
      <div class="input-container input-container__form mb-2">
        <p class="mb-1 text-20">Выберите дату</p>
        <input class="input input-form" type="date" v-model="date" @change="changeDate"/>
      </div>
    </div>
    <div v-if="loadChartReports.length > 0">
      <LoadChartLine :chart-options="chartOptions" :chart-data="formatLabels()" :key="loadChartReports"></LoadChartLine>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import LoadChartLine from "@/components/reports/LoadChartLine.vue";

export default {
  name: "LoadChartReportView",
  components: {LoadChartLine},
  created() {
    this.getTodayDate()
    this.fetchLoadChartReports({date: this.date})
  },
  data() {
    return {
      date: '',
      time: [],
      chartValue: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Время",
            },
          },
          y: {
            title: {
              display: true,
              text: "Нагрузка",
            },
            beginAtZero: true,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      loadChartReports: 'loadChartReports'
    }),
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchLoadChartReports: 'fetchLoadChartReports'
    }),
    formatLabels() {
      if (this.loadChartReports && this.loadChartReports.length > 0) {
        const transformedArray = [];
        for (const item of this.loadChartReports) {
          transformedArray.push({
            from: item["from"],
            to: item['to'],
            jur: item["jur"],
            fiz: item["fiz"],
            total: item["total"],
          });
        }

        const dataset = {
          labels:  transformedArray.map(function (date) {
            const time = date.from.substring(11, 16);
            return time;
          }),
          datasets: [
            {
              label: 'Физические лица',
              data: transformedArray.map(function (item) {
                return item.fiz;
              }),
              borderColor: "rgba(75,192,192,1)",
              barWidth: 13
            },
            {
              label: 'Юридические лица',
              data: transformedArray.map(function (item) {
                return item.jur;
              }),
              borderColor: "rgba(153,102,255,1)",
              barWidth: 13
            },
            {
              label: 'Общий',
              data: transformedArray.map(function (item) {
                return item.total;
              }),
              borderColor: "rgba(255,159,64,1)",
              barWidth: 13
            },
          ],
        };
        return dataset;
      }
    },
    getTodayDate() {
      const today = new Date();
      const formattedDate = today.getFullYear() + '-'
          + String(today.getMonth() + 1).padStart(2, '0') + '-'
          + String(today.getDate()).padStart(2, '0');
      this.date = formattedDate;
    },
    async changeDate() {
      this.setLoading(true)
      await this.fetchLoadChartReports({date: this.date});
      this.setLoading(false)
    },
  },
}
</script>

<style scoped lang="scss">

</style>