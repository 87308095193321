<template>
  <div class="choose-modal">
    <div v-if="label" class="dropdown-label">{{ label }}</div>
    <div class="dropdown w-100">
      <input
        type="text"
        class="input-form select mt-1"
        :value="selectedText"
        @click="toggleDropdown"
        :placeholder="placeholder"
        readonly
      />
      <div class="dropdown-controls">
        <img 
          v-if="!hasSelected"
          src="/assets/dropdown-toggle.svg" 
          class="dropdown-toggle"
          :class="{ open: isOpen }"
          alt="toggle"
          @click.stop="toggleDropdown"
        />
        <img 
          v-else
          src="/assets/clear-input.svg" 
          class="clear-icon"
          alt="clear"
          @click.stop="clearSelection"
        />
      </div>
      <div 
        class="dropdown-content scrollable" 
        :class="{ show: isOpen }" 
        v-show="isOpen"
      >
        <div class="dropdown-items">
          <div
            v-for="item in filteredItems"
            :key="item[keyProp]"
            class="dropdown-item checkbox-item"
            @click.stop="toggleItem(item)"
          >
            <input 
              type="checkbox" 
              :checked="isSelected(item[keyProp])"
              @click.stop
            >
            <span>{{ item[titleProp] }}</span>
          </div>
        </div>
        <div class="dropdown-actions">
          <button class="action-button apply" @click="applySelection">Применить</button>
          <button class="action-button reset" @click="clearSelection">Сбросить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMultiDropdown',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Выберите значения'
    },
    items: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: Array,
      default: () => []
    },
    keyProp: {
      type: String,
      default: 'id'
    },
    titleProp: {
      type: String,
      default: 'title'
    }
  },
  emits: ['update:selectedValue', 'change'],
  data() {
    return {
      isOpen: false,
      tempSelected: [],
      searchQuery: ''
    }
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) return this.items
      const query = this.searchQuery.toLowerCase()
      return this.items.filter(item => 
        item[this.titleProp].toLowerCase().includes(query)
      )
    },
    selectedText() {
      if (!Array.isArray(this.selectedValue) || !this.selectedValue.length) return ''
      const selected = this.items.filter(item => this.selectedValue.includes(item[this.keyProp]))
      return selected.map(item => item[this.titleProp]).join(', ')
    },
    hasSelected() {
      return Array.isArray(this.selectedValue) && this.selectedValue.length > 0
    },
    isAllSelected() {
      return this.items.length > 0 && this.tempSelected.length === this.items.length;
    }
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(newVal) {
        this.tempSelected = Array.isArray(newVal) ? [...newVal] : []
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.tempSelected = Array.isArray(this.selectedValue) ? [...this.selectedValue] : []
      }
    },
    toggleItem(item) {
      if (item[this.titleProp] === 'Все') {
        if (this.tempSelected.length === this.items.length) {
          this.tempSelected = [];
        } else {
          this.tempSelected = this.items.map(item => item[this.keyProp]);
        }
        return;
      }

      const index = this.tempSelected.indexOf(item[this.keyProp])
      if (index === -1) {
        this.tempSelected.push(item[this.keyProp])
      } else {
        this.tempSelected.splice(index, 1)
      }
    },
    isSelected(value) {
      return this.tempSelected.includes(value)
    },
    applySelection() {
      this.$emit('update:selectedValue', this.tempSelected)
      this.$emit('change', this.tempSelected)
      this.isOpen = false
    },
    clearSelection() {
      this.tempSelected = []
      this.$emit('update:selectedValue', [])
      this.$emit('change', null)
      this.isOpen = false
    },
    handleClickOutside(event) {
      const dropdown = this.$el
      if (!dropdown.contains(event.target)) {
        this.isOpen = false
        this.tempSelected = Array.isArray(this.selectedValue) ? [...this.selectedValue] : []
      }
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.tempSelected = [];
      } else {
        this.tempSelected = this.items.map(item => item[this.keyProp]);
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  width: 100%;

  .input-form {
    background: #F2F1F0 !important;
    border: none !important;
    border-radius: 12px !important;
    height: 44px !important;
    width: 100%;
    padding: 0 56px 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    &::placeholder {
      color: #757575;
    }
  }

  .dropdown-controls {
    position: absolute;
    right: 12px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .clear-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 1;
    }
  }

  .dropdown-toggle {
    transition: transform 0.2s ease;
    width: 12px;
    height: 12px;
    opacity: 0.8;
    cursor: pointer;
    
    &.open {
      transform: rotate(180deg);
    }
  }
}

.dropdown-label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #F2F1F0;
  border-radius: 12px;
  margin-top: 4px;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
  
  &.scrollable {
    .dropdown-items {
      max-height: 240px;
      overflow-y: auto;
      
      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        
        &:hover {
          background: #a1a1a1;
        }
      }
    }
  }
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    background-color: darken(#F2F1F0, 5%);
  }

  &.checkbox-item {
    display: flex;
    align-items: center;
    gap: 8px;

    input[type="checkbox"] {
      cursor: pointer;
    }
  }
}

.dropdown-actions {
  display: flex;
  gap: 8px;
  padding: 12px;
  border-top: 1px solid #E5E7EB;
}

.action-button {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &.apply {
    background: #2ab161;
    color: white;

    &:hover {
      background: darken(#2ab161, 5%);
    }
  }

  &.reset {
    background: #F5F5FA;
    color: #1a1a1a;

    &:hover {
      background: darken(#F5F5FA, 5%);
    }
  }
}
</style> 