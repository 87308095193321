import { debounce } from "lodash";
import { parse, compareAsc } from 'date-fns';

export default {
  data() {
    return {
      perPage: 100,
      page: 1,
      currentPage: 1,
      search: '',
      perPageOptions: [10, 25, 50, 100, 500],
      date_from: null,
      date_to: null,
      currentTab: 'all',
      menuMainArray: [],
      responsible_id: null,
      brigade_id: null,
      connection_department: null,
      showDropdown: false,
      showDropdownBrigade: false,
      filteredOptionsManagers: [],
      filteredOptionsBrigades: [],
      searchManager: '',
      searchBrigade: '',
      status_ids: [],
      area_id: null,
      category_id: null,
      district_id: null,
      isFirstLoadDone: false,
      isSorted: false,
      sortedValue: null,
      isButtonCollapsed: false,
      dateRange: [],
      isTicketDetailModal: false,
      isServiceTicketLogsModal: false,
      isServiceTicketCommentsModal: false,
      isBrigadeAssignModal: false,
      isPlannedDateModal: false,
      currentId: null,
      currentTicketId: null,
      currentDepartmentId: null,
      loadingRequestCount: 0,
      loading: false,
      params: {
        tickets_per_page: 100,
        page: 1,
        responsible: {
          type: null,
          id: null,
        },
        search: null,
        status_ids: [],
        column: null,
        date_from: null,
        date_to: null,
        responsible_department_id: null,
        brigade_id: null,
        area_id: null,
        category_id: null,
        district_id: null,
        ticket_kind_id: 2,
        on_departure: 1,
      }
    }
  },
  created() {
    this.setLoading(true)
    const today = new Date()
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)

    // Дата для фильтра
    this.date_from = this.formatDateForFilter(firstDay)
    this.date_to = this.formatDateForFilter(lastDay)
    this.dateRange = [
      this.formatDateForCalendar(firstDay),
      this.formatDateForCalendar(lastDay)
    ]

    // Проверка на наличие сохраненных фильтров
    const savedFilters = localStorage.getItem(this.storageKey)

    // Данные из компонента
    this.params.client_type = this.clientType
    this.params.ticket_kind_id = 2
    this.params.on_departure = 1

    this.initializeMenu()

    // Первый запрос всех данных
    let promises = [
      this.fetchDepartureManagers(),
      this.fetchCategories(2),
      this.fetchDistricts(),
      this.fetchStatuses({ is_departure: 1 }),
      this.fetchServiceDepartments(),
      this.fetchDepartureServiceTicketResponsibles(),
      this.fetchAllResponsibles(),
      this.getAreas({ per_page: 1000 }),
      this.fetchBrigadeByDepartment(),
      this.fetchFinishedServiceTickets()
    ]

    Promise.all(promises).then(() => {
      if (savedFilters) {
        this.setFirstFilterValues()
      } else {
        this.params.date_from = this.date_from
        this.params.date_to = this.date_to
        this.params.date_column = 'created_at'
      }

      this.setLoading(false)
      this.isFirstLoadDone = true
    })
  },
  computed: {
    totalPages() {
      return Math.ceil((this.serviceTickets?.meta?.total || 0) / this.perPage);
    },
    showData() {
      if (this.date_from && this.date_to) {
        return `${this.date_from} - ${this.date_to}`;
      } else {
        return 'за Все время'
      }
    },
    storageKey() {
      return `filtersDepartureService${this.clientType.toUpperCase()}`;
    },
    sortedTickets() {
      if (this.sortedValue) {
        return this.sortCalc(this.sortedValue);
      } else {
        return this.serviceTickets?.tickets || [];
      }
    }
  },
  watch: {
    params: {
      handler: debounce(function () {
        if (this.isFirstLoadDone) {
          this.watcherFunction();
        }
      }, 1000),
      deep: true,
    },
    search(value) {
      this.params.search = value;
    },
    area_id(value) {
      this.params.area_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    category_id(value) {
      this.params.category_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    district_id(value) {
      this.params.district_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    status_ids(value) {
      this.params.status_ids = value;
      this.params.page = 1;
      this.page = 1;
    },
    responsible_id(value) {
      if (value) {
        this.params.responsible.type = 'manager';
        this.params.responsible.id = value;
      } else {
        this.params.responsible.type = null;
        this.params.responsible.id = null;
      }
      this.params.page = 1;
      this.page = 1;
    },
    brigade_id(value) {
      this.params.brigade_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    searchManager(value) {
      this.filteredOptionsManagers = this.allResponsibles.filter(option =>
        option.title && value && option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.responsible_id = null;
        this.params.responsible.type = null;
        this.params.responsible.id = null;
      }
    },
    allResponsibles(newOptions) {
      this.filteredOptionsManagers = newOptions;
    },
    searchBrigade(value) {
      this.filteredOptionsBrigades = this.brigades.filter(option =>
        option.title && value && option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.brigade_id = null;
        this.params.brigade_id = null;
      }
    },
    brigades(newOptions) {
      this.filteredOptionsBrigades = newOptions;
    }
  },
  methods: {
    setLoading(value) {
      if (value) {
        this.loadingRequestCount++;
        this.loading = true;
      } else {
        this.loadingRequestCount--;
        if (this.loadingRequestCount <= 0) {
          this.loadingRequestCount = 0;
          this.loading = false;
        }
      }
    },
    async initializeMenu() {
      this.menuMainArray = [
        {
          key: 'all',
          title: 'Все заявки'
        },
        {
          key: 'my',
          title: 'Мои заявки',
          value: this.user?.id,
          type: 'manager'
        },
        {
          key: 'department',
          title: 'Мой департамент',
          type: 'department'
        },
        {
          key: 'open',
          title: 'Открытые заявки'
        },
        {
          key: 'closed',
          title: 'Закрытые заявки'
        },
      ]
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.params.tickets_per_page = perPage
    },
    onPageChange(page = 1) {
      this.page = page
      this.params.page = page
    },
    changeTab(item) {
      this.currentTab = item.key
      delete this.params.is_closed
      delete this.params.is_only_open

      if (item.key === 'all') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.my_department = null
        this.params.date_column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'open') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.my_department = null
        this.params.date_column = 'created_at'
        this.params.page = 1
        this.params.is_only_open = 1
      } else if (item.key === 'closed') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.my_department = null
        this.params.date_column = 'finished_at'
        this.params.page = 1
        this.params.is_closed = 1
      } else if (item.key === 'partners') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = 1
        this.params.my_department = null
        this.params.date_column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'department') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.my_department = 1
        this.params.date_column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'my') {
        this.params.responsible.type = "manager"
        this.params.responsible.id = item.value
        this.params.is_partner = null
        this.params.my_department = null
        this.params.date_column = 'created_at'
        this.params.page = 1
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.my_department = null
        this.params.date_column = 'created_at'
        this.params.page = 1
      }
    },
    searchForTickets() {
      if (this.search === "") {
        this.search = null
      }
      this.params.page = 1
      this.page = 1
      this.params.client_name = this.search
    },
    responsibleFilter() {
      if (this.responsible_id) {
        this.params.responsible.type = 'manager'
        this.params.responsible.id = this.responsible_id
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
      }
    },
    departmentFilter(value) {
      this.connection_department = value.key;
      this.params.responsible_department_id = value.id;
      this.params.page = 1;
      this.page = 1;
    },
    openTicket(ticket) {
      this.$router.push(`/service-tickets/${ticket.id}`);
    },
    openMiddleMouse(ticket) {
      window.open(`/service-tickets/${ticket.id}`, '_blank');
    },
    openClientInfo(ticket) {
      this.setCurrentClientInfo(ticket.client)
      this.setIsDepartureClientInfoModal(true)
    },
    openLogs(id) {
      this.currentId = id
      this.isServiceTicketLogsModal = true
    },
    openComments(id) {
      this.currentId = id
      this.isServiceTicketCommentsModal = true
    },
    openBrigade(ticket) {
      if (ticket.status?.id !== 26) {
        this.currentDepartmentId = ticket.department_id
        this.currentTicketId = ticket.id
        this.isBrigadeAssignModal = true
      }
    },
    openDate(ticket) {
      this.currentTicketId = ticket.id
      this.isPlannedDateModal = true
    },
    closeDate() {
      this.isPlannedDateModal = false
      this.watcherFunction()
    },
    openTicketInfo(id) {
      this.setCurrentTicketId(id)
      this.isTicketDetailModal = true
    },
    assignBrigade(data) {
      this.setLoading(true)
      this.assignBrigadeToTicket({
        id: this.currentTicketId,
        ...data
      }).then(() => {
        this.isBrigadeAssignModal = false
        this.watcherFunction()
      }).finally(() => {
        this.setLoading(false)
      })
    },
    async downloadExcel() {
      if (!this.params.search) {
        delete this.params.search
      }
      this.setLoading(true)

      const res = await this.fetchExcelList({ ...this.params, download: 1 })
      const url = window.URL.createObjectURL(res);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `отчеты.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    async watcherFunction() {
      if (!this.params) return;

      if (!this.params.ticket_kind_id) {
        this.params.ticket_kind_id = 2;
      }

      if (!this.params.on_departure) {
        this.params.on_departure = 1;
      }

      this.setLoading(true);

      const countParams = {
        ticket_kind_id: this.params.ticket_kind_id,
        on_departure: this.params.on_departure,
        client_type: this.params.client_type,
        date_from: this.params.date_from,
        date_to: this.params.date_to,
        date_column: "finished_at",
      };

      try {
        await Promise.all([
          this.fetchDepartureTickets(this.params),
          this.fetchDepartureServiceClosedInfo(countParams)
        ]);

        this.isFirstLoadDone = true;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$toast.error('Ошибка при загрузке данных');
      } finally {
        this.setLoading(false);
      }
    },
    changeArea() {
      if (this.area_id) {
        this.params.area_id = Number(this.area_id)
        this.params.page = 1
        this.page = 1
      } else {
        this.params.area_id = null
      }
    },
    async saveFilters() {
      localStorage.setItem(this.storageKey, JSON.stringify({ ...this.params, use_saved_filters: 1, search: null }))
      this.$toast.success('Фильтры успешно cохранены')
    },
    selectOption(option) {
      this.responsible_id = option.id || option.responsible_id
      this.searchManager = option.title;
      this.showDropdown = false;
      if (this.responsible_id) {
        this.params.responsible_manager_id = Number(this.responsible_id)
        this.params.page = 1
        this.page = 1
      } else {
        this.params.responsible_manager_id = null
        this.params.responsible_brigade_id = null
      }
    },
    selectOptionBrigade(option) {
      this.brigade_id = option.id
      this.searchBrigade = option.title;
      this.showDropdownBrigade = false;
      if (this.brigade_id) {
        this.params.responsible_brigade_id = Number(this.brigade_id)
        this.params.page = 1
        this.page = 1
      } else {
        this.params.responsible_brigade_id = null
      }
    },
    async resetFilters() {
      localStorage.removeItem(this.storageKey)

      // Сброс всех значений
      this.page = 1
      this.perPage = 100
      this.responsible_id = null
      this.status_ids = []
      this.search = ''
      this.searchManager = ''
      this.connection_department = null
      this.currentTab = 'all'
      this.area_id = null
      this.brigade_id = null
      this.category_id = null
      this.district_id = null

      const currentTicketKindId = this.params.ticket_kind_id
      const currentOnDeparture = this.params.on_departure

      this.params = {
        tickets_per_page: 100,
        page: 1,
        responsible: {
          type: null,
          id: null,
        },
        search: null,
        status_ids: [],
        date_column: 'created_at',
        date_from: this.date_from,
        date_to: this.date_to,
        responsible_department_id: null,
        brigade_id: null,
        area_id: null,
        category_id: null,
        district_id: null,
        client_type: this.clientType,
        // Сохранение значений ticket_kind_id и on_departure
        ticket_kind_id: currentTicketKindId,
        on_departure: currentOnDeparture
      }

      this.$toast.success('Фильтры успешно сброшены')
    },
    setFirstFilterValues() {
      const storageFilter = localStorage.getItem(this.storageKey) ? JSON.parse(localStorage.getItem(this.storageKey)) : {};
      if (Object.keys(storageFilter).length > 0) {
        // Сохранение текущих значения ticket_kind_id и on_departure
        const currentTicketKindId = this.params.ticket_kind_id;
        const currentOnDeparture = this.params.on_departure;

        // Установка всех параметров сразу, чтобы вызвать только одно обновление watch
        this.params = {
          ...this.params,
          ...storageFilter,
          use_saved_filters: 1,
          search: null,
          ticket_kind_id: currentTicketKindId || storageFilter.ticket_kind_id,
          on_departure: currentOnDeparture || storageFilter.on_departure
        };
        delete this.params.is_closed
        delete this.params.is_only_open

        // Фильтр бригады
        if (storageFilter.brigade_id) {
          this.brigade_id = storageFilter.brigade_id;

          if (this.brigades && this.brigades.length > 0) {
            const brigade = this.brigades.find(el => el.id === this.brigade_id);
            if (brigade) {
              this.searchBrigade = brigade.title;
            }
          } else {
            this.$watch('brigades', (newBrigades) => {
              if (newBrigades && newBrigades.length > 0) {
                const brigade = newBrigades.find(el => el.id === this.brigade_id);
                if (brigade) {
                  this.searchBrigade = brigade.title;
                }
              }
            });
          }
        }

        // Фильтр статусов
        if (storageFilter.status_ids && Array.isArray(storageFilter.status_ids)) {
          this.status_ids = [...storageFilter.status_ids];
          this.params.status_ids = [...storageFilter.status_ids];
        }

        // Фильтр отдела
        if (storageFilter.responsible_department_id) {
          this.connection_department = storageFilter.responsible_department_id
        }

        // Фильтр участка
        if (storageFilter.area_id) {
          this.area_id = storageFilter.area_id
        }

        // Фильтр категории
        if (storageFilter.category_id) {
          this.category_id = storageFilter.category_id
        }

        // Фильтр района
        if (storageFilter.district_id) {
          this.district_id = storageFilter.district_id
        }

        // Фильтр количества записей на странице
        if (storageFilter.tickets_per_page) {
          this.perPage = storageFilter.tickets_per_page
        }

        // Фильтр ответственного
        if (storageFilter.responsible) {
          if (storageFilter.responsible.type === 'manager') {
            this.responsible_id = storageFilter.responsible.id || null;

            if (this.allResponsibles && this.allResponsibles.length > 0) {
              const responsibleManager = this.allResponsibles.find(el => el.id === this.responsible_id);
              if (responsibleManager) {
                this.searchManager = responsibleManager.title;
              }
            } else {
              this.$watch('allResponsibles', (newManagers) => {
                if (newManagers && newManagers.length > 0) {
                  const responsibleManager = newManagers.find(el => el.id === this.responsible_id);
                  if (responsibleManager) {
                    this.searchManager = responsibleManager.title;
                  }
                }
              });
            }
          }
        }
      }
    },
    setDate(data) {
      if (data) {
        if (this.currentTab === 'closed') {
          this.params.date_column = 'finished_at';
        } else {
          this.params.date_column = 'created_at';
        }
        const firstDay = new Date(data?.[0])
        const lastDay = new Date(data?.[1])

        // Дата для фильтра
        this.date_from = this.formatDateForFilter(firstDay)
        this.date_to = this.formatDateForFilter(lastDay)
        this.params.date_from = this.date_from
        this.params.date_to = this.date_to
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
        delete this.params.date_from
        delete this.params.date_to
        delete this.params.date_column
      }
    },
    clearBtn(value) {
      if (value === 'status') {
        this.status_ids = []
        this.params.status_ids = []
      } else if (value === 'department') {
        this.connection_department = null
        this.params.responsible_department_id = null
      } else if (value === 'category') {
        this.category_id = null
        this.params.category_id = null
      } else if (value === 'district') {
        this.district_id = null
        this.params.district_id = null
      }
    },
    onTableClick(id) {
      const previouslySelected = document.querySelector('.selected');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected');
      }
      const el = document.getElementById(id);
      if (el) {
        el.classList.add('selected');
      }
    },
    formatDateForFilter(date) {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    formatDateForCalendar(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    setCurrentTicketId(id) {
      this.$store.commit('setCurrentTicketId', id);
    },
    changeCategory() {
      if (this.category_id) {
        this.params.ticket_category_id = Number(this.category_id)
        this.params.page = 1
        this.page = 1
      } else {
        this.params.ticket_category_id = null
      }
    },
    changeDistrict() {
      if (this.district_id) {
        this.params.district_id = Number(this.district_id)
        this.params.page = 1
        this.page = 1
      } else {
        this.params.district_id = null
      }
    },
    sortedData(column) {
      if (this.sortedValue === column) {
        this.isSorted = !this.isSorted;
      } else {
        this.sortedValue = column;
        this.isSorted = true;
      }

      this.sortTickets({
        column: this.sortedValue,
        direction: this.isSorted ? 'asc' : 'desc'
      });
    },
    sortCalc(sortedValue) {
      if (!this.serviceTickets?.tickets) return []

      return [...this.serviceTickets.tickets].sort((a, b) => {
        const valueA = this.getSortValue(a, sortedValue);
        const valueB = this.getSortValue(b, sortedValue);

        return this.isSorted ? this.compareValues(valueA, valueB) : this.compareValues(valueB, valueA);
      });
    },
    getSortValue(item, key) {
      switch (key) {
        case 'id':
          return item.ticket_number;
        case 'address':
          return item.address || '';
        case 'city':
          return item.district?.title || '';
        case 'responsible':
          return item.responsible_brigade?.title || item.responsible?.title || '';
        case 'created_at':
          return item.created_at ? parse(item.created_at, 'dd.MM.yyyy HH:mm', new Date()) : null;
        case 'plan_end':
          if (item.closed_at) return parse(item.closed_at, 'dd-MM-yyyy', new Date());
          if (item.planned_at_begin) return parse(item.planned_at_begin, 'dd-MM-yyyy', new Date());
          return null;
        default:
          return '';
      }
    },
    compareValues(a, b) {
      if (a === null && b === null) return 0;
      if (a === null) return 1;
      if (b === null) return -1;

      if (a instanceof Date && b instanceof Date) {
        return compareAsc(a, b);
      }

      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      }

      return a - b;
    },
    handleSearchUpdate(newSearch) {
      this.search = newSearch;
      if (this.search === "") {
        this.params.search = null;
      } else {
        this.params.search = this.search;
      }
      this.params.page = 1;
      this.page = 1;
    },
    handleClickOutside(event) {
      const dropdownManager = document.getElementById('dropdown-manager');
      const dropdownBrigade = document.getElementById('dropdown-brigade');

      if (dropdownManager && !dropdownManager.contains(event.target)) {
        this.showDropdown = false;
      }

      if (dropdownBrigade && !dropdownBrigade.contains(event.target)) {
        this.showDropdownBrigade = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};