import {api} from '@/service/axios'

export const virtualWarehousesModule = {
    // Начальное состояние хранилища
    state: () => ({
        virtualWarehouses: [], // Список неопознанного оборудования
        virtualWarehousesTotalPages: 0, // Общее количество страниц
        totalVirtualWarehouses: 0, // Общее количество записей
        warehouses: [], // Список складов
        statuses: [], // Список статусов записи
        equipmentStatuses: [], // Список статусов оборудования
        equipmentLocations: [], // Список местоположений оборудования
    }),
    
    // Геттеры для получения данных из state
    getters: {
        virtualWarehouses: state => state.virtualWarehouses,
        virtualWarehousesTotalPages: state => state.virtualWarehousesTotalPages,
        totalVirtualWarehouses: state => state.totalVirtualWarehouses,
        warehouses: state => state.warehouses,
        statuses: state => state.statuses,
        equipmentStatuses: state => state.equipmentStatuses,
        equipmentLocations: state => state.equipmentLocations,
    },
    
    // Мутации для изменения состояния
    mutations: {
        setVirtualWarehouses(state, payload) {
            state.virtualWarehouses = payload
        },
        setVirtualWarehousesTotalPages(state, payload) {
            state.virtualWarehousesTotalPages = payload
        },
        setTotalVirtualWarehouses(state, payload) {
            state.totalVirtualWarehouses = payload
        },
        setWarehouses(state, payload) {
            state.warehouses = payload
        },
        setStatuses(state, payload) {
            state.statuses = payload
        },
        setEquipmentStatuses(state, payload) {
            state.equipmentStatuses = payload
        },
        setEquipmentLocations(state, payload) {
            state.equipmentLocations = payload
        },
    },
    
    // Действия для работы с API
    actions: {
        // Получение списка неопознанного оборудования
        async getVirtualWarehouses({commit}, payload) {
            const res = await api.get(`unidentified-equipment/list`, {
                params: payload
            })
            commit('setVirtualWarehouses', res?.data)
        },

        // Получение списка складов
        async getWarehouses({commit}, payload) {
            const res = await api.get(`dict/unidentified-equipment/storages`, {
                params: payload
            })
            commit('setWarehouses', res?.data)
        },

        // Получение списка статусов записи
        async getStatuses({commit}, payload) {
            const res = await api.get(`dict/unidentified-equipment/statuses`, {
                params: payload
            })
            commit('setStatuses', res?.data)
        },

        // Получение списка статусов оборудования
        async getEquipmentStatuses({commit}, payload) {
            const res = await api.get(`dict/unidentified-equipment/equipment-statuses`, {
                params: payload
            })
            commit('setEquipmentStatuses', res?.data)
        },

        // Получение списка местоположений оборудования
        async getEquipmentLocations({commit}, payload) {
            const res = await api.get(`dict/unidentified-equipment/equipment-locations`, {
                params: payload
            })
            commit('setEquipmentLocations', res?.data)
        },

        // Создани�� новой записи неопознанного оборудования
        async createVirtualWarehouse({commit}, payload) {
            return await api.post(`external/create-unidentified-equipment`, payload)
        },

        // Обновление данных записи (склад, статус оборудования, местоположение)
        async updateWarehousesOrStatus({commit}, payload) {
            if (!payload.device_name) delete payload.device_name
            if (!payload.serial_number) delete payload.serial_number
            return await api.put(`unidentified-equipment/update/${payload.id}`, {
                storage_key: payload.storage_key,
                equipment_status_id: payload.equipment_status_id,
                equipment_location_id: payload.equipment_location_id,
                status_key: payload.status_key,
                device_name: payload.device_name,
                serial_number: payload.serial_number,
            })
        },

        // Проверка наличия дубликатов в системе 1С
        async checkDuplicateOneC({commit}, id) {
            return await api.get(`unidentified-equipment/check-duplicate-one-c/${id}`)
        },

        async fetchBrigades({ commit }) {
            try {
                const res = await api.get('brigade/by-department')
                console.log('Brigade API Response:', res)
                if (res?.data) {
                    commit('setVirtualWarehouseBrigades', res.data)
                    console.log('Brigades after commit:', res.data)
                } else {
                    console.error('Unexpected API response structure:', res)
                }
            } catch (error) {
                console.error('Error fetching brigades:', error)
            }
        },
        // выгрузка екзел таблицы
        async downloadExelList(_, params) {
            const res = await api.get('unidentified-equipment/list', {params: params, responseType: 'blob'});
            return res
        },
    }
}
