<template>
  <div class="wrapper modified flex column">
    <div class="flex justify-between mb-4">
      <div class="flex">
        <div
            class="ticket-menu flex justify-center align-center cursor-pointer mr-4"
            v-for="menu in menuMainArray"
            :key="menu.key"
            :class="{'active': currentTab === menu.key}"
            @click="changeTab(menu)"
        >
          {{ menu.title }}
        </div>
      </div>
      <button v-if="user.role.key !== 'maintenance_head' && user.role.key !== 'maintenance_ing'" type="button"
              class="btn btn-emergency" @click="() => $router.push('/emergency-tickets')">Аварии
      </button>
    </div>
    <div class="block  mb-3">
      <div class="bg-white p-20 rounded">
        <div class="w-100 p-20 border-bottom">
          <filter-bar
              :is-create="true"
              :is-search="true"
              :is-table-options="true"
              :is-sort="false"
              :is-filter="true"
              :is-date="true"
              :is-download="currentTab === 'all' ? true : false "
              :isStatuses="true"
              :create-text="'Создать заявку'"
              :vols="user.role.key == 'maintenance_head' || user.role.key == 'maintenance_ing' ? 'ВОЛС' : ''"
              :emergency-tickets="user.role.key == 'maintenance_head' || user.role.key == 'maintenance_ing' || OTPDepartmentsIds.includes(user.department_id) ? 'Аварийные' : ''"
              :search="search"
              :filters="filters"
              :date-range="dateRange"
              :client-access="user.department_id === 108 ? 'Клиентские': ''"
              :emergency-access="user.department_id === 108 ? 'Аварийные': ''"
              :subtasks="'Подзадачи'"
              :services="user.department_id !== 108 && user.role.key !== 'maintenance_head' &&  user.role.key !== 'maintenance_ing' ? 'Сервисные' : ''"
              @set-date-range="(data) => {
                dateRange = data
                dateFrom = data?.[0];
                dateTo = data?.[1];
              }"
              @on-create="toggleCreateModal"
              @on-search="searchTickets"
              @on-filter="filterModalSubmit"
              @on-submit="submitMultyTask"
              @on-submit-enroll="isEnrollToManagerModal = !isEnrollToManagerModal"
              @onVols="onVols"
              @onEmergency="onEmergency"
              @toggleTableOptions="toggleTableOptions"
              @on-emergency-access="fetchAccessTickets(1, 'emergency')"
              @on-client-access="fetchAccessTickets(1, 'client')"
              @on-subtasks="fetchSubtasks(1)"
              @on-services="fetchFilteredTickets(1)"
          />
        </div>
        <div class="flex justify-between items-center mt-2">
          <div class="flex">
            <!-- Removed duplicate menu tabs -->
          </div>
          <div v-if="currentTab === 'all'" class="mt flex">
            <button
                class="ticket-menu flex justify-center gap-1 align-center cursor-pointer mr-2 navs"
                style="background: #2ab161; color: white; width: auto; padding: 0 20px;"
                @click="downloadExcel"
            >
              <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="" width="24" height="24">
              <div>Выгрузить</div>
            </button>
          </div>
        </div>
        <div class="w-100 mt-2">
          <custom-table
              v-if="!isEmergency && user.department_id !== 108 && !isSubtasks"
              :columns="tableColumns"
              :data="tickets"
              :total-pages="ticketsTotalPages"
              :current-page="currentPage"
              :is-pagination="true"
              :sort-params="currentColumnSortParams"
              :sort-registrator-params="currentRegistratorColumnParams"
              @get-detail="getTicketDetail"
              @page-change="fetchFilteredTickets"
              @sort-column="sortColumn"
              @move-column="moveTableColumn"
              @changed-ticket="changeTicket"
              @getMiddleDetail="getMiddleDetail"
              @sortRegistrator="sortRegistrator"
          />
          <custom-table
              v-if="isEmergency && user.department_id !== 108 && !isSubtasks"
              :columns="emergencyTableColumns"
              :data="emergencyTickets"
              :total-pages="emergencyTicketsTotalPages"
              :current-page="emergencyCurrentPage"
              :is-pagination="true"
              @get-detail="openTicket"
              @page-change="onEmergency"
              @getMiddleDetail="getMiddleDetail"
          />
          <custom-table v-if="user.department_id === 108 && !isSubtasks" :columns="accessTableColumns" :data="tickets"
                        :total-pages="ticketsTotalPages"
                        :current-page="currentPage"
                        :is-pagination="true" @page-change="fetchAccessTickets"
                        @get-detail="getTicketDetail"></custom-table>
          <custom-table
              v-if="isSubtasks"
              :columns="subtasksTableColumns"
              :data="tickets"
              :total-pages="ticketsTotalPages"
              :current-page="currentPage"
              :is-pagination="true"
              @get-detail="openTicket"
              @page-change="fetchSubtasks"
              @getMiddleDetail="getMiddleDetail"
          />
        </div>
      </div>
    </div>

    <custom-modal v-if="isCreateTicketModal" @close="toggleCreateModal">
      <choose-ticket-type @choose-ticket-kind="chooseTicketKind"/>
    </custom-modal>

    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <SuccessModal @close="toggleSuccessModal">
        Заявка на сервис успешно создана!
      </SuccessModal>
    </custom-modal>

    <custom-modal v-if="isTableOptionsModal" @close="isTableOptionsModal = !isTableOptionsModal">
      <table-options-modal @close="isTableOptionsModal = !isTableOptionsModal"
                           :tableColumns="isEmergency ? emergencyTableColumns : tableColumns"
                           @getNewTableColumns="getNewTableColumns"></table-options-modal>
    </custom-modal>

    <custom-modal v-if="isEnrollToManagerModal" @close="isEnrollToManagerModal = !isEnrollToManagerModal">
      <service-tickets-enroll-to-manager-modal @close="closeEnrollModal"></service-tickets-enroll-to-manager-modal>
    </custom-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import {formatDate} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import ChooseTicketType from "@/components/modals/tickets/ChooseTicketType.vue";
import SuccessModal from '@/components/modals/tickets/SuccessModal.vue';
import MenuFilter from "@/components/navigation/MenuFilter.vue";
import {
  AccessDataTable,
  EmergencyTicketsTableColumns,
  PlanTicketsTableColumns,
  SubtasksTableColumns
} from "@/modules/table-columns";
import {TicketsTableColumns, CabinetTicketsTableColumns} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import router from "@/router";
import TableOptionsModal from "@/components/modals/TableOptionsModal.vue";
import {api} from "@/service/axios";
import {TicketRouterNavigationMixin} from "@/mixins/TicketRouterNavigationMixinModule";
import ServiceTicketsEnrollToManagerModal from "@/components/modals/tickets/ServiceTicketsEnrollToManagerModal.vue";


export default {
  name: 'TicketsView',
  components: {
    ServiceTicketsEnrollToManagerModal,
    TableOptionsModal,
    FilterBar,
    CustomTable,
    ChooseTicketType,
    SuccessModal,
    CustomModal,
  },
  mixins: [TicketRouterNavigationMixin],
  data() {
    return {
      selectedStatuses: [],
      tableColumns: [],
      accessTableColumns: [],
      currentTab: 'department',
      statusesValue: '',
      isTicketFormModal: false,
      isTicketFeedBackModal: false,
      isSuccessModal: false,
      isCreateTicketModal: false,
      author: null,
      responsible: null,
      emergencyCurrentPage: 1,
      district: null,
      ticketKind: 2,
      serviceType: null,
      dateFrom: null,
      dateRange: null,
      dateTo: null,
      search: '',
      searchTimeout: null,
      ticketIdForFeedBack: 0,
      currentPage: 1,
      isOnlyOpen: true,
      isLC: false,
      isEmergency: false,
      perPage: 8,
      emergencyTableColumns: [],
      related_type: '',
      status_ids: [],
      menuMainArray: [
        {
          key: 'department',
          title: 'Открытые  заявки'
        },
        {
          key: 'my',
          title: 'Назначены мне'
        },
        {
          key: 'author',
          title: 'Поручил'
        },
        {
          key: 'all',
          title: 'Все заявки'
        },
        {
          key: 'closed',
          title: 'Закрытые заявки'
        }
      ],
      menuSecondaryArray: [],
      currentColumnSortParams: {
        value: '',
        order: ''
      },
      isTableOptionsModal: false,
      subtasksTableColumns: [],
      isSubtasks: false,
      isAscending: false,
      currentRegistratorColumnParams: {
        order: ''
      },
      category_id: null,
      isEnrollToManagerModal: false,
    }
  },
  async created() {
    if (window.screen.width < 1025) {
      this.perPage = 5
    }
    if (window.localStorage.getItem('TicketsTableColumns')) {
      if (TicketsTableColumns.length !== JSON.parse(window.localStorage.getItem('TicketsTableColumns')).length) {
        window.localStorage.setItem('TicketsTableColumns', JSON.stringify(TicketsTableColumns))
        this.tableColumns = TicketsTableColumns
      } else {
        this.tableColumns = JSON.parse(window.localStorage.getItem('TicketsTableColumns'))
      }
    } else this.tableColumns = TicketsTableColumns

    if (window.localStorage.getItem('EmergencyTicketsTableColumns')) this.emergencyTableColumns = JSON.parse(window.localStorage.getItem('EmergencyTicketsTableColumns'))
    else this.emergencyTableColumns = EmergencyTicketsTableColumns

    if (window.localStorage.getItem('SubtasksTableColumns')) this.subtasksTableColumns = JSON.parse(window.localStorage.getItem('SubtasksTableColumns'))
    else this.subtasksTableColumns = SubtasksTableColumns

    // Дефолтные даты
    const today = new Date();
    today.setMonth(today.getMonth() - 3);
    this.dateRange = [today, new Date()];
    this.dateFrom = today;
    this.dateTo = this.dateRange[1];

    this.setLoading(true)
    if (!this.ticketKinds || !this.ticketKinds.length) await this.fetchTicketKinds()
    let promises = [this.fetchTicketCountList()]
    if (this.user.department_id === 108) {
      promises.push(this.fetchAccessTickets(1))
      this.accessTableColumns = AccessDataTable
    } else {
      promises.push(this.fetchFilteredTickets())
    }
    if (!this.statuses || !this.statuses.length) promises.push(this.fetchStatuses())

    if (!this.managers || !this.managers.length) promises.push(this.fetchManagers())
    if (!this.districts || !this.districts.length) promises.push(this.fetchDistricts())
    if (!this.ticketsCategories || !this.ticketsCategories.length) promises.push(this.fetchTicketsCategories({ticket_kind_id: this.ticketKind}))
    if (!this.OTPDepartments || !this.OTPDepartments.length) promises.push(this.fetchOTPDepartments())
    await Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      tickets: 'tickets',
      user: 'user',
      ticketsTotalPages: 'ticketsTotalPages',
      totalTickets: 'totalTickets',
      ticketKinds: 'ticketKinds',
      serviceTypes: 'serviceTypes',
      statuses: 'statuses',
      managers: 'managers',
      chosenTicket: 'chosenTicket',
      ticketCounts: 'ticketCounts',
      getChangedTicketIds: 'getChangedTicketIds',
      districts: 'districts',
      emergencyTickets: 'emergencyTickets',
      emergencyTicketsTotalPages: 'emergencyTicketsTotalPages',
      emergencyTotalTickets: 'emergencyTotalTickets',
      ticketsCategories: 'ticketsCategories',
      OTPDepartments: 'OTPDepartments',
    }),
    filters() {
      return [
        {
          title: 'Инициатор',
          array: this.managers,
          value: this.author,
          key: 'author',
          option_title: 'full_name'
        },
        {
          title: 'Исполнитель',
          array: this.managers,
          value: this.responsible,
          key: 'responsible',
          option_title: 'full_name'
        },
        {
          title: 'Район',
          array: this.districts,
          value: this.district,
          key: 'district',
          option_title: 'title'
        },
        {
          title: 'Категория',
          array: this.ticketsCategories,
          value: this.ticketsCategories,
          key: 'category',
          option_title: 'title'
        },
      ]
    },
    OTPDepartmentsIds() {
      return this.OTPDepartments.map(item => item.id)
    },
    getTicketKindIds() {
      if(!this.ticketKinds.length) {
        return []
      }
      const keys = ['service', 'consultation']
      return this.ticketKinds.filter(item => keys.includes(item.key)).map(item => item.id);
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: 'fetchCategories',
      fetchStatuses: 'fetchStatuses',
      fetchTickets: 'fetchTickets',
      fetchTicketKinds: 'fetchTicketKinds',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchTicketDetail: 'ticketDetail',
      createServiceTicket: 'createServiceTicket',
      createEmergencyTicket: 'createEmergencyTicket',
      createPlanTicket: 'createPlanTicket',
      fetchManagers: 'fetchManagers',
      fetchTicketCountList: 'fetchTicketCountList',
      createTaskOnTicketIds: 'createTaskOnTicketIds',
      enrollTicketsToManager: 'enrollTicketsToManager',
      fetchDistricts: 'fetchDistricts',
      fetchEmergencyTickets: 'fetchEmergencyTickets',
      downloadServiceTickets: 'downloadServiceTickets',
      fetchTicketsCategories: 'fetchTicketsCategories',
      downloadExcelTickets: 'downloadExcelTickets',
      fetchOTPDepartments: 'fetchOTPDepartments',
    }),
    ...mapMutations({
      setChosenTicket: 'setChosenTicket',
      setLoading: 'setLoading',
      setChangedTicketIds: 'setChangedTicketIds'
    }),
    // onStatusChange(option) {
    //   if (this.selectedStatuses.indexOf(option) > -1) {
    //     this.selectedStatuses.splice(this.selectedStatuses.indexOf(option), 1)
    //   }
    //   else this.selectedStatuses.push(option)
    //   this.fetchFilteredTickets()
    // },
    async submitMultyTask() {
      this.setLoading(true)
      const ticket_ids = this.getChangedTicketIds.map(item => item.id)
      try {
        const res = await this.createTaskOnTicketIds({tickets: ticket_ids})
        if (res.status) {
          this.$toast.open({message: res.message, type: 'success'})
          this.setChangedTicketIds([])
          await this.fetchFilteredTickets(this.currentPage)
        } else {
          this.$toast.open({message: res.message, type: 'error'})
        }
      } catch (e) {
        console.error(e)
        this.$toast.open({message: 'Произошла ошибка', type: 'error'})
      } finally {
        this.setLoading(false)
      }
    },
    getTitleByKey(array, key) {
      if (array.find(item => item.key === key)) return array.find(item => item.key === key).count
    },
    changeTicket(ticket) {
      if (Array.isArray(ticket)) {
        if (ticket.every(subItem => this.getChangedTicketIds.some(subTicket => subTicket.id === subItem.id))) {
          this.setChangedTicketIds([])
        } else {
          this.setChangedTicketIds(ticket)
        }
      } else {
        const ticketArray = this.getChangedTicketIds
        if (this.getChangedTicketIds.some(item => item.id === ticket.id)) {
          const filteredArray = ticketArray.filter(item => item.id !== ticket.id);
          this.setChangedTicketIds(filteredArray);
        } else {
          ticketArray.push(ticket)
          this.setChangedTicketIds(ticketArray)
        }
      }
    },
    async chooseTicketKind(id) {
      this.toggleCreateModal()
      if (id === 2) {
        await router.push('/service-ticket-form')
      } else if (id === 8) {
        await router.push('/plan-ticket-form')
      } else if (id === 10) {
        await router.push('/consultation-ticket-form')
      } else if (id === 12) {
        await router.push('/internal-ticket-form')
      } else if (id === 16) {
        await router.push('/access-form-view')
      } else await router.push('/emergency-ticket-form')
    },
    toggleCreateModal() {
      this.isCreateTicketModal = !this.isCreateTicketModal
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
    },
    resetFilters(preserveDates = false) {
      this.selectedStatuses = []
      if (!preserveDates) {
        this.dateRange = null
        this.dateFrom = null
        this.dateTo = null
      }
      this.author = null
      this.responsible = null
      this.serviceType = null
      this.district = null
      this.currentColumnSortParams = {
        value: '',
        order: ''
      }
      this.search = ''
    },
    changeTab(item) {
      let renderSubtasks1 = this.isSubtasks && this.user.department_id === 108
      let renderSubtasks2 = this.isSubtasks && this.isEmergency
      let renderSubtasks3 = this.isSubtasks && !this.isEmergency && this.user.department_id !== 108
      let renderAccessTickets = this.user.department_id === 108 && !this.isSubtasks && !this.isEmergency
      let c = this.isEmergency && !this.isSubtasks && this.user.department_id !== 108
      this.resetFilters(true)
      this.currentTab = item.key
      if (c) {
        this.onEmergency()
      } else if (renderAccessTickets) {
        this.fetchAccessTickets(1, this.related_type)
      } else if (renderSubtasks1) {
        this.fetchSubtasks(1)
      } else if (renderSubtasks2) {
        this.fetchSubtasks(1)
      } else if (renderSubtasks3) {
        this.fetchSubtasks(1)
      } else {
        this.fetchFilteredTickets()
      }
    },
    async getTicketDetail(ticket) {
      this.setLoading(true)
      this.ticketRouterNavigationFunc(ticket)
      this.setLoading(false)
    },
    async getMiddleDetail(ticket) {
      this.setLoading(true)
      this.ticketRouterNavigationFunc(ticket, true)
      this.setLoading(false)
    },
    moveTableColumn(columns) {
      this.tableColumns = columns
      window.localStorage.setItem('TicketsTableColumns', JSON.stringify(columns))
    },
    sortColumn(column) {
      if (this.currentColumnSortParams.value === column) {
        if (this.currentColumnSortParams.order === 'asc') this.currentColumnSortParams.order = 'desc'
        else if (this.currentColumnSortParams.order === 'desc') {
          this.currentColumnSortParams.order = ''
          this.currentColumnSortParams.value = ''
        }
      } else {
        this.currentColumnSortParams.value = column
        this.currentColumnSortParams.order = 'asc'
      }
      this.fetchFilteredTickets()
    },
    searchTickets(searchText) {
      this.search = searchText
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredTickets()
      }, 1500)
    },
    filterModalSubmit(filters, statuses) {
      this.author = filters.author
      this.responsible = filters.responsible
      this.serviceType = filters.serviceType
      this.district = filters.district
      this.category_id = filters.category
      if (Array.isArray(statuses) && statuses.length > 0) {
        this.status_ids = statuses
      } else {
        this.status_ids = []
      }

      if (this.isSubtasks) {
        this.fetchSubtasks()
      } else {
        this.fetchFilteredTickets()
      }
    },
    async fetchFilteredTickets(page = 1) {
      this.setLoading(true)
      this.isSubtasks = false
      this.isEmergency = false
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      filters.is_not_finished = this.isOnlyOpen ? '1' : '0'
      filters.is_lc = this.isLC ? '1' : '0'
      this.currentPage = page

      if (this.dateRange && Array.isArray(this.dateRange)) {
        if (this.dateRange[0]) filters.date_from = formatDate(this.dateRange[0], '-')
        if (this.dateRange[1]) filters.date_to = formatDate(this.dateRange[1], '-')
      }

      this.selectedStatuses.forEach((item, index) => {
        filters[`status_id[${index}]`] = item.id
      })
      if (this.author) filters.manager_id = this.author
      if (this.responsible) filters.responsible_manager_id = this.responsible
      if (this.serviceType) filters.service_type_id = this.serviceType
      if (this.district) filters.district_id = this.district
      if (this.status_ids.length > 0) filters.status_ids = this.status_ids
      if (this.category_id) filters.category_id = this.category_id
      if (this.currentColumnSortParams.value) {
        filters.sort_column = this.currentColumnSortParams.value
        filters.sort_order = this.currentColumnSortParams.order
      }
      if (this.search) filters.client_name = this.search

      if (this.currentTab === 'my') {
        filters.responsible_manager_id = this.user.id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'author') {
        filters.manager_id = this.user.id
        delete filters.responsible_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'department') {
        filters.responsible_department_id = this.user.department_id
      } else if (this.currentTab === 'closed') {
        filters.related_closed = 1
        delete filters.is_not_finished
      } else if (this.currentTab === 'all') {
        delete filters.responsible_manager_id
        delete filters.manager_id
        delete filters.responsible_department_id
        delete filters.is_not_finished
      }

      filters.ticket_kind_ids = this.getTicketKindIds

      await this.fetchTickets(filters)

      this.setLoading(false)
    },
    async onVols() {
      this.setLoading(true)
      this.isEmergency = false
      this.isSubtasks = false
      await this.fetchFilteredTickets()
      this.setLoading(false)
    },
    async onEmergency(page = 1) {
      this.setLoading(true)
      this.isEmergency = true
      this.isSubtasks = false
      this.emergencyTableColumns = EmergencyTicketsTableColumns
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      filters.is_not_finished = this.isOnlyOpen ? '1' : '0'
      filters.is_lc = this.isLC ? '1' : '0'
      filters.ticket_kind_id = 5
      this.currentPage = page
      this.selectedStatuses.forEach((item, index) => {
        filters[`status_id[${index}]`] = item.id
      })
      if (this.dateFrom) filters.date_from = formatDate(this.dateFrom, '-')
      if (this.dateTo) filters.date_to = formatDate(this.dateTo, '-')
      if (this.author) filters.manager_id = this.author
      if (this.responsible) filters.responsible_manager_id = this.responsible
      if (this.serviceType) filters.service_type_id = this.serviceType
      if (this.district) filters.district_id = this.district
      if (this.currentColumnSortParams.value) {
        filters.sort_column = this.currentColumnSortParams.value
        filters.sort_order = this.currentColumnSortParams.order
      }
      if (this.search) filters.client_name = this.search

      if (this.currentTab === 'my') {
        filters.responsible_manager_id = this.user.id
        delete filters.manager_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'author') {
        filters.manager_id = this.user.id
        delete filters.responsible_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'department') {
        filters.responsible_department_id = this.user.department_id
        delete filters.responsible_id
        delete filters.manager_id
      } else if (this.currentTab === 'closed') {
        filters.related_closed = 1
        delete filters.is_not_finished
      } else {
        delete filters.responsible_manager_id
        delete filters.manager_id
        delete filters.responsible_department_id
        delete filters.is_not_finished
      }
      await this.fetchEmergencyTickets(filters)
      this.setLoading(false)
    },
    openTicket(ticket) {
      this.setLoading(true)
      router.push(`/subtask/ticket/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    toggleTableOptions() {
      this.isTableOptionsModal = true
    },
    getNewTableColumns(newTable) {
      if (!this.isEmergency) {
        this.tableColumns = newTable
        window.localStorage.setItem('TicketsTableColumns', JSON.stringify(newTable))
        this.isTableOptionsModal = false
      } else {
        this.emergencyTableColumns = newTable
        window.localStorage.setItem('EmergencyTicketsTableColumns', JSON.stringify(newTable))
        this.isTableOptionsModal = false
      }
    },
    async fetchAccessTickets(page = 1, type) {
      this.setLoading(true)
      this.isSubtasks = false
      // let filters = {}
      // filters.page = page
      // filters.ticket_kind_id = 16
      // filters.related_type = type
      // filters.tickets_per_page = this.perPage
      // this.currentPage = page
      this.related_type = type
      this.ticketKind = 16
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      filters.is_not_finished = this.isOnlyOpen ? '1' : '0'
      filters.is_lc = this.isLC ? '1' : '0'
      filters.ticket_kind_id = 16
      filters.related_type = this.related_type
      this.currentPage = page
      this.selectedStatuses.forEach((item, index) => {
        filters[`status_id[${index}]`] = item.id
      })
      if (this.dateFrom) filters.date_from = formatDate(this.dateFrom, '-')
      if (this.dateTo) filters.date_to = formatDate(this.dateTo, '-')
      if (this.author) filters.manager_id = this.author
      if (this.responsible) filters.responsible_manager_id = this.responsible
      if (this.serviceType) filters.service_type_id = this.serviceType
      if (this.district) filters.district_id = this.district
      if (this.currentColumnSortParams.value) {
        filters.sort_column = this.currentColumnSortParams.value
        filters.sort_order = this.currentColumnSortParams.order
      }
      if (this.search) filters.client_name = this.search

      if (this.currentTab === 'my') {
        filters.responsible_manager_id = this.user.id
        delete filters.manager_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'author') {
        filters.manager_id = this.user.id
        delete filters.responsible_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'department') {
        filters.responsible_department_id = this.user.department_id
        delete filters.responsible_id
        delete filters.manager_id
      } else if (this.currentTab === 'closed') {
        filters.related_closed = 1
        delete filters.is_not_finished
      } else {
        delete filters.responsible_manager_id
        delete filters.manager_id
        delete filters.responsible_department_id
        delete filters.is_not_finished
        this.related_type = null
      }

      await this.fetchTickets(filters).then(() => {
        this.setLoading(false)
      })
    },
    async fetchSubtasks(page = 1) {
      this.setLoading(true)
      this.isSubtasks = true
      let filters = {}
      filters.page = page
      filters.tickets_per_page = this.perPage
      filters.is_not_finished = this.isOnlyOpen ? '1' : '0'
      filters.is_lc = this.isLC ? '1' : '0'
      filters.ticket_kind_id = 14

      this.currentPage = page
      this.selectedStatuses.forEach((item, index) => {
        filters[`status_id[${index}]`] = item.id
      })
      if (this.dateFrom) filters.date_from = formatDate(this.dateFrom, '-')
      if (this.dateTo) filters.date_to = formatDate(this.dateTo, '-')
      if (this.author) filters.manager_id = this.author
      if (this.responsible) filters.responsible_manager_id = this.responsible
      if (this.serviceType) filters.service_type_id = this.serviceType
      if (this.district) filters.district_id = this.district
      if (this.status_ids.length > 0) filters.status_ids = this.status_ids
      if (this.currentColumnSortParams.value) {
        filters.sort_column = this.currentColumnSortParams.value
        filters.sort_order = this.currentColumnSortParams.order
      }
      if (this.search) filters.client_name = this.search
      if (this.currentTab === 'my') {

        filters.responsible_manager_id = this.user.id
        delete filters.manager_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'author') {
        filters.manager_id = this.user.id
        delete filters.responsible_id
        delete filters.responsible_department_id
      } else if (this.currentTab === 'department') {
        filters.responsible_department_id = this.user.department_id
        delete filters.responsible_id
        delete filters.manager_id
      } else if (this.currentTab === 'closed') {
        filters.related_closed = 1
        delete filters.is_not_finished
      } else {
        delete filters.responsible_manager_id
        delete filters.manager_id
        delete filters.responsible_department_id
        delete filters.is_not_finished
        this.related_type = null
      }

      await this.fetchTickets(filters).then(() => {
        this.setLoading(false)
      })
    },
    sortRegistrator() {
      this.tickets.sort((a, b) => {
        const nameA = a.author.full_name.toLowerCase();
        const nameB = b.author.full_name.toLowerCase();

        if (nameA < nameB) {
          this.currentRegistratorColumnParams.order = 'asc'
          return this.isAscending ? -1 : 1;
        }
        if (nameA > nameB) {
          this.currentRegistratorColumnParams.order = 'desc'
          return this.isAscending ? 1 : -1;
        }
        return 0;
      });
      this.isAscending = !this.isAscending;
    },
    async downloadExcel() {
      this.setLoading(true)
      try {
        const params = new URLSearchParams();
        params.append('page', '1');
        params.append('tickets_per_page', '8');
        params.append('is_lc', '0');
        params.append('ticket_kind_ids[]', this.getTicketKindIds);
        params.append('download', '1');

        if (this.dateRange && Array.isArray(this.dateRange) && this.dateRange[0] && this.dateRange[1]) {
          params.append('date_from', formatDate(this.dateRange[0], '-'));
          params.append('date_to', formatDate(this.dateRange[1], '-'));
        }

        if (this.author) params.append('manager_id', this.author);
        if (this.responsible) params.append('responsible_manager_id', this.responsible);
        if (this.serviceType) params.append('service_type_id', this.serviceType);
        if (this.district) params.append('district_id', this.district);
        if (this.search) params.append('client_name', this.search);
        if (this.status_ids.length > 0) params.append('status_ids', this.status_ids);

        const res = await this.downloadExcelTickets(Object.fromEntries(params));

        const url = window.URL.createObjectURL(new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tickets.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.error("Error downloading excel:", error);
        this.$toast.error("Ошибка при скачивании файла");
      } finally {
        this.setLoading(false);
      }
    },
    closeEnrollModal() {
      this.isEnrollToManagerModal = !this.isEnrollToManagerModal
      this.setChangedTicketIds([])
      this.fetchFilteredTickets()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}
</style>