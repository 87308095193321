<template>
  <div class="flex justify-between filter w-100">
    <div class="filter-btns" v-if="!isNobtns">
      <button type="button" class="btn btn-create" @click="onCreate" v-if="isCreate">
        <img src="/assets/plus-create.svg" alt=""/>
        {{ $props.createText }}
      </button>
      <button type="button" :class=" isServices ? 'btn btn-create' : 'btn btn-create active-btn'" @click="onServices"
              v-if="$props.services">
        {{ $props.services }}
      </button>
      <button type="button" :class=" isVols ? 'btn btn-create' : 'btn btn-create active-btn'" @click="onVols"
              v-if="$props.vols">
        {{ $props.vols }}
      </button>
      <button type="button" :class=" isEmergency ? 'btn btn-create active-btn' : 'btn btn-create'" @click="onEmergency"
              v-if="$props.emergencyTickets">
        {{ $props.emergencyTickets }}
      </button>
      <button type="button" :class=" isClientAccess ? 'btn btn-create' : 'not-active-btn'"
              @click="onClientAccess" v-if="$props.clientAccess">
        {{ $props.clientAccess }}
      </button>
      <button type="button" :class=" isEmergencyAccess ? 'btn btn-create' : 'not-active-btn'"
              @click="onEmergencyAccess" v-if="$props.emergencyAccess">
        {{ $props.emergencyAccess }}
      </button>
      <button type="button" :class=" isSubtasks ? 'btn btn-create' : 'btn btn-create active-btn'" @click="onSubtasks"
              v-if="$props.subtasks">
        {{ $props.subtasks }}
      </button>
      <div v-if="getChangedTicketIds.length > 0" class="filter-action-buttons">
        <button type="button" class="btn btn-secondary btn-create__multi" @click="onSubmit">
          <span class="nowrap">В работу</span>
        </button>
        <button type="button" class="btn btn-secondary btn-create__multi" @click="onSubmitEnroll">
          Переназначить
        </button>
      </div>
    </div>


    <div class="flex">
      <div class="filter-btn mr-2 flex" v-if="isSearch">
        <img src="/assets/search-rounded.svg" alt="" @click="toggleSearch"/>
        <div class="filter-badge" v-if="searchText"></div>
        <tooltip-modal v-if="searchModal && !isAccessTickets" title="Поиск" @close="toggleSearch">
          <div class="input-container search mb-3">
            <input class="input" type="text" placeholder="Найти" v-model="searchText" @keyup="onSearch"/>
            <img src="/assets/search.svg" alt="">
          </div>
        </tooltip-modal>
        <tooltip-modal v-if="searchModal && isAccessTickets" title="Поиск" @close="toggleSearch"
                       style="position: absolute; top: 16px; left:-300px">
          <div class="input-container search mb-3">
            <input class="input" type="text" placeholder="Найти" v-model="searchText" @keyup="onSearch"/>
            <img src="/assets/search.svg" alt="">
          </div>
        </tooltip-modal>
      </div>
      <div v-if="isTableOptions" class="filter-btn ml-1 flex">
        <div class="filter-btn mr-2 flex">
          <img src="/assets/optionsTable.svg" alt="" @click="toggleTableOptions">
        </div>
      </div>

      <div class="filter-btn mr-2 flex" v-if="isSort">
        <img src="/assets/sort-rounded.svg" alt="" @click="toggleSort"/>
        <tooltip-modal v-if="sortModal" title="СОРТИРОВКА" @close="toggleSort">
          <div class="flex column w-100">
            <div class="input-container mb-2" v-for="filter in filterOptions" :key="filter.key">
              <select class="input select" v-model="filter.value">
                <option v-for="option in filter.array" :key="option.key" :value="option.id">
                  {{ option[filter.option_title] }}
                </option>
              </select>
            </div>
          </div>
        </tooltip-modal>
      </div>


      <div class="filter-btn mr-2 flex" v-if="isFilter">
        <img src="/assets/filter-rounded.svg" alt="" @click="toggleFilter"/>
        <div class="filter-badge" v-if="isFiltered"></div>
        <tooltip-modal v-if="filterModal" title="фильтр" @close="toggleFilter">
          <div class="flex column w-100">
            <div class="input-container mb-2" v-for="(filter, index) in filterOptions" :key="filter.key">
              <!--              <select class="input select" v-model="filter.value" @change="isFiltered = true">-->
              <!--                <option disabled :value="null">{{ filter.title }}</option>-->
              <!--                <option v-for="option in filters[index].array" :key="option.key" :value="option.id">-->
              <!--                  {{ option[filter.option_title] }}-->
              <!--                </option>-->
              <!--              </select>-->

              <div>
                <div>{{ filter.title }}</div>
                <multiselect
                    v-model="filter.value"
                    :options="parsedFilters(index)"
                    label="name"
                    track-by="id"
                    class="mt-1"
                    :placeholder="filter.title"
                    @change="isFiltered = true"
                    :clearable="true"
                ></multiselect>
              </div>
            </div>
            <div v-if="isStatuses">
              <div class="flex justify-center">
                <button class="flex justify-between align-center cursor-pointer w-100 bg-white"
                        style="border: 1px solid #eee2e2; height: 40px; border-radius: 6px;"
                        @click="onClickStatus">Статусы
                  <img src="/assets/right.svg" style="width: 20px; height: 20px" v-if="!isButtonCollapsed"/>
                  <img src="/assets/down.svg" style="width: 20px; height: 20px" v-if="isButtonCollapsed"/>
                </button>
              </div>
              <div v-if="isButtonCollapsed">
                <div>
                  <input type="checkbox" @change="checkAllStatuses" :checked="allChecked">
                  <label>Выбрать все</label>
                </div>
                <div v-for="status in statusOptions" :key="status.key">
                  <input type="checkbox" v-model="status.checked">
                  <label>{{ status.title }}</label>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-1">
              <div class="uppercase text-14 text-gray cursor-pointer" @click="cancelFilters">
                СБРОСИТЬ
              </div>
              <div class="uppercase text-14 text-secondary cursor-pointer ml-3" @click="onFilter">
                ПРИМЕНИТЬ
              </div>
            </div>
          </div>

        </tooltip-modal>
      </div>

      <range-date-picker
          v-if="isDate"
          :date-range="currentDate"
          :is-filter-bar="true"
          @set-date-range="setDateRange"
      />
    </div>
  </div>
</template>

<script>
import TooltipModal from "@/components/modules/TooltipModal.vue";
import RangeDatePicker from "@/components/modules/RangeDatePicker.vue";
import {mapActions, mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import { DateTime } from "luxon";

export default {
  name: 'FilterBar',
  components: {Multiselect, TooltipModal, RangeDatePicker},
  props: ['isCreate', 'isSearch', 'isSort', 'isFilter', 'search', 'filters', 'dateRange', 'createText', 'isDate', 'vols', 'emergencyTickets', 'isTableOptions', 'clientAccess', 'emergencyAccess', 'subtasks', 'services', 'isStatuses', 'isAccessTickets', 'isNobtns'],
  created() {
    this.initFirstLoad();
    this. initDatePickersFirstLoad()
  },
  data() {
    return {
      searchModal: false,
      sortModal: false,
      filterModal: false,
      searchText: this.search ? this.search : '',
      isFiltered: false,
      filterOptions: this.filters?.length ? this.filters : [],
      isVols: false,
      isEmergency: true,
      isClientAccess: false,
      isEmergencyAccess: false,
      isSubtasks: false,
      isServices: false,
      statusOptions: [],
      isStatusShow: false,
      isButtonCollapsed: false,
      isFirstLoadDone: false,
      currentDate: [],
    }
  },
  watch: {
    search() {
      this.searchText = this.$props.search
      this.filterOptions = this.$props.filters
      this.isFiltered = false
    },
  },
  computed: {
    ...mapGetters({
      getChangedTicketIds: 'getChangedTicketIds',
      statuses: 'statuses',
    }),
    allChecked() {
      return this.statusOptions.every(option => option.checked);
    }
  },
  methods: {
    ...mapActions({
      fetchStatuses: 'fetchStatuses',
    }),
    onCreate() {
      this.$emit('onCreate')
    },
    onSubmit() {
      this.$emit('onSubmit')
    },
    onSearch() {
      this.$emit('onSearch', this.searchText)
    },
    onDownload() {
      this.$emit('onDownload')
    },
    onFilter() {
      const filters = {}
      let statuses = new Set()
      this.filterOptions.forEach(item => {
        filters[item.key] = item.value?.id
      })
      this.statusOptions.forEach(item => {
        if (item.checked) {
          statuses.add(item.id)
        }
      })
      statuses = [...statuses]
      this.$emit('onFilter', filters, statuses)
      this.isFiltered = true
      if (this.isFirstLoadDone) {
        this.isFirstLoadDone = false
      } else {
        this.toggleFilter()
      }

    },
    setDateRange(dateRange) {
      this.currentDate = dateRange
      this.$emit('set-date-range', dateRange);
      this.$emit('onFilter', this.$props.filters);
    },
    cancelFilters() {
      const filters = {}
      this.filterOptions.forEach(item => {
        item.value = null
        filters[item.key] = item.value
      })
      const statuses = []
      this.statusOptions.forEach(item => {
        item.checked = false
      })
      this.isFiltered = false
      this.$emit('onFilter', filters, statuses)
      this.toggleFilter()
    },
    toggleSearch() {
      this.searchModal = !this.searchModal
    },
    toggleSort() {
      this.sortModal = !this.sortModal
    },
    toggleFilter() {
      this.filterModal = !this.filterModal
    },
    onVols() {
      this.isEmergency = true
      this.isVols = true
      this.isSubtasks = false
      this.isServices = false
      this.$emit('onVols')
    },
    onEmergency() {
      this.isEmergency = false
      this.isSubtasks = false
      this.isVols = false
      this.isServices = false
      this.$emit('onEmergency')
    },
    onClientAccess() {
      this.isClientAccess = true
      this.isEmergencyAccess = false
      this.isSubtasks = false
      this.isServices = false
      this.$emit('onClientAccess')
    },
    onEmergencyAccess() {
      this.isClientAccess = false
      this.isEmergencyAccess = true
      this.isSubtasks = false
      this.isServices = false
      this.$emit('onEmergencyAccess')
    },
    onSubtasks() {
      this.isClientAccess = false
      this.isEmergencyAccess = false
      this.isEmergency = true
      this.isVols = false
      this.isSubtasks = true
      this.isServices = false
      this.$emit('onSubtasks')
    },
    onServices() {
      this.isClientAccess = false
      this.isEmergencyAccess = false
      this.isEmergency = true
      this.isVols = false
      this.isSubtasks = false
      this.isServices = true
      this.$emit('onServices')
    },
    toggleTableOptions() {
      this.$emit('toggleTableOptions')
    },
    parsedFilters(index) {
      if (!this.filters[index].array.length) {
        return []
      }
      return this.filters[index].array.map((item) => ({
        name: item?.full_name || item?.title || item?.name_ru,
        id: item.id,
      }))
    },
    async onClickStatus() {
      this.isButtonCollapsed = !this.isButtonCollapsed
    },
    checkAllStatuses() {
      const shouldCheck = this.statusOptions.some(el => !el.checked);
      this.statusOptions.forEach(el => {
        el.checked = shouldCheck;
      });
    },
    onSubmitEnroll(){
      this.$emit('onSubmitEnroll')
    },
    async initFirstLoad() {
      await this.fetchStatuses()
      this.statusOptions = this.statuses
      if (this.$route.query.status_id) {
        this.statusOptions.forEach(item => {
          if (item.id === +this.$route.query.status_id) {
            item.checked = true
          }
        })
        this.isFirstLoadDone = true
        this.onFilter()
      }
    },
    initDatePickersFirstLoad(){
      this.currentDate = this.dateRange
      if (this.$route.query.date_from && this.$route.query.date_to) {
        const parsedDate = DateTime.fromFormat(this.$route.query.date_from, "dd-MM-yyyy", {zone: "UTC+5"})
        const parsedDate2 = DateTime.fromFormat(this.$route.query.date_to, "dd-MM-yyyy", {zone: "UTC+5"})
        const formattedDate = parsedDate.toFormat("EEE MMM dd yyyy HH:mm:ss 'GMT'ZZ")
        const formattedDate2 = parsedDate2.toFormat("EEE MMM dd yyyy HH:mm:ss 'GMT'ZZ")
        this.currentDate = [formattedDate, formattedDate2]
        let jsDate = [parsedDate.toJSDate(), parsedDate2.toJSDate()]
        this.setDateRange(jsDate)
      }
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.filter-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 100%;
  margin-right: 20px;

  button {
    width: initial;
  }
}

.filter-action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.btn-create__multi {
  font-size: 13px;
  border-radius: 25px;
  white-space: nowrap;
  min-width: fit-content;
  padding: 8px 16px;
}

.nowrap {
  white-space: nowrap;
}

.active-btn {
  background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
}

.not-active-btn {
  padding: 8px 16px;
  border-radius: 32px;
  background: #FFF;
  color: #333333;
  font-size: 18px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 48px;
  border: 1px solid #8B88AB;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filter-btn {
  position: relative;

  img {
    width: 48px;
    height: 48px;
    cursor: pointer;
  }

  .filter-badge {
    border-radius: 18px;
    background: $red;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
