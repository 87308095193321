<template>
  <div>
    <div class="flex">
      <button
        class="dashboard-button"
        @click="openDashboard"
    >
      <img src="/assets/dashboard.svg" alt="dashboard icon"/>
      Дашборд клиента
    </button>
    <button class="dashboard-button" style="background: #159C33;" @click.prevent="fetchWazzupFunc">
      Написать в Whatsapp
    </button>
    </div>

    <div class="w-100 p-20 bg-gray rounded">
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.name">
        <p class="text-14 text-black">Клиент:</p>
        <p class="text-14 text-black text-right">{{ client.name }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.personal_manager">
        <p class="text-14 text-black">Персональный менеджер:</p>
        <p class="text-14 text-black text-right">{{ client.personal_manager }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.vgroups[0] && client.vgroups[0].address ">
        <p class="text-14 text-black mr-4">Адрес:</p>
        <p class="text-14 text-black text-right">{{ client.vgroups[0].address }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.mobile">
        <p class="text-14 text-black">Телефон:</p>
        <p class="text-14 text-black text-right">{{ phone(client.mobile) }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.email">
        <p class="text-14 text-black">Эл. почта:</p>
        <p class="text-14 text-black text-right">{{ client.email }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.inn">
        <p class="text-14 text-black">ИИН:</p>
        <p class="text-14 text-black text-right">{{ client.inn }}</p>
      </div>
      <template v-for="vgroup in client.vgroups" :key="vgroup.vgid">
        <div class="bg-white rounded card flex justify-between align-center" v-if="vgroup.ip_info && vgroup.ip_info.info && vgroup.ip_info.info.location">
          <p class="text-14 text-black">Узел подключения:</p>
          <p class="text-14 text-black text-right">{{ vgroup.ip_info.info?.location }}</p>
        </div>
      </template>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.bankname">
        <p class="text-14 text-black">Банк:</p>
        <p class="text-14 text-black text-right">{{ client.bankname }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.bik">
        <p class="text-14 text-black">БИК:</p>
        <p class="text-14 text-black text-right">{{ client.bik }}</p>
      </div>
      <div class="bg-white rounded card flex justify-between align-center" v-if="client.uid">
        <p class="text-14 text-black">User ID:</p>
        <p class="text-14 text-black text-right">{{ client.uid }}</p>
      </div>
      <div class="bg-white rounded card  align-center" v-if="client.vgroups">
        <p class="text-14 text-black">Vlan:</p>
        <div class="text-14 text-black text-right" v-for="info in client.vgroups" :key="info">
           <p>{{info.ip_info?.info?.ip_info?.clvlan}}</p>
        </div>
      </div>
      <div class="bg-white rounded card" v-if="connectionTickets.length > 0">
        <p class="text-14 text-black">Заявки на подключение:</p>
        <div class="text-14 text-black" v-for="(connection,index) in connectionTickets" :key="connection">
          <p class="link" @click="openClientConnectionTicket(connection.id)">{{ index + 1}}) {{ connection.address }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
import {maskPhone} from "@/helpers/utils";

export default {
  name: 'ClientInfoCard',
  computed: {
    ...mapGetters({
      client: 'client',
      user: 'user',
      connectionTickets: 'connectionTickets',
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
      setClientDashboard: 'setClientDashboard',
      setIsClientDashboardModal: 'setIsClientDashboardModal',
      setIsClientCard: 'setIsClientCard'
    }),
    ...mapActions({
      fetchClientDashboard: 'fetchClientDashboard',
      fetchWazzup: 'fetchWazzupIframeUrl',
      createUser: 'createWazzupUser',
      fetchTicketDetail: 'fetchConnectionTicketDetail',
    }),
    phone(phone) {
      return maskPhone(phone)
    },
    async openDashboard() {
      this.setLoading(true);
      if (!this.client.dashboard)
        await this.fetchClientDashboard({client_lb_id: this.client.uid});
      this.setIsClientDashboardModal(true);
      this.setLoading(false);
    },
    async fetchWazzupFunc() {
      const data = {
        user: {
          id: `${this.user.id}`,
          name: this.user.full_name
        },
        scope: 'card',
        filter: [{
          chatType: 'whatsapp',
          chatId: this.client.mobile
        }]
      }
     await this.fetchWazzup(data).then(()=>{
        this.setIsClientCard(true)
        this.$emit("close");
        this.$router.push('/chat')
      }).catch((err)=> {
        if (err.response.status === 400) {
          const users = [{
            id: `${this.user.id}`,
            name: this.user.full_name,
            phone: this.user.phone
          }]
          this.createUser(users)
          location.reload()
        }
      })
    },
    openClientConnectionTicket(id){
     this.$router.push(`/connection-ticket/${id}`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.card {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}


</style>
