<template>
<div class="p-20">
  <div class="p-20">
    <h1 class="text-24 text-center mb-3">Выберите сотрудника на которого нужно переназначить заявки</h1>
    <multiselect v-model="newManager" :options="managers" label="full_name" track-by="id" placeholder="Выберите сотрудника"></multiselect>
    <div class="flex mt-3 gap-2 justify-between items-center">
      <button class="btn input-btn" @click="submitEnrollTickets">Принять</button>
      <button class="btn input-btn__form" style="background: gray;">Отмена</button>
    </div>
  </div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "ServiceTicketsEnrollToManagerModal",
  components: { Multiselect },
  data() {
    return {
      newManager: '',
    }
  },
  computed: {
    ...mapGetters({
      managers: 'managers',
      getChangedTicketIds: 'getChangedTicketIds',
    })
  },
  methods: {
    ...mapActions({
      fetchTickets: 'fetchTickets',
      enrollTicketsToManager: 'enrollTicketsToManager'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    submitEnrollTickets(){
      this.setLoading(true)
      const ticket_ids = this.getChangedTicketIds.map(item => item.id)
      this.enrollTicketsToManager({tickets: ticket_ids, manager_id: this.newManager.id})
          .then(res => {
            if (res.status) {
              this.$toast.open({message: 'Заявки успешно переназначены', type: 'success'})
              this.$emit('close')
            } else {
              this.$toast.open({message: res.message, type: 'error'})
            }
          })
          .catch(e => {
            this.$toast.error(`${e.message}`)
          })
          .finally(() => {
            this.setLoading(false)
          })
    },
  },
}
</script>

<style scoped lang="scss">

</style>