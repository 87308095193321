<template>
  <div class="wrapper wrapper-content modified flex column bg"
       style="padding-bottom: 0;padding-top:10px;margin-bottom: 10px;">
    <div class="bg-white p-20 rounded">
      <div class="flex gap-2 justify-between mb-1">
        <div class="w-100" @click="showDropdownCalendar = !showDropdownCalendar">
          <calendar-departure :show-dropdown-calendar="showDropdownCalendar" :date-from="date_from" :date-to="date_to"
                              @setDate="setDate" :type="'OTP'"></calendar-departure>
        </div>
        <div class="w-100 relative">
          <label>Отдел</label>
          <select class="input-form select mt-1" v-model="currentOTPDepartment"
                  style="height: 48px !important; border-radius: 25px !important;" @change="changeDepartment">
            <option :value=null>Все</option>
            <option
                v-for="option in OTPDepartments"
                :key="option.id"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
        </div>
        <div class="w-100 relative">
          <label>Тип клиента</label>
          <select class="input-form select mt-1" v-model="currentClientType"
                  style="height: 48px !important; border-radius: 25px !important;" @change="changeClientType">
            <option :value=null>Все</option>
            <option
                v-for="option in client_type"
                :key="option"
                :value="option.value"
            >
              {{ option.title }}
            </option>
          </select>
        </div>
        <div class="w-100 relative">
          <label for="">Категория</label>
          <select class="input-form select mt-1" v-model="category_id"
                  style="height: 48px !important; border-radius: 25px !important;" @change="changeCategory">
            <option :value="null">Все</option>
            <option v-for="option in OTPCategories" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>
      <div style="">
        <div class="flex justify-between input-form">
          <div>Всего открытых заявок на текущий момент:</div>
          <div style="color: #9F53FF">{{ OTPTicketsTotal?.total_responsible_open_tickets }}</div>
        </div>
        <div class="flex mt-1 gap-2">
          <div class="flex justify-between input-form">
            <div>Закрытые заявки ОТП 1</div>
            <div style="color: #FFB020">{{ OTPTicketsTotal?.otp1_closed }}</div>
          </div>
          <div class="flex justify-between input-form">
            <div>Открытые заявки ОТП 1</div>
            <div style="color: #1ED3FF">{{ OTPTicketsTotal?.otp1_opened }}</div>
          </div>
        </div>
        <div class="flex mt-1 gap-2">
          <div class="flex justify-between input-form">
            <div>Закрытые заявки ОТП 2</div>
            <div style="color: #FFB020">{{ OTPTicketsTotal?.otp2_closed }}</div>
          </div>
          <div class="flex justify-between input-form">
            <div>Открытые заявки ОТП 2</div>
            <div style="color: #1ED3FF">{{ OTPTicketsTotal?.otp2_opened }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-30 mt-1">
        <filter-bar :isStatuses="true" :is-filter="true" :is-Nobtns="true"  @on-filter="onFilterSubmit"></filter-bar>
        </div>
        <div class="content w-100 mt-1 flex  column">
          <div class="grid gridText" style="background-color: lightgray;">
            <div class="grid-num">
              <p>№</p>
            </div>
            <div class="grid-name">
              <p>ФИО</p>
            </div>
            <div class="grid-create">
              <p>Создано</p>
            </div>
            <div class="grid-closed">
              <p>Закрыто</p>
            </div>
          </div>
        </div>
        <div class="otp-table">
          <div class="grid cursor-pointer grid-borders"
               :class="{selected: selectedManagerId === manager.manager_id}"
               v-for="(manager, index) in OTPManagersTickets" :key="manager"
               @click="openManagerInfo(manager.manager_id)"
          >
            <div> {{ index + 1 }}</div>
            <div class="link">{{ manager.manager_full_name }}</div>
            <div>{{ manager.opened }}</div>
            <div>{{ manager.closed }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <OTPManagerTicketsInfoModal></OTPManagerTicketsInfoModal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CalendarDeparture from "@/components/calendar/calendar-departure.vue";
import OTPManagerTicketsInfoModal from "@/components/modals/OTPtickets/OTPManagerTicketsInfoModal.vue";
import FilterBar from "@/components/modules/FilterBar.vue";

export default {
  name: "OTPEmployeesTicketsView",
  components: {FilterBar, OTPManagerTicketsInfoModal, CalendarDeparture},
  created() {
    this.fetchOTPTicketsTotal();
    this.fetchOTPCategories();
    this.fetchOTPDepartments();
    this.fetchOTPManagersTickets()
  },
  watch: {
    params: {
      async handler(newValue, oldValue) {
        await this.watcherFunction()
      },
      deep: true
    },
  },
  data() {
    return {
      selectedManagerId: null,
      OTPDepartment: null,
      category_id: null,
      currentClientType: null,
      currentOTPDepartment: null,
      showDropdownCalendar: false,
      ticket_kind_id: 2,
      status_ids: null,
      client_type: [
        {
          title: 'Юр.лица',
          value: 'jur',
        },
        {
          title: 'Физ.лица',
          value: 'fiz'
        }
      ],
      date_from: '',
      date_to: '',
      params: {}
    }
  },
  computed: {
    ...mapGetters({
      OTPTicketsTotal: 'OTPTicketsTotal',
      OTPManagersTickets: 'OTPManagersTickets',
      OTPDepartments: 'OTPDepartments',
      OTPCategories: 'OTPCategory',
      ticketsCategories: 'ticketsCategories',
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    ...mapActions({
      fetchOTPCategories: 'fetchOTPCategories',
      fetchOTPDepartments: 'fetchOTPDepartments',
      fetchOTPTicketsTotal: 'fetchOTPTicketsTotal',
      fetchOTPManagersTickets: 'fetchOTPManagersTickets',
      fetchOTPManagerTicketInfo: 'fetchOTPManagerTicketInfo',
    }),
    async openManagerInfo(id) {
      this.setLoading(true)
      this.selectedManagerId = id
      await this.fetchOTPManagerTicketInfo({...this.params, manager_id: id, status_ids: this.status_ids}).then((res) => {
        if (res.success) {
          if (res.data.length === 0) {
            this.$toast.warning('На данный момент нет заявок, назначенных на этого сотрудника')
          }
        }

      });
      this.setLoading(false)
    },
    changeCategory() {
      if (this.category_id) {
        this.params.category_id = this.category_id
      } else {
        this.params.category_id = null
      }
    },
    changeClientType() {
      if (this.currentClientType) {
        this.params.client_type = this.currentClientType
      } else {
        this.params.client_type = null
      }
    },
    changeDepartment(){
      if (this.currentOTPDepartment){
        this.params.department_id = this.currentOTPDepartment
      } else {
        this.params.department_id = null
      }
    },
    async watcherFunction() {
      this.setLoading(true)
      await this.fetchOTPManagersTickets(this.params)
      await this.fetchOTPTicketsTotal(this.params)
      this.setLoading(false)
    },
    setDate(data) {
      if (data) {
        this.date_from = data?.[0]
        this.date_to = data?.[1]
        this.params.date_from = this.date_from
        this.params.date_to = this.date_to
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
        delete this.params.date_from
        delete this.params.date_to
      }
    },
    onFilterSubmit(filter, status){
      this.status_ids = status
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr  3fr 3fr;
  grid-template-areas: 'Num Name  Create Closed';
}

.grid-num {
  grid-area: Num;
}

.grid-name {
  grid-area: Name;
}

.grid-create {
  grid-area: Create;
}

.grid-closed {
  grid-area: Closed;
}

.grid-borders {
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray
}

.grid-borders.selected {
  background-color: #f0f8ff; /* Светло-голубой цвет для выделения */
  transition: background-color 0.3s ease; /* Плавное изменение цвета */
}

.otp-table {
  max-height: 360px;
  overflow-y: scroll;

  :hover {
    background-color: #F5F5FA;
  }
}
</style>