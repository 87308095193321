<template>
    <div class="choose-modal">
        <div class="choose-modal__title w-100 mb-4">
           Добавление сотрудника
        </div>
        <div class="dropdown w-100">
          <input
              type="text"
              class="input-form"
              v-model="search"
              @click="toggleDropdown"
              @input="showDropdown = true"
              placeholder="Выберите сотрудника"
              ref="searchInput"
          />
          <div class="dropdown-content" :class="{ show: showDropdown }">
            <div
                v-for="option in filteredInstallers"
                :key="option.key"
                @click="selectOption(option)"
            >
              {{ option.full_name }}
            </div>
          </div>
        </div>
        <div class="mt-3 flex justify-between" style="gap: 20px">
            <button class="btn" @click="submitNewInstaller">Добавить</button>
            <button class="btn" style="background: gray;" @click="closeModal">Отмена</button>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'InstallersListModal',
    data() {
        return {
            newInstaller: null,
            showDropdown: false,
            filteredInstallers: [],
            search: '',
        }
    },
    computed: {
        ...mapGetters({
            installers: 'installers',
            currentBrigadeOptions: 'currentBrigadeOptions'
        })
    },
  watch: {
    search(value) {
      this.filteredInstallers = this.installers.filter(option =>
          option.full_name.toLowerCase().includes(value.toLowerCase())
      );
    },
    installers(newOptions) {
      this.filteredInstallers = newOptions;
    }
  },
    methods: {
        ...mapActions({
            postBrigadesInstallers: 'postBrigadesInstallers',
            fetchBrigadesRelations: 'fetchBrigadesRelations'
        }),
        ...mapMutations({
            setLoading: 'setLoading',
            setisBrigadeAddInstallersModal: 'setisBrigadeAddInstallersModal'
        }),
      toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        if (this.showDropdown && !this.search) {
          this.filteredInstallers = this.installers;
        }
      },
      selectOption(option) {
        this.search = option.full_name;
        this.newInstaller = option.id
        this.showDropdown = false;
      },
      async submitNewInstaller() {
        const payload = {
            brigade_id: this.currentBrigadeOptions.brigade.crm_meganet_id,
            installers: [this.newInstaller]
        };

        this.setLoading(true);

        try {
            const res = await this.postBrigadesInstallers(payload);
            if (res.success) {
                await this.fetchBrigadesRelations();
                this.$toast.success('Сотрудник успешно добавлен');
                this.$emit('updateBrigadeData');
                this.closeModal();
            }
        } catch (err) {
            this.$toast.error(`Не удалось добавить сотрудника. Причина: ${err.message}`);
        } finally {
            this.setLoading(false);
        }
      },
      closeModal(){
          this.$emit('closeModal')
      }
    },
  mounted() {
    this.$refs.searchInput.focus();
    this.filteredInstallers = this.installers;
  },
}
</script>

<style lang="scss" scoped>
.choose-modal {
  width: 416px;
  margin-bottom: 32px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 130px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
</style>