<template>
  <div class="flex column mt-4 client-pie">
    <h4 class="client-pie__title">Типы клиентов по заявкам</h4>
    <Pie class="client-pie__diagram" :data="chartData()" :options="chartOptions" />
  </div>

</template>

<script>

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: 'ClientTypeDiagram',
  props: ['clientTypeBlock'],
  components: {Pie},
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            shape: 'circle'
          }
        }
      },
    }
  },
  computed: {

  },
  methods: {
    chartData() {
      console.log({
        labels: this.$props.clientTypeBlock.map(item => item.title),
        datasets: this.transformDataForChart(this.$props.clientTypeBlock)
      })
      return {
        labels: this.$props.clientTypeBlock.map(item => item.title),
        datasets: this.transformDataForChart(this.$props.clientTypeBlock)
      };
    },
    transformDataForChart(dataArray) {
      const backgroundColor = []
      const data = []
      dataArray.forEach(item => {
        backgroundColor.push(this.getColorForItem(item.key))
        data.push(parseFloat(item.percent))
      })
      return [{
        data,
        backgroundColor
      }]
    },
    getColorForItem(key) {
      const colorMap = {
        'b2b': '#357BE2',
        'b2c': '#FAA939',
        'b2o': '#3DC962',
        'b2g': '#656565'
      };
      return colorMap[key] || '#000000'; // Default color if key not found
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.client-pie {
  width: 426px;
  border-radius: 29px;
  background: #FFF;
  box-shadow: 0px 32px 44px 0px rgba(0, 0, 0, 0.10);
  padding: 30px 40px;
  @media (min-width: 1440px) {
    width: 50%;
    gap: 16px;
  }
}


.client-pie__title {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-bottom: 32px;
}

.client-pie__diagram {
  height: 480px !important;
}

</style>
