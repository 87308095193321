import {api} from "@/service/axios";

export const nodesModule = {
    state: () => ({
        isNodesListModal: false,
        commutatorsList: []
    }),
    getters: {
        isNodesListModal(state) {
            return state.isNodesListModal
        },
        commutatorsList(state) {
            return state.commutatorsList
        }
    },
    mutations: {
        setNodesListModal(state, payload) {
            state.isNodesListModal = payload
        },
        setCommutatorsList(state, payload) {
            state.commutatorsList = payload
        }
    },
    actions: {
        async fetchCommutatorsList({ commit }, payload) {
            const res = await api.get('techdata/get-commutators', {params: payload})
            commit('setCommutatorsList', res.data);
            return res
        }

    }
}