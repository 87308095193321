<template>
  <div class="p-20">
    <h1 class="text-center mt-3" style="font-size: 20px;">
      Вы действительно хотите удалить {{ client.full_name }} из аварии
    </h1>
    <button class="btn mt-3" @click="emitAction">Да</button>
  </div>
</template>

<script>
export default {
  name: 'EmergencyRemoveUserModal',
  props: {
    client: {
      type: Object,
      default: null
    }
  },
  methods: {
    emitAction() {
      this.$emit("remove")
    }
  }
}
</script>

<style>

</style>