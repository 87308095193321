<template>
    <div class="file-upload">
        <div class="upload-zone" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent>
            <input type="file" ref="fileInput" @change="handleFiles" :multiple="multiple" class="hidden-input">
            <span>Выберите файлы или перетащите их сюда</span>
        </div>

        <table v-if="files.length" class="files-table">
            <thead>
                <tr>
                    <th>Имя файла</th>
                    <th width="100">Действия</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="file in files" :key="file.id" class="file-row" :class="{ 'uploading': file.uploading }">
                    <td class="filename-cell">
                        <div class="file-info">
                            <span class="filename">{{ file.name }}</span>
                            <ProgressBar :size="50" :value="file.progress" :maxValue="100"></ProgressBar>
                        </div>
                    </td>
                    <td>
                        <button @click="file.uploading ? cancelUpload(file) : deleteFile(file)" class="button">
                            {{ file.uploading ? 'Отменить' : 'Удалить' }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { api } from '@/service/axios'
import ProgressBar from './ui/ProgressBar.vue';

export default {
    name: 'UploadFile',

    components: {
        ProgressBar
    },

    props: {
        multiple: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            files: [],
            uploadControllers: new Map(),
        };
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        handleDrop(e) {
            const droppedFiles = Array.from(e.dataTransfer.files);
            this.processFiles(droppedFiles);
        },

        handleFiles(e) {
            const selectedFiles = Array.from(e.target.files);
            this.processFiles(selectedFiles);
        },

        async processFiles(newFiles) {
            for (const file of newFiles) {
                const fileObj = {
                    id: Date.now() + Math.random(),
                    file: file,
                    name: file.name,
                    progress: 0,
                    uploading: true,
                    uploadedPath: null,
                    uploadedName: null,
                };

                this.files.push(fileObj);
                await this.uploadFile(fileObj);
            }
        },

        async uploadFile(fileObj) {
            const controller = new AbortController();
            this.uploadControllers.set(fileObj.id, controller);

            const formData = new FormData();
            formData.append('file', fileObj.file);

            try {
                let res = await api.post('file/upload', formData, {
                    signal: controller.signal,
                    onUploadProgress: (progressEvent) => {
                        fileObj.progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        this.files = this.files.map(i => i);
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (res.success) {
                    fileObj.uploadedPath = res.file.path;
                    fileObj.uploadedName = res.file.name;
                }

                fileObj.uploading = false;
                fileObj.uploaded = true;
                this.uploadControllers.delete(fileObj.id);

                this.files = this.files.map(i => i);
            } catch (error) {
                console.error('Ошибка загрузки:', error);
                this.files = this.files.filter(f => f.id !== fileObj.id);
            }
        },

        cancelUpload(file) {
            const controller = this.uploadControllers.get(file.id);
            if (controller) {
                controller.abort();
                this.uploadControllers.delete(file.id);
                this.files = this.files.filter(f => f.id !== file.id);
            }
        },

        async deleteFile(file) {
            if (file.uploading || !file.uploadedPath) return;

            try {
                let res = await api.post('file/delete', { path: file.uploadedPath });
                if (res.success) {
                    this.files = this.files.filter(f => f.id !== file.id);
                }
            } catch (error) {
                console.error('Ошибка удаления:', error);
            }
        }
    }
};
</script>

<style scoped>
.file-upload {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0px;
}

.upload-zone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}

.upload-zone:hover {
    border-color: #4CAF50;
}

.hidden-input {
    display: none;
}

.files-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.files-table th,
.files-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.filename-cell {
    position: relative;
    overflow: hidden;
}

.file-info {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;

  .filename {
    max-width: 250px;
  }
}

.file-row.uploading .delete-btn {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>