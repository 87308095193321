<template>
	<div class="search" v-click-away="() => (search = '')">
		<div class="search-input" v-click-away="() => (isShowDropdown = false)">
			<img src="/assets/nav/search.svg" alt="" />
			<input
				v-model="search"
				@input="fetchSearch"
				@click="isShowDropdown = false"
				placeholder="Поиск сотрудника, заявок"
				type="text"
			/>
			<img src="/assets/nav/filter.svg" alt="" class="search-input__filter" @click="isShowDropdown = !isShowDropdown" />
			<div class="filter-dropdown" v-if="isShowDropdown">
				<div>
					<AppCheckbox v-model="isClients" />
					<label for="" class="block">Клиенты</label>
				</div>
				<div>
					<AppCheckbox v-model="isApplications" />
					<label for="" class="block">Заявки</label>
				</div>
			</div>
		</div>
		<div class="search-results" v-if="search.length > 2">
			<div v-if="isClients" :class="{ 'mb-20': isApplications }">
				<p class="search-results__title">Клиенты</p>
				<div v-if="isClientsLoading" class="loader"></div>
				<div v-else-if="clients && clients.length > 0">
					<div
						class="search-results__item flex justify-between"
						v-for="client in clients"
						:key="client.ticket_id || client.id"
						@click="selectClient(client)"
					>
						<div class="search-results__item-main">
							<p v-if="client.name">
								{{ client.name }}
							</p>
							<p v-if="client.ticket_number" class="ticket-number">#{{ client.ticket_number }}</p>
						</div>
						<div class="search-results__item-details">
							<p v-if="client.iin">
								{{ client.iin }}
							</p>
							<p v-if="client.status" class="status">{{ client?.status?.title || client.status }}</p>
						</div>
					</div>
				</div>
				<div v-else-if="searchPerformed" class="search-results__empty">Ничего не найдено</div>
			</div>

			<div v-if="isApplications">
				<p class="search-results__title">Заявки</p>
				<div v-if="isApplicationsLoading" class="loader"></div>
				<div v-else-if="tickets && tickets.length > 0">
					<div
						class="search-results__item flex justify-between"
						v-for="ticket in tickets"
						:key="ticket.id"
						@click="openTicket(ticket)"
					>
						<div class="search-results__item-main">
							<p v-if="ticket.contact_info || ticket?.author?.full_name">
								{{ ticket.contact_info || ticket.author?.full_name }}
							</p>
						</div>
						<div class="search-results__item-details">
							<p v-if="ticket.ticket_number">
								{{ ticket.ticket_number }}
							</p>
						</div>
					</div>
				</div>
				<div v-else-if="searchPerformed" class="search-results__empty">Ничего не найдено</div>
			</div>

			<div v-if="!isClients && !isApplications" class="search-results__empty">Ничего не найдено</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex';
	import AppCheckbox from '@/components/ui/AppCheckbox.vue';
	import { TicketRouterNavigationMixin } from '@/mixins/TicketRouterNavigationMixinModule';

	export default {
		name: 'GlobalSearch',
		components: { AppCheckbox },
		data() {
			return {
				search: '',
				timeout: null,
				searchPerformed: false,
				isClients: true,
				isApplications: false,
				isShowDropdown: false,
				isClientsLoading: false,
				isApplicationsLoading: false,
			};
		},
		mixins: [TicketRouterNavigationMixin],
		watch: {
			async isApplications(value) {
				if (value && this.search.length) {
					this.isApplicationsLoading = true;
					const filters = { client_name: this.search };
					try {
						await this.fetchTickets(filters);
					} catch (e) {
						console.log(e);
					}
					this.isApplicationsLoading = false;
				}
			},
			async isClients(value) {
				if (value && this.search.length) {
					this.isClientsLoading = true;
					try {
						await this.clientsSearch(this.search);
						await this.fetchDids({ number: this.search });
					} catch (e) {
						console.log(e);
					}
					this.isClientsLoading = false;
				}
			},
		},
		methods: {
			...mapMutations({
				setClient: 'setClient',
				setClientsSimple: 'setClientsSimple',
				setLoading: 'setLoading',
			}),
			...mapActions({
				fetchClientData: 'fetchClientData',
				clientsSearch: 'clientsSearch',
				fetchDids: 'fetchDids',
				fetchTickets: 'fetchTickets',
			}),
			// Debounce function to optimize repeated calls
			debounce(func, delay) {
				clearTimeout(this.timeout);
				this.timeout = setTimeout(func, delay);
			},

			fetchSearch() {
				if (this.search.length < 3) return;

				this.isClientsLoading = true;
				this.isApplicationsLoading = true;
				this.isShowDropdown = false;

				this.debounce(async () => {
					const searchQuery = this.search;

					const tasks = [];

					if (this.isClients) {
						tasks.push(this.clientsSearch(searchQuery), this.fetchDids({ number: searchQuery }));
					}

					if (this.isApplications) {
						const filters = { client_name: searchQuery };
						tasks.push(this.fetchTickets(filters));
					}

					try {
						await Promise.all(tasks);
					} catch (e) {
						console.log(e);
					}

					this.isClientsLoading = this.isApplicationsLoading = false;
					this.searchPerformed = true;
				}, 1500);
			},

			async selectClient(client) {
				const { ticket_number, uid, ticket_kind } = client;

				this.resetClientData();

				this.setLoading(true);

				if (ticket_number && ticket_kind) {
					this.getTicketDetail(client);
				} else if (uid) {
					await this.fetchClientData(uid);
				}

				this.setLoading(false);
			},

			getTicketDetail(client) {
				this.ticketRouterNavigationFunc(client);
			},

			openTicket(ticket) {
				this.resetSearch();
				this.setLoading(true);
				this.$router.push(`/subtask/ticket/${ticket.ticket_number}`);
				this.setLoading(false);
			},

			resetClientData() {
				this.resetSearch();
				this.setClientsSimple([]);
				this.setClient({});
			},

			resetSearch() {
				this.search = '';
				this.isShowDropdown = false;
			},
		},
		computed: {
			...mapGetters({
				clients: 'clientsSimple',
				tickets: 'tickets',
			}),
		},
	};
</script>

<style scoped lang="scss">
	.mb-20 {
		margin-bottom: 20px;
	}
	.search {
		position: relative;
		min-width: 518px;
    padding-right: 16px;

		&-input {
			position: relative;
			display: flex;
			align-items: center;
			background-color: #f2f1f0;
			border-radius: 16px;
			padding: 13px 12px 15px 16px;

			input {
				width: 90%;
				padding-left: 8px;
				font-size: 16px;
				line-height: 18px;
				background-color: #f2f1f0;
				border: none;
				outline: none;
				font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
			}

			&__filter {
				cursor: pointer;
				position: absolute;
				right: 12px;
			}
		}

		.filter-dropdown {
			z-index: 100;
			position: absolute;
			top: 52px;
			width: 200px;
			right: 0;
			background-color: white;
			box-shadow: 0px 4px 24px 2px rgba(0, 0, 0, 0.05);

			padding: 18px 16px;
			border-radius: 12px 12px 24px 24px;

			> div {
				display: flex;
				align-items: center;
				gap: 14px;
				padding: 8px 0;

				label {
					font-weight: 500;
				}
			}
		}
	}

	.search-results {
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		border-radius: 12px;
		background: #fff;
		box-shadow: 0px 4px 24px 2px rgba(0, 0, 0, 0.05);
		padding: 18px 16px;
		top: 52px;
		left: 0;
		right: 0;
		max-height: 480px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: #e1e0e0;
		}

		&__title {
			font-size: 16px;
			font-weight: 600;
			width: 100%;
			padding-bottom: 8px;
			border-bottom: 1px solid #e1e0e0;
			margin-bottom: 8px;
		}

		&__item {
			width: 100%;
			box-sizing: border-box;
			cursor: pointer;
			padding: 11px 0;

			&-main {
				.ticket-number {
					color: #444;
					font-weight: 500;
				}
			}

			&-details {
				display: flex;
				gap: 10px;
				flex-wrap: wrap;
				justify-content: flex-end;

				.status {
					color: #28a745;
				}

				.brigade {
					color: #6c757d;
				}

				.type {
					color: #17a2b8;
				}
			}

			p {
				color: #1c1e23;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0.05px;
			}
		}

		&__empty {
			padding: 10px 12px;
			color: #a1a1a1;
			text-align: center;
			font-size: 15px;
		}
	}

	.loader {
		width: 40px;
		height: 40px;
		border: 4px solid rgba(0, 0, 0, 0.1);
		border-top-color: #fe7300;
		border-radius: 50%;
		animation: rotation 1s linear infinite;
		margin: 40px auto;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@media (max-width: 1224px) {
		.search {
			min-width: 400px;
		}
	}

	@media (max-width: 1044px) {
		.search {
			min-width: 300px;
		}
	}

	@media (max-width: 944px) {
		.search {
			min-width: 240px;

			input {
				width: 80%;
			}
		}
	}
</style>
