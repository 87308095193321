<template>
  <div class="flex justify-center">
    <div class="bg-white mt-1 p-20 rounded">
      <div class="mb-1">
        <h1 class="text-center text-24">Новая заявка на ЛКС</h1>
      </div>
      <div class="flex justify-center p-20" style="width: auto">
        <form @submit.prevent="submitForm">
          <div v-if="ticket?.ticket_kind?.key === 'service' || ticket?.connection_client" class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="newTicket.client_full_name" ref="client_full_name"
                   placeholder="ФИО клиента"
                   type="text" disabled/>
            <label class="label-form">ФИО клиента</label>
          </div>
          <div v-if="ticket?.ticket_kind?.key === 'service' || ticket?.connection_client" class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="newTicket.client_phone" ref="client_phone"
                   placeholder="Номер клиента"
                   type="text" disabled/>
            <label class="label-form">Номер клиента</label>
          </div>
          <div v-if="ticket?.ticket_kind?.key === 'emergency' || ticket?.ticket_kind?.key === 'plan'" class="input-container input-container__form mb-2">
            <input class="input input-form" disabled v-model="tempNodes" ref="tempNodes" placeholder="Узлы"
                   type="text"/>
            <label class="label-form">Узлы</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="authorInfo" ref="author_full_name"
                   placeholder="ФИО постановщика"
                   type="text" disabled/>
            <label class="label-form">ФИО постановщика</label>
          </div>
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="newTicket.author_phone" ref="author_phone"
                   placeholder="Номер постановщика"
                   type="text" disabled/>
            <label class="label-form">Номер постановщика</label>
          </div>
          <div class="input-container input-container__form mb-2">
          <textarea class="input-form textarea" v-model="newTicket.description" ref="description"
                    placeholder="Описание"
                    type="text"/>
            <label class="label-form" for="body">Описание</label>
          </div>
          <div class="flex justify-center gap-2 mt-2">
            <button class="btn form-btn" type="submit" @click.prevent="submitForm">Cоздать</button>
          </div>
        </form>
      </div>
    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        ЗАЯВКА НА ЛКС <span v-if="createdTicketId"><b>#{{ createdTicketId }}</b></span> УСПЕШНО СОЗДАНА
      </success-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import router from "@/router";

export default {
  name: 'CreateTicketLCSFormView',
  components: {
    SuccessModal,
    CustomModal,
  },
  props: {
    parentTicketId: {
      type: Number,
      default: 0
    },
    ticket: {
      type: Object,
      default: () => {
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newTicket: {
        related_ticket_id: 0,
        type_id: null,
        client_full_name: '',
        client_phone: '',
        author_full_name: '',
        author_phone: '',
        description: '',
        commutators: []
      },
      tempNodes: '',
      isSuccessModal: false,
      createdTicketId: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user'
    }),
    authorInfo() {
      if (!this.newTicket.author_full_name) return ''
      const departmentTitle = this.ticket?.author?.department?.title
      return departmentTitle ? `${this.newTicket.author_full_name} (${departmentTitle})` : this.newTicket.author_full_name
    }
  },
  created() {
    this.newTicket.client_full_name = this.ticket?.client?.full_name || this.ticket?.connection_client?.name
    this.newTicket.client_phone = this.ticket?.client?.phone || this.ticket?.connection_client?.phone_number
    this.newTicket.author_full_name = this.ticket?.author?.full_name || this.currentUser.full_name
    this.newTicket.author_phone = this.ticket?.author?.phone || this.currentUser.phone

    if (this.ticket?.commutators?.length) {
      this.newTicket.commutators = this.ticket?.commutators
      this.ticket.commutators.forEach((commutator, index) => {
        if (commutator.port_count === commutator.ports.length) {
          this.tempNodes += commutator.commutator_name
        } else {
          this.tempNodes += `${commutator.commutator_name} [${[...commutator.ports]}]`
        }
        if (index < this.ticket?.commutators?.length - 1) {
          this.tempNodes += `,`
        }
        commutator.name = commutator.commutator_name
        commutator.port_names = commutator.ports
      })
    }
  },
  methods: {
    ...mapActions({
      createTicketLCS: 'createTicketLCS',
      createConnectionRelatedLCSTicket: 'createConnectionAccessRelatedTicket'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push(`/tickets-lcs`)
    },
    clearData() {
      this.newTicket = {
        related_ticket_id: 0,
        type_id: null,
        client_full_name: '',
        client_phone: '',
        author_full_name: '',
        author_phone: '',
        description: '',
        commutators: []
      }
    },
    async submitForm() {
      if (this.type === 'connection') {
        await this.createConnectionRelatedLCSTicket({ id: +this.parentTicketId, description: this.newTicket.description, type: 'lks',}).then(response => {
          if (response.success) {
            this.createdTicketId = response.data.ticket?.ticket_number
            this.$toast.success('Заявка на ЛКС успешно создана')
            this.toggleSuccessModal()
            this.clearData()
          } else {
            this.$toast.error('Не удалось создать заявку на ЛКС')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        })
      } else {
        const obj = {
          description: this.newTicket.description,
          ...(this.ticket?.connection_client ? { connection_ticket_id: +this.parentTicketId} : { related_ticket_id: +this.parentTicketId})
        }
        await this.createTicketLCS(obj).then(response => {
          if (response.success) {
            this.createdTicketId = response.data.ticket?.ticket_number
            this.$toast.success('Заявка на ЛКС успешно создана')
            this.toggleSuccessModal()
            this.clearData()
          } else {
            this.$toast.error('Не удалось создать заявку на ЛКС')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        })
      }
    },
  },
  unmounted() {
    this.newTicket = {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
