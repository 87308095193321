<template>
  <div class="flex column">
    <div class="modal_title flex align-center mb-4">
      <h3 class="text-black2">{{title}}</h3>
    </div>
    <div class="w-100 p-20 bg-gray rounded">
      <div
        class="card rounded bg-white flex align-center justify-between cursor-pointer text-black2 mb-1"
        v-for="item in array"
        :key="item.id"
        :class="{'active': item.id === selected}"
        @click="itemClick(item.id)"
      >
        <p>{{ item[itemTitle] }}</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'OptionModal',
  props: ['array', 'selected', 'title', 'itemTitle'],
  methods: {
    itemClick(id) {
      this.$emit('itemClick', id)
    }
  },
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.card.active,
.card:hover {
  background: linear-gradient(91deg, #0095FF 1.98%, #00557B 101.6%);
  color: $white;
}

</style>
