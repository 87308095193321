<template>
    <div class="map flex column align-center">
        <div v-if="hasCoordinates" class="inner flex column align-center">
            <yandex-map :coordinates="mapCenter" :zoom="10" :controls="['rulerControl', 'zoomControl', 'searchControl']"
                @created="mapInit" :width="'100%'" :height="400">
                <yandex-marker :marker-id="'marker'" :coordinates="markerCoordinates" :options="{
                    iconColor: '#FF0000',
                    preset: 'islands#circleIcon',
                }" />
            </yandex-map>
        </div>
        <div v-else>Координаты не указаны</div>
    </div>
</template>

<script>
import { YandexMap, YandexMarker, loadYmap } from "vue-yandex-maps";

export default {
    name: 'QueriesTCMapComponent',
    components: {
        YandexMap,
        YandexMarker
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        defaultCenter: {
            latitude: 43.242411,
            longitude: 76.904358,
        },
        yandexMap: null,
    }),
    computed: {
        hasCoordinates() {
            return !!(this.item.latitude && this.item.longitude);
        },
        markerCoordinates() {
            return [this.item.latitude, this.item.longitude];
        },
        mapCenter() {
            if (this.hasCoordinates) {
                return [this.item.latitude, this.item.longitude];
            }
            return [this.defaultCenter.latitude, this.defaultCenter.longitude];
        }
    },
    methods: {
        async mapInit(mapInstance) {
            if (mapInstance) {
                this.yandexMap = mapInstance;
                await loadYmap({ lang: "en_US" });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;

  .inner {
    width: 100%;
  }

  yandex-map {
    width: 100%;
    height: 400px;
  }
}
</style>