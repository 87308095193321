<template>
  <div v-if="wikiPageForEdit">
    <div class="wrapper flex column ">
      <form @submit.prevent="createWiki" class="flex justify-around gap-2 align-center  bg-white rounded p-20">
        <div>
          <div>
            <label>Название<span class="text-red">*</span></label>
            <input class="input-form mt-1" type="text" v-model="wikiPageForEdit.title">
          </div>
          <div class="mt-2">
            <label for="">Описание</label>
            <input class="input-form mt-1" type="text" v-model="wikiPageForEdit.description">
          </div>
          <div class="flex gap-2 mt-4">
            <button class="btn btn-green" @click.prevent="editWiki">Сохранить</button>
            <button class="btn btn-secondary" @click.prevent="$router.push('/instructions')">Отмена</button>
          </div>
        </div>
        <div class="flex flex-column justify-between gap-2" style="flex-flow: column;">
          <button type="button" class="block btn-emergency" @click="addBlock">Добавить блок</button>
          <button type="button" class="block btn btn-status w-100" @click="isPreview = !isPreview">Preview</button>
        </div>
      </form>

      <div class="mt-4" v-for="el in wikiPageForEdit.blocks" :key="el.id">
        <WikiBlock :id="el.id" :blocks="el" :disabled="false" @editBlock="editBlock" @deleteBlock="deleteBlock"
                   @saveBlock="createBlock"></WikiBlock>
      </div>
    </div>
    <custom-modal :custom-class="'comments-full'" v-if="isPreview" @close="isPreview = !isPreview">
      <instruction-modal></instruction-modal>
    </custom-modal>
  </div>
</template>

<script>
import WikiBlock from "@/components/wiki/WikiBlock.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import InstructionModal from "@/components/modals/instructions/InstructionModal.vue";

export default {
  name: "InstructionEdit",
  components: {InstructionModal, CustomModal, WikiBlock},
  async created() {
    this.setLoading(true)
    await this.fetchWikiDetail(this.$route.params.id);
    await this.fetchWikiBlockTypes()
    this.wikiPageForEdit = this.wikiPage;
    this.setLoading(false)
  },
  mounted() {

  },
  data() {
    return {
      wikiPageForEdit: null,
      isPreview: false,
    }
  },
  computed: {
    ...mapGetters({
      wikiPage: 'wikiPage',
      blockTypes: 'wikiBlockTypes'
    })
  },
  methods: {
    ...mapActions({
      fetchWikiDetail: 'fetchWikiPage',
      deleteWikiBlock: 'deleteWikiBlock',
      editWikiPage: 'editWikiPage',
      editWikiBlocks: 'editWikiBlocks',
      createWikiBlocks: 'createWikiBlock',
      fetchWikiBlockTypes: 'fetchWikiBlockTypes',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    addBlock() {
      const uniqueId = Date.now().toString();
      this.wikiPageForEdit.blocks.push({id: uniqueId});
    },
    async deleteBlock(blockId) {
      if (typeof blockId === 'number') {
        const obj = {id: blockId}
        await this.deleteWikiBlock(obj).then((response) => {
          if (response.success) {
            this.$toast.success('Блок успешно удален')
            this.wikiPageForEdit.blocks = this.wikiPageForEdit.blocks.filter(block => block.id !== blockId);
          } else {
            this.$toast.error('Не удалось удалить блок')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        });
      } else {
        this.wikiPageForEdit.blocks = this.wikiPageForEdit.blocks.filter(block => block.id !== blockId);
      }
    },
    async editBlock(blockValue) {
      const formData = new FormData();
      formData.append(`blocks[0][id]`, blockValue.id);
      formData.append(`blocks[0][title]`, blockValue.title);
      formData.append(`blocks[0][description]`, blockValue.description);
      if (blockValue.attached_files) {
        blockValue.attached_files.forEach((file, fileIndex) => {
          formData.append(`blocks[0][files][${fileIndex}]`, file);
        });
      }

      await this.editWikiBlocks(formData).then(res => {
        if (res.success) {
          this.$toast.success('Блок успешно изменен!')
          this.fetchWikiDetail(this.$route.params.id).then(() => {
            this.wikiPageForEdit = this.wikiPage;
          });
        } else {
          this.$toast.error('Не удалось изменить данные!')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async editWiki() {
      const obj = {
        id: this.wikiPageForEdit.id,
        title: this.wikiPageForEdit.title,
        description: this.wikiPageForEdit.description,
      };
      await this.editWikiPage(obj).then(res => {
        if (res.success) {
          this.$toast.success('Данные успешно изменены')
        } else {
          this.$toast.error('Не удалось изменить данные')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async createBlock(blockValue) {
      const block = {
        ...blockValue, 
        wiki_block_type_id: this.blockTypes[0].id, 
        wiki_page_id: this.wikiPageForEdit.id
      };

      const existingBlock = this.wikiPageForEdit.blocks.find(b => b.id === block.id);
      if (existingBlock) {
        await this.editBlock(block);
        return;
      }

      try {
        const formData = new FormData();
        formData.append(`blocks[0][title]`, block.title);
        formData.append(`blocks[0][description]`, block.description);
        formData.append(`blocks[0][wiki_block_type_id]`, block.wiki_block_type_id);
        formData.append(`blocks[0][wiki_page_id]`, block.wiki_page_id);
        if (block.attached_files) {
          block.attached_files.forEach((file, fileIndex) => {
            formData.append(`blocks[0][files][${fileIndex}]`, file);
          });
        }

        const res = await this.createWikiBlocks(formData);
        if (res.success) {
          await this.fetchWikiDetail(this.$route.params.id);
          this.wikiPageForEdit = this.wikiPage;
          this.$toast.success('Блок успешно добавлен');
        } else {
          this.$toast.error('Не удалось добавить блок');
        }
      } catch (err) {
        this.$toast.error(`Ошибка: ${err.message}`);
      }
    }
  },
}
</script>
<style scoped lang="scss">

</style>