<template>
  <div class="wrapper modified flex column">
    <div class="bg-white rounded p-20">
      <div class="flex justify-between">
        <div style="width: 320px">
          <button
              class="btn"
              type="button"
              @click="isDepartments = false"
          >
            Роли
          </button>
        </div>
        <div style="width: 320px">
          <button
              class="btn"
              type="button"
              @click="isDepartments = true"
          >
            Отделы
          </button>
        </div>
      </div>
      <div class="mt-3" v-if="!isDepartments">
        <div style="width: 220px" class="mb-2">
          <button
              class="btn btn-create flex align-center justify-center w-max-content"
              type="button"
              @click="createNewRole"
          >
            <img src="/assets/plus.svg" alt=""/>Создать роль
          </button>
        </div>
        <div class="grid" style="background-color: lightgray;">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-name">
            <p>Роль</p>
          </div>
          <div class="grid-key">
            <p>Ключ</p>
          </div>
          <div class="grid-departments">
            Отделы
          </div>
          <div class="grid-tech">
            <p>ТО</p>
          </div>
          <div class="grid-options">
            <p>Управление</p>
          </div>
        </div>
        <div class="roles-table">
          <div class="grid" v-for="(role, index) in allRoles" :key="role">
            <div> {{ index + 1 }}</div>
            <div>{{ role.title }}</div>
            <div>
              <p>{{ role.key }}</p>
            </div>
            <div>
              <div v-for="department in role.departments" :key="department">
                {{department.title}}
              </div>
            </div>
            <div>
              <div v-for="department in role.departments" :key="department">
                {{department.is_tech_dep ? 'Да' : 'Нет'}}
              </div>
            </div>
            <div class="roles-options">
              <img src="/assets/list.svg" alt="edit" width="16" height="16" @click="editRole(role)" title="Редактировать роль">
              <img 
                src="/assets/pages-access.svg" 
                alt="pages" 
                width="16" 
                height="16" 
                @click="$router.push(`/roles/pages/${role.id}`)" 
                title="Управление доступом к страницам"
              >
              <img src="/assets/roles/book-copy.svg" alt="copy" width="16" height="16" @click="createNewRoleWithExistingKey(role)" title="Создать роль с этими же разрешениями"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="isDepartments">
        <roles-departments></roles-departments>
      </div>
    </div>
  </div>
  <custom-modal v-if="isCreateRoleModal" @close="isCreateRoleModal = !isCreateRoleModal">
    <CreateRoleModal @close="closeModal" :role-key="currentRole.key"></CreateRoleModal>
  </custom-modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import CreateRoleModal from "@/components/modals/roles/CreateRoleModal.vue";
import RolesDepartments from "@/views/roles/RolesDepartments.vue";

export default {
  name: "RolesView",
  components: {RolesDepartments, CreateRoleModal, CustomModal},
  async created() {
    this.setLoading(true)
    await this.fetchAllRoles();
    this.setLoading(false)
  },
  data() {
    return {
      isCreateRoleModal: false,
      currentRole: [],
      isDepartments: false,
    }
  },
  computed: {
    ...mapGetters({
      allRoles: 'allRoles',
    })
  },
  methods: {
    ...mapActions({
      fetchAllRoles: 'fetchAllRoles',
      fetchRolePermissions: 'fetchRolePermissions',
      deleteRole: 'deleteRole'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentRole: 'setCurrentRole'
    }),
    editRole(role) {
      this.setCurrentRole(role)
      this.$router.push('/roles/' + role.key)
    },
    editRolePages(role) {
      this.setCurrentRole(role)
      this.$router.push('/roles/' + role.key + '/pages')
    },
    createNewRoleWithExistingKey(role){
      this.currentRole = role
      this.isCreateRoleModal = !this.isCreateRoleModal
    },
    createNewRole() {
      this.isCreateRoleModal = true
      this.currentRole = {}
    },
    async deleteNewRole(role) {
      this.setLoading(true)
      await this.deleteRole({id: role.id}).then((res) => {
        if (res.success) {
          this.$toast.success('Роль успешно удалена')
          this.fetchAllRoles()
        } else {
          this.$toast.error('Не удалось удалить роль')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
      this.setLoading(false)
    },
    async closeModal() {
      this.setLoading(true)
      this.isCreateRoleModal = false
      await this.fetchAllRoles()
      this.setLoading(false)
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr 3fr 3fr 1fr 1fr;
  grid-template-areas: 'Num Name Key Departments Tech Options';
}

.grid-num {
  grid-area: Num;
}

.grid-name {
  grid-area: Name;
}

.grid-key {
  grid-area: Key;
}

.grid-departments {
  grid-area: Departments;
}

.grid-tech {
  grid-area: Tech;
}

.grid-options {
  grid-area: Options;
}

.roles-table {
  :hover {
    background-color: #F5F5FA;
  }
}

.roles-options {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}
</style>