<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="details.connection_ticket">
      <div class="flex justify-between mb-2">
        <div class="flex align-center">
          <h2 class="text-black2 mr-2">ЗАЯВКА <b>#{{ details.connection_ticket.ticket_number }}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="details.connection_ticket.connection_status"
               @click="toggleAddressModal">
            <img
                :src="details.connection_ticket.connection_status.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
                alt="">
            {{ details.connection_ticket.connection_status.title }}
          </div>
        </div>
        <div>
          <img height="24" width="24" class="block cursor-pointer" @click="$router.go(-1)" src="/assets/close.svg" alt=""/>
        </div>
      </div>
      <div class="w-100 flex justify-between">
        <div class="flex column mb-4">
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Создана: </p>
            <p class="text-14 text-black2" v-if="details.connection_ticket.created_at">
              {{ details.connection_ticket.created_at }}</p>
          </div>
          <div class="flex">
            <p class="text-14 text-gray mr-1">Подключение: </p>
            <p class="text-14 link" v-if="details.connection_ticket.planned_date" @click="toggleChangeDateModal">
              {{ details.connection_ticket.planned_date }}</p>
          </div>
        </div>
      </div>
      <div class="w-100 ticket-kind text-center text-black2 mb-2">
        ДОПРОДАЖА
      </div>
      <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" @tab-click="changeTab"/>
      <div>
        <div class="info-block" v-if="activeTab === 'info'">
          <div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Наименование компании:</p>
              <p class="text-gray5 text-right text-14">
                {{ details?.connection_ticket?.connection_client?.name || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2c'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">ФИО клиента:</p>
              <p class="text-gray5 text-right text-14">
                {{ details?.connection_ticket?.connection_client?.contact_person || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2c'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">ИИН:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">
                  {{ details?.connection_ticket?.connection_client?.iin || ' - ' }}</p>
                <img class="ml-1 cursor-pointer"
                     @click="copyToClipboard(details?.connection_ticket?.connection_client?.iin)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">БИН:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">
                  {{ details?.connection_ticket?.connection_client?.iin || ' - ' }}</p>
                <img class="ml-1 cursor-pointer"
                     @click="copyToClipboard(details?.connection_ticket?.connection_client?.iin)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">№ Договора:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.contract_number || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Контактное лицо:</p>
              <p class="text-gray5 text-right text-14">
                {{ details?.connection_ticket?.connection_client?.contact_person || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Контактный номер:</p>
              <p class="text-gray5 text-right text-14">
                {{ details?.connection_ticket?.connection_client?.phone_number || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Адрес подключения услуг:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{ details?.connection_ticket?.address.address || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="toggleAddressModal" src="/assets/MapPinLine.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Координаты подключения:</p>
              <div class="text-gray5 text-right text-14">
                <div>{{ details?.connection_ticket?.address?.latitude || ' - ' }}</div>
                <div>{{ details?.connection_ticket?.address?.longitude || ' - ' }}</div>
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Источник:</p>
              <p class="text-gray5 text-right text-14">
                {{ details?.connection_ticket?.connection_source?.title || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Id процесса:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.bp_id || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">ID сделки:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.deal_id || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b'"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Подключение от компании:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.contractor || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Менеджер, ответственный за процесс:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.manager?.full_name || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket?.responsible">
              <p class="text-black bold text-14">Ответственный</p>
              <p class="text-gray5 text-right text-14">{{details?.connection_ticket?.responsible?.title || ' - '}} {{details.connection_ticket?.responsible?.department?.title ? `(${details.connection_ticket?.responsible?.department?.title})` : ''}}</p>
            </div>
            <div class="mb-2">Сведения об услугах</div>
<!--            <div class="card bg-white rounded"-->
<!--                 v-if="details?.connection_ticket?.services && details?.connection_ticket?.services.length">-->
<!--              <div v-for="(service, index) in details?.connection_ticket?.services" :key="service?.id"-->
<!--                   class="flex" style="gap: 40px;padding: 4px;">-->
<!--                <div class="flex w-50">-->
<!--                  <p class="text-gray5 mr-1">{{ index + 1 }})</p>-->
<!--                  <div class="flex gap-1">-->
<!--                    <p class="text-black bold">Название услуги:</p>-->
<!--                    <p class="text-gray5 ">{{ service?.title || ' - ' }}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="flex gap-1 w-30">-->
<!--                  <p class="text-black bold">Тип услуги:</p>-->
<!--                  <p class="text-gray5 ">{{ service?.type || ' - ' }}</p>-->
<!--                </div>-->
<!--                <div class="flex gap-1 w-30">-->
<!--                  <p class="text-black bold">Стоимость:</p>-->
<!--                  <p class="text-gray5 ">{{ service?.price || ' - ' }}</p>-->
<!--                </div>-->
<!--                <div class="flex gap-1">-->
<!--                  <p class="text-black bold">Количество:</p>-->
<!--                  <p class="text-gray5">{{ service?.pivot.quantity || ' - ' }}</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="card bg-white rounded"
                 v-if="details?.connection_ticket?.products && details?.connection_ticket?.products.length">
              <div v-for="(service, index) in details?.connection_ticket?.products" :key="service?.id"
                   class="flex" style="gap: 40px;padding: 4px;">
                <div class="flex w-50">
                  <p class="text-gray5 mr-1">{{ index + 1 }})</p>
                  <div class="flex gap-1">
                    <p class="text-black bold">Оборудование:</p>
                    <p class="text-gray5 ">{{ service?.title || ' - ' }}</p>
                  </div>
                </div>
                <div class="flex gap-1 w-30">
                  <p class="text-black bold">Стоимость:</p>
                  <p class="text-gray5 ">{{ service?.price || ' - ' }}</p>
                </div>
                <div class="flex gap-1">
                  <p class="text-black bold">Количество:</p>
                  <p class="text-gray5">{{ service?.pivot.quantity || ' - ' }}</p>
                </div>
              </div>
            </div>
            <div v-else class="card bg-white rounded flex align-center text-gray5 text-right text-14">Отсутствует</div>
          </div>
        </div>
        <div class="info-block" v-if="activeTab === 'tech'">
          <div style="overflow: visible">
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.ip_address"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">IP адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.ip_address }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.ip_address)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.connection_ticket_type?.title"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Метод подключения:</p>
              <multiselect :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)" v-model="connectionMethod" :options="connectionTypes" label="type"
                           track-by="id" placeholder="Выберите метод" class="mt-1"  style="overflow: visible; width: 400px" @select="onChangeConnectionType"></multiselect>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2c'"
                 class="card bg-white rounded flex align-center justify-between relative">
              <p class="text-black bold text-14">Тип подключения:</p>
              <button class="link relative" style="background: transparent; border: none" :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)" @click="isChangeConnectionType = !isChangeConnectionType">
                {{ renameConnectionType }}</button>
              <div v-if="isChangeConnectionType" class="fiber_window">
                <p class="cursor-pointer fiber_type" @click="onChangeConnectionType('optic')">Оптика</p>
                <p class="cursor-pointer fiber_type" @click="onChangeConnectionType('copper')">Медь</p>
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2c' && details?.connection_ticket?.host?.location"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Хост:</p>
              <p class="text-14 w-50 text-right relative link" @click="getHosts(details?.connection_ticket?.node_id)">
                {{ details?.connection_ticket?.host?.location || "Не указано" }}</p>
              <div v-if="isChangeHosts && departureInfoHosts.length > 0" class="fiber_window"
                   style="height: 120px; overflow: scroll; bottom: -120px;">
                <div class="cursor-pointer fiber_type" style="width: 400px" v-for="host in departureInfoHosts"
                     :key="host.id">
                  <p @click="changeHosts(host.id)">{{ host.location }}</p>
                </div>
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2c' && details?.connection_ticket?.communications_facility"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Сооружение связи:</p>
              <p class="text-gray5 text-right text-14">{{
                  details?.connection_ticket?.communications_facility || ' - '
                }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.gateway"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Gateway:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.gateway || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.dns1"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">DNS1:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.dns1 || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.dns2"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">DNS2:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.dns2 || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.mask"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Mask:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.mask || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.node_name"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Узел подключения:</p>
              <div class="flex align-center gap-2">
                <input class="input input-form"
                       style="width: 200px"
                       v-model="details.connection_ticket.node_name"
                       placeholder="Узел подключения"
                       type="text"
                       :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)"
                />
                <button :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)" class="btn btn-gray" @click="onChangeConnectionNode">Сохранить</button>
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.qinq_vlan"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">QINQ VLAN:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.qinq_vlan || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.node_name" class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Коммутатор:</p>
              <multiselect :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)" v-model="selectedCommutator" :options="commutatorsList" label="device_name"
                           track-by="id" placeholder="Выберите коммутатор" class="mt-1"  style="overflow: visible; width: 400px" @select="onChangeCommutator"></multiselect>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.node_name" class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Порт:</p>
              <multiselect :disabled="[6, 7].includes(details.connection_ticket?.connection_status?.id)" v-model="selectedPort" :options="commutatorPorts" label="name"
                           track-by="id" placeholder="Выберите порт" class="mt-1"  style="overflow: visible; width: 400px" @select="onChangePort"></multiselect>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.equipment"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Необходимое оборудование:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.equipment || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.login_ppoe"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Логин PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.login_ppoe || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.login_ppoe)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.password_ppoe "
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Пароль PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.password_ppoe || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.password_ppoe)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.sip_number"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">SIP номер:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.sip_number || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.sip_number)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.login_sip"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Login SIP:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.login_sip || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.login_sip)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.password_sip"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Password SIP:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.password_sip || ' - ' }}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details?.connection_ticket?.password_sip)"
                     src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.deal_id"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Запрос ТВ ссылка:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.deal_id || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.router_sks"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">Роутер:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.router_sks || ' - ' }}</p>
            </div>
            <div v-if="this.details.connection_ticket?.client_type === 'b2b' && details?.connection_ticket?.sks"
                 class="card bg-white rounded flex align-center justify-between">
              <p class="text-black bold text-14">СКС:</p>
              <p class="text-gray5 text-right text-14">{{ details?.connection_ticket?.sks || ' - ' }}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details?.connection_ticket?.tv_ticket?.id">
              <p class="text-black bold text-14">Запрос на ТВ</p>
              <p class="text-gray5 text-right text-14 link" @click="goToTV(details.connection_ticket.tv_ticket.id)">{{details?.connection_ticket?.tv_ticket?.id}}</p>
            </div>
          </div>
        </div>
        <comments-tab
            v-if="activeTab === 'comment'"
            :comments="ticketComments"
            :totalComments="totalComments"
            @addComment="addComment"
            @loadComments="loadComments"
            @openImage="toggleImageModal"
        />
        <history-tab
            v-if="activeTab === 'history'"
            :history="details.logs"
        />
      </div>
      <div class="w-100 ticket-kind text-center text-black2 mb-2 mt-2">
        СВЯЗАННЫЕ ЗАЯВКИ
      </div>
      <custom-table
          :columns="RelatedTicketsShortColumns"
          :data="relatedTickets"
          :current-page="1"
          :is-pagination="false"
          @get-detail="getTicketLCSDetail"
      />
    </div>
    <div class="actions flex flex-wrap">
      <button class="actions-buttons mr-2 actions-buttons_info"
              @click="isAccessFormModal = true">Создать связанную заявку на доступ
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info"
              @click="isLCSFormModal = true">Создать связанную заявку на ЛКС
      </button>
      <button class="actions-buttons mr-2 actions-buttons_info" v-if="details.connection_ticket.connection_status.id !== 7 || details.connection_ticket.connection_status.id !== 6"
              @click="isDisassemblyBrigadeAssignModal = !isDisassemblyBrigadeAssignModal">Назначить на бригаду
      </button>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
    <custom-modal v-if="isAddressModal" @close="toggleAddressModal">
      <map-modal title="АДРЕС ПОДКЛЮЧЕНИЯ" :latitude="details.connection_ticket.address.latitude"
                 :longitude="details.connection_ticket.address.longitude" @close="toggleAddressModal"/>
    </custom-modal>
    <custom-modal v-if="isChangeDateModal" @close="toggleChangeDateModal">
      <change-connection-date-modal @change-date="onChangeConnectionDate"/>
    </custom-modal>
<!--    <custom-modal v-if="isChangeConnectionType" @close="toggleChangeConnectionType">-->
<!--      <change-connection-type-modal @change-type="onChangeConnectionType"/>-->
<!--    </custom-modal>-->
    <custom-modal v-if="isAccessFormModal" @close="isAccessFormModal = !isAccessFormModal">
      <access-form-view :parent-ticket-id="+details.connection_ticket.id" :ticket="details.connection_ticket"
                        :type="'connection'"></access-form-view>
    </custom-modal>
    <custom-modal v-if="isLCSFormModal" @close="isLCSFormModal = !isLCSFormModal">
      <CreateTicketLCSFormView :parent-ticket-id="+details.connection_ticket.id" :ticket="details.connection_ticket"
                               :type="'connection'"></CreateTicketLCSFormView>
    </custom-modal>
    <custom-modal v-if="isDisassemblyBrigadeAssignModal"
                  @close="isDisassemblyBrigadeAssignModal = !isDisassemblyBrigadeAssignModal">
      <disassembly-brigade-assign-modal @submit="assignBrigade"></disassembly-brigade-assign-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import HistoryTab from "@/components/tabs/HistoryTab.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import ChangeConnectionDateModal from "@/components/modals/changeConnection/ChangeConnectionDateModal.vue";
// import ChangeConnectionTypeModal from "@/components/modals/changeConnection/ChangeConnectionTypeModal.vue";
import MapModal from "@/components/modals/MapModal.vue";
import CustomTable from "@/components/modules/CustomTable.vue";
import {RelatedTicketsShortColumns} from "@/modules/table-columns";
import AccessFormView from "@/views/accessData/AccessFormView.vue";
import CreateTicketLCSFormView from "@/views/ticketsLCS/CreateTicketLCSFormView.vue";
import router from "@/router";
import DisassemblyBrigadeAssignModal from "@/components/modals/disassembly/DisassemblyBrigadeAssingModal.vue";
import Multiselect from "vue-multiselect";

export default {
  name: 'UpsellsTicketDetailsComponent',
  components: {
    Multiselect,
    DisassemblyBrigadeAssignModal,
    CreateTicketLCSFormView, AccessFormView,
    CustomTable,
    // ChangeConnectionTypeModal,
    MapModal,
    TabsComponent,
    HistoryTab,
    CommentsTab,
    ImageModal,
    CustomModal,
    ChangeConnectionDateModal,
  },
  props: [],
  async created() {
    await this.fetchConnectionMethods()
    await this.fetchConnectionTypes()
    if(this.details?.connection_ticket?.node_name) {
      await this.fetchCommutatorsList({search: this.details?.connection_ticket?.node_name})
    }
    const currentMethod = this.details?.connection_ticket?.type_key ? this.details?.connection_ticket?.type_key : this.details?.connection_ticket?.connection_type?.type ? this.details?.connection_ticket?.connection_type?.type : this.details?.connection_ticket?.connection_ticket_type?.title
    this.connectionMethod = {id: currentMethod, type: currentMethod}
    this.selectedCommutator = {id: this.details?.connection_ticket?.commutator, device_name: this.details?.connection_ticket?.commutator}
    this.selectedPort = {id: this.details?.connection_ticket?.port, name: this.details?.connection_ticket?.port}
  },
  data() {
    return {
      RelatedTicketsShortColumns,
      tabs: [
        {
          title: 'Инфо',
          icon: '/assets/tabs/info-gray.svg',
          icon_active: '/assets/tabs/info-white.svg',
          key: 'info'
        },
        {
          title: 'тех.данные',
          icon: '/assets/tabs/wrench-gray.svg',
          icon_active: '/assets/tabs/wrench-white.svg',
          key: 'tech'
        },
        {
          title: 'Комментарии',
          icon: '/assets/tabs/comment-gray.svg',
          icon_active: '/assets/tabs/comment-white.svg',
          key: 'comment'
        },
        {
          title: 'ИСТОРИЯ',
          icon: '/assets/tabs/clock-gray.svg',
          icon_active: '/assets/tabs/clock-white.svg',
          key: 'history'
        },
      ],
      activeTab: 'info',
      image: '',
      isChangeDateModal: false,
      isChangeConnectionType: false,
      isAddressModal: false,
      isAccessFormModal: false,
      isLCSFormModal: false,
      isChangeHosts: false,
      isDisassemblyBrigadeAssignModal: false,
      connectionMethod: {},
      selectedCommutator: null,
      selectedPort: null,
    }
  },
  computed: {
    ...mapGetters('queriesTCModule', {
      connectionMethods: "connectionMethods",
    }),
    ...mapGetters({
      details: "chosenConnectionTicket",
      currentCommentPage: 'currentConnectionTicketCommentsPage',
      ticketComments: 'connectionTicketComments',
      totalComments: 'totalConnectionTicketComments',
      relatedTickets: 'relatedTickets',
      departureInfoHosts: 'departureInfoHosts',
      commutatorsList: 'commutatorsList',
      connectionTypes: 'connectionTypes'
    }),
    renameConnectionType() {
      if (this.details?.connection_ticket?.connection_type.key === 'optic') {
        return 'Оптика'
      } else {
        return 'Медь'
      }
    },
    commutatorPorts() {
      const list = this.commutatorsList.find((item) => item?.device_name === this.selectedCommutator?.device_name)
      if(!this.commutatorsList.length){
        return []
      } else if (list) {
        return list?.iface_poller_data
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('queriesTCModule', {
      fetchConnectionMethods: 'fetchConnectionMethods',
    }),
    ...mapActions({
      fetchTicketDetail: 'fetchConnectionTicketDetail',
      fetchTicketComments: 'fetchConnectionTicketComments',
      addTicketComments: 'addConnectionTicketComments',
      changeConnectionDate: 'changeConnectionDate',
      changeConnectionType: 'changeConnectionType',
      error: 'errorAlert',
      fetchDepartureInfoHosts: 'fetchDepartureInfoHosts',
      changeDepartureInfoHost: 'changeDepartureInfoHost',
      changeDisassemblyBrigade: 'changeDisassemblyBrigade',
      fetchCommutatorsList: 'fetchCommutatorsList',
      fetchConnectionTypes: 'fetchConnectionTypes',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
    }),
    async getHosts(id) {
      this.isChangeHosts = !this.isChangeHosts
      if (this.isChangeHosts) {
        await this.fetchDepartureInfoHosts({id: id}).then(response => {
          this.isChangeHosts = true
        })
      }
    },
    async changeHosts(hostId) {
      await this.changeDepartureInfoHost({id: this.details?.connection_ticket.id, host_id: hostId}).then(response => {
        if (response.success) {
          this.$toast.success('Хост был успешно изменен')
          this.isChangeHosts = false
        } else {
          this.$toast.error('Не удалось сменить хост')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    changeTab(tab) {
      this.activeTab = tab
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({connection_ticket_id: this.details.connection_ticket.id, page: this.currentCommentPage})
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    toggleChangeDateModal() {
      this.isChangeDateModal = !this.isChangeDateModal
    },
    toggleChangeConnectionType() {
      this.isChangeConnectionType = !this.isChangeConnectionType
    },
    toggleAddressModal() {
      this.isAddressModal = !this.isAddressModal
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    addComment(comment) {
      comment.connection_ticket_id = this.details.connection_ticket.id
      this.addTicketComments(comment)
    },
    async onChangeConnectionType(type) {
      const obj = {
        id: this.details.connection_ticket.id,
        type_key: type.key ? type.key : type
      }
      this.setLoading(true)
      await this.changeConnectionType(obj)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.isChangeConnectionType = false
      this.setLoading(false)
    },
    async onChangeConnectionDate(data) {
      data.id = this.details.connection_ticket.id
      this.toggleChangeDateModal()
      this.setLoading(true)
      await this.changeConnectionDate(data)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    async onChangeConnectionNode() {
      const obj = {
        id: this.details.connection_ticket.id,
        node_name: this.details.connection_ticket.node_name
      }
      this.setLoading(true)
      await this.changeConnectionType(obj)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.isChangeConnectionType = false
      this.setLoading(false)
    },
    async onChangeCommutator(type) {
      const obj = {
        id: this.details.connection_ticket.id,
        commutator: type?.device_name
      }
      this.setLoading(true)
      await this.changeConnectionType(obj)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.selectedPort = {}
      this.setLoading(false)
    },
    async onChangePort(type) {
      const obj = {
        id: this.details.connection_ticket.id,
        port: type?.name
      }
      this.setLoading(true)
      await this.changeConnectionType(obj)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    async getTicketLCSDetail(ticket) {
      this.setLoading(true)
      if (ticket.ticket.ticket_kind.key === 'lks') {
        router.push(`/ticket-lcs/${ticket.ticket?.ticket_number}`)
      } else if (ticket.ticket.ticket_kind.key === 'commutation') {
        router.push(`/commutation-tickets/${ticket.ticket?.ticket_number}`)
      } else if (ticket.ticket.ticket_kind.key === 'preparation') {
        router.push(`/preparing-connections-tickets/${ticket.ticket?.ticket_number}`)
      } else if (ticket.ticket.ticket_kind.key === 'rework') {
        router.push(`/connection-improvements-tickets/${ticket.ticket?.ticket_number}`)
      } else {
        router.push(`/ticket/${ticket.ticket?.ticket_number}`)
      }
      this.setLoading(false)
    },
    async assignBrigade(id) {
      const payload = {
        id: this.details.connection_ticket.id,
        brigade_id: id
      }
      this.setLoading(true)
      await this.changeDisassemblyBrigade(payload).then(res => {
        if (res.success) {
          this.$toast.success('Бригада успешно назначена!')
          this.setLoading(false)
          this.isDisassemblyBrigadeAssignModal = false
        } else {
          this.setLoading(false)
          this.$toast.error('Не удалось назначить бригаду!')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
        this.setLoading(false)
      })
    },
    goToTV(id){
      this.$router.push(`/queries-technical-capability/${id}`)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  border-radius: 15px;
  background: $gray4;
  padding: 20px;


  & > div {
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;

    .images {
      img {
        width: 50px;
        height: 50px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > p:first-child {
      width: 35%;
    }
  }
}

.option {
  color: $black2;
  height: 58px;

  &.active,
  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}

.actions {
  margin-top: 28px;

  &-buttons {
    background: #04567B;
    color: white;
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 25px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_info {
      background: #04567B;
    }

    &_departure {
      background: #57C2DC;
    }

    &_waiting {
      background: #0095FF;
    }

    &_emergency {
      background: #FF6700;
    }

    &_complete {
      background: #15AD37;
    }
  }
}
.fiber_window {
  position: absolute; right: 0;z-index: 2; bottom: -80px;  border: 1px solid gray;
  border-radius: 4px;filter: drop-shadow(2px 2px 2px #000000);
  background: white;
}

.fiber_type {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid gray;
  width: 200px;
  &:hover {
    background: gray;
    transition: 200ms;
  }
}
</style>
