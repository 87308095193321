import {api} from '@/service/axios'
import {getIndexById} from '@/helpers/utils'

export const areasModule = {
    state: () => ({
        authorsList: [],
        areas: [],
        areasTotalPages: 0,
        totalAreas: 0,
        areaData: {},
        ticketCountsByArea: [],
    }),
    getters: {
        authorsList: state => state.authorsList,
        areas: state => state.areas,
        areasTotalPages: state => state.areasTotalPages,
        totalAreas: state => state.totalAreas,
        areaData: state => state.areaData,
        ticketCountsByArea: state => state.ticketCountsByArea,
    },
    mutations: {
        setAuthorsList (state, payload) {
            return state.authorsList = payload
        },
        setAreas (state, payload) {
            return state.areas = payload
        },
        setAreasTotalPages (state, payload) {
            return state.areasTotalPages = payload
        },
        setTotalAreas (state, payload) {
            return state.totalAreas = payload
        },
        setAreaData (state, payload) {
            return state.areaData = payload
        },
        setTicketCountsByArea(state, payload){
            return state.ticketCountsByArea = payload
        }
    },
    actions: {
        async createArea({dispatch}, payload) {
            const res = await api.post('polygons-ticket-kind-area/create-area', payload)
            dispatch('getAreas', {page: 1, per_page: 10})
            return res
        },
        async checkPolygon({dispatch}, payload) {
            return await api.post('polygons-ticket-kind-area/check-polygon', payload)
        },
        async deactivateArea({dispatch}, id) {
            const res = await api.post(`polygons-ticket-kind-area/change-area-status/${id}`)
            dispatch('getAreas', {page: 1, per_page: 10})
            return res
        },
        async getAuthorsList({commit}, payload) {
            const res = await api.get(`managers/author-list`, {
                params: payload
            })
            commit('setAuthorsList', res?.data);
        },
        async getAreas ({commit}, payload) {
            const res = await api.get(`polygons-ticket-kind-area/area-list`, {
                params: payload
            })
            commit('setAreas', res?.data?.data)
            commit('setAreasTotalPages', res?.data?.pagination?.total)
            commit('setTotalAreas', res?.data?.pagination?.total_pages)
        },
        async getAreaData ({commit}, id) {
            const res = await api.get(`polygons-ticket-kind-area/area-detail/${id}`)
            commit('setAreaData', res?.data)
        },
        async updateArea({dispatch}, payload) {
            const res =  await api.put('polygons-ticket-kind-area/update-area', payload)
            dispatch('getAreas', {page: 1, per_page: 10})
            dispatch('getAreaData', payload.id)
            return res
        },
        async deleteArea({dispatch}, id) {
            const res = await api.delete(`polygons-ticket-kind-area/delete-area/${id}`)
            dispatch('getAreas', {page: 1, per_page: 10})
            return res
        },
        async getTicketCountsByArea({commit}, payload) {
            const res = await api.get('polygons-ticket-kind-area/ticket-counts-by-area', {params: payload})
            commit('setTicketCountsByArea', res?.data)
            return res
        }
    }
}
