<template>
  <div class="wrapper modified">
    <div class="content w-100 flex column">
      <button class="btn mb-2" style="width: 300px" @click="isCreateDutyScheduleModal = !isCreateDutyScheduleModal">Создать дежурство</button>
      <div class="mt-2">
        <div>Подразделения:</div>
        <div class="mt-2 mb-2" style="width: 260px;">
        <select class="input-form select" v-model="department_id" @change="selectDepartment(department_id)">
                    <option  :value="null">Все</option>
                    <option v-for="option in departmentList" :key="option.key" :value="option.id">
                        {{ option.title }}
                    </option>
                </select>
        </div>
      </div>
      <custom-table :data="scheduleAll" :columns="tableColumns" @get-detail="getDetails" @copyValue="copyValue"></custom-table>
    </div>
    <custom-modal v-if="isDutyScheduleModal" @close="isDutyScheduleModal = !isDutyScheduleModal">
      <duty-schedule-detail-modal :currentDutySchedule="currentDutySchedule" @close="isDutyScheduleModal = !isDutyScheduleModal" @successModal="isSuccessModal = !isSuccessModal"></duty-schedule-detail-modal>
    </custom-modal>
    <custom-modal v-if="isCreateDutyScheduleModal" @close="isCreateDutyScheduleModal = !isCreateDutyScheduleModal">
      <duty-schedule-create-modal @close="isCreateDutyScheduleModal = !isCreateDutyScheduleModal" @successModal="isSuccessModal = !isSuccessModal" :departmentId="department_id"></duty-schedule-create-modal>
    </custom-modal>
    <custom-modal v-if="isSuccessModal" @close="isSuccessModal = !isSuccessModal">
      <success-modal @close="isSuccessModal = !isSuccessModal">
        <h1>Дежурство было успешно создано!</h1>
      </success-modal>
    </custom-modal>
    <custom-modal v-if="isCreateFromCopy" @close="isCreateFromCopy = !isCreateFromCopy">
      <duty-schedule-create-from-copy :currentDutySchedule="currentDutySchedule" @close="isCreateFromCopy = !isCreateFromCopy" @successModal="isSuccessModal = !isSuccessModal"></duty-schedule-create-from-copy>
    </custom-modal>
  </div>

</template>

<script>
import CustomTable from "@/components/modules/CustomTable.vue";
import {DutyScheduleColumns} from "@/modules/table-columns";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import DutyScheduleDetailModal from "@/components/modals/dutySchedule/DutyScheduleDetailModal.vue";
import DutyScheduleCreateModal from "@/components/modals/dutySchedule/DutyScheduleCreateModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import DutyScheduleCreateFromCopy from "@/components/modals/dutySchedule/DutyScheduleCreateFromCopy.vue"

export default {
  name: 'DutySchedule',
  components: {SuccessModal, DutyScheduleCreateModal, DutyScheduleDetailModal, CustomModal, CustomTable, DutyScheduleCreateFromCopy},
  created() {
      this.setLoading(true)
      this.fetchScheduleAll()
      this.fetchDepartmentList()
      this.setLoading(false)
  },
  data() {
    return {
      tableColumns: DutyScheduleColumns,
      isDutyScheduleModal: false,
      isCreateDutyScheduleModal: false,
      isCreateFromCopy: false,
      currentDutySchedule: {},
      isSuccessModal: false,
      department_id: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      scheduleAll: 'getScheduleAll',
      scheduleDepartment: 'getScheduleDepartment',
      departmentList: 'departmentList'
    })
  },
  methods: {
    ...mapActions({
      fetchScheduleAll: "fetchDutyScheduleAll",
      fetchScheduleDepartment: "fetchDutyScheduleDepartment",
      fetchDepartmentList: 'fetchDepartmentList'
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    getDetails(details){
      this.isDutyScheduleModal = true
      this.currentDutySchedule = details
    },
   async selectDepartment(id){
    this.setLoading(true)
    const payload = {
      department_id: id
    }
      await this.fetchScheduleAll(payload)
      this.setLoading(false)
    },
    copyValue(details) {
      this.isCreateFromCopy = true
      this.currentDutySchedule = details
    }
  },

}
</script>