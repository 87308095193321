<template>
  <div class="modal">
    <div class="modal-overlay" @click="handleClose"></div>
    <div ref="modal_side" class="modal-side">
      <div class="modal-header">
        <div class="modal-header-content">
          <slot name="header"></slot>
        </div>
        <button class="modal-close-btn" @click="handleClose">
          <img src="/assets/close-rounded-light.svg" alt="close" />
        </button>
      </div>
      <div ref="notificationsWrapper" class="notifications-wrapper" :class="{ 'no-scrollbar': !hasScrollbar }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewSideModal',
  data() {
    return {
      hasScrollbar: false
    };
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    checkScrollbar() {
      const wrapper = this.$refs.notificationsWrapper;
      if (wrapper) {
        this.hasScrollbar = wrapper.scrollHeight > wrapper.clientHeight;
      }
    }
  },
  mounted() {
    this.$nextTick(this.checkScrollbar);
  },
  updated() {
    this.$nextTick(this.checkScrollbar);
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  padding: 24px;

  &-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &-header {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 2;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;

    &-content {
      flex: 1;
    }
  }

  &-close-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: all 0.2s ease;
    border-radius: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      img {
        filter: brightness(0.9);
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition: filter 0.2s ease;
    }
  }

  &-side {
    position: relative;
    height: calc(100vh - 48px);
    width: 580px;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
  }
}

.notifications-wrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -20px;
  padding: 0 14px 0 20px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    min-height: 100px;
  }

  /* Регулировка отступов при отсутствии скроллбара */
  &.no-scrollbar {
    padding-right: 20px;
  }
}
</style>