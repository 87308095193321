<template>
  <div class="p-20">
  <button class="btn btn-create mb-4" @click="createInstruction" style="width: auto">Создать</button>
    <div class="bg-white rounded p-20">
      <div class="mt-3">
        <div class="grid" style="background-color: lightgray;">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-Name">
            <p>Название</p>
          </div>
          <div class="grid-Description">
            <p>Описание</p>
          </div>
          <div class="grid-options">
            <p>Управление</p>
          </div>
        </div>
        <div class="wiki-table">
          <div class="grid" v-for="(el, index) in wiki" :key="el" @click="getDetails(el.id)">
            <div> {{ index + 1 }}</div>
            <div>{{ el.title}}</div>
            <div>
              {{el.description}}
            </div>
            <div class="wiki-options">
              <img class="wiki-options-trash" title="Посетить" src="/assets/eyeInstruction.svg" alt="inspect" width="20" height="20" @click.stop="openWikiPage(el.id)"/>
              <img class="wiki-options-trash" title="Редактировать" src="/assets/list.svg" alt="edit" width="16" height="16" @click.stop="$router.push(`/instructions/${el.id}/edit`)">
              <img class="wiki-options-trash" title="Удалить" src="/assets/TrashBr.svg" alt="delete" width="20" height="20" @click.stop="deleteWikiBtn(el.id)"/>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'InstructionsComponent',
  created() {
    this.fetchWikiList()
  },
  computed: {
    ...mapGetters({
      wiki: 'wikiList',
    })
  },
  methods: {
    ...mapActions({
      fetchWikiList: 'fetchWikiPages',
      deleteWiki: 'deleteWikiPage',
      fetchWikiPage: 'fetchWikiPage'
    }),
    ...mapMutations({
      filterWiki: 'filterWikiList',
      setLoading: 'setLoading'
    }),
    createInstruction(){
      this.$router.push('/instructions/create')
    },
    getDetails(id){
      this.$router.push(`/instructions/${id}`)
    },
    async deleteWikiBtn(id){
    const obj = {
      id: id
    }
    await this.deleteWiki(obj).then(res=>{
      if(res.success){
        this.$toast.success('Инструкция успешно удалена')
        this.filterWiki(obj)
      } else {
        this.$toast.error('Не удалось удалить инструкцию')
      }
    }).catch((err)=>{
      this.$toast.error(`${err.message}`)
    })
    },
    async openWikiPage(id){
      this.$router.push(`/instructions/visit/${id}`)
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr  3fr 1fr;
  grid-template-areas: 'Num Name  Description Options';
}
.grid-num {
  grid-area: Num;
}
.grid-Name {
  grid-area: Name;
}
.grid-Description {
  grid-area: Description;
}
.grid-Options {
  grid-area: Options;
}

.wiki-options {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  &-trash {
    &:hover {
      transform: scale(1.2);
      transition: 200ms;
    }
  }
}

.wiki-table {
  cursor: pointer;
  :hover {
    background-color: #F5F5FA;
  }
}
</style>