<template>
  <div class="comment-container">
    <h1>Редактировать сообщение</h1>
    <!--    <textarea class="input edit-textarea" v-model="editedMessage" @paste="pasteImage" @drop.prevent="pasteImage"></textarea>-->
    <QuillEditor ref="quillEditor" style="height: 160px !important; margin-bottom: 12px; !important;"
                 class="input textarea" theme="snow" toolbar="essential" v-model:content="editedMessage"
                 content-type="html" @drop.prevent="pasteImage"></QuillEditor>
    <div class="edit__files">
      <div v-for="(file, index) in comment.files" :key="file.id" style="position: relative;">
<!--        <img class="edit__files-img" :src="file.file_url" alt="">-->
        <template
            v-if="file.file_name.match(/\.(jpg|jpeg|png|gif|jfif)$/i) || file.file_url.match(/\.(jpg|jpeg|png|gif|jfif)$/i)">
          <img :src="file.file_url" alt="Image" style="max-width: 600px"/>
        </template>
        <template
            v-else-if="file.file_name.match(/\.(mp4|avi|mov|webm)$/i) || file.file_url.match(/\.(mp4|avi|mov|webm)$/i)">
          <video class="attached-files__video" controls :src="file.file_url" width="65" height="65"></video>
        </template>
        <template
            v-else-if="file.file_name.match(/\.(xlsx|xls|doc|docx|pdf)$/i) || file.file_url.match(/\.(xlsx|xls|doc|docx|pdf)$/i)">
          <div  class="cursor-pointer"><div class="mr-2 mt-1 link">{{file.file_name}}</div></div>
        </template>
        <img class="edit-delete" @click="removeExistingFiles(index)" src="/assets/close-red.svg" alt="Delete">
      </div>
    </div>
    <div style="display: flex; justify-content: end;">
      <div>
        <img class="edit-file" @click="onPickFile" src="/assets/paper-clip.svg" alt="">
        <img class="edit-send" @click="editComment" src="/assets/send.svg" alt="">
      </div>
    </div>
    <input type="file"
           style="display: none"
           ref="fileInput"
           id="fileInput"
           multiple
           accept="image/*"
           @change="onFilePicked"/>
    <div class="attached-files flex flex-wrap w-100" v-if="attached_files.length > 0">
      <div class="attached-files__item" v-for="(file, index) in attached_files" :key="index">
        <img class="attached-files__delete" @click="removeFile(index)" src="/assets/close-red.svg" alt="">
        <img class="attached-files__file" :src="'data:image/png;base64,' + file.base64String" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {getBase64, getFileFormat} from "@/helpers/utils";
import {QuillEditor} from "@vueup/vue-quill";

export default {
  name: 'EditCommentModal',
  props: ['comment'],
  components: {QuillEditor,},
  created() {
    this.editedMessage = this.comment.message
    this.existed_files = this.comment.files
    this.reply_id = this.comment.id
  },
  data() {
    return {
      editedMessage: '',
      existed_files: [],
      attached_files: [],
      reply_id: null,
    }
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const files = event.target.files
      files.forEach(async (item) => {
        let file = {}
        await getBase64(item).then(data => {
          file.format = getFileFormat(item)
          file.base64String = data.split(',')[1]
          this.attached_files.push(file)
        })
      })
    },
    removeFile(index) {
      this.attached_files.splice(index, 1)
    },
    removeExistingFiles(index) {
      this.existed_files.splice(index, 1)
    },
    async convertExistedFilesToBase64() {
      const convertedFiles = [];
      for (const file of this.existed_files) {
        console.log('test', file)

          try {
            const response = await fetch(file.file_url);
            const blob = await response.blob();
            const base64String = await getBase64(blob);
            convertedFiles.push({
              format: file.file_name,
              base64String: base64String.split(',')[1],
            });
            console.log('test3', convertedFiles)
          } catch (error) {
            console.error(`Ошибка при конвертации файла ${file.file_name}:`, error);
          }

      }
      return convertedFiles;
    },

    async editComment() {
      const convertedExistedFiles = await this.convertExistedFilesToBase64();

      const obj = {
        comment: this.editedMessage,
        attached_files: [...this.attached_files, ...convertedExistedFiles],
        reply_id: this.reply_id
      }
      this.$emit('submitEdittingComment', obj)
    },
    pasteImage(pasteEvent) {
      let items = pasteEvent.clipboardData ? pasteEvent.clipboardData.items : pasteEvent.dataTransfer.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        let blob = items[i].getAsFile();
        const temp = {
          target: {
            files: [blob]
          }
        }
        this.onFilePicked(temp)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.comment-container {
  margin-top: 48px;
  width: 600px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
  }
}

.edit-textarea {
  border-radius: 25px;
  border: 2px solid $gray;
  background: $white;
  margin-bottom: 12px;
  height: 400px;
  resize: none;

  &:focus, &:active, &:focus-visible {
    border: 1px solid $gray;
    outline: none !important;
  }
}

.edit-file {
  cursor: pointer;
}

.edit-send {
  cursor: pointer;
}

.edit__files {
  display: flex;
  flex-wrap: wrap;

  &-img {
    width: 65px;
    height: 65px;
    margin-right: 12px;
    cursor: pointer;
    margin-top: 12px;
  }
}

.attached-files {
  margin-bottom: 12px;

  &__item {
    margin-right: 8px;
    position: relative;
  }

  &__delete {
    position: absolute;
    z-index: 2;
    top: -4px;
    right: -4px;
    cursor: pointer;
  }

  &__file {
    width: 65px;
    height: 65px;
  }
}

.edit-delete {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  z-index: 2 !important;
  top: 10px !important;
  right: 6px !important;
  cursor: pointer !important;
}
</style>