<template>
  <div class="p-20">
    <div class="p-20 inner flex column align-center" style="width: 600px;">
      <yandex-map
          :zoom="12"
          :controls="[
          'rulerControl',
          'zoomControl',
          'searchControl',
        ]"
          :coordinates="[43.238949,76.889709]"
          @map-was-initialized="mapInit"
          @click="placeMarker"
      >
        <yandex-marker
            v-if="markerCoordinates"
            marker-id="clicked-placemark"
            marker-type="circle"
            :coordinates="markerCoordinates"
        />
      </yandex-map>
      <div v-if="markerCoordinates" class="coordinates">
        Выбранные координаты {{ markerCoordinates }}
      </div>
    </div>
    <div class="flex justify-center" v-if="markerCoordinates">
      <button class="w-30 btn input-btn" @click="pickCoordinates">Выбрать</button>
    </div>
  </div>
</template>

<script>
import { YandexMap, YandexMarker } from "vue-yandex-maps";
import {api} from "@/service/axios";

export default {
  name: "ServiceTicketFormAddressModal",
  components: { YandexMap, YandexMarker },
  data() {
    return {
      yandexMap: undefined,
      markerCoordinates: null,
      streetName: null,
      districtName: null,
    };
  },
  methods: {
    mapInit(map) {
      this.yandexMap = map;
    },
    placeMarker(event) {
      const coords = event.get("coords");
      this.markerCoordinates = coords;
      this.getStreetName(coords);
    },
    async getStreetName(coords) {
      try {
        const [lat, lon] = coords;
        const apiKey = process.env.VUE_APP_YANDEX_API_KEY;
        const url = `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${apiKey}&geocode=${lon},${lat}`;
        
        const response = await fetch(url);
        const data = await response.json();
        
        if (!data?.response?.GeoObjectCollection?.featureMember?.length) {
          console.error('No geocoding results found');
          return;
        }

        const featureMembers = data.response.GeoObjectCollection.featureMember;
        
        let bestAddress = null;
        for (const member of featureMembers) {
          const metadata = member.GeoObject.metaDataProperty.GeocoderMetaData;
          const text = metadata.text;
          
          if (metadata.kind === 'country' || 
              (metadata.kind === 'province' && !text.includes('Алматы,') && !text.includes('Алматинская область'))) {
            continue;
          }
          
          if (text === 'Казахстан, Алматы' || text === 'Казахстан, Алматинская область') {
            continue;
          }
          
          bestAddress = text;
          break;
        }
        
        if (!bestAddress || bestAddress.toLowerCase().includes('баренцево море')) {
          console.error('Invalid or no address found');
          return;
        }
        
        this.streetName = bestAddress;
        
        const districtGeoObject = featureMembers.find(member => {
          const metadata = member.GeoObject.metaDataProperty.GeocoderMetaData;
          const components = metadata.Address.Components;
          
          const cityAdminComponent = components.find(comp => 
            comp.kind === 'area' && 
            comp.name.toLowerCase().includes('городская администрация')
          );

          if (cityAdminComponent) {
            return true;
          }

          const locationComponent = components.find(comp => 
            (comp.kind === 'district' || comp.kind === 'area') && 
            this.isValidLocation(comp.name)
          );
          
          return !!locationComponent;
        });
        
        if (districtGeoObject) {
          const components = districtGeoObject.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
          
          const cityAdminComponent = components.find(comp => 
            comp.kind === 'area' && 
            comp.name.toLowerCase().includes('городская администрация')
          );

          if (cityAdminComponent) {
            const cityName = cityAdminComponent.name.replace('городская администрация ', '');
            this.districtName = `г. ${cityName}`;
            console.log('Found city administration:', this.districtName);
            return;
          }

          const locationComponent = components.find(comp => 
            (comp.kind === 'district' || comp.kind === 'area') && 
            this.isValidLocation(comp.name)
          );
          
          if (locationComponent) {
            this.districtName = locationComponent.name;
            console.log('Found district:', this.districtName);
          }
        } else {
          console.log('District not found in address');
          this.districtName = null;
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    isValidLocation(name) {
      const validLocations = [
        // Almaty city districts
        'алатауский район',
        'алмалинский район',
        'ауэзовский район',
        'бостандыкский район',
        'жетысуский район',
        'медеуский район',
        'наурызбайский район',
        'турксибский район',
        
        // Almaty region districts
        'талгарский район',
        'карасайский район',
        'илийский район',
        'енбекшиказахский район',
        'жамбылский район',
        'конаев',
        'г. конаев',
        'городская администрация конаев',
        'талгар',
        'карасай',
        'илийский',
        'енбекшиказахский',
        'жамбыл',
        'капчагай',
        'г. капчагай',
        'городская администрация капчагай'
      ];

      const normalizedName = name.toLowerCase().trim();
      
      if (validLocations.includes(normalizedName)) {
        return true;
      }
      
      const nameWithoutRayon = normalizedName
        .replace(' район', '')
        .replace('городская администрация ', '')
        .replace('г. ', '');

      return validLocations.some(location => {
        const normalizedLocation = location
          .replace(' район', '')
          .replace('городская администрация ', '')
          .replace('г. ', '');
        return normalizedLocation.includes(nameWithoutRayon) || 
               nameWithoutRayon.includes(normalizedLocation);
      });
    },

    pickCoordinates() {
      this.$emit('close', this.markerCoordinates);
      this.$emit('pickedCoordinates', {
        coordinates: this.markerCoordinates,
        street: this.streetName
      });
      this.$emit('getStreetName', this.streetName);
      if (this.districtName) {
        this.$emit('getDistrictName', this.districtName);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.coordinates {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}
</style>