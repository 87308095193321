<template>
  <comments-tab :comments="ticketComments"
                :totalComments="totalComments"
                @addComment="addComment"
                @loadComments="loadComments"
                @openImage="toggleImageModal"></comments-tab>
</template>

<script>
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "DepartureServiceTicketsCommentsModal",
  components: {CommentsTab},
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  async created() {
    this.setLoading(true)
    await this.fetchFirstTicketComments({ticket_id: this.id});
    this.setLoading(false)
  },
  data() {
    return {
      image: '',
    }
  },
  computed: {
    ...mapGetters({
      ticketComments: 'ticketComments',
      totalComments: 'totalComments',
      currentCommentPage: 'currentCommentPage',
    })
  },
  methods: {
    ...mapActions({
      fetchTicketComments: 'fetchTicketComments',
      addTicketComments: 'addTicketComments',
      fetchFirstTicketComments: 'fetchFirstTicketComments'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
    }),
    async addComment(comment) {
      comment.ticket_id = this.id
      await this.addTicketComments(comment)
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({ticket_id: this.id, page: this.currentCommentPage})
    },
    toggleImageModal(src = '') {
      this.image = src
    },
  },
}
</script>

<style scoped lang="scss">

</style>