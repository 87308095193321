<template>
  <div class="auth-container min-h-full flex align-center justify-end">
    <div class="auth-form flex column align-center">
      <div class="flex w-100 align-center justify-center">
        <img class="mr-2" src="/assets/nls-logo.svg" alt="">
        <h3 class="auth-title">NLS.SUPPORT ЦЕНТР</h3>
      </div>
      <div class="auth-inner">
        <h4 :class="{'mb-5': errorText.length === 0 || successText.length === 0, 'mb-2': errorText.length || successText.length}">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</h4>
        <p v-if="errorText.length" class="error-text mb-2 uppercase">{{errorText}}</p>
        <p v-if="successText.length" class="success-text mb-2 uppercase">{{successText}}</p>


        <div class="auth-stepper" :class="{'second': isSecondStep}">
          <div class="auth-step">
            <div class="input-container mb-2">
              <input ref="loginInput" id="loginInput" placeholder="Логин" v-mask="phoneMask" class="input" type="text" v-model="login" @paste="onPasteLogin" />
              <label for="loginInput">Логин</label>
            </div>
            <button type="button" class="btn mb-2 mt-2" @click="sendSMS">Получить код</button>
          </div>


          <div class="auth-step">
            <div class="input-container">
              <input ref="codeInput" id="codeInput" class="input" type="number" placeholder="Код из SMS" v-model="code" :readonly="!codeSend" />
              <label for="codeInput">Код из SMS</label>
              <div class="input-btn modified" :class="{'active': timer === 0}" @click="sendSMS">
                {{ timer === 0 ? 'Получить код' : `ПОВТОРНО ЧЕРЕЗ ${getTimerSeconds()} СЕК` }}
              </div>
            </div>


            <div class="auth-actions">
              <button type="button" class="btn mb-2 mt-2" @click="checkSMS">Задать пароль</button>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import {mapMutations} from "vuex";
import Cookies from 'js-cookie'
export default {
  name: 'ForgotView',
  data() {
    return {
      login: '',
      code: '',
      timer: 0,
      errorText: '',
      successText: '',
      codeSend: false,
      phoneMask: '+7 (###)-###-##-##',
      isSecondStep: false
    }
  },
  async mounted() {
    if (this.$route.query.login) this.login = this.$route.query.login
    this.$refs.loginInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.checkSMS()
      }
    })
    this.$refs.codeInput.addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        this.checkSMS()
      }
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    async checkSMS() {
      if (this.code) {
        let stringCode = this.code.toString()
        while (stringCode.length < 4) stringCode = "0" + stringCode;
        this.setLoading(true)
        await store.dispatch('checkSMS', {
          login: this.unMask(this.login),
          code: stringCode,
        }).then(res => {
          if (!res.status) {
            this.errorText = res.message
            setTimeout(() => {
              this.errorText = ''
            }, 5000)
          }
          else this.$router.push(`/reset${this.login ? `?login=${this.unMask(this.login)}` : ''}&code=${stringCode}`)
        })
        this.setLoading(false)
      }
    },
    onPasteLogin(e) {
      if (e.clipboardData.getData('text').length === 11 && e.clipboardData.getData('text')[0] === '8') {
        this.login = '7' + this.unMask(e.clipboardData.getData('text')).slice(-10)
      }
    },
    getTimerSeconds() {
      let result = ''
      if (this.timer/ 1000 >= 10) {
        result = `${this.timer / 1000}`
      }
      else {
        result = `0${this.timer / 1000}`
      }

      return result
    },
    startTimer() {
      this.timer = 60000
      const interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(interval)
          return
        }
        this.timer -= 1000
      }, 1000)
    },
    async sendSMS() {
      if (this.timer === 0) {
        this.setLoading(true)
        await store.dispatch('sendSMS', {
          login: this.unMask(this.login),
        }).then(res => {
          if (!res.status) {
            this.errorText = res.message
            setTimeout(() => {
              this.errorText = ''
            }, 5000)
          }
          else {
            this.isSecondStep = true
            this.codeSend = true
            this.successText = 'КОД ОТПРАВЛЕН НА НОМЕР ' + this.login
            setTimeout(() => {
              this.successText = ''
            }, 5000)
            this.startTimer()
          }
        })
        this.setLoading(false)
      }
    },
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    toLogin() {
      this.$router.push("/login")
    }
  }
}
</script>
