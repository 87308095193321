import axios from "axios";
const token = process.env.VUE_APP_WAZZUP_TOKEN

export const wazzupModule = {
    state: () => ({
        url: '',
        isClientCard: false,
    }),
    getters: {
        url(state){
            return state.url;
        },
        isClientCard(state) {
            return state.isClientCard;
        }
    },
    mutations: {
        setUrl(state, payload) {
            state.url = payload;
        },
        setIsClientCard(state, payload) {
            state.isClientCard = payload;
        }
    },
    actions: {
        async fetchWazzupIframeUrl({commit}, payload) {

            const res = await axios.post('https://api.wazzup24.com/v3/iframe',payload, {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
            commit('setUrl', res.data.url)
        },
        async getWazzupUsers(){
            const res = await axios.get('https://api.wazzup24.com/v3/users', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
            return res
        },
        async createWazzupUser({commit}, payload){
            const res = await axios.post('https://api.wazzup24.com/v3/users',payload, {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}})
            return res
        },
    }
}