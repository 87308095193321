import { api } from "@/service/axios"

export const pagesModule = {
  namespaced: true,
  state: () => ({
    pages: [],
    currentRole: null,
    currentRolePages: [],
  }),
  mutations: {
    setPages(state, pages) {
      state.pages = pages
    },
    setCurrentRole(state, role) {
      state.currentRole = role
    },
    setCurrentRolePages(state, pages) {
      state.currentRolePages = pages
    },
  },
  actions: {
    async fetchPages({ commit }) {
      try {
        const response = await api.get('/pages')
        commit('setPages', response.data)
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error fetching pages' }
      }
    },
    async createPage({ commit }, page) {
      try {
        const response = await api.post('/pages', page)
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error creating page' }
      }
    },
    async createManyPages({ commit }, { pages }) {
      try {
        const response = await api.post('/pages/many', { pages })
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error creating pages' }
      }
    },
    async deletePage({ commit }, key) {
      try {
        const response = await api.delete(`/pages/${key}`)
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error deleting page' }
      }
    },
    async updatePage({ commit }, page) {
      try {
        const response = await api.put('/pages', page)
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error updating page' }
      }
    },
    async updateRolePages({ commit }, { roleId, pages }) {
      try {
        const response = await api.post(`/role-pages/${roleId}`, { pages })
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error updating role pages' }
      }
    },
    async deleteRolePages({ commit }, roleId) {
      try {
        const response = await api.delete(`/role-pages/${roleId}`)
        return { success: true, data: response.data }
      } catch (error) {
        return { success: false, message: error.response?.message || 'Error deleting role pages' }
      }
    },
    async fetchRoleById({ commit }, roleId) {
      try {
        const response = await api.get(`/role/show/${roleId}`)
        if (response.success) {
          console.log(response.data)
          commit('setCurrentRole', response.data)
          commit('setCurrentRolePages', response.data.pages || [])
        }
        return { success: true, data: response.data.data }
      } catch (error) {
        console.error('Error fetching role:', error)
        return { success: false, message: error.response?.data?.message || 'Error fetching role data' }
      }
    },
  },
  getters: {
    pages: state => state.pages,
    currentRole: state => state.currentRole,
    currentRolePages: state => state.currentRolePages,
  },
} 