<template>
  <div class="p-20">
    <div>
      <h1 class="text-24">Список узлов подключения</h1>

      <!-- Поле поиска -->
      <div class="input-container search mb-3 mt-4">
        <div class="dropdown w-100">
          <input
              type="search"
              class="input-form select mt-1"
              v-model="searchText"
              @click="toggleDropdown"
              @input="showDropdown = true"
              placeholder="Поиск узла подключения"
              style="height: 48px !important; border-radius: 25px !important;"
          />
          <div class="dropdown-content" :class="{ show: showDropdown }">
            <div
                v-for="option in filteredCommutatorsList"
                :key="option.id"
                @click="selectOption(option)"
            >
              {{ option?.device_hostname ? option?.device_hostname :  option?.device_name}}
              <p>{{ option.node_name }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Список выбранных узлов -->
      <div class="bg-gray rounded">
        <div style="padding: 12px" v-for="node in selectedCommutators" :key="node.id">
          <div class="bg-white rounded relative" style="padding: 12px">
            <!-- Кнопка удаления узла -->
            <button class="node-del" title="Удалить" @click="deleteNode(node)">
              <img src="/assets/close-red.svg" alt="Удалить" height="18px" width="18px" style="border: 1px solid red; border-radius: 50%;">
            </button>

            <div class="align-center justify-between gap-2 flex cursor-pointer"  @click="toggleNodeInfo(node)">
              <div class="flex gap-1 items-center">
                <input type="checkbox" style="display: none;" v-model="node.checked" disabled>
                <div>
                  <p class="bold">{{ node.device_hostname ? node.device_hostname :  node.device_name }}</p>
                  <p>{{ node.node_name }}</p>
                </div>
              </div>
              <div class="flex align-center gap-1 cursor-pointer" style="color: #0095FF; font-size: 16px"
                  >
                <p>Порты</p>
                <img v-if="!node.collapsed" src="/assets/right.svg" alt="Развернуть">
                <img v-if="node.collapsed" src="/assets/down.svg" alt="Свернуть">
              </div>
            </div>

            <!-- Список портов -->
            <div v-if="node.iface_count && node.collapsed">
              <div class="flex mt-1">
                <input type="checkbox" :checked="areAllPortsSelected(node)" @change="selectAll($event, node)">
                <p>Выбрать все</p>
              </div>
              <div v-for="iface in node.iface_poller_data" :key="iface">
                <div class="flex mt-1 mb-1">
                  <input type="checkbox" :checked="node.chosenPort.includes(iface.name)"
                         @change="handleChange($event, node, iface)">
                  <p>{{iface.name}}</p>
                </div>
              </div>
            </div>
            <div v-if="!node.iface_count && node.collapsed">
              <p>Список пуст</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="selectedCommutators.length > 0" class="input-btn btn mt-4" @click="addNodes">Добавить</button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NodesListModal",
  props: {
    commutators: {
      type: Array,
      default: () => []
    },
  },
  created() {
    if(this.commutators.length > 0){
      this.commutators.forEach((el) => {
        this.fetchCommutatorsList({ search: el.commutator_name ? el.commutator_name : el.name }).then(res => {
          if (res.success && res.data.length > 0){
            const fetchedNode = res.data[0];
            // Инициализация chosenPort как массива
            fetchedNode.chosenPort = [];
            if (el.port_names.length === fetchedNode.iface_count){
              fetchedNode.iface_poller_data.forEach((el) => {
                fetchedNode.chosenPort.push(el.name)
              })
            } else {
              if (el.port_names.length > 0 <= fetchedNode.iface_count){
                fetchedNode.chosenPort.push(...el.port_names);
              }
            }
            fetchedNode.checked = fetchedNode.chosenPort.length > 0;
            fetchedNode.collapsed = false;
            this.selectedCommutators.push(fetchedNode);
          } else {
            this.$toast.error('Не удалось получить список узлов из заявки')
          }
        }).catch(err => {
          this.$toast.error('Не удалось получить данные узла');
        });
      });
    }
  },
  data() {
    return {
      searchText: '',
      showDropdown: false,
      filteredCommutatorsList: [],
      timeout: null,
      selectedCommutators: []
    }
  },
  computed: {
    ...mapGetters({
      commutatorsList: 'commutatorsList',
    }),
  },
  watch: {
    searchText(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        this.setLoading(true)
        if (value.length > 2){
          await this.fetchCommutatorsList({ search: value }).then((res) => {
            if (res.success){
              this.filteredCommutatorsList = res.data;
            } else {
              this.$toast.error('Не удалось получить список');
              this.filteredCommutatorsList = [];
            }
          }).catch((err) => {
            this.$toast.error('Не удалось получить список');
            this.filteredCommutatorsList = [];
          })
        } else {
          this.filteredCommutatorsList = [];
        }
        this.setLoading(false)
      }, 500)
    },
    commutatorsList(newOptions){
      this.filteredCommutatorsList = newOptions
    }
  },
  methods: {
    ...mapActions({
      fetchCommutatorsList: 'fetchCommutatorsList',
      fetchNodes: 'fetchNodes',
    }),
    ...mapMutations({
      setNodesListModal: 'setNodesListModal',
      setLoading: 'setLoading',
    }),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleNodeInfo(node) {
      node.collapsed = !node.collapsed;
    },
    handleChange(event, node, port) {
      if (!node.chosenPort) {
        this.$set(node, 'chosenPort', []);
      }
      if (event.target.checked) {
        if (!node.chosenPort.includes(port.name)) {
          node.chosenPort.push(port.name);
        }
      } else {
        const index = node.chosenPort.indexOf(port.name);
        if (index > -1) {
          node.chosenPort.splice(index, 1);
        }
      }
      node.checked = node.chosenPort.length > 0;
    },
    selectAll(event, node) {
      if (!node.chosenPort) {
        this.$set(node, 'chosenPort', []);
      }
      if (event.target.checked) {
        node.chosenPort = node.iface_poller_data.map(port => port.name);
        node.checked = true;
      } else {
        node.chosenPort = [];
        node.checked = false;
      }
    },
    selectOption(option) {
      if (!this.selectedCommutators.find(node => node.id === option.id)) {
        const newNode = { ...option };
        newNode.chosenPort = [];
        newNode.checked = false;
        newNode.collapsed = false;
        this.selectedCommutators.push(newNode);
      } else {
        this.$toast.info('Этот узел уже добавлен.');
      }
      this.showDropdown = false;
      this.searchText = '';
    },
    addNodes(){
      this.setNodesListModal(false)
      this.$emit('addNodes', this.selectedCommutators)
    },
    deleteNode(node){
      const index = this.selectedCommutators.findIndex(n => n.id === node.id);
      if (index > -1) {
        this.selectedCommutators.splice(index, 1);
        this.$toast.success('Узел удален из выбранных.');
      }
    },
    areAllPortsSelected(node) {
      return node.chosenPort.length === node.iface_poller_data.length;
    }
  },
  unmounted() {
    this.selectedCommutators = []
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.node-del {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
}

.bold {
  font-weight: bold;
}

.selected-ports ul {
  list-style-type: disc;
  padding-left: 20px;
}

.selected-ports p {
  margin: 0;
}
</style>
