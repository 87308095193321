<template>
  <main class="-mt-32" style="overflow-x: auto">
    <div class="flex justify-between mt-4 mb-4">
      <div class="flex">
        <div
          class="tab service"
          :class="{ active: currentTab === 'service' }"
          @click="changeTab('service')"
        >
          Сервисные заявки
        </div>
        <div
          class="tab emergency"
          :class="{ active: currentTab === 'emergencyTickets' }"
          @click="changeTab('emergencyTickets')"
        >
          Аварийные заявки
        </div>
        <div
          class="tab plan"
          :class="{ active: currentTab === 'plan' }"
          @click="changeTab('plan')"
        >
          Плановые заявки
        </div>
      </div>
    </div>
    <WorktimeTable
      :tab="currentTab"
      :tableData="worktimeData"
      :tabStyle="tabStyle"
      @get-detail="setEditModal"
    ></WorktimeTable>
    <custom-modal v-if="isEditPriority" @close="toggleEditModal">
      <priority-edit
        @submit="onEditPriority"
        :tabStyle="tabStyle"
        :detail="detail"
        :tab="currentTab"
      ></priority-edit>
    </custom-modal>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        Вы успешно поменяли срок выполнения!
      </success-modal>
    </custom-modal>
  </main>
</template>
<script>
import WorktimeTable from "@/components/worktime/WorktimeTable.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from '@/components/modals/tickets/SuccessModal.vue';
import PriorityEdit from "@/components/modals/PriorityEdit.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "WorkTimeView",
  components: {
    WorktimeTable,
    CustomModal,
    PriorityEdit,
    SuccessModal
  },
  data() {
    return {
      currentTab: "service",
      isEditPriority: false,
      isSuccessModal: false,
      detail: {},
    };
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchWorktime();
    this.setLoading(false);
  },
  computed: {
    ...mapGetters({
      worktime: "worktime",
    }),
    worktimeData() {
      return this.worktime.map((x) => {
        return {
          id: x.key,
          title: x.title,
          timeFormat: `+${
            x[this.currentTab + "_work_time"]
          } суточных часа от даты создания заявки`,
          time: x[this.currentTab + "_work_time"],
        };
      });
    },
    tabStyle() {
      switch (this.currentTab) {
        case "service":
          return {
            title: "Сервисные заявки",
            header: "green-gradient",
          };
        case "emergencyTickets":
          return {
            title: "Аварийные заявки",
            header: "peach-gradient",
          };
        case "plan":
          return {
            title: "Плановые заявки",
            header: "blue-gradient",
          };
        default:
          return {
            title: "Сервисные заявки",
            header: "green-gradient",
          };
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWorktime: "fetchWorktime",
      editPriority: "editPriority",
    }),
    ...mapMutations({
      setLoading: "setLoading",
    }),
    changeTab(tab) {
      this.currentTab = tab;
    },
    setEditModal(detail) {
      this.detail =
        this.worktime[this.worktime.map((x) => x.key).indexOf(detail)];
      this.toggleEditModal();
    },
    toggleEditModal() {
      this.isEditPriority = !this.isEditPriority;
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal;
    },
    formatPayload(payload) {
      return {
        priority_id: payload.id,
        emergency_work_time: JSON.parse(payload.emergency_work_time),
        plan_work_time: JSON.parse(payload.plan_work_time),
        service_work_time: JSON.parse(payload.service_work_time)
      }
    },
    async onEditPriority(value) {
      this.setLoading(true);
      const payload = this.formatPayload(value);
      await this.editPriority(payload);
      await this.fetchWorktime();
      this.toggleEditModal();
      this.toggleSuccessModal();
      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../theme/variables";

.tab {
  width: 251px;
  height: 39px;
  border-radius: 8px;
  background: #fff;
  color: #8d8d8d;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.07px;
  margin-right: 12px;
  cursor: pointer;
  padding: 10px 0;

  &.active,
  &:hover {
    background: #00557b;
    color: $white;
  }

  &.service.active,
  &.service:hover {
    background: linear-gradient(135deg, #149d8f 0%, #35d784 100%);
  }

  &.emergency.active,
  &.emergency:hover {
    background: linear-gradient(135deg, #ffb06d 0%, #f67785 100%);
  }

  &.plan.active,
  &.plan:hover {
    background: linear-gradient(
      132deg,
      #04567b 5.49%,
      #1576a2 44.5%,
      #2da4da 100%
    );
  }
}
</style>
