<template>
  <div class="wrapper modified flex column">
    <div class="block">
      <ticket-details-component :is-modal="isModal" v-if="isDataLoaded && chosenTicket?.ticket?.id" />
      <div v-else-if="!isDataLoaded" class="loading">Loading...</div>
    </div>
  </div>
</template>

<script>
import TicketDetailsComponent from "@/components/cards/TicketDetailsComponent.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'TicketDetailView',
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TicketDetailsComponent
  },
  data() {
    return {
      isDataLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
      currentTicketId: 'currentTicketId'
    })
  },
  async created() {
    await this.loadTicketData();
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'ticketDetail',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async loadTicketData() {
      try {
        this.setLoading(true);
        this.isDataLoaded = false;
        if (this.isModal) {
          await this.fetchTicketDetail(this.currentTicketId);
        } else {
          await this.fetchTicketDetail(this.$route.params.id);
        }
      } catch (error) {
        console.error('Error loading ticket data:', error);
      } finally {
        this.isDataLoaded = true;
        this.setLoading(false);
      }
    }
  },
  watch: {
    async '$route.params.id'() {
      if (!this.isModal) {
        await this.loadTicketData();
      }
    },
    async currentTicketId() {
      if (this.isModal && this.currentTicketId) {
        await this.loadTicketData();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2em;
  color: #666;
}
</style>
