<template>
    <div class="wrapper modified flex column">
        <div class="block">
            <QueriesTCDetailsComponent v-if="item?.id" />
        </div>
    </div>
</template>

<script>
import QueriesTCDetailsComponent from "@/components/cards/queriesTC/QueriesTCDetailsComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'QueriesTCDetailView',
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        QueriesTCDetailsComponent
    },
    data() {
        return {
            loading: true
        }
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(newId) {
                if (newId && !this.isModal) {
                    // Удаление состояния предыдущих комментов
                    this.$store.commit('setTicketComments', [])
                    this.$store.commit('setCurrentCommentPage', 1)
                    
                    // Получение основных данных
                    this.fetchItem(newId)
                    
                    // Асинхронное получение дополнительных данных
                    Promise.all([
                        this.fetchNecessaryEquipments(),
                        this.fetchConnectionMethods(),
                        this.fetchAdditionalGoods()
                    ]).catch(console.error)
                }
            }
        }
    },
    computed: {
        ...mapGetters('queriesTCModule', {
            item: 'item',
            necessaryEquipments: 'necessaryEquipments',
        })
    },
    methods: {
        ...mapActions('queriesTCModule', {
            fetchItem: 'fetchItem',
            fetchNecessaryEquipments: 'fetchNecessaryEquipments',
            fetchConnectionMethods: 'fetchConnectionMethods',
            fetchAdditionalGoods: 'fetchAdditionalGoods',
        })
    },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';
</style>