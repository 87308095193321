<template>
  <div class="wrapper justify-center">
    <div class="form flex justify-center">

      <div class="form-inner">
        <h1 class="text-black2 text-center w-100 mb-2">НОВАЯ ЗАЯВКА “ВНУТРЕННЯЯ ЗАЯВКА”</h1>


        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.category_id}" v-model="newTicket.category_id" ref="category_id" @change="categorySelected">
            <option v-for="option in categories" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Категория</label>
        </div>

        <div class="input-container input-container__form mb-2" v-if="types.length">
          <select class="input input-form select" :class="{'empty': !newTicket.type_id}" v-model="newTicket.type_id" ref="type_id">
            <option v-for="option in types" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Подкатегория</label>
        </div>

        <div class="input-container input-container__form mb-2">
          <select class="input input-form select" :class="{'empty': !newTicket.department_id}" v-model="newTicket.department_id" ref="department_id">
            <option v-for="option in departments" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
          <label class="label-form">Департамент</label>
        </div>

        <div class="input-container input-container__form mb-2" v-if="newTicket.category_id === 56">
          <input class="input input-form" v-model="newTicket.firstname" ref="firstname" placeholder="Имя" type="text" />
          <label class="label-form">Имя</label>
        </div>

        <div class="input-container input-container__form mb-2" v-if="newTicket.category_id === 56">
          <input class="input input-form" v-model="newTicket.lastname" ref="lastname" placeholder="Фамилия" type="text" />
          <label class="label-form">Фамилия</label>
        </div>

        <template v-if="newTicket.category_id === 56">
          <label class="checkbox mb-2">Домен
            <input type="checkbox" @change="setRequirements('domain')">
            <span class="checkmark"></span>
          </label>
          <label class="checkbox mb-2">CRM
            <input type="checkbox" @change="setRequirements('crm')">
            <span class="checkmark"></span>
          </label>
          <label class="checkbox mb-2">Userside
            <input type="checkbox" @change="setRequirements('userside')">
            <span class="checkmark"></span>
          </label>
          <label class="checkbox mb-2">1C
            <input type="checkbox" @change="setRequirements('1c')">
            <span class="checkmark"></span>
          </label>
          <label class="checkbox mb-2">Требуется выдача нового ПК
            <input type="checkbox" @change="setRequirements('new_pc')">
            <span class="checkmark"></span>
          </label>
          <label class="checkbox mb-4">Требуется выдача телефонного аппарата
            <input type="checkbox" @change="setRequirements('new_landline_phone')">
            <span class="checkmark"></span>
          </label>
        </template>

        <template v-if="newTicket.details.length > 0">

          <template v-for="detail in newTicket.details" :key="detail.id">
            <template v-if="detail.key === 'db_list'">
              <div class="input-container input-container__form mb-2" v-if="newTicket.requirements.indexOf('1c') > -1">
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title" type="text" />
                <label class="label-form">{{ detail.title }}</label>
              </div>
            </template>


            <div class="input-container input-container__form mb-2" v-else>
              <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title" type="text" />
              <label class="label-form">{{ detail.title }}</label>
            </div>

          </template>
        </template>


        <div class="input-container input-container__form mb-2">
          <textarea class="input-form textarea" v-model="newTicket.message" ref="message" placeholder="Дополнительная информация" type="text" />
          <label class="label-form" for="body">Дополнительная информация</label>
        </div>

        <div class="w-100 mb-2">
          <DropZone
              class="!cursor-pointer w-full h-full !border-none"
              :uploadOnDrop="false"
              ref="files"
              :multipleUpload="true"
              :parallelUpload="3">
            <template v-slot:message>
              <DropZoneInner />
            </template>
          </DropZone>
        </div>

        <button type="button" class="btn form-btn mb-4" @click="submitForm">создать</button>

      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        ВНУТРЕННЯЯ ЗАЯВКА УСПЕШНО СОЗДАНА
      </success-modal>
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {formatDate, getBase64, getFileFormat} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import { DropZone } from 'dropzone-vue';
import DropZoneInner from "@/components/static/DropZoneInner.vue";
import router from "@/router";

export default {
  name: 'InternalTicketFormView',
  components: {
    SuccessModal,
    CustomModal,
    DropZone,
    DropZoneInner,
  },
  data() {
    return {
      newTicket: {
        category_id: 0,
        type_id: 0,
        department_id: 0,
        firstname: '',
        lastname: '',
        message: '',
        requirements: [],
        details: [],
        attached_files: []
      },
      phoneMask: '+7 (###)-###-##-##',
      isSuccessModal: false,
    }
  },
  created() {
    this.setLoading(true)
    let promises = [this.fetchCategories(12), this.fetchDepartments()]
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      categories: 'categories',
      detailTypes: 'detailTypes',
      types: 'types',
      departments: 'departments'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    ...mapActions({
      fetchCategories: 'fetchCategories',
      fetchTypes: 'fetchTypes',
      fetchDetailTypes: 'fetchDetailTypes',
      createInternalTicket: 'createInternalTicket',
      fetchDepartments: 'fetchDepartments',
      errorAlert: 'errorAlert',
    }),
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push('/tickets')
    },
    categorySelected() {
      this.setLoading(true)
      let promises = [
        this.fetchDetailTypes({
          category_id: this.newTicket.category_id,
          vg_id: 0,
          problem_type_id: 0
        }),
        this.fetchTypes(
            {
              id: this.newTicket.category_id,
              problem: 0
            }
        )
      ]
      Promise.all(promises).then(() => {
        this.newTicket.details = this.detailTypes
        this.setLoading(false)
      })
    },
    setRequirements(req) {
      if (this.newTicket.requirements.indexOf(req) > -1) {
        this.newTicket.requirements.splice(this.newTicket.requirements.indexOf(req), 1)
      }
      else {
        this.newTicket.requirements.push(req)
      }
    },
    validateForm() {
      let isError = false
      for (let property in this.newTicket) {
        if (property === 'details') {
          for (const item of this.newTicket.details) {
            if (!item.value && item.is_required) {
              isError = true
              this.errorAlert(`${item.title} не указан`)
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            }
          }
        }
        else if (!this.newTicket[property] &&
            property !== 'ticket_id' &&
            property !== 'attached_files' &&
            property !== 'requirements' &&
            property !== 'firstname' &&
            property !== 'lastname') {
          isError = true
          if (this.$refs[property]) {
            this.$refs[property].classList.add('error')
            setTimeout(() => {
              this.$refs[property].classList.remove('error')
            }, 3000)
          }
        }
      }
      return isError
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.setLoading(true)
        let file = {}
        for(const prop in this.$refs.files.all) {

          await getBase64(this.$refs.files.all[prop].file).then(data => {
            file.format = getFileFormat(this.$refs.files.all[prop].file)
            file.base64String = data
            this.newTicket.attached_files.push(file)
          })
          // = getBase64(this.$refs.files.all[prop].file)

        }
        await this.createInternalTicket(this.newTicket).then(res => {
          if(res.success) {
            this.toggleSuccessModal()
          }
          else this.errorAlert(res.message)

          this.setLoading(false)
        })
      } else this.errorAlert('Заполните все поля')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
