<template>
  <div class="flex justify-center">
    <div class="bg-white mt-4 p-20 rounded">
      <div class="mb-1">
        <h1 class="text-center text-24">Создать заявку доступа</h1>
        <div class="flex justify-center p-20" style="width: auto">
          <form>
            <div v-for="(el, index) in addressesArr" :key="el">
              <div class="flex justify-between gap-2 mt-1 mb-2 input">
                <p class="text-center text-20">Выбрать координаты:</p>
                <div class="flex items-center justify-center gap-1" style="align-items: center">
                  <img src="/assets/access-data/2gis-svgrepo-com.svg" class="img-btn" @click="open2GisModal(index)"
                       title="2GIS" height="24"
                       width="24" alt="">
                  <img src="/assets/access-data/yandex-svgrepo-com.svg" class="img-btn" @click="openYandexModal(index)"
                       title="Yandex"
                       height="20" width="22" alt="">
                  <button type="button" @click="removeAddress(index)" class="delete-btn">✕</button>
                </div>
              </div>
              <div class="input-container input-container__form mb-2 mt-1">
                <input class="input input-form"
                       :value="el.latitude && el.longitude ? [el.latitude, el.longitude] : null"
                       placeholder="Координаты" type="text" readonly/>
                <label class="label-form">Координаты</label>
              </div>
              <div class="input-container input-container__form mb-1">
                <input class="input input-form" v-model="el.address"
                       placeholder="Адрес" type="text"/>
                <label class="label-form">Адрес</label>
              </div>
              <p class="text-center text-green" v-if="el.has_access && el.latitude && el.longitude">Есть доступ</p>
              <p class="text-center text-red" v-if="!el.has_access &&  el.latitude && el.longitude">Нет доступа</p>
            </div>
            <div class="mb-2 mt-1 flex justify-center">
              <button class="btn btn-green" @click.prevent="addAddressToArray">Добавить адрес</button>
            </div>
            <div class="input-container input-container__form mb-1">
              <app-textarea class="input input-form" v-model="notes"
                            placeholder="Описание" type="text"/>
              <label class="label-form">Описание</label>
            </div>
            <div class="flex justify-center gap-2 mt-2">
              <button class="btn form-btn" type="submit" @click.prevent="submitAccessData">Cоздать</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Модальные окна -->
  <custom-modal v-if="isAddressModal" @close="isAddressModal = false">
    <service-ticket-form-address-modal @getStreetName="getStreetName"
                                       @close="pickedCoordinates"></service-ticket-form-address-modal>
  </custom-modal>
  <custom-modal v-if="is2gisAddressModal" @close="is2gisAddressModal = false">
    <map-gis-modal @close="pickGisCoordinates"></map-gis-modal>
  </custom-modal>
  <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
    <success-modal @close="toggleSuccessModal">
      ЗАЯВКА <span v-if="createdTicketId"><b>#{{ createdTicketId }}</b></span> НА ДОСТУП УСПЕШНО СОЗДАНА
    </success-modal>
  </custom-modal>
</template>

<script>
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import ServiceTicketFormAddressModal from "@/components/modals/tickets/ServiceTicketFormAddressModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import MapGisModal from "@/components/modals/access-data/MapGisModal.vue";
import router from "@/router";
import AppTextarea from "@/components/ui/AppTextarea.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AccessDataPlainFormView",
  components: {MapGisModal, CustomModal, ServiceTicketFormAddressModal, SuccessModal, AppTextarea},
  created() {
    if (this.$route.query.edit === 'true' && this.chosenTicket.ticket) {
      this.notes = this.chosenTicket.ticket.message;
      this.addressesArr.push(...this.chosenTicket.ticket.addresses);
      this.ticket_id = +this.chosenTicket.ticket.id
    }
  },
  data() {
    return {
      notes: '',
      isAddressModal: false,
      is2gisAddressModal: false,
      isSuccessModal: false,
      createdTicketId: "",
      addressesArr: [],
      selectedAddressIndex: null,
      ticket_id: '',
    };
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
    })
  },
  methods: {
    ...mapActions({
      createAccessDataTicket: 'createAccessDataTicket',
      checkAccessInfoAddress: 'checkAccessInfoAddress',
    }),
    async pickedCoordinates(coords) {
      this.isAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].latitude = coords[0];
        this.addressesArr[this.selectedAddressIndex].longitude = coords[1];
        const addresses = {
          addresses: [
            {
              longitude: this.addressesArr[this.selectedAddressIndex].longitude,
              latitude: this.addressesArr[this.selectedAddressIndex].latitude
            }
          ]
        }
        await this.checkAccessInfoAddress(addresses).then((res) => {
          if (res.success) {
            this.addressesArr[this.selectedAddressIndex].has_access = res.data[0].has_access
          }
        })
      }
    },
    async pickGisCoordinates({coordinates, street}) {
      this.is2gisAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].latitude = coordinates[0];
        this.addressesArr[this.selectedAddressIndex].longitude = coordinates[1];
        this.addressesArr[this.selectedAddressIndex].address = street;
        const addresses = {
          addresses: [
            {
              longitude: this.addressesArr[this.selectedAddressIndex].longitude,
              latitude: this.addressesArr[this.selectedAddressIndex].latitude
            }
          ]
        }
        await this.checkAccessInfoAddress(addresses).then((res) => {
          if (res.success) {
            this.addressesArr[this.selectedAddressIndex].has_access = res.data[0].has_access
          }
        })
      }
    },
    openYandexModal(index) {
      this.selectedAddressIndex = index;
      this.isAddressModal = true;
    },
    open2GisModal(index) {
      this.selectedAddressIndex = index;
      this.is2gisAddressModal = true;
    },
    addAddressToArray() {
      this.addressesArr.push({latitude: null, longitude: null, address: ''});
    },
    removeAddress(index) {
      this.addressesArr.splice(index, 1);
    },
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal;
      if (!this.isSuccessModal) router.push('/access-tickets');
    },
    getStreetName(street) {
      this.isAddressModal = false;
      if (this.selectedAddressIndex !== null) {
        this.addressesArr[this.selectedAddressIndex].address = street
      }
    },
    async submitAccessData() {
      let obj = {}
      if (this.$route.query.edit === 'true') {
        obj = {
          description: this.notes,
          addresses: this.addressesArr,
          ticket_id: this.ticket_id
        }
      } else {
        obj = {
          description: this.notes,
          addresses: this.addressesArr,
        }
      }
      await this.createAccessDataTicket(obj).then((response) => {
        if (response.success) {
          this.createdTicketId = response.data.ticket_number;
          this.$toast.success('Заявка успешно создана');
          this.toggleSuccessModal();
        } else {
          this.$toast.error('Не удалось создать адрес');
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.img-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 200ms;
}
</style>
