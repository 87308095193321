<template>
  <div class="w-100 p-20 bg-gray rounded">
    <template v-if="clientAgreements.length > 0 && currentAgreement.agrmid">
      <div class="mb-3">
        <div class="card rounded bg-white flex align-center justify-between">
          <p class="text-14 text-black">Договор:</p>
          <div class="flex align-center">
            <p class="text-14 text-black text-right mr-2">
              {{ currentAgreement.number }}
            </p>
            <div class="input-btn">
              <img
                src="/assets/select-arrow-form.svg"
                alt=""
                @click="toggleSelect"
              />
            </div>
          </div>
          <div class="field__search-results" v-if="isSelect">
            <div
              class="field__search-results_item flex justify-between"
              v-for="(agrm, index) in clientAgreements"
              :key="agrm.agrmid"
              @click="selectAgreement(index)"
            >
              <p>{{ agrm.number }}</p>
            </div>
          </div>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="currentAgreement.date">
          <p class="text-14 text-black">Дата заключения:</p>
          <p class="text-14 text-black text-right">
            {{ currentAgreement.date }}
          </p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="currentAgreement.address">
          <p class="text-14 text-black">Адрес:</p>
          <p class="text-14 text-black text-right">
            {{ currentAgreement.address }}
          </p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="currentAgreement.opername">
          <p class="text-14 text-black">Оператор:</p>
          <p class="text-14 text-black text-right">
            {{ currentAgreement.opername }}
          </p>
        </div>
      </div>

      <div
        class="mb-4"
        v-for="vgroup in currentAgreement.vgroups"
        :key="vgroup.vgid"
      >
        <div v-if="vgroup.blocked === 0">
        <p class="text-black text-14 mb-2 bold">{{ vgroup.vgroup_name }}</p>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.login">
          <p class="text-14 text-black">Логин:</p>
          <p class="text-14 text-black text-right">{{ vgroup.login }}</p>
        </div>
        <template
            v-for="(ip, index) in vgroup.ip_address.split(',')"
            :key="ip">
          <div class="card rounded bg-white flex align-center justify-between" v-if="ip">
            <p class="text-14 text-black">IP адрес {{ index + 1 }}:</p>
            <p
                class="text-14 link text-right cursor-pointer"
                @click="fetchConnectionType(ip)"
            >
              {{ ip }}
            </p>
          </div>
        </template>

        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.tarifdescr">
          <p class="text-14 text-black">Тариф:</p>
          <p class="text-14 text-black text-right">{{ vgroup.tarifdescr }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.tariff_above">
          <p class="text-14 text-black">Цена тарифа:</p>
          <p class="text-14 text-black text-right">
            {{ vgroup.tariff_above ? vgroup.tariff_above.split(".")[0] : "" }}
          </p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="currentAgreement.balance">
          <p class="text-14 text-black">Баланс:</p>
          <p class="text-14 text-black text-right">
            {{ currentAgreement.balance }}
          </p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.recommended_sum">
          <p class="text-14 text-black">Рекомендуемая сумма для оплаты:</p>
          <p class="text-14 text-black text-right">{{ vgroup.recommended_sum }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.blocked_status">
          <p class="text-14 text-black">Статус в ЛБ:</p>
          <p class="text-14 text-black text-right">{{ vgroup.blocked_status }}</p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.connection_method">
          <p class="text-14 text-black">Метод подключения:</p>
          <p class="text-14 text-black text-right">
            {{ vgroup.connection_method }}
          </p>
        </div>
        <div class="card rounded bg-white flex align-center justify-between" v-if="vgroup.accondate">
          <p class="text-14 text-black">Дата:</p>
          <p class="text-14 text-black text-right">
            {{ vgroup.accondate ? vgroup.accondate.slice(0, 10) : "" }}
          </p>
        </div>
        <button
          class="dashboard-button"
          @click="createServiceTicket(vgroup.vgid)"
        >
          Создать заявку
        </button>
      </div>
      </div>
    </template>
    <div v-else class="w-100 empty-text text-center text-black uppercase">
      Нет договоров
    </div>
    <custom-modal
      v-if="isIpCheckResultModal"
      @close="toggleIpCheckResult"
      :custom-class="'bg-gray'"
    >
      <ip-check-result-modal />
    </custom-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { maskPhone } from "@/helpers/utils";
import IpCheckResultModal from "@/components/modals/IpCheckResultModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import router from "@/router";

export default {
  name: "ClientAgreementsCard",
  components: { IpCheckResultModal, CustomModal },
  data() {
    return {
      currentAgreement: {},
      isSelect: false,
    };
  },
  mounted() {
    this.currentAgreement = this.clientAgreements[0];
  },
  computed: {
    ...mapGetters({
      clientAgreements: "clientAgreements",
      isIpCheckResultModal: "isIpCheckResultModal",
    }),
  },
  methods: {
    ...mapActions({
      checkIpAddress: "checkIpAddress",
      checkIpExtended: "checkIpExtendedRework",
    }),
    ...mapMutations({
      setIsIpCheckResultModal: "setIsIpCheckResultModal",
    }),
    phone(phone) {
      return maskPhone(phone);
    },
    toggleSelect() {
      this.isSelect = !this.isSelect;
    },
    fetchConnectionType(ip) {
      this.checkIpExtended(ip);
    },
    toggleIpCheckResult() {
      this.setIsIpCheckResultModal(!this.isIpCheckResultModal);
    },
    selectAgreement(index) {
      this.currentAgreement = this.clientAgreements[index];
      this.toggleSelect();
    },
    createServiceTicket(vg_id) {
      router.push(`/service-ticket-form?vg_id=${vg_id}`);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../theme/variables";

.input-btn {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: $gray3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card {
  margin-bottom: 8px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  & > p:first-child {
    width: 35%;
  }
}
</style>
