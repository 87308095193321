<template>
  <div class="tooltip-modal" :class="{'modified': modified}">
    <div class="tooltip-backdrop" @click="$emit('close')"></div>
    <div class="tooltip-body">
      <img class="tooltip-close" src="/assets/close-rounded.svg" alt="" @click="$emit('close')" />
      <div class="text-14 text-black2 uppercase text-center w-100 mt-2 mb-4">
        {{title}}
      </div>
      <slot></slot>
    </div>
  </div>

</template>

<script>


export default {
  name: 'TooltipModal',
  props: ['title', 'modified'],
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

  .tooltip-modal {
    position: absolute;
    top: -16px;
    left: -16px;
    width: 374px;

    .tooltip-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      z-index: 10;
    }

    .tooltip-body {
      position: relative;
      border-radius: 15px;
      width: 100%;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      background: $gray4;
      padding: 16px;
      z-index: 11;
    }

    .tooltip-close {
      position: absolute;
      top: 16px;
      left: 16px;
      cursor: pointer;
    }

    &.modified {
      width: 356px;
    }
  }


</style>
