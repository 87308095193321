<template>
  <button 
    class="base-button" 
    :class="[
      `btn-${color}`,
      { 'btn-outlined': !filled },
      { 'with-icon': icon }
    ]"
    v-bind="$attrs"
  >
    <img 
      v-if="icon" 
      :src="`/assets/${icon}`" 
      :alt="icon"
      class="button-icon"
    />
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'success', 'yellow', 'secondary'].includes(value)
    },
    icon: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables';

.base-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 44px;
  padding: 0 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;

  &.with-icon {
    padding-left: 12px;
  }

  .button-icon {
    width: 20px;
    height: 20px;
  }

  &.btn-primary {
    background: $primary;
    color: white;

    &:hover {
      background: darken($primary, 5%);
    }

    &.btn-outlined {
      background: transparent;
      border-color: $primary;
      color: $primary;

      &:hover {
        background: rgba($primary, 0.1);
      }
    }
  }

  &.btn-success {
    background: linear-gradient(92.65deg, #00C82C 0%, #5AC371 100.1%);
    color: white;

    &:hover {
      background: linear-gradient(92.65deg, #00B528 0%, #4FAF65 100.1%);
    }

    &.btn-outlined {
      background: transparent;
      border-color: #00C82C;
      color: #00C82C;

      &:hover {
        background: rgba(#00C82C, 0.1);
      }
    }
  }

  &.btn-yellow {
    background: #ffd700;
    color: #1a1a1a;

    &:hover {
      background: #ffdb1a;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    &.btn-outlined {
      background: transparent;
      border-color: #ffd700;
      color: #1a1a1a;

      &:hover {
        background: rgba(#ffd700, 0.1);
      }
    }
  }

  &.btn-secondary {
    background: #F5F5FA;
    color: #1a1a1a;

    &:hover {
      background: darken(#F5F5FA, 5%);
    }

    &.btn-outlined {
      background: transparent;
      border-color: #E5E7EB;
      color: #1a1a1a;

      &:hover {
        background: #F5F5FA;
      }
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style> 