<template>
<div class="p-20">
  <div class="p-20">
    <h1 class="text-24 mb-4">Назначение даты подключения</h1>
    <form action="">
      <label class="block mb-1" for="">Дата подключения</label>
      <input class="input-form" type="date" max="2999-12-31" v-model="connection_date">
      <div class="flex gap-2 mt-4">
        <button type="button" class="input-btn cursor-pointer w-100" style="border: none" @click="$emit('closeDate')">Отменить</button>
        <button type="button" class="btn btn-green w-100 text-center text-14" style="padding: 0 12px" @click.prevent="submitChangeDate">Назначить дату</button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "DepartureConnectionPlanDate",
  props: {
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    params: {
      type: null,
      default: {}
    }
  },
  data() {
    return {
      connection_date: '',
    }
  },
  methods: {
    ...mapActions({
      changeConnectionDateDeparture: 'changeConnectionDateDeparture',
      changeConnectionDate: 'changeConnectionDate',
      fetchConnectionList: 'fetchConnectionList'
    }),
    async submitChangeDate(){
      if(this.type === 'connection') {
        await this.changeConnectionDate({id: this.id,planned_date: this.connection_date, reason: 'test'}).then((res)=> {
          if (res.success){
            this.fetchConnectionList(this.params)
            this.$toast.success('Дата успешно изменена')
            this.$emit('closeDate')
          } else {
            this.$toast.error('Не удалось изменить дату')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        })
      } else {
        await this.changeConnectionDateDeparture({id: this.id,connection_date: this.connection_date}).then((res)=> {
          if (res.success){
            this.$toast.success('Дата успешно изменена')
            this.$emit('closeDate')
          } else {
            this.$toast.error('Не удалось изменить дату')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        })
      }

    }
  },
}
</script>

<style scoped lang="scss">

</style>