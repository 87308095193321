<template>
  <div class="wrapper modified flex column">
    <div class="mb-3">
      <div class="bg-white rounded" style="padding: 12px 16px;">
        <div class="w-100">
          <div class="table-title">
            <div class="flex w-100 mb-1" style="justify-content: space-between; align-items: center;">
              <div>Закрытые аварийные заявки</div>
              <div class="flex gap-1">
                <div class="input-container input-container__form" style="width: fit-content">
                  <select class="input input-form select"
                          v-model="selectedMonth"
                          :class="{'empty': !selectedMonth}"
                          @change="updateDateRange"
                          ref="selectedMonth">
                    <option v-for="(month, index) in months" :key="index" :value="index + 1">
                      {{ month }}
                    </option>
                  </select>
                </div>
                <div style="width: 200px;">
                  <filter-bar
                      :is-create="true"
                      :is-search="false" :is-sort="false"
                      :is-filter="false" :is-date="false"
                      :is-table-options="true"
                      create-text="Создать заявку"
                      :search="search"
                      @on-create="toggleCreateModal"
                      @on-search="searchTickets"
                      @on-filter="filterModalSubmit"
                      @toggleTableOptions="toggleTableOptions"/>
                </div>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="mb-1" style="width: 200px;">
                <label class="text-20">Показывать по</label>
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    label="name"
                    track-by="id"
                ></multiselect>
              </div>
            </div>
          </div>
          <custom-table
              :columns="emergencyTableColumns"
              :data="emergencyTickets"
              :total-pages="emergencyTicketsTotalPages"
              :current-page="emergencyCurrentPage"
              @get-detail="openTicket"
              @move-column="moveEmergencyTableColumn"
              @updateComment="updateComment"
          />
          <PaginationComponent :total-pages="emergencyTicketsTotalPages"
                               :current-page="emergencyCurrentPage"
                               @page-change="fetchFilteredEmergencyTickets"
          />
        </div>
      </div>

    </div>


    <custom-modal v-if="isCreateTicketModal" @close="toggleCreateModal">
      <choose-ticket-type @choose-ticket-kind="chooseTicketKind"/>
    </custom-modal>
    <custom-modal v-if="isCurrentSituationComment" @close="isCurrentSituationComment = !isCurrentSituationComment">
      <current-situation-comment :ticket="currentComment"
                                 @close="closeComment"
                                 @shut="isCurrentSituationComment = !isCurrentSituationComment"></current-situation-comment>
    </custom-modal>

    <custom-modal v-if="isTableOptionsModal" @close="isTableOptionsModal = !isTableOptionsModal">
      <table-options-modal @close="isTableOptionsModal = !isTableOptionsModal" :tableColumns="emergencyTableColumns"
                           @getNewTableColumns="getNewTableColumns"></table-options-modal>
    </custom-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CustomTable from '@/components/modules/CustomTable.vue'
import {formatDate} from "@/helpers/utils";
import CustomModal from "@/components/modules/CustomModal.vue";
import ChooseTicketType from "@/components/modals/tickets/ChooseTicketType.vue";
import {DutyScheduleShortColumns} from "@/modules/table-columns";
import {EmergencyTicketsTableColumns, PlanTicketsTableColumns} from "@/modules/table-columns";
import FilterBar from "@/components/modules/FilterBar.vue";
import router from "@/router";
import CurrentSituationComment from "@/components/modals/CurrentSituationComment.vue";
import TableOptionsModal from "@/components/modals/TableOptionsModal.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import Multiselect from "vue-multiselect";

export default {
  name: 'EmergencyOnlyView',
  components: {
    Multiselect,
    PaginationComponent,
    TableOptionsModal,
    CurrentSituationComment,
    FilterBar,
    CustomTable,
    ChooseTicketType,
    CustomModal,
  },
  data() {
    return {
      emergencyTableColumns: [],
      planTableColumns: [],
      isCreateTicketModal: false,
      search: '',
      searchTimeout: null,
      emergencyCurrentPage: 1,
      planCurrentPage: 1,
      isOnlyOpen: true,
      emergencyPerPage: 5,
      planPerPage: 5,
      tableColumns: DutyScheduleShortColumns,
      isDutyScheduleModal: false,
      isCreateDutyScheduleModal: false,
      isSuccessModal: false,
      currentDutySchedule: [],
      isCurrentSituationComment: false,
      currentComment: [],
      intervalId: null,
      isTableOptionsModal: false,
      months: [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
      ],
      selectedMonth: new Date().getMonth() + 1,
      dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      dateTo: new Date(),
      perPage: {id: 100, name: 100},
      perPageOptions: [
        {id: 10, name: 10},
        {id: 25, name: 25},
        {id: 50, name: 50},
        {id: 100, name: 100},
        {id: 500, name: 500}
      ],
    }
  },
  created() {
    this.onFirstLoad()
    this.intervalId = setInterval(this.onFirstLoad, 2 * 60 * 1000)
    this.fetchFilteredEmergencyTickets()
  },
  computed: {
    ...mapGetters({
      emergencyTickets: 'emergencyTickets',
      user: 'user',
      emergencyTicketsTotalPages: 'emergencyTicketsTotalPages',
      emergencyTotalTickets: 'emergencyTotalTickets',
      planTickets: 'planTickets',
      planTicketsTotalPages: 'planTicketsTotalPages',
      planTotalTickets: 'planTotalTickets',
      ticketKinds: 'ticketKinds',
      serviceTypes: 'serviceTypes',
      statuses: 'statuses',
      managers: 'managers',
      chosenTicket: 'chosenTicket',
      ticketCounts: 'ticketCounts',
      scheduleAll: 'getScheduleAll',
    }),
  },
  methods: {
    ...mapActions({
      fetchStatuses: 'fetchStatuses',
      fetchTickets: 'fetchTickets',
      fetchTicketKinds: 'fetchTicketKinds',
      fetchServiceTypes: 'fetchServiceTypes',
      fetchManagers: 'fetchManagers',
      fetchEmergencyTickets: 'fetchEmergencyTickets',
      fetchPlanTickets: 'fetchPlanTickets',
      fetchScheduleAll: "fetchDutyScheduleAll",
      fetchScheduleDepartment: "fetchDutyScheduleDepartment",
      fetchTicketDetail: 'ticketDetail',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    getTitleByKey(array, key) {
      if (array.find(item => item.key === key)) return array.find(item => item.key === key).count
    },
    async chooseTicketKind(id) {
      this.toggleCreateModal()
      if (id === 2) {
        await router.push('/service-ticket-form')
      } else if (id === 8) {
        await router.push('/plan-ticket-form')
      } else if (id === 10) {
        await router.push('/consultation-ticket-form')
      } else if (id === 12) {
        await router.push('/internal-ticket-form')
      } else if (id === 16) {
        await router.push('/access-form-view')
      }
      else await router.push('/emergency-ticket-form')
    },
    toggleCreateModal() {
      this.isCreateTicketModal = !this.isCreateTicketModal
    },
    moveEmergencyTableColumn(columns) {
      this.emergencyTableColumns = columns
      window.localStorage.setItem('EmergencyTicketsTableColumns', JSON.stringify(columns))
    },
    movePlanTableColumn(columns) {
      this.emergencyTableColumns = columns
      window.localStorage.setItem('PlanTicketsTableColumns', JSON.stringify(columns))
    },
    async openTicket(ticket) {
      this.setLoading(true)
      this.$router.push(`/emergency/ticket/${ticket.ticket_number}`)
      this.setLoading(false)
    },
    searchTickets(searchText) {
      this.search = searchText
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchFilteredEmergencyTickets()
      }, 1500)
    },
    filterModalSubmit(filters) {
      this.author = filters.author
      this.responsible = filters.responsible
      // this.selectedStatuses = filters.statuses
      this.serviceType = filters.serviceType
      this.fetchFilteredEmergencyTickets()
    },
    async fetchFilteredEmergencyTickets(page = 1) {
      this.currentPage = page
      this.setLoading(true)
      let filters = {
        date_from: this.formatDate(this.dateFrom),
        date_to: this.formatDate(this.dateTo),
        per_page:this.perPage.id
      }
      // filters.page = page
      // filters.tickets_per_page = this.emergencyPerPage
      // this.emergencyCurrentPage = page
      // if (this.search) filters.client_name = this.search
      filters.is_closed = 1
      await this.fetchEmergencyTickets(filters)


      this.setLoading(false)
    },
    async fetchFilteredPlanTickets(page = 1) {
      this.setLoading(true)
      let filters = {}
      // filters.page = page
      // filters.tickets_per_page = this.planPerPage
      filters.is_only_open = 0
      // this.planCurrentPage = page
      // if (this.search) filters.client_name = this.search

      await this.fetchPlanTickets(filters)


      this.setLoading(false)
    },
    async getCurrentDutySchedule() {
      this.setLoading(true)
      await this.fetchScheduleAll()
      const parsedDate = new Date()
      const res = this.scheduleAll.filter(element => {
        const startTime = new Date(element.started_at)
        const endTime = new Date(element.ended_at)
        return parsedDate >= startTime && parsedDate <= endTime
      })
      this.currentDutySchedule = res
      this.setLoading(false)
    },
    async updateComment(item) {
      this.isCurrentSituationComment = true
      this.currentComment = item
    },
    async closeComment() {
      this.isCurrentSituationComment = false
      await this.onFirstLoad()
    },
    async onFirstLoad() {
      if (window.localStorage.getItem('EmergencyTicketsTableColumns')) this.emergencyTableColumns = JSON.parse(window.localStorage.getItem('EmergencyTicketsTableColumns'))
      else this.emergencyTableColumns = EmergencyTicketsTableColumns
      if (window.localStorage.getItem('PlanTicketsTableColumns')) this.planTableColumns = JSON.parse(window.localStorage.getItem('PlanTicketsTableColumns'))
      else this.planTableColumns = PlanTicketsTableColumns
      this.setLoading(true)
      let promises = [this.fetchFilteredEmergencyTickets()]
      if (!this.statuses || !this.statuses.length) promises.push(this.fetchStatuses())
      if (!this.ticketKinds || !this.ticketKinds.length) promises.push(this.fetchTicketKinds())
      if (!this.managers || !this.managers.length) promises.push(this.fetchManagers())
      await Promise.all(promises).then(() => {
        this.setLoading(false)
      })
    },
    getNewTableColumns(newTable) {
      this.emergencyTableColumns = newTable
      window.localStorage.setItem('EmergencyTicketsTableColumns', JSON.stringify(newTable))
      this.isTableOptionsModal = false
    },
    toggleTableOptions() {
      this.isTableOptionsModal = true
    },
    updateDateRange() {
      if (this.selectedMonth !== null) {
        const year = new Date().getFullYear();
        const monthIndex = this.selectedMonth - 1;

        this.dateFrom = new Date(year, monthIndex, 1);
        this.dateTo = new Date(year, monthIndex + 1, 0);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return formatDate(date, '-', false)
    },
    // watch: {
    //   currentTab() {
    //     this.fetchFilteredEmergencyTickets()
    //   },
    // }
  },

  beforeUnmount() {
    clearInterval(this.intervalId)
  },
  watch: {
    dateFrom() {
      this.fetchFilteredEmergencyTickets()
    },
    perPage() {
      this.fetchFilteredEmergencyTickets()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';


.ticket-menu {
  padding: 16px;
  border-radius: 25px;
  background: #FFF;
  color: $black2;
  font-size: 20px;
  font-style: normal;
  width: 240px;
  font-weight: 500;
  height: 56px;

  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.table-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 43.2px;
  margin: px 0;
}
</style>
