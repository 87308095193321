<template>
  <div class="wrapper modified flex column">
    <div class="block">
      <upsells-details-component v-if="details?.connection_ticket?.id" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UpsellsDetailsComponent from "@/components/cards/upsells/UpsellsDetailsComponent.vue";

export default {
  name: 'TicketDetailView',
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UpsellsDetailsComponent
  },
  created() {
    if (this.isModal === false) {
      this.fetchTicketDetail(this.$route.params.id)
    }
  },
  watch: {
    '$route.params.id'(newId) {
      this.fetchTicketDetail(newId);
    }
  },
  computed: {
    ...mapGetters({
      details: "chosenConnectionTicket",
    })
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'fetchConnectionTicketDetail',
    })
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

</style>
