import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import LoginView from "@/views/auth/LoginView.vue";
import TicketsView from '../views/serviceTickets/TicketsView.vue'
import HomeView from "@/views/auth/HomeView.vue";
import UsersView from "@/views/users/UserView.vue";
import Cookies from 'js-cookie';
import WorktimeView from "@/views/worktime/WorktimeView.vue";
import DevView from "@/views/auth/DevView.vue";
import ResetView from "@/views/auth/ResetView.vue";
import ForgotView from "@/views/auth/ForgotView.vue";
import NotificationView from "@/views/notifications/NotificationView.vue";
import NotificationFormView from "@/views/notifications/NotificationFormView.vue";
import EmergencyTicketFormView from "@/views/emergencyTickets/EmergencyTicketFormView.vue";
import PlanTicketFormView from "@/views/planTicket/PlanTicketFormView.vue";
import ServiceTicketFormView from "@/views/serviceTickets/ServiceTicketFormView.vue";
import ConnectionTicketsView from "@/views/clientConnection/ConnectionTicketsView.vue";
import CalendarView from "@/views/calendar/CalendarView.vue";
import ConsultationTicketFormView from "@/views/consultation/ConsultationTicketFormView.vue";
import TicketDetailView from "@/views/serviceTickets/TicketDetailView.vue";
import EmergencyTicketsView from "@/views/emergencyTickets/EmergencyTicketsView.vue";
import InternalTicketFormView from "@/views/internalTicket/InternalTicketFormView.vue";
import CabinetTicketsView from "@/views/cabinetTickets/CabinetTicketsView.vue";
import CurrentSituationView from "@/views/employeeTickets/CurrentSituationView.vue";
import DutySchedule from "@/views/dutySchedule/DutySchedule.vue";
import Chat from "@/views/chat/Chat.vue";
import BrigadeOptions from "@/views/brigadeControl/BrigadeOptions.vue"
import Instructions from "@/views/instructions/Instructions.vue";
import InstructionsCreate from "@/components/wiki/InstructionsCreate.vue";
import InstructionView from "@/components/wiki/InstructionView.vue";
import InstructionEdit from "@/components/wiki/InstructionEdit.vue";
import InstructionPage from "@/components/wiki/InstructionPage.vue";
import RolesView from "@/views/roles/RolesView.vue";
import PermissionsView from "@/views/permissions/PermissionsView.vue";
import ReportView from "@/views/reports/ReportView.vue";
import DepartureServiceTicketsB2CView from "@/views/departureService/DepartureServiceTicketsB2CView.vue";
import EmergencyOnlyView from "@/views/closedEmergency/EmergencyOnlyView.vue";
import AccessDataView from "@/views/accessData/AccessDataView.vue";
import AccessFormView from "@/views/accessData/AccessFormView.vue";
import AccessDataTicketsView from "@/views/accessData/AccessDataTicketsView.vue";
import AccessDataPlainFormView from "@/views/accessData/AccessDataPlainFormView.vue";
import AccessDataTicketDetailView from "@/views/accessData/AccessDataTicketDetailView.vue";
import QueriesTCView from "@/views/queriesTC/QueriesTCView.vue"
import QueriesTCDetailView from "@/views/queriesTC/QueriesTCDetailView.vue";
import EmergencyTicketDetailView from "@/views/emergencyTickets/EmergencyTicketDetailView.vue";
import SubtaskTicketDetailView from "@/views/subtaskTickets/SubtaskTicketDetailView.vue";
import TicketsLCSView from "@/views/ticketsLCS/TicketsLCS.vue";
import TicketLCSDetailView from "@/views/ticketsLCS/TicketLCSDetailView.vue";
import UpdateTicketLCSFormView from "@/views/ticketsLCS/UpdateTicketLCSFormView.vue"
import CreateTicketLCSFormView from "@/views/ticketsLCS/CreateTicketLCSFormView.vue"
import AreasManagementView from "@/views/areas/AreasManagementView.vue"
import PolygonsManagement from "@/views/polygons/PolygonsManagement.vue";
import DepartureServiceTicketsB2BView from "@/views/departureService/DepartureServiceTicketsB2BView.vue";
import DisassemblyView from "@/views/disassembly/disassemblyView.vue";
import DisassemblyDetailView from "@/views/disassembly/disassemblyDetailView.vue";
import UpsellsB2BView from "@/views/upsells/UpsellsB2BView.vue";
import UpsellsB2CView from "@/views/upsells/UpsellsB2CView.vue";
import UpsellsDetailView from "@/views/upsells/UpsellsDetailView.vue";
import ServicesTransferB2BView from "@/views/servicesTransfer/ServicesTransferB2BView.vue";
import ServicesTransferB2CView from "@/views/servicesTransfer/ServicesTransferB2CView.vue";
import ServicesTransferDetailView from "@/views/servicesTransfer/ServicesTransferDetailView.vue";
import ConnectionB2CView from "@/views/connection/ConnectionB2CView.vue";
import ConnectionB2BView from "@/views/connection/ConnectionB2BView.vue";
import ConnectionDetailView from "@/views/connection/ConnectionDetailView.vue";
import AreasAnalyticsView from "@/views/areas/AreasAnalyticsView.vue";
import DepartmentsTicketsView from "@/views/departmentsTickets/DepartmentsTicketsView.vue";
import LoadChartReportView from "@/views/reports/LoadChartReportView.vue";
import OTPEmployeesTicketsView from "@/views/OTPemployeesTickets/OTPEmployeesTicketsView.vue";
import RolesPermissionsEdit from "@/components/roles/RolesPermissionsEdit.vue";
import VirtualWarehouse from "@/views/virtualWarehouse/VirtualWarehouse.vue";
import CabinetTicketDetailView from "@/views/cabinetTickets/CabinetTicketDetailView.vue";
import CabinetTicketFormView from "@/views/cabinetTickets/CabinetTicketFormView.vue";
import CommutationTickets from "@/views/commutationTickets/CommutationTickets.vue";
import CommutationTicketDetailView from "@/views/commutationTickets/CommutationTicketDetailView.vue";
import PreparingConnectionsTickets from "@/views/preparingConnectionsTickets/PreparingConnectionsTickets.vue";
import PreparingConnectionsTicketDetailView
  from "@/views/preparingConnectionsTickets/PreparingConnectionsTicketDetailView.vue";
import ConnectionImprovementsTickets from "@/views/connectionImprovementsTickets/ConnectionImprovementsTickets.vue";
import ConnectionImprovementsTicketDetailView
  from "@/views/connectionImprovementsTickets/ConnectionImprovementsTicketDetailView.vue";
import SksB2BView from "@/views/sks/SksB2BView.vue";
import DisassemblyImprovementsTickets from "@/views/disassemblyImprovements/DisassemblyImprovementsTickets.vue";
import DisassemblyImprovementsTicketDetailView from "@/views/disassemblyImprovements/DisassemblyImprovementsTicketDetailView.vue";
import MonetizationReportView from "@/views/monetizationReport/MonetizationReportView.vue"
import MonetizationView from "@/views/monetization/MonetizationView.vue";
import EmergencyTicketsInfoView from "@/views/emergencyTickets/EmergencyTicketsInfoView.vue";
import PlanTicketsView from "@/views/planTicket/PlanTicketsView.vue";
import RolePagesAccess from "@/views/roles/RolePagesAccess.vue";
import { fullAccessRoles } from '@/config/roles'
import TasksView from '@/views/tasks/TasksView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Дашборд'},
    component: HomeView
  },
  {
    path: '/tickets',
    name: 'tickets-list',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СПИСОК ЗАЯВОК'},
    component: TicketsView
  },
  {
    path: '/commutation-tickets',
    name: 'commutation-tickets',
    meta: { title: 'ЗАЯВКИ', subtitle: 'Заявки на коммутацию'},
    component: CommutationTickets
  },
  {
    path: '/commutation-tickets/:id',
    name: 'commutation-tickets-details',
    meta: { title: 'ЗАЯВКА НА КОММУТАЦИЮ'},
    component: CommutationTicketDetailView
  },
  {
    path: '/preparing-connections-tickets',
    name: 'preparing-connections-tickets',
    meta: { title: 'ЗАЯВКИ', subtitle: 'Подготовка подключений'},
    component: PreparingConnectionsTickets
  },
  {
    path: '/preparing-connections-tickets/:id',
    name: 'preparing-connections-tickets-details',
    meta: { title: 'ПОДГОТОВКА ПОДКЛЮЧЕНИЙ'},
    component: PreparingConnectionsTicketDetailView
  },
  {
    path: '/connection-improvements-tickets',
    name: 'connection-improvements-tickets',
    meta: { title: 'ЗАЯВКИ', subtitle: 'Заявки на доработку подключения'},
    component: ConnectionImprovementsTickets
  },
  {
    path: '/connection-improvements-tickets/:id',
    name: 'connection-improvements-tickets-details',
    meta: { title: 'ЗАЯВКА НА ДОРАБОТКУ ПОДКЛЮЧЕНИЯ'},
    component: ConnectionImprovementsTicketDetailView
  },
  {
    path: '/queries-technical-capability',
    name: 'queries-technical-capability-list',
    meta: { title: 'ЗАЯВКИ', subtitle: 'ЗАПРОСЫ НА ТВ'},
    component: QueriesTCView
  },
  {
    path: '/queries-technical-capability/:id',
    name: 'queries-technical-capability-details',
    meta: { title: 'ЗАЯВКА', subtitle: 'ЗАПРОСЫ НА ТВ'},
    component: QueriesTCDetailView
  },
  {
    path: '/tickets-lcs',
    name: 'tickets-lcs-list',
    meta: { title: 'ЗАЯВКИ', subtitle: 'ЗАЯВКИ НА ЛКС'},
    component: TicketsLCSView
  },
  {
    path: '/tickets-OTP',
    name: 'tickets-OTP-list',
    meta: { title: 'ЗАЯВКИ', subtitle: 'ЗАЯВКИ ПО СОТРУДНИКАМ ОТП'},
    component: OTPEmployeesTicketsView
  },
  {
    path: '/ticket-lcs/:id',
    name: 'ticket-lcs-details',
    meta: { title: 'ЗАЯВКА'},
    component: TicketLCSDetailView
  },
  {
    path: '/departments-tickets',
    name: 'departments-tickets-list',
    meta: { title: 'ЗАЯВКИ', subtitle: 'ЗАЯВКИ ПО ОТДЕЛАМ'},
    component: DepartmentsTicketsView
  },
  {
    path: '/update-ticket-lcs-form',
    name: 'update-ticket-lcs-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'РЕДАКТИРОВАНИЕ ЗАЯВКИ ЛКС'},
    component: UpdateTicketLCSFormView
  },
  {
    path: '/create-ticket-lcs-form',
    name: 'create-ticket-lcs-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ ЗАЯВКИ ЛКС'},
    component: CreateTicketLCSFormView
  },
  {
    path: '/emergency-tickets',
    name: 'emergency-tickets-list',
    meta: { title: 'АВАРИЙНЫЕ ЗАЯВКИ'},
    component: EmergencyTicketsView
  },
  {
    path: '/emergency/ticket/:id',
    name: 'emergency-tickets',
    meta: { title: 'АВАРИЙНЫЕ ЗАЯВКИ'},
    component: EmergencyTicketDetailView
  },
  {
    path: '/emergency-tickets-only',
    name: 'emergency-tickets-only',
    meta: { title: 'АВАРИЙНЫЕ ЗАЯВКИ'},
    component: EmergencyOnlyView
  },
  {
    path: '/emergency-tickets-info',
    name: 'emergency-tickets-info',
    meta: { title: 'АВАРИЙНЫЕ ЗАЯВКИ'},
    component: EmergencyTicketsInfoView
  },
  {
    path: '/access-tickets',
    name: 'access-tickets',
    meta: { title: 'Заявки на доступ'},
    component: AccessDataTicketsView
  },
  {
    path: '/ticket/:id',
    name: 'ticket-details',
    meta: { title: 'ЗАЯВКА'},
    component: TicketDetailView
  },
  {
    path: '/access/ticket/:id',
    name: 'access-ticket-details',
    meta: { title: 'ЗАЯВКА'},
    component: AccessDataTicketDetailView
  },
  {
    path: '/service-tickets/:id',
    name: 'service-ticket-details',
    meta: { title: 'ЗАЯВКА' },
    component: TicketDetailView
  },
  {
    path: '/connection-ticket/:id',
    name: 'connection-ticket-details',
    meta: { title: 'ЗАЯВКА НА ПОДКЛЮЧЕНИЕ'},
    component: ConnectionDetailView
  },
  {
    path: '/connection-tickets',
    name: 'connection-tickets',
    meta: { title: 'ЗАЯВКИ', subtitle: 'ПОДКЛЮЧЕНИЕ'},
    component: ConnectionTicketsView
  },
  {
    path: '/subtask/ticket/:id',
    name: 'subtask-ticket-details',
    meta: { title: 'ЗАЯВКА'},
    component: SubtaskTicketDetailView
  },
  {
    path: '/service-ticket-form',
    name: 'service-ticket-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ НОВОЙ ЗАЯВКИ'},
    component: ServiceTicketFormView
  },
  {
    path: '/emergency-ticket-form',
    name: 'emergency-ticket-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ НОВОЙ ЗАЯВКИ'},
    component: EmergencyTicketFormView
  },
  {
    path: '/plan-ticket-form',
    name: 'plan-ticket-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ НОВОЙ ЗАЯВКИ'},
    component: PlanTicketFormView
  },
  {
    path: '/plan-tickets-view',
    name: 'plan-tickets-view',
    meta: { title: 'Плановая работа'},
    component: PlanTicketsView
  },
  {
    path: '/consultation-ticket-form',
    name: 'consultation-ticket-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ НОВОЙ ЗАЯВКИ'},
    component: ConsultationTicketFormView
  },
  {
    path: '/internal-ticket-form',
    name: 'internal-ticket-form',
    meta: { title: 'ЗАЯВКИ', subtitle: 'СОЗДАНИЕ НОВОЙ ЗАЯВКИ'},
    component: InternalTicketFormView
  },
  {
    path: '/access-form-view',
    name: 'access-form-view',
    meta: { title: 'ЗАЯВКИ ДОСТУПА', subtitle: 'СОЗДАНИЕ НОВОГО ДОСТУПА'},
    component: AccessDataPlainFormView
  },
  {
    path: '/users',
    name: 'users',
    meta: { title: 'Пользователи'},
    component: () => import('@/views/users/UsersLayout.vue'),
    children: [
      {
        path: 'managers',
        name: 'managers',
        meta: { title: 'Менеджеры'},
        component: () => import('@/views/users/UserView.vue')
      },
      {
        path: 'technicians',
        name: 'technicians',
        meta: { title: 'Техники'},
        component: () => import('@/views/users/TechniciansView.vue')
      }
    ]
  },
  {
    path: '/cabinet-tickets',
    name: 'cabinet-tickets',
    meta: { title: 'ЗАЯВКИ С ЛК'},
    component: CabinetTicketsView
  },
  {
    path: '/cabinet-tickets/:id',
    name: 'cabinet-tickets-details',
    meta: { title: 'ЗАЯВКИ С ЛК'},
    component: CabinetTicketDetailView
  },
  {
    path: '/cabinet-ticket-form-view',
    name: 'cabinet-ticket-form-view',
    meta: { title: 'ЗАЯВКИ С ЛК'},
    component: CabinetTicketFormView
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: { title: 'КАЛЕНДАРЬ ЗАЯВОК'},
    component: CalendarView
  },
  {
    path: '/worktime',
    name: 'worktime',
    meta: { title: 'Крайние сроки по заявкам'},
    component: WorktimeView
  },
  {
    path: '/push-notifications',
    name: 'notifications-list',
    meta: { title: 'ЦЕНТР УВЕДОМЛЕНИЙ', subtitle: 'СПИСОК УВЕДОМЛЕНИЙ', },
    component: NotificationView
  },
  {
    path: '/push-notifications-form',
    name: 'notifications-form',
    meta: { title: 'ЦЕНТР УВЕДОМЛЕНИЙ', subtitle: 'Создание уведомления', },
    component: NotificationFormView
  },
  {
    path: '/current-situation',
    name: 'current-situation',
    meta: { title: 'ТЕКУЩАЯ СИТУАЦИЯ' },
    component: CurrentSituationView
  },
  {
    path: '/login',
    name: 'authLogin',
    meta: { notRequiresAuth: true },
    component: LoginView
  },
  {
    path: '/reset',
    name: 'authReset',
    meta: { notRequiresAuth: true },
    component: ResetView
  },
  {
    path: '/forgot',
    name: 'authForgot',
    meta: { notRequiresAuth: true },
    component: ForgotView
  },
  {
    path: '/dev',
    name: 'dev',
    component: DevView
  },
  {
    path: '/duty-schedule',
    name: 'dutySchedule',
    meta: { title: 'График дежурств'},
    component: DutySchedule
  },
  {
    path: '/chat',
    name: 'chat',
    meta: { title: 'Чат'},
    component: Chat
  },
  {
    path: '/brigade-options',
    name: 'Brigade Options',
    meta: { title: 'Управление бригадами'},
    component: BrigadeOptions
  },
  {
    path: '/instructions',
    name: 'instructions',
    meta: { title: 'Инструкции'},
    component: Instructions
  },
  {
    path: '/instructions/create',
    name: 'create instruction',
    meta: { title: 'Инструкции - создание'},
    component:  InstructionsCreate
  },
  {
    path: '/instructions/:id',
    name: 'instruction view',
    meta: { title: 'Инструкции - просмотр'},
    component:  InstructionView
  },
  {
    path: '/instructions/:id/edit',
    name: 'instruction edit',
    meta: { title: 'Инструкции - редактировать'},
    component:  InstructionEdit
  },
  {
    path: '/instructions/visit/:id',
    name: 'instruction page',
    meta: { title: 'Инструкции - страница'},
    component:  InstructionPage
  },
  {
    path: '/connection-b2c',
    name: 'connection-b2c-list',
    meta: { title: 'На выезд', subtitle: 'Подключение B2C'},
    component:  ConnectionB2CView
  },
  {
    path: '/connection-b2b',
    name: 'connection-b2b-list',
    meta: { title: 'На выезд', subtitle: 'Подключение B2B'},
    component:  ConnectionB2BView
  },
  {
    path: '/connection/:id',
    name: 'connection-details',
    meta: { title: 'На выезд', subtitle: 'Подключение - просмотр'},
    component:  ConnectionDetailView
  },
  {
    path: '/disassembly',
    name: 'disassembly-list',
    meta: { title: 'Заявки', subtitle: 'Демонтажи'},
    component:  DisassemblyView
  },
  {
    path: '/disassembly/:id',
    name: 'disassembly-details',
    meta: { title: 'Демонтажи', subtitle: 'Демонтажи - просмотр'},
    component:  DisassemblyDetailView
  },
  {
    path: '/upsells-b2b',
    name: 'upsells-b2b-list',
    meta: { title: 'На выезд', subtitle: 'Допродажи B2B'},
    component:  UpsellsB2BView
  },
  {
    path: '/upsells-b2c',
    name: 'upsells-b2c-list',
    meta: { title: 'На выезд', subtitle: 'Допродажи B2C'},
    component:  UpsellsB2CView
  },
  {
    path: '/upsells/:id',
    name: 'upsells-details',
    meta: { title: 'На выезд', subtitle: 'Допродажи - просмотр'},
    component:  UpsellsDetailView
  },
  {
    path: '/services-transfer-b2b',
    name: 'services-transfer-b2b-list',
    meta: { title: 'На выезд', subtitle: 'Перенос услуг B2B'},
    component:  ServicesTransferB2BView
  },
  {
    path: '/services-transfer-b2c',
    name: 'services-transfer-b2c-list',
    meta: { title: 'На выезд', subtitle: 'Перенос услуг B2C'},
    component:  ServicesTransferB2CView
  },
  {
    path: '/services-transfer/:id',
    name: 'services-transfer-details',
    meta: { title: 'На выезд', subtitle: 'Перенос услуг - просмотр'},
    component:  ServicesTransferDetailView
  },
  {
    path: '/departure/service-b2c',
    name: 'Сервисные заявки B2C',
    meta: { title: 'На выезд', subtitle: 'Сервисные заявки B2C'},
    component:  DepartureServiceTicketsB2CView
  },
  {
    path: '/departure/service-b2b',
    name: 'Сервисные заявки B2B',
    meta: { title: 'На выезд', subtitle: 'Сервисные заявки B2B'},
    component:  DepartureServiceTicketsB2BView
  },
  {
    path: '/roles',
    name: 'Роли',
    meta: {title: 'Роли'},
    component: RolesView
  },
  {
    path: '/roles/pages/:id',
    name: 'Доступ к страницам',
    meta: {title: 'Управление доступом к страницам'},
    component: RolePagesAccess
  },
  {
    path: '/roles/:key',
    name: 'Редактирование роли',
    meta: {title: 'Редактирование роли'},
    component: RolesPermissionsEdit
  },
  {
    path: '/roles/permissions',
    name: 'Разрешения',
    meta: {title: 'Роли - разрешения'},
    component: PermissionsView
  },
  {
    path: '/reports',
    name: 'Отчет 66',
    meta: { title: 'Отчеты', subtitle: '66'},
    component:  ReportView
  },
  {
    path: '/reports/load-chart',
    name: 'График нагрузки',
    meta: {title: 'Отчеты', subtitle: 'График нагрузки'},
    component:  LoadChartReportView
  },
  {
    path: '/access',
    name: 'Доступы',
    meta: { title: 'Доступы', subtitle: 'База доступов'},
    component:  AccessDataView
  },
  {
    path: '/areas-management',
    name: 'areas-management',
    meta: { title: 'Управление участками'},
    component: AreasManagementView
  },
  {
    path: '/areas-analytics',
    name: 'areas-analytics',
    meta: { title: 'Аналитика по участкам'},
    component: AreasAnalyticsView
  },
  {
    path: `/polygons`,
    name: 'Полигоны доступов',
    meta: {title: 'Полигоны', subtitle: 'Полигоны доступов'},
    component: PolygonsManagement
  },
  {
    path: '/virtual-warehouse',
    name: 'virtual-warehouse',
    meta: { title: 'Виртуальный склад'},
    component:  VirtualWarehouse
  },
  {
    path: '/monetization-report',
    name: 'monetization-report',
    meta: { title: 'Отчет ТО'},
    component:  MonetizationReportView
  },
  {
    path: '/sks-b2b',
    name: 'sks-b2b-list',
    meta: { title: 'На выезд', subtitle: 'Заявки на СКС B2B'},
    component: SksB2BView
  },
  {
    path: '/disassembly-improvements-tickets',
    name: 'disassembly-improvements-tickets',
    meta: { title: 'ЗАЯВКИ', subtitle: 'Доработки демонтажей B2B'},
    component: DisassemblyImprovementsTickets
  },
  {
    path: '/disassembly-improvements-tickets/:id',
    name: 'disassembly-improvements-tickets-details',
    meta: { title: 'Доработки демонтажей'},
    component: DisassemblyImprovementsTicketDetailView
  },
  {
    path: '/monetization',
    name: 'monetization',
    meta: { title: 'Монетизация', subtitle: 'Настройки бонусов'},
    component: MonetizationView
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: { title: 'Поручения'},
    component: TasksView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit('setCurrentPage', to)
  
  if (to.meta.notRequiresAuth) {
    return next()
  }

  const token = localStorage.getItem("token");
  const user = store.getters.user

  if (token && !store.getters.isFirstLoad) {
    await store.commit('setIsFirstLoad', true)
    await store.dispatch('fetchUnreadNotificationsCount')
  }

  if (!token) {
    return next({ path: 'login' })
  }

  if (!user.id && !from.name?.indexOf('auth') > -1) {
    await store.dispatch('checkLogin')
  }

  if (fullAccessRoles.includes(store.getters.user?.role?.key)) {
    return next()
  }

  const userPages = store.getters.user?.role?.pages || []
  
  // Если массив пустой, то доступ к всем страницам
  if (userPages.length === 0) {
    return next()
  }

  // Если в массиве есть ключ, то доступ к странице
  const allowedPageKeys = userPages.map(page => page.key)
  if (allowedPageKeys.includes(to.name)) {
    return next()
  } else {
    store.dispatch('errorAlert', 'У вас нет доступа к этой странице')
    return next({ path: '/' })
  }
})

export default router
