import {api} from '@/service/axios'

export const monetizationReportModule = {
    state: () => ({
        monetizationReport: [],
        monetizationReportTotalPages: 0,
        totalMonetizationReport: 0,
        companies: [],
        workTypes: []
    }),

    getters: {
        monetizationReport: state => state.monetizationReport,
        monetizationReportTotalPages: state => state.monetizationReportTotalPages,
        totalMonetizationReport: state => state.totalMonetizationReport,
        companies: state => state.companies,
        workTypes: state => state.workTypes,
    },

    mutations: {
        setMonetizationReport(state, payload) {
            state.monetizationReport = payload
        },
        setMonetizationReportTotalPages(state, payload) {
            state.monetizationReportTotalPages = payload
        },
        setTotalMonetizationReport(state, payload) {
            state.totalMonetizationReport = payload
        },
        setCompanies(state, payload) {
            state.companies = payload
        },
        setWorkTypes(state, payload) {
            state.workTypes = payload
        },
    },

    actions: {
        async getMonetizationReport({commit}, payload) {
            const res = await api.get(`installer/reward/histories`, {
                params: payload
            })
            commit('setMonetizationReport', res)
        },
        async getCompanies({commit}, payload) {
            const res = await api.get(`installer/reward/companies`, {
                params: payload
            })
            commit('setCompanies', res)
        },
        async getWorkTypes({commit}, payload) {
            const res = await api.get(`installer/reward/work-types`, {
                params: payload
            })
            commit('setWorkTypes', res)
        },
        async downloadExel(_, params) {
            const res = await api.get('installer/reward/crm-conditions-excel-data', {params: params, responseType: 'blob'});
            return res
        },
    }
}
