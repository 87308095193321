import {api} from '@/service/axios'
import {getIndexById} from '@/helpers/utils'

export const ticketsLCSModule = {
    state: () => ({
        ticketsLCS: [],
        ticketsLCSTotalPages: 0,
        totalTicketsLCS: 0,
        cableTypes: [],
        suspensionTypes: [],
    }),
    getters: {
        ticketsLCS(state) {
            return state.ticketsLCS
        },
        ticketsLCSTotalPages(state) {
            return state.ticketsLCSTotalPages
        },
        totalTicketsLCS(state) {
            return state.totalTicketsLCS
        },
        cableTypes(state) {
            return state.cableTypes
        },
        suspensionTypes(state) {
            return state.suspensionTypes
        },
    },
    mutations: {
        setTicketsLCS(state, payload) {
            state.ticketsLCS = payload
        },
        setTicketsLCSTotalPages(state, payload) {
            state.ticketsLCSTotalPages = payload
        },
        setTotalTicketsLCS(state, payload) {
            state.totalTicketsLCS = payload
        },
        setCableTypes(state, payload) {
            state.cableTypes = payload
        },
        setSuspensionTypes(state, payload) {
            state.suspensionTypes = payload
        }
    },
    actions: {
        async fetchTicketsLCS({commit}, payload) {
            const res = await api.get(`tickets/ticket-list`, {
                params: payload
            })
            commit('setTicketsLCS', res.data.tickets)
            commit('setTicketsLCSTotalPages', res.data.meta.last_page)
            commit('setTotalTicketsLCS', res.data.meta.total)
        },
        async createTicketLCS ({dispatch}, payload) {
            return await api.post('tickets/relate-ticket-lks', payload)
        },
        async fetchCableTypes({commit}) {
            const res = await api.get('dict/get-cable-types')
            commit('setCableTypes', res.data)
        },
        async fetchSuspensionTypes({commit}) {
            const res = await api.get('dict/get-suspension-types')
            commit('setSuspensionTypes', res.data)
        }
    }
}
