<template>
  <div v-if="OTPManagerTicketInfo.length > 0" class="wrapper wrapper-content modified flex column" style="padding-top:0;">
    <div class="bg-white p-20 rounded" style="padding-top:0;">
      <div>
        <div class="content w-100 mt-4 flex  column">
          <div class="grid gridText" style="background-color: lightgray;">
            <div class="grid-ticket">
              <p>Номер заявки</p>
            </div>
            <div class="grid-name">
              <p>Клиент</p>
            </div>
            <div class="grid-category">
              <p>Категория</p>
            </div>
            <div class="grid-date">
              <p>Дата и время создания</p>
            </div>
            <div class="grid-status">
              <p>Статус</p>
            </div>
            <div class="grid-registrator">
              <p>Регистратор</p>
            </div>
            <div class="grid-lifetime">
              <p>Время жизни</p>
            </div>
          </div>
        </div>
        <div class="otpManager-table">
          <div :class="ticket.is_expired ? 'grid grid-borders bg-red' : 'grid grid-borders'"
               v-for="(ticket) in OTPManagerTicketInfo" :key="ticket">
            <div class="link" @click="openTicketLink(ticket.ticket_number)" @click.middle="openTicketLinkMiddleMouse(ticket.ticket_number)"> {{ ticket.ticket_number }}</div>
            <div>{{ ticket.client?.full_name }}</div>
            <div>{{ ticket.category?.title }}</div>
            <div>{{ ticket.created_at }}</div>
            <div>{{ ticket.status?.title }}</div>
            <div>{{ ticket.author?.full_name }}</div>
            <div>{{ ticket.life_time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper wrapper-content modified flex column" style="padding-top:0;" v-else>
    <p></p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OTPManagerTicketsInfoModal",
  computed: {
    ...mapGetters({
      OTPManagerTicketInfo: 'OTPManagerTicketInfo'
    })
  },
  methods: {
    openTicketLink(ticket_number){
      this.$router.push(`/ticket/${ticket_number}`)
    },
    openTicketLinkMiddleMouse(ticket_number){
      const url = this.$router.resolve({ path: `/ticket/${ticket_number}` }).href;
      window.open(url, '_blank');
    }
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 3fr 3fr  3fr 3fr 3fr 3fr 3fr;
  grid-template-areas: 'Ticket Name Category Date Status Registrator Lifetime';
}

.grid-ticket {
  grid-area: Ticket;
}

.grid-name {
  grid-area: Name;
}

.grid-category {
  grid-area: Category;
}

.grid-date {
  grid-area: Date;
}

.grid-status {
  grid-area: Status;
}

.grid-registrator {
  grid-area: Registrator;
}

.grid-lifetime {
  grid-area: Lifetime;
}

.bg-red {
  background-color: #f65f5f;
}
.grid-borders {
  border-bottom: 1px solid lightgray;border-left: 1px solid lightgray; border-right: 1px solid lightgray
}

.otpManager-table {
}
</style>