<template>
  <div class="history w-100">
    <div class="table-container">
      <table class="history-table">
        <thead>
          <tr>
            <th>Дата и время</th>
            <th>Автор</th>
            <th>Действия</th>
            <th>Изменение</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in history" :key="log.id">
            <td class="date-column">
              <div class="text-14 text-black2">{{log.created_at.split(' ')[0]}}</div>
              <div class="text-14 text-gray">{{log.created_at.split(' ')[1]}}</div>
            </td>
            <td>
              <span class="text-14 text-black2">
                {{ log.author.full_name || log.author.title }}
              </span>
            </td>
            <td class="text-14 text-black2">
              {{ log.action.title }}
            </td>
            <td>
              <div class="status-badge flex align-center text-black2">
                <img :src="resolveLogData(log)?.key === 'created' ? '/assets/status_created.svg' : resolveLogData(log)?.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'" alt="">
                <span v-if="resolvePreviousLogData(log)">
                  {{resolvePreviousLogData(log)?.key === 'created' ? 'Создан' : resolvePreviousLogData(log)?.title}}
                  {{resolvePreviousLogData(log)?.full_name || ''}}
                  →
                </span>
                {{resolveLogData(log)?.key === 'created' ? 'Создан' : resolveLogData(log)?.title}}
                {{resolveLogData(log)?.full_name || ''}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {formatDate} from "@/helpers/utils";

export default {
  name: 'HistoryTab',
  props: ['history'],
  methods: {
    resolveLogData(log) {
      return log.action.data?.status || log.action.data?.department || log.action.data?.manager || log.action.data?.brigade
    },
    resolvePreviousLogData(log) {
      return log.previous_action.data?.status || log.previous_action.data?.department || log.previous_action.data?.manager || log.previous_action.data?.brigade
    },
  },
  computed: {
    ...mapGetters({
      details: 'chosenTicket'
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.history {
  max-height: 340px;
  border-radius: 15px;
  background: $gray4;
  padding: 20px;

  .table-container {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 3px;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  &-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    min-width: 100%;

    th {
      text-align: left;
      padding: 12px 16px;
      background: white;
      font-weight: 600;
      color: $black2;
      white-space: nowrap;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    td {
      padding: 12px 16px;
      background: white;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    tr {
      margin-bottom: 8px;
    }
  }

  .date-column {
    white-space: nowrap;
  }

  .status-badge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    
    img {
      width: 16px;
      height: 16px;
    }
  }
}

</style>
