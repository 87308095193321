import {api} from '@/service/axios'
import {getIndexById} from "@/helpers/utils";

export const connectionTicketsModule = {
  state: () => ({
    connectionTickets: [],
    totalConnectionTicketsPages: 0,
    chosenConnectionTicket: {},
    connectionTicketComments: [],
    totalConnectionTicketComments: 0,
    currentConnectionTicketCommentsPage: 1,
    relatedTickets: []
  }),
  getters: {
    connectionTickets(state) {
      return state.connectionTickets
    },
    totalConnectionTicketsPages(state) {
      return state.totalConnectionTicketsPages
    },
    chosenConnectionTicket(state) {
      return state.chosenConnectionTicket
    },
    connectionTicketComments(state) {
      return state.connectionTicketComments
    },
    totalConnectionTicketComments(state) {
      return state.totalConnectionTicketComments
    },
    currentConnectionTicketCommentsPage(state) {
      return state.currentConnectionTicketCommentsPage
    },
    relatedTickets(state) {
      return state.relatedTickets
    },
  },
  mutations: {
    setConnectionTickets(state, payload) {
      state.connectionTickets = payload
    },
    setTotalConnectionTicketsPages(state, payload) {
      state.totalConnectionTicketsPages = payload
    },
    setChosenConnectionTicket(state, payload) {
      state.chosenConnectionTicket = payload
    },
    setConnectionTicketComments(state, payload) {
      state.connectionTicketComments = payload
    },
    setTotalConnectionTicketComments(state, payload) {
      state.totalConnectionTicketComments = payload
    },
    setCurrentConnectionTicketCommentsPage(state, payload) {
      state.currentConnectionTicketCommentsPage = payload
    },
    setRelatedTickets(state, payload) {
      state.relatedTickets = payload
    },
  },
  actions: {
    async fetchConnectionTickets ({commit}, payload) {
      const res = await api.get(`connection-tickets/filter-connection-ticket-list`, {
        params: payload
      })
      commit('setConnectionTickets', res.data.data)
      commit('setTotalConnectionTicketsPages', res.data.meta.last_page)
    },
    async fetchConnectionTicketDetail({state, commit, dispatch}, payload) {
      const res = await api.get(`connection-tickets/connection-ticket/${payload}`)
      commit('setChosenConnectionTicket', res.data)
      commit('setCurrentConnectionTicketCommentsPage', 1)
      commit('setConnectionTicketComments', [])
      dispatch('fetchConnectionTicketComments', {connection_ticket_id: res.data.connection_ticket.id, page: state.currentCommentPage})
    },
    async fetchConnectionTicketComments ({state, commit}, payload) {
      const res = await api.get(`connection-tickets/connection-ticket-comments`, {
        params: payload
      })
      commit('setConnectionTicketComments', [...state.connectionTicketComments, ...res.data.connection_replies])
      commit('setTotalConnectionTicketComments', res?.data?.meta?.total)
    },
    async addConnectionTicketComments ({state, commit}, payload) {
      const res = await api.post(`connection-tickets/connection-ticket-comment-add`, payload)
      commit('setConnectionTicketComments', [res.data, ...state.connectionTicketComments])
    },
    async changeConnectionDate ({state, commit}, payload) {
      const res = await api.put(`connection-tickets/update-connection-ticket-planned-date`, payload)
      return res
    },
    async changeConnectionType ({state, commit}, payload) {
      const res = await api.put(`connection-tickets/update-connection-ticket-details`, payload)
    },
    async changeConnectionBrigade ({state, commit}, payload) {
      const res = await api.put(`connection-tickets/connection-ticket-brigade-id-update`, payload)
    },
    async fetchConnectionTicketDeadlines ({commit}, payload) {
      const res = await api.get(`connection-tickets/list-connection-tickets-deadlines`)
    },
    async fetchNearOpk ({commit}, payload) {
      return await api.get(`techdata/get-near-opk`, {params: payload})
    },
    async changeOrkId ({state, commit}, payload) {
      const res = await api.put(`connection-tickets/update-ork-id`, payload)
    },
    async departureCommentEdit({state, commit}, payload){
      const res = await api.put('connection-tickets/connection-ticket-comment-update', payload)
      commit('setConnectionTicketComments', [...state.connectionTicketComments.slice(0,getIndexById(state.connectionTicketComments, res.data.id)),
        res.data,...state.connectionTicketComments.slice(getIndexById(state.connectionTicketComments,res.data.id)+1)])
      return res
    },
    async getRelatedTickets({state, commit, dispatch}, payload) {
      const res = await api.get(`connection-tickets/get-related-tickets/${payload}`)
      commit('setRelatedTickets', res.data)
    },
    async changeDisassemblyBrigade({state, commit}, payload) {
      const res = await api.post(`connection-tickets/change-brigade/${payload.id}`, {brigade_id: payload.brigade_id})
      return res
    }
  }
}
