<template>
  <div class="success flex column align-center">
    <div class="success-inner flex column align-center">
      <p class="text-center">
        <slot></slot>
      </p>
      <img src="/assets/success.svg" alt="" />

      <button class="btn" @click="$emit('close')">ОК</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';

.success {

  width: 670px;

  &-inner {
    width: 400px;

  }

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $black2;
  }

  img {
    margin-top: 24px;
    margin-bottom: 60px;
  }
}
</style>
