import { loginToRocketChat, getRocketChatRooms, getRocketChatUnreadCount, getRocketChatUser } from "@/service/chat";
import { initializeWebSocket } from '@/service/rocketChatWebSocket'
import {useToast} from "vue-toast-notification";

export const chatModule = {
    state: () => ({
        rocketChatRooms: [],
        unreadCounts: {},
        wsConnection: null,
        rocketChatCred: null,
        processedMessageIds: new Set(),
        rocketChatUser: null
    }),
    getters: {
        getRocketChatRooms: state => state.rocketChatRooms,
        getUnreadCounts: state => state.unreadCounts,
        getRocketChatCred: state => state.rocketChatCred,
        isRocketChatAvailable: state => !!state.rocketChatCred?.authToken && !!state.rocketChatCred?.userId
    },
    mutations: {
        setRocketChatUser(state, user) {
            state.rocketChatUser = user
        },

        setRocketChatCred(state, cred) {
            state.rocketChatCred = cred
        },

        setRocketChatRooms(state, rooms) {
            state.rocketChatRooms = rooms
        },

        setWsConnection(state, connection) {
            state.wsConnection = connection
        },

        setUnreadCount(state, { roomId, count }) {
            state.unreadCounts = {
                ...state.unreadCounts,
                [roomId]: count
            }
        },

        incrementUnreadCount(state, { roomId, messageId }) {
            if (state.processedMessageIds.has(messageId)) return

            state.unreadCounts = {
                ...state.unreadCounts,
                [roomId]: (state.unreadCounts[roomId] || 0) + 1
            }

            state.processedMessageIds.add(messageId)
            if (state.processedMessageIds.size > 1000) {
                state.processedMessageIds.clear()
            }

            const roomIndex = state.rocketChatRooms.findIndex(r => r._id === roomId)
            if (roomIndex !== -1) {
                const room = {
                    ...state.rocketChatRooms[roomIndex],
                    lm: new Date().toISOString()
                }
                state.rocketChatRooms.splice(roomIndex, 1)
                state.rocketChatRooms.unshift(room)
            }
        },

        addNewDirectMessage(state, { room }) {
            const existingRoom = state.rocketChatRooms.find(r => r._id === room._id)
            if (!existingRoom) {
                const newRoom = {
                    ...room,
                    unread: room.unread || 0,
                    lm: room.lm || new Date().toISOString()
                }
                state.rocketChatRooms.unshift(newRoom)

                if (room.msgs > 0) {
                    state.unreadCounts[room._id] = room.unread || 0
                }
            }
        }
    },
    actions: {
        async getRocketChatUser({ commit, state }) {
            try {
                const response = await getRocketChatUser()
                commit('setRocketChatUser', response.data)
                return response.data
            } catch (error) {
                console.error('Error fetching RocketChat user:', error)
            }
        },

        async loginToRocketChat({ commit, state }) {
            const toast = useToast();
            try {
                const credentials = {
                    username: state.rocketChatUser.user,
                    password: state.rocketChatUser.password
                }

                const response = await loginToRocketChat(credentials)
                const { authToken, userId } = response.data.data

                const rocketChatCred = {
                    authToken,
                    userId,
                    username: state.rocketChatUser.user
                }

                commit('setRocketChatCred', rocketChatCred)
                localStorage.setItem('rocketChatCred', JSON.stringify(rocketChatCred))

                const ws = initializeWebSocket({ commit, state })
                commit('setWsConnection', ws)

                return response
            } catch (error) {
                console.warn('RocketChat login failed:', error)
                toast.open({
                    message: 'NLS Чат недоступен',
                    type: 'error'
                })
                return null
            }
        },

        async getRocketChatRooms({ commit }) {
            try {
                const roomsResponse = await getRocketChatRooms()

                if (roomsResponse.data.success) {
                    const rooms = roomsResponse.data.update || []

                    const unreadResponse = await getRocketChatUnreadCount()
                    const subscriptions = unreadResponse.data.success ? unreadResponse.data.update : []

                    const processedRooms = rooms
                        .filter(room => room.t === 'd')
                        .map(room => {

                            const subscription = subscriptions.find(sub => sub.rid === room._id)
                            return {
                                ...room,
                                unread: subscription?.unread || 0,
                                lm: room.lastMessage?.ts || room.lm
                            }
                        })

                    commit('setRocketChatRooms', processedRooms)

                    processedRooms.forEach(room => {
                        commit('setUnreadCount', {
                            roomId: room._id,
                            count: room.unread || 0
                        })
                    })
                }
            } catch (error) {
                console.error('Error fetching RocketChat rooms:', error)
            }
        },

        async initializeFromStorage({ commit, dispatch, state }) {
            try {
                if (!state.rocketChatCred) {
                    const storedCred = JSON.parse(localStorage.getItem('rocketChatCred'))
                    if (storedCred?.authToken && storedCred?.userId) {
                        commit('setRocketChatCred', storedCred)
                    }
                }

                if (state.rocketChatCred?.authToken && state.rocketChatCred?.userId) {
                    await dispatch('getRocketChatRooms')
                    const ws = initializeWebSocket({ commit, dispatch, state })
                    commit('setWsConnection', ws)
                }
            } catch (error) {
                console.error('Failed to initialize RocketChat:', error)
                commit('setRocketChatCred', null)
            }
        }
    }
}
