<template>
  <div class="modal-content p-8">
    <h2 class="text-[16px] font-bold mb-6">Создать отдел</h2>
    <div class="form__row_field mb-6">
      <label class="form__label mb-2 block">Название отдела</label>
      <input
        v-model="title"
        type="text"
        class="input-form w-full"
        placeholder="Введите название отдела"
      />
    </div>
    <div class="flex justify-end mt-8">
      <button
        class="btn btn-cancel mr-4"
        @click="$emit('close')"
      >
        Отмена
      </button>
      <button
        @click="createDepartment"
        class="btn btn-create flex items-center justify-center"
        :disabled="!title"
      >
        Создать отдел
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewDepartment',
  data() {
    return {
      title: ''
    }
  },
  methods: {
    createDepartment() {
      this.$emit('on-submit', { title: this.title })
      this.title = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  min-width: 400px;
  max-width: 500px;
}

.form__label {
  color: #374151;
  font-size: 14px;
}

.input-form {
  padding: 8px 12px;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  
  &:focus {
    outline: none;
    border-color: #149d8f;
  }
}

.btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-width: 120px;
  text-align: center;
  
  &.btn-create {
    background: linear-gradient(92.74deg, #149d8f 1.61%, #35d784 101.49%);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  
  &.btn-cancel {
    border: 1px solid #149d8f;
    color: #149d8f;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mt-8 {
  margin-top: 32px;
}

.mr-4 {
  margin-right: 16px;
}

.w-full {
  width: 100%;
}

.block {
  display: block;
}
</style>