
export const themeModule = {
  state: () => ({
    sideBarOpened: false,
    currentPage: {}
  }),
  getters: {
    sideBarOpened(state) {
      return state.sideBarOpened
    },
    currentPage(state) {
      return state.currentPage
    },
  },
  mutations: {
    setSideBarOpened(state, payload) {
      state.sideBarOpened = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload
    },
  },
  actions: {

  }
}
