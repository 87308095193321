<template>
  <div class="flex column">
    <div class="modal_title flex align-center mb-4">
      <div class="modal_title__image flex align-center justify-center mr-2">
        <img src="/assets/newspaper.svg" alt="" />
      </div>
      <h3 class="text-black2">Карточка клиента</h3>
    </div>
    <div class="w-100 flex flex column mb-2 align-end" v-if="client.tickets.length">
      <div class="error-text mb-1 bold text-14 cursor-pointer" v-for="ticket in client.tickets" :key="ticket" @click="goToTicket(ticket.ticket_number)">
          СВЯЗАН С АВАРИЕЙ #{{ticket.ticket_number}}
          <template v-for="(node, index) in ticket.nodes">
            {{node.node_name}}{{index < ticket.nodes.length ? ' ' : ', '}}
          </template>
      </div>
    </div>
    <tabs-component class="mb-2" :tabs="tabs" :active-tab="activeTab" @tab-click="changeTab" />

    <client-info v-if="activeTab === 'main' && client.uid" @close="closeModal"/>
    <client-agreements v-if="activeTab === 'agreements'" @close="closeModal" />
    <client-documents v-if="activeTab === 'documents'" />
    <client-equipments v-if="activeTab === 'equipment'" />
<!--      <custom-table-->
<!--        class="mt-3"-->
<!--        v-if="navigation[4].current"-->
<!--        :columns="tableColumns"-->
<!--        :data="clientsTickets"-->
<!--        :header-style="'green-background'"-->
<!--        @get-detail="getTicketDetail"-->
<!--      />-->
<!--    <side-modal v-if="isTicketDetailModal" @close="toggleTicketDetailModal">-->
<!--      <ticket-details />-->
<!--    </side-modal>-->
  </div>
</template>

<script>


import {mapGetters, mapActions, mapMutations} from "vuex";
import ClientInfo from "@/components/tabs/ClientInfo.vue";
import ClientAgreements from "@/components/tabs/ClientAgreements.vue";
import ClientDocuments from "@/components/tabs/ClientDocuments.vue";
import ClientEquipments from "@/components/tabs/ClientEquipments.vue";
import {ClientTableColumns} from "@/modules/table-columns";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import router from "@/router";

export default {
  name: 'ClientModal',
  components: {ClientInfo, ClientAgreements, ClientDocuments, ClientEquipments, TabsComponent},
  data() {
    return {
      equipLoaded: false,
      docLoaded: false,
      agrmLoaded: false,
      ticketsLoaded: false,
      tabs: [
        {
          title: 'Общее',
          key: 'main',
          icon: '/assets/tabs/info-gray.svg',
          icon_active: '/assets/tabs/info-white.svg',
        },
        {
          title: 'Договора',
          key: 'agreements',
          icon: '/assets/tabs/agreements-gray.svg',
          icon_active: '/assets/tabs/agreements-white.svg',
        },
        {
          title: 'Документы',
          key: 'documents',
          icon: '/assets/tabs/docs-gray.svg',
          icon_active: '/assets/tabs/docs-white.svg',
        },
        {
          title: 'Оборудование',
          key: 'equipment',
          icon: '/assets/tabs/equipments-gray.svg',
          icon_active: '/assets/tabs/equipments-white.svg',
        },
      ],
      activeTab: 'main',
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchClientData: 'fetchClientData',
      fetchClientAgreements: 'fetchClientAgreements',
      fetchClientDocuments: 'fetchClientDocuments', 
      fetchClientEquipmentList: 'fetchClientEquipmentList',
      fetchClientFullData: 'fetchClientFullData',
      checkIP: 'checkIP',
      fetchClientsTickets: 'fetchClientsTickets'
    }),
    async changeTab(tab) {
      if (tab === 'agreements' && !this.agrmLoaded) {
        this.setLoading(true)
        await this.fetchClientAgreements(this.client.uid).then(() => {
          this.agrmLoaded = true
          this.setLoading(false)
        })
      }
      if (tab === 'documents' && !this.docLoaded) {
        this.setLoading(true)
        await this.fetchClientDocuments(this.client.uid).then(() => {
          this.docLoaded = true
          this.setLoading(false)
        })
      }
      if (tab === 'equipment' && !this.equipLoaded) {
        this.setLoading(true)
        await this.fetchClientEquipmentList(this.client.uid).then(() => { 
          this.equipLoaded = true
          this.setLoading(false)
        })
      }
      if (tab === 'tickets' && !this.ticketsLoaded) {
        this.setLoading(true)
        await this.fetchClientsTickets(this.client.uid).then(() => {
          this.ticketsLoaded = true
          this.setLoading(false)
        })
      }
      this.activeTab = tab
    },
    closeModal() {
      this.$emit('close')
    },
    goToTicket(number) {
      router.push(`/ticket/${number}`)
      this.closeModal()
    }
  },
  computed: {
    ...mapGetters({
      client: 'client',
      clientsTickets: 'clientsTickets'
    }),
    tableColumns() {
      return ClientTableColumns
    }
  },
  mounted() {
      // this.fetchClientFullData(this.client.uid)
  }
}
</script>


<style lang="scss" scoped>
@import '../../../theme/variables';


</style>
