<template>
  <new-side-modal @close="handleClose">
    <template #header>
      <div class="modal_title">
        <div class="modal_title__image">
          <img src="/assets/plusik.svg" alt="create" />
        </div>
        <h3>Создать нового пользователя</h3>
      </div>
    </template>
    
    <div class="form-content">
      <div class="form__body_item form__body_item--row">
        <base-input
          v-model="payload.phone"
          label="Номер телефона"
          ref="phone"
          v-mask="phoneMask"
          @input="updateLoginFields"
          class="form__body_item--half"
        />
        <base-input v-model="payload.login" label="Логин" ref="login" disabled class="form__body_item--half" />
      </div>

      <div class="form__body_item form__body_item--row">
        <base-input v-model="payload.name" label="Имя" ref="name" class="form__body_item--half" />
        <base-input v-model="payload.lastname" label="Фамилия" ref="lastname" class="form__body_item--half" />
      </div>

      <div class="form__body_item">
        <base-input v-model="payload.patronymicname" label="Отчество" ref="patronymicname" />
      </div>

      <div class="form__body_item">
        <base-input v-model="payload.iin" label="ИИН" ref="iin" v-mask="'############'" />
      </div>

      <div class="form__body_item">
        <base-input v-model="payload.position" label="Должность" ref="position" />
      </div>

      <div class="form__body_item">
        <base-dropdown
          v-model:selectedValue="payload.role_id"
          :items="sortedGroups"
          label="Роль пользователя"
          keyProp="id"
          titleProp="title"
          placeholder="Выберите роль"
          ref="role_id"
          :showAllOption="false"
        />
      </div>

      <div class="form__body_item">
        <base-dropdown
          v-model:selectedValue="payload.department_id"
          :items="sortedDepartments"
          label="Отдел"
          keyProp="id"
          titleProp="title"
          placeholder="Выберите отдел"
          ref="department_id"
          :showAllOption="false"
        />
      </div>

      <div class="form__body_item">
        <div class="form__row form__row--horizontal">
          <div class="form__row_label">Руководитель</div>
          <div class="form__row_field">
            <input
              type="checkbox"
              v-model="payload.is_head"
              class="checkbox"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-actions">
      <button class="form__body_submit" @click="createUser">Сохранить</button>
      <button class="form__body_cancel" @click="handleClose">Отмена</button>
    </div>
  </new-side-modal>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from "vuex"
import {validateIin} from "@/modules/validations";
import NewSideModal from './NewSideModal.vue'
import BaseInput from '@/components/ui/BaseInput.vue'
import BaseDropdown from '@/components/ui/BaseDropdown.vue'

export default {
  name: 'NewUser',
  emits: ['close', 'onSubmit'],
  components: {
    NewSideModal,
    BaseInput,
    BaseDropdown
  },
  data() {
    return {
      phoneMask: '+7 (###)-###-##-##',
      payload: {
        phone: "",
        login: "",
        username: "",
        lastname: "",
        name: "",
        patronymicname: "",
        department_id: null,
        role_id: null,
        position: "",
        iin: '',
        is_head: false
      }
    }
  },
  computed: {
    ...mapGetters({
      groups: "groups",
      departments: "departments",
    }),
    sortedGroups() {
      return [...this.groups].sort((a, b) => a.title.localeCompare(b.title));
    },
    sortedDepartments() {
      return [...this.departments].sort((a, b) => a.title.localeCompare(b.title));
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    handleClose() {
      this.$emit('close');
    },
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    updateLoginFields() {
      if (this.payload.phone) {
        const phone = this.unMask(this.payload.phone)
        this.payload.login = phone
        this.payload.username = phone
      } else {
        this.payload.login = ''
        this.payload.username = ''
      }
    },
    validate() {
      let isError = false
      for (let property in this.payload) {
        if (!this.payload[property] && property !== 'is_head' && property !== 'patronymicname') {
          isError = true
          const element = this.$refs[property]
          if (element) {
            if (element.$el && (property === 'role_id' || property === 'department_id')) {
              element.$el.classList.add('error')
              setTimeout(() => {
                element.$el.classList.remove('error')
              }, 3000)
            } else {
              element.classList.add('error')
              setTimeout(() => {
                element.classList.remove('error')
              }, 3000)
            }
          }
        }
      }
      return isError
    },
    async createUser() {
      if (!validateIin(this.payload.iin)) {
        this.$toast.error('ИИН не верный')
      } else {
        if (!this.validate()) {
          const phone = this.unMask(this.payload.phone)
          this.payload.phone = phone
          this.payload.login = phone
          this.payload.username = phone
          this.payload.is_head = this.payload.is_head ? 1 : 0
          this.$emit("onSubmit", this.payload)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modal_title {
  display: flex;
  align-items: center;
  gap: 12px;
  
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  &__image {
    width: 48px;
    height: 48px;
    border: 1px solid #E1E0E0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.form__body_item {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &--row {
    display: flex;
    gap: 16px;
  }

  &--half {
    width: 50%;
  }
}

.form__row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--horizontal {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }

  &_label {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin: 0;
  }

  &_field {
    display: flex;
    align-items: center;

    .checkbox {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 0;
    }
  }
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
}

.form__body_submit {
  width: 100%;
  background: #FE7300;
  border: none;
  outline: none;
  color: white;
  border-radius: 12px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
}

.form__body_cancel {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #000000;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}
</style>