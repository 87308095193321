export const navigation = [
  {
    text: 'Главная',
    name: 'home',
    href: '/',
    image: '/assets/nav/house.svg',
    hover: '/assets/nav/house-white.svg',
  },
  {
    text: 'Заявки',
    name: 'tickets',
    href: '/tickets',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
    inner: [
      {
        href: '/tickets',
        text: 'Список заявок',
        name: 'tickets-list',
      },
      {
        href: '/emergency-tickets',
        text: 'Текущая ситуация',
        name: 'emergency-tickets-list',
      },
      {
        href: '/emergency-tickets-only',
        text: 'Закрытые аварии',
        name: 'emergency-tickets-only',
      },
      {
        href: '/access-tickets',
        text: 'Заявки на доступ',
        name: 'access-tickets',
      },
      {
        href: '/queries-technical-capability',
        text: 'Запросы на ТВ',
        name: 'queries-technical-capability',
      },
      {
        href: '/tickets-lcs',
        text: 'Заявки на ЛКС',
        name: 'tickets-lcs',
      },
      {
        href: '/departments-tickets',
        text: 'Заявки по отделам',
        name: 'departments-tickets-list',
      },
      {
        href: '/tickets-OTP',
        text: 'Заявки по сотрудникам ОТП',
        name: 'tickets-OTP-list',
      },
      {
        href: '/commutation-tickets',
        text: 'Заявки на коммутацию',
        name: 'commutation-tickets',
      },
      {
        href: '/preparing-connections-tickets',
        text: 'Подготовка подключений',
        name: 'preparing-connections-tickets',
      },
      {
        href: '/connection-improvements-tickets',
        text: 'Заявки на доработку подключения',
        name: 'connection-improvements-tickets',
      }
    ]
  },
  {
    text: 'Заявки c ЛК',
    name: 'cabinet-tickets',
    href: '/cabinet-tickets',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
  {
    text: 'Заявки по сотрудникам',
    name: 'current-situation',
    href: '/current-situation',
    image: '/assets/nav/current-s.svg',
    hover: '/assets/nav/current-s-white.svg',
  },
  {
    text: 'Пользователи',
    name: 'users',
    href: '/users/managers',
    image: '/assets/nav/users.svg',
    hover: '/assets/nav/users-white.svg',
    inner: [
      {
        href: '/users/managers',
        text: 'Менеджеры',
        name: 'managers',
      },
      {
        href: '/users/technicians',
        text: 'Техники',
        name: 'technicians',
      }
    ]
  },
  {
    text: 'Крайние сроки заявки',
    name: 'worktime',
    href: '/worktime',
    image: '/assets/nav/clock.svg',
    hover: '/assets/nav/clock-white.svg',
  },
  {
    text: 'Календарь заявок',
    name: 'calendar',
    href: '/calendar',
    image: '/assets/nav/calendar.svg',
    hover: '/assets/nav/calendar-white.svg',
  },
  {
    text: 'Уведомления',
    name: 'notifications',
    href: '/push-notifications',
    image: '/assets/nav/notifications.svg',
    hover: '/assets/nav/notifications-white.svg',
    inner: [
      {
        href: '/push-notifications',
        text: 'Список уведомлений',
        name: 'notifications-list',
      },
      {
        href: '/push-notifications-form',
        text: 'Создание нового уведомления',
        name: 'notifications-form',
      }
    ]
  },
  {
    text: 'График дежурных',
    name: 'duty-schedule',
    href: '/duty-schedule',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
  {
    text: 'Чат',
    name: 'chat',
    href: '/chat',
    image: '/assets/nav/chat.svg',
    hover: '/assets/nav/chat-white.svg',
  },
  {
    text: 'Управление бригадами',
    name: 'brigade-options',
    href: '/brigade-options',
    image: '/assets/nav/UserGear.svg',
    hover: '/assets/nav/UserGearWhite.svg',
    requiresRole: [
      'ADMIN',
      'SUPERVISOR',
      'support_super_admin',
      'it_admin',
      'tech_admin',
      'tech_super_admin',
      'dev_super_admin',
      'maintenance_head',
      'service_department_head',
      'lks_head',
      'vols_head',
      'sks_head',
      'vols_development_head',
      'sales_head',
      'subscriptions_head',
      'tech_director',
      'net_projecting_head'
    ]
  },
  {
    text: 'Инструкции',
    name: 'instructions',
    href: '/instructions',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
  {
    text: 'На выезд B2C',
    name: 'departure',
    href: '/departure',
    image: '/assets/nav/Truck.svg',
    hover: '/assets/nav/TruckBlue.svg',
    inner: [
      {
        href: '/connection-b2c',
        text: 'Заявки на подключение B2C',
        name: 'connection-b2c-list',
      },
      {
        href: '/departure/service-b2c',
        text: 'Сервисные заявки B2C',
        name: 'service-b2c',
      },
      {
        href: '/upsells-b2c',
        text: 'Допродажи B2C',
        name: 'upsells-b2c-list',
      }
    ]
  },
  {
    text: 'На выезд B2B',
    name: 'departure-b2b',
    href: '/departure-b2b',
    image: '/assets/nav/Truck.svg',
    hover: '/assets/nav/TruckBlue.svg',
    inner: [
      {
        href: '/connection-b2b',
        text: 'Заявки на подключение B2B',
        name: 'connection-b2b-list',
      },
      {
        href: '/departure/service-b2b',
        text: 'Сервисные заявки B2B',
        name: 'service-b2b',
      },
      {
        href: '/services-transfer-b2b',
        text: 'Заявки на перенос B2B',
        name: 'services-transfer-b2b-list',
      },
      {
        href: '/upsells-b2b',
        text: 'Допродажи B2B',
        name: 'upsells-b2b-list',
      },
      {
        href: '/disassembly',
        text: 'Демонтажи B2B',
        name: 'disassembly-list',
      },
      {
        href: '/sks-b2b',
        text: 'Заявки на СКС B2B',
        name: 'sks-b2b-list',
      },
      {
        href: '/disassembly-improvements-tickets',
        text: 'Доработки демонтажей B2B',
        name: 'disassembly-improvements-tickets',
      }
    ]
  },
  {
    text: 'Монетизация',
    name: 'monetization',
    href: '/monetization',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
  {
    text: 'Отчет ТО',
    name: 'monetization-report',
    href: '/monetization-report',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg'
  },
  {
    text: 'Роли и отделы',
    name: 'roles',
    href: '/roles',
    image: '/assets/nav/users.svg',
    hover: '/assets/nav/users-white.svg',
  },
  {
    text: 'Отчеты',
    name: 'reports',
    href: '/reports',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
    inner: [
      {
        href: '/reports',
        text: 'Отчет 66',
        name: 'report-66',
      },
      {
        href: '/reports/load-chart',
        text: 'График нагрузки',
        name: 'load-chart',
      }
    ]
  },
  {
    text: 'Доступы',
    name: 'access',
    href: '/access',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
    inner: [
      {
        href: '/access',
        text: 'База доступов',
        name: 'access-base',
      }
    ]
  },
  {
    text: 'Управление участками',
    name: 'areas-management',
    href: '/areas-management',
    image: '/assets/nav/users.svg',
    hover: '/assets/nav/users-white.svg',
    inner: [
      {
        href: '/areas-management',
        text: 'Управление участками',
        name: 'areas-management-list',
      },
      {
        href: '/areas-analytics',
        text: 'Аналитика по участкам',
        name: 'areas-analytics',
      },
    ]
  },
  {
    text: 'Полигоны',
    name: 'polygons',
    href: '/polygons',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
    inner: [
      {
        href: '/polygons',
        text: 'Полигоны доступов',
        name: 'polygons-list',
      }
    ]
  },
  {
    text: 'Виртуальный склад',
    name: 'virtual-warehouse',
    href: '/virtual-warehouse',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
  {
    text: 'Поручения',
    name: 'tasks',
    href: '/tasks',
    image: '/assets/nav/list.svg',
    hover: '/assets/nav/list-white.svg',
  },
] 