<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="details.connection_ticket">
      <div class="flex justify-between mb-2">
        <div class="flex align-center">
          <h2 class="text-black2 mr-2">ЗАЯВКА <b>#{{details.connection_ticket.ticket_number}}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="details.connection_ticket.connection_status" @click="toggleAddressModal">
            <img :src="details.connection_ticket.connection_status.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'" alt="">
            {{details.connection_ticket.connection_status.title}}
          </div>
        </div>
        <div></div>
      </div>

      <div class="w-100 flex justify-between">
        <div class="flex column mb-4">
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Создана: </p>
            <p class="text-14 text-black2" v-if="details.connection_ticket.created_at">{{details.connection_ticket.created_at}}</p>
          </div>
          <div class="flex">
            <p class="text-14 text-gray mr-1">Подключение: </p>
            <p class="text-14 link" v-if="details.connection_ticket.planned_date" @click="toggleChangeDateModal">{{details.connection_ticket.planned_date}}</p>
          </div>
        </div>
        <div class="flex column mb-4">
          <div class="flex w-100 mb-1">
            <p class="text-14 text-gray mr-1">Бригада: </p>
            <p class="text-14 link" @click="toggleChangeBrigadeModal">{{details.connection_ticket.brigade_id ? brigadeName(details.connection_ticket.brigade_id) : 'Назначить'}}</p>
          </div>
        </div>
      </div>



      <div class="w-100 ticket-kind text-center text-black2 mb-2">
        ПОДКЛЮЧЕНИЕ
      </div>

      <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" @tab-click="changeTab" />

      <div>
        <div class="info-block" v-if="activeTab === 'info'">
          <div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.connection_client">
              <p class="text-black bold text-14">Клиент:</p>
              <p class="text-gray5 text-right text-14">{{details.connection_ticket.connection_client.name}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.connection_client">
              <p class="text-black bold text-14">Контактное лицо:</p>
              <p class="text-gray5 text-right text-14">{{details.connection_ticket.connection_client.contact_person}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.connection_client">
              <p class="text-black bold text-14">Контактный номер:</p>
              <p class="text-gray5 text-right text-14">{{details.connection_ticket.connection_client.phone_number}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.address">
              <p class="text-black bold text-14">Адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{details.connection_ticket.address.address}}</p>
                <img class="ml-1 cursor-pointer" @click="toggleAddressModal" src="/assets/MapPinLine.svg" alt="">
              </div>

            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.manager">
              <p class="text-black bold text-14">Менеджер:</p>
              <p class="text-gray5 text-right text-14">{{details.connection_ticket.manager.full_name}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.connection_source">
              <p class="text-black bold text-14">Источник:</p>
              <p class="text-gray5 text-right text-14">{{details.connection_ticket.connection_source.title}}</p>
            </div>
          </div>
        </div>
        <div class="info-block" v-if="activeTab === 'tech'">
          <div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.login_ppoe">
              <p class="text-black bold text-14">Логин PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{details.connection_ticket.login_ppoe}}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details.connection_ticket.login_ppoe)" src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.password_ppoe">
              <p class="text-black bold text-14">Пароль PPOE:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{details.connection_ticket.password_ppoe}}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details.connection_ticket.password_ppoe)" src="/assets/copy.svg" alt="">
              </div>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.ip_address">
              <p class="text-black bold text-14">IP адрес:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-right text-14">{{details.connection_ticket.ip_address}}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details.connection_ticket.ip_address)" src="/assets/copy.svg" alt="">
              </div>
            </div>
<!--            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.address">-->
<!--              <p class="text-black text-14">Host:</p>-->
<!--              <p class="text-gray5 text-14 text-right">{{details.connection_ticket.address.address}}</p>-->
<!--            </div>-->
            <div class="card bg-white rounded flex align-center justify-between relative" v-if="details.connection_ticket.connection_type">
              <p class="text-black bold text-14">Тип подключения:</p>
              <p class="link text-14 text-right" @click="toggleChangeConnectionType">{{details.connection_ticket.connection_type.type}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between relative" v-if="details.connection_ticket.connection_type">
              <p class="text-black bold text-14">ОРК:</p>
              <p class="link text-14 text-right" @click="toggleOpkChangeModal">ОРК {{details.connection_ticket.ork_id}}</p>
            </div>
            <div class="card bg-white rounded flex align-center justify-between" v-if="details.connection_ticket.agent">
              <p class="text-black bold text-14">Агент:</p>
              <div class="flex align-center">
                <p class="text-gray5 text-14 text-right">{{details.connection_ticket.agent}}</p>
                <img class="ml-1 cursor-pointer" @click="copyToClipboard(details.connection_ticket.agent)" src="/assets/copy.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <comments-tab
          v-if="activeTab === 'comment'"
          :comments="ticketComments"
          :totalComments="totalComments"
          @addComment="addComment"
          @loadComments="loadComments"
          @openImage="toggleImageModal"
        />
        <history-tab
          v-if="activeTab === 'history'"
          :history="details.logs"
        />
      </div>
    </div>
    <div class="actions">
      <button class="actions-buttons" :class="'actions-buttons_'+item.key" v-for="item in details.buttons" :key="item.id" @click="changeStatus(item)">
        {{ item.title }}
        <img src="/assets/chevron-right-w.svg">
      </button>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image" />
    </custom-modal>

    <custom-modal v-if="isAddressModal" @close="toggleAddressModal">
      <map-modal title="АДРЕС ПОДКЛЮЧЕНИЯ" :latitude="details.connection_ticket.address.latitude" :longitude="details.connection_ticket.address.longitude" @close="toggleAddressModal" />
    </custom-modal>

    <custom-modal v-if="isOpkChangeModal" @close="toggleOpkChangeModal">
      <opk-pick-modal
        :coordinates="{latitude: parseFloat(details.connection_ticket.address.latitude), longitude: parseFloat(details.connection_ticket.address.longitude), ork_id: details.connection_ticket.ork_id}"
        :ticket-id="details.connection_ticket.id"
        @close="toggleAddressModal"
        @success="orkChanged"
      />
    </custom-modal>

    <custom-modal v-if="isChangeDateModal" @close="toggleChangeDateModal">
      <change-connection-date-modal @change-date="onChangeConnectionDate" />
    </custom-modal>

    <custom-modal v-if="isChangeConnectionType" @close="toggleChangeConnectionType">
      <change-connection-type-modal @change-type="onChangeConnectionType" />
    </custom-modal>

    <side-modal v-if="isChangeBrigadeModal" @close="toggleChangeBrigadeModal">
      <option-modal title="Смена бригады" :array="brigades" :selected="details.connection_ticket.brigade_id" item-title="name_ru" @item-click="onChangeBrigade" />
    </side-modal>

  </div>
</template>

<script>


import {mapActions, mapGetters, mapMutations} from "vuex";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import HistoryTab from "@/components/tabs/HistoryTab.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import OptionModal from "@/components/modals/OptionModal.vue";
import SideModal from "@/components/modules/SideModal.vue";
import ChangeConnectionDateModal from "@/components/modals/changeConnection/ChangeConnectionDateModal.vue";
import ChangeConnectionTypeModal from "@/components/modals/changeConnection/ChangeConnectionTypeModal.vue";
import MapModal from "@/components/modals/MapModal.vue";
import OpkPickModal from "@/components/modals/OpkPickModal.vue";
import {findById} from "@/helpers/utils";

export default {
  name: 'ConnectionTicketDetailsComponent',
  components: {
    ChangeConnectionTypeModal,
    MapModal,
    TabsComponent,
    HistoryTab,
    CommentsTab,
    ImageModal,
    CustomModal,
    ChangeConnectionDateModal,
    OptionModal,
    SideModal,
    OpkPickModal
  },
  props: [],
  data() {
    return {
      tabs: [
        {
          title: 'Инфо',
          icon: '/assets/tabs/info-gray.svg',
          icon_active: '/assets/tabs/info-white.svg',
          key: 'info'
        },
        {
          title: 'тех.данные',
          icon: '/assets/tabs/wrench-gray.svg',
          icon_active: '/assets/tabs/wrench-white.svg',
          key: 'tech'
        },
        {
          title: 'Комментарии',
          icon: '/assets/tabs/comment-gray.svg',
          icon_active: '/assets/tabs/comment-white.svg',
          key: 'comment'
        },
        {
          title: 'ИСТОРИЯ',
          icon: '/assets/tabs/clock-gray.svg',
          icon_active: '/assets/tabs/clock-white.svg',
          key: 'history'
        },
      ],
      activeTab: 'info',
      image: '',
      isChangeDateModal: false,
      isChangeConnectionType: false,
      isChangeBrigadeModal: false,
      isAddressModal: false,
      isOpkChangeModal: false
    }
  },
  computed: {
    ...mapGetters({
      details: "chosenConnectionTicket",
      currentCommentPage: 'currentConnectionTicketCommentsPage',
      ticketComments: 'connectionTicketComments',
      totalComments: 'totalConnectionTicketComments',
      connectionTypes: 'connectionTypes',
      brigades: 'brigades',
    }),
  },
  // created() {
  //   this.fetchConnectionTicketDeadlines()
  // },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'fetchConnectionTicketDetail',
      fetchTicketComments: 'fetchConnectionTicketComments',
      addTicketComments: 'addConnectionTicketComments',
      changeConnectionDate: 'changeConnectionDate',
      changeConnectionBrigade: 'changeConnectionBrigade',
      fetchConnectionTicketDeadlines: 'fetchConnectionTicketDeadlines',
      changeConnectionType: 'changeConnectionType',
      fetchNearOpk: 'fetchNearOpk'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
    }),
    changeTab(tab) {
      this.activeTab = tab
    },
    brigadeName(id) {
      return findById(this.brigades, id)?.name_ru
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({connection_ticket_id: this.details.connection_ticket.id, page: this.currentCommentPage})
    },
    toggleImageModal(src = '') {
      this.image = src
    },
    toggleChangeDateModal() {
      this.isChangeDateModal = !this.isChangeDateModal
    },
    toggleChangeConnectionType() {
      this.isChangeConnectionType = !this.isChangeConnectionType
    },
    toggleChangeBrigadeModal() {
      this.isChangeBrigadeModal = !this.isChangeBrigadeModal
    },
    toggleAddressModal() {
      this.isAddressModal = !this.isAddressModal
    },
    toggleOpkChangeModal() {
      this.isOpkChangeModal = !this.isOpkChangeModal
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    async orkChanged() {
      this.toggleOpkChangeModal()
      this.setLoading(true)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    async onChangeBrigade(id) {
      let payload = {
        brigade_id: id,
        connection_ticket_id: this.details.connection_ticket.id
      }
      this.setLoading(true)
      this.toggleChangeBrigadeModal()
      await this.changeConnectionBrigade(payload)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    addComment(comment) {
      comment.connection_ticket_id = this.details.connection_ticket.id
      this.addTicketComments(comment)
    },
    async onChangeConnectionType(data) {
      data.id = this.details.connection_ticket.id
      this.toggleChangeConnectionType()
      this.setLoading(true)
      await this.changeConnectionType(data)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    async onChangeConnectionDate(data) {
      data.id = this.details.connection_ticket.id
      this.toggleChangeDateModal()
      this.setLoading(true)
      await this.changeConnectionDate(data)
      await this.fetchTicketDetail(this.details.connection_ticket.id)
      this.setLoading(false)
    },
    async getNearOpk() {
      this.setLoading(true)
      await this.fetchNearOpk({
        latitude: this.details.connection_ticket.address.latitude,
        longitude: this.details.connection_ticket.address.longitude
      })
      this.setLoading(false)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  max-height: 340px;
  border-radius: 15px;
  background: $gray4;
  padding: 20px;


  & > div {
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;

    .images {
      img {
        width: 50px;
        height: 50px;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    & > p:first-child {
      width: 35%;
    }
  }
}

.actions {
  margin-top: 28px;
  &-buttons {
    background: #2FCD86;
    color: white;
    border: none;
    outline: none;
    padding: 14px 16px;
    border-radius: 12px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:hover {
      background: #36B27A;
    }
    &_info {
      background: #00557B;
      &:hover {
        background: #073C54;
      }
    }
    &_departure {
      background: #36A2BD;
      &:hover {
        background: #2F8FA7;
      }
    }
    &_waiting {
      background: #4294FF;
      &:hover {
        background: #1D74E7;
      }
    }
    &_emergency {
      background: #FDA272;
      &:hover {
        background: #F98D54;
      }
    }
    &_complete {
      background: #2FCD86;
    }
  }
}

.option {
  color: $black2;
  height: 58px;

  &.active,
  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}
</style>
