<template>
  <div class="choose-modal">
    <div v-if="label" class="dropdown-label">{{ label }}</div>
    <div class="dropdown w-100" ref="dropdownContainer">
      <input
        type="text"
        class="input-form select mt-1"
        v-model="searchQuery"
        @input="handleInput"
        @click="toggleDropdown"
        :placeholder="placeholder"
      />
      <div class="dropdown-controls">
        <img 
          v-if="!selectedValue"
          src="/assets/dropdown-toggle.svg" 
          class="dropdown-toggle"
          :class="{ open: isOpen }"
          alt="toggle"
          @click.stop="toggleDropdown"
        />
        <img 
          v-else
          src="/assets/clear-input.svg" 
          class="clear-icon"
          alt="clear"
          @click.stop="clearSelection"
        />
      </div>
      <div 
        class="dropdown-content scrollable" 
        :class="{ 
          show: isOpen,
          'position-top': shouldShowOnTop 
        }" 
        v-show="isOpen"
      >
        <div
          v-if="showAllOption"
          class="dropdown-item"
          @click="selectItem(null)"
        >
          Все
        </div>
        <div
          v-for="(item,i) in filteredItems"
          :key="i"
          @click="selectItem(item)"
          class="dropdown-item"
        >
          {{ item[titleProp] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Выберите значение'
    },
    items: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: [String, Number, Object, null],
      default: null
    },
    keyProp: {
      type: String,
      default: 'key'
    },
    titleProp: {
      type: String,
      default: 'title'
    },
    showAllOption: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:selectedValue', 'change'],
  data() {
    return {
      isOpen: false,
      searchQuery: '',
      internalValue: null,
      shouldShowOnTop: false
    }
  },
  watch: {
    selectedValue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          let selected;
          if(this.internalValue && this.internalValue[this.keyProp] === newVal){
            selected = this.items.find(item => item[this.titleProp] === this.internalValue[this.titleProp])
          } else{
            selected = this.items.find(item => item[this.keyProp] === newVal)
            this.internalValue = newVal
          }
          this.searchQuery = selected ? selected[this.titleProp] : ''
        } else {
          this.searchQuery = ''
          this.internalValue = null
        }
      }
    }
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) return this.items
      
      const query = this.searchQuery.toLowerCase()
      return this.items.filter(item => 
        item[this.titleProp].toLowerCase().includes(query)
      )
    }
  },
  methods: {
    handleInput() {
      this.isOpen = true
      if (!this.searchQuery) {
        this.$emit('update:selectedValue', null)
        this.internalValue = null
      }
      // If exact match is found, select it
      const exactMatch = this.items.find(
        item => item[this.titleProp].toLowerCase() === this.searchQuery.toLowerCase()
      )
      if (exactMatch) {
        this.internalValue = exactMatch
        this.$emit('update:selectedValue', exactMatch[this.keyProp])
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => {
          this.updateDropdownPosition();
        });
      }
    },
    updateDropdownPosition() {
      const dropdownContainer = this.$refs.dropdownContainer;
      const dropdownContent = dropdownContainer.querySelector('.dropdown-content');
      if (!dropdownContainer || !dropdownContent) return;

      const containerRect = dropdownContainer.getBoundingClientRect();
      const spaceBelow = window.innerHeight - containerRect.bottom;
      const spaceAbove = containerRect.top;
      const contentHeight = dropdownContent.offsetHeight;

      const minSpace = contentHeight + 10;

      this.shouldShowOnTop = spaceBelow < minSpace && spaceAbove > spaceBelow;
    },
    selectItem(item) {
      this.internalValue = item
      this.searchQuery = item ? item[this.titleProp] : ''
      this.$emit('update:selectedValue', item ? item[this.keyProp] : null)
      this.$emit('change', item)
      this.isOpen = false
    },
    handleClickOutside(event) {
      const dropdown = this.$el;
      if (!dropdown.contains(event.target)) {
        this.isOpen = false;
        // Reset search query to selected value if clicked outside
        if (this.internalValue) {
          const selected = this.items.find(item => item[this.keyProp] === this.internalValue[this.keyProp] && item[this.titleProp] === this.internalValue[this.titleProp]);
          this.searchQuery = selected ? selected[this.titleProp] : '';
        } else {
          this.searchQuery = '';
        }
      }
    },
    clearSelection() {
      this.selectItem(null)
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('scroll', this.updateDropdownPosition);
    window.addEventListener('resize', this.updateDropdownPosition);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.updateDropdownPosition);
    window.removeEventListener('resize', this.updateDropdownPosition);
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  width: 100%;

  .input-form {
    background: #F2F1F0 !important;
    border: none !important;
    border-radius: 12px !important;
    height: 44px !important;
    width: 100%;
    padding: 0 56px 0 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    &::placeholder {
      color: #757575;
    }
  }

  .dropdown-controls {
    position: absolute;
    right: 12px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .clear-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 1;
    }
  }

  .dropdown-toggle {
    transition: transform 0.2s ease;
    width: 12px;
    height: 12px;
    opacity: 0.8;
    cursor: pointer;
    
    &.open {
      transform: rotate(180deg);
    }
  }
}

.dropdown-label {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  background: #F2F1F0;
  border-radius: 12px;
  margin-top: 4px;
  z-index: 100;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.03);
  top: 100%;
  
  &.position-top {
    bottom: 100%;
    top: auto;
    margin-top: 0;
    // margin-bottom: 2px;
  }
  
  &.scrollable {
    max-height: 240px;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 4px;
      
      &:hover {
        background: #a1a1a1;
      }
    }
  }
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    background-color: darken(#F2F1F0, 5%);
  }
}

.select {
  background: #F2F1F0 !important;
  border: none !important;
  border-radius: 8px !important;
  height: 44px !important;
  
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.dropdown-search {
  padding: 8px;
  border-bottom: 1px solid #E5E7EB;
  
  .search-input {
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #2A5298;
    }
    
    &::placeholder {
      color: #9CA3AF;
    }
  }
}
</style> 