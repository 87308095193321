<template>
  <div class="wrapper modified flex column">
    <div class="block">
      <subtask-ticket-details-component v-if="chosenTicket.ticket?.id" />
    </div>
  </div>
</template>

<script>
import TicketDetailsComponent from "@/components/cards/TicketDetailsComponent.vue";
import {mapActions, mapGetters} from "vuex";
import EmergencyTicketDetailsComponent from "@/components/cards/emergencyTickets/EmergencyTicketDetailsComponent.vue";
import SubtaskTicketDetailsComponent from "@/components/cards/subtasks/SubtaskTicketDetailsComponent.vue";
export default {
  name: 'SubtaskTicketDetailView',
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SubtaskTicketDetailsComponent,
  },
  created() {
    if (this.isModal === false) {
      this.fetchTicketDetail(this.$route.params.id)
    }
  },
  watch: {
    '$route.params.id'(newId) {
      this.fetchTicketDetail(newId);
    }
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
    })
  },
  methods: {
    ...mapActions({
      fetchTicketDetail: 'ticketDetail',
    })
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

</style>
