<template>
  <div class="flex column w-100">
    <div class="flex column bg-white p-20 rounded" v-if="item">
      <div class="flex justify-between mb-4">
        <div class="flex align-center gap-2">
          <h2 class="text-black2">{{item.title}} <b>#{{ item.ticket_number }}</b></h2>
          <div class="status-badge flex align-center text-black2" v-if="item.status">
            <img :src="item.status.key === 'finished' ? '/assets/status_done.svg' : '/assets/status_inprocess.svg'"
              alt="">
            {{ item.status.title }}
          </div>
        </div>
        <div>
          <img height="24" width="24" class="block cursor-pointer" @click="$router.go(-1)" src="/assets/close.svg" alt="" />
        </div>
      </div>
      <QueriesTCMainInfoComponent :item="item" :necessaryEquipments="necessaryEquipments"
        :additionalGoods="additionalGoods" :connectionMethods="connectionMethods" />
      <QueriesTCTabsComponent :item="item" />
    </div>
    <div class="actions flex flex-wrap" v-if="item.status.key !== 'closed'">
      <button @click="handleApprove" class="actions-buttons mr-2 actions-buttons_complete">
        Подтвердить ТВ
      </button>
      <button @click="handleReject" class="actions-buttons mr-2 actions-buttons_emergency">
        Отказать в ТВ
      </button>
    </div>
    <custom-modal v-if="approveModal" @close="closeModal">
      <div class="approve-modal flex column gap-3">
        <h3 class="text-center">Подтверждение ТВ</h3>
        <div class="approve-modal-content">
          <div class="form-group">
            <label>Метод подключения *</label>
            <select class="input-form" v-model="approveForm.connection_method_id" required>
              <option value="">Выберите метод</option>
              <option v-for="method in connectionMethods" :key="method.id" :value="method.id">
                {{ method.name_ru }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Расстояние по трассе *</label>
            <input type="number" class="input-form" v-model="approveForm.distance_along_the_route" required>
          </div>

          <div class="form-group">
            <label>Ориентировочное время подключения *</label>
            <input type="date" class="input-form" v-model="approveForm.estimated_connection_date" required>
          </div>

          <div class="form-group">
            <label>Схема подключения *</label>
            <UploadFile ref="fileInput" multiple @filesUpdated="handleFilesUpdate" />
          </div>

          <div class="form-group">
            <label>Доступ *</label>
            <fieldset class="mt-2 flex gap-2">
              <div>
                <input id="need" type="radio" v-model="approveForm.need_access" :value="true">
                <label for="need">Нужен</label>
              </div>
              <div>
                <input id="no_need" type="radio" v-model="approveForm.need_access" :value="false">
                <label for="no_need">Не нужен</label>
              </div>
            </fieldset>
            <div v-if="approveForm.need_access" class="mt-3" >
              <QueriesTCAccessForm ref="accessForm" :parent-ticket-id="item.id" :ticket="item" @created="handleAccessCreated"/>
            </div>
            <div v-else-if="accessCreated" class="text-center">
              Заявка на доступ создана
            </div>
          </div>

          <div class="form-group">
            <label>Себестоимость *</label>
            <input type="number" class="input-form" v-model="approveForm.price" required>
          </div>

          <div class="form-group">
            <label>Дополнительные услуги</label>
            <multiselect
              v-model="approveForm.additional_services"
              :options="additionalGoods"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Выберите услуги"
              label="name_ru"
              track-by="id"
            >
              <template #no-result>Нет результатов</template>
              <template #no-options>Нет данных</template>
            </multiselect>
          </div>

          <div class="form-group">
            <label>Необходимое оборудование</label>
            <multiselect
                v-model="approveForm.necessary_equipments"
                :options="necessaryEquipmentsWithNone"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Выберите оборудование"
                label="name_ru"
                track-by="id"
                @select="handleNecessaryEquipmentsSelect"
                @remove="handleNecessaryEquipmentsRemove"
            >
                <template #no-result>Нет результатов</template>
                <template #no-options>Нет данных</template>
            </multiselect>
          </div>

          <div class="form-group">
            <label>Комментарий *</label>
            <app-textarea class="input-form" v-model="approveForm.comment" required maxlength="600" />
          </div>

          <button class="btn btn-primary" @click="validateAndSubmit">Подтвердить</button>
        </div>
      </div>
    </custom-modal>
    <custom-modal v-if="commentModal" @close="closeRejectModal">
      <div class="choose-modal mt-4 mb-4 flex column justify-center gap-2">
        <label class="choose-modal__title w-100">Введите комментарий</label>
        <app-textarea 
          required 
          class="input input-form" 
          v-model="comment" 
          placeholder="" 
          maxlength="600" 
        />
        <button class="btn btn-primary" @click="sendActionStatus">Сохранить</button>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QueriesTCMainInfoComponent from "./QueriesTCMainInfoComponent.vue";
import QueriesTCTabsComponent from "./QueriesTCTabsComponent.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import AppTextarea from "@/components/ui/AppTextarea.vue";
import Multiselect from 'vue-multiselect'
import UploadFile from "@/components/UploadFile.vue";
import QueriesTCAccessForm from "@/components/cards/queriesTC/QueriesTCAccessForm.vue";

export default {
  name: 'QueriesTCDetailsComponent',
  components: {
    QueriesTCAccessForm,
    AppTextarea,
    QueriesTCMainInfoComponent,
    QueriesTCTabsComponent,
    CustomModal,
    Multiselect,
    UploadFile
  },
  data() {
    return {
      status: '',
      comment: '',
      commentModal: false,
      approveModal: false,
      isClicked: false,
      approveForm: {
        connection_method_id: '',
        distance_along_the_route: '',
        estimated_connection_date: '',
        price: '',
        additional_services: [],
        necessary_equipments: [],
        comment: '',
        files: [],
        need_access: null,
      },
      accessCreated: null
    }
  },
  props: [],
  created() {

  },
  computed: {
    ...mapGetters('queriesTCModule', {
      item: "item",
      necessaryEquipments: "necessaryEquipments",
      additionalGoods: "additionalGoods",
      connectionMethods: "connectionMethods",
    }),
    necessaryEquipmentsWithNone() {
      return [
        { id: 0, name_ru: 'Нет' },
        ...this.necessaryEquipments
      ]
    },
    isFormValid() {
      return this.approveForm.connection_method_id &&
             this.approveForm.distance_along_the_route &&
             this.approveForm.estimated_connection_date &&
             this.approveForm.price &&
             this.approveForm.comment &&
             this.$refs.fileInput?.files?.length > 0
    }
  },
  methods: {
    ...mapActions('queriesTCModule', {
      changeActionStatus: "changeActionStatus",
      updateItem: "updateItem"
    }),
    handleApprove() {
      this.approveModal = true
    },
    handleReject() {
      this.commentModal = true;
      this.status = 'impossible'
    },
    async sendActionStatus() {
      if (!this.comment) {
        this.$toast.error('Пожалуйста, введите комментарий');
        return;
      }

      try {
        await this.changeActionStatus({
          id: this.item.ticket_number,
          payload: {
            status: this.status,
            comment: this.comment
          }
        });
        this.$toast.success('Статус успешно изменен');
        this.closeRejectModal();
        this.$router.push('/queries-technical-capability');
      } catch (error) {
        console.error('Status change error:', error);
        this.$toast.error('Произошла ошибка при изменении статуса');
      }
    },
    closeModal() {
      this.approveModal = false
      this.approveForm = {
        connection_method_id: '',
        distance_along_the_route: '',
        estimated_connection_date: '',
        price: '',
        necessary_equipments: [],
        comment: '',
        files: [],
        need_access: null,
      }
      if (this.$refs.fileInput) {
        this.$refs.fileInput.$emit('filesUpdated', [])
      }
    },
    closeRejectModal() {
      this.commentModal = false;
      this.comment = '';
      this.status = '';
    },
    validateAndSubmit() {
      if (!this.approveForm.connection_method_id) {
        this.$toast.error('Пожалуйста, выберите метод подключения');
        return;
      }
      if (!this.approveForm.distance_along_the_route) {
        this.$toast.error('Пожалуйста, укажите расстояние по трассе');
        return;
      }
      if (!this.approveForm.estimated_connection_date) {
        this.$toast.error('Пожалуйста, укажите ориентировочное время подключения');
        return;
      }
      if (!this.approveForm.price) {
        this.$toast.error('Пожалуйста, укажите себестоимость');
        return;
      }
      if (!this.approveForm.comment) {
        this.$toast.error('Пожалуйста, добавьте комментарий');
        return;
      }
      if (!this.$refs.fileInput?.files?.length) {
        this.$toast.error('Пожалуйста, загрузите схему подключения');
        return;
      }
      if (this.approveForm.need_access === null) {
        this.$toast.error('Пожалуйста, укажите нужен ли доступ');
        return;
      }

      this.handleApproveSubmit();
    },
    async handleApproveSubmit() {
      try {
        const formData = {
          price: parseInt(this.approveForm.price),
          connection_method_id: parseInt(this.approveForm.connection_method_id),
          distance_along_the_route: parseFloat(this.approveForm.distance_along_the_route),
          estimated_connection_date: this.approveForm.estimated_connection_date,
          necessary_equipments: this.approveForm.necessary_equipments?.length === 1 && 
            this.approveForm.necessary_equipments[0].id === 0 
            ? [0] 
            : this.approveForm.necessary_equipments?.map(equipment => equipment.id) || [],
          additional_services: this.approveForm.additional_services?.map(service => service.id) || [],
          files: this.$refs.fileInput?.files?.map(file => ({
            name: file.uploadedName,
            path: file.uploadedPath
          })) || [],
          need_access: this.approveForm.need_access
        }

        await this.updateItem({
          id: this.item.id,
          payload: formData
        })

        await this.changeActionStatus({
          id: this.item.ticket_number,
          payload: {
            status: 'possible',
            comment: this.approveForm.comment
          }
        })

        this.$toast.success('ТВ успешно подтверждено')
        this.closeModal()
        this.$router.push('/queries-technical-capability')
      } catch (error) {
        console.error('Approve error:', error)
        this.$toast.error('Произошла ошибка при подтверждении')
      }
    },
    handleFilesUpdate(files) {
      this.approveForm.files = files
    },
    fullSizeImage() {
      this.isClicked = !this.isClicked
      if (!this.isClicked) {
        this.$refs.imageContainer.classList.remove('image-container-full')
      } else {
        this.$refs.imageContainer.classList.add('image-container-full')
      }
    },
    beforeUnmount() {
      this.isClicked = false
      if (this.$refs.imageContainer) {
        this.$refs.imageContainer.classList.remove('image-container-full')
      }
    },
    handleNecessaryEquipmentsSelect(selected) {
      if (selected.id === 0) {
        this.approveForm.necessary_equipments = [selected]
      } else {
        this.approveForm.necessary_equipments = this.approveForm.necessary_equipments.filter(e => e.id !== 0)
      }
    },
    handleNecessaryEquipmentsRemove(removed) {
      if (removed.id === 0) {
        this.approveForm.necessary_equipments = []
      }
    },
    handleAccessCreated(value) {
      this.accessCreated = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.client-emergency {
  &__remove {
    font-size: 24px;
    color: red;
    margin-left: 10px;
  }
}

.client-accident {
  margin: 30px 0 60px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  &__search-bar {
    width: 450px;
  }

  &:deep(input) {
    border-radius: 5px;
    border: solid 1px #000;
    outline: none;
  }

  &:deep(.search-client__result-dropdown) {
    background: #fff;
    border-radius: 5px;
  }

  &:deep(.search-client__result-item) {
    color: #000;
  }

  &__label {
    font-size: 24px;
  }

  &__btn {
    height: unset;
    width: unset;
    padding: 15px 25px;

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
}

.w-200 {
  width: 200px;
}

.text-margin {
  margin-bottom: 6px;
}

.ticket-kind {
  border-radius: 15px;
  background: $gray4;
  font-size: 18px;
  line-height: 140%;
  padding: 12px;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%
}

.info-block {
  max-height: 340px;
  border-radius: 15px;
  background: $gray4;
  padding: 20px;


  &>div {
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;
  }

  .card {
    margin-bottom: 16px;


    &:last-child {
      margin-bottom: 0;
    }

    &>p:first-child {
      width: 35%;
    }
  }
}

.actions {
  margin-top: 28px;

  &-buttons {
    background: #04567B;
    color: white;
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 25px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_info {
      background: #04567B;
    }

    &_departure {
      background: #57C2DC;
    }

    &_waiting {
      background: #0095FF;
    }

    &_emergency {
      background: #cf0000;
    }

    &_complete {
      background: #15AD37;
    }
  }
}

.choose-modal {
  width: 500px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

.approve-modal {
  width: 500px;

  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &-content {
    max-height: calc(80vh - 100px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      color: #666;
    }

    .input-form {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}

.multiselect {
  min-height: 40px;
  
  &__tags {
    min-height: 40px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  &__single {
    margin-bottom: 0;
    font-size: 14px;
  }
  
  &__option {
    padding: 8px;
    min-height: 40px;
    
    &--highlight {
      background: #f3f3f3;
      color: #333;
    }
  }
}

.images {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    border-radius: 4px;
    object-fit: cover;
  }
}

.text-20 {
  font-size: 20px;
}

.image-container {
  margin-top: 48px;
  width: 600px;

  img {
    width: 100%;
  }
}

.image-container-full {
  width: 100%;
}

.btn-image {
  display: flex;
  background: transparent;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
</style>