<template>
  <div class="p-20 text-20 text-center">
    <h1 class="mb-3">Создать дежурство</h1>
    <form @submit.prevent="createDutyScheduleBtn">
      <div class="mb-2">
        <label >Дежурный</label>
        <select class="input-form select mt-1" v-model="manager_id">
          <option disabled value=""></option>
          <option
              v-for="option in managers"
              :key="option.id"
              :value="option.id"
          >
            {{ option.full_name}}
          </option>
        </select>
      </div>
      <div class="mb-2">
        <label class="block">Дата начала</label>
        <input class="block input-form mt-1" type="datetime-local" v-model="start_date" required max="2999-12-31T00:00"/>
      </div>
      <div class="">
        <label class="block">Дата окончания</label>
        <input class="block input-form mt-1" type="datetime-local" v-model="end_date" required max="2999-12-31T00:00"/>
      </div>
      <div class="mt-3 flex gap-2">
        <button type="submit" class="btn" @click.prevent="createDutyScheduleBtn">Создать</button>
        <button type="button" class="btn" style="background: gray" @click.prevent="closeModal">Отмена</button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DutyScheduleCreateModal",
  props: {
    departmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      manager_id: 0,
      start_date: '',
      end_date: '',
    }
  },
  async created() {
    if (this.departmentId){
      await this.fetchManagers({department_id: this.departmentId})
    } else {
      await this.fetchManagers()
    }
  },
  computed: {
    ...mapGetters({
      managers: 'managers'
    })
  },
  methods: {
    ...mapActions({
      createDutySchedule: 'createDutySchedule',
      fetchSchedule: 'fetchDutyScheduleAll',
      fetchManagers: 'fetchManagers',
      errorAlert: 'errorAlert',
    }),
    closeModal() {
      this.$emit('close')
    },
    async createDutyScheduleBtn() {
      const obj = {
        schedule: [
          {
            manager_id: this.manager_id,
            started_at: this.start_date,
            ended_at: this.end_date,
          }
        ]
      }
      await this.createDutySchedule(obj).then((res)=> {
        if (res.success){
          this.fetchSchedule()
          this.$emit('close')
          this.$emit('successModal')
        } else {
          this.errorAlert(`${res.message}`)
        }

      }).catch((err)=>{
        this.errorAlert(`${err.message}`)
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>