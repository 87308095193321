<template>
  <div class="flex justify-center cursor-pointer">
    <div class="mt-1 text-center">
      <img class="file-svg" src="/assets/plus-blue.svg" alt="">
      <div class="flex text-14 text-secondary">
        <div class="relative">
          <span>Вложения</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'DropZoneInner',
}
</script>
