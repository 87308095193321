<template>
  <div class="change-date flex column align-center">
    <div class="inner flex column align-center">
      <yandex-map
          :coordinates="[ defaultCenter.latitude, defaultCenter.longitude ]"
          :zoom="10"
          :controls="['rulerControl', 'zoomControl', 'searchControl']"
          @created="mapInit"
          :width="200"
      >
        <yandex-marker
            v-for='polygon in polygons'
            :key='polygon.id'
            :marker-id="`polygon`"
            type="Polygon"
            :coordinates="[polygon.coordinates.map(([latitude, longitude]) => [longitude, latitude])]"
            :options="{
            iconColor: polygon.is_free ? '#808080' : '#FF0000',
            preset: 'islands#circleIcon',
            strokeColor: polygon.is_free ? '#808080' : '#FF0000',
            strokeWidth: 2,
            strokeOpacity: 0.6,
            fillColor: polygon.is_free ? '#808080' : '#FF0000',
            opacity: .6
          }"
            @click="polygonClick(polygon)"
        />
        <div v-if="selectedPolygons?.length">
          <div v-for="(item, idx) in selectedPolygons"
               :key="'segmentIdx' + idx">
            <yandex-marker
                :marker-id="`polygon`"
                type="Polygon"
                :coordinates="[item.coordinates.map(([latitude, longitude]) => [longitude, latitude])]"
                :options="{
                    iconColor: '#4AF100',
                    preset: 'islands#circleIcon',
                    strokeColor: '#4AF100',
                    strokeWidth: 1,
                    strokeOpacity: 0.6,
                    fillColor: '#4AF100',
                    opacity: .6
                }"
                @click="polygonUnClick(item)"
            />
          </div>
        </div>
      </yandex-map>
    </div>
  </div>
</template>

<script>
import {YandexMap, YandexMarker, loadYmap} from "vue-yandex-maps";
import {mapActions, mapMutations} from "vuex";

export default {
  name: 'AreasMapComponent',
  components: {
    YandexMap,
    YandexMarker
  },
  props: {
    polygons: {
      type: Object,
      required: true,
    },
    currentPolygons: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    defaultCenter: {
      latitude: 43.242411,
      longitude: 76.904358,
    },
    yandexMap: null,
    polygonFill: {
      color: '#ff0000',
      opacity: 0.1
    },
    polygonStroke: {
      color: '#ff0000',
      width: 1
    },
    selectedPolygons: []
  }),
  computed: {},
  methods: {
    ...mapActions({
      checkPolygon: 'checkPolygon'
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async mapInit(mapInstance) {
      if (mapInstance) {
        this.yandexMap = mapInstance
        const settings = {lang: "en_US"};
        await loadYmap(settings);
      }
    },
    polygonClick(polygon) {
      this.checkPolygon({polygon_id: polygon.id}).then((res) => {
        if (res?.data?.has_area) {
          this.$toast.error(res?.data?.message)
        } else {
          this.selectedPolygons.push(polygon);
          this.$emit('selected-polygons', this.selectedPolygons)

        }
      })
    },
    polygonUnClick(polygon) {
      if (this.selectedPolygons.some(item => item.id === polygon.id)) {
        this.selectedPolygons = this.selectedPolygons.filter(item => item.id !== polygon.id);
        this.$emit('selected-polygons', this.selectedPolygons)
      }
    },
    initializeSelectedPolygons() {
      this.selectedPolygons = this.polygons.filter((polygon) =>
          this.currentPolygons.some(
              (current) => current.polygon_id === polygon.id
          )
      );
    },
  },
  watch: {
    currentPolygons() {
      this.initializeSelectedPolygons();
    }
  }
}
</script>

<style lang="scss" scoped>
.change-date {

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}
</style>
