<template>
  <div class="relative h-100">
    <div class="to-calendar-card">
      <div
        class="flex justify-between align-center"
        :class="`${
					dateInfo.notThisMonth
						? 'disabled-date'
						: ''
				}`"
      >
        <div>
          <p class="bold text-black2">{{ dateInfo.number }}</p>
        </div>
        <div class="flex gap-1 align-center">
<!--          <p-->
<!--            :class="`${-->
<!--							dateInfo.dayOff-->
<!--								? 'text-red'-->
<!--								: 'text-gray2'-->
<!--						}`"-->
<!--          >-->
<!--            {{ dateInfo.day }}-->
<!--          </p>-->
          <div
            class="cursor-pointer"
            v-if="!dateInfo.notThisMonth && assignedBrigades.length > 0"
            @click.stop="toggleChooseBrigadeModal(dateInfo)"
          >
            <img
              v-if="!addDialog"
              class="small-plus"
              src="/assets/create.svg"
              alt="open"
            />
            <img
              v-else
              src="/assets/calendar/plusCircleGreen.svg"
              alt="open"
            />
          </div>
        </div>

      </div>
      <div
        class="flex flex-col gap-1 pt-1"
        :class="`${
					dateInfo.notThisMonth
						? 'disabled-date'
						: ''
				}`"
      >
        <div
          v-if="dateInfo.notThisMonth"
          role="status"
          class="w-100 animate-pulse"
        >
          <div class="gray-line_long mb-1"></div>
          <div class="flex justify-between gap-2 mb-1">
            <div class="gray-line_long"></div>
            <div class="gray-line_short"></div>
          </div>
          <div class="flex justify-between gap-2 mb-1">
            <div class="gray-line_long"></div>
            <div class="gray-line_short"></div>
          </div>
          <div class="flex justify-between gap-2 mb-1">
            <div class="gray-line_long"></div>
            <div class="gray-line_short"></div>
          </div>
          <div class="flex justify-between gap-2">
            <div class="gray-line_long"></div>
            <div class="gray-line_short"></div>
          </div>
        </div>
        <template v-if="!dateInfo.notThisMonth">
          <div class="flex column">
              <div
                class="to-calendar-card__brigade"
                v-for="(brigade, index) in assignedBrigades"
                :key="brigade">
                <div class="text-gray2 text-12">
                  {{ getBrigadeName(brigade) }}
                </div>
                <button @click="deleteBrigade(dateInfo, index)" class="cursor-pointer" title="убрать бригаду">
                  <img
                    src="/assets/calendar/minusCircle.svg"
                    alt="delete"
                  />
                </button>
              </div>
          </div>
          <div v-if="assignedBrigades.length < 1" class="empty-day flex justify-center align-center w-100">
            <img class="cursor-pointer" src="/assets/create.svg" alt="" @click="toggleChooseBrigadeModal(dateInfo)" />
          </div>
        </template>

      </div>
    </div>
<!--    <div-->
<!--      class="to-calendar-card__add-dialog"-->
<!--      ref="addDialog"-->
<!--      v-click-outside="close"-->
<!--      :class="{-->
<!--				'to-calendar-card__add-dialog&#45;&#45;overstepping':-->
<!--					isOversteppingScreen,-->
<!--				'h-0 w-0 overflow-hidden': !addDialog,-->
<!--			}"-->
<!--    >-->
<!--      <div-->
<!--        v-for="(department, index) in modifiedBrigadeDepartments"-->
<!--        :key="`all-departments-${department.id}`"-->
<!--        class="to-calendar-card__add-dialog-block"-->
<!--        @click="toggleSubDialog(index)"-->
<!--      >-->
<!--        {{ department.name }}-->
<!--        <div-->
<!--          v-if="department.brigadesDialog"-->
<!--          class="to-calendar-card__add-dialog to-calendar-card__add-dialog&#45;&#45;subitem"-->
<!--        >-->
<!--          <div-->
<!--            v-for="brigade in department.brigades"-->
<!--            :key="`all-brigades-${brigade.id}`"-->
<!--            class="to-calendar-card__add-dialog-block"-->
<!--            @click="setBrigade(brigade.id)"-->
<!--          >-->
<!--            {{ brigade.name }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <side-modal v-if="isChooseBrigadeModal" @close="toggleChooseBrigadeModal">
      <multiple-options-modal title="Смена бригады" :array="brigades" item-title="name_ru" :selected="assignedBrigades" @submit="onChooseBrigade" />
    </side-modal>
  </div>
</template>

<script>


import {mapActions, mapGetters} from "vuex"
import MultipleOptionsModal from "@/components/modals/MultipleOptionsModal.vue";
import SideModal from "@/components/modules/SideModal.vue";

export default {
  name: 'CalendarBlock',
  components: {MultipleOptionsModal, SideModal},
  props: {
    assignedBrigades: {
      type: Array,
      default: () => [],
    },
    dateInfo: {
      type: Object,
      required: true,
    },
    allBrigadeDepartments: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {
      addDialog: false,
      removeDialog: false,
      modifiedBrigadeDepartments: this.allBrigadeDepartments.map(
        (item) => ({
          ...item,
          brigadesDialog: false,
        })
      ),
      lastDepartmentIndex: undefined,
      isOversteppingScreen: false,
      isChooseBrigadeModal: false,
      selectedDate: ''
    };
  },
  computed: {
    ...mapGetters({
      brigades: 'brigades',
    }),
  },
  methods: {
    ...mapActions({
      error: 'errorAlert',
      changeBrigadeSchedule: 'changeBrigadeSchedule'
    }),
    toggleChooseBrigadeModal(dateInfo) {
      if (dateInfo) this.selectedDate = dateInfo.date
      this.isChooseBrigadeModal = !this.isChooseBrigadeModal
    },
    close() {
      this.addDialog = false;
      this.removeDialog = false;
      if (this.modifiedBrigadeDepartments[this.lastDepartmentIndex]) {
        this.modifiedBrigadeDepartments[
          this.lastDepartmentIndex
          ].brigadesDialog = false;
        this.lastDepartmentIndex = undefined;
      }
    },
    getBrigadeName(id) {
      let result = ''
      this.brigades.forEach(item => {
        if (item.id === id) result = item.name_ru
      })
      return result
    },
    toggleSubDialog(index) {
      if (index === this.lastDepartmentIndex) {
        this.modifiedBrigadeDepartments[
          this.lastDepartmentIndex
          ].brigadesDialog = false;
        this.lastDepartmentIndex = undefined;
        return;
      }
      if (this.modifiedBrigadeDepartments[this.lastDepartmentIndex]) {
        this.modifiedBrigadeDepartments[
          this.lastDepartmentIndex
          ].brigadesDialog = false;
      }
      this.modifiedBrigadeDepartments[index].brigadesDialog = true;
      this.lastDepartmentIndex = index;
    },
    // calculateScreen() {
    //   const dialogElement = this.$refs.addDialog;
    //   const positions = dialogElement.getBoundingClientRect();
    //   this.isOversteppingScreen =
    //     window.innerWidth - positions.left < 460;
    // },
    async setBrigade(brigade_id) {
      console.log({
        brigades: [brigade_id],
        days: [this.dateInfo.date],
      })
      // try {
      //   const res = await PostCreateSchedule({
      //     brigades: [brigade_id],
      //     days: [this.dateInfo.date],
      //   });
      //   console.log(res);
      // } catch (e) {
      //   console.log(e);
      //   this.error("Не удалось назначить бригаду");
      // } finally {
      //   this.close();
      //   this.$emit("refresh");
      // }
    },
    async deleteBrigade(dateInfo, index) {
      let newBrigades = this.assignedBrigades.slice()
      newBrigades.splice(index, 1)
      await this.changeBrigadeSchedule({
        schedule: [
          {
            day: dateInfo.date,
            brigades: newBrigades
          }
        ]
      })
      this.$emit("refresh");
      // try {
      //   await DeleteSchedule(scheduleId);
      // } catch (e) {
      //   console.log(e);
      //   this.error("Не удалось убрать бригаду");
      // } finally {
      //   this.$emit("refresh");
      // }
    },
    async onChooseBrigade(newBrigades) {
      await this.changeBrigadeSchedule({
        schedule: [
          {
            day: this.selectedDate,
            brigades: newBrigades
          }
        ]
      })
      this.$emit("refresh");
      this.toggleChooseBrigadeModal()
    }
  },
  mounted() {
    // this.calculateScreen();
  },
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';

.to-calendar-card {
  position: relative;
  padding: 8px;
  min-width: 150px;
  min-height: 150px;
  height: 100%;
  width: 100%;
  background: $white;
  border-bottom: 8px solid $gray4;

  &__brigade {
    padding: 4px;
    display: grid;
    grid-template-columns: 10fr 2fr;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    margin-bottom: 2px;

    &:nth-child(odd) {
      background: #D2F0FF;
    }

    &:nth-child(even) {
      background: #FEE6C9;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
    }
    &:hover {
      button {
        opacity: 1;
      }
    }
  }

  &__add-dialog {
    position: absolute;
    top: 36px;
    left: 20px;
    z-index: 1;
    width: 180px;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 1fr;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.5));
    user-select: none;
    &--subitem {
      max-height: 300px;
      overflow-y: auto;
      left: calc(100% + 4px);
      top: 0;
    }
    &--overstepping {
      left: initial;
      right: 110px;
      .to-calendar-card__add-dialog--subitem {
        left: initial;
        right: calc(100% + 4px);
      }
    }
  }
  &__add-dialog-block {
    position: relative;
    width: 100%;
    padding: 12px;
    color: #333333;
    font-weight: 600;
    font-size: 12px;
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      background-color: #2ab161;
      color: #ffffff;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
    }
  }
}

.disabled-date {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

.gray-line_long {
  width: 75%;
  border-radius: 50px;
  height: 12px;
  background: $gray2;
}

.gray-line_short {
  width: 17%;
  border-radius: 50px;
  height: 12px;
  background: $gray2;
}

.border-l {
  border-left: 1px solid $gray2;
  padding-left: 4px;
}

.empty-day {
  height: 90px;
}

.small-plus {
  width: 20px;
}

</style>
