import { debounce } from "lodash";

export default {
  data() {
    return {
      perPage: 100,
      page: 1,
      currentPage: 1,
      search: '',
      perPageOptions: [10, 25, 50, 100, 500],
      date_from: null,
      date_to: null,
      currentTab: 'all',
      isManagerInfoModal: false,
      isPlanDate: false,
      currentId: null,
      menuMainArray: [],
      responsible_id: null,
      brigade_id: null,
      connection_department: null,
      isDepartureLogsModal: false,
      showDropdown: false,
      showDropdownBrigade: false,
      filteredOptionsManagers: [],
      filteredOptionsBrigades: [],
      searchManager: '',
      searchBrigade: '',
      status_ids: [],
      isBrigadeModal: false,
      currentBrigadeId: 0,
      isConnectionInfoModal: false,
      isChangeResponsibleModal: false,
      isOrkModal: false,
      isCommentsModal: false,
      showDropdownCalendar: false,
      isBrigadeSort: false,
      tooltipStyles: {
        top: '0px',
        left: '0px'
      },
      isTooltipWithPeriod: false,
      isTooltipWithToday: false,
      isTooltipWithYesterday: false,
      selectedId: null,
      isShowCreateLCSTicket: false,
      isShowCreateAccessTicket: false,
      isShowCreateSubtask: false,
      selectedTicket: {},
      area_id: null,
      isFirstLoadDone: false,
      isSorted: false,
      sortedValue: null,
      isButtonCollapsed: false,
      dateRange: [],
      params: {
        tickets_per_page: 100,
        page: 1,
        responsible: {
          type: null,
          id: null,
        },
        search: null,
        status_ids: [],
        filter_date: {
          column: null,
          date_from: null,
          date_to: null,
        },
        responsible_dep: null,
        brigade_id: null,
        area_id: null,
      }
    }
  },
  created() {
    this.setLoading(true)
    const today = new Date()
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    
    this.date_from = this.formatDateForFilter(firstDay)
    this.date_to = this.formatDateForFilter(lastDay)
    
    // Format for BaseCalendar (YYYY-MM-DD)
    this.dateRange = [
      this.formatDateForCalendar(firstDay),
      this.formatDateForCalendar(lastDay)
    ]
    
    const savedFilters = localStorage.getItem(this.storageKey)
    
    if (!savedFilters) {
      this.params.filter_date.date_from = this.date_from
      this.params.filter_date.date_to = this.date_to
      this.params.filter_date.column = 'created_at'
    }

    this.params.client_type = this.clientType
    this.params.connection_ticket_type = this.connectionTicketType

    this.initializeMenu()
    let promises = [this.fetchConnectionStatuses(), this.fetchFinishedCount(), this.fetchManagers(),
      this.fetchDepartureManagers(), this.fetchBrigadeByDepartment(),
      this.fetchDepartureStatuses(), this.getAreas({per_page: 1000})]
    Promise.all(promises).then(() => {
      this.setFirstFilterValues()
      this.setLoading(false)
      this.isFirstLoadDone = true
      this.watcherFunction()
    })
  },
  computed: {
    totalPages() {
      return Math.ceil((this.connectionList?.meta?.total || 0) / this.perPage);
    },
    sortedData() {
      if (this.sortedValue) {
        return this.sortCalc(this.sortedValue);
      } else {
        return this.connectionList.data;
      }
    },
    showData() {
      if (this.date_from && this.date_to) {
        return `${this.date_from} - ${this.date_to}`;
      } else {
        return 'за Все время'
      }
    },
    allChecked() {
      return this.departureStatuses.every(option => option.checked);
    }
  },
  watch: {
    params: {
      handler: debounce(function () {
        const savedFilters = localStorage.getItem(this.storageKey)
        // Вызываем watcherFunction только если данные уже загружены и нет сохраненных фильтров
        if (this.isFirstLoadDone && !savedFilters) {
          this.watcherFunction();
        }
      }, 1000),
      deep: true
    },
    search(value) {
      this.params.search = value;
    },
    area_id(value) {
      this.params.area_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    status_ids(value) {
      this.params.status_ids = value;
      this.params.page = 1;
      this.page = 1;
    },
    responsible_id(value) {
      if (value) {
        this.params.responsible.type = 'manager';
        this.params.responsible.id = value;
      } else {
        this.params.responsible.type = null;
        this.params.responsible.id = null;
      }
      this.params.page = 1;
      this.page = 1;
    },
    brigade_id(value) {
      this.params.brigade_id = value;
      this.params.page = 1;
      this.page = 1;
    },
    searchManager(value) {
      this.filteredOptionsManagers = this.departureManagers.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.responsible_id = null;
        this.params.responsible.type = null;
        this.params.responsible.id = null;
      }
    },
    departureManagers(newOptions) {
      this.filteredOptionsManagers = newOptions;
    },
    searchBrigade(value) {
      this.filteredOptionsBrigades = this.brigades.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.brigade_id = null;
        this.params.brigade_id = null;
      }
    },
    brigades(newOptions) {
      this.filteredOptionsBrigades = newOptions;
    }
  },
  methods: {
    async initializeMenu() {
      await this.fetchDepartureRoles({is_head: 1}).then(() => {
        if (this.user) {
          this.menuMainArray = [
            {
              key: 'all',
              title: 'Все заявки'
            },
            {
              key: 'my',
              title: 'Мои заявки',
              value: this.user.id,
              type: 'manager'
            },
            {
              key: 'department',
              title: 'Мой департамент',
              type: 'department'
            },
            {
              key: 'open',
              title: 'Открытые заявки'
            },
            {
              key: 'closed',
              title: 'Закрытые заявки'
            },
          ]

          let roleNotFound = true;

          this.departureRoles.forEach((el) => {
            if (el.id === this.user.role_id) {
              roleNotFound = false;
            }
          });

          if (roleNotFound) {
            const keysToRemove = ['my', 'department'];
            this.menuMainArray = this.menuMainArray.filter(item => !keysToRemove.includes(item.key));
          }
        }
      })
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.params.tickets_per_page = perPage
    },
    onPageChange(page = 1) {
      this.page = page
      this.params.page = page
    },
    changeTab(item) {
      this.currentTab = item.key
      if (item.key === 'all') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'open') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = 1
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'closed') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = 0
        this.params.my_department = null
        this.params.filter_date.column = 'finished_at'
        this.params.page = 1
      } else if (item.key === 'partners') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = 1
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'department') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = 1
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'my'){
        this.params.responsible.type = "manager"
        this.params.responsible.id = item.value
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      }
    },
    async openManagerCard(id) {
      this.setLoading(true)
      await this.fetchManagerPhones(id).then(() => {
        this.isManagerInfoModal = true
        this.setLoading(false)
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    openChangeDate(id) {
      this.currentId = id
      this.isPlanDate = true
    },
    searchForConnections() {
      if (this.search === "") {
        this.search = null
      }
      this.params.page = 1
      this.page = 1
      this.params.search = this.search
    },
    openBrigadeModal(ticket) {
      this.currentBrigadeId = ticket.brigade_id
      this.currentId = ticket.id
      this.isBrigadeModal = true
    },
    getConnectionInfo(id) {
      this.currentId = id
      this.setLoading(true)
      let promises = [this.fetchConnectionInfo({id: id}), this.fetchProductServices({id: id}), this.getRelatedTickets(id)]
      Promise.all(promises).then(() => {
        this.$router.push(`/connection/${id}`);
        this.setLoading(false)
      })
    },
    openChangeResponsible(list) {
      if (list.status?.id !== 17) {
        this.isChangeResponsibleModal = true
        this.currentId = list.id
      }
    },
    responsibleFilter() {
      if (this.responsible_id) {
        this.params.responsible.type = 'manager'
        this.params.responsible.id = this.responsible_id
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
      }
    },
    departmentFilter(value) {
      this.connection_department = value.key;
      this.params.responsible_dep = value.key;
      this.params.page = 1;
      this.page = 1;
    },
    async openOrk(id) {
      this.setLoading(true)
      await this.fetchTicket({id: id})
      this.setLoading(false)
      this.isOrkModal = true
    },
    async orkChanged() {
      this.isOrkModal = false
      this.setLoading(true)
      await this.fetchTicket({id: this.connectionDepartureTicket.connection_ticket.id})
      this.setLoading(false)
    },
    async openComments(id) {
      this.setLoading(true)
      await this.fetchTicket({id: id})
      await this.fetchConnectionTicketDetail(id).then(() => {
        this.setLoading(false)
        this.isCommentsModal = true
      })
    },
    async openToggle(id, ticket) {
      this.selectedId = this.selectedId === id ? null : id
      this.selectedTicket = ticket
    },
    openCreateLCSTicket() {
      this.isShowCreateLCSTicket = true
    },
    openCreateAccessTicket() {
      this.isShowCreateAccessTicket = true
    },
    openCreateSubtask() {
      this.isShowCreateSubtask = true
    },
    addComment(comment) {
      comment.connection_ticket_id = this.connectionDepartureTicket.connection_ticket.id
      this.addTicketComments(comment)
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({
        connection_ticket_id: this.connectionDepartureTicket.connection_ticket.id,
        page: this.currentCommentPage
      })
    },
    openClientInfo(list) {
      this.setCurrentClientInfo(list.connection_client)
      this.setIsDepartureClientInfoModal(true)
    },
    async openLogs(id) {
      this.currentId = id
      this.isDepartureLogsModal = true
    },
    async downloadExcel() {
      if (!this.params.search) {
        delete this.params.search
      }
      this.setLoading(true)

      const res = await this.fetchExcelList({...this.params, download: 1})
      const url = window.URL.createObjectURL(res);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `отчеты.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    async watcherFunction() {
      if (this.isLoading) return;
      
      this.setLoading(true);
      try {
        const savedFilters = localStorage.getItem(this.storageKey)
        const additionalParams = savedFilters 
          ? { use_saved_filters: null } 
          : {};
          
        await this.fetchConnectionList({ ...this.params, ...additionalParams });

        if (this.params.filter_date?.date_from || this.params.filter_date?.date_to) {
          const filterParams = {
            filter_date: this.params.filter_date,
            area_id: this.params.area_id,
            client_type: this.params.client_type,
            connection_ticket_type: this.connectionTicketType,
          };
          
          await Promise.all([
            this.fetchFinishedCount(filterParams),
            this.fetchClosedConnectionTickets(filterParams),
          ]);
        }

        this.isFirstLoadDone = true;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$toast.error('Ошибка при загрузке данных');
      } finally {
        this.setLoading(false);
      }
    },
    changeArea() {
      if (this.area_id) {
        this.params.area_id = this.area_id
        this.page = 1
        this.params.page = 1
      } else {
        this.params.area_id = null
      }
    },
    async saveFilters() {
      localStorage.setItem(this.storageKey, JSON.stringify({...this.params, use_saved_filters: 1, search:null}))
      this.$toast.success('Фильтры успешно cохранены')
    },
    selectOption(option) {
      this.responsible_id = option.id
      this.searchManager = option.title;
      this.showDropdown = false;
      if (this.responsible_id) {
        this.params.responsible.type = 'manager'
        this.params.responsible.id = this.responsible_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
      }
    },
    selectOptionBrigade(option) {
      this.brigade_id = option.id
      this.searchBrigade = option.title;
      this.showDropdownBrigade = false;
      if (this.brigade_id) {
        this.params.brigade_id = this.brigade_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.brigade_id = null
      }
    },
    async resetFilters() {
      localStorage.removeItem(this.storageKey)
      
      this.page = 1
      this.perPage = 100
      this.responsible_id = null
      this.status_ids = []
      this.search = ''
      this.searchManager = ''
      this.connection_department = null
      this.currentTab = 'all'
      this.area_id = null
      this.brigade_id = null
      
      if (Object.prototype.hasOwnProperty.call(this, 'client_type')) {
        this.client_type = null
      }
      
      // Сброс параметров
      this.params = {
        tickets_per_page: 100,
        page: 1,
        responsible: {
          type: null,
          id: null,
        },
        search: null,
        status_ids: [],
        filter_date: {
          column: 'created_at',
          date_from: this.date_from,
          date_to: this.date_to,
        },
        responsible_dep: null,
        brigade_id: null,
        area_id: null,
        client_type: this.clientType,
        connection_ticket_type: this.connectionTicketType,
      }
      
      this.$toast.success('Фильтры успешно сброшены')
    },
    setFirstFilterValues() {
      const storageFilter = localStorage.getItem(this.storageKey) ? JSON.parse(localStorage.getItem(this.storageKey)) : {};
      if (Object.keys(storageFilter).length > 0) {
        this.params = {
          ...this.params,
          ...storageFilter,
          use_saved_filters: 1,
          search: null
        };

        // Установка client_type
        if (storageFilter.client_type) {
          this.client_type = storageFilter.client_type;
        }

        // Фильтр бригады
        if (storageFilter.brigade_id) {
          this.brigade_id = storageFilter.brigade_id;

          if (this.brigades && this.brigades.length > 0) {
            this.searchBrigade = this.brigades.find(el => el.id === this.brigade_id)?.title;
          } else {
            this.$watch('brigades', (newBrigades) => {
              if (newBrigades && newBrigades.length > 0) {
                this.searchBrigade = newBrigades.find(el => el.id === this.brigade_id)?.title;
              }
            });
          }
        }

        // Фильтр статусов
        if (storageFilter.status_ids && Array.isArray(storageFilter.status_ids)) {
          this.status_ids = [...storageFilter.status_ids];
          this.params.status_ids = [...storageFilter.status_ids];
        }

        // Фильтр категории
        if (storageFilter.responsible_dep) {
          this.connection_department = storageFilter.responsible_dep
        }

        // Фильтр района
        if (storageFilter.area_id) {
          this.area_id = storageFilter.area_id
        }

        // Фильтр количества записей на странице
        if (storageFilter.tickets_per_page) {
          this.perPage = storageFilter.tickets_per_page
        }

        // Фильтр ответственного
        if (storageFilter.responsible) {
          if (storageFilter.responsible.type === 'manager') {
            this.responsible_id = storageFilter.responsible.id || null;

            if (this.managers && this.managers.length > 0) {
              this.searchManager = this.managers.find(el => el.id === this.responsible_id)?.full_name;
            } else {
              this.$watch('managers', (newManagers) => {
                if (newManagers && newManagers.length > 0) {
                  this.searchManager = newManagers.find(el => el.id === this.responsible_id)?.full_name;
                }
              });
            }
          }
        }
      }
    },
    setDate(data) {
      if (data) {
        this.date_from = data?.[0]
        this.date_to = data?.[1]
        if(this.currentTab === 'closed') {
          this.params.filter_date.column = 'finished_at';
        } else {
          this.params.filter_date.column = 'created_at';
        }
        this.params.filter_date.date_from = this.date_from
        this.params.filter_date.date_to = this.date_to
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
        delete this.params.filter_date.date_from
        delete this.params.filter_date.date_to
        delete this.params.filter_date.column
      }
    },
    sortByBrigade() {
      this.isBrigadeSort = !this.isBrigadeSort
    },
    onTableClick(id) {
      const previouslySelected = document.querySelector('.selected');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected');
      }
      const el = document.getElementById(id);
      el.classList.add('selected');
    },
    clearBtn(value) {
      if (value === 'status') {
        this.status_ids = []
        this.params.status_ids = []
      } else if (value === 'department') {
        this.connection_department = null
        this.params.responsible_dep = null
      }
    },
    showTooltip(key, event) {
      const tooltipHeight = 40;
      const scrollContainer = event.currentTarget;
      const boundingRect = scrollContainer.getBoundingClientRect();

      this.tooltipStyles = {
        top: `${boundingRect.top - tooltipHeight + 100}px`,
        left: `${boundingRect.left + boundingRect.width / 2}px`,
      };

      if (key === 'period') {
        this.isTooltipWithPeriod = true;
      } else if (key === 'today') {
        this.isTooltipWithToday = true;
      } else if (key === 'yesterday') {
        this.isTooltipWithYesterday = true;
      }
    },
    hideTooltip(key) {
      if (key === 'period') {
        this.isTooltipWithPeriod = false;
      } else if (key === 'today') {
        this.isTooltipWithToday = false;
      } else if (key === 'yesterday') {
        this.isTooltipWithYesterday = false;
      }
    },
    onSortData(sortedValue) {
      this.isSorted = !this.isSorted
      this.sortedValue = sortedValue
    },
    sortCalc(sortedValue){
      const clonedList = [...this.connectionList.data];
      return clonedList.sort((a, b) => {
        const titleA = String(a[sortedValue] || '')
        const titleB = String(b[sortedValue] || '')
        return this.isSorted ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
      })
    },
    async openConnectionInfo(id){
      this.setLoading(true)
      await this.fetchConnectionInfo({id: id}).then(()=> {
        this.setLoading(false)
        this.isConnectionInfoModal = true
      }).catch((error)=> {
        this.$toast.error(`${error.message}`)
        this.isConnectionInfoModal = false
      }).finally(()=>{
        this.setLoading(false)
      })
    },
    async onClickStatus() {
      this.isButtonCollapsed = !this.isButtonCollapsed
    },
    checkAllStatuses() {
      const shouldCheck = this.departureStatuses.some(el => !el.checked);
      this.departureStatuses.forEach(el => {
        el.checked = shouldCheck;
      });
    },
    cancelFilters() {
      this.departureStatuses.forEach(item => {
        item.checked = false
      })
      this.params.status_ids = []
      this.isButtonCollapsed = false
    },
    changeStatus() {
      this.params.status_ids = this.status_ids;
      this.params.page = this.page = 1;
    },
    handleClickOutside(event) {
      if (this.$refs.statusDropdown && !this.$refs.statusDropdown.contains(event.target)) {
        this.isButtonCollapsed = false;
      }
    },
    formatDateForFilter(date) {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    formatDateForCalendar(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    handleSearchUpdate(newSearch) {
      this.search = newSearch;
      this.params.page = 1;
      this.page = 1;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
}; 