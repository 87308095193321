<template>
  <div class="p-20">
    <div class="p-20">
      <!-- Search input field -->
      <div class="flex">
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" v-model="searchQuery" ref="executor" placeholder="Поиск" type="text"/>
          <label class="label-form">Поиск</label>
        </div>
        <button @click="searchLocation" class="flex align-center search-btn">
          <p>Найти</p>
        </button>
      </div>

      <!-- Map container -->
      <div id="container" style="width: 600px; height: 400px;"></div>

      <!-- Selected coordinates display -->
      <p v-if="latitude && longitude" class="mt-1 mb-1">Выбранные координаты: {{ this.latitude }}, {{
          this.longitude
        }}</p>
      <p v-if="streetName" class=" mt-1 mb-1" style="word-break: break-all; width: 600px">Выбранная улица:
        {{ this.streetName }}</p>

      <!-- Select button -->
      <div class="flex justify-center">
        <button class="mt-1 btn w-30" @click="submitCoords">Выбрать</button>
      </div>
    </div>
  </div>
</template>


<script>
import {load} from '@2gis/mapgl';
import {mapGetters} from "vuex";

export default {
  name: "MapGisModal",
  data() {
    return {
      latitude: null,
      longitude: null,
      streetName: null,
      searchQuery: '',
      map: null,
      marker: null,
    };
  },
  mounted() {
    this.initGisMap();
  },
  computed: {
    ...mapGetters({
      gisKey: 'gisKey'
    })
  },
  methods: {
    async initGisMap() {
      const token = process.env.VUE_APP_GIS_TOKEN;
      // const token = this.gisKey;
      await load().then((mapglAPI) => {
        this.map = new mapglAPI.Map('container', {
          center: [76.889709, 43.238949],
          zoom: 12,
          key: token,
        });

        this.marker = new mapglAPI.Marker(this.map, {
          coordinates: [],
          interactive: true,
        });

        this.map.on('click', (event) => {
          const {lngLat} = event;
          const [longitude, latitude] = lngLat;
          this.marker.setCoordinates([longitude, latitude]);
          this.latitude = latitude;
          this.longitude = longitude;
          this.getStreetName(latitude, longitude);
        });
      });
    },
    async getStreetName(latitude, longitude) {
      const apiKey = process.env.VUE_APP_GIS_TOKEN;
      // const apiKey = this.gisKey;
      const url = `https://catalog.api.2gis.com/3.0/items/geocode?key=${apiKey}&lat=${latitude}&lon=${longitude}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.result.items.length) {
          this.streetName = data.result.items[0].full_name;
        } else {
          this.streetName = "Улица не найдена";
        }
      } catch (error) {
        console.error("Error fetching street name:", error);
        this.streetName = "Ошибка при получении улицы";
      }
    },
    async searchLocation() {
      if (!this.searchQuery) {
        return;
      }

      const apiKey = process.env.VUE_APP_GIS_TOKEN;
      const url = `https://catalog.api.2gis.com/3.0/items?q=${this.searchQuery}&key=${apiKey}&fields=items.point`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.result.items.length) {
          const firstResult = data.result.items[0];
          const point = firstResult.point;
          const longitude = point.lon;
          const latitude = point.lat;


          this.map.setCenter([longitude, latitude]);
          this.map.setZoom(15);
          this.marker.setCoordinates([longitude, latitude]);


          this.latitude = latitude;
          this.longitude = longitude;
          this.getStreetName(latitude, longitude);
        } else {
          this.$toast.error('Не удалось найти ')
          alert("Местоположение не найдено");
        }
      } catch (error) {
        this.$toast.error('Не удалось найти ')
        console.error("Error fetching search location:", error);
      }
    },
    submitCoords() {
      this.$emit('close', {
        coordinates: [+this.latitude, +this.longitude],
        street: this.streetName,
      });
    },
  },
};
</script>


<style scoped lang="scss">
.search-btn {
  background: rgb(31, 211, 69);
  border: 1px solid #8B88AB;
  cursor: pointer;
  border-radius: 6px;
  padding: 0 6px;
  height: 44px;
  margin-left: 12px;
  color: white
}
</style>