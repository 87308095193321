<template>
  <div class="wrapper wrapper-content modified flex column bg">
    <div class="bg-white p-20">
      <div class="flex justify-between items-center">
        <button class="btn w-30" @click="isCreateAccessModal = !isCreateAccessModal">Добавить адрес</button>
        <div class="flex">
        <button class="ticket-menu flex justify-center gap-1 align-center rounded cursor-pointer mr-2 navs"
                style="background: #7598f5;color: white;border: none; padding: 0 16px" @click="downloadExcelTruly">
          <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="" >
          <div>Выгрузить</div>
        </button>
        <button class="ticket-menu flex justify-center gap-1 align-center rounded cursor-pointer mr-2 navs"
                style="background: #1fd345;color: white;border: none; padding: 0 16px" @click="downloadExcel">
          <input ref="fileInput" type="file" accept=".xls,.xlsx" style="display: none" @change="uploadExcel"/>
          <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="" style="transform: rotate(180deg)">
          <div>Отправить</div>
        </button>
        </div>
      </div>
      <div class="mt-2">
        <div class="input-container input-container__form mb-2">
          <input class="input input-form" type="search" v-model="search"
                 placeholder="Поиск" @keyup="onSearch"/>
          <label class="label-form">Поиск</label>
          <div class="input-btn input-btn__form">
            <img src="/assets/search-form.svg" alt="">
          </div>
        </div>
      </div>
      <div>
        <div class="content w-100 mt-2 flex  column">
          <div class="grid" style="">
            <div class="grid-num">
              <p>№</p>
            </div>
            <div class="grid-name flex align-center justify-center">
              <p class="text-center">Название</p>
            </div>
            <div class="grid-address flex align-center justify-center">
              <p class="text-center">Адрес</p>
            </div>
            <div class="grid-contact flex align-center justify-center">
              <p class="text-center">ФИО</p>
            </div>
            <div class="grid-telephone flex align-center justify-center">
              <p class="text-center">Телефон</p>
            </div>
            <div class="grid-agreement flex align-center justify-center">
              <p class="text-center">№ Договора</p>
            </div>
            <div class="grid-notes cursor-pointer flex align-center justify-center">
              <p class="text-center">Примечание</p>
            </div>
            <div class="grid-accessType cursor-pointer flex align-center justify-center">
              <p class="text-center">Тип доступа</p>
            </div>
            <div class="grid-coords flex align-center justify-center">
              <p class="text-center">Координаты</p>
            </div>
          </div>
          <div class="access-table">
            <div class="grid" v-for="(el, index) in accessList.data" :key="el">
              <div class="grid-num">{{ index + 1 }}</div>
              <div class="grid-name">
                <input type="text" class="input input-access" v-model="el.name" @change="changeInfo(el)"
                >
              </div>
              <div class="grid-address">
                <input type="text" class="input input-access" v-model="el.address" @change="changeInfo(el)">
              </div>
              <div class="grid-contact">
                <input type="text" class="input input-access" v-model="el.supervisor" @change="changeInfo(el)">
              </div>
              <div class="grid-telephone">
                <input type="text" class="input input-access" v-model="el.phone" @change="changeInfo(el)">
              </div>
              <div class="grid-agreement">
                <input type="text" class="input input-access" v-model="el.agreement" @change="changeInfo(el)">
              </div>
              <div class="grid-notes">
                <input type="text" class="input input-access" v-model="el.notes" @change="changeInfo(el)">
              </div>
              <div class="grid-accessType">
<!--                <input type="text" class="input input-access" v-model="el.access_type" @change="changeInfo(el)">-->
                <select class="input-form select" style="max-width: 340px" @change="changeInfo(el)" v-model="el.ticket_address_access_type_id">
                  <option
                      v-for="option in accessTypes"
                      :key="option.key"
                      :value="option.id"
                  >
                    {{ option.title }}
                  </option>
                </select>
              </div>
              <div class="grid-coords">
                <div v-if="el.latitude && el.longitude" class="mb-1">
                  <input type="text" class="input input-access" v-model="el.latitude">
                  <input type="text" class="input input-access" v-model="el.longitude">
                </div>
                <div class="flex items-center justify-center gap-1" style="align-items: center">
                  <img src="/assets/access-data/2gis-svgrepo-com.svg" @click="openGisMap(el)" title="2GIS" height="24"
                       width="24" alt="">
                  <img src="/assets/access-data/yandex-svgrepo-com.svg" @click="openYandexMap(el)" title="Yandex"
                       height="20" width="22" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent :total-pages="totalPages" :current-page="page"
                           @page-change="onPageChange"></PaginationComponent>
    </div>

  </div>

  <custom-modal v-if="isCreateAccessModal" @close="isCreateAccessModal = !isCreateAccessModal">
    <access-data-create-modal @close="isCreateAccessModal = !isCreateAccessModal"></access-data-create-modal>
  </custom-modal>
  <custom-modal v-if="isGisModal" @close="isGisModal = !isGisModal">
    <map-gis-modal @close="getGisCoords"></map-gis-modal>
  </custom-modal>
  <custom-modal v-if="isYandexModal" @close="isYandexModal = !isYandexModal">
    <service-ticket-form-address-modal @close="getYandexCoords"></service-ticket-form-address-modal>
  </custom-modal>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import AccessDataCreateModal from "@/components/modals/access-data/AccessDataCreateModal.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import MapGisModal from "@/components/modals/access-data/MapGisModal.vue";
import ServiceTicketFormAddressModal from "@/components/modals/tickets/ServiceTicketFormAddressModal.vue";
import {debounce} from "@/helpers/utils";

export default {
  name: "AccessDataView",
  components: {ServiceTicketFormAddressModal, MapGisModal, PaginationComponent, AccessDataCreateModal, CustomModal},
  mounted() {
    this.fetchAccessDataList();
    this.getAccessAddressType()
  },
  data() {
    return {
      isCreateAccessModal: false,
      perPage: 50,
      page: 1,
      isGisModal: false,
      coords: null,
      currentElement: null,
      isYandexModal: false,
      search: '',
      timeout: null
    }
  },
  computed: {
    ...mapGetters({
      accessList: 'accessList',
      accessTypes: 'accessTypes',
    }),
    totalPages() {
      return Math.ceil((this.accessList.total || 0) / this.perPage);
    },
  },
  methods: {
    ...mapActions({
      fetchAccessDataList: 'fetchAccessDataList',
      fetchAccessDataExcel: 'fetchAccessDataExcel',
      updateAccessData: 'updateAccessData',
      downloadExcelAccessData: 'downloadExcelAccessData',
      getAccessAddressType: 'getAccessAddressType',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
    async downloadExcel() {
      this.$refs.fileInput.click()
    },
    async onPageChange(page = 1) {
      this.page = page
      await this.fetchAccessDataList({page: this.page})
    },
    async uploadExcel(event) {

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('infos', file);
      try {
        await this.fetchAccessDataExcel(formData);
        console.log('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
   async downloadExcelTruly(){
      this.setLoading(true)
   const res = await this.downloadExcelAccessData({page: this.page, download: 1})
     const url = window.URL.createObjectURL(res);

     const link = document.createElement("a");
     link.href = url;
     link.setAttribute("download", `База доступов.xlsx`);
     document.body.appendChild(link);
     link.click();
     window.URL.revokeObjectURL(url);
     this.setLoading(false)
    },
    async changeInfo(element) {
      console.log(element)
      await this.updateAccessData({
        id: element.id,
        name: element.name,
        address: element.address,
        phone: element.phone,
        supervisor: element.supervisor,
        agreement: element.agreement,
        notes: element.notes,
        longitude: element.longitude,
        latitude: element.latitude,
        ticket_address_access_type_id: element.ticket_address_access_type_id,
      }).catch((res) => {
        this.$toast.error(`${res.message}`);
      });
    },
    async getGisCoords(info) {
      this.currentElement.latitude = info.coordinates[0]
      this.currentElement.longitude = info.coordinates[1]
      this.isGisModal = false
      await this.updateAccessData({
        id: this.currentElement.id,
        name: this.currentElement.name,
        address: this.currentElement.address,
        phone: this.currentElement.phone,
        supervisor: this.currentElement.supervisor,
        agreement: this.currentElement.agreement,
        notes: this.currentElement.notes,
        longitude: this.currentElement.longitude,
        latitude: this.currentElement.latitude,
        ticket_address_access_type_id: this.currentElement.ticket_address_access_type_id,
      }).catch((res) => {
        this.$toast.error(`${res.message}`);
      });
    },
    async getYandexCoords(coords) {
      this.currentElement.latitude = coords[0]
      this.currentElement.longitude = coords[1]
      this.isYandexModal = false
      await this.updateAccessData({
        id: this.currentElement.id,
        name: this.currentElement.name,
        address: this.currentElement.address,
        phone: this.currentElement.phone,
        supervisor: this.currentElement.supervisor,
        agreement: this.currentElement.agreement,
        notes: this.currentElement.notes,
        longitude: this.currentElement.longitude,
        latitude: this.currentElement.latitude,
        ticket_address_access_type_id: this.currentElement.ticket_address_access_type_id,
      }).catch((res) => {
        this.$toast.error(`${res.message}`);
      });
    },
    openGisMap(el) {
      this.currentElement = el
      this.isGisModal = true
    },
    openYandexMap(el) {
      this.currentElement = el
      this.isYandexModal = true
    },
   async onSearch(){
     if (this.search.length > 1) {
       clearTimeout(this.timeout)
       this.timeout = setTimeout(async () => {
         this.setLoading(true)
         await this.fetchAccessDataList({search: this.search})
         this.setLoading(false)
       }, 500)
     } else {
       await this.fetchAccessDataList({search: null})
     }
    },
  },
}
</script>


<style scoped lang="scss">
.grid {
  display: grid;
  gap: 1px;
  align-items: center;
  padding: 1px 0;
  grid-template-columns: minmax(0, 40px) minmax(0, 3fr) minmax(0, 3fr) minmax(0, 2.6fr) minmax(0, 1.5fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1.5fr);
  grid-template-areas: 'Num Name Address Contact Telephone Agreement Notes AccessTypes Coords';
}

.grid-num {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Num;
}

.grid-name {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Name;
}

.grid-address {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Address
}

.grid-contact {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Contact
}

.grid-telephone {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Telephone
}

.grid-agreement {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Agreement;
}

.grid-notes {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: Notes;
}

.grid-accessType {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: AccessTypes;
}
.grid-ownerPhone {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px 10px;
  grid-area: OwnerPhone;
}
.grid-coords {
  border: 1px solid black;
  height: 100%;
  padding: 4px 10px;
  grid-area: Coords;

  img:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: 200ms;
  }
}

.input-access {
  border: none;
  padding: 0;
  font-size: 16px;
  height: auto;
}
</style>