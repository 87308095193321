<template>
  <div class="dropdown">
    <img style="width: 15px" @click="close" src="/assets/close.svg" alt="" />
    <button @click="createTicketLCS(rowItem)" class="dropdown-button">
      <span class="button-text">Создать заявку на ЛКС</span>
    </button>
    <button @click="createTicketAccess(rowItem)" class="dropdown-button">
      <span class="button-text">Создать заявку на доступ</span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ToggleDropdown',
  props: {
    rowItem: {
      type: Object,
      required: true
    }
  },
  emits: ['create-lcs', 'create-access', 'create-subtask', 'close'],
  methods: {
    createTicketLCS(data) {
      this.$emit('create-lcs', data);
    },
    createTicketAccess(data) {
      this.$emit('create-access', data);
    },
    createSubtask(data) {
      this.$emit('create-subtask', data);
    },
    close() {
      this.$emit('close')
    }
  }
});
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  top: 1px;
  right: 150px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 50;
  width: fit-content;
  padding: 11px 16px;
  white-space: nowrap;

  &:hover {
    transform: none;
  }

  &-button {
    height: 40px;
    cursor: pointer;
    padding: 0 12px;
    border-radius: 5px;
    color: green;
    border: 1px solid green;

    .button-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>