import { api } from '@/service/axios'

export const departmentsModule = {
  state: () => ({
    departments: [],
  }),
  
  getters: {
    departments: (state) => state.departments,
  },
  
  mutations: {
    setDepartments(state, payload) {
      state.departments = payload
    },
    updateDepartmentInState(state, updatedDepartment) {
      const index = state.departments.findIndex(dep => dep.id === updatedDepartment.id)
      if (index !== -1) {
        state.departments[index] = { ...state.departments[index], ...updatedDepartment }
      }
    }
  },
  
  actions: {
    async storeDepartment({ commit, dispatch }, payload) {
      const res = await api.post('departments/store-department', payload)
      if (res.success) {
        dispatch('fetchDepartments')
        return res
      } else {
        dispatch('errorAlert', res.message)
        return res
      }
    },

    async updateDepartment({ commit, dispatch }, payload) {
      const res = await api.put(`departments/update-department/${payload.id}`, {
        title: payload.title,
        dispatcher_id: payload.dispatcher_id,
        parent_id: payload.parent_id,
        bitrix_id: payload.bitrix_id,
        is_tech_dep: payload.is_tech_dep,
        is_departure: payload.is_departure
      })
      if (res.success) {
        commit('updateDepartmentInState', payload)
      } else {
        dispatch('errorAlert', res.message)
      }
      return res
    },

    async fetchDepartments({ commit }) {
      const res = await api.get('dict/get-departments')
      if (res.success) {
        commit('setDepartments', res.data)
      }
      return res
    },
  }
}
