<template>
  <div class="choose-modal" :class="{ 'expanded': showDropdown }">
    <div class="choose-modal__title w-100 mb-4">
      Выберите бригаду
    </div>

    <div class="dropdown w-100">
      <input
          type="text"
          class="input-form"
          v-model="search"
          @click="toggleDropdown"
          @input="showDropdown = true"
          placeholder="Выберите бригаду"
      />
      <div class="dropdown-content" :class="{ show: showDropdown }">
        <div
            v-for="option in filteredOptions"
            :key="option.id"
            @click="selectOption(option)"
        >
          {{ option.title }}
        </div>
      </div>
    </div>
    <button type="button" class="btn" @click="setBrigade">Назначить</button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'BrigadeAssignModal',
  props: {
    departmentId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      responsible_id: 0,
      search: '',
      showDropdown: false,
      filteredOptions: this.departments
    }
  },
  created() {
    this.getBrigadeByDepartment()
  },
  computed: {
  ...mapGetters({
    user: 'user',
    departments: 'brigadeByDepartment',
    details: "chosenTicket",
  })
  },
  watch: {
    search(value) {
      this.filteredOptions = this.departments.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
    },
    departments(newOptions) {
      this.filteredOptions = newOptions;
    }
  },
  methods: {
   ...mapActions({
     fetchBrigadeByDepartment: 'fetchBrigadeByDepartment'
   }),
    setBrigade() {
     if (this.responsible_id === 0) {
       return
     }
     this.$emit('submit', this.responsible_id)
    },
    async getBrigadeByDepartment() {
     if (this.departmentId !== 0) {
       await this.fetchBrigadeByDepartment({department_id: this.departmentId}).then((res)=>{
         if  (!res.success){
           this.$toast.error('Не удалось загрузить список бригад')
         }
         if(res.data.length === 0){
           this.$toast.error('Cписок бригад пуст')
         }
       })
     } else {
       await this.fetchBrigadeByDepartment({department_id: this.details.ticket?.ticket_department}).then((res)=>{
         if  (!res.success){
           this.$toast.error('Не удалось загрузить список бригад')
         }
         if(res.data.length === 0){
           this.$toast.error('Cписок бригад пуст')
         }
       })
     }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.responsible_id = option.id
      this.search = option.title;
      this.showDropdown = false;
    },
  },
}
</script>

<style scoped lang="scss">
.choose-modal {
  width: 416px;
  transition: min-height 0.3s ease;
  display: flex;
  flex-direction: column;
  
  &.expanded {
    min-height: 380px;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.4;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn {
  margin-top: 12px;
  width: 100%;
  padding: 12px;
}
</style>