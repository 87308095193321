<template>

  <div class="modal flex justify-center align-center">
    <div class="modal-overlay" @click="close"></div>
    <div class="spinner-container flex  column">
      <img src="/assets/spinner.svg" alt="spinner">
    </div>
  </div>

</template>

<script>


export default {
  name: 'CustomLoader',
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.modal-overlay {
  background: rgba(0, 0, 0, 0.51);
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  text-align: center;
  font-size: 27px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.27px;
}

/* Set the size of the image */
.spinner-container img {
  width: 120px; /* Adjust the width as per your image size */
  height: auto; /* Maintain the aspect ratio */
  margin-top: 12px;
}

/* Create the spinning animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the image */
.spinner-container img {
  animation: spin 2.3s linear infinite; /* Adjust the duration as per your preference */
}

.modal {
  z-index: 99999999;
}

</style>
