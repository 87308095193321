<template>
  <div class="filters-section">
    <div class="filters-grid">
      <div class="filter-item">
        <BaseSearch
          :model-value="search"
          label="ФИО/Тел/ИИН/Адрес"
          placeholder="Поиск по..."
          @update:model-value="handleSearchUpdate"
        />
      </div>
      <div class="filter-item">
        <BaseCalendar
          :model-value="dateRange"
          @setDate="$emit('setDate', $event)"
          :default-date-range="[date_from, date_to]"
          :show-clear-button="false"
        />
      </div>
      <div class="filter-item" v-if="showClientType">
        <BaseDropdown
          label="Тип клиента"
          :items="clientTypes"
          :selectedValue="client_type"
          @update:selectedValue="$emit('update:client_type', $event)"
          keyProp="title"
          titleProp="title"
          placeholder="Выберите тип клиента"
          @change="$emit('update:client_type', $event?.title || null)"
          @clear="$emit('clearBtn', 'client_type')"
          :clearable="true"
          :show-arrow="true"
        />
      </div>
      <div class="filter-item" v-if="showAreas">
        <BaseDropdown
          label="Участки"
          :items="areas"
          :selectedValue="area_id"
          @update:selectedValue="$emit('update:area_id', $event)"
          keyProp="id"
          titleProp="area_name"
          placeholder="Выберите участок"
          @change="$emit('update:area_id', $event?.id || null)"
        />
      </div>
      <div class="filter-item">
        <BaseMultiDropdown
          label="Статусы"
          :items="departureStatuses"
          :selectedValue="status_ids"
          @update:selectedValue="$emit('update:status_ids', $event)"
          keyProp="id"
          titleProp="title"
          placeholder="Выберите статусы"
          @change="$emit('update:status_ids', $event)"
        />
      </div>
      <div class="filter-item">
        <BaseDropdown
          label="Ответственный"
          :items="departureManagers"
          :selectedValue="responsible_id"
          @update:selectedValue="$emit('update:responsible_id', $event)"
          keyProp="id"
          titleProp="title"
          placeholder="Выберите менеджера"
          @change="$emit('update:responsible_id', $event?.id || null)"
        />
      </div>
      <div class="filter-item">
        <universal-departments-dropdown 
          @clearBtn="$emit('clearBtn', 'department')" 
          @departmentFilter="$emit('departmentFilter', $event)" 
          :current-value="connection_department"
          :responsible-dep="responsible_dep"
        />
      </div>
      <div class="filter-item">
        <BaseDropdown
          label="Бригада"
          :items="brigades"
          :selectedValue="brigade_id"
          @update:selectedValue="$emit('update:brigade_id', $event)"
          keyProp="id"
          titleProp="title"
          placeholder="Выберите бригаду"
          @change="$emit('update:brigade_id', $event?.id || null)"
        />
      </div>
      <div class="filter-item filter-buttons">
        <div class="flex gap-2">
          <BaseButton
            color="success"
            class="save-btn"
            @click="$emit('saveFilters')"
          >
            Сохранить
          </BaseButton>
          <BaseButton
            color="secondary"
            class="reset-btn"
            @click="$emit('resetFilters')"
          >
            Сбросить
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSearch from "@/components/ui/BaseSearch.vue";
import BaseCalendar from "@/components/ui/BaseCalendar.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import UniversalDepartmentsDropdown from "@/components/ui/UniversalDepartmentsDropdown.vue";
import BaseMultiDropdown from "@/components/ui/BaseMultiDropdown.vue";
import { debounce } from "lodash";

export default {
  name: "ConnectionFilters",
  components: {
    BaseSearch,
    BaseCalendar,
    BaseDropdown,
    BaseButton,
    UniversalDepartmentsDropdown,
    BaseMultiDropdown
  },
  data() {
    return {
      debouncedSearchUpdate: null,
    }
  },
  created() {
    // Create debounced search method
    this.debouncedSearchUpdate = debounce((value) => {
      this.$emit('update:search', value);
    }, 500);
  },
  methods: {
    handleSearchUpdate(value) {
      this.debouncedSearchUpdate(value);
    },
    handleClientTypeChange(value) {
      this.$emit('update:client_type', value?.title || null);
    }
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    showClientType: {
      type: Boolean,
      default: false
    },
    showAreas: {
      type: Boolean,
      default: true
    },
    client_type: {
      type: String,
      default: null
    },
    dateRange: {
      type: Array,
      default: () => []
    },
    date_from: {
      type: String,
      default: null
    },
    date_to: {
      type: String,
      default: null
    },
    area_id: {
      type: [String, Number],
      default: null
    },
    status_ids: {
      type: Array,
      default: () => []
    },
    responsible_id: {
      type: [String, Number],
      default: null
    },
    brigade_id: {
      type: [String, Number],
      default: null
    },
    connection_department: {
      type: [String, Number],
      default: null
    },
    responsible_dep: {
      type: String,
      default: null
    },
    areas: {
      type: Array,
      default: () => []
    },
    departureStatuses: {
      type: Array,
      default: () => []
    },
    departureManagers: {
      type: Array,
      default: () => []
    },
    brigades: {
      type: Array,
      default: () => []
    },
    clientTypes: {
      type: Array,
      default: () => [
        { title: 'b2b' },
        { title: 'b2c' }
      ]
    }
  },
  emits: [
    'update:search',
    'update:dateRange',
    'update:area_id',
    'update:status_ids',
    'update:responsible_id',
    'update:brigade_id',
    'update:client_type',
    'setDate',
    'clearBtn',
    'departmentFilter',
    'saveFilters',
    'resetFilters'
  ]
}
</script>

<style scoped lang="scss">
.filters-section {
  margin-bottom: 1.5rem;
}

.filters-grid {
  display: grid;
  gap: 1rem;
  align-items: start;
  
  grid-template-columns: 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }
  
  @media (min-width: 1536px) {
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
  }
}

.filter-item {
  min-width: 0;
  width: 100%;
  
  &.filter-buttons {
    @media (max-width: 767px) {
      grid-column: 1 / -1;
    }
  }
}

.filter-buttons {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;

  .flex {
    width: 100%;
    gap: 0.8rem !important;
    display: flex;

    @media (max-width: 767px) {
      justify-content: stretch;
      
      .save-btn, .reset-btn {
        flex: 1;
      }
    }

    @media (min-width: 768px) {
      justify-content: stretch;
      
      .save-btn, .reset-btn {
        flex: 1;
      }
    }
  }

  .save-btn, .reset-btn {
    height: 44px;
    padding: 0 12px;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
}
</style> 