<template>
  <div class="success flex column align-center">
    <div class="success-inner flex column align-center">

      <img :src="qrLink" alt="" />

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SurfQrModal',
  computed: {
    ...mapGetters({
      qrLink: 'qr',
    }),
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.success {

  width: 670px;

  &-inner {
    width: 400px;

  }

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $black2;
  }

  img {
    width: 400px;
    margin-top: 24px;
    margin-bottom: 60px;
  }
}
</style>
