import {api} from "@/service/axios";
import {request} from "axios";
import {getIndexById} from "@/helpers/utils";

export const departureModule = {
    state: () => ({
        connectionList: [],
        finishedCount: [],
        connectionInfo: [],
        connectionDepartureTicket: [],
        isDepartureClientInfoModal: false,
        currentClientInfo: [],
        productServices: {},
        connectionLogs: [],
        connectionDepartments: [],
        departureFilters: [],
        departureManagers: [],
        departureStatuses: [],
        departureRoles: [],
        departureOrk: [],
        departureInfoHosts: [],
        closedConnectionTickets: null
    }),
    getters: {
        connectionList: state => {
            return state.connectionList;
        },
        finishedCount: state => {
            return state.finishedCount;
        },
        connectionInfo: state => {
            return state.connectionInfo;
        },
        connectionDepartureTicket: state => {
            return state.connectionDepartureTicket;
        },
        isDepartureClientInfoModal: state => {
            return state.isDepartureClientInfoModal;
        },
        currentClientInfo: state => {
            return state.currentClientInfo;
        },
        productServices: state => {
            return state.productServices;
        },
        connectionLogs: state => {
            return state.connectionLogs;
        },
        connectionDepartments: state => {
            return state.connectionDepartments;
        },
        departureFilters: state => {
            return state.departureFilters;
        },
        departureManagers: state => {
            return state.departureManagers;
        },
        departureStatuses: state => {
            return state.departureStatuses;
        },
        departureRoles: state => {
            return state.departureRoles;
        },
        departureOrk: state => {
            return state.departureOrk;
        },
        departureInfoHosts: state => {
            return state.departureInfoHosts;
        },
        closedConnectionTickets(state) {
            return state.closedConnectionTickets
        },
    },
    mutations: {
        setConnectionList(state, payload) {
            state.connectionList = payload;
        },
        setFinishedCount(state, payload) {
            state.finishedCount = payload;
        },
        setConnectionInfo(state, payload) {
            state.connectionInfo = payload;
        },
        setConnectionDepartureTicket(state, payload) {
            state.connectionDepartureTicket = payload;
        },
        setIsDepartureClientInfoModal(state, payload) {
            state.isDepartureClientInfoModal = payload;
        },
        setCurrentClientInfo(state, payload) {
            state.currentClientInfo = payload;
        },
        setProductServices(state, payload) {
            state.productServices = payload;
        },
        setConnectionLogs(state,payload){
            state.connectionLogs = payload
        },
        setConnectionDepartments(state,payload) {
            state.connectionDepartments = payload
        },
        setDepartureFilters(state, payload){
            state.departureFilters = payload
        },
        setDepartureManagers(state,payload){
            state.departureManagers = payload
        },
        setDepartureStatuses(state, payload){
            state.departureStatuses = payload
        },
        setDepartureRoles(state, payload){
            state.departureRoles = payload
        },
        setDepartureOrk(state, payload) {
            state.departureOrk = payload
        },
        setDepartureInfoHosts(state, payload){
            state.departureInfoHosts = payload
        },
        setClosedConnectionTickets(state, payload) {
            state.closedConnectionTickets = payload
        },
    },
    actions: {
        async fetchConnectionList({commit}, payload) {
            const res = await api.get('connection-tickets/filter-connection-ticket-list', {params: payload});
            commit('setConnectionList', res.data);
            commit('setDepartureFilters', res.filters)
            return res
        },
        async changeConnectionDateDeparture({commit,dispatch}, payload) {
            const res = await api.post(`connection-tickets/change-connection-date/${payload.id}`, {connection_date: payload.connection_date})
            return res
        },
        async fetchFinishedCount({commit}, payload){
            const res = await api.get('connection-tickets/get-finished-count', {params: payload});
            commit('setFinishedCount', res.data);
            return res
        },
        async changeBrigadeDeparture({commit}, payload){
            const res = await api.post(`connection-tickets/change-brigade/${payload.ticket_id}`, {brigade_id: payload.brigade_id});
            return res
        },
        async fetchConnectionInfo({commit}, payload){
            const res = await api.get(`connection-tickets/connection-info/${payload.id}`);
            commit('setConnectionInfo', res.data);
            return res
        },
        async fetchProductServices({commit}, payload){
            const res = await api.get(`connection-tickets/${payload.id}/get-product-services`);
            commit('setProductServices', res.data);
            return res
        },
      async  fetchHostByNode({commit}, payload){
            const res = await api.get(`connection-tickets/get-host-by-node/${payload.node_id}`);

            return res
        },
        async changeResponsibleDeparture({commit}, payload){
            const res = await api.post(`connection-tickets/change-responsible/${payload.id}`, payload);
            return res
        },
        async fetchConnectionDepartureTicket({commit}, payload){
            const res = await api.get(`/connection-tickets/connection-ticket/${payload.id}`)
            commit('setConnectionDepartureTicket', res.data)
            return res
        },
        async fetchConnectionDepartureLogs({commit}, payload){
            const res = await api.get(`connection-tickets/${payload.id}/get-responsible-logs`);
            commit('setConnectionLogs', res.data)
            return res
        },
       async fetchExcelList({commit}, payload){
            const res = await api.get('connection-tickets/filter-connection-ticket-list', {params: payload, responseType: 'blob'});
            return res
        },
        async fetchConnectionDepartments({commit}, payload){
            const res = await api.get('dict/get-connection-departments', {params: payload});
            commit('setConnectionDepartments', res.data);
            return res
        },
        async saveRequestFilter({commit}, payload){
            const res = await api.post(`saved-request-filters/save`, payload)

            return res
        },
        async deleteRequestFilter({commit}, payload){
            const res = await api.delete('saved-request-filters/delete', {params: payload})

            return res
        },
        async fetchDepartureManagers({commit}, payload){
            const res  = await api.get('managers/connection-author-list')
            commit('setDepartureManagers', res.data)
            return res
        },
        async fetchDepartureStatuses({commit}, payload){
            const res = await api.get('dict/get-connection-ticket-statuses-list')
            commit('setDepartureStatuses', res.data)
            return res
        },
        async fetchDepartureRoles({commit}, payload){
            const res = await api.get('role/all-roles', {params: payload})
            commit('setDepartureRoles', res.data)
            return res
        },
        async fetchNearOrkDeparture({commit}, payload) {
            const res = await api.get(`connection-tickets/${payload.id}/near-ork`)
            commit('setDepartureOrk', res.data)
            return res
        },
        async fetchDepartureInfoHosts({commit}, payload){
            const res = await api.get(`connection-tickets/get-host-by-node/${payload.id}`)
            commit('setDepartureInfoHosts', res.data)
            return res
        },
        async changeDepartureInfoHost({commit}, payload){
            const res = await api.post(`connection-tickets/change-host_id/${payload.id}`, {host_id: payload.host_id});
            return res
        },
        async fetchClosedConnectionTickets ({commit}, payload) {
            const res = await api.get(`connection-tickets/get-finished-by-department-count`, {
                params: payload
            })
            commit('setClosedConnectionTickets', res)
        },
    }
}