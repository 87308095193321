<template>
  <div class="choose-modal">
    <div class="choose-modal__title w-100">
      Период
    </div>
    <div class="dropdown w-100">
      <input
          type="search"
          class="input-form select mt-1 cursor-pointer"
          style="height: 48px !important; border-radius: 25px !important;"
          @click="toggleDropdown"
          :value="showData"
          @input="showDropdown = true"
          placeholder="Выберите дату"
      />
      <div class="dropdown-content" :class="{ show: showDropdown }" style="width: 400px">
        <div style="display: flex; justify-content: space-between; gap: 10px;padding: 10px">
          <input type="date"  class="input-form cursor-pointer" v-model="start_date">
           <input type="date" class="input-form cursor-pointer" v-model="end_date">
        </div>
        <div style="padding: 10px">
        <button type="button" class="btn btn-green" @click="setDate">Применить</button>
        </div>
        <div>
          <p style="padding: 0 10px;color: gray">Или вывести данные за последний(-е):</p>
          <div class="flex justify-between" style="padding: 10px;">
            <button class="calendar_btn" @click="setTodayDate">Cегодня</button>
            <button class="calendar_btn" @click="setYesterdayDate">Вчера</button>
            <button v-if="type !== 'departments-tickets' && type !== 'OTP' " class="calendar_btn" @click="setWeekDate">Неделя</button>
            <button v-if="type === 'departments-tickets' || type === 'OTP'" class="calendar_btn" @click="setAllTimeDate">За все время</button>
            <button class="calendar_btn" @click="setMonthDate">Месяц</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {formatDate, formatDateReverse} from "@/helpers/utils";
import {mapGetters} from "vuex";

export default {
  name: "calendar-departure",
  created() {
    this.showDropdown = this.showDropdownCalendar;
    this.checkForRouteQueryAndLocalStorage()
    document.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  props: {
    showDropdownCalendar: {
      type: Boolean,
      default: false
    },
    ticketTypeAndClient: {
      type: Object,
      default: ()=>{}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDropdown: false,
      start_date:  '',
      end_date:  '',
      isFirstLoadDone: false,
    }
  },
  computed: {
    ...mapGetters({
      departureFilters: 'departureFilters',
      departureServiceTicketsFilters: 'departureServiceTicketsFilters'
    }),
    showData() {
      if (this.start_date && this.end_date) {
        return `${formatDate(new Date(this.start_date),'-', false)} / ${formatDate(new Date(this.end_date),'-', false)}`
      } else {
        return ''
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setDate(){
      if (this.start_date && this.end_date){
        this.$emit('setDate', [formatDate(new Date(this.start_date),'-', false),  formatDate(new Date(this.end_date),'-', false)]);
        this.toggleDropdown()
      } else {
        this.$emit('setDate',[this.start_date, this.end_date])
        this.toggleDropdown()
      }

    },
    setTodayDate(){
      let currentDate = new Date();

      let startDate = new Date(currentDate);
      startDate.setHours(0, 0, 0, 0);

      let endDate = new Date(currentDate);
      endDate.setHours(23, 59, 59, 999);

      this.start_date = formatDate(startDate,'-').split('-').reverse().join('-');
      this.end_date = formatDate(endDate,'-').split('-').reverse().join('-')
    },
    setYesterdayDate(){
      let currentDate = new Date();

      let yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(currentDate.getDate() - 1);

      let startDateYesterday = new Date(yesterdayDate);
      startDateYesterday.setHours(0, 0, 0, 0);

      let endDateYesterday = new Date(yesterdayDate);
      endDateYesterday.setHours(23, 59, 59, 999);

      this.start_date = formatDate(startDateYesterday,'-').split('-').reverse().join('-');
      this.end_date = formatDate(endDateYesterday,'-').split('-').reverse().join('-')
    },
    setWeekDate(){
      let currentDate = new Date();

      let startDateWeek = new Date(currentDate);
      startDateWeek.setDate(startDateWeek.getDate() - 7);
      startDateWeek.setHours(0, 0, 0, 0);
      let endDateWeek = new Date(currentDate);
      endDateWeek.setHours(23, 59, 59, 999);

      this.start_date = formatDate(startDateWeek,'-').split('-').reverse().join('-');
      this.end_date = formatDate(endDateWeek,'-').split('-').reverse().join('-')
    },
    setMonthDate(){
      let currentDate = new Date();
      let startDateMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      startDateMonth.setHours(0, 0, 0, 0);
      let endDateMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      endDateMonth.setHours(23, 59, 59, 999);

      this.start_date = formatDate(startDateMonth,'-').split('-').reverse().join('-');
      this.end_date = formatDate(endDateMonth,'-').split('-').reverse().join('-')
    },
    setAllTimeDate(){
      this.start_date = null
      this.end_date = null
    },
    handleClickOutside(event) {
      const dropdownElement = this.$el.querySelector('.dropdown');
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    checkForRouteQueryAndLocalStorage() {
      const dateFrom = this.$route.query.date_from;
      const dateTo = this.$route.query.date_to;

      if (dateFrom && dateTo) {
        this.start_date = dateFrom.split('-').reverse().join('-');
        this.end_date = dateTo.split('-').reverse().join('-');
        this.$emit('setDate', [dateFrom, dateTo]);

        this.isFirstLoadDone = true;
      } else {
        this.checkForLocalStorageToInitiateLoad();
      }
    },
    checkForLocalStorageToInitiateLoad() {
      const filterMappings = {
        'on_departure_b2c': 'filtersDepartureServiceB2C',
        'on_departure_b2b': 'filtersDepartureServiceB2B',
        'connection_b2c': 'filtersDepartureConnectionB2C',
        'connection_b2b': 'filtersDepartureConnectionB2B',
        'add-sale_b2c': 'filtersDepartureAddSaleB2C',
        'add-sale_b2b': 'filtersDepartureAddSaleB2B',
        'dis-assembly_b2b': 'filtersDepartureDisassemblyB2B',
        'transposition_b2b': 'filtersDepartureTranspositionB2B',
        'scs_b2b': 'filtersDepartureSCSB2B',
      };

      const ticketKey = `${this.ticketTypeAndClient?.type}_${this.ticketTypeAndClient?.client}`;
      const localStorageKey = filterMappings[ticketKey];

      if (localStorageKey) {
        const savedFilters = JSON.parse(localStorage.getItem(localStorageKey));
        if (savedFilters) {
          const filterDate = savedFilters.filter_date || savedFilters;
          this.start_date = filterDate.date_from.split('-').reverse().join('-');
          this.end_date = filterDate.date_to.split('-').reverse().join('-');
          this.$emit('setDate', [filterDate.date_from, filterDate.date_to]);
        } else {
          this.setDefaultDates();
        }
      } else {
        this.setDefaultDates();
      }
    },
    setDefaultDates() {
      this.setMonthDate();
      this.$emit(
          'setDate',
          [
            formatDate(new Date(this.start_date), '-', false),
            formatDate(new Date(this.end_date), '-', false),
          ]
      );
    }
  },
}
</script>
<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}
.calendar_btn {
  border: none;
  background: transparent;
  color: gray;
  text-decoration: underline;
  &:hover {
    color: green;
    text-decoration: none;
    transition: 200ms;
    cursor: pointer;
  }
}

.show {
  display: block;
}
</style>