<template>
  <div>
    <h1 style="font-size: 30px;">Редактирование Бригады</h1>
    <div style="background: #F5F5FA;border-radius: 10px;">
      <div class="mt-3" style="padding: 20px;">
        <p class="text-20 mb-1">Название</p>
        <input class="brigade-installer input-form" type="text" v-model="currentBrigadeOptions.brigade.title"/>
        <p class="text-20 mb-1 mt-2">Bitrix ID</p>
        <input class="brigade-installer input-form" type="text" v-model="currentBrigadeOptions.brigade.bitrix_id"/>
        <div><p class="text-20 mb-1 mt-2 ">Бригадир</p>
          <p class="brigade-installer">{{ currentBrigadeOptions.brigade.owner?.full_name || 'Не указано' }}</p>
          <div class="mt-1" style="color:blue; cursor: pointer;"
               @click="isOwnerModal = !isOwnerModal">
            Изменить
          </div>
        </div>
        <p class="text-20 mb-1 mt-2">Установщики</p>
        <div class="brigade-installer flex justify-between mt-2" v-for="installer in currentBrigadeOptions.installers"
             :key="installer.id">
          <div>
            <p>{{ installer.full_name }}</p>
            <p class="mt-2" style="color: gray">{{ installer.department }}</p>
          </div>
          <div @click="deleteCurrentBrigadeInstaller(installer.relation_id)">
            <img src="/assets/TrashBr.svg" style="cursor: pointer;"/>
          </div>
        </div>
        <div class="mt-3" style="color:blue; cursor: pointer;" @click="isInstallersListModal = !isInstallersListModal">
          Добавить сотрудника
        </div>

        <div class="mt-2">
          <div>Отдел</div>
          <div class="mt-2 mb-2">
            <select class="input-form select" v-model="currentBrigadeOptions.brigade.department_id">
              <option v-for="option in departmentList" :key="option.key" :value="option.id">
                {{ option.title }}
              </option>
            </select>
          </div>
        </div>
      </div>


      <div style="padding: 20px;">
        <p class="text-24 mb-2">Цвет бригады</p>
        <div class="w-100">
          <color-picker z-index="999" v-model:pure-color="currentBrigadeOptions.brigade.color" is-widget="true"
                        format="hex" picker-type="chrome"></color-picker>

        </div>
      </div>

      <div style="padding: 0 20px">
        <p class="text-24 mb-2">Статус</p>
        <select class="input-form select" v-model="currentBrigadeOptions.brigade.active">
          <option :value="false">Неактивный</option>
          <option :value="true">Активный</option>
        </select>
      </div>
      <div class="p-20">
        <button class="btn btn-green" @click.prevent="updateCurrentBrigade">Сохранить</button>
      </div>
    </div>
    <div>
      <custom-modal v-if="isInstallersListModal" @close="isInstallersListModal = !isInstallersListModal">
        <installers-list-modal @closeModal="closeModal" @updateBrigadeData="updateBrigadeData"></installers-list-modal>
      </custom-modal>
      <custom-modal v-if="isOwnerModal" @close="isOwnerModal = !isOwnerModal">
        <brigade-add-owner-modal @close="isOwnerModal = false"></brigade-add-owner-modal>
      </custom-modal>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CustomModal from '../../modules/CustomModal.vue';
import InstallersListModal from './InstallersListModal.vue';
import {ColorPicker} from "vue3-colorpicker";
import BrigadeAddOwnerModal from "@/components/modals/brigade/BrigadeAddOwnerModal.vue";

export default {
  name: 'BrigadeAddInstallersModal',
  components: {
    BrigadeAddOwnerModal,
    CustomModal,
    InstallersListModal,
    ColorPicker
  },
  created() {
    this.fetchDepartmentList();
  },
  data() {
    return {
      newInstallers: [],
      isInstallersListModal: false,
      color: '',
      status: false,
      isOwnerModal: false,
    }
  },
  computed: {
    ...mapGetters({
      installers: 'installers',
      currentBrigadeOptions: 'currentBrigadeOptions',
      departmentList: 'departmentList',
      brigadesOptions: 'brigadesOptions'
    })
  },
  methods: {
    ...mapActions({
      deleteBrigadesInstallers: 'deleteBrigadesInstallers',
      updateBrigade: 'updateBrigade',
      fetchBrigades: 'fetchBrigadesRelations',
      fetchDepartmentList: 'fetchDepartmentList'
    }),
    ...mapMutations({
      setisBrigadeAddInstallersModal: 'setisBrigadeAddInstallersModal',
    }),
    closeModal() {
      this.isInstallersListModal = false
    },
    async deleteCurrentBrigadeInstaller(id) {
      const payload = {
        relation_id: id
      }
      await this.deleteBrigadesInstallers(payload).then((res) => {
        if (res.success) {
          this.$toast.success('Cотрудник успешно удален')
        } else {
          this.$toast.error('Не удалось удалить сотрудника')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })

    },
    async updateCurrentBrigade() {
      const payload = {
        id: this.currentBrigadeOptions.brigade.id,
        color: this.currentBrigadeOptions.brigade.color,
        title: this.currentBrigadeOptions.brigade.title,
        bitrix_id: this.currentBrigadeOptions.brigade.bitrix_id,
        active: this.currentBrigadeOptions.brigade.active,
        department_id: this.currentBrigadeOptions.brigade.department_id
      }
      await this.updateBrigade(payload).then((res) => {
        if (res.success) {
          this.$toast.success('Успешно изменено!')
          this.setisBrigadeAddInstallersModal(false)
        } else {
          this.$toast.error('Не удалось отредактировать')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async updateBrigadeData() {
      if (!this.brigadesOptions || !this.currentBrigadeOptions?.brigade?.id) return
      const brigadeData = this.brigadesOptions.find(b => b.brigade.id === this.currentBrigadeOptions.brigade.id)
      if (brigadeData?.installers) {
        this.currentBrigadeOptions.installers = brigadeData.installers
      }
    }
  },
}
</script>

<style lang="scss">
@import "../../../../node_modules/vue3-colorpicker/style.css";

.brigade-installer {
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  align-items: center;
}

.vc-colorpicker.white {
  width: 100% !important;
}

</style>