<template>
  <BaseDropdown
    label="Отдел"
    :items="connectionDepartments"
    v-model:selectedValue="department"
    keyProp="key"
    titleProp="title"
    placeholder="Выберите отдел"
    @change="departmentFilter"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BaseDropdown from '@/components/ui/BaseDropdown.vue';

export default {
  name: "UniversalDepartmentsDropdown",
  components: {
    BaseDropdown
  },
  props: {
    currentValue: {
      type: String,
      default: null,
    },
    responsible_dep: {
      type: String,
      default: null,
    },
  },
  created() {
    this.fetchConnectionDepartments().then(() => {
      if (this.responsible_dep) {
        // Find department by ID if responsible_dep is numeric
        const department = this.connectionDepartments.find(d => 
          Number.isInteger(Number(this.responsible_dep)) 
            ? d.id === Number(this.responsible_dep)
            : d.key === this.responsible_dep
        );
        if (department) {
          this.department = department.key;
        }
      } else if (this.currentValue) {
        // Find by key or ID
        const department = this.connectionDepartments.find(d => 
          d.key === this.currentValue || d.id === Number(this.currentValue)
        );
        if (department) {
          this.department = department.key;
        }
      }
    })
  },
  data() {
    return {
      department: null
    }
  },
  computed: {
    ...mapGetters({
      connectionDepartments: 'connectionDepartments',
    })
  },
  methods: {
    ...mapActions({
      fetchConnectionDepartments: 'fetchConnectionDepartments',
    }),
    departmentFilter(option) {
      if (option) {
        this.$emit('departmentFilter', {
          key: option.key,
          id: option.id,
          title: option.title
        })
      } else {
        this.$emit('clearBtn', 'department')
      }
    }
  },
  watch: {
    responsible_dep: {
      immediate: true,
      handler(newValue) {
        if (newValue === null) {
          this.department = null;
        } else if (newValue && this.connectionDepartments.length > 0) {
          const department = this.connectionDepartments.find(d => 
            Number.isInteger(Number(newValue)) 
              ? d.id === Number(newValue)
              : d.key === newValue
          );
          if (department) {
            this.department = department.key;
          }
        }
      }
    },
    currentValue: {
      immediate: true,
      handler(newValue) {
        if (newValue === null) {
          this.department = null;
        } else if (!this.responsible_dep && newValue && this.connectionDepartments.length > 0) {
          const department = this.connectionDepartments.find(d => 
            d.key === newValue || d.id === Number(newValue)
          );
          if (department) {
            this.department = department.key;
          }
        }
      }
    },
    connectionDepartments: {
      immediate: true,
      handler(newValue) {
        if (this.responsible_dep && newValue.length > 0) {
          const department = newValue.find(d => 
            Number.isInteger(Number(this.responsible_dep)) 
              ? d.id === Number(this.responsible_dep)
              : d.key === this.responsible_dep
          );
          if (department) {
            this.department = department.key;
          }
        } else if (this.currentValue && newValue.length > 0) {
          const department = newValue.find(d => 
            d.key === this.currentValue || d.id === Number(this.currentValue)
          );
          if (department) {
            this.department = department.key;
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.clear-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 32px;
  bottom: 28px;
  z-index: 1
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
</style>