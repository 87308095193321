<template>
  <div class="p-20">
    <h1 class="text-24 text-center mb-4 p-20">Настройка таблицы</h1>
    <div v-for="column in newTableColumns" :key="column">
      <div :class="column.isChecked ? 'flex align-center justify-between bg-blue rounded mb-1 padding' : 'flex align-center bg-gray justify-between mb-1 rounded padding'">
        <p class="mb-1">{{ column.title }}</p>
        <input type="checkbox" v-model="column.isChecked">
      </div>

    </div>
    <div>
      <button class="btn mt-2" @click.prevent="getNewTableColumn">Cохранить</button>
      <button class="btn mt-2" @click="$emit('close')">Отменить</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "TableOptionsModal",
  props: {
    tableColumns: {
      type: Array,
      default: () => []
    },
  },
  created() {
    this.newTableColumns = this.tableColumns;
  },
  data() {
    return {
      newTableColumns: []
    }
  },
  methods: {
    getNewTableColumn() {
      this.$emit('getNewTableColumns', this.newTableColumns);
    },
  },
}
</script>


<style scoped lang="scss">
.bg-blue {
  background: #6bfdef;
}
.padding {
  padding: 6px;
}
</style>