<template>
  <div class="wrapper wrapper-content modified flex column bg">
    <div class="bg-white p-20">
      <div class="flex items-center justify-between gap-2">
        <div class="w-100">
          <label for="">Введите ФИО/телефон/ИИН/адрес</label>
          <input type="search" placeholder="Поиск по..." class="input-form w-100 mt-1"
                 style="height: 48px !important; border-radius: 25px !important;" v-model="search" min="3"
                 @change="searchForConnections">
        </div>

        <div class="w-100" @click="showDropdownCalendar = !showDropdownCalendar">
          <calendar-departure :show-dropdown-calendar="showDropdownCalendar" @setDate="setDate"></calendar-departure>
        </div>

        <div class="w-100 relative">
          <label for="">Участки</label>
          <button class="clear-btn cursor-pointer" @click="clearBtn('status')">
            <img v-if="area_id" src="/assets/close.svg" height="16" width="16"/></button>
          <select class="input-form select mt-1" v-model="area_id"
                  style="height: 48px !important; border-radius: 25px !important;" @change="changeArea">
            <option :value="null">Все</option>
            <option v-for="option in areas" :key="option.id" :value="option.id">
              {{ option.area_name }}
            </option>
          </select>
        </div>
        <div class="w-100 relative">
          <label for="">Статус</label>
          <button class="clear-btn cursor-pointer" @click="clearBtn('status')">
            <img v-if="status_id" src="/assets/close.svg" height="16" width="16"/></button>
          <select class="input-form select mt-1" v-model="status_id"
                  style="height: 48px !important; border-radius: 25px !important;" @change="changeStatus">
            <option :value="null">Все</option>
            <option v-for="option in departureStatuses" :key="option.key" :value="option.id">
              {{ option.title }}
            </option>
          </select>
        </div>
        <div class="w-100">
          <div class="choose-modal">
            <div class="">
              Ответственный
            </div>
            <div class="dropdown w-100">
              <input
                  type="search"
                  class="input-form select mt-1"
                  v-model="searchManager"
                  @click="showDropdown = !showDropdown"
                  @input="showDropdown = true"
                  placeholder="Выберите менеджера"
                  style="height: 48px !important; border-radius: 25px !important;"
              />
              <div class="dropdown-content" :class="{ show: showDropdown }">
                <div
                    v-for="option in filteredOptionsManagers"
                    :key="option"
                    @click="selectOption(option)"
                >
                  {{ option.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <universal-departments-dropdown @clearBtn="clearBtn"  @departmentFilter="departmentFilter" :current-value="connection_department"></universal-departments-dropdown>
      </div>

      <div class="flex align-center mt-1">
        <div class="w-30 mr-4">
          <div class="choose-modal">
            <div class="">
              Бригада
            </div>
            <div class="dropdown w-100">
              <input
                  type="search"
                  class="input-form select mt-1"
                  v-model="searchBrigade"
                  @click="showDropdownBrigade = !showDropdownBrigade"
                  @input="showDropdownBrigade = true"
                  placeholder="Выберите бригаду"
                  style="height: 48px !important; border-radius: 25px !important;"
              />
              <div class="dropdown-content" :class="{ show: showDropdownBrigade }">
                <div
                    v-for="option in filteredOptionsBrigades"
                    :key="option.id"
                    @click="selectOptionBrigade(option)"
                >
                  {{ option.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="ticket-menu flex justify-center align-center cursor-pointer mr-2 navs"
                  style="background: #2ab161;color: white;margin-top: 10px;" @click="saveFilters">Сохранить фильтры
          </button>
        </div>
        <div>
          <button class="ticket-menu flex justify-center align-center cursor-pointer mr-2 navs"
                  style="margin-top: 10px;" @click="resetFilters">Сбросить фильтры
          </button>
        </div>

      </div>
      <div class="flex mt-2 gap-2">
        <div class="relative flex justify-between input-form">
          <div>Закрытые за данный период</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.period.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('period', $event)"
                 @mouseleave="hideTooltip('period')"
            >
            <div style="color: #FFB020">{{ finishedCount.period }}</div>
          </div>
          <div v-if="isTooltipWithPeriod" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.period" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
        <div class="relative flex justify-between input-form">
          <div>Закрытые за сегодня</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.today.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('today', $event)"
                 @mouseleave="hideTooltip('today')"
            >
            <div style="color: #1ED3FF">{{ finishedCount.today }}</div>
          </div>
          <div v-if="isTooltipWithToday" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.today" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
        <div class="relative flex justify-between input-form">
          <div>Закрытые за вчера</div>
          <div class="flex gap-1">
            <img v-if="closedConnectionTickets?.yesterday.length"
                 src="/assets/info.svg" alt="" class="cursor-pointer"
                 @mouseover="showTooltip('yesterday', $event)"
                 @mouseleave="hideTooltip('yesterday')"
            >
            <div style="color: #9F53FF">{{ finishedCount.yesterday }}</div>
          </div>
          <div v-if="isTooltipWithYesterday" class="tooltip" :style="tooltipStyles">
            <div v-for="(department, index) in closedConnectionTickets?.yesterday" :key="index">
              <ClosedTicketsByDepartmentComponent :total-count="finishedCount.period" :department="department"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 flex justify-center rounded mt-2 p-20" style="background: #00bfff;font-weight: 500">
        <p class="">План подключений по задачам {{ showData }}, записей
          {{ connectionList?.meta?.total }}</p>
      </div>


      <div class="flex justify-between items-center mt-2">
        <div class="w-100 flex" style="align-items: end">
          <div
              class="ticket-menu flex justify-center align-center cursor-pointer mr-2 navs"
              v-for="menu in menuMainArray"
              :key="menu.key"
              :class="{'active': currentTab === menu.key}"
              @click="changeTab(menu)"
          >
            {{ menu.title }}
          </div>


          <div class="mt flex flex-wrap" style="margin-left:auto;justify-content: flex-end;align-items: end">
            <div>
              <button class="ticket-menu flex justify-center gap-1 align-center cursor-pointer mr-2 navs"
                      style="background: #2ab161;color: white;" @click="downloadExcel">
                <img src="/assets/download-minimalistic-svgrepo-com.svg" alt="">
                <div>Выгрузить</div>
              </button>
            </div>
            <div style="width: 200px;">
              <div class="mb-1">Показывать по</div>
              <AppSelect
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  :searchable="false"
                  @change="onPerPageChange"
              />
            </div>
          </div>
        </div>
      </div>


      <div class="content w-100 mt-4 flex  column">
        <div class="grid gridText" style="">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-id" style="padding:4px 10px">
            <p class="text-center">ID</p>
            <p class="text-center" style="color: #3a94af">bitrix_task_id</p>
          </div>
          <div class="grid-Name flex align-center justify-center">
            <p class="text-center">Наименование</p>
          </div>
          <div class="grid-Address flex align-center justify-center">
            <p class="text-center">Адрес</p>
          </div>
          <div class="grid-Creator flex align-center justify-center">
            <p class="text-center">Создал</p>
          </div>
          <div class="grid-Responsible flex align-center justify-center">
            <p class="text-center">Ответственный</p>
          </div>
          <div class="grid-CreatedData flex align-center justify-center">
            <p class="text-center">Дата создания</p>
          </div>
          <div class="grid-Brigade cursor-pointer flex align-center justify-center" @click="sortByBrigade">
            <p class="text-center">Бригада</p>
          </div>
          <div class="grid-Plan flex align-center justify-center">
            <p class="text-center">План дата подключения</p>
          </div>
          <div class="grid-EndDate flex align-center justify-center">
            <p class="text-center">Дата завершения</p>
          </div>
          <div class="grid-Options flex align-center justify-center">
            <p class="text-center">Управление</p>
          </div>
        </div>
        <div class="departure-table" v-if="sortedData">
          <div class="grid gridText rounded" :id="list.id" @click="onTableClick(list.id)"
               v-for="(list, index) in sortedData" :key="list.id"
               :style="{ background: list.brigade_color ? list.brigade_color : '' }">
            <div class="grid-num"> {{ index + 1 }}</div>
            <div class="grid-id flex justify-center items-center">
              <div style="padding: 2px 4px">
                <p @click="getConnectionInfo(list.id)" class="text-center mb-1" style="cursor: pointer">{{ list.ticket_number }}</p>
                <a :href="list.bitrix_task_link" class="text-center link" style="color: #3a94af;cursor: pointer"
                   target="_blank">{{ list.bitrix_task_id }}</a>
              </div>
            </div>
            <div class="grid-Name flex align-center" @click="openClientInfo(list)"><p class="">
              {{ list.connection_client.name }}</p></div>
            <div class="grid-Address flex align-center">
              <div>{{ list.address }}</div>
            </div>
            <div class="grid-Creator flex align-center justify-center">
              <div v-if="list.manager" class="cursor-pointer" @click="openManagerCard(list.manager.id)">
                {{ list.manager?.full_name ? list.manager.full_name : 'Не указано' }}
                {{ list.manager.department?.title ? `(${list.manager.department.title})` : '(Отдел не указан)' }}
              </div>
              <div v-else>
                Не указано
              </div>
            </div>
            <div
                :class="list.status?.id === 17 ? 'grid-Responsible grid-closed gap-1 flex justify-between align-center' : 'grid-Responsible cursor-pointer gap-1 flex justify-between align-center'">
              <div v-if="list.responsible.title" @click="openManagerCard(list.responsible.id)">
                <div :class="list.status?.id === 17 ? '' : 'grid-blue'">
                  <div>{{ list.responsible?.title }}</div>
                  <div v-if="list.responsible.department">({{ list.responsible?.department?.title }})</div>
                </div>
              </div>
              <div v-else :class="list.status?.id === 17 ? '' : 'grid-blue'">
                Не указано
              </div>
              <div class="cursor-pointer" v-if="list.status?.id !== 17" @click="openChangeResponsible(list)">
                <img src="/assets/edit-button-blue.svg"/>
              </div>
            </div>
            <div class="grid-Brigade flex align-center">
              <span class="mr-1">{{
                  list.brigade_title ? list.brigade_title : 'Не указано'
                }}</span>
              <!--              <img src="/assets/edit-button-blue.svg"/>-->
            </div>
            <div class="grid-CreatedData flex align-center">{{ list.created_at }}</div>

            <div class="grid-Plan flex align-center" @click="openChangeDate(list.id)">
              <span class="mr-1" style="color: #2A5298">{{ list.planned_date }}</span>
              <img src="/assets/edit-button-blue.svg"/>
            </div>
            <div class="grid-EndDate flex align-center text-center">{{ list.finished_at || "Не указано" }}</div>
            <div class="brigade-options relative" style="padding: 0 2px;">
              <img src="/assets/logs-icon.svg" alt="" width="22" height="22" title="История" @click="openLogs(list.id)">
              <img src="/assets/list.svg" alt="edit" width="20" height="20" title="Информация"
                   @click="getConnectionInfo(list.id)">
              <img src="/assets/locationpin.svg" alt="location" width="24" height="24" title="Смена сооружения"
                   @click="openOrk(list.id)"/>
              <img src="/assets/comment.svg" alt="comment" width="24" height="24" title="Комментарии"
                   @click="openComments(list.id)"/>
              <img src="/assets/plus-gray.svg" alt="create" width="24" height="24" title="Создание связанной задачи"
                   @click="openToggle(list.id, list)"/>
              <ToggleDropdown class="toggle-dropdown" v-if="selectedId === list.id"
                              @close="selectedId = null"
                              :rowItem="list"
                              @create-lcs="(data) => openCreateLCSTicket(data)"
                              @create-access="(data) => openCreateAccessTicket(data)"/>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent :total-pages="totalPages" :current-page="page"
                           @page-change="onPageChange"></PaginationComponent>
    </div>
  </div>
  <custom-modal v-if="isManagerInfoModal" @close="isManagerInfoModal = !isManagerInfoModal">
    <manager-info-modal></manager-info-modal>
  </custom-modal>
  <custom-modal v-if="isPlanDate" @close="isPlanDate = !isPlanDate">
    <departure-connection-plan-date @closeDate="isPlanDate = false" :id="currentId" :type="'connection'" :params="params"></departure-connection-plan-date>
  </custom-modal>
  <custom-modal v-if="isBrigadeModal" @close="isBrigadeModal = !isBrigadeModal">
    <departure-brigade-modal :brigade-id="currentBrigadeId" :ticket_id="currentId"
                             @close="isBrigadeModal = !isBrigadeModal"></departure-brigade-modal>
  </custom-modal>
  <custom-modal v-if="isConnectionInfoModal" @close="isConnectionInfoModal = !isConnectionInfoModal">
    <departure-connection-info-modal :id="currentId"></departure-connection-info-modal>
  </custom-modal>
  <custom-modal v-if="isChangeResponsibleModal" @close="isChangeResponsibleModal = !isChangeResponsibleModal">
    <departure-change-responsible :id="currentId"
                                  @close="isChangeResponsibleModal = !isChangeResponsibleModal" :params="params" :type="'connection'"></departure-change-responsible>
  </custom-modal>
  <custom-modal v-if="isOrkModal" @close="isOrkModal = !isOrkModal">
    <departure-opk-modal
        :coordinates="{latitude: parseFloat(connectionDepartureTicket.connection_ticket.address.latitude), longitude: parseFloat(connectionDepartureTicket.connection_ticket.address.longitude), ork_id: connectionDepartureTicket.connection_ticket.ork_id}"
        :ticket-id="connectionDepartureTicket.connection_ticket.id" @close="isOrkModal = !isOrkModal"
        @success="orkChanged" :type="'b2c'"></departure-opk-modal>
  </custom-modal>
  <custom-modal custom-class="departure-comment" v-if="isCommentsModal" @close="isCommentsModal = !isCommentsModal">
    <comments-tab :comments="ticketComments"
                  :totalComments="totalComments" @addComment="addComment" @loadComments="loadComments"></comments-tab>
  </custom-modal>
  <custom-modal v-if="isDepartureLogsModal" @close="isDepartureLogsModal = !isDepartureLogsModal">
    <departure-logs-modal :id="currentId" :type="'connection'" :ticket="connectionDepartureTicket?.logs"></departure-logs-modal>
  </custom-modal>
  <custom-modal v-if="isShowCreateLCSTicket" @close="isShowCreateLCSTicket = !isShowCreateLCSTicket">
    <CreateTicketLCSFormView :parent-ticket-id="+selectedId" :ticket="selectedTicket" :type="'connection'"></CreateTicketLCSFormView>
  </custom-modal>
  <custom-modal v-if="isShowCreateAccessTicket" @close="isShowCreateAccessTicket = !isShowCreateAccessTicket">
    <access-form-view :parent-ticket-id="+selectedId" :ticket="selectedTicket" :type="'connection'"></access-form-view>
  </custom-modal>
  <custom-modal v-if="isShowCreateSubtask" @close="isShowCreateSubtask = !isShowCreateSubtask">
    <create-subtask-modal @close="isShowCreateSubtask = !isShowCreateSubtask"></create-subtask-modal>
  </custom-modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AppSelect from "@/components/ui/AppSelect.vue";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import ManagerInfoModal from "@/components/modals/ManagerInfoModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import DepartureConnectionPlanDate from "@/components/modals/departureB2c/DepartureConnectionPlanDate.vue";
import DepartureBrigadeModal from "@/components/modals/departureB2c/DepartureBrigadeModal.vue";
import DepartureConnectionInfoModal from "@/components/modals/departureB2c/DepartureConnectionInfoModal.vue";
import DepartureChangeResponsible from "@/components/modals/departureB2c/DepartureChangeResponsible.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import DepartureLogsModal from "@/components/modals/departureB2c/DepartureLogsModal.vue";
import CalendarDeparture from "@/components/calendar/calendar-departure.vue";
import DepartureOpkModal from "@/components/modals/departureB2c/DepartureOpkModal.vue";
import ClosedTicketsByDepartmentComponent from "@/components/cards/departure/ClosedTicketsByDepartmentComponent.vue";
import CreateTicketLCSFormView from "@/views/ticketsLCS/CreateTicketLCSFormView.vue";
import AccessFormView from "@/views/accessData/AccessFormView.vue";
import CreateSubtaskModal from "@/components/modals/subtasks/CreateSubtaskModal.vue";
import ToggleDropdown from "@/components/ui/ToggleDropdown.vue";
import UniversalDepartmentsDropdown from "@/components/ui/UniversalDepartmentsDropdown.vue";

export default {
  name: "DepartureView",
  components: {
    UniversalDepartmentsDropdown,
    ToggleDropdown,
    CreateSubtaskModal,
    AccessFormView,
    CreateTicketLCSFormView,
    ClosedTicketsByDepartmentComponent,
    DepartureOpkModal,
    CalendarDeparture,
    DepartureLogsModal,
    CommentsTab,
    DepartureChangeResponsible,
    DepartureConnectionInfoModal,
    DepartureBrigadeModal,
    DepartureConnectionPlanDate,
    CustomModal, ManagerInfoModal, PaginationComponent, AppSelect
  },
  async mounted() {
    this.setLoading(true)
    let promises = [await this.fetchConnectionStatuses(), await this.fetchFinishedCount(), await this.fetchManagers(),
      await this.fetchDepartureManagers(), await this.fetchConnectionDepartments(), await this.fetchBrigadeByDepartment(),
      await this.fetchDepartureStatuses(), await this.getAreas({per_page: 1000}),]
    await Promise.all(promises).then(results => {
      this.initializeMenu()
      this.setFirstFilterValues()
      this.setLoading(false)
    })
  },
  data() {
    return {
      perPage: 100,
      page: 1,
      currentPage: 1,
      search: '',
      perPageOptions: [10, 25, 50, 100, 500],
      date_from: null,
      date_to: null,
      currentTab: 'all',
      isManagerInfoModal: false,
      isPlanDate: false,
      currentId: null,
      menuMainArray: [],
      responsible_id: null,
      brigade_id: null,
      connection_department: null,
      isDepartureLogsModal: false,
      showDropdown: false,
      showDropdownBrigade: false,
      filteredOptionsManagers: this.departureManagers,
      filteredOptionsBrigades: this.brigades,
      searchManager: '',
      searchBrigade: '',
      status_id: null,
      params: {
        tickets_per_page: this.perPage ? this.perPage : 100,
        page: this.page ? this.page : 1,
        responsible: {
          type: null,
          id: null,
        },
        search: null,
        status_id: null,
        filter_date: {
          column: null,
          date_from: null,
          date_to: null,
        },
        responsible_dep: null,
        use_saved_filters: 1,
        brigade_id: null,
        connection_ticket_type: 'transposition',
        client_type: 'b2c',
        area_id: null
      },
      isBrigadeModal: false,
      currentBrigadeId: 0,
      isConnectionInfoModal: false,
      isChangeResponsibleModal: false,
      isOrkModal: false,
      isCommentsModal: false,
      showDropdownCalendar: false,
      isBrigadeSort: false,
      tooltipStyles: {
        top: '0px',
        left: '0px'
      },
      isTooltipWithPeriod: false,
      isTooltipWithToday: false,
      isTooltipWithYesterday: false,
      selectedId: null,
      isShowCreateLCSTicket: false,
      isShowCreateAccessTicket: false,
      isShowCreateSubtask: false,
      selectedTicket: {},
      client_type: 'b2c',
      clientTypes: [
        {id:1, title: 'b2b'},
        {id:2, title: 'b2c'},
      ],
      area_id: null
    }
  },
  computed: {
    ...mapGetters({
      connectionList: 'connectionList',
      connectionStatuses: 'connectionStatuses',
      finishedCount: 'finishedCount',
      user: 'user',
      managers: 'managers',
      connectionDepartureTicket: 'connectionDepartureTicket',
      currentCommentPage: 'currentConnectionTicketCommentsPage',
      ticketComments: 'connectionTicketComments',
      totalComments: 'totalConnectionTicketComments',
      connectionDepartments: 'connectionDepartments',
      departureFilters: 'departureFilters',
      departureManagers: 'departureManagers',
      brigades: 'brigadeByDepartment',
      departureStatuses: 'departureStatuses',
      departureRoles: 'departureRoles',
      connectionInfo: 'connectionInfo',
      closedConnectionTickets: 'closedConnectionTickets',
      areas: 'areas',
    }),
    totalPages() {
      return Math.ceil((this.connectionList?.meta?.total || 0) / this.perPage);
    },
    sortedData() {
      if (this.isBrigadeSort) {
        return [...this.connectionList.data].sort((a, b) => {
          const titleA = a.brigade_title || '';
          const titleB = b.brigade_title || '';
          return titleA.localeCompare(titleB);
        });
      } else {
        return this.connectionList.data;
      }
    },
    showData() {
      if (this.date_from && this.date_to) {
        return `${this.date_from} - ${this.date_to}`;
      } else {
        return 'за Все время'
      }
    }
  },
  watch: {
    params: {
      async handler(newValue, oldValue) {
        await this.watcherFunction()
      },
      deep: true
    },
    searchManager(value) {
      this.filteredOptionsManagers = this.departureManagers.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.responsible_id = null
        this.params.responsible.type = null
        this.params.responsible.id = null
      }
    },
    departureManagers(newOptions) {
      this.filteredOptionsManagers = newOptions;
    },
    searchBrigade(value) {
      this.filteredOptionsBrigades = this.brigades.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
      if (!value) {
        this.brigade_id = null
        this.params.brigade_id = null
      }
    },
    brigades(newOptions) {
      this.filteredOptionsBrigades = newOptions
    }
  },
  methods: {
    ...mapActions({
      fetchConnectionList: "fetchConnectionList",
      fetchManagerPhones: 'fetchManagerPhoneNumbers',
      fetchConnectionStatuses: 'fetchConnectionStatuses',
      fetchFinishedCount: 'fetchFinishedCount',
      fetchClosedConnectionTickets: 'fetchClosedConnectionTickets',
      fetchProductServices: 'fetchProductServices',
      changeResponsible: 'changeResponsibleDeparture',
      fetchManagers: 'fetchManagers',
      fetchTicket: 'fetchConnectionDepartureTicket',
      addTicketComments: 'addConnectionTicketComments',
      fetchTicketComments: 'fetchConnectionTicketComments',
      fetchConnectionTicketDetail: 'fetchConnectionTicketDetail',
      fetchExcelList: 'fetchExcelList',
      fetchConnectionDepartments: 'fetchConnectionDepartments',
      saveRequestFilter: 'saveRequestFilter',
      deleteRequestFilter: 'deleteRequestFilter',
      fetchDepartureManagers: 'fetchDepartureManagers',
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      fetchDepartureStatuses: 'fetchDepartureStatuses',
      fetchDepartureRoles: 'fetchDepartureRoles',
      getRelatedTickets: 'getRelatedTickets',
      getAreas: 'getAreas',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
      setCurrentCommentPage: 'setCurrentCommentPage',
      setIsDepartureClientInfoModal: 'setIsDepartureClientInfoModal',
      setCurrentClientInfo: 'setCurrentClientInfo',
    }),
    async initializeMenu() {
      await this.fetchDepartureRoles({is_head: 1}).then(()=>{
        if (this.user) {
          this.menuMainArray = [
            {
              key: 'all',
              title: 'Все заявки'
            },
            {
              key: 'my',
              title: 'Мои заявки',
              value: this.user.id,
              type: 'manager'
            },
            {
              key: 'department',
              title: 'Мой департамент',
              type: 'department'
            },
            {
              key: 'open',
              title: 'Открытые заявки'
            },
            {
              key: 'closed',
              title: 'Закрытые заявки'
            },
          ]

          let roleNotFound = true;

          this.departureRoles.forEach((el) => {
            if (el.id === this.user.role_id) {
              roleNotFound = false;
            }
          });

          if (roleNotFound) {
            const keysToRemove = ['my', 'department'];
            this.menuMainArray = this.menuMainArray.filter(item => !keysToRemove.includes(item.key));
          }
        }
      })
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.params.tickets_per_page = perPage
    },
    onPageChange(page = 1) {
      this.page = page
      this.params.page = page
    },
    changeTab(item) {
      this.currentTab = item.key
      if (item.key === 'all') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'open') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = 1
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'closed') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = 0
        this.params.my_department = null
        this.params.filter_date.column = 'finished_at'
        this.params.page = 1
      } else if (item.key === 'partners') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = 1
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'department') {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = 1
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else if (item.key === 'my'){
        this.params.responsible.type = "manager"
        this.params.responsible.id = item.value
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
        this.params.is_partner = null
        this.params.open_tickets = null
        this.params.my_department = null
        this.params.filter_date.column = 'created_at'
        this.params.page = 1
      }
    },
    async openManagerCard(id) {
      this.setLoading(true)
      await this.fetchManagerPhones(id).then(() => {
        this.isManagerInfoModal = true
        this.setLoading(false)
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    openChangeDate(id) {
      this.currentId = id
      this.isPlanDate = true
    },
    searchForConnections() {
      if (this.search === "") {
        this.search = null
      }
      this.params.search = this.search
      this.params.page = 1
      this.page = 1
    },
    openBrigadeModal(ticket) {
      this.currentBrigadeId = ticket.brigade_id
      this.currentId = ticket.id
      this.isBrigadeModal = true
    },
    getConnectionInfo(id) {
      this.currentId = id
      this.setLoading(true)
      let promises = [this.fetchConnectionTicketDetail(id), this.fetchProductServices({id: id}), this.getRelatedTickets(id)]
      Promise.all(promises).then(() => {
        this.$router.push(`/upsells/${id}`);
        this.setLoading(false)
      })
    },
    openChangeResponsible(list) {
      if (list.status.id !== 17) {
        this.isChangeResponsibleModal = true
        this.currentId = list.id
      } else return
    },
    responsibleFilter() {
      if (this.responsible_id) {
        this.params.responsible.type = 'manager'
        this.params.responsible.id = this.responsible_id
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
      }

    },
    departmentFilter(value) {
      this.connection_department = value
      this.params.responsible_dep = value
      this.params.page = 1
      this.page = 1
    },
    async openOrk(id) {
      this.setLoading(true)
      await this.fetchTicket({id: id})
      this.setLoading(false)
      this.isOrkModal = true
    },
    async orkChanged() {
      this.isOrkModal = false
      this.setLoading(true)
      await this.fetchTicket({id: this.connectionDepartureTicket.connection_ticket.id})
      this.setLoading(false)
    },
    async openComments(id) {
      this.setLoading(true)
      await this.fetchTicket({id: id})
      await this.fetchConnectionTicketDetail(id).then(() => {
        this.setLoading(false)
        this.isCommentsModal = true
      })
    },
    async openToggle(id, ticket) {
      this.selectedId = this.selectedId === id ? null : id
      this.selectedTicket = ticket
    },
    openCreateLCSTicket() {
      this.isShowCreateLCSTicket = true
    },
    openCreateAccessTicket() {
      this.isShowCreateAccessTicket = true
    },
    openCreateSubtask() {
      this.isShowCreateSubtask = true
    },
    addComment(comment) {
      console.log(comment)
      comment.connection_ticket_id = this.connectionDepartureTicket.connection_ticket.id
      this.addTicketComments(comment)
    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({
        connection_ticket_id: this.connectionDepartureTicket.connection_ticket.id,
        page: this.currentCommentPage
      })
    },
    openClientInfo(list) {
      this.setCurrentClientInfo(list.connection_client)
      this.setIsDepartureClientInfoModal(true)
    },
    async openLogs(id) {
      this.currentId = id
      this.isDepartureLogsModal = true
    },
    async downloadExcel() {
      if (!this.params.search) {
        delete this.params.search
      }
      this.setLoading(true)

      const res = await this.fetchExcelList({...this.params, download: 1})
      const url = window.URL.createObjectURL(res);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `отчеты.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      this.setLoading(false)
    },
    async watcherFunction() {
      this.setLoading(true)
      await this.fetchConnectionList(this.params).then(() => {
        this.fetchFinishedCount({
          filter_date: this.params.filter_date,
          connection_ticket_type: 'dis-assembly',
          client_type: this.params.client_type,
          area_id: this.params.area_id
        })
        this.fetchClosedConnectionTickets({
          filter_date: this.params.filter_date,
          connection_ticket_type: 'dis-assembly',
          client_type: this.params.client_type,
          area_id: this.params.area_id
        })
      })
      this.setLoading(false)
    },
    async saveFilters() {
      const baseUrl = process.env.VUE_APP_API_URL
      await this.saveRequestFilter({
        ...this.params,
        url: baseUrl + 'connection-tickets/filter-connection-ticket-list',
        request_method: 'GET'
      }).then((res) => {
        if (res.success) {
          this.$toast.success('Фильтры успешно cохранены')
        } else {
          this.$toast.error('Не удалось сохранить фильтры')
        }
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    selectOption(option) {
      this.responsible_id = option.id
      this.searchManager = option.title;
      this.showDropdown = false;
      if (this.responsible_id) {
        this.params.responsible.type = 'manager'
        this.params.responsible.id = this.responsible_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.responsible.type = null
        this.params.responsible.id = null
      }
    },
    selectOptionBrigade(option) {
      this.brigade_id = option.id
      this.searchBrigade = option.title;
      this.showDropdownBrigade = false;
      if (this.brigade_id) {
        this.params.brigade_id = this.brigade_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.brigade_id = null
      }
    },
    changeStatus() {
      if (this.status_id) {
        this.params.status_id = this.status_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.status_id = null
      }
    },
    changeArea() {
      if (this.area_id) {
        this.params.area_id = this.area_id
        this.params.page = 1
        this.page = 1
      } else {
        this.params.area_id = null
      }
    },
    async resetFilters() {
      const baseUrl = process.env.VUE_APP_API_URL
      await this.deleteRequestFilter({
        ...this.params,
        url: baseUrl + 'connection-tickets/filter-connection-ticket-list',
        request_method: 'GET'
      }).then((res) => {
        if (res.success) {
          this.$toast.success('Фильтры успешно сброшены')
          this.setLoading(true)
          this.page = 1
          this.perPage = 100
          this.responsible_id = null
          this.status_id = null
          this.search = ''
          this.searchManager = ''
          this.connection_department = null
          this.currentTab = 'all'
          this.fetchConnectionList({page: 1, tickets_per_page: 100})
          location.reload()
          this.setLoading(false)
        } else {
          this.$toast.error('Не удалось сбросить фильтры')
        }
      }).catch((err) => {
        this.$toast.error(`${err}`)
      })
    },
    setFirstFilterValues() {
      if (this.departureFilters) {
        //department
        if (this.departureFilters.responsible_dep) {
          this.connection_department = this.departureFilters.responsible_dep
          this.params.responsible_dep = this.connection_department
        }
        //status
        if (this.departureFilters.status_id) {
          this.status_id = this.departureFilters.status_id
          this.params.status_id = this.departureFilters.status_id
        }
        //search
        if (this.departureFilters.search) {
          this.search = this.departureFilters.search
          this.params.search = this.departureFilters.search
        }

        if (this.departureFilters.responsible) {
          //manager
          if (this.departureFilters.responsible.type === 'manager') {
            this.responsible_id = this.departureFilters.responsible.id ? this.departureFilters.responsible.id : null
            this.searchManager = this.managers.filter(el => el.id === this.responsible_id)[0].full_name

            this.params.responsible = {
              type: this.departureFilters.responsible.type,
              id: this.departureFilters.responsible.id,
            }
          }
        }
        if (this.departureFilters.is_partner === 1) {
          this.currentTab = 'partners'
          this.params.is_partner = 1
        }

        //brigade
        if (this.departureFilters.brigade_id) {
          this.brigade_id = this.departureFilters.brigade_id
          this.searchBrigade = this.brigades.filter(el => el.id === this.brigade_id)[0]?.title
          this.params.brigade_id = this.departureFilters.brigade_id
        }

        //page
        if (this.departureFilters.page) {
          this.page = +this.departureFilters.page
          this.params.page = +this.departureFilters.page
        }
        //per page
        if (this.departureFilters.tickets_per_page) {
          this.perPage = this.departureFilters.tickets_per_page
          this.params.tickets_per_page = this.departureFilters.tickets_per_page
        }


        //date
        if (this.departureFilters.filter_date) {
          if (this.departureFilters.filter_date.date_from && this.departureFilters.filter_date.date_to) {
            this.date_from = this.departureFilters.filter_date.date_from
            this.date_to = this.departureFilters.filter_date.date_to
            this.params.filter_date = {
              column: this.departureFilters?.filter_date?.column,
              date_from: this.departureFilters?.filter_date?.date_from,
              date_to: this.departureFilters?.filter_date?.date_to,
            }
          }
        }

      }
    },
    setDate(data) {
      if (data) {
        this.date_from = data?.[0]
        this.date_to = data?.[1]
        if(this.currentTab === 'closed') {
          this.params.filter_date.column = 'finished_at';
        } else {
          this.params.filter_date.column = 'created_at';
        }
        this.params.filter_date.date_from = this.date_from
        this.params.filter_date.date_to = this.date_to
        this.showDropdownCalendar = false
      } else {
        this.date_from = null
        this.date_to = null
        delete this.params.filter_date.date_from
        delete this.params.filter_date.date_to
        delete this.params.filter_date.column
      }
    },
    sortByBrigade() {
      this.isBrigadeSort = !this.isBrigadeSort
    },
    onTableClick(id) {
      const previouslySelected = document.querySelector('.selected');
      if (previouslySelected) {
        previouslySelected.classList.remove('selected');
      }
      const el = document.getElementById(id);
      el.classList.add('selected');
    },
    clearBtn(value) {
      if (value === 'status') {
        this.status_id = null
        this.params.status_id = null
      } else if (value === 'department') {
        this.connection_department = null
        this.params.responsible_dep = null
      }
    },
    showTooltip(key, event) {
      const tooltipHeight = 40; // высота тултипа
      const scrollContainer = event.currentTarget;
      const boundingRect = scrollContainer.getBoundingClientRect();

      this.tooltipStyles = {
        top: `${boundingRect.top - tooltipHeight + 100}px`,
        left: `${boundingRect.left + boundingRect.width / 2}px`,
      };

      if (key === 'period') {
        this.isTooltipWithPeriod = true;
      } else if (key === 'today') {
        this.isTooltipWithToday = true;
      } else if (key === 'yesterday') {
        this.isTooltipWithYesterday = true;
      }
    },
    hideTooltip(key) {
      if (key === 'period') {
        this.isTooltipWithPeriod = false;
      } else if (key === 'today') {
        this.isTooltipWithToday = false;
      } else if (key === 'yesterday') {
        this.isTooltipWithYesterday = false;
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';

.selected {
  border: 4px solid black;
}

.grid {
  display: grid;
  gap: 1px;
  align-items: center;
  padding: 1px 0;
  grid-template-columns: minmax(0, 40px) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 3fr) repeat(3, minmax(0, 2.2fr)) repeat(3, minmax(0, 1.5fr)) minmax(0, 1.2fr);
  grid-template-areas: 'Num Id Name Address Creator Responsible Brigade CreatedData  Plan EndDate Options';
}

.grid-num {
  border: 1px solid black;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-area: Num;
}

.grid-id {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  align-items: center;
  grid-area: Id;
}

.grid-Name {
  border: 1px solid black;
  border-radius: 10px;
  grid-area: Name;
  padding: 4px 10px;
  height: 100%;
}

.grid-Address {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Address;
}

.grid-Creator {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Creator;

}

.grid-Responsible {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Responsible;
}

.grid-CreatedData {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 4px;
  grid-area: CreatedData;
}

.grid-Brigade {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 4px 10px;
  grid-area: Brigade;
}

.grid-Plan {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 2px;
  grid-area: Plan;
}

.grid-EndDate {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  padding: 0 2px;
  grid-area: EndDate;
}

.grid-Options {
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;
  grid-area: Options;
}

.brigade-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  height: 100%;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.departure-table {
  :hover {
    background-color: #F5F5FA !important;
  }

  .grid {
    &-Creator:hover {
      text-decoration: underline;
      transition: 200ms;
      font-weight: bold;
    }

    &-Plan {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    //&-Brigade:hover {
    //  cursor: pointer;
    //  font-weight: bold;
    //  transition: 200ms;
    //  text-decoration: underline;
    //}

    &-Responsible {
      &:hover {
        cursor: pointer;
        font-weight: bold;
        transition: 200ms;
        text-decoration: underline;
      }
    }

    &-Name {
      &:hover {
        cursor: pointer;
        transition: 200ms;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    &-id {
      a:hover {
        transition: 200ms;
        font-weight: bold;
      }
    }

    &-closed {
      &:hover {
        cursor: default;
        font-weight: normal;
        text-decoration: none;
      }
    }

    &-blue {
      color: #2A5298
    }
  }
}

.ticket-menu {
  padding: 9px 13px 11px 12px;
  border-radius: 12px;
  background: #FFF;
  color: #888B94;
  border: 1px #F2F1F0 solid;
  font-size: 16px;
  font-style: normal;
  width: 166px;
  font-weight: 600;
  height: 40px;


  &.active {
    color: $white;
    background: linear-gradient(269deg, #04567B 1.72%, #0095FF 100%);
  }
}

.navs:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: 200ms;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.clear-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 40px;
  bottom: 12px;
  z-index: 1
}

.tooltip {
  position: fixed;
  transform: translateX(-80%);
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: white;
  box-shadow: 0 4px 30px 0 #0000001A;
  color: black;
  border-radius: 4px;
  font-size: 12px;
  z-index: 10;
  width: 300px;
  white-space: wrap;
}

.toggle-dropdown {
  position: absolute;
  right: 100px;
  top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:hover {
    transform: none;
  }
}

@media only screen and (max-width: 1660px) {
  .gridText {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  .gridText {
    font-size: 8px;
  }
}
</style>