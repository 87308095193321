<template>
  <div class="p-20">
    <div class="choose-modal" >
      <div class="choose-modal__title w-100 mb-4">
        Выберите бригаду
      </div>
      <select class="input-form select" v-model="responsible_id">
        <option disabled value=""></option>
        <option
            v-for="option in departments"
            :key="option.key"
            :value="option.id"
        >
          {{ option.title }}
        </option>
      </select>
      <button type="button" class="btn mb-2 mt-4" @click="setBrigade">Назначить</button>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DepartureBrigadeModal",
  props: {
    brigadeId: {
      type: Number,
      default: 0
    },
    ticket_id: {
      type: Number,
      default: 0
    }
  },
  async created() {
   await this.fetchBrigadeByDepartment();
   this.responsible_id = this.brigadeId
  },
  data() {
    return {
      responsible_id: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      departments: 'brigadeByDepartment',
    }),
  },
  methods: {
    ...mapActions({
      fetchBrigadeByDepartment: 'fetchBrigadeByDepartment',
      changeBrigadeDeparture: 'changeBrigadeDeparture'
    }),
   async setBrigade(){
      if (this.responsible_id === 0) {
        return
      } else {
        await this.changeBrigadeDeparture({ticket_id: this.ticket_id, brigade_id: this.responsible_id}).then((res)=> {
          if (res.success){
            this.$toast.success('Бригада успешно изменена')
            this.$emit('close')
          } else {
            this.$toast.error('Не удалось изменить бригаду')
          }
        }).catch((err) => {
          this.$toast.error(`${err.message}`)
        });
      }
    }
  },
}
</script>


<style scoped lang="scss">

</style>