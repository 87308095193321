<template>
  <div class="modal-content">
    <h2 class="modal-title">ИЗМЕНИТЬ</h2>

    <div class="warning-message">
      <img src="/assets/warning-icon.svg" alt="warning" class="warning-icon"/>
      <span>Меняйте статус только после выполнения рекомендованных действий по записи</span>
    </div>

    <div class="status-section">
      <div class="status-field">
        <label>Статус оборудования</label>
        <input
            type="text"
            class="input-form disabled"
            :value="equipmentStatus"
            disabled
            readonly
        />
      </div>

      <div class="status-field">
        <label>Статус записи</label>
        <div class="dropdown w-100">
          <input
              type="text"
              class="input-form"
              v-model="search"
              @click="toggleDropdown"
              @input="showDropdown = true"
              placeholder="Выберите статус"
          />
          <img
              src="/assets/dropdown-toggle.svg"
              class="dropdown-toggle"
              :class="{ open: showDropdown }"
              alt="toggle"
          />
          <div class="dropdown-content" :class="{ show: showDropdown }">
            <div
                v-for="option in filteredOptions"
                :key="option.id"
                @click="selectOption(option)"
            >
              {{ option.title }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="[3,4,7,9].includes(currentTicket?.equipment_status?.id)">
        <div class="status-field mb-2">
          <label>Наименование оборудования</label>
          <input
              type="text"
              class="input-form"
              v-model="deviceName"
          />
        </div>

        <div class="status-field">
          <label>Серийный номер</label>
          <input
              type="text"
              class="input-form"
              v-model="serialNumber"
          />
        </div>
      </div>

    </div>

    <div class="modal-actions">
      <button class="btn-save" @click="setStatus">Сохранить</button>
      <button class="btn-cancel" @click="$emit('close')">Отмена</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ChangeStatusModal",
  props: {
    id: {
      type: Number,
      default: 0
    },
    equipmentStatus: {
      type: String,
      default: 'Установлено'
    },
    currentTicket: {
      type: Object,
      default: () => {
      }
    }
  },
  async created() {
    await this.getStatuses();
    if (Object.keys(this.currentTicket).length > 0 && [3,4,7,9].includes(this.currentTicket?.equipment_status?.id)) {
      this.serialNumber = this.currentTicket.serial_number
      this.deviceName = this.currentTicket.device_name
    }
  },
  data() {
    return {
      statusKey: '',
      search: '',
      showDropdown: false,
      filteredOptions: this.statuses,
      serialNumber: null,
      deviceName: null,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'statuses',
      loading: 'loading'
    })
  },
  watch: {
    search(value) {
      this.filteredOptions = this.statuses.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
    },
    statuses(newOptions) {
      this.filteredOptions = newOptions;
    }
  },
  methods: {
    ...mapActions({
      getStatuses: 'getStatuses',
      getVirtualWarehouses: 'getVirtualWarehouses',
      updateWarehousesOrStatus: 'updateWarehousesOrStatus',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.dropdown')
      if (dropdown && !dropdown.contains(event.target)) {
        this.showDropdown = false
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
      event.stopPropagation()
    },
    selectOption(option) {
      this.statusKey = option.key
      this.search = option.title;
      this.showDropdown = false;
    },
    async setStatus() {
      if (!this.statusKey) {
        this.$toast.error('Выберите статус')
        return
      }

      try {
        this.setLoading(true)
        const obj = {
          id: this.id,
          status_key: this.statusKey,
          serial_number: this.serialNumber,
          device_name: this.deviceName,
        }
        const res = await this.updateWarehousesOrStatus(obj)

        if (res.success) {
          await this.getVirtualWarehouses()
          this.$toast.success('Статус был успешно изменён')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось изменить статус')
        }
      } catch (err) {
        this.$toast.error(err.message || 'Произошла ошибка при обновлении статуса')
      } finally {
        this.setLoading(false)
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>


<style scoped lang="scss">
.modal-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}

.warning-message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;

  .warning-icon {
    width: 20px;
    height: 20px;
  }

  span {
    color: #888B94;
    font-size: 14px;
    line-height: 1.4;
  }
}

.status-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.status-field {
  label {
    display: block;
    color: #ADADAD;
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.input-form {
  width: 100%;
  height: 44px;
  background: #F5F5FA;
  border: none;
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  color: #333;

  &.disabled {
    background: #F5F5FA;
    color: #888B94;
  }
}

.dropdown {
  position: relative;

  .dropdown-toggle {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s;

    &.open {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.modal-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  align-items: center;

  button {
    width: 100%;
    max-width: 350px;
    height: 44px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-save {
    background: linear-gradient(268.85deg, #04567B 1.72%, #0095FF 100%);
    color: white;
    border: none;

    &:hover {
      opacity: 0.9;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #333;
    border: none;

    &:hover {
      background: #F5F5FA;
    }
  }
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  display: none;

  div {
    padding: 12px 16px;
    cursor: pointer;
    color: #333;

    &:hover {
      background: #F5F5FA;
    }
  }

  &.show {
    display: block;
  }
}
</style>