<template>
  <div class="ip-result flex column align-center">
    <div class="title uppercase text-black2">
      {{
        ipCheckResult.checked_ip
          ? ipCheckResult.checked_ip
          : ipCheckResult.client_ip
      }}
    </div>
    <div class="inner">
      <div
        class="card rounded bg-white "
        v-if="Object.keys(ipCheckResult).length === 4 || Object.keys(ipCheckResult).length === 2"
      >
        <pre>{{ ipMessage }}</pre>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.name"
      >
        <p class="text-black">Название:</p>
        <p class="text-black text-right">{{ ipCheckResult.name }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.client_gateway"
      >
        <p class="text-black">Шлюз:</p>
        <p class="text-black text-right">{{ ipCheckResult.client_gateway }}</p>
      </div>
      <div class="card rounded bg-white flex align-center justify-between" v-if="Object.keys(ipCheckResult).length > 4">
        <p class="text-black">Заблокировано:</p>
        <p class="text-black text-right">
          {{ ipCheckResult.block || ipCheckResult.blocked ? "Да" : "Нет" }}
        </p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.client_primary_dns"
      >
        <p class="text-black">Основной DNS:</p>
        <p class="text-black text-right">
          {{ ipCheckResult.client_primary_dns }}
        </p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.client_slave_dns"
      >
        <p class="text-black">Дочерний DNS:</p>
        <p class="text-black text-right">
          {{ ipCheckResult.client_slave_dns }}
        </p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.rate"
      >
        <p class="text-black">Скорость:</p>
        <p class="text-black text-right">{{ ipCheckResult.rate }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.name"
      >
        <p class="text-black">MAC адрес сессии:</p>
        <p class="text-black text-right">{{}}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.session_start_time"
      >
        <p class="text-black">Начало сессии:</p>
        <p class="text-black text-right">
          {{ ipCheckResult.session_start_time }}
        </p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.session_uptime"
      >
        <p class="text-black">Длительность сессии:</p>
        <p class="text-black text-right">{{ ipCheckResult.session_uptime }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.session_status"
      >
        <p class="text-black">Статус сессии:</p>
        <p class="text-black text-right">
          {{ ipCheckResult.session_status ? "Активен" : "Не активен" }}
        </p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.loss"
      >
        <p class="text-black">Потери на 1000 пакетов:</p>
        <p class="text-black text-right">{{ ipCheckResult.loss }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.intraffic"
      >
        <p class="text-black">Входящий трафик:</p>
        <p class="text-black text-right">{{ ipCheckResult.intraffic }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.outtraffic"
      >
        <p class="text-black">Исходящий трафик:</p>
        <p class="text-black text-right">{{ ipCheckResult.outtraffic }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.location"
      >
        <p class="text-black">Узел подключения:</p>
        <p class="text-black text-right">{{ ipCheckResult.location }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.pppoe_username"
      >
        <p class="text-black">Логин:</p>
        <p class="text-black text-right">{{ ipCheckResult.pppoe_username }}</p>
      </div>
      <div
        class="card rounded bg-white flex align-center justify-between"
        v-if="ipCheckResult.pppoe_password"
      >
        <p class="text-black">Пароль:</p>
        <p class="text-black text-right">{{ ipCheckResult.pppoe_password}}</p>
      </div>
      <template v-if="ipCheckResult.config">
        <template v-for="(config, index) in ipCheckResult.config" :key="index">
          <template v-for="item in Object.keys(config)" :key="item">
            <div
              class="card rounded bg-white flex align-center justify-between"
            >
              <p class="text-black">MAC адрес {{ item }}:</p>
              <p class="text-black text-right">
                {{ config[item].mac ? config[item].mac : "Отсутсвует" }}
              </p>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IpCheckResultModal",
  computed: {
    ...mapGetters({
      ipCheckResult: "ipCheckResult",
      ipMessage: "getIpMessage"
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../theme/variables";

.ip-result {
  width: 750px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    margin: 0 65px;
    width: 100%;
  }
}

.card {
  margin-bottom: 8px;
  position: relative;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
