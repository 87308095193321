<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import RangeDatePicker from "@/components/modules/RangeDatePicker.vue";

const store = useStore();
const filters = computed(() => store.getters.dashboardFilters);
const setFilters = async (filters) => await store.dispatch('setDashboardFilters', filters);

const date_range = ref(null);
const status = ref(null);

const STATUSES = [
  { value: "1", text: "Ожидают выполнения" },
  { value: "2", text: "Активные" },
  { value: "3", text: "Завершенные" },
];

async function apply() {
  await setFilters({
    date_from: date_range.value?.[0] ?? null,
    date_to: date_range.value?.[1] ?? null,
    status: status.value,
  })
}
async function clear() {
  date_range.value = null;
  status.value = null;

  await setFilters({
    date_from: null,
    date_to: null,
    status: null,
  })
}

onMounted(() => {
  date_range.value = filters.value.date_from || filters.value.date_to
    ? [
        store.getters.dashboardFilters.date_from,
        store.getters.dashboardFilters.date_to
      ]
    : null
  status.value = store.getters.dashboardFilters.status;
});
</script>

<template>
<div class="dash-filt">
  <h3 class="dash-filt__title">Фильтр вывода данных</h3>

  <div class="dash-filt__content">
    <range-date-picker
      :date-range="date_range"
      @set-date-range="new_date_range => date_range = new_date_range"
    />

    <div class="dash-filt__content__select-wrapper">
      <select v-model="status">
        <option :value="null" disabled v-text="'Статус'" />
        <option
          v-for="{ value, text } in STATUSES" :key="value"
          :value="value" v-text="text"
        />
      </select>

      <button v-if="status" @click="status = null">
        <img src="/assets/close.svg" />
      </button>

      <img v-if="!status" class="select-icon" src="/assets/select-arrow.svg" />
    </div>

    <div class="dash-filt__content__buttons">
      <button class="apply" @click="apply">
        <img src="/assets/check-mark-white.svg" />
        Применить
      </button>
      <button class="clear" @click="clear">
        <img src="/assets/close-white.svg" />
        Сбросить
      </button>
    </div>
  </div>
</div>
</template>

<style lang="scss">
.dash-filt {
  height: 95%;

  &__title {
    margin: 16px;
    font-size: 24px;
    font-weight: 400;
    color: #333;
  }
  &__content {
    width: 100%;
    height: 95%;
    margin-top: 48px;
    padding: 20px;
    background: #F5F5FA;
    border-radius: 15px;

    &__select-wrapper {
      margin-top: 20px;
      position: relative;

      select {
        width: 100%;
        height: 50px;
        padding: 0 16px;
        font-size: 18px;
        color: #333;
        border-radius: 25px;
        appearance: none;
        cursor: pointer;
      }

      button, .select-icon {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;

        position: absolute;
        top: calc(50% - 8px);
        right: 20px;

        img {
          width: 16px;
          height: 16px;
        }
      }
      .select-icon {
        width: 16px;
        height: 16px;
        pointer-events: none;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-top: 40px;

      button {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 12px 24px;

        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        color: #fff;

        border: none;
        border-radius: 25px;
        outline: none;
        cursor: pointer;

        &.apply {
          background: #04567B;
        }
        &.clear {
          background: #EC3751;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
