<template>
  <div class="wrapper modified flex column">
    <div class="bg-white rounded p-20">
      <div class="flex justify-between">
        <div>
          <button class="btn btn-green" @click="$router.push('/roles')">Назад</button>
        </div>
        <div>
          <button class="btn btn-create" @click="isAddPermissionModal = !isAddPermissionModal">
            <img src="/assets/plus-create.svg" alt=""/>Добавить разрешения
          </button>
        </div>
      </div>
      <div>
        <div class="p-20">
          <div class="flex justify-between">
            <div class="flex align-center gap-2">
              <p class="text-center mb-1">Роль</p>
              <input type="text" class="input-form mb-2" placeholder="Имя новой роли" v-model="currentRole.title">
              <div class="flex w-100">
                <input type="checkbox" :checked="currentRole.is_head" v-model="currentRole.is_head">
                <label>Is Head</label>
              </div>
            </div>
            <div class="flex align-center gap-2">
              <p class="text-center mb-1">Ключ</p>
              <input type="text" class="input-form mb-2" placeholder="Ключ новой роли" v-model="currentRole.key">
            </div>
          </div>

          <div v-if="currentRole.permissions?.length">
            <h1 class="text-center text-20 mb-1">Разрешения</h1>
            <div class="grid table-header" style="background-color: #F5F5FA;">
              <div class="grid-num">
                <p>№</p>
              </div>
              <div class="grid-name">
                <p>Роль</p>
              </div>
              <div class="grid-key">
                <p>Ключ</p>
              </div>
              <div class="grid-isCreate">
                <p>Создание</p>
              </div>
              <div class="grid-isEdit">
                <p>Редактирование</p>
              </div>
              <div class="grid-isShow">
                <p>Просмотр</p>
              </div>
              <div class="grid-isDelete">
                <p>Удаление</p>
              </div>
              <div class="grid-options">
                <p>Управление</p>
              </div>
            </div>
          </div>
          <div class="roles-table">
            <div class="grid" v-for="(role, index) in currentRole.permissions" :key="role">
              <div class="flex align-center"> {{ index + 1 }}</div>
              <div class="flex align-center">{{ role.section_title }}</div>
              <div class="flex align-center">
                <p>{{ role.section_key }}</p>
              </div>
              <div class="flex align-center"><input type="checkbox" :checked="role.is_create === 1" disabled></div>
              <div class="flex align-center"><input type="checkbox" :checked="role.is_edit === 1" disabled></div>
              <div class="flex align-center"><input type="checkbox" :checked="role.is_show === 1" disabled></div>
              <div class="flex align-center"><input type="checkbox" :checked="role.is_delete === 1" disabled></div>
              <div class="roles-options">
                <img src="/assets/TrashBr.svg" alt="delete" width="20" height="20"
                     @click.prevent="deleteCurrentPermission(role.id)" title="Удалить"/>
              </div>
            </div>
          </div>

          <div class="flex justify-center mt-4">
            <button class="btn form-btn text-center" @click.prevent="editNewRole">Cохранить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <custom-modal v-if="isAddPermissionModal" @close="isAddPermissionModal = !isAddPermissionModal">
    <add-new-permission-modal @close="createPermissions"></add-new-permission-modal>
  </custom-modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import AddNewPermissionModal from "@/components/modals/roles/AddNewPermissionModal.vue";

export default {
  name: 'RolesPermissionsEdit',
  components: {AddNewPermissionModal, CustomModal},
  data() {
    return {
      roleData: {
        title: '',
        key: '',
      },
      isAddPermissionModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentRole: 'currentRole',
    }),
  },
  methods: {
    ...mapActions({
      createRole: 'createRole',
      updateRole: 'updateRole',
      fetchAllSections: 'fetchAllSections',
      createPermission: 'createRolePermissions',
      fetchAllRoles: 'fetchAllRoles',
      deleteRolePermission: 'deleteRolePermission',
      fetchRolePermissions: 'fetchRolePermissions',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    async editNewRole() {
      const roleUpdateResult = await this.updateRole({
        id: this.currentRole.id, 
        title: this.currentRole.title, 
        key: this.currentRole.key, 
        is_head: this.currentRole.is_head,
      })

      if (!roleUpdateResult.success) {
        this.$toast.error('Не удалось изменить роль')
        return
      }

      // Refresh the role data to get updated permissions
      await this.fetchAllRoles()
      await this.fetchRolePermissions()

      this.$toast.success('Роль успешно изменена')
      this.$router.push('/roles')
    },
    async createPermissions(permission) {
      this.setLoading(true)
      this.isAddPermissionModal = false
      const obj = {
        role_permissions: [{
          role: this.currentRole.key,
          permissions: [permission]
        }]
      }
      await this.createPermission(obj).then((res) => {
        if (res.success) {
          this.fetchAllRoles().then(()=>{
            this.setLoading(false)
          })
          this.$toast.success('Разрешение успешно добавлено')
        } else {
          this.$toast.error('Не удалось изменить')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
    async deleteCurrentPermission(id) {
      await this.deleteRolePermission({id: id}).then((res) => {
        if (res.success) {
          const array = this.currentRole.permissions.filter(item => item.id !== id)
          this.currentRole.permissions = array
          this.fetchRolePermissions()
          this.$toast.success('Разрешение успешно удалено')
        } else {
          this.$toast.error('Не удалось изменить')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })
    },
  },
  created() {
    if (this.currentRole) {
      this.roleData = { ...this.currentRole };
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr  2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'Num Name  Key IsCreate IsEdit IsShow IsDelete Options';
}

.grid-num {
  grid-area: Num;
}

.grid-name {
  grid-area: Name;
}

.grid-key {
  grid-area: Key;
}

.grid-isCreate {
  grid-area: IsCreate;
}

.grid-isEdit {
  grid-area: IsEdit;
}

.grid-isShow {
  grid-area: IsShow;
}

.grid-isDelete {
  grid-area: IsDelete;
}

.grid-options {
  grid-area: Options;
}

.roles-table {
  :hover {
    background-color: #F5F5FA;
  }
}

.roles-options {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}
</style>