<template>
  <main class="p-20">
    <div class="flex">
      <button type="button" class="btn btn-create" @click="showQR">
        <img src="/assets/qr.svg" alt="" />
        Показать QR
      </button>
      <button class="open-filters-button" @click="openDashboardFiltersModal">
      <span class="open-filters-button__icon">
        <span
            class="open-filters-button__icon__badge"
            v-if="filtersCount"
            v-text="filtersCount"
        />
        <img src="/assets/settings.svg" alt="filters button icon" />
      </span>

        <span>Фильтр данных</span>
      </button>
    </div>


    <cards-grid
      v-if="dashboard.cnt_block || dashboard.time_block"
      :cnt-block="dashboard.cnt_block[0]"
      :time-block="dashboard.time_block[0]"
    />

    <monthly-diagram
      v-if="dashboard.month_block"
      :month-block="dashboard.month_block"
    />

    <div class="flex justify-between flex-wrap">
      <ticket-count-progress
        v-if="dashboard.service_cat_block"
        :service-cat-block="dashboard.service_cat_block"
      />
      <top-managers
        v-if="dashboard.top_managers"
        :top-managers="dashboard.top_managers"
      />
      <client-type-diagram
        v-if="dashboard.client_type_block"
        :client-type-block="dashboard.client_type_block"
      />
    </div>
    <custom-modal v-if="isQrModal" @close="isQrModal = !isQrModal">
      <SurfQrModal />
    </custom-modal>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardsGrid from "@/components/dashboard/CardsGrid.vue";
import TicketCountProgress from "@/components/dashboard/TicketsCountProgress.vue";
import TopManagers from "@/components/dashboard/TopManagers.vue";
import ClientTypeDiagram from "@/components/dashboard/ClientTypeDiagram.vue";
import MonthlyDiagram from "@/components/dashboard/monthlyDiagram.vue";
import SurfQrModal from "@/components/modals/SurfQrModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";

export default {
  name: 'HomeView',
  components: {
    SurfQrModal,
    MonthlyDiagram,
    ClientTypeDiagram,
    TopManagers,
    TicketCountProgress,
    CardsGrid,
    CustomModal
  },
  data() {
    return {
      isQrModal: false
    }
  },
  computed: {
    ...mapGetters({
      dashboard: 'dashboard',
      filters: 'dashboardFilters',
      user: 'user'
    }),
    filtersCount() {
      return Object.values(this.filters).filter(value => !!value).length;
    }
  },
  methods: {
    ...mapActions({
      fetchDashboardInfo: 'fetchDashboardInfo',
      openDashboardFiltersModal: 'openDashboardFiltersModal',
      getQRData: 'getQRData'
    }),
    async showQR() {
      await this.getQRData(this.user.iin)
      this.isQrModal = true
    }
  },
  async mounted() {
    await this.fetchDashboardInfo()
    console.log(this.user)
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

.open-filters-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  width: 202px;
  height: 48px;
  margin: 0 0 16px auto;

  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #FFF;

  background: #04567B;
  border-radius: 25px;
  border: none;
  outline: none;

  cursor: pointer;

  &:hover {
    opacity: .9;
  }
  &:active {
    opacity: .8;
  }

  span {
    margin-bottom: 5px;
    line-height: 1;
  }

  &__icon {
    margin-bottom: 0 !important;
    position: relative;

    &__badge {
      width: 14px;
      height: 14px;

      font-size: 10px;
      line-height: 1.3;
      color: #FFF;

      background: $red;
      border-radius: 50%;

      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.btn-create {
  width: 200px;
  margin-right: 16px;
  justify-content: center;
}
</style>
