<template>
  <div class="p-20">
    <div class="p-20">
      <h1 class="text-24 uppercase text-center mb-4">Добавить комментарий</h1>
      <div>
        <textarea class="input-form mb-1"  style="min-height: 112px" v-model="message" maxlength="100"/>
        <div class="text-gray flex justify-end mb-4">
          Символов {{message.length}}/100
        </div>
        <div class="flex gap-2">
          <button class="btn btn-secondary" @click.prevent="updateSituationComment">Сохранить</button>
          <button class="btn  text-gray" style="background: transparent; border: 1px solid gray" @click.prevent="$emit('shut')">Отменить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "CurrentSituationComment",
  props: {
    ticket: {
      type: Object,
      default: () => {
      }
    },
  },
  created() {
    if (this.ticket.message_current_situation
    ) {
      this.message = this.ticket.message_current_situation
    }
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
   ...mapActions({
     updateCommentCurrentSituation: 'updateCommentCurrentSituation'
   }),
   async updateSituationComment() {
     await this.updateCommentCurrentSituation({id: this.ticket.id, message: this.message}).then((res)=> {
       if (res.success) {
         this.$toast.success('Комментарий успешно добавлен')
         this.$emit('close')
       } else {
         this.$toast.error('Не удалось изменить комментарий')
       }
     }).catch((err)=> {
       this.$toast.error(`${err.message}`)
     })
    }
  },
}
</script>

<style scoped lang="scss">

</style>