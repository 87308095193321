<template>
  <div class="modal">
    <div class="modal-overlay" @click="close"></div>
    <div ref="modal_side" class="modal-side" :style="modalStyle">
      <img class="modal-side-close" @click="close" src="/assets/close-rounded-light.svg" alt="" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SideModal',
  props: {
    modalStyle: String,
    wide: Boolean,
    small: Boolean
  },
  methods: {
    close() {
      if (this.$props.wide)
        this.$refs.modal_side.style.right = '-1438px'
      else if (this.$props.small)
        this.$refs.modal_side.style.right = '-463px'
      else
        this.$refs.modal_side.style.right = '-650px'
      
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    }
  },
  mounted() {
    if (this.$props.wide) {
      this.$refs.modal_side.style.right = '-1438px'
      this.$refs.modal_side.style.width = '1438px'
    } else if (this.$props.small) {
      this.$refs.modal_side.style.right = '-463px'
      this.$refs.modal_side.style.width = '463px'
    } else {
      this.$refs.modal_side.style.right = '-650px'
      this.$refs.modal_side.style.width = '650px'
    }

    this.$refs.modal_side.style.transition = 'right .3s'
    
    setTimeout(() => {
      this.$refs.modal_side.style.right = '0'
    }, 0)
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';
</style>
