<template>
    <div class="flex flex-wrap justify-between">
        <div v-for="(value, key) in fields" :key="key" class="w-45 flex column mb-3">
            <p class="text-gray8 text-margin">{{ value }}</p>
            <p class="text-20 mt-1" v-if="key !== 'related_tickets'" >{{ getValue(key) }}</p>
            <p class="text-20 mt-1" v-else>
                <template v-if="item?.related_tickets?.length">
                    <template v-for="(ticket, index) in item.related_tickets" :key="ticket.id">
                        <span class="link cursor-pointer" @click="redirectToTicket(ticket)">{{ ticket.ticket_number }}</span>{{index < item.related_tickets.length - 1 ? ', ' : ''}}
                    </template>
                </template>
                <template v-else>-</template>
            </p>
        </div>
        <div class="w-45 flex column mb-3">
            <div class="text-20 text-gray8 text-margin">Схема подключения
                <span class="text-12 mt-1 cursor-pointer link" @click="edit('connection_schema')">изменить</span>
            </div>
            <div v-if="item?.files.length" class="flex gap-2 flex-wrap mt-3">
                <div class="images" v-for="file in item?.files" :key="file">
                    <!-- <div class="w-200">
                        <template
                            v-if="file.file_name.match(/\.(jpg|jpeg|png|gif|jfif)$/i) || file.file_url.match(/\.(jpg|jpeg|png|gif|jfif)$/i)">
                            <img :src="file.file_url" @click="toggleImageModal(file.file_url)" alt="Image"
                                class="w-200 h-200" />
                        </template>
<template v-else-if="file.file_name.match(/\.(mp4|avi|mov|webm)$/i) || file.file_url.match(/\.(mp4|avi|mov|webm)$/i)">
                            <video class="attached-files__video w-200 h-200" controls :src="file.file_url"></video>
                        </template>
<div></div>
</div> -->
<!--                    <a class="cursor-pointer" :href="file.file_url" download target="_blank">-->
<!--                        <div class="mr-2 mt-1 link">{{ file.file_name }}</div>-->
<!--                    </a>-->

                  <template v-if="file.file_name.match(/\.(pdf)$/i) || file.file_url.match(/\.(pdf)$/i)">
                    <a class="cursor-pointer" :href="file.file_url" download target="_blank">
                                              <div class="mr-2 mt-1 link">{{ file.file_name }}</div>
                    </a>
                  </template>
                  <img v-else class="cursor-pointer" @click="toggleImageModal(file.file_url)" :src="file.file_url" height="50" width="50" alt="image">
                </div>
            </div>
            <p v-else class="text-20 mt-1 cursor-pointer link" @click="edit('connection_schema')">-</p>
        </div>

        <custom-modal v-if="image" @close="toggleImageModal">
            <image-modal :image="image" />
        </custom-modal>
        <custom-modal v-if="currentEditableInput" @close="closeModal">
          <div class="choose-modal">
            <template v-if="currentEditableInput === 'price'">
              <div class="mt-4 mb-4 flex column justify-center gap-2">
                <label class="choose-modal__title w-100">Себестоимость</label>
                <input type="number" class="input input-form mt-2" v-model="updateForm.price"
                       placeholder="Себестоимость" />
              </div>
            </template>
            <template v-if="currentEditableInput === 'distance_along_the_route'">
              <div class="mt-4 mb-4 flex column justify-center gap-2">
                <label class="choose-modal__title w-100">Расстояние по трассе</label>
                <input class="input input-form mt-2" v-model="updateForm.distance_along_the_route"
                       placeholder="Расстояние по трассе" type="number" />
              </div>
            </template>
            <template v-if="currentEditableInput === 'estimated_connection_date'">
                <div class="mt-3">
                    <label class="label-form">Ориентировочное время подключения</label>
                    <input class="input-form mt-2" type="date" max="2999-12-31"
                        v-model="updateForm.estimated_connection_date">
                </div>
            </template>
            <template v-if="currentEditableInput === 'need_access'">
                <div class="mt-3">
                    <label class="label-form">Доступ</label>
                    <fieldset class="mt-2 flex gap-2">
                        <div>
                            <input id="need" type="radio" v-model="updateForm.need_access" :value="true">
                            <label for="need">Нужен</label>
                        </div>
                        <div>
                            <input id="no_need" type="radio" v-model="updateForm.need_access" :value="false">
                            <label for="no_need">Не нужен</label>
                        </div>
                    </fieldset>
                    <div v-if="updateForm.need_access" class="mt-3" >
                        <QueriesTCAccessForm ref="accessForm" :parent-ticket-id="item.id" :ticket="item" @created="handleAccessCreated"/>
                    </div>
                </div>
            </template>
            <template v-if="currentEditableInput === 'necessary_equipments'">
              <div class="mt-4 mb-4 flex column justify-center gap-2">
                <label class="choose-modal__title w-100">Необходимое оборудование</label>
                <multiselect 
                    v-model="updateForm.necessary_equipments" 
                    :options="necessaryEquipmentsWithNone" 
                    label="name_ru"
                    multiple 
                    track-by="id" 
                    placeholder="Выберите оборудование" 
                    class="mt-1"
                    style="overflow: visible;"
                    @select="handleNecessaryEquipmentsSelect"
                    @remove="handleNecessaryEquipmentsRemove"
                >
                    <template #no-result>Нет результатов</template>
                    <template #no-options>Нет данных</template>
                </multiselect>
              </div>
            </template>
            <template v-if="currentEditableInput === 'connection_method'">
              <div class="mt-4 mb-4 flex column justify-center gap-2">
                    <label class="choose-modal__title w-100">Метод подключения</label>
                    <select class="input-form select mt-1" v-model="updateForm.connection_method_id">
                        <option v-for="option in connectionMethods" :key="option.id" :value="option.id">
                            {{ option.name_ru }}
                        </option>
                    </select>
              </div>
            </template>
            <template v-if="currentEditableInput === 'additional_goods'">
              <div class="mt-4 mb-4 flex column justify-center gap-2">
                <label class="choose-modal__title w-100">Дополнительные услуги</label>
                <multiselect 
                    v-model="updateForm.additional_goods" 
                    :options="additionalGoods"
                    label="name_ru"
                    multiple 
                    track-by="id" 
                    placeholder="Выберите услуги" 
                    class="mt-1"
                    style="overflow: visible;"
                >
                    <template #no-result>Нет результатов</template>
                    <template #no-options>Нет данных</template>
                </multiselect>
              </div>
            </template>
            <template v-if="currentEditableInput === 'connection_schema'">
                <div class="mt-3 flex gap-2 column">
                    <UploadFile ref="fileInput" class="mt-3" multiple />
                </div>
            </template>
            <button @click.prevent="update" class="btn form-btn mt-2 w-100">Сохранить</button>
          </div>
        </custom-modal>
    </div>
</template>
<script>
import CustomModal from "@/components/modules/CustomModal.vue";
import ImageModal from "@/components/modals/ImageModal.vue";
import Multiselect from 'vue-multiselect';
import QueriesTCAccessForm from "./QueriesTCAccessForm.vue";
import { mapActions } from "vuex";
import UploadFile from "@/components/UploadFile.vue";
export default {
    name: 'QueriesTCMainInfoComponent',
    components: {
        ImageModal,
        CustomModal,
        Multiselect,
        QueriesTCAccessForm,
        UploadFile
    },
    props: [
        'item',
        'necessaryEquipments',
        'connectionMethods',
        'additionalGoods',
    ],
    data() {
        return {
            image: '',
            currentEditableInput: null,
            updateForm: {
                price: this.item?.price || '',
                necessary_equipments: this.item?.necessary_equipments
                    ?.filter(item => item?.necessary_equipment)
                    ?.map(item => item.necessary_equipment) || [],
                connection_method_id: this.item?.connection_method?.id || '',
                additional_goods: this.item?.additional_goods
                    ?.filter(item => item?.additional_service)
                    ?.map(item => item.additional_service) || [],
                distance_along_the_route: this.item?.distance_along_the_route || '',
                estimated_connection_date: this.item?.estimated_connection_date || '',
                need_access: this.item?.need_access || false,
            }
        }
    },
    computed: {
        necessaryEquipmentsWithNone() {
            return [
                { id: 0, name_ru: 'Нет' },
                ...this.necessaryEquipments
            ]
        }
    },
    methods: {
        ...mapActions('queriesTCModule', {
            updateItem: 'updateItem'
        }),
        downloadFile(file) {
            const url = file.file_url;
            const a = document.createElement('a');
            a.href = url;
            a.download = file.file_name || 'downloaded-file';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        getValue(key) {
            let additionalServices, equipments;
            
            switch (key) {
                case 'clientType':
                    return this.item?.client?.type?.name_ru || ' - '
                case 'clientName':
                    return this.item?.client?.name || ' - '
                case 'author':
                    return this.item?.author?.full_name || ' - '
                case 'connection_technology':
                    return this.item?.connection_technology?.name_ru || ' - '
                case 'connection_method':
                    return this.item?.connection_method?.name_ru || ' - '
                case 'lines':
                    return this.item?.lines?.map(item => item.name_ru).join(', ') || ' - '
                case 'additional_goods':
                    if (!this.item?.additional_goods?.length) return ' - '
                    additionalServices = this.item.additional_goods
                        .filter(item => item?.additional_service)
                        .map(item => item.additional_service.name_ru)
                    return additionalServices.length ? additionalServices.join(', ') : ' - '
                case 'necessary_equipments':
                    if (!this.item?.necessary_equipments?.length) return ' - '
                    equipments = this.item.necessary_equipments
                        .filter(item => item?.necessary_equipment)
                        .map(item => item.necessary_equipment.name_ru)
                    return equipments.length ? equipments.join(', ') : ' - '
                case 'need_access':
                    return this.item?.need_access ? 'Нужен' : 'Не нужен'
                default:
                    return this.item[key] || ' - '
            }
        },
        toggleImageModal(src = '') {
            this.image = src
        },
        edit(field) {
            if (this.editableFields.includes(field)) {
                this.currentEditableInput = field
            }
        },
        async update() {
            try {
                const formData = {}
                if (this.currentEditableInput === 'price')
                    formData.price = this.updateForm.price

                if (this.currentEditableInput === 'connection_method')
                    formData.connection_method_id = this.updateForm.connection_method_id

                if (this.currentEditableInput === 'distance_along_the_route')
                    formData.distance_along_the_route = this.updateForm.distance_along_the_route

                if (this.currentEditableInput === 'necessary_equipments') {
                    if (this.updateForm.necessary_equipments?.length === 1 && 
                        this.updateForm.necessary_equipments[0].id === 0) {
                        formData.necessary_equipments = [0]
                    } else if (this.updateForm.necessary_equipments?.length) {
                        formData.necessary_equipments = this.updateForm.necessary_equipments.map(equipment => equipment.id)
                    }
                }

                if (this.currentEditableInput === 'estimated_connection_date')
                    formData.estimated_connection_date = this.updateForm.estimated_connection_date

                if (this.currentEditableInput === 'need_access')
                    formData.need_access = this.updateForm.need_access

                if (this.currentEditableInput === 'additional_goods') {
                    formData.additional_services = this.updateForm.additional_goods?.map(service => service.id) || []
                }

                if (this.currentEditableInput === 'connection_schema') {
                    if (this.$refs.fileInput?.files?.length) {
                        this.$refs.fileInput?.files.forEach(file => {
                            formData.files = formData?.files || []
                            formData.files.push({
                                'name': file.uploadedName,
                                'path': file.uploadedPath
                            });
                        })
                    }
                }

                await this.updateItem({
                    id: this.item.id,
                    payload: formData
                })

                this.currentEditableInput = null

                this.$toast.success('Данные успешно обновлены')
            } catch (error) {
                console.error('Update error:', error)
                this.$toast.error('Произошла ошибка при обновлении данных')
            }
        },
      closeModal() {
        this.currentEditableInput = false
        this.updateForm.price = ''
        this.updateForm.necessary_equipments = []
        this.updateForm.connection_method_id = ''
        this.updateForm.additional_goods = ''
        this.updateForm.distance_along_the_route = ''
        this.updateForm.estimated_connection_date = ''
        this.updateForm.need_access = ''
      },
      handleNecessaryEquipmentsSelect(selected) {
        if (selected.id === 0) {
            this.updateForm.necessary_equipments = [selected]
        } else {
            this.updateForm.necessary_equipments = this.updateForm.necessary_equipments.filter(e => e.id !== 0)
        }
    },

    handleNecessaryEquipmentsRemove(removed) {
        if (removed.id === 0) {
            this.updateForm.necessary_equipments = []
        }
    },
    redirectToTicket(ticket) {
        if (!ticket?.ticket_number || !ticket?.ticket_kind_key) {
            this.$toast.error('Некорректные данные заявки')
            return
        }
        this.$router.push(`/${ticket.ticket_kind_key}/ticket/${ticket.ticket_number}`)
    }
    },
    created() {
      this.updateForm.price = ''
      this.updateForm.necessary_equipments = []
      this.updateForm.connection_method_id = ''
      this.updateForm.additional_goods = ''
      this.updateForm.distance_along_the_route = ''
      this.updateForm.estimated_connection_date = ''
      this.updateForm.need_access = ''

        this.fields = {
            clientType: "Тип клиента",
            clientName: "Название клиента",
            address: "Адрес подключения",
            contact_info: "Контактное лицо",
            contact_number: "Телефон контактного лица",
            author: "Менеджер",
            lines: "Услуги",
            connection_technology: "Технология",
            created_at: "Дата и время создания заявки",
            price: "Себестоимость",
            connection_method: "Метод подключения",
            additional_goods: "Дополнительные услуги",
            distance_along_the_route: "Расстояние по трассе",
            necessary_equipments: "Необходимое оборудование",
            estimated_connection_date: "Ориентировочное время подключения",
            need_access: "Доступ",
            related_tickets: "Связанные заявки",
        }

        this.editableFields = [
            'price',
            'connection_method',
            'additional_goods',
            'distance_along_the_route',
            'need_access',
            'necessary_equipments',
            'estimated_connection_date',
            'connection_schema',
        ]
    },
}
</script>
<style scoped lang="scss">
.w-200 {
    width: 200px;
}

.h-200 {
    height: 200px;
}

.images {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
}

.choose-modal {
  width: 500px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.15px;
  }

  &__btn {
    height: 92px;
    padding-left: 28px;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 32px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    p {
      color: #FFF;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.24px;
    }
  }
}

::v-deep .modal-container {
  overflow: visible;
}
</style>