<template>
  <div class="w-100 flex column mt-4 monthly">
    <div class="monthly__header flex align-center justify-between">
      <h4 class="monthly__title">График по заявкам</h4>

      <div class="monthly__months">
        <span>{{ format(new Date(monthBlock?.[0].selected_date), 'LLLL', { locale: ru }) }}</span>
        <span> - </span>
        <span>{{ format(new Date(monthBlock?.[monthBlock?.length - 1].selected_date), 'LLLL', { locale: ru }) }}</span>
      </div>
    </div>

    <div class="monthly__container">
      <Bar class="monthly__diagram" :data="transformArray()" :options="options" />
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'MonthlyDiagram',
  props: ['monthBlock'],
  components: { Bar },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              fontSize: 12,
              usePointStyle: true,
              textAlign: 'left',
            },
            title: {
              padding: 40
            },
            align: 'start'
          },
        },
        scales: {
          y: {
            display: false,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks:{
              align: 'start',
              padding: 10
            }
          }
        },
        elements: {
          bar: {
            borderRadius: 10
          }
        },
      },
      format,
      ru,
    }
  },
  methods: {
    transformArray() {
      const transformedArray = [];
      for (const item of this.monthBlock) {
        transformedArray.push({
          selected_date: item["selected_date"].slice(-2),
          service_cnt: item["service_cnt"],
          emergency_cnt: item["emergency_cnt"],
        });
      }

      const dataset = {
        labels: transformedArray.map(function (date) {
          return date.selected_date;
        }),
        datasets: [
          {
            label: 'Сервис',
            data: transformedArray.map(function (item) {
              return item.service_cnt;
            }),
            backgroundColor: '#35CD7C',
            barWidth: 13
          },
          {
            label: 'Авария',
            data: transformedArray.map(function (item) {
              return item.emergency_cnt;
            }),
            backgroundColor: '#FFB06D',
            barWidth: 13
          },
        ],
      };

      return dataset;
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';
.monthly {
  padding: 32px 16px 16px;
  background: #fff;
  border-radius: 15px;
}
.monthly__radios {
  padding-left: 16px;
  border-left: 1px solid #D9D9D9;

  .field__radio {
    width: 200px;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.datepicker {
  width: 166px;
  margin-right: 12px;
  @media (min-width: 1440px) {
    width:300px;
  }
}
.monthly__title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-right: 32px;

  @media (min-width: 1440px) {
    margin-right: 0;
    white-space: nowrap;
  }
}

.monthly__months {
  span {
    text-transform: capitalize;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

.monthly__diagram {
  height: 189px !important;
}
.monthly__container {
  border-radius: 43px;
  background: #FFF;
  margin-top: 24px;
}
</style>
