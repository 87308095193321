export const ClientTicketsTableColumns = [
    {
        title: 'Дата создания',
        value: 'created_at',
        sort: false,
        isShort: false
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Тип заявки',
        value: 'ticket_kind',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Категория',
        value: 'category',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Подкатегория',
        value: 'type',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Исполнитель',
        value: 'responsible',
        inner: 'title',
        sort: false,
        isShort: false
    }
]

export const ClientTableColumns = [
    {
        title: 'Дата создания',
        value: 'created_at',
        sort: false,
        isShort: false
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Тип заявки',
        value: 'ticket_kind',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Категория',
        value: 'category',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Подкатегория',
        value: 'type',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Исполнитель',
        value: 'responsible',
        inner: 'title',
        sort: false,
        isShort: false
    }
]

export const TicketStatusTimeTableColumns = [
    {
        title: 'Дата',
        value: 'created_at',
        inner: null,
        sort: false,
        isShort: false
    },
    {
        title: 'Старый статус',
        value: 'old_status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Новый статус',
        value: 'new_status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Время',
        value: 'work_time',
        inner: null,
        sort: false,
        isShort: false
    },
]

export const TicketsTableColumns = [
    {
        title: 'checkbox',
        value: 'id',
        inner: 'full_name',
        sort: false,
        isShort: true,
        isChecked: true,
    },
    {
        title: 'Клиент',
        value: 'client',
        inner: 'full_name',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Номер заявки',
        value: 'ticket_number',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Категория',
        value: 'category',
        inner: 'title',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Дата и время создания',
        value: 'created_at',
        sort: true,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Адрес',
        value: 'address',
        sort: false,
        isShort: false,
        isLong: true,
        isChecked: true,
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Регистратор',
        combined: [
            {
                path: 'author.full_name',
            },
            {
                path: 'author.department.title',
                wrapAroundWith: (value) => `(${value})`,
            }
        ],
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Ответственный',
        combined: [
            {
                path: 'responsible.title',
            },
            {
                path: 'responsible.department.title',
                wrapAroundWith: (value) => {
                    return value ? `(${value})` : ''
                },
            }
        ],
        sort: false,
        isShort: false,
        isChecked: true,
    },
]


export const EmergencyTicketsTableColumns = [
    {
        title: 'Тип инцидента',
        value: 'commutators',
        another_value: 'nodes',
        sort: false,
        isShort: false,
        isLong: true,
        isChecked: true,
    },
    {
        title: 'Начало инцидента',
        value: 'emergency_started_at',
        sort: true,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Клиентов',
        value: 'abonent_cnt',
        sort: false,
        isShort: false,
        isLong: false,
        isChecked: true,
    },
    {
        title: 'Задача',
        value: 'ticket_number',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'План, время восстан.',
        value: 'planned_recovered_at',
        sort: false,
        isShort: false,
        isChecked: true,
    },
    {
        title: 'Комментарий',
        value: 'message_current_situation',
        sort: false,
        isShort: false,
        isChecked: true,
    },
]

export const SubtasksTableColumns = [
    {
        title: 'Номер подзадачи',
        combined: [
            {
                path: 'ticket_number',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата создания',
        combined: [
            {
                path: 'created_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Автор',
        combined: [
            {
                path: 'author.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]


export const PlanTicketsTableColumns = [
    {
        title: 'Тип инцидента',
        value: 'commutators',
        another_value: 'nodes',
        sort: false,
        isShort: false,
        isLong: true
    },
    {
        title: 'Начало работы',
        value: 'planned_at_begin',
        sort: true,
        isShort: false
    },
    {
        title: 'Окончание работы',
        value: 'planned_at_end',
        sort: false,
        isShort: false,
    },
    {
        title: 'Клиентов',
        value: 'abonent_cnt',
        sort: false,
        isShort: false,
        isLong: false
    },
    {
        title: 'Задача',
        value: 'ticket_number',
        sort: false,
        isShort: false
    },
    {
        title: 'Комментарий',
        value: 'message_current_situation',
        sort: false,
        isShort: false,
    },
]

export const ClientsTableColumns = [
    {
        title: 'Клиент',
        value: 'full_name',
        second: 'billing_id',
        sort: false,
        isShort: false
    },
    {
        title: 'Договор',
        value: 'agreement',
        sort: false,
        isShort: false
    },
    {
        title: 'Контакты',
        value: 'email',
        second: 'phone',
        sort: false,
        isShort: false
    },
]

export const ConnectionTicketsTableColumns = [
    {
        title: 'ЗАЯВКА',
        value: 'ticket_number',
        sort: false,
        isShort: false
    },
    {
        title: 'Клиент',
        value: 'connection_client',
        inner: 'name',
        sort: false,
        isShort: false
    },
    {
        title: 'Тип',
        value: 'type',
        inner: 'type',
        sort: false,
        isShort: false
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false
    },
]

export const CabinetTicketsTableColumns = [
    {
        title: 'Клиент',
        value: 'client',
        inner: 'full_name',
        sort: false,
        isShort: false
    },
    {
        title: 'Номер заявки',
        value: 'ticket_number',
        sort: false,
        isShort: false
    },
    {
        title: 'КАТЕГОРИЯ',
        value: 'category',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Дата и время создания',
        value: 'created_at',
        sort: true,
        isShort: false
    },
    {
        title: 'Адрес',
        value: 'address',
        sort: false,
        isShort: false,
        isLong: true
    },
    {
        title: 'Ответственный',
        value: 'responsible',
        inner: 'title',
        sort: false,
        isShort: false
    },
]

export const UserTableColumns = [
    {
        title: "ID",
        value: "id",
        sort: false,
        isShort: false
    },
    {
        title: "ФИО",
        value: "full_name",
        sort: false,
        isShort: false
    },
    {
        title: "ERP ID",
        value: "erp_id",
        sort: false,
        isShort: false
    },
    {
        title: "Статус",
        value: "block_date",
        sort: false,
        isShort: false
    },
    {
        title: "Номер телефона",
        value: "phone",
        sort: false,
        isShort: false
    },
    {
        title: "Должность",
        value: "position",
        sort: false,
        isShort: false
    },
    {
        title: "Роль",
        value: "role",
        inner: 'title',
        sort: false,
        isShort: false
    },
];

export const WorkTimeColumns = [
    {
        title: "Приоритет",
        value: "id",
        sort: false,
        isShort: false
    },
    {
        title: "Название",
        value: "title",
        sort: false,
        isShort: false
    },
    {
        title: "Крайние сроки выполнения",
        value: "timeFormat",
        sort: false,
        isShort: false,
        icon: '/assets/pencil.svg'
    },
]


export const NotificationColumns = [
    {
        title: 'ID',
        value: 'id',
        sort: false,
        isShort: true
    },
    {
        title: 'ЛИЧНЫЙ КАБИНЕТ',
        value: 'app_name',
        sort: false,
        isShort: false
    },
    {
        title: 'Время отправки',
        value: 'send_at',
        sort: false,
        isShort: false
    },
    {
        title: 'Загаловок',
        value: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'СОДЕРЖАНИЕ',
        value: 'body',
        sort: false,
        isShort: false
    },
    {
        title: 'Автор',
        value: 'author',
        inner: 'full_name',
        sort: false,
        isShort: false
    },
]

export const CurrentSitTableColumns = [
    {
        title: 'ID',
        path: 'manager.id',
        sort: false,
        isShort: false
    },
    {
        title: 'Сотрудник',
        path: 'manager.full_name',
        sort: false,
        isShort: false
    },
    {
        title: 'Активных задач',
        path: 'tickets_data.waiting',
        sort: false,
        isShort: false
    },
    {
        title: 'Созданных',
        path: 'tickets_data.created',
        sort: false,
        isShort: false
    },
    {
        title: 'Выполняется',
        path: 'tickets_data.process',
        sort: false,
        isShort: false
    },
    {
        title: 'Выполнено',
        path: 'tickets_data.done',
        sort: false,
        isShort: false
    },
]

export const DutyScheduleColumns = [
    {
        title: 'Подразделение',
        combined: [
            {
                path: 'department',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дежурный',
        combined: [
            {
                path: 'manager',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Внутренний номер',
        combined: [
            {
                path: 'innet_phone',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Мобильный номер',
        combined: [
            {
                path: 'phone',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата и время начала дежурства',
        combined: [
            {
                path: 'started_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата и время окончания дежурства',
        combined: [
            {
                path: 'ended_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Копировать',
        value: 'id',
        inner: 'full_name',
        sort: false,
        isShort: true
    },
]

export const DutyScheduleShortColumns = [
    {
        title: 'Отдел',
        combined: [
            {
                path: 'department',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дежурный',
        combined: [
            {
                path: 'manager',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Внутренний номер',
        combined: [
            {
                path: 'innet_phone',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Мобильный номер',
        combined: [
            {
                path: 'phone',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]

export const AccessDataTable = [
    {
        title: 'Инициатор',
        value: 'author',
        inner: 'full_name',
        sort: false,
        isShort: false

    },
    {
        title: 'Адрес',
        value: 'addresses',
        inner: 'address',
        sort: false,
        isShort: false

    },
    {
        title: 'Номер заявки',
        value: 'ticket_number',
        sort: false,
        isShort: false
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Исполнитель',
        value: 'responsible',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Тип заявки',
        value: 'service_type',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Дата создания заявки',
        value: 'created_at',
        sort: false,
        isShort: false
    }]

export const QueriesTCShortColumns = [
    {
        title: 'Номер заявки',
        value: 'ticket_number',
        sort: false,
        isShort: false
    },
    {
        title: 'Название клиента',
        combined: [
            {
                path: 'client',
                wrapAroundWith: (value) => {
                    if (!value) return 'Не указано';
                    return value.name || value.full_name || 'Не указано';
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Адрес',
        combined: [
            {
                path: 'address',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Технология',
        combined: [
            {
                path: 'connection_technology.name_ru',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Статус',
        value: 'status',
        inner: 'title',
        sort: false,
        isShort: false
    },
    {
        title: 'Менеджер (автор создания ТВ)',
        combined: [
            {
                path: 'author.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]
export const EmergencyTicketsLCSShortColumns = [
    {
        title: 'Номер заявки',
        combined: [
            {
                path: 'ticket_number',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Тип инцидента',
        combined: [
            {
                path: 'service_type.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Начало инцидента',
        combined: [
            {
                path: 'emergency_started_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Клиентов',
        combined: [
            {
                path: 'abonent_cnt',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата и время создания',
        combined: [
            {
                path: 'created_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Статус',
        combined: [
            {
                path: 'status.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Регистратор',
        combined: [
            {
                path: 'author.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Ответственный',
        combined: [
            {
                path: 'responsible.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]

export const ServicesTicketsLCSShortColumns = [
    {
        title: 'Номер заявки',
        combined: [
            {
                path: 'ticket_number',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Клиент',
        combined: [
            {
                path: 'client.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Адрес',
        combined: [
            {
                path: 'address',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата и время создания',
        combined: [
            {
                path: 'created_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Статус',
        combined: [
            {
                path: 'status.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Регистратор',
        combined: [
            {
                path: 'author.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Ответственный',
        combined: [
            {
                path: 'responsible.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]

export const TicketLCSPointsInfoShortColumns = [
    {
        title: 'Номера точек',
        combined: [
            {
                path: 'point_number',
                wrapAroundWith: (value) => {
                    return value ? `${value} - ${value + 1}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Координаты точки',
        value: 'coordinates',
        sort: false,
        isShort: true
    },
    {
        title: 'Начало кабеля',
        combined: [
            {
                path: 'start_cable',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Конец кабеля',
        combined: [
            {
                path: 'end_cable',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Кол-во кабеля затраченного между точками',
        combined: [
            {
                path: 'cable_length',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Тип кабеля',
        combined: [
            {
                path: 'cable_type.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Кол-во подвесов',
        combined: [
            {
                path: 'suspension_count',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Тип подвеса',
        combined: [
            {
                path: 'suspension_type.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Комментарии',
        combined: [
            {
                path: 'comment',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Фото',
        value: 'photo_uri',
        sort: false,
        isShort: true
    },
]

export const AreasShortColumns = [
    {
        title: 'Название участка',
        combined: [
            {
                path: 'area_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Начальник участка',
        combined: [
            {
                path: 'manager.full_name',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Количество бригад на участке',
        combined: [
            {
                path: 'brigades.length',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Статус участка',
        value: 'active_status',
        sort: false,
        isShort: true
    },
    {
        title: '',
        value: 'action',
        sort: false,
        isShort: true
    },
]

export const RelatedTicketsShortColumns = [
    {
        title: 'ID задачи',
        combined: [
            {
                path: 'ticket.id',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Название ',
        combined: [
            {
                path: 'ticket.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Ответственный',
        combined: [
            {
                path: 'ticket.responsible.title',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
    {
        title: 'Дата создания',
        combined: [
            {
                path: 'ticket.created_at',
                wrapAroundWith: (value) => {
                    return value ? `${value}` : 'Не указано'
                },
            }
        ],
        sort: false,
        isShort: true
    },
]