<template>
  <div class="wrapper modified flex column">
    <div v-if="loading" class="loader-wrapper">
      <div class="loader"></div>
    </div>
    <div v-else class="bg-white p-20 rounded flex column gap-2">
      <div class="w-100 p-20 border-bottom flex justify-between">
        <button @click="openCreateModal" type="button" class="btn btn-create" style="width: fit-content">
          <img src="/assets/plus-create.svg" alt=""/>
          Создать участок
        </button>
      </div>
      <custom-table
          :columns="AreasShortColumns"
          :data="areas"
          :total-pages="10"
          :current-page="currentPage"
          :is-pagination="false"
          @deleteArea="handleDeleteArea"
      />
      <PaginationComponent :total-pages="totalAreas"
                           :current-page="currentPage"
                           @page-change="onPageChange"
      />
    </div>
    <custom-modal v-if="isOpenCreateModal" @close="isOpenCreateModal = !isOpenCreateModal">
      <create-area-form @close="isOpenCreateModal = !isOpenCreateModal" />
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "@/components/modules/CustomModal.vue";
import CreateAreaForm from "@/components/modals/areas/CreateAreaForm.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaginationComponent from "@/components/ui/PaginationComponent.vue";
import CustomTable from "@/components/modules/CustomTable.vue";
import {AreasShortColumns} from "@/modules/table-columns"

export default {
  name: 'AreasManagementView',
  components: {
    CustomTable, 
    PaginationComponent, 
    CustomModal, 
    CreateAreaForm
  },
  async created() {
    this.setLoading(true)
    try {
      await this.getPolygons()
      await this.getAreas({per_page: 10})
    } finally {
      this.setLoading(false)
    }
  },
  data() {
    return {
      isOpenCreateModal: false,
      currentPage: 1,
      AreasShortColumns,
      selectedAreaId: 0
    }
  },
  computed: {
    ...mapGetters({
      areas: 'areas',
      areasTotalPages: 'areasTotalPages',
      totalAreas: 'totalAreas',
      loading: 'loading'
    }),
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      getPolygons: 'getPolygons',
      getAreas: 'getAreas',
      deleteArea: 'deleteArea'
    }),
    openCreateModal() {
      this.isOpenCreateModal = true
    },
    async handleDeleteArea(id) {
      this.setLoading(true)
      try {
        await this.deleteArea(id)
        this.$toast.success('Участок успешно удален')
      } finally {
        this.setLoading(false)
      }
    },
    async onPageChange(page = 1) {
      this.setLoading(true)
      try {
        this.currentPage = page
        await this.getAreas({page: this.currentPage, per_page: 10})
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>