import {api} from '@/service/axios'

export const brigadeOptionsModule = {
    state: () => ({
        brigadesOptions: [],
        isBrigadeAddInstallersModal: false,
        installers: [],
        currentBrigadeOptions: [],
        brigadeLogs: []
    }),
    getters: {
        brigadesOptions(state) {
            return state.brigadesOptions
        },
        isBrigadeAddInstallersModal(state) {
            return state.isBrigadeAddInstallersModal
        },
        installers(state) {
            return state.installers
        },
        currentBrigadeOptions(state) {
            return state.currentBrigadeOptions
        },
        brigadeLogs(state) {
            return state.brigadeLogs
        },
    },
    mutations: {
        setBrigadesOptions(state, payload) {
            state.brigadesOptions = payload
        },
        setisBrigadeAddInstallersModal(state, payload) {
            state.isBrigadeAddInstallersModal = payload
        },
        setInstallers(state, payload) {
            state.installers = payload
        },
        setCurrentBrigadeOptions(state, payload) {
            state.currentBrigadeOptions = payload
        },
        deleteCurrentBrigadeOptions(state, payload) {
            const res = state.currentBrigadeOptions.installers.filter((el) => el.relation_id !== payload)
            state.currentBrigadeOptions.installers = res
        },
        setBrigadeLogs(state, payload) {
            state.brigadeLogs = payload
        },
        setCurrentBrigadeOwner(state, payload){
            state.currentBrigadeOptions.brigade.owner = payload
        },
        setBrigadeStatus(state, payload) {
            const brigade = state.brigadesOptions.find(item => item.brigade.id === payload)
            if (brigade){
                brigade.brigade.active = !brigade.brigade.active;
            }
        },
        removeBrigade(state, brigadeId) {
            state.brigadesOptions = state.brigadesOptions.filter(item => item.brigade.id !== brigadeId);
        }
    },
    actions: {
        async fetchBrigadesRelations({commit}, payload) {
            const res = await api.get(`brigade/relations`, {params: payload})
            commit('setBrigadesOptions', res.data)
            return res
        },
        async fetchBrigadesInstallers({commit}, payload) {
            const res = await api.get('brigade/installers')
            commit('setInstallers', res.data)
            return res
        },
        async deleteBrigadesInstallers({commit}, payload) {
            const res = await api.delete(`brigade/delete-installer-relations/${payload.relation_id}`)
            commit('deleteCurrentBrigadeOptions', payload.relation_id)
            return res
        },
        async postBrigadesInstallers({commit}, payload) {
            const res = await api.post('brigade/add-installers', payload)
            return res
        },
        async updateBrigade({commit}, payload) {
            const {id} = payload
            delete payload.id
            const res = await api.put(`brigade/update/${id}`, payload)
            return res
        },
        async changeStatusBrigade({commit, dispatch}, payload) {
            const res = await api.post(`brigade/change-active/${payload.id}`)
            return res
        },
        async fetchBrigadeLogs({commit}, payload) {
            const res = await api.get(`brigade/get-logs/${payload.id}`)
            commit('setBrigadeLogs', res.data)
            return res
        },
        async changeBrigadeOwner({commit}, payload){
            const res = await api.post('brigade/change-brigade-owner', payload)
            return res
        },
        async createBrigade({commit}, payload) {
            const res = await api.post(`brigade/create-brigade`, payload)
            return res
        },
        async createTechnic({commit}, payload) {
            const res = await api.post(`brigade/create-installers`, payload)
            return res
        },
        async deleteBrigade({commit}, brigadeId) {
            const res = await api.delete(`brigade/delete/${brigadeId}`)
            if (res.data.success) {
                commit('removeBrigade', brigadeId)
            }
            return res
        }
    }
}