<template>
  <div class="flex justify-between" :class="{'justify-start': isModified}">
      <div class="tab flex column justify-center align-center cursor-pointer" v-for="tab in tabs" :key="tab.key" :class="{'active': activeTab === tab.key, 'mr-3': isModified}" @click="tabClick(tab.key)">
        <img class="tab-icon" :src="tab.icon" alt="" />
        <img class="tab-icon_active" :src="tab.icon_active" alt="" />
        <p class="uppercase bold">{{tab.title}}</p>
      </div>
  </div>
</template>

<script>


export default {
  name: 'TabsComponent',
  props: ['tabs', 'activeTab', 'isModified'],
  methods: {
    tabClick(tab) {
      this.$emit('tabClick', tab)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';


.tab {
  width: 92px;
  height: 72px;
  background: $gray3;
  border-radius: 5px;

  p {
    color: $gray2;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
  }

  .tab-icon {
    display: block;
  }

  .tab-icon_active {
    display: none;
  }

  &.active, &:hover {
    background: linear-gradient(125deg, #0294FB 0%, #025A83 92.65%);

    .tab-icon {
      display: none;
    }

    .tab-icon_active {
      display: block;
    }

    p {
      color: $white;
    }
  }
}

</style>
