<template>
  <div class="change-date flex column align-center">
    <div class="title uppercase text-black2">
      {{title}}
    </div>
    <div class="inner">
      <yandex-map
        :coordinates="[
								+latitude,
								+longitude
							]"
        :zoom="17"
        :controls="[
							'rulerControl',
							'zoomControl',
							'searchControl',
						]"
        @map-was-initialized="mapInit"
      >
        <yandex-marker
          marker-id="current-placemark"
          :coordinates="[
								+latitude,
								+longitude
							]"
          :options="mapIcon"
        />
      </yandex-map>
      <button type="button" class="btn mt-3" @click="() => $emit('close')">OK</button>
    </div>
  </div>
</template>

<script>

import { YandexMap, YandexMarker } from 'vue-yandex-maps'
export default {
  name: 'MapModal',
  components: {
    YandexMap,
    YandexMarker,
  },
  props: ['longitude', 'latitude', 'title'],
  data() {
    return {
      yandexMap: undefined,
    }
  },
  methods: {
    async mapInit(map) {
      this.yandexMap = map;
    },
  },
  computed: {
    mapIcon() {
      return {
        iconLayout: 'default#image', // используем кастомный макет
          iconImageHref: '/assets/location-pin.svg', // путь к вашей иконке
        iconImageSize: [30, 30], // размеры вашей иконки
        iconImageOffset: [-15, -30], // смещение иконки относительно маркера
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.change-date {
  width: 750px;

  .title {
    margin: 20px 0 40px;
    font-size: 24px;
    text-align: center;
  }

  .inner {
    width: 600px;
  }
}

</style>
