<template>
  <div class="sidebar flex column justify-between" :class="{ 'opened': sideBarOpened }">
    <div class="sidebar-body">
      <div class="sidebar-logo flex w-100 align-center mb-2">
        <img src="/assets/logo_nls.svg" alt=""/>
        <h4>SUPPORT ЦЕНТР</h4>
      </div>
      <div class="flex column" v-for="nav in filteredNavigation" :key="nav.text">
        <router-link class="sidebar-item flex align-center"
           :to="nav.inner ? nav.inner[0].href : nav.href"
           :class="{ 'active': nav.active, 'has-inner': nav.inner }">
          <div class="sidebar-item_image flex justify-center align-center">
            <img class="sidebar-item_main_img" :src="nav.image" alt=""/>
            <img class="sidebar-item_hover_img" :src="nav.hover" alt=""/>
          </div>

          <p>{{ nav.text }}</p>
        </router-link>
        <template v-if="nav.inner">
          <router-link class="sidebar-inner flex align-center" v-for="inner in nav.inner" :key="inner.name"
             :to="inner.href"
             :class="{ 'active': inner.active }">
            <div></div>
            <span>{{ inner.text }}</span>
          </router-link>
        </template>

      </div>

    </div>
    <div class="sidebar-footer">
      <div class="sidebar-item flex align-center" @click="openSideBar()">
        <div class="sidebar-item_image flex justify-center align-center">
          <template v-if="!sideBarOpened">
            <img class="sidebar-item_main_img" src="/assets/CaretRight.svg" alt=""/>
            <img class="sidebar-item_hover_img" src="/assets/CaretRight-white.svg" alt=""/>
          </template>
          <template v-if="sideBarOpened">
            <img class="sidebar-item_main_img" src="/assets/CaretLeft.svg" alt=""/>
            <img class="sidebar-item_hover_img" src="/assets/CaretLeft-white.svg" alt=""/>
          </template>
        </div>
        <p>{{ sideBarOpened ? 'Скрыть меню' : 'Открыть меню' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { navigation } from '@/config/navigation'
import { fullAccessRoles } from '@/config/roles'

export default {
  name: 'SideNavBar',
  data() {
    return {
      isLaptop: false,
      navigation: navigation.map(item => ({
        ...item,
        active: false,
        inner: item.inner ? item.inner.map(inner => ({
          ...inner,
          active: false
        })) : undefined
      }))
    }
  },
  mounted() {
    this.updateActiveStates()
  },
  methods: {
    ...mapMutations({
      setSideBarOpened: 'setSideBarOpened'
    }),
    openSideBar() {
      this.setSideBarOpened(!this.sideBarOpened)
    },
    updateActiveStates() {
      this.navigation.forEach(nav => {
        nav.active = false
        if (nav.inner) {
          nav.inner.forEach(inner => {
            inner.active = false
            if (this.currentPage.name === inner.name) {
              nav.active = true
              inner.active = true
            }
          })
        } else if (this.currentPage.name === nav.name) {
          nav.active = true
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      sideBarOpened: 'sideBarOpened',
      currentPage: 'currentPage',
      user: 'user'
    }),
    filteredNavigation() {
      // Если пользователь имеет полный доступ, показывается вся навигация
      if (fullAccessRoles.includes(this.user?.role?.key)) {
        return this.navigation;
      }

      const userPages = this.user?.role?.pages || [];
      
      // Если массив страниц пуст, показываем всю навигацию
      if (userPages.length === 0) {
        return this.navigation;
      }

      // В противном случае, показываются только доступные страницы
      const allowedPageKeys = userPages.map(page => page.key);

      return this.navigation.filter(nav => {
        // Если в nav есть подразделы
        if (nav.inner) {
          const filteredInner = nav.inner.filter(inner => 
            allowedPageKeys.includes(inner.name)
          );
          // Возвращаем элемент навигации только если есть доступные подразделы
          if (filteredInner.length > 0) {
            return {
              ...nav,
              inner: filteredInner
            };
          }
          return false;
        }
        
        // Проверка на разрешение для элементов навигации, не имеющих внутренних страниц
        return allowedPageKeys.includes(nav.name);
      }).map(nav => {
        if (nav.inner) {
          return {
            ...nav,
            inner: nav.inner.filter(inner => allowedPageKeys.includes(inner.name))
          };
        }
        return nav;
      });
    }
  },
  watch: {
    currentPage() {
      this.updateActiveStates()
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../../theme/variables';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  background: $white;
  width: 100px;
  padding: 25px;
  z-index: 5;
  transition: all .3s ease-out;
  overflow-x: hidden;
  overflow-y: scroll;

  .sidebar-logo {
    img {
      margin-right: 26px;
      transition: all .3s ease-out;
    }

    h4 {
      color: #00557B;
      white-space: nowrap;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  p {
    white-space: nowrap;
  }

  &-item {
    color: $gray2;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 140%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

    &.has-inner {
      margin-bottom: 12px;
    }

    &_image {
      width: 50px;
      min-width: 50px;
      height: 50px;
      background: $gray3;
      border-radius: 50%;
      margin-right: 26px;
      transition: all .3s ease-out;
    }

    &_hover_img {
      display: none;
    }


    &:hover,
    &.active {
      color: $secondary;

      .sidebar-item_image {
        background: linear-gradient(180deg, #0191F6 0%, #04567B 100%);
      }

      .sidebar-item_hover_img {
        display: block;
      }

      .sidebar-item_main_img {
        display: none;
      }
    }

  }

  &-inner {
    margin-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

    div {
      min-width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $gray3;
      margin-left: 20px;
      margin-right: 46px;
      transition: all .3s ease-out;
    }

    span {
      color: $gray2;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      white-space: nowrap;
    }

    &:hover,
    &.active {
      div {
        background: $blue;
      }

      span {
        color: $secondary;
      }
    }
  }

  &:hover,
  &.opened {
    width: 350px;

    .sidebar-inner {
      div {
        margin-right: 36px;
      }

    }

    .sidebar-item_image {
      margin-right: 16px;
    }

    .sidebar-logo {

      img {
        margin-right: 16px;
      }

    }
  }
}

.sidebar-bar3 {
  cursor: pointer;
}

@media only screen and (max-width: 1919px) {
  .sidebar-footer {
    display: none;
  }
}
</style>
