<template>
  <div>
    <tabs-component class="mb-1" :tabs="tabs" :active-tab="activeTab" :is-modified="true" @tab-click="changeTab"/>

    <div>
      <comments-tab v-if="activeTab === 'comment' && item?.id"
                    :comments="getAllComments"
                    :totalComments="totalComments"
                    @addComment="addComment"
                    @loadComments="loadComments"
                    @openImage="toggleImageModal"/>
      <QueriesTCMapComponent v-if="activeTab === 'map'" :item="item"/>
    </div>
    <custom-modal v-if="image" @close="toggleImageModal">
      <image-modal :image="image"/>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import TabsComponent from "@/components/modules/TabsComponent.vue";
import CommentsTab from "@/components/tabs/CommentsTab.vue";
import QueriesTCMapComponent from "./QueriesTCMapComponent.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import ImageModal from "@/components/modals/ImageModal.vue";

export default {
  name: "QueriesTCTabsComponent",
  components: {CommentsTab, TabsComponent, QueriesTCMapComponent, CustomModal},
  props: ['item'],
  created() {
    this.tabs = [
      {
        title: 'Комментарии',
        icon: '/assets/tabs/comment-gray.svg',
        icon_active: '/assets/tabs/comment-white.svg',
        key: 'comment'
      },
      {
        title: 'Карта',
        icon: '/assets/tabs/map-gray.svg',
        icon_active: '/assets/tabs/map-white.svg',
        key: 'map'
      },
    ]
  },
  mounted() {
    if (this.item?.id && this.activeTab === 'comment') {
      this.$nextTick(() => {
        this.fetchTicketComments({ticket_id: this.item.id, page: 1})
      })
    }
  },
  watch: {
    'item.id': {
      immediate: false,
      handler(newId) {
        if (newId && this.activeTab === 'comment') {
          this.$nextTick(() => {
            this.fetchTicketComments({ticket_id: newId, page: 1})
          })
        }
      }
    },
    activeTab(newTab) {
      if (newTab === 'comment' && this.item?.id) {
        this.fetchTicketComments({ticket_id: this.item.id, page: 1})
      }
    }
  },
  data() {
    return {
      activeTab: 'comment',
      image: '',
    }
  },
  computed: {
    ...mapGetters({
      currentCommentPage: 'currentCommentPage',
      ticketComments: 'ticketComments',
      totalComments: 'totalComments',
    }),
    getAllComments() {
      if (this.item.erp_comments?.success) {
        const erpComments = this.item.erp_comments.data.map(({user, comment, created_at}) => ({
          ...user,
          author: {
            full_name: `${user.last_name} ${user.name} (ERP)`,
          },
          message: comment,
          created_at: created_at,
        }));
        return [...this.ticketComments, ...erpComments.reverse()];
      }
      return this.ticketComments;
    }
  },
  methods: {
    ...mapActions({
      fetchTicketComments: 'fetchTicketComments',
      addTicketComments: 'addTicketComments',

    }),
    ...mapActions('queriesTCModule', {
      sendCommentToErp: 'sendCommentToErp',
      fetchItem: 'fetchItem',
    }),
    ...mapMutations({
      setCurrentCommentPage: 'setCurrentCommentPage',
      setTicketComments: 'setTicketComments',
    }),
    changeTab(tab) {
      this.activeTab = tab
    },
    async addComment(comment) {
      comment.ticket_id = this.item.id
      await Promise.all([this.sendCommentToErp({
        id: this.item.id,
        payload: comment
      }), this.addTicketComments(comment)]).then((res) => {
        this.fetchItem(this.$route.params.id)
      })

    },
    loadComments() {
      this.setCurrentCommentPage(this.currentCommentPage + 1)
      this.fetchTicketComments({
        ticket_id: this.item.id,
        page: this.currentCommentPage
      })
    },
    toggleImageModal(src = '') {
      this.image = src
    },
  },

}
</script>