<template>
  <div class="search-client">
    <label class="search-client__search-bar">
      <input
        type="text"
        class="search-client__search-bar-input"
        v-model="search"
        :placeholder="placeholder"
        @input="onInput"
      >
      <img
        src="/assets/search.svg"
        alt="search"
        class="search-client__search-bar-icon"
      />
    </label>
    <div
      v-if="result.length && search.length"
      class="search-client__result-dropdown"
    >
      <ul class="search-client__result-list">
        <li
          v-for="item in result"
          :key="item.id"
          class="search-client__result-item"
          @click="onClick(item)"
        >
          <slot name="resultItem" :item="item">
            <p>{{ item.name }}</p>
            <p>{{ item.iin }}</p>
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Поиск клиента',
    },
    modelValue: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: {
    'update:modelValue': (value) => true,
    input: (value) => true,
    select: (item) => true,
  },
  data() {
    return {
      search: this.modelValue,
      timeout: null,
    };
  },
  methods: {
    onClick(item) {
      this.$emit('select', item)
    },
    onInput() {
      this.debounce(() => {
        this.$emit('update:modelValue', this.search);
        this.$emit('input', this.search);
      });
    },
    debounce(callback, ms = 1500) {
      this.timeout ? clearTimeout(this.timeout) : null;
      this.timeout = setTimeout(callback, ms);
    },
  },
  watch: {
    modelValue(value) {
      this.search = value;
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../theme/variables';

.search-client {
  position: relative;
  width: 352px;

  &__search-bar {
    position: relative;
    width: 100%;
  }

  &__search-bar-input {
    width: 100%;
    height: 43px;

    outline-color: $gray8;
    box-sizing: border-box;

    border-radius: 25px;
    border: 2px solid $gray6;
    background: $white;
    padding: 12px 20px 12px 40px;

    letter-spacing: 0.07px;
    line-height: 20px;
    font-size: 18px;
    color: $black2;
  }

  &__search-bar-icon {
    position: absolute;

    top: 50%;
    left: 15px;

    width: 20px;

    transform: translateY(-50%);
  }

  &__result-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 99;

    border: 1px solid #E1E1E1;
    background: #F3F7FA;
    border-radius: 15px;

    width: 100%;
    overflow: hidden;
  }

  &__result-list {
    padding: 0;
    width: 100%;
  }

  &__result-item {
    position: relative;
    list-style: none;

    padding: 10px 20px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;
    font-weight: 400;
    color: #A1A1A1;

    &:hover {
      background: #E1E1E1;
    }

    &::before {
      content: '';

      position: absolute;
      top: 100%;
      left: 20px;
      right: 20px;

      height: 1px;
      background: #E1E1E1;

      opacity: 0;
    }

    &:not(:last-of-type)::before {
      opacity: 1;
    }
  }
}
</style>
