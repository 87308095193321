<template>
  <div class="wrapper modified flex column">
    <div class="bg-white rounded p-20">
      <div class="flex justify-between">
        <div>
          <button class="btn btn-green" @click="$router.push('/roles')">Назад</button>
        </div>
        <div>
          <button class="btn btn-create" @click="toggleCreateModal">
            <img src="/assets/plus-create.svg" alt=""/>Создать отдел
          </button>
        </div>
      </div>
      <div>
        <div class="mt-4">
          <h1 class="text-center text-20 mb-3">Отделы</h1>
          <div class="table-container">
            <div class="grid" style="background-color: lightgray;">
              <div class="grid-num">
                <p>№</p>
              </div>
              <div class="grid-name">
                <p>Отдел</p>
              </div>
              <div class="grid-key">
                <p>Ключ</p>
              </div>
              <div class="grid-dispatcher">
                <p>Диспетчер</p>
              </div>
              <div class="grid-tech">
                <p>ТО</p>
              </div>
              <div class="grid-roles">
                <p>Роли</p>
              </div>
              <div class="grid-options">
                <p>Опции</p>
              </div>
            </div>
            <div class="department-table">
              <div class="grid" v-for="(department, index) in departments" :key="department">
                <div> {{ index + 1 }}</div>
                <div>{{ department.title }}</div>
                <div>
                  <p>{{ department.key }}</p>
                </div>
                <div>
                  <p>{{department.dispatcher?.full_name || '-'}}</p>
                </div>
                <div>
                  <p>{{ department.is_tech_dep ? 'Да' : 'Нет' }}</p>
                </div>
                <div>
                  <div v-for="role in department.roles" :key="role">
                    {{role.title}}
                  </div>
                </div>
                <div class="department-options">
                  <img src="/assets/list.svg" alt="edit" width="16" height="16" @click="editDepartment(department)" title="Изменить статус ТО"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <custom-modal v-if="isCreateModal" @close="toggleCreateModal">
      <new-department
        @close="toggleCreateModal"
        @on-submit="onCreateDepartmentSubmit"
      />
    </custom-modal>

    <custom-modal v-if="isEditTOModal" @close="toggleEditTOModal">
      <div class="p-4">
        <h3 class="text-lg font-bold mb-4">Изменить статус ТО</h3>
        <div class="mb-4">
          <p class="mb-2">Отдел: {{ editingDepartment?.title }}</p>
          <div class="list">
            <label class="mr-2">ТО:</label>
            <input 
              type="checkbox" 
              v-model="tempIsTechDep"
              class="form-checkbox"
            />
          </div>
          <div class="list">
            <label class="mr-2">На выезд:</label>
            <input
                type="checkbox"
                v-model="isDeparture"
                class="form-checkbox"
            />
          </div>
        </div>
        <div class="flex justify-end gap-2">
          <button class="btn btn-secondary" @click="toggleEditTOModal">Отмена</button>
          <button class="btn btn-primary" @click="onUpdateTOStatus">Сохранить</button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import NewDepartment from "@/components/modals/department/NewDepartment.vue";

export default {
  name: "RolesDepartments",
  components: {CustomModal, NewDepartment},
  created() {
    this.setLoading(true)
    this.fetchDepartments().then(departments => {
      this.setLoading(false)
    });
  },
  data() {
    return {
      isCreateModal: false,
      isEditTOModal: false,
      editingDepartment: null,
      tempIsTechDep: false,
      isDeparture: false
    }
  },
  computed: {
    ...mapGetters({
      departments: 'departments'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchDepartments: 'fetchDepartments',
      updateDepartment: 'updateDepartment',
      storeDepartment: 'storeDepartment',
    }),
    toggleCreateModal() {
      this.isCreateModal = !this.isCreateModal
    },
    toggleEditTOModal() {
      this.isEditTOModal = !this.isEditTOModal
      if (!this.isEditTOModal) {
        this.editingDepartment = null
        this.tempIsTechDep = false
      }
    },
    editDepartment(department) {
      this.editingDepartment = department
      this.tempIsTechDep = department.is_tech_dep
      this.isDeparture = department.is_departure
      this.isEditTOModal = true
    },
    async onCreateDepartmentSubmit(department) {
      this.setLoading(true)
      try {
        const res = await this.storeDepartment(department)
        if (res.success) {
          this.$toast.success('Отдел успешно создан!')
          this.toggleCreateModal()
        } else {
          this.$toast.error('Не удалось создать отдел')
        }
      } catch (err) {
        this.$toast.error(`${err}`)
      } finally {
        this.setLoading(false)
      }
    },
    async onUpdateTOStatus() {
      if (!this.editingDepartment) return

      this.setLoading(true)
      try {
        const updatedDepartment = {
          ...this.editingDepartment,
          is_tech_dep: this.tempIsTechDep,
          is_departure: this.isDeparture
        }
        
        const res = await this.updateDepartment(updatedDepartment)
        
        if (res.success) {
          this.$toast.success('Статус ТО успешно обновлен!')
          this.toggleEditTOModal()
        } else {
          this.$toast.error('Не удалось обновить статус ТО')
        }
      } catch (err) {
        this.$toast.error(`${err}`)
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-areas: 'Num Name Key Dispatcher Tech Roles Options';
}

.grid-num {
  grid-area: Num;
}

.grid-name {
  grid-area: Name;
}

.grid-key {
  grid-area: Key;
}

.grid-roles {
  grid-area: Roles;
}

.grid-dispatcher {
  grid-area: Dispatcher;
}

.grid-tech {
  grid-area: Tech;
}

.department-options {
  grid-area: Options;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.department-table {
  :hover {
    background-color: #F5F5FA;
  }
}

.list {
  display: grid;
  grid-template-columns: 100px 20px;
}
</style>