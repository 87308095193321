<template>
  <div class="p-20">
    <div class="choose-modal">
      <div class="choose-modal__title w-100 mb-4">
        Выберите склад
      </div>
      <div class="dropdown w-100">
        <input
            type="text"
            class="input-form"
            v-model="search"
            @click="toggleDropdown"
            @input="showDropdown = true"
            placeholder="Выберите склад"
        />
        <div class="dropdown-content" :class="{ show: showDropdown }">
          <div
              v-for="option in filteredOptions"
              :key="option.id"
              @click="selectOption(option)"
          >
            {{ option.title }}
          </div>
        </div>
      </div>
      <button type="button" class="btn mb-2 mt-4" @click="setWarehouse">Сохранить</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ChangeWarehouseModal",
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  async created() {
    await this.getWarehouses();
  },
  data() {
    return {
      warehouseKey: '',
      search: '',
      showDropdown: false,
      filteredOptions: this.warehouses
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses'
    })
  },
  watch: {
    search(value) {
      this.filteredOptions = this.warehouses.filter(option =>
          option.title.toLowerCase().includes(value.toLowerCase())
      );
    },
    warehouses(newOptions) {
      this.filteredOptions = newOptions;
    }
  },
  methods: {
    ...mapActions({
      getWarehouses: 'getWarehouses',
      getVirtualWarehouses: 'getVirtualWarehouses',
      updateWarehousesOrStatus: 'updateWarehousesOrStatus',
    }),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.warehouseKey = option.key
      this.search = option.title;
      this.showDropdown = false;
    },
    async setWarehouse() {
      const obj = {
        id: this.id,
        storage_key: this.warehouseKey,
      }
      await this.updateWarehousesOrStatus(obj).then((res)=> {
        if (res.success){
          this.getVirtualWarehouses()
          this.$toast.success('Склад был успешно изменён')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось изменить склад')
        }
      }).catch((err) => {
        this.$toast.error(`${err.message}`)
      })

    }
  },
}
</script>


<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}
</style>