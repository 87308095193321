<template>
  <div class="wrapper justify-center">
    <div class="form flex justify-center">

      <div class="form-inner">
        <h1 class="text-black2 text-center w-100 mb-2">НОВАЯ ЗАЯВКА “Консультация”</h1>


          <div class="input-container input-container__form mb-2">
            <select class="input input-form select" :class="{'empty': !newTicket.category_id}" v-model="newTicket.category_id" ref="category_id" @change="categorySelected">
              <option v-for="option in categories" :key="option.key" :value="option.id">
                {{ option.title }}
              </option>
            </select>
            <label class="label-form">Категория</label>
          </div>

          <div class="input-container input-container__form mb-2">
            <input class="input input-form" type="text" v-model="searchClient" ref="client" placeholder="Клиент" @keyup="searchClients" />
            <label class="label-form">Клиент</label>
            <div class="field__search-results" v-if="clients && clients.length > 0">
              <div class="field__search-results_item flex justify-between" v-for="client in clients" :key="client.uid" @click="selectClient(client)">
                <p>{{ client.name }}</p>
                <p>{{ client.login }}</p>
              </div>
            </div>
            <div class="input-btn input-btn__form">
              <img src="/assets/search-form.svg" alt="">
            </div>
          </div>

          <div class="input-container input-container__form mb-2">
            <input class="input input-form" type="text" v-model="searchAddress" ref="address" placeholder="Адрес" @keyup="searchAddresses" />
            <label class="label-form">Адрес</label>
            <div class="field__search-results" v-if="addresses.length > 0">
              <div class="field__search-results_item flex justify-between" v-for="address in addresses" :key="address.id" @click="selectAddress(address.metaDataProperty.GeocoderMetaData.text)">
                <p>{{ address.metaDataProperty.GeocoderMetaData.text }}</p>
              </div>
            </div>
            <div class="input-btn input-btn__form">
              <img src="/assets/search-form.svg" alt="">
            </div>
          </div>

          <div class="input-container input-container__form mb-2">
            <select class="input input-form select" :class="{'empty': !newTicket.district_id}" v-model="newTicket.district_id" ref="district_id">
              <option v-for="option in districts" :key="option.key" :value="option.id">
                {{ option.title }}
              </option>
            </select>
            <label class="label-form">Район</label>
          </div>



          <div class="input-container input-container__form mb-2">
            <select class="input input-form select" :class="{'empty': !newTicket.language}" v-model="newTicket.language" ref="language">
              <option v-for="option in langOptions" :key="option.id" :value="option.id">
                {{ option.title }}
              </option>
            </select>
            <label class="label-form">Язык</label>
          </div>

          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="newTicket.contact_fio" ref="contact_fio" placeholder="Контактное лицо" type="text" />
            <label class="label-form">Контактное лицо</label>
          </div>

          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="newTicket.contact_number" v-mask="phoneMask" ref="contact_number" placeholder="Контактный номер" type="text" />
            <label class="label-form">Контактный номер</label>
          </div>


          <div class="input-container input-container__form mb-2">
            <select class="input input-form select" :class="{'empty': !newTicket.lanbilling_oper_id}" v-model="newTicket.lanbilling_oper_id" ref="lanbilling_oper_id">
              <option v-for="option in companies" :key="option.key" :value="option.lanbilling_id">
                {{ option.title }}
              </option>
            </select>
            <label class="label-form">Компания</label>
          </div>

          <template v-if="newTicket.details.length > 0">

            <template v-for="detail in newTicket.details" :key="detail.id">

              <div class="input-container input-container__form mb-2" v-if="detail.key === 'lack_of_service'">
                <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value" :ref="detail.key">
                  <option value="Полное">Полное</option>
                  <option value="Частичное">Частичное</option>
                </select>
                <label class="label-form">{{ detail.title }}</label>
              </div>

              <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'pbx_in_service'">
                <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value" :ref="detail.key">
                  <option value="Да">Да</option>
                  <option value="Нет">Нет</option>
                </select>
                <label class="label-form">{{ detail.title }}</label>
              </div>

              <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'connection_by'">
                <select class="input input-form select" :class="{'empty': !detail.value}" v-model="detail.value" :ref="detail.key">
                  <option value="Приставка">Приставка</option>
                  <option value="Wi-Fi">Wi-Fi</option>
                </select>
                <label class="label-form">{{ detail.title }}</label>
              </div>

              <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'sip_data' || detail.key === 'registration_number'">
                <input class="input input-form" v-model="detail.value" v-mask="phoneMask" :ref="detail.key" :placeholder="detail.title" type="text" />
                <label class="label-form">{{ detail.title }}</label>
              </div>

              <div class="input-container input-container__form mb-2" v-else-if="detail.key === 'error_number'">
                <input class="input input-form" v-model="detail.value" v-mask="phoneMask" :ref="detail.key" :placeholder="detail.title" type="number" />
                <label class="label-form">{{ detail.title }}</label>
              </div>

              <template v-else-if="detail.key === 'ip_address'">
                <template v-if="isClientIps">
                  <div class="input-container input-container__form mb-2" v-for="(ip, index) in detail.value.split(',')" :key="ip" @click="fetchConnectionType(ip)">
                    <input class="input input-form cursor-pointer link" :value="ip" :placeholder="detail.title" type="text" readonly />
                    <label class="label-form">{{ detail.title }} {{index + 1}}</label>
                  </div>
                </template>

                <div class="input-container input-container__form mb-2" v-else>
                  <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title" type="text" />
                  <label class="label-form">{{ detail.title }}</label>
                </div>
              </template>


              <div class="input-container input-container__form mb-2" v-else>
                <input class="input input-form" v-model="detail.value" :ref="detail.key" :placeholder="detail.title" type="text" />
                <label class="label-form">{{ detail.title }}</label>
              </div>

            </template>
          </template>

          <div class="input-container input-container__form mb-2">
            <textarea class="input-form textarea" v-model="newTicket.message" ref="message" placeholder="Описание" />
            <label class="label-form" for="body">Описание</label>
          </div>

          <div class="w-100 mb-2">
            <DropZone
                class="!cursor-pointer w-full h-full !border-none"
                :uploadOnDrop="false"
                ref="files"
                :multipleUpload="true"
                :parallelUpload="3">
              <template v-slot:message>
                <DropZoneInner />
              </template>
            </DropZone>
          </div>




          <button type="button" class="btn form-btn mb-4" @click="submitForm">создать</button>


      </div>

    </div>
    <custom-modal v-if="isSuccessModal" @close="toggleSuccessModal">
      <success-modal @close="toggleSuccessModal">
        ЗАЯВКА НА КОНСУЛЬТАЦИЮ УСПЕШНО СОЗДАНА
      </success-modal>
    </custom-modal>
    <custom-modal v-if="isIpCheckResultModal" @close="toggleIpCheckResult" :custom-class="'bg-gray'">
      <ip-check-result-modal />
    </custom-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {formatDate, getBase64, getFileFormat} from "@/helpers/utils";
import IpCheckResultModal from "@/components/modals/IpCheckResultModal.vue";
import CustomModal from "@/components/modules/CustomModal.vue";
import SuccessModal from "@/components/modals/tickets/SuccessModal.vue";
import { DropZone } from 'dropzone-vue';
import DropZoneInner from "@/components/static/DropZoneInner.vue";
import router from "@/router";

export default {
  name: 'ConsultationTicketFormView',
  components: {
    SuccessModal,
    CustomModal,
    DropZone,
    DropZoneInner,
    IpCheckResultModal
  },
  data() {
    return {
      newTicket: {
        lanbilling_oper_id: 0,
        lanbilling_id: 0,
        lanbilling_vg_id: 0,
        category_id: 0,
        district_id: 0,
        contact_number: '',
        language: '',
        message: '',
        address: '',
        details: [],
        contact_fio: '',
        attached_files: []
      },
      phoneMask: '+7 (###)-###-##-##',
      searchClient: '',
      clientTimeOut: null,
      searchAddress: '',
      addressTimeOut: null,
      selectedClient: {
        name: ''
      },
      langOptions: [
        {
          title: 'Казахский',
          id: 'kz'
        },
        {
          title: 'Русский',
          id: 'ru'
        },
      ],
      isSuccessModal: false,
      isClientIps: false
    }
  },
  created() {
    this.setLoading(true)
    let promises = [this.fetchCategories(10)]

    if (!this.districts || !this.districts.length) promises.push(this.fetchDistricts())
    if (!this.companies || !this.companies.length) promises.push(this.fetchCompanies())
    Promise.all(promises).then(() => {
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters({
      categories: 'categories',
      clients: 'clients',
      addresses: 'addresses',
      districts: 'districts',
      companies: 'companies',
      detailTypes: 'detailTypes',
      isIpCheckResultModal: 'isIpCheckResultModal'
    })
  },
  methods: {
    ...mapMutations({
      setClients: 'setClients',
      setAddresses: 'setAddresses',
      setLoading: 'setLoading',
      setIsIpCheckResultModal: 'setIsIpCheckResultModal'
    }),
    ...mapActions({
      fetchCategories: 'fetchCategories',
      fetchClients: 'fetchClients',
      fetchAddresses: 'fetchAddresses',
      fetchDistricts: 'fetchDistricts',
      fetchCompanies: 'fetchCompanies',
      fetchDetailTypes: 'fetchDetailTypes',
      createConsultationTicket: 'createConsultationTicket',
      errorAlert: 'errorAlert',
      checkIpAddress: 'checkIpAddress'
    }),
    toggleSuccessModal() {
      this.isSuccessModal = !this.isSuccessModal
      if (!this.isSuccessModal) router.push('/tickets')
    },
    formatDate(date) {
      return formatDate(date, '.', true)
    },
    searchClients() {
      if (this.searchClient.length >= 3) {
        clearTimeout(this.clientTimeOut)
        this.clientTimeOut = setTimeout(async () => {
          this.setLoading(true)
          await this.fetchClients(this.searchClient)
          this.setLoading(false)
        }, 1500)
      }
      else this.setClients([])
    },
    fetchConnectionType(ip) {
      this.checkIpAddress(ip)
    },
    toggleIpCheckResult() {
      this.setIsIpCheckResultModal(!this.isIpCheckResultModal)
    },
    async selectClient(client) {
      this.selectedClient = client
      this.newTicket.contact_number = this.toMask(client.mobile)
      this.newTicket.lanbilling_oper_id = client.lanbilling_oper_id
      this.newTicket.lanbilling_id = client.uid
      this.newTicket.lanbilling_vg_id = client.vg_id
      this.newTicket.client_type_id = client.client_type_id
      if (client.type === 2) {
        this.newTicket.contact_fio = client.name
      }
      this.searchClient = client.name
      this.setClients([])
      if (this.newTicket.category_id) {
        this.setLoading(true)
        await this.fetchDetailTypes({
          category_id: this.newTicket.category_id,
          vg_id: this.newTicket.lanbilling_vg_id,
          problem_type_id: 0
        })

        this.setLoading(false)
        this.newTicket.details = this.detailTypes
        this.newTicket.details.forEach(item => {
          if (item.key === 'ip_address') {
            this.isClientIps = true
            item.value = this.selectedClient.ip_address
          }
          if (item.key === 'connection_method') item.value = this.selectedClient.connection_method
          if (item.key === 'lb_status_is_active') item.value = this.selectedClient.status
        })
      }
    },
    searchAddresses() {
      if (this.searchAddress.length >= 3) {
        clearTimeout(this.addressTimeOut)
        this.addressTimeOut = setTimeout(async () => {
          this.setLoading(true)
          await this.fetchAddresses(this.searchAddress)
          this.setLoading(false)
        }, 1500)
      }
      else this.setAddresses([])
    },
    selectAddress(address) {
      this.newTicket.address = address
      this.searchAddress = address
      this.setAddresses([])
    },
    unMask(value) {
      return value.replace(/[() +-]/g, "").trim()
    },
    toMask(value) {
      return '7' + this.unMask(value).slice(-10)
    },
    chooseLang(lang) {
      this.newTicket.language = lang
    },
    async categorySelected() {
      if (this.newTicket.lanbilling_vg_id) {
        this.setLoading(true)
        await this.fetchDetailTypes({
          category_id: this.newTicket.category_id,
          vg_id: this.newTicket.lanbilling_vg_id,
          problem_type_id: 0
        })

        this.setLoading(false)
        this.newTicket.details = this.detailTypes
        this.newTicket.details.forEach(item => {
          if (item.key === 'ip_address') {
            this.isClientIps = true
            item.value = this.selectedClient.ip_address
          }
          if (item.key === 'connection_method') item.value = this.selectedClient.connection_method
          if (item.key === 'lb_status_is_active') item.value = this.selectedClient.status
        })
      }

    },
    validateForm() {
      let isError = false
      for (let property in this.newTicket) {
        if (property === 'details') {
          for (const item of this.newTicket.details) {
            if (!item.value && item.is_required) {
              isError = true
              this.errorAlert(`${item.title} не указан`)
              this.$refs[item.key][0].classList.add('error')
              setTimeout(() => {
                this.$refs[item.key][0].classList.remove('error')
              }, 3000)
            }
          }
        }
        else if (property === 'lanbilling_id' || property === 'lanbilling_vg_id') {
          if (!this.newTicket['lanbilling_id'] || !this.newTicket['lanbilling_vg_id']) {
            isError = true
            this.$refs['client'].classList.add('error')
            setTimeout(() => {
              this.$refs['client'].classList.remove('error')
            }, 3000)
          }
        }
        else if (property === 'address') {
          if (!this.newTicket['address']) {
            isError = true
            this.$refs['address'].classList.add('error')
            setTimeout(() => {
              this.$refs['address'].classList.remove('error')
            }, 3000)
          }
        }
        else if (property === 'message') {
          if (this.newTicket['message'].length > 200 || this.newTicket['message'].length < 3) {
            isError = true
            this.errorAlert('Описание не может быть меньше чем 3 или больше чем 200 символов')
            this.$refs['message'].classList.add('error')
            setTimeout(() => {
              this.$refs['message'].classList.remove('error')
            }, 3000)
          }
        }
        else if (property === 'language') {
          if (!this.newTicket['language']) {
            isError = true
            this.errorAlert('Выберите язык комуникации')
          }
        }
        else if (!this.newTicket[property] && property !== 'ticket_id' && property !== 'attached_files') {
          isError = true
          if (this.$refs[property]) {
            this.$refs[property].classList.add('error')
            setTimeout(() => {
              this.$refs[property].classList.remove('error')
            }, 3000)
          }
        }
      }
      return isError
    },
    async submitForm() {
      if (!this.validateForm()) {
        let file = {}
        for(const prop in this.$refs.files.all) {

          await getBase64(this.$refs.files.all[prop].file).then(data => {
            file.format = getFileFormat(this.$refs.files.all[prop].file)
            file.base64String = data
            this.newTicket.attached_files.push(file)
          })
          // = getBase64(this.$refs.files.all[prop].file)

        }
        // if (this.$props.scoreId) this.newTicket.score_id = this.$props.scoreId
        this.newTicket.contact_number = this.unMask(this.newTicket.contact_number)
        if (this.newTicket.additional_contact_number) this.newTicket.additional_contact_number = this.unMask(this.newTicket.additional_contact_number)
        await this.createConsultationTicket(this.newTicket).then(res => {
          if(res.success) {
            this.toggleSuccessModal()
          }
          else this.errorAlert(res.message)
        })
      } else this.errorAlert('Заполните все поля')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../theme/variables';

</style>
