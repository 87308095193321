<template>
  <div class="wrapper wrapper-content modified flex column bg">
    <div class="bg-white p-20 rounded">
      <div class="mt-2 mb-2" style="border: 2px solid #F5F5F5"></div>
      <div>
        <div class="mt-3" style="background: #F5F5FA; border-radius: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="grid">
            <div class="grid-num">
              <p class="title-text">№</p>
            </div>
            <div class="grid-name">
              <p class="title-text">Название участка</p>
            </div>
            <div class="grid-color">
              <p class="title-text">Цвет</p>
            </div>
            <div class="grid-manager">
              <p class="title-text">Менеджер</p>
            </div>
          </div>
          <div class="area-table">
            <div v-for="(polygon, index) in polygons" :key="polygon"
                 style="background: white; margin-bottom: 6px; border-radius: 10px;">
              <PolygonsTableDataComponent :polygon="polygon" :index="index" :managers="parsedManagers"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import PolygonsTableDataComponent from "@/components/cards/polygons/PolygonsTableDataComponent.vue";

export default {
  name: "PolygonsManagement",
  components: {PolygonsTableDataComponent},
  data() {
    return {
      isOpenMap: false,
      polygonCoordinates: null,
      selectedManager: null,
      technologies: [
        {value: 1, label: "OPTIC"},
        {value: 2, label: "Radio"}
      ],
    }
  },
  created() {
    this.setLoading(true)
    this.getPolygons();
    this.getManagerPolygon()
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      polygons: 'polygons',
      managerPolygon: 'managerPolygon'
    }),
    parsedManagers() {
      if (!this.managerPolygon) {
        return [];
      }
      return this.managerPolygon.map((manager) => {
        return {
          value: manager.id,
          label: manager.full_name,
          polygons: manager.manager_polygons
        }
      })
    }
  },
  methods: {
    ...mapActions({
      getPolygons: 'getPolygons',
      getManagerPolygon: 'getManagerPolygon',
    }),
    ...mapMutations({
      setLoading: 'setLoading',
    }),
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 6fr 3fr 4fr;
  grid-template-areas: 'Num Name Color Manager';
}

.title-text {
  color: #888888;
}

.grid-num {
  grid-area: Num;
  text-align: center;
}

.grid-name {
  grid-area: Name;
  text-align: center;
}

.grid-color {
  grid-area: Color;
  text-align: center;
}

.grid-manager {
  grid-area: Manager;
  text-align: center;
}

.grid-options {
  grid-area: Options;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.area-table {
}
</style>