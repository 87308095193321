<template>
  <new-side-modal>
    <template #header>
      <div class="modal_title">
        <div class="modal_title__image">
          <img src="/assets/bell.svg" alt="notifications" />
        </div>
        <h3>Уведомления</h3>
      </div>
    </template>
    
    <div class="notifications-toggle-container">
      <div class="notifications-toggle">
        <button 
          class="notifications-toggle__button" 
          :class="{ active: activeTab === 'active' }"
          @click="activeTab = 'active'"
        >
          Активные
        </button>
        <button 
          class="notifications-toggle__button" 
          :class="{ active: activeTab === 'archive' }"
          @click="activeTab = 'archive'"
        >
          Архив
        </button>
      </div>

      <base-search
        v-model="searchQuery"
        placeholder="Поиск по дате или номеру заявки"
        class="notifications-search"
      />

      <div class="read-all-button-container" v-if="activeTab === 'active' && unreadNotifications.length">
        <button 
          class="read-all-button"
          @click="handleReadAll"
        >
          Отметить как прочитанное
        </button>
      </div>
    </div>
    
    <div class="notifications-content">
      <div class="notifications-list">
        <template v-if="filteredNotifications.length">
          <div 
            class="notifications__item" 
            v-for="notification in filteredNotifications" 
            :key="notification.id" 
            :class="{
              'new': notification.is_read === 0,
              'archived': notification.is_read === 1
            }"
          >
            <div class="notifications__item-header">
              <h4 class="title">{{notification.manager.full_name}}</h4>
            </div>
            <p class="body">{{notification.body}}</p>
            <div class="footer">
              <span class="date">{{notification.created_at}}</span>
            </div>
          </div>
        </template>
        <div v-else class="empty-state">
          <p>Уведомлений нет</p>
        </div>
      </div>
    </div>
  </new-side-modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import NewSideModal from './NewSideModal.vue';
import BaseSearch from '../../ui/BaseSearch.vue';

export default {
  name: 'UserNotificationsModal',
  components: {
    NewSideModal,
    BaseSearch
  },
  data() {
    return {
      activeTab: 'active',
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters({
      userNotifications: 'userNotifications'
    }),
    filteredNotifications() {
      let filtered = this.userNotifications.filter(notification => {
        if (this.activeTab === 'active') {
          return notification.is_read === 0;
        } else {
          return notification.is_read === 1;
        }
      });

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(notification => {
          const ticketNumber = this.getTicketNumber(notification.body);
          return notification.created_at.toLowerCase().includes(query) ||
            (ticketNumber && ticketNumber.includes(query));
        });
      }

      return filtered;
    },
    unreadNotifications() {
      return this.userNotifications.filter(notification => notification.is_read === 0);
    },
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
      setUserNotifications: 'setUserNotifications'
    }),
    ...mapActions({
      readNotification: 'readNotification',
      readAllNotification: 'readAllNotification'
    }),
    getTicketNumber(body) {
      const match = body.match(/№(\d+)/);
      return match ? match[1] : null;
    },
    async handleReadAll() {
      // Optimistically update UI
      const updatedNotifications = this.userNotifications.map(notification => ({
        ...notification,
        is_read: 1
      }));
      this.setUserNotifications(updatedNotifications);
      
      try {
        await this.readAllNotification();
      } catch (error) {
        console.error('Failed to mark notifications as read:', error);
        this.setUserNotifications(this.userNotifications);
      }
    },
    async setIsRead(notification) {
      if (notification.is_read === 0) {
        this.setLoading(true)
        await this.readNotification(notification.id)
        this.setLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../theme/variables';

.modal_title {
  display: flex;
  align-items: center;
  gap: 12px;
  
  h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  &__image {
    width: 48px;
    height: 48px;
    border: 1px solid #E1E0E0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.notifications-toggle-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  margin: 0 -20px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .read-all-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .notifications-search {
    margin: 0;

    :deep(.search-container .input-form) {
      &:focus {
        outline: none;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), transparent);
    pointer-events: none;
  }
}

.notifications-toggle {
  display: flex;
  padding: 4px;
  border-radius: 14px;
  background: #F2F1F0;

  &__button {
    flex: 1;
    padding: 8px;
    border: none;
    background: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #1C1E23;
    cursor: pointer;
    transition: all 0.2s ease;

    &.active {
      background: #fff;
      color: #000;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:hover:not(.active) {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.notifications-content {
  flex: 1;
  padding-right: 0;

  .notifications-list {
    margin-top: 18px;
  }
}

.notifications {
  &__item {
    padding: 20px;
    margin: 0 0 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #fff;
    border-radius: 12px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;

      .ticket-number {
        font-size: 14px;
        font-weight: 500;
        color: #2F80ED;
        white-space: nowrap;
      }
    }

    &.new {
      background: #E6ECF2;
    }

    &.archived {
      background: #F2F1F0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #1C1E23;
      margin: 0;
    }

    .body {
      font-size: 15px;
      line-height: 1.5;
      color: #1C1E23;
      margin: 0;
    }

    .footer {
      .date {
        font-size: 13px;
        color: #757575;
      }
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 20px;
  gap: 16px;

  p {
    font-size: 16px;
    color: #9095A0;
    text-align: center;
    margin: 0;
  }
}

.read-all-button-container {
  margin: 6px 0;
}

.read-all-button {
  background: none;

  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #2F80ED;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: underline;

  &:hover {
    color: darken(#2F80ED, 10%);
  }
}
</style>