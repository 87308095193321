<template>
  <div class="wrapper flex column ">
    <div class="bg-white rounded p-20">
      <div class="flex items-center justify-between">
      <button class="btn w-30" @click="createNewPermission">Создать</button>
      <button class="btn btn-green w-30" @click="$router.push('/roles')">К ролям</button>
      </div>
      <div class="mt-3">
        <div class="grid" style="background-color: lightgray;">
          <div class="grid-num">
            <p>№</p>
          </div>
          <div class="grid-name">
            <p>Роль</p>
          </div>
          <div class="grid-permissions">
            <p>Разрешения</p>
          </div>
          <div class="grid-options">
            <p>Управление</p>
          </div>
        </div>
        <div class="roles-table">
          <div class="grid" v-for="(permission, index) in permissions" :key="permission">
            <div> {{ index + 1 }}</div>
            <div>{{ permission.role_title }}</div>
            <div>
              <div v-for="el in permission.permissions" :key="el">
                {{ el.section_title }}
              </div>
            </div>
            <div class="roles-options">
              <img src="/assets/list.svg" alt="edit" width="16" height="16" @click="editPermission(permission)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <custom-modal v-if="isPermissionModal" @close="isPermissionModal = !isPermissionModal">
    <permission-modal :permission="currentPermission" @close="closePermissionModal"></permission-modal>
  </custom-modal>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CustomModal from "@/components/modules/CustomModal.vue";
import PermissionModal from "@/components/modals/PermissionModal.vue";

export default {
  name: "PermissionsView",
  components: {PermissionModal, CustomModal},
  async created() {
    this.setLoading(true)
    await this.fetchRolePermissions();
    this.setLoading(false)
  },
  data() {
    return {
      isPermissionModal: false,
      currentPermission: [],
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions',
    })
  },
  methods: {
    ...mapActions({
      fetchRolePermissions: 'fetchRolePermissions',
    }),
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    editPermission(permission){
      this.isPermissionModal = true
      this.currentPermission = permission
    },
    createNewPermission(){
      this.currentPermission = {}
      this.isPermissionModal = true
    },
   async closePermissionModal(){
      this.setLoading(true)
      this.isPermissionModal = false
     await this.fetchRolePermissions()
     this.setLoading(false)
    }
  },
}
</script>
<style scoped lang="scss">
.grid {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 3fr  3fr 1fr;
  grid-template-areas: 'Num Name  Permissions Options';
}

.grid-num {
  grid-area: Num;
}

.grid-name {
  grid-area: Name;
}

.grid-permissions {
  grid-area: Permissions;
}

.grid-options {
  grid-area: Options;
}

.roles-table {
  :hover {
    background-color: #F5F5FA;
  }
}

.roles-options {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
</style>