<template>
  <div class="p-20">
    <div class="p-20 subtask-wrapper">
      <h1 class="text-center text-24 mb-4">{{type === 'edit' ? 'Редактировать подзадачу' : 'Создать подзадачу'}}</h1>
      <form>
        <div v-for="address in addresses" :key="address">
          <div class="input-container input-container__form mb-2">
            <input class="input input-form" v-model="address.address"
                   placeholder="Адрес" type="text"/>
            <label class="label-form">Адрес</label>
          </div>
        </div>
        <div class="input-container input-container__form mb-2" v-if="name">
          <input class="input input-form" v-model="name"
                 placeholder="ФИО клиента" type="text" />
          <label class="label-form">ФИО клиента</label>
        </div>
        <div class="input-container input-container__form mb-2" v-if="phone">
          <input class="input input-form" v-model="phone"
                 placeholder="Номер клиента" type="text" />
          <label class="label-form">Номер клиента</label>
        </div>
        <div class="input-container input-container__form mb-2" v-if="commutators.length > 0">
          <div v-for="commutator in commutators" :key="commutator.id">
            <input class="input input-form" v-model="commutator.commutator_name"
                   placeholder="Узлы" type="text" disabled/>
            <label class="label-form">Узлы</label>
          </div>
        </div>
        <div class="input-container input-container__form mb-2" v-if="initiator">
          <input class="input input-form" v-model="initiator"
                 placeholder="ФИО постановщика" type="text" />
          <label class="label-form">ФИО постановщика</label>
        </div>
        <div class="input-container input-container__form mb-1" v-if="initiator_phone">
          <input class="input input-form" v-model="initiator_phone"
                 placeholder="Номер постановщика" type="text" />
          <label class="label-form">Номер постановщика</label>
        </div>

        <div class="mb-1" >
          <div class="mb-1">Тип проблемы</div>
          <select class="input-form select" style="max-width: 340px" v-model="access_problem" @change="onProblemChange">
            <option
                v-for="option in subtaskProblems"
                :key="option.key"
                :value="option"
            >
              {{ option.title }}
            </option>
          </select>
        </div>

        <div class="mb-1" >
          <div class="mb-1">Отдел</div>
          <select class="input-form select" style="max-width: 340px"  v-model="department_id">
            <option value="empty"></option>
            <option :value="null"></option>
            <option
                v-for="option in filteredDepartments"
                :key="option.key"
                :value="option.id"
            >
              {{ option.title }}
            </option>
          </select>
        </div>

        <div class="input-container input-container__form mb-1 mt-2">
          <app-textarea class="input input-form" v-model="description"
                        placeholder="Описание" type="text"/>
          <label class="label-form">Описание</label>
        </div>
        <button class="btn form-btn" type="button" @click="createNewSubtask">{{type === 'edit' ? 'Редактировать' : 'Создать'}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppTextarea from "@/components/ui/AppTextarea.vue";

export default {
  name: "CreateSubtaskModal",
  components: {AppTextarea},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getAccessSubtaskProblems();
    this.fetchDepartments();
    this.addresses = this.chosenTicket.ticket.addresses;
    this.name = this.chosenTicket.ticket.client?.full_name;
    this.phone = this.chosenTicket.ticket.client?.phone;
    this.initiator = this.chosenTicket.ticket.author?.full_name;
    this.initiator_phone = this.chosenTicket.ticket.author?.phone;
    this.commutators = this.chosenTicket.ticket.commutators;
    this.description = this.chosenTicket.ticket.message

  },
  data() {
    return {
      addresses: [],
      description: '',
      name: '',
      phone: '',
      initiator: '',
      initiator_phone: '',
      commutators: [],
      department_id: null,
      access_problem: 'empty',
    };
  },
  computed: {
    ...mapGetters({
      chosenTicket: 'chosenTicket',
      subtaskProblems: 'subtaskProblems',
      departments: 'departments'
    }),
    filteredDepartments() {
      if (this.access_problem.department_id) {
        return this.departments.filter(department => department.id === this.access_problem.department_id);
      }
      return this.departments;
    }
  },
  methods: {
    ...mapActions({
      getAccessSubtaskProblems: 'getAccessSubtaskProblems',
      fetchDepartments: 'fetchDepartments',
      createSubtask: 'createSubtask'
    }),
    onProblemChange() {
      if (this.filteredDepartments.length > 0 && this.access_problem.department_id) {
        this.department_id = this.filteredDepartments[0].id;
      } else {
        this.department_id = null;
      }
    },
  async  createNewSubtask(){
    const obj = {
        parent_ticket_id: this.chosenTicket.ticket.id,
        access_problem_id: this.access_problem.id,
        department_id: this.department_id,
        description: this.description,
      }

      await this.createSubtask(obj).then((res)=> {
        if (res.success){
          this.$toast.success('Подзадача успешно создана')
          this.$emit('close')
        } else {
          this.$toast.error('Не удалось создать подзадачу')
        }
      }).catch((err)=>{
        this.$toast.error(`${err.message}`)
      })
    }
  },
  unmounted() {
    this.department_id = null
    this.access_problem = 'empty'
  }
};
</script>

<style scoped lang="scss">
.subtask-wrapper {
  height: 80vh;
  overflow-y: auto;
}
</style>
