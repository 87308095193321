<template>
  <div v-if="wikiPage" class="wrapper flex justify-center ">
    <div>
      <div v-for="block in wikiPage.blocks" :key="block.id">
        <div v-html="block.description"></div>
        <div v-for="video in block.files" :key="video.file_name">
          <h1 class="mb-2 mt-4">{{ video.file_name }}</h1>
          <video :src="video.file_url" controls width="600">
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "InstructionPage",
  async created() {
    this.setLoading(true)
    await this.fetchWikiPage(this.$route.params.id);
    this.setLoading(false)
  },
  computed: {
    ...mapGetters({
      wikiPage: 'wikiPage'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading'
    }),
    ...mapActions({
      fetchWikiPage: 'fetchWikiPage'
    }),
  },
}
</script>
<style scoped lang="scss">
@import '../../theme/variables';
</style>